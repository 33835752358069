import { makeStyles } from '@material-ui/core/styles';
import QueryTable from 'components-lib/QueryTable/QueryTable';
import Button from "components/CustomButtons/Button.js";
import paths from 'paths';
import React from 'react';
import { injectIntl } from 'react-intl';
import { generatePath } from 'react-router-dom';
import styles from "common/styles/widgets.js";
import { DateTime } from "luxon";
import OrderStatusEnum from 'common/enums/OrderStatusEnum';
import OrderTypeEnum from 'common/enums/OrderTypeEnum';
import Box from '@material-ui/core/Box';
import ButtonDetail from 'components/ButtonDetail/ButtonDetail';

const useStyles = makeStyles((theme) => styles(theme))

export const OrderTable = (props) => {
    const { history, query, detail, queryVariables, numberOfRows, intl, toggle, defaultFilter, queryDataPath } = props
    const classes = useStyles();

    const onOrderDetail = (id) => {
        history.push(`/admin${generatePath(paths.orders.detail, { orderId: id })}`);
    }

    const getOrderStatus = (status) => {
        switch (status) {
            case OrderStatusEnum.CREATED:
                return (<Box color="#795548">{intl.formatMessage({ id: 'orderDetail.historyCard.Created' })}</Box>);
            case OrderStatusEnum.PROCESSING:
                return (<Box color="#00acc1">{intl.formatMessage({ id: 'orderDetail.historyCard.Processing' })}</Box>);
            case OrderStatusEnum.READY:
                return (<Box color="#0288d1">{intl.formatMessage({ id: 'orderDetail.historyCard.Ready' })}</Box>);
            case OrderStatusEnum.INPROGRESS:
                return (<Box color="#01579b">{intl.formatMessage({ id: 'orderDetail.historyCard.InProgress' })}</Box>);
            case OrderStatusEnum.COMPLETED:
                return (<Box color="success.main">{intl.formatMessage({ id: 'orderDetail.historyCard.Completed' })}</Box>);
            case OrderStatusEnum.INCOMPLETE:
                return (<Box color="warning.main">{intl.formatMessage({ id: 'orderDetail.historyCard.Incomplete' })}</Box>);
            case OrderStatusEnum.PARTIAL:
                return (<Box color="warning.main">{intl.formatMessage({ id: 'orderDetail.historyCard.Partial' })}</Box>);
            case OrderStatusEnum.EXPIRED:
                return (<Box color="#9e9e9e">{intl.formatMessage({ id: 'orderDetail.historyCard.Expired' })}</Box>);
            case OrderStatusEnum.CANCELED:
                return (<Box color="error.main">{intl.formatMessage({ id: 'orderDetail.historyCard.Canceled' })}</Box>);
            default:
                return (<Box>{status}</Box>);
        }
    }

    const getOrderType = (type) => {
        switch (type) {
            case OrderTypeEnum.CREDIT:
                return (<Box>{intl.formatMessage({ id: 'orderList.table.creditType' })}</Box>);
            case OrderTypeEnum.FREEPHOTO:
                return (<Box>{intl.formatMessage({ id: 'orderList.table.freeFotoType' })}</Box>);
            case OrderTypeEnum.CREDIT_AND_PAYMENT:
                return (<Box>{intl.formatMessage({ id: 'orderList.table.creditAndPaymentType' })}</Box>);
            case OrderTypeEnum.PAYMENT:
                return (<Box>{intl.formatMessage({ id: 'orderList.table.paymentType' })}</Box>);

            default:
                return (<Box>{type}</Box>);
        }
    }

    const getColumnsDetail = () => {
        return (
            [
                {
                    Header: intl.formatMessage({ id: 'orderList.table.ID' }),
                    accessor: 'bid',
                    sortKey: 'bid',
                    filterKey: 'bid',
                    filterOperator: 'contains'
                },
                {
                    Header: intl.formatMessage({ id: 'orderList.table.status' }),
                    accessor: (rowData) => getOrderStatus(rowData.status),
                    sortKey: 'status',
                    filterKey: 'status',
                    filterComponent: 'select',
                    filterOperator: 'in',
                    filterOptions: [
                        {
                            value: OrderStatusEnum.CREATED,
                            label: intl.formatMessage({ id: 'orderDetail.historyCard.Created' }),
                        },
                        {
                            value: OrderStatusEnum.PROCESSING,
                            label: intl.formatMessage({ id: 'orderDetail.historyCard.Processing' }),
                        },
                        {
                            value: OrderStatusEnum.READY,
                            label: intl.formatMessage({ id: 'orderDetail.historyCard.Ready' }),
                        },
                        {
                            value: OrderStatusEnum.INPROGRESS,
                            label: intl.formatMessage({ id: 'orderDetail.historyCard.InProgress' }),
                        },
                        {
                            value: OrderStatusEnum.COMPLETED,
                            label: intl.formatMessage({ id: 'orderDetail.historyCard.Completed' }),
                        },
                        {
                            value: OrderStatusEnum.INCOMPLETE,
                            label: intl.formatMessage({ id: 'orderDetail.historyCard.Incomplete' }),
                        },
                        {
                            value: OrderStatusEnum.PARTIAL,
                            label: intl.formatMessage({ id: 'orderDetail.historyCard.Partial' }),
                        },
                        {
                            value: OrderStatusEnum.EXPIRED,
                            label: intl.formatMessage({ id: 'orderDetail.historyCard.Expired' }),
                        },
                        {
                            value: OrderStatusEnum.CANCELED,
                            label: intl.formatMessage({ id: 'orderDetail.historyCard.Canceled' }),
                        },
                    ]
                },
                {
                    Header: intl.formatMessage({ id: 'orderList.table.paid' }),
                    accessor: (rowData) => (rowData.isPaid) === true
                        ? intl.formatMessage({ id: 'orderList.isPaid.yes' })
                        : <Box color="#9e9e9e">{intl.formatMessage({ id: 'orderList.isPaid.no' })}</Box>,
                    sortKey: 'isPaid',
                    filterKey: 'isPaid',
                    filterComponent: 'select',
                    filterOperator: 'eq',
                    filterOptions: [
                        {
                            value: true,
                            label: intl.formatMessage({ id: 'orderList.isPaid.yes' })
                        },
                        {
                            value: false,
                            label: intl.formatMessage({ id: 'orderList.isPaid.no' })
                        },
                    ]
                },
                {
                    Header: intl.formatMessage({ id: 'orderList.table.createdAt' }),
                    accessor: (rowData) => rowData.createdAt !== null ? DateTime.fromISO(rowData.createdAt).toFormat("dd.MM.yyyy HH:mm:ss") : "",
                    sortKey: 'createdAt',
                    filterKey: "createdAt",
                    filterOperator: 'between',
                    filterDataType: "dateBetween",
                    filterComponent: 'date',
                },
                {
                    Header: intl.formatMessage({ id: 'orderList.table.expiration' }),
                    accessor: (rowData) => rowData.expiresAt !== null ? DateTime.fromISO(rowData.expiresAt).toFormat("dd.MM.yyyy HH:mm:ss") : "",
                    sortKey: 'expiresAt',
                    filterKey: "expiresAt",
                    filterOperator: 'between',
                    filterDataType: "dateBetween",
                    filterComponent: 'date',
                },
                {
                    Header: intl.formatMessage({ id: 'orderList.table.credit' }),
                    accessor: (rowData) => rowData.price + " C",
                    sortKey: "price",
                    filterKey: "price",
                    filterOperator: 'eq',
                    filterDataType: 'number',
                },
                {
                    Header: intl.formatMessage({ id: 'orderList.table.actions' }),
                    accessor: (rowData) => 
                        <div style={{ display: "flex", flexDirection: "row",  flexWrap: "wrap",
                        justifyContent: "center", alignItems: "center", gap: "0.2em" }}>

                        {/* // <Button round className={classes.tenantButtons} size="sm" color="info"
                        // onClick={() => onOrderDetail(rowData.id)}>{intl.formatMessage({ id: 'orderList.table.actions.detail' })}
                        // </Button> */}
                            <ButtonDetail
                            // classes={classes.tenantButtons}
                            onClick={ () => onOrderDetail(rowData.id) }
                            />
                        </div>

                }
            ]
        )
    }

    const getColumns = () => {
        return (
            [
                {
                    Header: intl.formatMessage({ id: 'orderList.table.ID' }),
                    accessor: 'bid',
                    sortKey: 'bid',
                    filterKey: 'bid',
                    filterOperator: 'contains'
                },
                {
                    Header: intl.formatMessage({ id: 'orderList.table.status' }),
                    accessor: (rowData) => getOrderStatus(rowData.status),
                    sortKey: 'status',
                    filterKey: 'status',
                    filterComponent: 'select',
                    filterOperator: 'in',
                    filterOptions: [
                        {
                            value: OrderStatusEnum.CREATED,
                            label: intl.formatMessage({ id: 'orderDetail.historyCard.Created' }),
                        },
                        {
                            value: OrderStatusEnum.PROCESSING,
                            label: intl.formatMessage({ id: 'orderDetail.historyCard.Processing' }),
                        },
                        {
                            value: OrderStatusEnum.READY,
                            label: intl.formatMessage({ id: 'orderDetail.historyCard.Ready' }),
                        },
                        {
                            value: OrderStatusEnum.INPROGRESS,
                            label: intl.formatMessage({ id: 'orderDetail.historyCard.InProgress' }),
                        },
                        {
                            value: OrderStatusEnum.COMPLETED,
                            label: intl.formatMessage({ id: 'orderDetail.historyCard.Completed' }),
                        },
                        {
                            value: OrderStatusEnum.INCOMPLETE,
                            label: intl.formatMessage({ id: 'orderDetail.historyCard.Incomplete' }),
                        },
                        {
                            value: OrderStatusEnum.PARTIAL,
                            label: intl.formatMessage({ id: 'orderDetail.historyCard.Partial' }),
                        },
                        {
                            value: OrderStatusEnum.EXPIRED,
                            label: intl.formatMessage({ id: 'orderDetail.historyCard.Expired' }),
                        },
                        {
                            value: OrderStatusEnum.CANCELED,
                            label: intl.formatMessage({ id: 'orderDetail.historyCard.Canceled' }),
                        },
                    ]
                },
                {
                    Header: intl.formatMessage({ id: 'orderList.table.paid' }),
                    accessor: (rowData) => (rowData.isPaid) === true
                        ? intl.formatMessage({ id: 'orderList.isPaid.yes' })
                        : <Box color="#9e9e9e">{intl.formatMessage({ id: 'orderList.isPaid.no' })}</Box>,
                    sortKey: 'isPaid',
                    filterKey: 'isPaid',
                    filterComponent: 'select',
                    filterOperator: 'eq',
                    filterOptions: [
                        {
                            value: true,
                            label: intl.formatMessage({ id: 'orderList.isPaid.yes' })
                        },
                        {
                            value: false,
                            label: intl.formatMessage({ id: 'orderList.isPaid.no' })
                        },
                    ]
                },
                {
                    Header: intl.formatMessage({ id: 'orderList.table.email' }),
                    accessor: "email",
                    sortKey: "email",
                    filterKey: 'email',
                    filterOperator: 'contains'
                },
                {
                    Header: intl.formatMessage({ id: 'orderList.table.createdAt' }),
                    accessor: (rowData) => rowData.createdAt !== null ? DateTime.fromISO(rowData.createdAt).toFormat("dd.MM.yyyy HH:mm:ss") : "",
                    sortKey: 'createdAt',
                    filterKey: "createdAt",
                    filterOperator: 'between',
                    filterDataType: "dateBetween",
                    filterComponent: 'date',
                },
                {
                    Header: intl.formatMessage({ id: 'orderList.table.expiration' }),
                    accessor: (rowData) => rowData.expiresAt !== null ? DateTime.fromISO(rowData.expiresAt).toFormat("dd.MM.yyyy HH:mm:ss") : "",
                    sortKey: 'expiresAt',
                    filterKey: "expiresAt",
                    filterOperator: 'between',
                    filterDataType: "dateBetween",
                    filterComponent: 'date',
                },
                {
                    Header: intl.formatMessage({ id: 'orderList.table.credit' }),
                    accessor: (rowData) => rowData.price + " C",
                    sortKey: "price",
                    filterKey: "price",
                    filterOperator: 'eq',
                    filterDataType: 'number',
                },
                {
                    Header: intl.formatMessage({ id: 'orderList.table.type' }),
                    accessor: (rowData) => getOrderType(rowData.paymentSource),
                    sortKey: 'paymentSource',
                    filterKey: 'paymentSource',
                    filterComponent: 'select',
                    filterOperator: 'in',
                    filterOptions: [
                        {
                            value: OrderTypeEnum.CREDIT,
                            label: intl.formatMessage({ id: 'orderList.table.creditType' }),
                        },
                        {
                            value: OrderTypeEnum.CREDIT_AND_PAYMENT,
                            label: intl.formatMessage({ id: 'orderList.table.creditAndPaymentType' }),
                        },
                        {
                            value: OrderTypeEnum.PAYMENT,
                            label: intl.formatMessage({ id: 'orderList.table.paymentType' }),
                        },
                        {
                            value: OrderTypeEnum.FREEPHOTO,
                            label: intl.formatMessage({ id: 'orderList.table.freeFotoType' }),
                        },

                    ]
                },
                {
                    Header: intl.formatMessage({ id: 'orderList.table.purchaseAmount' }),
                    accessor: (rowData) => (rowData.paymentSource === OrderTypeEnum.CREDIT_AND_PAYMENT || rowData.paymentSource === OrderTypeEnum.PAYMENT)
                        ? rowData.purchaseAmount + " €"
                        : "-",
                    sortKey: "purchaseAmount",
                    filterKey: "purchaseAmount",
                    filterOperator: 'eq',
                    filterDataType: 'number',
                },
                {
                    Header: intl.formatMessage({ id: 'orderList.table.actions' }),
                    accessor: (rowData) => 
                    <div style={{ display: "flex", flexDirection: "row",  flexWrap: "wrap",
                        justifyContent: "flex-start", alignItems: "center", gap: "0.2em" }}>

                        <ButtonDetail
                        // classes={classes.tenantButtons}
                        onClick={ () => onOrderDetail(rowData.id) }
                        />

                    </div>

                }
            ]
        )
    }

    return (
        <QueryTable
            query={query}
            queryVariables={queryVariables}
            permanentFilter={defaultFilter}
            numberOfRows={numberOfRows}
            queryDataPath={queryDataPath}
            columns={(detail) ? getColumnsDetail() : getColumns()}
            toggle={toggle}
        />
    )
}

export default injectIntl(OrderTable);