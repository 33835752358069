import React from "react";
import PropTypes from "prop-types";
import Refresh from "@material-ui/icons/Refresh";
import ReactTable from "components/ReactTable/ReactTable.js";
import { pathOr } from "rambda";
import Button from "components/CustomButtons/Button.js";
import { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { useLazyQuery } from "@apollo/react-hooks";

const QueryTable = (props) => {
  const {
    columns = [],
    queryVariables = {},
    queryDataPath = [""],
    showRefreshButton = false,
    defaultSort = null,
    permanentFilter = null,
    history,
    openCollapse,
    idCollapse,
    selectedIndex,
    index,
    orFilter,
    numberOfRows = 25,
    toggle,
    query = null,
  } = props;

  const getPosition = (string, subString, index) => {
    return string.split(subString, index).join(subString).length;
  };

  //filters
  const parseQueryFilters = () => {
    const initQueryParams = history.location.search;
    const filtersRaw = initQueryParams.split("filter");
    const result = {};

    filtersRaw.map((filterRaw, index) => {
      if (index === 0) {
        return null;
      }

      const fieldName = filterRaw.substring(
        filterRaw.indexOf("[") + 1,
        filterRaw.indexOf("]")
      );

      const filterType = filterRaw.substring(
        getPosition(filterRaw, "[", 2) + 1,
        getPosition(filterRaw, "]", 2)
      );


      let filterValue = "";
      let startFilterValue = "";
      let endFilterValue = "";

      if (filterRaw.indexOf("&") > 0) {
        filterValue = filterRaw.substring(
          filterRaw.indexOf("=") + 1,
          filterRaw.indexOf("&")
        );
      } else {
        filterValue = filterRaw.substring(
          filterRaw.indexOf("=") + 1,
          filterRaw.length
        );
      }

      if (filterRaw.indexOf("[start]") > 0) {
        filterValue = filterRaw.substring(
          filterRaw.indexOf("=") + 1,
          filterRaw.length
        );
        startFilterValue = filterValue.substring(0, 10);
        endFilterValue = filterValue.substring(11, 21);
      }

      filterValue = decodeURIComponent(filterValue);
      if (!isNaN(filterValue)) {
        filterValue = parseFloat(filterValue);
      }

      if (filterType === "contains") {
        result[fieldName] = { contains: String(filterValue) }
      } else if (filterType === "between") {
        result[fieldName] = {
          between: {
            start: String(startFilterValue),
            end: String(endFilterValue + "T23:59")
          }
        }
      } else {
        if (filterValue === "true") result[fieldName] = { eq: true }
        else if (filterValue === "false") result[fieldName] = { eq: false }
        else if (filterType === "in") result[fieldName] = { in: filterValue.split(',') };
        else result[fieldName] = { eq: filterValue }
      }
      return ""

    });


    return result;

  };

  //sorts
  const parseQuerySorts = () => {
    const initQueryParams = history.location.search;
    const sortsRaw = initQueryParams.split("sort");
    const result = [];

    sortsRaw.map((sortRaw, index) => {
      if (index === 0) {
        return null;
      }

      const fieldName = sortRaw.substring(
        sortRaw.indexOf("[") + 1,
        sortRaw.indexOf("]")
      );

      let sortValue = "";
      if (sortRaw.indexOf("&") > 0) {
        sortValue = sortRaw.substring(
          sortRaw.indexOf("=") + 1,
          sortRaw.indexOf("&")
        );
      } else {
        sortValue = sortRaw.substring(sortRaw.indexOf("=") + 1, sortRaw.length);
      }

      result.push({ field: fieldName, order: sortValue });
      return ""
    });

    return result;
  };

  //TODO
  const initFilters = (toggle)
    ? null
    : parseQueryFilters();
  const initSorts = (toggle)
    ? []
    : parseQuerySorts();

  const [pageNumber, setPageNumber] = useState(0);
  const [rowsCount, setRowsCount] = useState(numberOfRows);
  const [refreshHelper, toggleRefreshHelper] = useState(numberOfRows);
  const [filters, setFilters] = useState({ ...initFilters });
  const [sorts, setSorts] = useState(initSorts);

  const [loadData, { data }] = useLazyQuery(query);
  const generateQueryParams = () => {
    const querySorts = sorts
      .map((sort) => `sort[${sort["field"]}]=` + sort["order"])
      .join("&");
      
    const queryFilters = Object.keys(filters)
      .map((key) => {
        if (filters[key]["contains"]) {
          return `filter[${key}][contains]=` + encodeURIComponent(filters[key]["contains"]);
        } else if (filters[key]["eq"]) {
          return `filter[${key}][eq]=` + encodeURIComponent(filters[key]["eq"]);
        } else if (filters[key]["eq"] === false) {
          return `filter[${key}][eq]=` + encodeURIComponent(filters[key]["eq"]);
        } else if (filters[key]["in"]) {
          return `filter[${key}][in]=` + encodeURIComponent(filters[key]["in"]);
        } else if (filters[key]["between"]) {
          return `filter[${key}][between][start]=` + encodeURIComponent(filters[key]["between"]["start"]).substring(0, 10) + `|` + encodeURIComponent(filters[key]["between"]["end"]).substring(0, 10);
        }
        return ""
      })
      .join("&");

    if (!queryFilters && !querySorts) {
      return "";
    } else if (queryFilters && !querySorts) {
      return queryFilters;
    } else if (!queryFilters && querySorts) {
      return querySorts;
    } else if (queryFilters && querySorts) {
      return `${queryFilters}&${querySorts}`;
    }
  };

  useEffect(() => {
    history.replace({
      pathname: history.location.pathname,
      search: generateQueryParams(),
    });



    let classicFilter = {
      ...filters,
      ...permanentFilter,
    }
    let operationFilter = [
      {
        ...filters,
        ...permanentFilter,
      },
      {
        "sourceId": { "eq": Number(orFilter) },
        ...filters,
        ...permanentFilter,
      },
      {
        "targetId": { "eq": Number(orFilter) },
        ...filters,
        ...permanentFilter,
      }
    ];
    loadData({
      variables: {
        ...queryVariables,
        offset: pageNumber * rowsCount,
        limit: rowsCount,
        filter: (orFilter)
          ? operationFilter
          : classicFilter,
        sort: sorts.length > 0 ? sorts : defaultSort ? defaultSort : [],
      },
    });
  }, [pageNumber, rowsCount, filters, refreshHelper, sorts]);

  const handleFilterChange = (filterKey, value, valueEnd, dataType, operator) => {
    let newFilters = { ...filters };
    if (dataType === "number" && isNaN(value)) {
      return;
    }

    if (dataType) {
      if (!value) {
        if (Array.isArray(filterKey)) {
          filterKey.map((key) => delete newFilters[key]);
        } else {
          delete newFilters[filterKey];
        }
      } else {
        if (Array.isArray(filterKey)) {
          filterKey.map((key) => {
            if (dataType === "number") {
              newFilters[filterKey] = {};
              newFilters[key][operator] = parseFloat(value);
            }
            else if (dataType === "dateBetween") {
              newFilters[filterKey] = {
                between: {
                  start: ((value === " ") || (value === null)) ? "2018-01-01" : value + "T00:00",
                  end: ((valueEnd === " ") || (valueEnd === null)) ? "2100-01-01" : valueEnd + "T23:59"
                }

              }
            }
            else {
              newFilters[filterKey] = {};
              newFilters[key][operator] = value;
            }
            return ""
          });
        }
        else {
          if (dataType === "number") {
            newFilters[filterKey] = {};
            newFilters[filterKey][operator] = parseFloat(value);
          }
          else if (dataType === "dateBetween") {
            newFilters[filterKey] = {
              between: {
                start: ((value === " ") || (value === null)) ? "2018-01-01" : value + "T00:00",
                end: ((valueEnd === " ") || (valueEnd === null)) ? "2100-01-01" : valueEnd + "T23:59"
              }
            }

          }
          else {
            newFilters[filterKey] = {};
            newFilters[filterKey][operator] = operator !== "in" ? value : [value.value];
          }
        }
      }
    } else {
      if (value === null || value === 0 || value === "") {
        if (Array.isArray(filterKey)) {
          filterKey.map((key) => delete newFilters[key]);
        } else {
          delete newFilters[filterKey];
        }
      } else {
        if (Array.isArray(filterKey)) {
          filterKey.map((key) => {
            newFilters[filterKey] = {};
            newFilters[key][operator] = value;
            return ""
          });
        }

        else {
          newFilters[filterKey] = {};
          newFilters[filterKey][operator] = value;
        }

      }
      if (Array.isArray(value)) {
        if (value?.length === 0) {
          newFilters[filterKey] = {};
        }

      }
    }
    setFilters(newFilters);


  };

  const handleSortChange = (sortKey, canSort) => {
    const newSorts = [...sorts];
    if (!canSort) {
      return;
    }

    if (newSorts.length > 0) {
      if (newSorts[0].field === sortKey) {
        if (newSorts[0].order === "ASC") {
          newSorts[0].order = "DESC";
        } else if (newSorts[0].order === "DESC") {
          newSorts.splice(0, 1);
        }
      } else {
        newSorts.splice(0, 1);
        newSorts.push({
          field: sortKey,
          order: "ASC",
        });
      }
    } else {
      newSorts.push({
        field: sortKey,
        order: "ASC",
      });
    }

    setSorts(newSorts);
  };

  const onPageChange = (page) => {
    setPageNumber(page);
  };

  const onRowsCountChange = (rowsCount) => {
    setRowsCount(rowsCount);
  };

  const tableData = pathOr([], queryDataPath, data);
  const totalCount = pathOr(0, [queryDataPath[0], "totalCount"], data);

  return (
    <>
      {showRefreshButton && (
        <div style={{ display: "flex" }}>
          <Button
            style={{ marginLeft: "auto" }}
            size="sm"
            onClick={() => toggleRefreshHelper(!refreshHelper)}
            color="primary"
          >
            <Refresh />
          </Button>
        </div>
      )}
      <ReactTable
        columns={columns}
        data={tableData}
        filters={filters}
        handleFilterChange={handleFilterChange}
        sorts={sorts}
        handleSortChange={handleSortChange}
        currentPage={pageNumber}
        rowsPerPage={rowsCount}
        totalCount={totalCount}
        onPageChange={(page) => {
          onPageChange(page);
        }}
        onRowsCountChange={(rows) => {
          onRowsCountChange(rows);
        }}

        openCollapse={openCollapse}
        numberOfRows={numberOfRows}
        idCollapse={idCollapse}
        selectedIndex={selectedIndex}
        index={index}
        
      />
    </>
  );
};

QueryTable.propTypes = {
  query: PropTypes.any,
};

export default withRouter(QueryTable);
