import gql from 'graphql-tag';

const ORDERS_BY_USER = (id) => gql`
  query ordersTableByUser($offset: Int, $limit: Int, $sort: [OrdersTableSortInput!], $filter: [OrdersTableFilterInput]){
    ordersTableByUser(userId: ${id}, offset:$offset, limit: $limit, sort: $sort, filter: $filter){
    totalCount,  
    items{
        id
        bid
        price
        expiresAt
        createdAt
        status
        isPaid    
      }
    }
  }
`;

export default ORDERS_BY_USER;
