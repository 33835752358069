import gql from 'graphql-tag';

const ALL_TOPICS = gql`
query topics(
  $offset: Int, 
  $limit: Int,
  $sort:[TopicSortInput!],
  $filter:[TopicFilterInput]
){
    topics(offset:$offset, limit: $limit, sort: $sort, filter: $filter){
      totalCount
      items{
        id
      	name
      }
    }
  }
`;

export default ALL_TOPICS;