import { Grid } from "@material-ui/core";
import InfoIcon from '@material-ui/icons/Info';
import IconCard from "components-lib/IconCard/IconCard";
import React from "react";
import { injectIntl } from "react-intl";
import FormikField from "components-lib/FormikField/FormikField";
import { useQuery } from "@apollo/react-hooks";
import ALL_COMPANIES from "queries/CompaniesQueries/selectCompanies"
import { pathOr } from "rambda";

const InfoCard = (props) => {
    const {
        intl,
        formikProps,
        callbackCompanyId
    } = props;

    const { data: companiesData } = useQuery(ALL_COMPANIES)
    let copmanyId = (formikProps.values.campaign?.companyId) ? (formikProps.values.campaign?.companyId) : null
    if (copmanyId) callbackCompanyId(copmanyId)

    const getCompaniesOptions = () => {
        const comapniesOptions = [];
        pathOr([], ['companies', 'items'], companiesData).map(company => {
            return (comapniesOptions.push({
                value: pathOr("", "id", company),
                label: `${pathOr("", "name", company)}`,
            }))
        })
        return comapniesOptions
    }

    return <>
        <IconCard
            title={intl.formatMessage({ id: 'campaigns.infoCard.title' })}
            icon={<InfoIcon />}
        >
            <Grid container>
                <Grid item sm={12}>
                    <FormikField
                        type="select"
                        name="campaign.companyId"
                        labelText={intl.formatMessage({ id: 'campaigns.infoCard.company' })}
                        options={getCompaniesOptions()}
                        formikProps={formikProps}
                        highlightChange
                    />
                </Grid>
                <Grid item sm={12}>
                    <FormikField
                        name="campaign.name"
                        labelText={intl.formatMessage({ id: 'campaigns.infoCard.name' })}
                        formikProps={formikProps}
                        data={formikProps.values}
                        highlightChange
                    />
                </Grid>
                <Grid container spacing={3}>
                    <Grid item sm={6}>
                        <br />
                        <FormikField
                            name="campaign.validFrom"
                            labelText={intl.formatMessage({ id: 'campaigns.infoCard.startDate' })}
                            formikProps={formikProps}
                            data={formikProps.values}
                            highlightChange
                            type="picker2"
                        />
                    </Grid>
                    <Grid item sm={6}>
                        <br />
                        <FormikField
                            name="campaign.validTo"
                            labelText={intl.formatMessage({ id: 'campaigns.infoCard.endDate' })}
                            formikProps={formikProps}
                            data={formikProps.values}
                            highlightChange
                            type="picker2"
                        />
                    </Grid>
                </Grid>
                <Grid item sm={12}>
                    <br />
                    <FormikField
                        name="campaign.description"
                        labelText={intl.formatMessage({ id: 'campaigns.infoCard.description' })}
                        formikProps={formikProps}
                        data={formikProps.values}
                        highlightChange
                    />
                </Grid>
            </Grid>
        </IconCard>
    </>
};

export default injectIntl(InfoCard);
