import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import IconCard from "components-lib/IconCard/IconCard";
import Button from "components/CustomButtons/Button.js";
import React from "react";
import { injectIntl } from "react-intl";
import { generatePath, withRouter } from "react-router-dom";
import paths from "paths";
import styles from "common/styles/widgets.js";
import Box from "@material-ui/core/Box";
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';

const useStyles = makeStyles((theme) => styles(theme));

const OrderCustomerCard = (props) => {
  const {
    history,
    intl,
    fullName,
    email,
    phone,
    userId
  } = props;
  const classes = useStyles();

  return (
    <>
      <IconCard
        title={intl.formatMessage({ id: "orderDetail.customerCard.title" })}
        icon={<AssignmentIndIcon />}>
        <Grid container spacing={2}>
          <Grid item sm={12}>
            {intl.formatMessage({ id: "orderDetail.customerCard.name" })}
            <Box className={classes.floatRight}>
              {fullName}
            </Box>
          </Grid>
          <Grid item sm={12}>
            {intl.formatMessage({ id: "orderDetail.customerCard.email" })}
            <Box className={classes.floatRight}>
              {(email) ? (email) : "-"}
            </Box>
          </Grid>
          <Grid item sm={12}>
            {intl.formatMessage({ id: "orderDetail.customerCard.phone" })}
            <Box className={classes.floatRight}>
              {(phone) ? phone : "-"}
            </Box>
          </Grid>
        </Grid>

        <Grid item sm={12}>
          <hr />
          <Button
            className={classes.floatRight}
            color="info"
            size="sm"
            round
            onClick={() => { history.push(`/admin${generatePath(paths.users.detail, { userId: userId })}`) }}
          >
            {intl.formatMessage({ id: "orderDetail.customerCard.userDetail" })}
          </Button>
        </Grid>
      </IconCard>
    </>
  );
};

export default withRouter(injectIntl(OrderCustomerCard));