import QueryTable from "components-lib/QueryTable/QueryTable";
import paths from "paths";
import React from "react";
import { injectIntl } from "react-intl";
import { generatePath } from "react-router-dom";
import { pathOr } from "rambda";
import { getUserToken } from "helpers/helpers";
import { useSnackbar } from "notistack";
import Grid from "@material-ui/core/Grid";
import GetAppIcon from '@material-ui/icons/GetApp';
import { makeStyles } from '@material-ui/core/styles';
import styles from "common/styles/widgets.js";
import { apiHost } from "configFiles/config";

const useStyles = makeStyles((theme) => styles(theme))

export const CouponTable = (props) => {
    const {
        history,
        query,
        intl,
        queryDataPath,
        numberOfRows,
        defaultFilter,
        pqrType,
    } = props;
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();

    const onDetail = (id) => {
        history.push(`/admin${generatePath(paths.users.detail, { userId: id })}`);
    };

    const handleExportCoupon = async (couponId) => {
        /*axios.get(`https://api.dev.fotomat.cultify.studio/pages/coupon/30194/qrcode`, {
            headers: {
                Authorization: String(getUserToken()),
            }
        })
            .then((response) => {
                console.log(response.data);
                enqueueSnackbar(intl.formatMessage({ id: 'campaigns.coupons.couponSetExport' }), { variant: "success" });
                window.open(response);
            })
            .catch(() => {
                console.log(getUserToken());
            });*/

        let url = `${apiHost}/pages/coupon/${couponId}/qrcode`
        const result = await fetch(url, {
            headers: {
                Authorization: String(getUserToken()),
            }
        })
        const blob = await result.blob()
        const href = window.URL.createObjectURL(blob)

        var downloadLink = document.createElement("a");
        downloadLink.href = href;
        downloadLink.download = `coupon_${couponId}.svg`;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    }

    const pqrColumns = () => {
        return [
            {
                Header: intl.formatMessage({ id: "campaigns.table.ID" }),
                accessor: "bid",
                sortKey: "bid",
                filterKey: "bid",
                filterOperator: "contains",
            },
            {
                Header: intl.formatMessage({
                    id: "campaigns.table.couponNominalValueDetail",
                }),
                accessor: (rowData) => rowData.value + " C",
                sortKey: "value",
                filterKey: "value",
                filterOperator: "eq",
                filterDataType: "number",
            },
            {
                Header: intl.formatMessage({ id: "campaigns.table.couponDate" }),
                accessor: (rowData) =>
                    rowData.appliedAtTimestamp ? (
                        new Date(pathOr(0, "appliedAtTimestamp", rowData)).toLocaleString(
                            "sk"
                        )
                    ) : (
                        <p> - </p>
                    ),
                sortKey: "appliedAtTimestamp",
                filterKey: "appliedAtTimestamp",
                filterOperator: "between",
                filterDataType: "dateBetween",
                filterComponent: "date",
            },
            {
                Header: intl.formatMessage({ id: "campaigns.table.couponUser" }),
                accessor: (rowData) =>
                    rowData.appliedByUser ? (
                        <span
                            style={{ textDecoration: "underline", cursor: "pointer" }}
                            onClick={() => onDetail(rowData.appliedByUserId)}
                        >
                            {" "}
                            {rowData.appliedByUser}
                        </span>
                    ) : (
                        <p> - </p>
                    ),
                sortKey: "appliedByUser",
                filterKey: "appliedByUser",
                filterOperator: "contains",
            },
            {
                Header: intl.formatMessage({ id: "campaigns.table.couponToken" }),
                accessor: "qrToken",
            },
            {
                Header: intl.formatMessage({ id: "campaigns.table.actions" }),
                accessor: (rowData) => (
                    <Grid container spacing={2}>
                        <GetAppIcon
                            className={classes.pointer}
                            onClick={() => handleExportCoupon(rowData.id)}
                        />
                    </Grid>
                ),
            },
        ];
    };

    const columns = () => {
        return [
            {
                Header: intl.formatMessage({ id: "campaigns.table.ID" }),
                accessor: "bid",
                sortKey: "bid",
                filterKey: "bid",
                filterOperator: "contains",
            },
            {
                Header: intl.formatMessage({
                    id: "campaigns.table.couponStatusDetail",
                }),
                accessor: (rowData) =>
                    rowData.status === "Used" ? (
                        <p style={{ color: "green" }}>
                            {intl.formatMessage({ id: "campaigns.table.coupon.used" })}
                        </p>
                    ) : (
                        <p style={{ color: "gray" }}>
                            {intl.formatMessage({ id: "campaigns.table.coupon.unused" })}
                        </p>
                    ),
                sortKey: "status",
                filterKey: "status",
                filterComponent: "select",
                filterOperator: "eq",
                filterOptions: [
                    {
                        value: "Used",
                        label: (
                            <p style={{ color: "green" }}>
                                {intl.formatMessage({ id: "campaigns.table.coupon.used" })}
                            </p>
                        ),
                    },
                    {
                        value: "Unused",
                        label: (
                            <p style={{ color: "gray" }}>
                                {intl.formatMessage({ id: "campaigns.table.coupon.unused" })}
                            </p>
                        ),
                    },
                ],
            },
            {
                Header: intl.formatMessage({
                    id: "campaigns.table.couponNominalValueDetail",
                }),
                accessor: (rowData) => rowData.value + " C",
                sortKey: "value",
                filterKey: "value",
                filterOperator: "eq",
                filterDataType: "number",
            },
            {
                Header: intl.formatMessage({ id: "campaigns.table.couponDate" }),
                accessor: (rowData) =>
                    rowData.appliedAtTimestamp ? (
                        new Date(pathOr(0, "appliedAtTimestamp", rowData)).toLocaleString(
                            "sk"
                        )
                    ) : (
                        <p> - </p>
                    ),
                sortKey: "appliedAtTimestamp",
                filterKey: "appliedAtTimestamp",
                filterOperator: "between",
                filterDataType: "dateBetween",
                filterComponent: "date",
            },
            {
                Header: intl.formatMessage({ id: "campaigns.table.couponUser" }),
                accessor: (rowData) =>
                    rowData.appliedByUser ? (
                        <span
                            style={{ textDecoration: "underline", cursor: "pointer" }}
                            onClick={() => onDetail(rowData.appliedByUserId)}
                        >
                            {" "}
                            {rowData.appliedByUser}
                        </span>
                    ) : (
                        <p> - </p>
                    ),
                sortKey: "appliedByUser",
                filterKey: "appliedByUser",
                filterOperator: "contains",
            },
            {
                Header: intl.formatMessage({ id: "campaigns.table.couponToken" }),
                accessor: "qrToken",
            },
            {
                Header: intl.formatMessage({ id: "campaigns.table.actions" }),
                accessor: (rowData) => (
                    <Grid container spacing={2}>
                        <GetAppIcon
                            className={classes.pointer}
                            onClick={() => handleExportCoupon(rowData.id)}
                        />
                    </Grid>
                ),
            },
        ];
    };

    return (
        <>
            <QueryTable
                query={query}
                queryDataPath={queryDataPath}
                numberOfRows={numberOfRows}
                permanentFilter={defaultFilter}
                columns={pqrType ? pqrColumns() : columns()}
            />
        </>
    );
};

export default injectIntl(CouponTable);
