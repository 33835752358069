import React, { useState } from "react";

import Checkbox from '@material-ui/core/Checkbox';
import { Grid } from '@material-ui/core';

import {defaultColors, check} from "common/styles/configLayout";

const CheckBoxField = (props) => {
    const { 
        value, 
        checked = true, 
        label,

        onChecked,
        page,
        status
    } = props;

    const handleChange = (event) => {
        onChecked( event.target.checked, value );
    };

    const [color, setColor] = useState(false);

    const styleBox = () => { 
        if ( value === 'na' || value === 'men' || value === 'women'  ) { 
            return {
                color: "rgba(0, 0, 0, 0.8)",
              };
        } else {
            return {
                color: defaultColors.one,
            };    
        }
    }

    const styleStep4 = checked ? { color: "rgba(0, 0, 0, 0.8)", backgroundColor:"#FFF8CC" } : { color: defaultColors.two, };
    const styleDetail = color ? { color: "rgba(0, 0, 0, 0.8)", backgroundColor:"#FFF8CC" } : { color: "rgba(0, 0, 0, 0.8)", };

    const rejectedStyle = { color: "rgba(0, 0, 0, 0.38)" };

    const checkboxStyleStep4 = checked ? styleBox() : { color: defaultColors.two };
    const checkboxStyleDetail = checked ? styleBox() : { color: defaultColors.one };

    const rejectedCheckboxStyle = { color: "rgba(0, 0, 0, 0.38)" };

    const handleToggle = () => {
        setColor(!color);
    };

    return (   

        <Grid 
            style={ ( (status === 'Rejected' && checked === false ) || ( status === 'Finished' && checked === false ) || ( status === 'Inactive' && checked === false ) ) ? rejectedStyle : (page === 'page4' ? styleStep4 : styleDetail) } 
        >            
           
            <Checkbox 
                checked={ checked }
                onChange={ handleChange }
                className={{
                    root: checked ? 'kedCheckbox' : '', // Add class when checked
                  }}
                // style={ page === 'page4' ? checkboxStyleStep4 : checkboxStyleDetail }
                style={ (status === 'Rejected' || status === 'Finished' || status === 'Inactive') ? rejectedCheckboxStyle : (page === 'page4' ? checkboxStyleStep4 : checkboxStyleDetail)}
                onClick={ handleToggle }
                disabled={ status === 'Rejected' || status === 'Finished' || status === 'Inactive' }
            />

            {label}           
            
        </Grid>  
    );
};

export default CheckBoxField;