import gql from 'graphql-tag';

const COUPON_CAMPAIGN_CANCEL = gql`
    mutation couponCampaignCancel($id: ID!){
        couponCampaignCancel(id:$id){
            id
        	name
        }
    }
`;

export default COUPON_CAMPAIGN_CANCEL;

