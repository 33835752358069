import React, { useState, useEffect } from "react";
import { injectIntl } from "react-intl";
import { withRouter } from "react-router-dom";
import PageHeader from "components-lib/PageHeader/PageHeader";
import { Grid } from "@material-ui/core";
import { useMutation, useQuery, useLazyQuery } from "@apollo/react-hooks";
import { pathOr } from "rambda";
import DetailCompanyCard from "./CampaignCards/DetailCompanyCard";
import DetailChartCouponsCard from "./CampaignCards/DetailChartCouponsCard";
import DetailInfoCard from "./CampaignCards/DetailInfoCard";
import { makeStyles } from "@material-ui/core/styles";
import styles from "common/styles/widgets.js";
import Modal from "components-lib/Modal/Modal";
import Button from "components/CustomButtons/Button.js";
import { useSnackbar } from "notistack";
import paths from "paths";
import CampaignStatusEnum from "common/enums/CampaignStatusEnum";
import Loading from "components-lib/Loading/Loading";
import { generatePath } from "react-router-dom";
import IconCard from "components-lib/IconCard/IconCard";
import CampaignTypeEnum from "common/enums/CampaignType";
import CouponTable from "./CouponTable";
import Box from "@material-ui/core/Box";
import FormikField from "components-lib/FormikField/FormikField";
import ALL_COUPON_SETS from "queries/CampaignsQueries/allCouponSets"
import CAMPAIGN_DETAILS from "queries/CampaignsQueries/campaignDetails";
import COUPON_CAMPAIGN_DETAIL from "queries/CampaignsQueries/couponCampaignDetail";
import CAMPAIGN_CANCEL from "queries/CampaignsQueries/campaignCancel";
import COUPON_CAMPAIGN_CANCEL from "queries/CampaignsQueries/couponCampaignCancel";
import COUPON_CAMPAIGN_ADD_BUDGET from "queries/CampaignsQueries/couponCampaignAddBudget";
import CAMPAIGN_UPDATE from "queries/CampaignsQueries/campaignUpdate";
import CAMPAIGN_APPROVE from "queries/CampaignsQueries/campaignApprove";
import CAMPAIGN_REJECT from "queries/CampaignsQueries/campaignReject";
import COUPON_SET_ASSIGNED from "queries/CampaignsQueries/couponSetAssigned";
import ALL_STORES_CAMPAIGN from "queries/CampaignsQueries/allStoresCampaign";
import { Formik } from "formik";
import { validationCreditUp, validationQRCouponSet, validationPQRCouponSet } from './validationSchema';
import QRCampaignsIcon from 'components-lib/Icons/QRCampaignsIcon/QRCampaignsIcon';
import DetailBudgetCard from "./CampaignCards/DetailBudgetCard";
import DetailBannersCard from "./CampaignCards/DetailBannersCard";
import DetailWatermarkCard from "./CampaignCards/DetailWatermarkCard";
import DetailLocationsCard from './CampaignCards/DetailLocationsCard'
import DetailHoursCard from './CampaignCards/DetailHoursCard'
import { getValidationSchemaDetail } from "./validationSchema";
// import CAMPAIGNS_STORES from "queries/CampaignsQueries/campaignsStores";
import DetailStoreCard from "pages/Stores/StoreCards/DetailStoreCard";
import StoreCard from "pages/Devices/DetailCards/StoreCard";
import StoreListPage from "pages/Stores/StoreListPage";
import StoreTable from "pages/Stores/StoreTable";
import GenderCheckBox from 'components-lib/GenderCheckBox/GenderCheckBox';
import WcIcon from "@material-ui/icons/Wc";

import CampaignStoreTable from "./CampaignStoreTable";
import StoreIcon from '@material-ui/icons/Store';
import { isConstructorDeclaration } from "typescript";

import Business from "@material-ui/icons/Business";


const useStyles = makeStyles((theme) => styles(theme));

const CampaignDetailPage = (props) => {
    const { intl, history } = props;
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const campaignId = props.match.params.campaignId
    const [userData] = useState({});

    let campaignType = ""
    let url = window.location.pathname;

    if (url.includes("freephoto-campaigns")) campaignType = CampaignTypeEnum.FREEPHOTO
    else if (url.includes("coupon-campaigns/qr")) campaignType = CampaignTypeEnum.QR
    else campaignType = CampaignTypeEnum.PQR


    let query = (campaignType === CampaignTypeEnum.FREEPHOTO) ? CAMPAIGN_DETAILS : COUPON_CAMPAIGN_DETAIL
    let pathType = (campaignType === CampaignTypeEnum.FREEPHOTO) ? 'campaign' : 'couponCampaign'

    let backButton = (history.length > 1) ? true : false

    //Hooks
    const [removeCampaignModalOpen, setRemoveCampaignModalOpen] = useState(false)
    const [approveCampaignModalOpen, setApproveCampaignModalOpen] = useState(false)
    const [rejectCampaignModalOpen, setRejectCampaignModalOpen] = useState(false)
    const [couponSetAssignedModal, setCouponSetAssignedModal] = useState(false)
    const [upCreditModal, setUpCreditModal] = useState(false)
    const [upCreditConfirmationModal, setUpCreditConfirmationModal] = useState(false)
    const [couponSetConfirmationModal, setCouponSetConfirmationModal] = useState(false)
    const [couponSets, setCouponSets] = useState([]);
    const [selectedCouponSet, setSelectedCouponSet] = useState(null);
    const [upCreditValues, setUpCreditValues] = useState(null);
    const [storesData, setStoresData] = useState(null);
    //Queries and mutations

    const { data: campaignDataTable, loading: campaignLoadingTable, refetch: refetchDataTable } = useQuery(ALL_STORES_CAMPAIGN, {
    variables: {
        campaignId: campaignId,          
    },
    });

    const { data: campaignData, loading: campaignLoading, refetch: refetchData } = useQuery(query, { variables: { id: campaignId } });
    let companyBalance = pathOr('', [pathType, 'company', 'account', 'balance'], campaignData)
    const [times, setTimes] = useState(pathOr([], [pathType, 'activeTimes'], campaignData));
    const [loadCouponSets, { data: dataCouponSets, refetch: refetchCouponsSet }] = useLazyQuery(ALL_COUPON_SETS);
    const [campaignCancel] = useMutation(CAMPAIGN_CANCEL)
    const [couponCampaignCancel] = useMutation(COUPON_CAMPAIGN_CANCEL)
    const [couponSetAssigned] = useMutation(COUPON_SET_ASSIGNED)
    const [couponCampaignAddBudget] = useMutation(COUPON_CAMPAIGN_ADD_BUDGET)
    const [updateCampaign] = useMutation(CAMPAIGN_UPDATE);
    const [campaignApprove] = useMutation(CAMPAIGN_APPROVE)
    const [campaignReject] = useMutation(CAMPAIGN_REJECT)
    //GraphQL get stores

    // 
    // handle gender and age ///////////////////////////////////////////
    // 
    const criteria = pathOr([], ['campaign', 'criteria' ], campaignData);
    const [checkedMen, setCheckedMen] = useState( );
    const [checkedWomen, setCheckedWomen] = useState( );
    const [checkedNA, setCheckedNA] = useState(  );

    
    const [ rangeMale, setRangeMale ] = useState([]);
    const [ rangeFemale, setRangeFemale ] = useState([]);
    const [ rangeUnknownGender, setRangeUnknownGender ] = useState([]);

    const [rangeMaleChecked, setRangeMaleChecked] = useState({
      R13_18: false, R19_34: false, R35_49: false, R50_: false,
    });
  
    const [rangeFemaleChecked, setRangeFemaleChecked] = useState({
      R13_18: false, R19_34: false, R35_49: false, R50_: false,
    });

    const [rangeUnknownGenderChecked, setRangeUnknownGenderChecked] = useState({
      R13_18: false, R19_34: false, R35_49: false, R50_: false,
    });

    useEffect( () => {
        criteria.map((gender) => { setRangeMale(gender.genderMaleAge)} )
        criteria.map((gender) => { setRangeFemale(gender.genderFemaleAge)} )
        criteria.map((gender) => { setRangeUnknownGender(gender.unknownGenderAge)} )
    },[ criteria ])
   
    useEffect( () => {
        handleChecked( rangeMale, rangeMaleChecked, setRangeMaleChecked );
        handleChecked( rangeFemale, rangeFemaleChecked, setRangeFemaleChecked );
        handleChecked( rangeUnknownGender, rangeUnknownGenderChecked, setRangeUnknownGenderChecked );
        ( rangeMale.length > 0 && rangeMale[0] !== 'Unknown' ) ? setCheckedMen( true ) : setCheckedMen( false ); 
        ( rangeFemale.length > 0 && rangeFemale[0] !== 'Unknown' ) ? setCheckedWomen( true ) : setCheckedWomen( false ); 
        ( rangeUnknownGender.length > 0 && rangeUnknownGender[0] !== 'Unknown' ) ? setCheckedNA( true ) : setCheckedNA( false ); 
                
    },[ rangeMale, rangeFemale, rangeUnknownGender ])
    

    const handleChecked = ( range, rangeChecked, setRangeChecked ) => {
        const updatedRangeChecked = { ...rangeChecked };

        // Loop through the keys in rangeMaleChecked
        Object.keys(updatedRangeChecked).forEach(key => {
        // Check if the value exists in rangeMale
            if (range.includes(key)) {
                updatedRangeChecked[key] = true;
            } else {
                updatedRangeChecked[key] = false;
            }
        });
        // Update the state
        setRangeChecked(updatedRangeChecked);
    }
    
    // handle if click checkBox men, woman, N/A
    const handleCheck = ( boolean, value ) => {
        if ( value === "men" ) {
            !boolean ? setCheckedMen( false ) : setCheckedMen( true );
        } else if ( value === "women" ) {
            !boolean ? setCheckedWomen( false ) : setCheckedWomen( true );
        } else if ( value === "na" ) {
            !boolean ? setCheckedNA( false ) : setCheckedNA( true );
        }
    }

    // set all age checkBox false
    const handleFalseAll = ( setRangeChecked, setRange ) => {
        setRangeChecked(prevState => ({
        R13_18: false, R19_34: false, R35_49: false, R50_: false,
        }));
        setRange([])
    };  
    
    // set all age checkBox true
    const handleTrueAll = ( setRangeChecked, setRange ) => {
        setRangeChecked( prevState => ({
        R13_18: true, R19_34: true, R35_49: true, R50_: true,
        }));
        setRange(['R13_18', 'R19_34', 'R35_49', 'R50_'])
    };

    // handle checkBox men, women, N/A
    const handleCheckedAge = ( boolean, value ) => {
        if ( value === "men" ) {
            handleCheck( boolean, value );
            if ( boolean === true ) {  // if true set male true
                handleTrueAll( setRangeMaleChecked, setRangeMale )
            } else if ( boolean === false ) {
                handleFalseAll( setRangeMaleChecked, setRangeMale )
            }
        } else if ( value === "women" ) { // if true set women true
            handleCheck( boolean, value );
            if ( boolean === true ) {
                handleTrueAll( setRangeFemaleChecked, setRangeFemale )
            } else if ( boolean === false ) {
                handleFalseAll( setRangeFemaleChecked, setRangeFemale )
            }
        } else if ( value === "na" ) { // if true set N/A true
            handleCheck( boolean, value );      
            if ( boolean === true ) {
                handleTrueAll( setRangeUnknownGenderChecked, setRangeUnknownGender )
            } else if ( boolean === false ) {
                handleFalseAll( setRangeUnknownGenderChecked, setRangeUnknownGender )      
            }
        }
    }

    // handle checkBox age, checked or not
    const handleCheckBox = ( boolean, value ) => {

        if ( value === 'menR13_18' ) handleCheckBoxToggle('R13_18', rangeMaleChecked, setRangeMaleChecked, setRangeMale );
        if ( value === 'menR19_34' ) handleCheckBoxToggle('R19_34', rangeMaleChecked, setRangeMaleChecked, setRangeMale );
        if ( value === 'menR35_49' ) handleCheckBoxToggle('R35_49', rangeMaleChecked, setRangeMaleChecked, setRangeMale );
        if ( value === 'menR50_' ) handleCheckBoxToggle('R50_', rangeMaleChecked, setRangeMaleChecked, setRangeMale );

        if ( value === 'womenR19_34' ) handleCheckBoxToggle('R19_34', rangeFemaleChecked, setRangeFemaleChecked, setRangeFemale );
        if ( value === 'womenR13_18' ) handleCheckBoxToggle('R13_18', rangeFemaleChecked, setRangeFemaleChecked, setRangeFemale );
        if ( value === 'womenR35_49' ) handleCheckBoxToggle('R35_49', rangeFemaleChecked, setRangeFemaleChecked, setRangeFemale );
        if ( value === 'womenR50_' ) handleCheckBoxToggle('R50_', rangeFemaleChecked, setRangeFemaleChecked, setRangeFemale );
        
        if ( value === 'naR19_34' ) handleCheckBoxToggle('R19_34', rangeUnknownGenderChecked, setRangeUnknownGenderChecked, setRangeUnknownGender );
        if ( value === 'naR13_18' ) handleCheckBoxToggle('R13_18', rangeUnknownGenderChecked, setRangeUnknownGenderChecked, setRangeUnknownGender );
        if ( value === 'naR35_49' ) handleCheckBoxToggle('R35_49', rangeUnknownGenderChecked, setRangeUnknownGenderChecked, setRangeUnknownGender );
        if ( value === 'naR50_' ) handleCheckBoxToggle('R50_', rangeUnknownGenderChecked, setRangeUnknownGenderChecked, setRangeUnknownGender );
    }

    // remove value from array
    const handleRemoveValue = ( setRange ,valueToRemove ) => {
        setRange(prevRange => prevRange.filter(value => value !== valueToRemove));
    };

    // toogle if click on age from to
    const handleCheckBoxToggle = (value, rangeChecked , setRangeChecked, setRange ) => {
        setRangeChecked(prevState => ({
        ...prevState,
        [value]: !prevState[value]
        }));

        // remove value if false or ad value
        // const numericValue = `R${value.substring(5)}`;
        const numericValue = `${value}`;
        if (rangeChecked[value]) {
        handleRemoveValue( setRange, numericValue )
        } else {
        setRange(prevRange => [...prevRange, `${numericValue}`]);
        }
    };

     // if do not have checkbox of age, set false checkbox male or female or na
    useEffect( () => { 
        rangeMale.length === 0 ? handleCheck( false, "men" ) : handleCheck( true, "men" );
        rangeFemale.length === 0 ? handleCheck( false, "women" ) : handleCheck( true, "women" );
    }, [ rangeMale, rangeFemale ])

    // if do not have checkbox of age, set false checkbox male or female or na
    useEffect( () => {
        rangeUnknownGender.length === 0 ? handleCheck( false, "na" ) : handleCheck( true, "na" ); 
    }, [ rangeUnknownGender ])

// ////////////////////////////////////////////////////////////////////////////



    
    let status = pathOr('', [pathType, 'status'], campaignData)
    let balance = (campaignType === CampaignTypeEnum.PQR) ? pathOr(0, [pathType, 'budget', 'total'], campaignData) : pathOr(0, [pathType, 'budget', 'free'], campaignData)
    
    const { data: unassignedCoupons, refetch: refetchCouponSets } = useQuery(ALL_COUPON_SETS,
        {
            variables: {
                filter: {
                    status: {
                        eq: "Unassigned"
                    },
                    totalSetValue: {
                        lte: balance
                    },
                    type: {
                        "eq": (campaignType === CampaignTypeEnum.QR) ? "Qr" : "PublicQr"
                    }

                }
            }
        }
    );
    /*useEffect(()=>{
        if(campaignData){
            //console.log(campaignData.campaign.company.stores)
            setStoresData(campaignData.campaign.company.stores)
        }
    })*/
    
    
    
    useEffect(() => {
        //setType(appType.state)
        let couponsSelectItems = pathOr([], "couponSetsTable.items", unassignedCoupons).map(
            (coupon) => {
                return {
                    value: pathOr("", "id", coupon),
                    label: `${(campaignType === CampaignTypeEnum.QR)
                        ? intl.formatMessage({ id: 'campaigns.unassignedCoupons.couponSet' })
                        : intl.formatMessage({ id: 'campaigns.unassignedCoupons.PQRcouponSet' })
                        } 
                        ${pathOr("", "bid", coupon)} - 
                        ${intl.formatMessage({ id: 'campaigns.unassignedCoupons.value' })}: 
                        ${pathOr("", "totalSetValue", coupon)} C`,
                    sum: pathOr("", "totalSetValue", coupon)
                };
            }
        );
        setCouponSets(couponsSelectItems);
    }, [unassignedCoupons, intl, campaignType]);

    //API operations

    const handleUpdateCampaign = (values) => {

        let tms
        if (times === true || times.length === 0) {
            tms = (pathOr([], ['campaign', 'activeTimes'], campaignData))
            for (let i = 0; i < tms.length; i++) {
                setTimes(delete tms[i].__typename);

            }
        }

        for (let i = 0; i < times.length; i++) {
            setTimes(delete times[i].__typename);
        }

        const result = { ...values };
        delete result.id;
        delete result.bid;
        delete result.__typename;
        // let arr = [ {
        //         variables: {
        //             id: campaignId,
        //             input: {
        //                 description: values.campaign.description,
        //                 validTo: values.campaign.validTo,
        //                 minRepeatInterval: (values.campaign.minRepeatInterval) ? (values.campaign.minRepeatInterval) : 0,
        //                 maxBid: values.campaign.maxBid,
        //                 activeTimes: (tms) ? tms : times,
        //                 criteria: {
        //                     locationIds: values.campaign.criteria[0].locations ? values.campaign.criteria[0].locations.map( loc => loc.id ) : [],
        //                     segmentIds: values.campaign.criteria[0].segments ? values.campaign.criteria[0].segments.map( seg => seg.id ) : [],
        //                     companyIds: values.campaign.criteria[0].companies ? values.campaign.criteria[0].companies.map( com => com.id ) : [],
        //                     zoneIds: values.campaign.criteria[0].zones ? values.campaign.criteria[0].zones.map( zon => zon.id ) : [],
        //                     genderMaleAge: rangeMale,
        //                     genderFemaleAge: rangeFemale,
        //                     unknownGenderAge: rangeUnknownGender,
        //                 }
        //             }
        //         }
        //     } ]
        // console.log( values )
        // console.log( arr )
        updateCampaign({
            variables: {
                id: campaignId,
                input: {
                    description: values.campaign.description,
                    validTo: values.campaign.validTo,
                    minRepeatInterval: (values.campaign.minRepeatInterval) ? (values.campaign.minRepeatInterval) : 0,
                    maxBid: values.campaign.maxBid,
                    activeTimes: (tms) ? tms : times,
                    criteria: {
                        locationIds: values.campaign.criteria[0].locations ? values.campaign.criteria[0].locations.map( loc => loc.id ) : [],
                        segmentIds: values.campaign.criteria[0].segments ? values.campaign.criteria[0].segments.map( seg => seg.id ) : [],
                        companyIds: values.campaign.criteria[0].companies ? values.campaign.criteria[0].companies.map( com => com.id ) : [],
                        zoneIds: values.campaign.criteria[0].zones ? values.campaign.criteria[0].zones.map( zon => zon.id ) : [],
                        genderMaleAge: rangeMale,
                        genderFemaleAge: rangeFemale,
                        unknownGenderAge: rangeUnknownGender,
                    }
                }
            }
        }).then((response) => {
            enqueueSnackbar(intl.formatMessage({ id: 'campaigns.update.success' }), { variant: 'success' });
            // refetchData();
        }).catch((err) => {
            console.log(err);
        });

    };

    const removeCampaign = () => {
        if (campaignType === CampaignTypeEnum.FREEPHOTO) {
            campaignCancel({
                variables: {
                    id: campaignId,
                }
            }).then(response => {
                history.push(`/admin${generatePath(paths.campaigns.FreePhoto)}`)
                enqueueSnackbar(intl.formatMessage({ id: 'campaigns.cancel.response.success' }), { variant: 'success' })
            }).catch((err) => {
                console.log('[error]', err);
            });
            setRemoveCampaignModalOpen(false)
        } else {
            couponCampaignCancel({
                variables: {
                    id: campaignId,
                }
            }).then(response => {
                history.push(`/admin${generatePath(paths.campaigns.Coupon)}`)
                enqueueSnackbar(intl.formatMessage({ id: 'campaigns.cancel.response.success' }), { variant: 'success' })
            }).catch((err) => {
                console.log('[error]', err);
            });
            setRemoveCampaignModalOpen(false)
        }
    }

    const approveCampaign = () => {
        campaignApprove({
            variables: {
                id: campaignId,
            }
        }).then(response => {
            //window.location.reload()
            enqueueSnackbar(intl.formatMessage({ id: 'campaigns.approve.success' }), {
                variant: 'success',
            })
            refetchData()
        }).catch(err => {
            console.log(err)
        });
        setApproveCampaignModalOpen(false)
    }

    const rejectCampaign = () => {
        campaignReject({
            variables: {
                id: campaignId,
            }
        }).then(response => {
            //window.location.reload()
            enqueueSnackbar(intl.formatMessage({ id: 'campaigns.reject.success' }), {
                variant: 'success',
            })
            refetchData()
        }).catch(err => {
            console.log(err)
        });
        setRejectCampaignModalOpen(false)
    }

    const upCredit = () => {
        couponCampaignAddBudget({
            variables: {
                id: campaignId,
                budget: Number(upCreditValues?.credit)
            }
        }).then(response => {
            refetchData()
            enqueueSnackbar(intl.formatMessage({ id: 'campaigns.upCredit.success' }), { variant: 'success' })
        }).catch((err) => {
            console.log('[error]', err);
        });
        setUpCreditConfirmationModal(false)
    }
    const assignedCouponSet = () => {
        couponSetAssigned({
            variables: {
                name: selectedCouponSet?.description,
                id: campaignId,
                couponSetId: selectedCouponSet?.couponSet
            }
        }).then(response => {
            enqueueSnackbar((campaignType === CampaignTypeEnum.QR)
                ? intl.formatMessage({ id: 'campaigns.setCouponSet.success' })
                : intl.formatMessage({ id: 'campaigns.setPQRCouponSet.success' })
                , { variant: 'success', })
            refetchData()
            refetchCouponsSet()
            refetchCouponSets()
                (false)
        }).catch((err) => {
            console.log('[error]', err);
        });
        setCouponSetConfirmationModal(false)
        setSelectedCouponSet('');
    }

    //Page header buttons method
    const getActions = () => {
        if (campaignType === CampaignTypeEnum.FREEPHOTO) {
            let actions = []

            if (status !== "Awaiting") {
                if (status === "Inactive" || status === "Scheduled" || status === "Running") {
                    actions.push({
                        title: intl.formatMessage({ id: 'campaigns.end.button' }),
                        color: "danger",
                        onClick: () => setRemoveCampaignModalOpen(true)
                    })
                }
            } else if (status === "Awaiting") {
                actions.push({
                    title: intl.formatMessage({ id: 'campaigns.reject.button' }),
                    color: "danger",
                    onClick: () => setRejectCampaignModalOpen(true)
                })
                actions.push({
                    title: intl.formatMessage({ id: 'campaigns.approve.button' }),
                    color: "success",
                    onClick: () => setApproveCampaignModalOpen(true)
                })
            }
            return actions

        } else if (campaignType !== CampaignTypeEnum.FREEPHOTO) {
            if (campaignType === CampaignTypeEnum.QR) {
                if (status !== CampaignStatusEnum.AWAITING) {
                    return (
                        (status === CampaignStatusEnum.INACTIVE || status === CampaignStatusEnum.SCHEDULED || status === CampaignStatusEnum.RUNNING)
                        &&
                        (
                            [
                                {
                                    title: (CampaignTypeEnum.QR) ? intl.formatMessage({ id: 'campaigns.setCouponSet.button' }) : intl.formatMessage({ id: 'campaigns.setPQRCoupon.button' }),
                                    onClick: () => setCouponSetAssignedModal(true)
                                },
                                {
                                    title: intl.formatMessage({ id: 'campaigns.end.button' }),
                                    color: "danger",
                                    disabled: true,
                                    onClick: () => setRemoveCampaignModalOpen(true)
                                }
                            ]
                        )

                    )
                }
            } else {
                if (status !== CampaignStatusEnum.AWAITING) {
                    return (
                        (status === CampaignStatusEnum.INACTIVE || status === CampaignStatusEnum.SCHEDULED || status === CampaignStatusEnum.RUNNING)
                        &&
                        (
                            [
                                {
                                    title: intl.formatMessage({ id: 'campaigns.addBudget.button' }),
                                    onClick: () => setUpCreditModal(true)
                                },
                                {
                                    title: intl.formatMessage({ id: 'campaigns.setPQRCoupon.button' }),
                                    onClick: () => setCouponSetAssignedModal(true)
                                },
                                {
                                    title: intl.formatMessage({ id: 'campaigns.end.button' }),
                                    color: "danger",
                                    disabled: true,
                                    onClick: () => setRemoveCampaignModalOpen(true)
                                }
                            ]
                        )

                    )
                }
            }
        } else {
            return ([])
        }
    }
    
    //Modal Buttons methods
    const getRemoveCampaignModalActions = () => {
        return (
            <>
                <Button
                    onClick={() => removeCampaign()}
                    color="danger"
                    round
                    size="sm"
                >
                    {intl.formatMessage({ id: 'campaigns.end.button' })}
                </Button>
                <Button
                    onClick={() => setRemoveCampaignModalOpen(false)}
                    color="primary" round size="sm"
                >
                    {intl.formatMessage({ id: 'campaigns.removeModal.no' })}
                </Button>
            </>
        )
    }

    // const { loading, data, error } = useQuery(CAMPAIGNS_STORES, {
    //     variables: {
    //         id: campaignId,
    //     },
    // });

    // if (loading) {
    //     return <div>Loading...</div>;
    // }

    // if (error) {
    //     return <div>Error: {error.message}</div>;
    // }

    // const campaignStoresData = data.campaign;
    
    // console.log(campaignId, campaignStoresData)
    const getApproveCampaignModalActions = () => {
        return (
            <>
                <Button
                    onClick={() => approveCampaign()}
                    color="success"
                    round
                    size="sm"
                >
                    {intl.formatMessage({ id: 'campaigns.approve.bigButton' })}
                </Button>
                <Button
                    onClick={() => setApproveCampaignModalOpen(false)}
                    color="primary" outlined round size="sm"
                >
                    {intl.formatMessage({ id: 'campaigns.removeModal.no' })}
                </Button>
            </>
        )
    }

    const getRejectCampaignModalActions = () => {
        return (
            <>
                <Button
                    onClick={() => rejectCampaign()}
                    color="danger"
                    round
                    size="sm"
                >
                    {intl.formatMessage({ id: 'campaigns.reject.bigButton' })}
                </Button>
                <Button
                    onClick={() => setRejectCampaignModalOpen(false)}
                    color="primary" outlined round size="sm"
                >
                    {intl.formatMessage({ id: 'campaigns.removeModal.no' })}
                </Button>
            </>
        )
    }


    const getUpCreditConfirmationModalActions = () => {
        return (
            <>
                <Button
                    onClick={() => upCredit()}
                    color="success"
                    round
                    size="sm"
                >
                    {intl.formatMessage({ id: 'campaigns.button.continue' })}
                </Button>
                <Button
                    onClick={() => setUpCreditConfirmationModal(false)}
                    color="primary" round size="sm"
                >
                    {intl.formatMessage({ id: 'campaigns.removeModal.no' })}
                </Button>
            </>
        )
    }

    const getUpCreditModalActions = (formikProps) => {
        return (
            <>
                <Button
                    onClick={() => {
                        setUpCreditValues(formikProps?.values);
                        setUpCreditModal(false);
                        setUpCreditConfirmationModal(true);
                        formikProps.resetForm();
                    }}
                    disabled={!formikProps?.values?.credit}
                    color="success"
                    round
                    size="sm"
                >
                    {intl.formatMessage({ id: 'campaigns.addCredit.button' })}
                </Button>
                <Button
                    onClick={() => { formikProps.resetForm(); setUpCreditModal(false); setSelectedCouponSet('') }}
                    color="primary" round size="sm"
                >
                    {intl.formatMessage({ id: 'campaigns.removeModal.no' })}
                </Button>
            </>
        )
    }

    const getCouponSetAssignedModalActions = (formikProps) => {
        return (
            <>
                {(campaignType === CampaignTypeEnum.PQR) ?
                    <Button
                        onClick={() => {
                            setSelectedCouponSet(formikProps?.values);
                            setCouponSetAssignedModal(false);
                            setCouponSetConfirmationModal(true)
                            formikProps.resetForm()
                        }}
                        disabled={(campaignType === CampaignTypeEnum.PQR)
                            ? (!formikProps?.values?.couponSet || !formikProps?.values?.description)
                            : (!formikProps?.values?.couponSet)
                        }
                        color="success"
                        round
                        size="sm"
                    >
                        {intl.formatMessage({ id: 'campaigns.setCouponSet.assigned' })}
                    </Button>
                    :
                    <Button
                        onClick={() => {
                            setSelectedCouponSet(formikProps?.values);
                            setCouponSetAssignedModal(false);
                            setCouponSetConfirmationModal(true)
                            formikProps.resetForm()
                        }}
                        disabled={(campaignType === CampaignTypeEnum.PQR)
                            ? (!formikProps?.values?.couponSet || !formikProps?.values?.description)
                            : (!formikProps?.values?.couponSet) ? true : false
                        }
                        color="success"
                        round
                        size="sm"
                    >
                        {intl.formatMessage({ id: 'campaigns.setCouponSet.assigned' })}
                    </Button>
                }
                <Button
                    onClick={() => { setCouponSetAssignedModal(false); setSelectedCouponSet(''); formikProps.resetForm() }}
                    color="primary" round size="sm"
                >
                    {intl.formatMessage({ id: 'campaigns.removeModal.no' })}
                </Button>
            </>
        )
    }

    const getCouponSetConfirmationModalActions = () => {
        return (
            <>
                <Button
                    onClick={() => assignedCouponSet()}
                    disabled={!selectedCouponSet}
                    color="success"
                    round
                    size="sm"
                >
                    {intl.formatMessage({ id: 'campaigns.setCouponSet.assigned' })}
                </Button>
                <Button
                    onClick={() => { setCouponSetConfirmationModal(false); setSelectedCouponSet('') }}
                    color="primary" round size="sm"
                >
                    {intl.formatMessage({ id: 'campaigns.removeModal.no' })}
                </Button>
            </>
        )
    }

    //Render Modals Methods
    const getRemoveCampaignTitle = () => {
        return <>
            {intl.formatMessage({ id: 'campaigns.removeModal.text' })} {pathOr('', [pathType, 'name'], campaignData)}?
        </>
    }

    const renderRemoveCampaignModal = () => {
        return (
            <Modal
                title={getRemoveCampaignTitle()}
                open={removeCampaignModalOpen}
                onClose={() => setRemoveCampaignModalOpen(false)}
                actions={getRemoveCampaignModalActions()}
                fullWidth={true}
            />
        )
    }


    const renderApproveCampaignModal = () => {
        return (
            <Modal
                title={intl.formatMessage({ id: 'campaigns.approve.bigButton' })}
                open={approveCampaignModalOpen}
                onClose={() => setApproveCampaignModalOpen(false)}
                actions={getApproveCampaignModalActions()}
            >
                {intl.formatMessage({ id: 'campaigns.approve.modalBody' })}
                <br />
                {pathOr('', ['campaign', 'name'], campaignData)}?
            </Modal>
        )
    }

    const renderRejectCampaignModal = () => {
        return (
            <Modal
                title={intl.formatMessage({ id: 'campaigns.reject.bigButton' })}
                open={rejectCampaignModalOpen}
                onClose={() => setRejectCampaignModalOpen(false)}
                actions={getRejectCampaignModalActions()}
            >
                {intl.formatMessage({ id: 'campaigns.reject.modalBody' })}
                <br />
                {pathOr('', ['campaign', 'name'], campaignData)}?
            </Modal>
        )
    }

    const renderUpCreditConfirmationModal = () => {
        return (
            <Modal
                title={intl.formatMessage({ id: 'campaigns.creditUp.title' }, [upCreditValues?.credit])}
                open={upCreditConfirmationModal}
                onClose={() => setUpCreditConfirmationModal(false)}
                actions={getUpCreditConfirmationModalActions()}
                fullWidth={true}
            />
        )
    }


    const renderCouponSetAssignedModal = () => {
        return (
            <>
                {(campaignType === CampaignTypeEnum.QR) ?

                    <Formik
                        initialValues={userData}
                        onSubmit={(values, { resetForm }) => { upCredit(values, resetForm) }}
                        validationSchema={validationQRCouponSet(intl)}
                    >
                        {(formikProps) => (
                            <Modal
                                title={intl.formatMessage({ id: 'campaigns.setCouponSet.button' })}
                                open={couponSetAssignedModal}
                                onClose={() => { setCouponSetAssignedModal(false); formikProps.resetForm() }}
                                actions={getCouponSetAssignedModalActions(formikProps)}
                                fullWidth={true}
                            >
                                <Grid container spacing={3}>
                                    <Grid item sm={12}>
                                        <FormikField
                                            name="couponSet"
                                            labelText={intl.formatMessage({ id: 'campaigns.setCouponSet.label' })}
                                            formikProps={formikProps}
                                            highlightChange
                                            type={'select'}
                                            options={couponSets}
                                        />
                                    </Grid>
                                </Grid>
                            </Modal >
                        )}
                    </Formik>
                    :
                    <Formik
                        initialValues={userData}
                        onSubmit={(values, { resetForm }) => { upCredit(values, resetForm) }}
                        validationSchema={validationPQRCouponSet(intl)}
                    >

                        {(formikProps) => (
                            <Modal
                                title={intl.formatMessage({ id: 'campaigns.assignPQRCoupon.title' })}
                                open={couponSetAssignedModal}
                                onClose={() => { setCouponSetAssignedModal(false); formikProps.resetForm() }}
                                actions={getCouponSetAssignedModalActions(formikProps)}
                                fullWidth={true}
                            >
                                <Grid container spacing={3}>
                                    <Grid item sm={12}>
                                        <FormikField
                                            name="description"
                                            labelText={intl.formatMessage({ id: 'campaigns.PQRCoupon.description' })}
                                            formikProps={formikProps}
                                            highlightChange
                                        />
                                    </Grid>
                                    <Grid item sm={12}>
                                        <FormikField
                                            name="couponSet"
                                            labelText={(campaignType === CampaignTypeEnum.QR)
                                                ? intl.formatMessage({ id: 'campaigns.setCouponSet.label' })
                                                : intl.formatMessage({ id: 'campaigns.PQRCoupon.couponSet' })
                                            }
                                            formikProps={formikProps}
                                            highlightChange
                                            type={'select'}
                                            options={couponSets}
                                        />
                                    </Grid>
                                </Grid>
                            </Modal >
                        )}
                    </Formik>
                }
            </>
        )
    }

    const getRemains = (cred) => {
        let final = (cred)
            ? Number(companyBalance) - Number(cred)
            : companyBalance
        return <>
            {(final >= 0)
                ? <span style={{ color: "black" }}>{intl.formatMessage({ id: 'campaigns.budgetCard.balanceOfCredits' })} {final} C</span>
                : ""
            }
        </>
    }

    const renderUpCreditModal = () => {
        return (
            <Formik
                initialValues={userData}
                onSubmit={(values, { resetForm }) => { upCredit(values, resetForm) }}
                validationSchema={validationCreditUp(intl, companyBalance)}
            >
                {(formikProps) => (
                    <Modal
                        title={intl.formatMessage({ id: 'campaigns.creditUp.modalTitle' })}
                        open={upCreditModal}
                        onClose={() => { formikProps.resetForm(); setUpCreditModal(false) }}
                        actions={getUpCreditModalActions(formikProps)}
                        fullWidth={true}
                    >
                        <>
                            <FormikField
                                name="credit"
                                labelText={
                                    <p>
                                        {intl.formatMessage({ id: "invoicesList.assignModal.credit" })}
                                        {" (Max. " + companyBalance + " C)"}
                                    </p>
                                }
                                formikProps={formikProps}
                                highlightChange
                            />
                            <Grid item sm={12}>
                                <br />
                                {getRemains(formikProps.values.credit)}
                            </Grid>
                        </>
                    </Modal >
                )}
            </Formik>
        )
    }

    const getCouponInfo = (id, operation) => {
        let couponsSelectItems = pathOr([], "couponSetsTable.items", unassignedCoupons).filter(coup => coup.id === id).map(
            (coupon) => {
                return {
                    value: pathOr("", "id", coupon),
                    label: pathOr("", "bid", coupon),
                    nominalValue: pathOr("", "totalSetValue", coupon)
                };
            }
        );
        if (operation === "label") return couponsSelectItems?.[0]?.label
        else return couponsSelectItems?.[0]?.nominalValue

    }

    const renderCouponSetConfirmationModal = () => {
        return (
            <Modal
                title={(campaignType === CampaignTypeEnum.PQR)
                    ? intl.formatMessage({ id: 'campaigns.setPQRCouponSet.confirm' })
                    : intl.formatMessage({ id: 'campaigns.setCouponSet.confirm' })
                }
                open={couponSetConfirmationModal}
                onClose={() => setCouponSetConfirmationModal(false)}
                actions={getCouponSetConfirmationModalActions()}
                fullWidth={true}
            >
                <Grid item sm={12}><hr /></Grid>
                {(campaignType === CampaignTypeEnum.PQR) &&
                    <Grid item sm={12}>
                        {intl.formatMessage({ id: 'campaigns.setPQRCouponSet.description' })}
                        <Box className={classes.floatRight}>
                            {selectedCouponSet?.description}
                        </Box>
                    </Grid>
                }
                <Grid item sm={12}>
                    {(campaignType === CampaignTypeEnum.QR)
                        ? intl.formatMessage({ id: 'campaigns.setQRCouponSet.id' })
                        : intl.formatMessage({ id: 'campaigns.setPQRCouponSet.id' })
                    }
                    <Box className={classes.floatRight}>
                        {getCouponInfo(selectedCouponSet?.couponSet, "label")}
                    </Box>
                </Grid>
                <Grid item sm={12}>
                    {(campaignType === CampaignTypeEnum.QR)
                        ? intl.formatMessage({ id: 'campaigns.setQRCouponSet.value' })
                        : intl.formatMessage({ id: 'campaigns.setPQRCouponSet.value' })
                    }
                    <Box className={classes.floatRight}>
                        {getCouponInfo(selectedCouponSet?.couponSet, "nominalValue")} C
                    </Box>
                </Grid>
            </Modal >
        )
    }

    //Renders methods
    const getTitile = () => {
        return (
            (status === CampaignStatusEnum.FINISHED) ?
                pathOr('', [pathType, 'name'], campaignData) + " (" + intl.formatMessage({ id: 'campaigns.status.finished' }) + ")"
                : (status === CampaignStatusEnum.REJECTED)
                    ? pathOr('', [pathType, 'name'], campaignData) + " (" + intl.formatMessage({ id: 'campaigns.status.rejected' }) + ")"
                    : pathOr('', [pathType, 'name'], campaignData)
        )
    }

    const getDetailInfoCard = (formikProps) => {
        return <DetailInfoCard
            formikProps={formikProps}
            name={pathOr('', [pathType, 'name',], campaignData)}
            type={pathOr('', [pathType, 'type',], campaignData)}
            company={pathOr('', [pathType, 'company', 'name'], campaignData)}
            topics={pathOr('', [pathType, 'topics'], campaignData)}
            startDate={pathOr('', [pathType, 'validFrom'], campaignData)}
            endDate={pathOr('', [pathType, 'validTo'], campaignData)}
            description={pathOr('', [pathType, 'description'], campaignData)}
            createdAt={pathOr(0, [pathType, 'createdAt',], campaignData)}
            createdBy={pathOr(0, [pathType, 'createdBy', 'fullName'], campaignData)}
            total={pathOr(0, [pathType, 'budget', 'total'], campaignData)}
            isFreePhoto={(campaignType === CampaignTypeEnum.FREEPHOTO) ? true : false}
            isCanceled={((status === "Rejected") || (status === "Finished"))}
        />
    }
    
    const getDetailCompanyCard = () => {
        return <DetailCompanyCard
            className={classes.floatRight}
            history={history}
            companyId={pathOr('', [pathType, 'company', 'id'], campaignData)}
            companyBid={pathOr('', [pathType, 'company', 'bid'], campaignData)}
            companyName={pathOr('', [pathType, 'company', 'name'], campaignData)}
            companyAddress={pathOr('', [pathType, 'company', 'address'], campaignData)}
            companyPostal={pathOr('', [pathType, 'company', 'postal'], campaignData)}
            companyCity={pathOr('', [pathType, 'company', 'city'], campaignData)}
            companyFirstName={pathOr('', [pathType, 'company', 'contactFirstName'], campaignData)}
            companyLastName={pathOr('', [pathType, 'company', 'contactLastName'], campaignData)}
            companyMail={pathOr('', [pathType, 'company', 'contactEmail'], campaignData)}
            companyPhone={pathOr('', [pathType, 'company', 'contactPhone'], campaignData)}

        />
    }

    //Final Render
    if (campaignLoading) return <Loading />
    return (
        <>
            <PageHeader
                title={getTitile()}
                subTitle={pathOr('', [pathType, 'bid'], campaignData)}
                actions={getActions()}
                handleBackAction={(e) => history.goBack()}
                withBackButton={backButton}
            />
            {campaignType === CampaignTypeEnum.FREEPHOTO ?
                <Formik
                    onSubmit={(values) => { handleUpdateCampaign(values) }}
                    enableReinitialize
                    initialValues={campaignData}
                    validationSchema={getValidationSchemaDetail(intl, pathOr(0, [pathType, 'budget', 'total'], campaignData))}
                >
                    {(formikProps) => (
                        <>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={12} md={6} lg={4}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sm={12} md={12} lg={12} >
                                            {getDetailInfoCard(formikProps)}
                                        </Grid>

                                        <Grid item xs={12} sm={12} md={12} lg={12} >
                                            <DetailHoursCard
                                                times={(pathOr([], ['campaign', 'activeTimes'], campaignData))}
                                                campaignId={(pathOr('', ['campaign', 'id'], campaignData))}
                                                callbackTimes={(newValue) => setTimes(newValue)}
                                                formikProps={formikProps}
                                                withoutOpenInfo={true}
                                                isCanceled={((status === "Rejected") || (status === "Finished"))}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} sm={12} md={6} lg={8}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sm={12} md={12} lg={6} >
                                            <DetailBudgetCard
                                                budgetSpent={pathOr('', ['campaign', 'budget', 'spent'], campaignData)}
                                                budgetRemaining={pathOr('', ['campaign', 'budget', 'remaining'], campaignData)}
                                                budgetTotal={pathOr('', ['campaign', 'budget', 'total'], campaignData)}
                                                averageBid={pathOr('', ['campaign', 'stats', 'avgPrice'], campaignData)}
                                                data={formikProps.values}
                                                formikProps={formikProps}
                                                isCanceled={((status === "Rejected") || (status === "Finished"))}
                                            //maxBid={pathOr('', ['campaign', 'maxBid'], campaignData)}
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={12} md={12} lg={6} >
                                            <DetailBannersCard
                                                media={pathOr([], ['campaign', 'media'], campaignData)}
                                                watermark={pathOr([], ['campaign', 'watermark'], campaignData)}
                                                />
                                            {/*<DetailWatermarkCard
                                                media={pathOr([], ['campaign', 'media'], campaignData)}
                                            />*/}
                                        </Grid>
                                    </Grid>

                                    <Grid container spacing={3}
                                       
                                    >
                                        <Grid item xs={12} sm={12} md={12} lg={6}>
                                        {getDetailCompanyCard()}
                                        </Grid>

                                        <Grid item xs={12} sm={12} md={12} lg={6} >
                                            <IconCard
                                                title={intl.formatMessage({ id: 'campaigns.wizard.step4.title' })}
                                                icon={<WcIcon />}
                                                className= "genderCheckBox"
                                            >
                                                <GenderCheckBox
                                                        checkedMen={checkedMen}
                                                        checkedWomen={checkedWomen}
                                                        checkedNA={checkedNA}
                                                        rangeMaleChecked={ rangeMaleChecked }
                                                        rangeFemaleChecked={ rangeFemaleChecked }
                                                        rangeUnknownGenderChecked={ rangeUnknownGenderChecked }
                                                        onCheckedAge={handleCheckedAge}
                                                        onCheckBox={handleCheckBox}
                                                        page={ 'detailPage' }
                                                        status={status}
                                                    />  
                                            </IconCard>
                                        </Grid>                                       

                                    </Grid>

                                    <Grid container spacing={3} >
                                        <Grid item xs={12} sm={12} md={12} lg={12}>
                                            <DetailLocationsCard
                                                data={formikProps.values}
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={12} md={12} lg={12}>

                                            <IconCard
                                                icon={<StoreIcon />}
                                                title={intl.formatMessage({ id: "storeList.table.title" })}
                                                className= "campaignStore"
                                            >
                                                <CampaignStoreTable
                                                    query={ALL_STORES_CAMPAIGN}
                                                    // defaultFilter={defaultFilter}
                                                    history={history}
                                                    queryDataPath={['storesTableByCampaign', 'items']}
                                                    queryVariables={{ campaignId: campaignId }}  
                                                />
                                            </IconCard>

                                        </Grid>
                                    </Grid>

                                </Grid>
                            </Grid>
                            {((status === "Rejected") || (status === "Finished")) ?
                                <></>
                                : <>
                                    <div style={{ position: "fixed", bottom: "20px", right: "20px", zIndex: 1000  }} >
                                        <Button
                                            disabled={!formikProps.isValid}
                                            color="primary"
                                            onClick={(e) => { formikProps.handleSubmit() }}
                                        >
                                            {intl.formatMessage({ id: "userDetail.buttons.confirm" })}
                                        </Button>
                                        <Button
                                            onClick={() => formikProps.resetForm()}
                                        >
                                            {intl.formatMessage({ id: 'settings.cancel' })}
                                        </Button>
                                    </div>
                                </>
                            }
                        </>

                    )}
                </Formik>
                :
                <>
                    <Grid container spacing={3}>
                        <Grid item md={6} lg={4} sm={12}>
                            {getDetailInfoCard()}
                            {getDetailCompanyCard()}
                        </Grid>

                        <Grid item md={6} lg={8} sm={12}>
                            {(campaignType !== CampaignTypeEnum.PQR) &&
                                <DetailChartCouponsCard
                                    remainingCoupons={pathOr(0, [pathType, 'couponCounts', 'unusedCount'], campaignData)}
                                    usedCoupons={pathOr(0, [pathType, 'couponCounts', 'usedCount'], campaignData)}
                                    totalCoupons={pathOr(0, [pathType, 'couponCounts', 'totalCount'], campaignData)}
                                    remainingBudget={pathOr(0, [pathType, 'budget', 'free'], campaignData)}
                                    allocatedBudget={pathOr(0, [pathType, 'budget', 'allocated'], campaignData)}
                                    usedBudget={pathOr(0, [pathType, 'budget', 'used'], campaignData)}
                                    unusedBudget={pathOr(0, [pathType, 'budget', 'unused'], campaignData)}
                                    totalBudget={pathOr(0, [pathType, 'budget', 'total'], campaignData)}
                                    pqrType={(campaignType === CampaignTypeEnum.PQR) ? true : false} //TODO
                                />
                            }
                            <IconCard
                                icon={<QRCampaignsIcon raw={true} />}
                                title={(campaignType !== CampaignTypeEnum.PQR)
                                    ? intl.formatMessage({ id: 'campaigns.couponWidget.title' })
                                    : "MQR kupóny"
                                }
                            >
                                <CouponTable
                                    loadData={loadCouponSets}
                                    data={dataCouponSets}
                                    defaultFilter={{ campaignId: { eq: Number(campaignId) } }}
                                    refetch={refetchCouponsSet}
                                    detail={true}
                                    history={history}
                                    queryDataPath={['couponSetsTable', 'items']}
                                    numberOfRows={10}
                                    pqrType={(campaignType === CampaignTypeEnum.PQR) ? true : false} //TODO
                                />
                            </IconCard>
                        </Grid>
                    </Grid>

                </>
            }
            {renderRemoveCampaignModal()}
            {renderCouponSetAssignedModal()}
            {renderCouponSetConfirmationModal()}
            {renderUpCreditModal()}
            {renderUpCreditConfirmationModal()}
            {campaignType === CampaignTypeEnum.FREEPHOTO && renderApproveCampaignModal()}
            {campaignType === CampaignTypeEnum.FREEPHOTO && renderRejectCampaignModal()}
        </>
    )

}

export default injectIntl(withRouter(CampaignDetailPage))
