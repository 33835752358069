import { Grid } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import IconCard from "components-lib/IconCard/IconCard";
import React, { useState } from "react";
import { injectIntl } from "react-intl";
import styles from "common/styles/widgets.js"
import { pathOr } from "rambda";
import PhotoAlbumIcon from '@material-ui/icons/PhotoAlbum';
import CardMedia from '@material-ui/core/CardMedia';
import Modal from "components-lib/Modal/Modal";
import { defaultFFWatermarkUrl } from 'configFiles/config';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => styles(theme));

const DetailBannersCard = (props) => {
    const { intl, media, watermark } = props;

    const classes = useStyles();

    /**
     * MEDIA
     */
    let imgWidth = ((300 * Number(window.innerWidth)) / 1920)
    const [modalOpen, setModalOpen] = useState(false)
    const [defaultWatermarkModalOpen, setDefaultWatermarkModalOpen] = useState(false)
    const [mediaUrl, setMediaUrl] = useState({})

    const handleSetModal = (data) => {
        setMediaUrl({
            name: pathOr('', 'media', 'name', data),
            url: pathOr('', 'url', data)

        })
        setModalOpen(true)
    }

    const getActions = (data) => {
        return <span
            style={{ padding: "10px" }}
            className={classes.pointer}
            onClick={() => handleSetModal(data)}
        >
            <img src={pathOr('', 'url', data)} alt={pathOr('', 'url', data)} width="20px" />
        </span>
    }
    //END


    return <>
        <IconCard
            title={intl.formatMessage({ id: 'campaigns.spotsCard.title' })}
            icon={<PhotoAlbumIcon />}
            className= "detailBanner"
        >
            <>
                <Grid container spacing={1} >
                    <p style={{ fontSize: '1.2em' }}>{intl.formatMessage({ id: 'campaigns.spotsCard.banners' })}</p>
                    {
                        media.map(med =>
                            <Grid item sm={12}>
                                {getActions(med)}
                                {pathOr('', ['media', 'name'], med)}
                                &nbsp;&nbsp;
                                <Box className={classes.floatRight}>
                                    {`
                                        ${pathOr('', 'usageCount', med)} 
                                        ${intl.formatMessage({ id: 'campaigns.spotsCard.count' })}
                                    `}

                                </Box>
                            </Grid>
                        )
                    }
                    <Grid item sm={12}>
                        <hr style={{ margin: '2px 0px 5px 0px' }} />
                        <p style={{ fontSize: '1.2em', marginBottom: '15px' }}>{intl.formatMessage({ id: 'campaigns.freeFoto.watermark' })}</p>
                        <span
                            style={{ margin: '20px 8px 10px 8px', backgroundColor: '#F36E21' }}
                            className={classes.pointer}
                            onClick={() => setDefaultWatermarkModalOpen(true)}
                        >
                            <img src={(watermark?.url) ? watermark?.url : defaultFFWatermarkUrl} alt={(watermark?.url) ? watermark?.url : defaultFFWatermarkUrl} width="40px" />

                        </span>
                        {(watermark?.url) ? watermark?.name : intl.formatMessage({ id: 'campaigns.freeFoto.watermark.default' })}

                    </Grid>
                </Grid>
            </>
        </IconCard>
        <Modal
            title={intl.formatMessage({ id: 'campaigns.spotsCard.banner' })}
            open={modalOpen}
            onClose={() => { setModalOpen(false) }}
        >
            <CardMedia
                component="img"
                style={{ width: imgWidth }}
                alt={mediaUrl.url}
                image={mediaUrl.url}
                title={mediaUrl.url}
            />

        </Modal>
        <Modal
            title={intl.formatMessage({ id: 'campaigns.freeFoto.watermark.title' })}
            open={defaultWatermarkModalOpen}
            onClose={() => { setDefaultWatermarkModalOpen(false) }}
        >
            <CardMedia
                component="img"
                style={{ width: imgWidth, backgroundColor: '#F36E21' }}
                alt={(watermark?.url) ? watermark?.url : defaultFFWatermarkUrl}
                image={(watermark?.url) ? watermark?.url : defaultFFWatermarkUrl}
                title={(watermark?.url) ? watermark?.url : defaultFFWatermarkUrl}
            />

        </Modal>
    </>
};

export default injectIntl(DetailBannersCard);
