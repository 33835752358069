import gql from 'graphql-tag';

const PAYMENTS_BY_USER = (id) => gql`
query paymentsTableByUser($offset: Int, $limit: Int, $sort: [PaymentsTableSortInput!], $filter: [PaymentsTableFilterInput]){
  paymentsTableByUser(userId:${id}, offset:$offset, limit: $limit, sort: $sort, filter: $filter){
  totalCount,  
  items{
      id
      bid
      purpose
      status
      method
      amount
      credit
      userId
      userEmail
      operationId
      operationBid
      checkoutId    
      createdAt
      status        
    }
  }
}
`;

export default PAYMENTS_BY_USER;
