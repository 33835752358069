import * as Yup from 'yup';

export const validationSchema = (intl) =>
    Yup.object().shape({
        title: Yup.string()
            .required(intl.formatMessage({ id: 'validation.required' }))
            .max(38, intl.formatMessage({ id: 'validation.string.max' }, [38]))
            .nullable(),
        text: Yup.string()
            .required(intl.formatMessage({ id: 'validation.required' }))
            .max(170, intl.formatMessage({ id: 'validation.string.max' }, [170]))
            .nullable(),

        screen: Yup.string()
            .required(intl.formatMessage({ id: 'validation.required' }))
            .nullable(),
        scheduledTo: Yup.date()
            .typeError(intl.formatMessage({ id: 'validation.date' }))
            .min(new Date(Date.now()), intl.formatMessage({ id: 'validation.pastDate' })).nullable(),
    });

export const validationSchemaFilter = (intl) =>
    Yup.object().shape({
        criteria: Yup.object().shape({
            creditAccountBalance: Yup.number()
                .integer(intl.formatMessage({ id: 'validation.number.integer' }))
                .typeError(intl.formatMessage({ id: 'validation.number' }))
                .min(0, intl.formatMessage({ id: 'validation.number.min' }, [0]))
                .nullable(),
            revenue: Yup.number()
                .integer(intl.formatMessage({ id: 'validation.number.integer' }))
                .typeError(intl.formatMessage({ id: 'validation.number' }))
                .min(0, intl.formatMessage({ id: 'validation.number.min' }, [0]))
                .nullable(),
        }),
    });

export const validationSchemaAuto = (intl) =>
    Yup.object().shape({
        mutations: Yup.array()
            .of(Yup.object().shape({
                title: Yup.string()
                    .max(38, intl.formatMessage({ id: 'validation.string.max' }, [38]))
                    .nullable(),
                text: Yup.string()
                    .max(170, intl.formatMessage({ id: 'validation.string.max' }, [170]))
                    .nullable(),
            })),
    });
