import gql from 'graphql-tag';

const ALL_SEGMENTS = gql`
query segments(
  $offset: Int, 
  $limit: Int,
  $sort:[SegmentSortInput!],
  $filter:[SegmentFilterInput]
){
  segments(offset:$offset, limit: $limit, sort: $sort, filter: $filter){
      totalCount
      items{
        id
      	name
      }
    }
  }
`;

export default ALL_SEGMENTS;