import { Grid } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import CameraIcon from '@material-ui/icons/Camera';
import IconCard from "components-lib/IconCard/IconCard";
import React from "react";
import { injectIntl } from "react-intl";
import styles from "common/styles/widgets.js"

const useStyles = makeStyles((theme) => styles(theme));

const CampaignsCard = (props) => {
    const {
        intl,
        companyCredits,
        userCredits,
        allCredits
    } = props;
    const classes = useStyles();

    return <>
        <IconCard
            title={intl.formatMessage({ id: 'dashboard.creditsCard.title' })}
            icon={<CameraIcon />}
        >
            <Grid container spacing={1}>
                <Grid item sm={12}>
                    {intl.formatMessage({ id: 'dashboard.creditsCard.userCredits' })}
                    <Box className={classes.floatRight}>
                        {userCredits}
                    </Box>
                </Grid>
                <Grid item sm={12}>
                    {intl.formatMessage({ id: 'dashboard.creditsCard.companyCredits' })}
                    <Box className={classes.floatRight}>
                        {companyCredits}
                    </Box>
                    <hr style={{ marginTop: "5px", marginBottom: "5px" }} />
                </Grid>
                <Grid item sm={12}>
                    {intl.formatMessage({ id: 'dashboard.creditsCard.allCredits' })}
                    <Box className={classes.floatRight}>
                        {allCredits}
                    </Box>
                </Grid>
            </Grid>
        </IconCard>
    </>
};

export default injectIntl(CampaignsCard);
