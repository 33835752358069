import { makeStyles } from "@material-ui/core/styles";
import QueryTableRefresh from "components-lib/QueryTable/QueryTableRefresh";
import Button from "components/CustomButtons/Button.js";
import paths from "paths";
import React from "react";
import { injectIntl } from "react-intl";
import { generatePath } from "react-router-dom";
import styles from "common/styles/widgets.js";
import { DateTime } from "luxon";

const useStyles = makeStyles((theme) => styles(theme));

export const CompanyTable = (props) => {
  const {
    history,
    queryVariables,
    data,
    loadData,
    intl,
    defaultFilter = null,
    numberOfRows = 10,
    queryDataPath,
  } = props;
  const classes = useStyles();

  const onCompanyDetail = (id) => {
    history.push(
      `/admin${generatePath(paths.companies.detail, { companyId: id })}`
    );
  };

  return (
    <QueryTableRefresh
      data={data}
      loadData={loadData}
      numberOfRows={numberOfRows}
      permanentFilter={defaultFilter}
      queryDataPath={queryDataPath}
      queryVariables={queryVariables}
      columns={[
        {
          Header: intl.formatMessage({ id: "device.issue.code" }),
          accessor: "code",
          /*sortKey: "code",
          filterKey: "code",
          filterComponent: "select",
          filterOperator: "eq",
          filterOptions: [
            { value: "E005", label: "E005" },
            { value: "E006", label: "E006" },
            { value: "E007", label: "E007" },
            { value: "E008", label: "E008" },
            { value: "E009", label: "E009" },
            { value: "E010", label: "E010" },
            { value: "E011", label: "E011" },
            { value: "E012", label: "E012" },
            { value: "E013", label: "E013" },
            { value: "E014", label: "E014" },
            { value: "E015", label: "E015" },
            { value: "E016", label: "E016" },
            { value: "E017", label: "E017" },
            { value: "E018", label: "E018" },
            { value: "E019", label: "E019" },
            { value: "E020", label: "E020" },
            { value: "E021", label: "E021" },
            { value: "E022", label: "E022" },
            { value: "E023", label: "E023" },
            { value: "E026", label: "E026" },
            { value: "E027", label: "E027" },
            { value: "E028", label: "E028" },
            { value: "E029", label: "E029" },
            { value: "E030", label: "E030" },
          ],*/
        },

        {
          Header: intl.formatMessage({ id: "device.issue.description" }),
          accessor: "description",
        },
        {
          Header: intl.formatMessage({ id: "device.issue.date" }),
          accessor: (rowData) =>
            rowData.date !== null
              ? DateTime.fromISO(rowData.date).toFormat("dd.MM.yyyy HH:mm:ss")
              : "",
        },
      ]}
    />
  );
};

export default injectIntl(CompanyTable);
