import React, { useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from "components/CustomButtons/Button.js";
import { useMutation } from 'react-apollo';
import { pathOr } from 'rambda';
import paths from "paths";
import { generatePath } from "react-router-dom";

import { injectIntl } from 'react-intl';
import { defaultColors } from "common/styles/configLayout.js";
import { getApolloClient } from "configFiles/apollo";
import { useSnackbar } from "notistack";

// core components
import PageHeader from 'components-lib/PageHeader/PageHeader';
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import IconCard from 'components-lib/IconCard/IconCard';
import moment from 'moment';

import Step1 from "./WizardSteps/Step1.js";
import Step2 from "./WizardSteps/Step2.js";
import Step3 from "./WizardSteps/Step3.js";
import Box from "@material-ui/core/Box";

import NOTIFICATIONS_REACH from 'queries/NotificationsQueries/notificationsReach';
import CREATE_NOTIFICATION from 'queries/NotificationsQueries/createNotification';

import NotificationImportantIcon from '@material-ui/icons/NotificationImportant';
import SettingsInputComponentIcon from '@material-ui/icons/SettingsInputComponent';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import SendIcon from '@material-ui/icons/Send';
import clsx from 'clsx';
import StepConnector from '@material-ui/core/StepConnector';
import { useLocation } from "react-router-dom";

const ColorlibConnector = withStyles({
    alternativeLabel: {
        top: 22,
    },
    active: {
        '& $line': {
            backgroundImage:
                'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
        },
    },
    completed: {
        '& $line': {
            backgroundImage:
                'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
        },
    },
    line: {
        height: 3,
        border: 0,
        backgroundColor: '#eaeaf0',
        borderRadius: 1,
    },
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({
    root: {
        backgroundColor: defaultColors.two,
        zIndex: 1,
        color: '#fff',
        width: 50,
        height: 50,
        display: 'flex',
        borderRadius: '50%',
        justifyContent: 'center',
        alignItems: 'center',
    },
    active: {
        backgroundColor: defaultColors.one,
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    },
    completed: {
        backgroundColor: defaultColors.one,
    },
});

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    button: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    floatRight: {
        float: "right"
    },
}));



const NotificationNewPage = (props) => {
    const classes = useStyles();
    const { history, intl } = props;

    let defaultCriteria = useLocation()?.state?.notification?.criteria;
    let defaultBody = useLocation()?.state?.notification;

    const [activeStep, setActiveStep] = useState(0);
    const [criteria, setCriteria] = useState({});


    const [all, setAll] = useState([]);
    const [valid, setValid] = useState(false);
    const [valid2, setValid2] = useState(false);
    const [sendCriteria, setSendCriteria] = useState([]);
    const [criteriaNumber, setCriteriaNumber] = useState(-1);
    const [allValues, setAllValues] = useState({});

    const [initialData, setInitialData] = useState({
        criteria: {
            ageRange: (defaultCriteria?.ageRange) ? (defaultCriteria?.ageRange) : [0, 100],
            genders: (defaultCriteria?.genders) ? (defaultCriteria?.genders) : ['AllNotifications'],
            printedAtFotomatIds: (defaultCriteria?.printedAtFotomatIds) ? (defaultCriteria?.printedAtFotomatIds) : ['AllNotifications'],
            registrations: (defaultCriteria?.registrations) ? (defaultCriteria?.registrations) : ['AllNotifications'],
            subscriptionPlans: (defaultCriteria?.subscriptionPlans) ? (defaultCriteria?.subscriptionPlans) : ['AllNotifications'],
            usedFreePhoto: (defaultCriteria?.usedFreePhoto) ? (defaultCriteria?.usedFreePhoto) : 'AllNotifications',
            birthDay: (defaultCriteria?.birthDay) ? (defaultCriteria?.birthDay) : undefined,
            emails: (defaultCriteria?.emails) ? (defaultCriteria?.emails) : [],
            names: (defaultCriteria?.names) ? (defaultCriteria?.names) : undefined,
            revenue: (defaultCriteria?.revenue) ? (defaultCriteria?.revenue) : undefined,
            creditAccountBalance: (defaultCriteria?.creditAccountBalance) ? (defaultCriteria?.creditAccountBalance) : undefined,
            lastOrder: (defaultCriteria?.lastOrder) ? (defaultCriteria?.lastOrder) : undefined,
        }
    })

    const [initialAllData, setInitialAllData] = useState({
        title: (defaultBody?.title) ? (defaultBody?.title) : undefined,
        text: (defaultBody?.text) ? (defaultBody?.text) : undefined,
        screen: (defaultBody?.action?.screen) ? (defaultBody?.action?.screen) : undefined,
    })


    const [createNotification] = useMutation(CREATE_NOTIFICATION);

    const steps = getSteps();
    const { enqueueSnackbar } = useSnackbar();

    const client = getApolloClient(enqueueSnackbar, history, intl);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        if (activeStep === 0) setInitialData(criteria)
        else if (activeStep === 1) setInitialAllData(all)
        //else setInitialData(criteria)
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
        if (activeStep === 0) setInitialData(criteria)
        else if (activeStep === 1) setInitialAllData(all)
    };
    function getSteps() {
        return [intl.formatMessage({ id: 'notificationsPage.stepperLabel.filters' }),
        intl.formatMessage({ id: 'notificationsPage.stepperLabel.notifications' }),
        intl.formatMessage({ id: 'notificationsPage.stepperLabel.confirm' })];
    }

    function getStepContent(step) {
        switch (step) {
            case 0:
                return <Step1
                    callback={(newValue) => setCriteria(newValue)}
                    callbackValid={(newValue) => setValid(newValue)}
                    initialData={initialData}
                />;
            case 1:
                return <Step2
                    callback={(newValue) => setAll(newValue)}
                    callbackValid={(newValue) => setValid2(newValue)}
                    initialData={initialAllData}

                />;
            case 2:
                return <Step3
                    criteriaNumber={criteriaNumber}
                    allData={all}
                    text={setNotificationsText}
                />;
            default:
                return 'Unknown step';
        }
    }

    const setGender = (genders) => {
        let arrayLength = genders?.length
        if (arrayLength === 1) {
            if (genders.includes('Male'))
                return ['Male']
            else if (genders.includes('Female'))
                return ['Female']
            else
                return [null]
        } else if (arrayLength === 2) {
            if (genders.includes('Male') && genders.includes('Female'))
                return ['Male', 'Female']
            else if (genders.includes('Male') && genders.includes('Without'))
                return [null, 'Male']
            else
                return [null, 'Female']
        } else
            return undefined
    }



    const renderCalculateNumber = async () => {
        setAllValues(criteria)
        let values = criteria
        let genders = values?.criteria?.genders
        let allCriteria = {
            criteria: {
                genders: ((values?.criteria?.genders)?.length === 0 || (!values?.criteria?.genders) || (values?.criteria?.genders).includes('AllNotifications'))
                    ? undefined
                    : setGender(genders),

                ageRange: (JSON.stringify(values?.criteria?.ageRange) === JSON.stringify([0, 100])) ? undefined : values?.criteria?.ageRange,
                birthDay: (!values?.criteria?.birthDay) ? undefined : moment(values?.criteria?.birthDay).format("YYYY-MM-DD"),
                emails: ((values?.criteria?.emails)?.length === 0 || (!values?.criteria?.emails) || (values?.criteria?.emails).includes('AllNotifications'))
                    ? undefined
                    : values?.criteria?.emails,

                names: ((values?.criteria?.names)?.length === 0 || (!values?.criteria?.names) || (values?.criteria?.names).includes('AllNotifications'))
                    ? undefined
                    : values?.criteria?.names,

                printedAtFotomatIds: ((values?.criteria?.printedAtFotomatIds)?.length === 0 || (!values?.criteria?.printedAtFotomatIds) || (values?.criteria?.printedAtFotomatIds).includes('AllNotifications'))
                    ? undefined
                    : values?.criteria?.printedAtFotomatIds,

                registrations: ((values?.criteria?.registrations)?.length === 0 || (!values?.criteria?.registrations) || (values?.criteria?.registrations).includes('AllNotifications'))
                    ? undefined
                    : values?.criteria?.registrations,

                lastOrder: (!values?.criteria?.lastOrder) ? undefined : values?.criteria?.lastOrder,
                revenue: (!values?.criteria?.revenue) ? undefined : Number(values?.criteria?.revenue),

                subscriptionPlans: ((values?.criteria?.subscriptionPlans)?.length === 0 || (!values?.criteria?.subscriptionPlans) || (values?.criteria?.subscriptionPlans).includes('AllNotifications'))
                    ? undefined
                    : (values?.criteria?.subscriptionPlans),

                creditAccountBalance: (!values?.criteria?.creditAccountBalance) ? undefined : Number(values?.criteria?.creditAccountBalance),
                usedFreePhoto: (!values?.criteria?.usedFreePhoto || (values?.criteria?.usedFreePhoto) === 'AllNotifications')
                    ? undefined
                    : ((values?.criteria?.usedFreePhoto) === "No")
                        ? false
                        : true,
            }
        }

        setSendCriteria(allCriteria)
        try {
            const result = await client.query({
                query: NOTIFICATIONS_REACH,
                variables: {
                    ...allCriteria
                }
            });
            if (result.error) {
                throw result.error;
            }
            if (result.errors) {
                throw result.errors[0];
            }
            setCriteriaNumber(result.data.notificationReach.reach);

        } catch (error) {
            throw error;
        }
    };

    const setNotificationsText = () => {
        let helpText = pathOr('', ['text'], all)
        var res1 = helpText.replace('@[@firstName](1)', '{firstName}');
        var res2 = res1.replace('@[@lastName](2)', '{lastName}');
        var resFinal = res2.replace('@[@balance](3)', '{balance}');
        return resFinal
    }

    const handleCreateNotification = () => {
        setNotificationsText()

        const values = {
            title: pathOr('', ['title'], all),
            text: setNotificationsText(),
            action: {
                screen: pathOr('', ['screen'], all)
            },
            scheduledTo: (pathOr('', ['scheduledTo'], all)) ? (pathOr('', ['scheduledTo'], all)) : null,
            ...sendCriteria
        };


        if (!pathOr(null, ['screen'], all)) {
            delete values.action;
        }

        createNotification({
            variables: {
                input: values
            }
        }).then((response) => {
            enqueueSnackbar(intl.formatMessage({ id: 'notificationsPage.create.success' }), { variant: 'success' });
            all.resetForm();

        }).catch((err) => {
            console.log('[error]', err);
        })

        history.push(`/admin${generatePath(paths.notifications.list)}`)
    };


    const setTitle = (active) => {
        if (activeStep === 0) return intl.formatMessage({ id: 'notificationsPage.stepperTitle.filters' })
        else if (activeStep === 1) return intl.formatMessage({ id: 'notificationsPage.stepperTitle.notifications' })
        else return intl.formatMessage({ id: 'notificationsPage.stepperTitle.confirm' })
    }

    function ColorlibStepIcon(props) {
        const classes = useColorlibStepIconStyles();
        const { active, completed } = props;

        const icons = {
            1: <SettingsInputComponentIcon />,
            2: <NotificationsActiveIcon />,
            3: <SendIcon />,
        };

        return (
            <div
                className={clsx(classes.root, {
                    [classes.active]: active,
                    [classes.completed]: completed,
                })}
            >
                {icons[String(props.icon)]}
            </div>
        );
    }

    return (
        <>
            <PageHeader
                title={intl.formatMessage({ id: 'notificationsPage.stepperTitle.title' })}
                handleBackAction={(e) => history.goBack()}
            />
            <GridItem><br /></GridItem>
            <GridContainer justify="center">
                <GridItem sm={10}>
                    <IconCard
                        icon={<NotificationImportantIcon />}
                        title={setTitle(activeStep)}
                    >

                        <GridItem>
                            <Stepper alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />}>
                                {steps.map((label) => (
                                    <Step key={label}>
                                        <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
                                    </Step>
                                ))}
                            </Stepper>


                            <GridItem><hr /></GridItem>


                            {getStepContent(activeStep)}
                            <GridItem><hr /></GridItem>
                            <Box className={classes.floatRight}>
                                {(activeStep !== 0) &&
                                    <Button disabled={activeStep === 0} onClick={handleBack} className={classes.button}>
                                        {intl.formatMessage({ id: 'deviceDetail.status.back' })}
                                    </Button>
                                }
                                {(JSON.stringify(criteria) === JSON.stringify(allValues)) &&
                                    <>
                                        {(activeStep === 0 && criteriaNumber !== -1) &&
                                            <span style={{ color: criteriaNumber === 0 ? 'red' : 'green', fontSize: "1.5em" }}>{intl.formatMessage({ id: 'notificationsPage.new.button.calculateDisabled' })}: {criteriaNumber} &nbsp;&nbsp;</span>
                                        }

                                        < Button
                                            disabled={(activeStep === 0 && JSON.stringify(criteria) !== JSON.stringify(allValues) || criteriaNumber === 0)
                                                ? true
                                                : (activeStep === 1 && (!all.text || !all.screen || !all.title || !valid2))
                                                    ? true
                                                    : false

                                            }
                                            variant="contained"
                                            color="success"
                                            onClick={(activeStep === 2) ? handleCreateNotification : handleNext}
                                        >
                                            {activeStep === steps.length - 1 ? intl.formatMessage({ id: 'notificationsPage.modal.submit' }) : intl.formatMessage({ id: 'notificationsPage.stepperTitle.continueButton' })}
                                        </Button>
                                    </>
                                }
                                {(JSON.stringify(criteria) !== JSON.stringify(allValues)) &&
                                    <Button
                                        disabled={valid ? false : true}
                                        color={(JSON.stringify(criteria) === JSON.stringify(allValues))
                                            ? "primary"
                                            : "primary"
                                        }
                                        onClick={renderCalculateNumber}
                                    >
                                        {intl.formatMessage({ id: 'notificationsPage.new.button.calculate' })}
                                    </Button>
                                }
                            </Box>
                        </GridItem>

                    </IconCard>
                </GridItem>
            </GridContainer>
        </>
    );
}

export default injectIntl(NotificationNewPage);