import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { makeStyles } from "@material-ui/core/styles";
import TextField from '@material-ui/core/TextField';
import { default as React, useEffect, useState } from "react";
import { injectIntl } from "react-intl";
import styles from "common/styles/widgets.js";
import { Grid } from "@material-ui/core";
import { colors } from "common/styles/configLayout.js";
import Box from "@material-ui/core/Box";
import FormHelperText from "@material-ui/core/FormHelperText";
import STORE_DETAIL from "queries/StoresQueries/storeDetail";
import { useQuery } from "@apollo/react-hooks";
import { pathOr } from "rambda";
import Loading from "components-lib/Loading/Loading";
import RoleEnum from "common/enums/RoleEnum";
import { isInRoles } from "helpers/helpers";
import CAMPAIGN_DETAILS from "queries/CampaignsQueries/campaignDetails";
import { TimePicker } from "@material-ui/pickers";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import moment from 'moment';

const useStyles = makeStyles((theme) => styles(theme));

const days = [
    { day: "Monday" },
    { day: "Tuesday" },
    { day: "Wednesday" },
    { day: "Thursday" },
    { day: "Friday" },
    { day: "Saturday" },
    { day: "Sunday" }
]

const ScheduleCard = (props) => {

    const {
        intl,
        formikProps,
        callbackTimes,
        helperText,
        storeId,
        campaignId,
        withoutOpenInfo,
        readOnly,
        tms
    } = props;



    const { data: storeData, loading } = useQuery(STORE_DETAIL, { variables: { id: storeId }, skip: !storeId });
    const { data: campaignData } = useQuery(CAMPAIGN_DETAILS, { variables: { id: campaignId }, skip: !campaignId });

    const classes = useStyles();

    const helpTimes = (storeData) ? formikProps?.values?.zones?.[0]?.preferences?.activeTimes : formikProps?.values?.campaign?.activeTimes

    const [times, setTimes] = useState((!tms)
        ? (storeData)
            ? pathOr([], ['store', 'zones', [0], 'preferences', 'activeTimes'], storeData)
            : (pathOr([], ['campaign', 'activeTimes'], campaignData))
        : tms)

    useEffect(() => {
        setTimes((!tms)
            ? (storeData)
                ? pathOr([], ['store', 'zones', [0], 'preferences', 'activeTimes'], storeData)
                : (pathOr([], ['campaign', 'activeTimes'], campaignData))
            : tms)
    }, [storeData, campaignData, tms]);

    const handleCheckIsOpen = (day) => {
        let help = null
        if (times?.length > 0)
            times.filter(filt => filt.weekDay.includes(day)).map(item => (
                help = true
            ))

        if (help === true) return true
        else return false
    }

    const handleGiveFrom = (day) => {
        let help = null
        if (times?.length > 0)
            times.filter(filt => filt.weekDay.includes(day)).map(item => (
                help = item.from
            ))

        return help
    }

    const handleGiveTo = (day) => {
        let help = null
        if (times?.length > 0)
            times.filter(filt => filt.weekDay.includes(day)).map(item => (
                help = item.to
            ))
        return help
    }

    const handleCheckIsChanged = (day) => {
        let originalValue = { weekDay: day, from: null, to: null }
        let currentValue = { weekDay: day, from: null, to: null }
        if (helpTimes?.length > 0)
            (helpTimes).filter(filt => filt.weekDay.includes(day)).map(item => (
                originalValue = item
            ))

        if (times?.length > 0)
            times.filter(filt => filt.weekDay.includes(day)).map(item => (
                currentValue = item
            ))

        let inputStyles = ((originalValue?.from?.toString().substring(0, 5) !== currentValue?.from?.toString().substring(0, 5))
            || originalValue?.to?.toString().substring(0, 5) !== currentValue?.to?.toString().substring(0, 5))
            ? { backgroundColor: '#FFF8CC' }
            : {};

        return (inputStyles)
    }

    const handleTimeChange = (day, from, to, checked, type) => {
        let helpHours = times
        let helpNumber = 1

        let fromFinal = (type === "from") ? moment(from).format("HH:mm:ss") : from
        let toFinal = (type === "to") ? moment(to).format("HH:mm:ss") : to

        helpHours.map((item, index) => {
            if (checked === true) {
                if (item.weekDay === day) {
                    delete helpHours[index]
                }
                helpNumber = 1
            }
            else {
                if (item.weekDay === day) {
                    delete helpHours[index]
                    helpNumber = 0
                }
            }

        })
        if (helpNumber === 1) {
            helpHours.push({
                weekDay: day,
                from: fromFinal,
                to: toFinal
            })
        }
        let j = 0
        let arr = []
        for (let i = 0; i < helpHours.length; i++) {
            if (helpHours[i]) {
                arr[j] = helpHours[i]
                j++
            }
        }
        handleSetTimes(arr)
    }

    const handleSetTimes = (arr) => {
        callbackTimes(arr)
    }

    const [selectedDate, handleDateChange] = useState(new Date());


    const renderContent = () => {
        if (!isInRoles([RoleEnum.ADMIN, RoleEnum.MAINTAINER]) || readOnly) {
            return (
                <>
                    {days?.map((item, index) => (
                        <Grid container spacing={2} key={index}>
                            {handleCheckIsOpen(item.day) ?
                                <Grid item sm={12}>
                                    {intl.formatMessage({ id: `weekDay.${item.day}` })}
                                    <Box className={classes.floatRight}>
                                        {handleGiveFrom(item.day)} - {handleGiveTo(item.day)}
                                    </Box>
                                </Grid>
                                :
                                <Grid item sm={12}>
                                    {intl.formatMessage({ id: `weekDay.${item.day}` })}
                                    <Box className={classes.floatRight}>-</Box>
                                </Grid>
                            }
                        </Grid >
                    ))}
                </>
            )
        }
        return (
            <MuiPickersUtilsProvider
                utils={DateFnsUtils}


            >
                {days?.map((item, index) => (
                    <Grid container spacing={1} style={handleCheckIsChanged(item.day)}

                    >

                        <Grid item sm={1}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        style={{ color: colors.main, marginRight: '-20px' }}
                                        checked={handleCheckIsOpen(item.day)}
                                        onClick={(e) => handleTimeChange(item.day, '00:00:00', '23:59:00', e.target.checked)}
                                    />
                                }
                                classes={{ label: classes.label }}
                                label={props.day}
                            />
                        </Grid>
                        {handleCheckIsOpen(item.day) ?
                            <>
                                <Grid item sm={7}>
                                    <p style={{ marginTop: '10px' }}>
                                        {intl.formatMessage({ id: `weekDay.${item.day}` })} {(!withoutOpenInfo) && <span style={{ color: "green" }}>({intl.formatMessage({ id: 'storeDetail.openingHours.open' })})</span>}
                                    </p>
                                </Grid>
                                <Grid item sm={2}>
                                    <TimePicker
                                        showTodayButton
                                        todayLabel="teraz"
                                        ampm={false}
                                        value={new Date(`May 04, 2025 ${handleGiveFrom(item.day)}`)}
                                        minutesStep={5}
                                        onChange={(value) => handleTimeChange(item.day, value, handleGiveTo(item.day), true, 'from')}
                                    />
                                </Grid>
                                <Grid item sm={2}>
                                    <TimePicker
                                        showTodayButton
                                        todayLabel="teraz"
                                        ampm={false}
                                        value={new Date(`May 04, 2025 ${handleGiveTo(item.day)}`)}
                                        minutesStep={5}
                                        onChange={(value) => handleTimeChange(item.day, handleGiveFrom(item.day), value, true, 'to')}
                                    />
                                </Grid>

                                {/*<TextField
                                        id="time"
                                        type="time"
                                        value={handleGiveFrom(item.day)}
                                        className={classes.textField}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        inputProps={{
                                            step: 300,
                                        }}
                                        onChange={(e) => handleTimeChange(item.day, e.target.value, handleGiveTo(item.day), true)}
                                    />
                                    <label>   {intl.formatMessage({ id: "commissionsList.table.pickerEnd" })} </label>
                                    <TextField
                                        id="time"
                                        type="time"
                                        className={classes.textField}
                                        value={handleGiveTo(item.day)}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        inputProps={{
                                            step: 300, // 5 min
                                        }}
                                        onChange={(e) => handleTimeChange(item.day, handleGiveFrom(item.day), e.target.value, true)}
                                    />*/}

                            </>
                            :
                            <Grid item sm={7}>
                                <p style={{ marginTop: '10px' }}>
                                    {intl.formatMessage({ id: `weekDay.${item.day}` })} {(!withoutOpenInfo) && <span style={{ color: "red" }}>({intl.formatMessage({ id: 'storeDetail.openingHours.closed' })})</span>}
                                </p>
                            </Grid>
                        }
                    </Grid >
                ))}
                <Grid item sm={12}>
                    {helperText && <FormHelperText>{helperText}</FormHelperText>}
                </Grid>
            </MuiPickersUtilsProvider>
        )
    }

    if (loading) return <Loading />;
    return (<>{renderContent()}</>)
};

export default injectIntl(ScheduleCard);
//769