import gql from 'graphql-tag';

const USER_TRANSFER_CREDIT = gql`
mutation userTransferCredit($amount:UnsignedFloat!, $sourceUserId: ID!, $targetUserId: ID! ) {
    userTransferCredit(
      amount:$amount, 
      sourceUserId: $sourceUserId, 
      targetUserId: $targetUserId
    ) 
}
`;

export default USER_TRANSFER_CREDIT;