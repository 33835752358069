import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import { Grid } from '@material-ui/core';
import Icon from "@material-ui/core/Icon";
import { injectIntl } from 'react-intl';

// @material-ui/icons
import Email from "@material-ui/icons/Email";
// import LockOutline from "@material-ui/icons/LockOutline";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";

import customStyles from './LoginPageCustomStyles';
import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";
import { useMutation } from '@apollo/react-hooks';
import { gql } from "apollo-boost";
import { processLogin, getSelectedLanguage } from 'helpers/helpers';
import { withRouter } from "react-router-dom";
import paths from "../../paths";
import { IntlContext } from "index";
import getValidationSchema from "./LoginValidationSchema";
import { Formik } from "formik";
import FormikField from "../../components-lib/FormikField/FormikField";
import { useSnackbar } from "notistack";

const useStyles = makeStyles(styles);
const useCustomStyles = makeStyles(customStyles);

const LOGIN_MUTATION = gql`
mutation login ($email: EmailAddress!, $password: String!, $keepLoggedIn: Boolean) {
  login(email: $email, password: $password, keepLoggedIn: $keepLoggedIn) {
    token
    user {
      id
      firstName
      lastName
      role
      preferences {
        language
      }
    }
    expiresAt
  }
}
`;

const LoginPage = (props) => {
  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");

  const { history, intl } = props;
  const { enqueueSnackbar } = useSnackbar();

  const [login] = useMutation(LOGIN_MUTATION);



  React.useEffect(() => {
    let id = setTimeout(function () {
      setCardAnimation("");
    }, 700);
    // Specify how to clean up after this effect:
    return function cleanup() {
      window.clearTimeout(id);
    };
  });

  const handleLogin = (changeLanguage, values) => {
    //console.log('[handle]', values);
    login({
      variables: {
        ...values
      }
    }).then(response => {
      processLogin(response);
      changeLanguage(getSelectedLanguage())
      history.push(`/admin${paths.dashboard}`)
      enqueueSnackbar(intl.formatMessage({ id: 'loginPage.login.success' }), { variant: 'success' })
    }).catch(err => {
      console.log('[err]', err);
    })
  };

  const handleKeyDown = (e, changeLanguage, values) => {
    if (e.key === 'Enter') {
      handleLogin(changeLanguage, values);
    }
  };

  const classes = useStyles();
  const customClasses = useCustomStyles();

  return (
    <IntlContext.Consumer>{(intlContextProps) => (
      <div className={classes.container}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={8} md={6} lg={4}>
            <form>
              <Card login className={classes[cardAnimaton]}>
                <CardHeader
                  className={`${classes.cardHeader} ${classes.textCenter}`}
                  color="primary"
                >
                  <h4 className={classes.cardTitle}>{intl.formatMessage({ id: 'loginPage.cardTitle' })}</h4>
                </CardHeader>
                <Formik
                  enableReinitialize
                  validationSchema={getValidationSchema(intl)}
                  onSubmit={(values) => {
                    handleLogin(intlContextProps.handleLanguageChange, values);
                  }}
                  initialValues={{
                    email: '',
                    password: '',
                    keepLoggedIn: false
                  }}
                >
                  {(formikProps) => (
                    <>
                      <CardBody>
                        <FormikField
                          highlightChange={false}
                          name="email"
                          labelText={intl.formatMessage({ id: 'loginPage.email' })}
                          formikProps={formikProps}
                          inputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <Email className={classes.inputAdornmentIcon} />
                              </InputAdornment>
                            )
                          }}
                        />

                        <FormikField
                          highlightChange={false}
                          name="password"
                          labelText={intl.formatMessage({ id: 'loginPage.password' })}
                          formikProps={formikProps}
                          inputProps={{
                            onKeyDown: (e) => handleKeyDown(e, intlContextProps.handleLanguageChange, formikProps.values),
                            type: "password",
                            autoComplete: "off",
                            endAdornment: (
                              <InputAdornment position="end">
                                <Icon className={classes.inputAdornmentIcon}>
                                  lock_outline
                                  </Icon>
                              </InputAdornment>
                            )
                          }}
                        />
                        <Grid item>
                          <br />
                          <FormikField
                            labelText={intl.formatMessage({ id: 'loginPage.stayLogged' })}
                            type="checkbox"
                            name="keepLoggedIn"
                            formikProps={formikProps}
                          />
                        </Grid>
                      </CardBody>
                      <CardFooter
                        className={classes.justifyContentCenter}
                        style={{
                          width: '100%',
                          margin: '0px',
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          padding: '20px'
                        }}
                      >
                        <Button
                          color="primary"
                          size="md"
                          round
                          onClick={() => {
                            formikProps.handleSubmit();
                          }}
                        >
                          {intl.formatMessage({ id: 'loginPage.submit' })}
                        </Button>
                        <Button
                          simple
                          color="primary"
                          size="sm"
                          round
                          onClick={() => {
                            history.push(`/auth${paths.auth.forgottenPassword}`)
                          }}
                        >
                          {intl.formatMessage({ id: 'loginPage.forgottenPassword' })}
                        </Button>
                        <div className={customClasses.languagesWrapper}>
                          <span
                            className={`${customClasses.language} ${intlContextProps.selectedLanguage === 'sk' && customClasses.selected}`}
                            onClick={() => intlContextProps.handleLanguageChange('sk')}
                          >
                            SK
                            </span>
                          <span
                            className={`${customClasses.language} ${intlContextProps.selectedLanguage === 'en' && customClasses.selected}`}
                            onClick={() => intlContextProps.handleLanguageChange('en')}
                          >
                            EN
                            </span>
                        </div>
                      </CardFooter>
                    </>
                  )}
                </Formik>
              </Card>
            </form>
          </GridItem>
        </GridContainer>
      </div>
    )}</IntlContext.Consumer>
  );
};

export default injectIntl(withRouter(LoginPage));
