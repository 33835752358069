import React from "react";
import { Grid } from "@material-ui/core";
import { injectIntl } from "react-intl";
import PersonIcon from "@material-ui/icons/Person";
import IconCard from "components-lib/IconCard/IconCard";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
} from "recharts";
import { chartColors } from "common/styles/configLayout.js";
import moment from "moment";

const AmbassadorReferralsChart = (props) => {
  const { intl, allReferrals } = props;

  let data = [];
  let sumUsers = 0;
  for (let i = 0; i < allReferrals?.length; i++) {
    let helpDate = moment(allReferrals[i]?.date).format("DD.MM.YYYY");
    let date = helpDate;
    let scans = allReferrals[i]?.count;

    data[i] = {
      name: date,
      scans: scans,
    };
    sumUsers += data[i].scans;
  }

  const legendData = [
    {
      id: 1,
      name: intl.formatMessage({
        id: "userDetail.ambassadorReferralsChart.barTitle",
      }),
      color: chartColors.one,
    },
  ];

  return (
    <>
      <ResponsiveContainer width="100%" height={200}>
        <BarChart
          data={data}
          margin={{ top: 5, left: -25, right: 10, bottom: 15 }}
        >
          <CartesianGrid stroke="#f5f5f5" />
          <XAxis dataKey="name" />
          <YAxis allowDecimals={false} />
          <Tooltip />
          <Bar
            name={intl.formatMessage({
              id: "userDetail.ambassadorReferralsChart.barTitle",
            })}
            dataKey="scans"
            stackId="a"
            barSize={25}
            fill={chartColors.one}
          />
          <Legend
            wrapperStyle={{ bottom: -20 }}
            payload={legendData.map((item) => ({
              id: item.id,
              type: "square",
              color: item.color,
              value: item.name,
            }))}
          />
        </BarChart>
      </ResponsiveContainer>
      <br />
      <hr />
      <Grid container>
        <Grid item sm={12}>
          <b>
            {intl.formatMessage({
              id: "dashboard.printedPhotosCard.allPhotos",
            })}
            : {sumUsers}
          </b>
        </Grid>
      </Grid>
    </>
  );
};

export default injectIntl(AmbassadorReferralsChart);
