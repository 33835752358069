import gql from 'graphql-tag';

const COMMISSIONS_EXPORT = gql`
mutation companyCommissionsExport($input: CommissionsExportInput) {
  companyCommissionsExport(input: $input) {
    type
    url
    expiresAt
  }
}`

export default COMMISSIONS_EXPORT