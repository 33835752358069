import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import IconCard from "components-lib/IconCard/IconCard";
import PageHeader from "components-lib/PageHeader/PageHeader";
import React from "react";
import { injectIntl } from "react-intl";
import OrderTable from "./OrderTable";
import { useLocation } from 'react-router-dom';
import ALL_ORDERS from 'queries/OrdersQueries/allOrders';

const OrderListPage = (props) => {
  const { intl, history } = props;
  let location = useLocation();
  let defaultFilter = location.state ? location.state : null

  return (
    <>
      <PageHeader
        title={intl.formatMessage({ id: "orderList.title" })}
        withBackButton={(location.state) ? true : false}
      />
      <IconCard
        icon={<ShoppingBasketIcon />}
        title={intl.formatMessage({ id: "orderList.table.title" })}
      >
        <OrderTable
          queryDataPath={['ordersTable', 'items']}
          query={ALL_ORDERS}
          defaultFilter={defaultFilter}
          history={history}
        />
      </IconCard>
    </>
  );
};
export default injectIntl(OrderListPage);