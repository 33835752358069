import React, { useEffect, useState } from 'react';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import IconCard from 'components-lib/IconCard/IconCard';
import PageHeader from 'components-lib/PageHeader/PageHeader';
import paths from 'paths';
import { withRouter, useLocation } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import RoleEnum from 'common/enums/RoleEnum';
import { isInRoles } from 'helpers/helpers';
import UserTable from './UserTable'
import ALL_USERS from 'queries/UsersQueries/allUsers';
import Grid from "@material-ui/core/Grid";
import Button from "components/CustomButtons/Button.js";
import Modal from "components-lib/Modal/Modal";
import { Formik } from "formik";
import FormikField from "components-lib/FormikField/FormikField";
import { getAmbassadorExportValidation } from './validationSchema';
import AMBASSADOR_REFERRALS_EXPORT from 'queries/AmbassadorQueries/ambassadorReferralsExport';
import { useMutation } from 'react-apollo';
import { useSnackbar } from "notistack";
import moment from 'moment';

const UsersPage = (props) => {
    const { history, intl } = props;
    const { enqueueSnackbar } = useSnackbar();
    let location = useLocation();
    let defaultFilter = location.state ? location.state : null
    const [ambassadorReferralsExport] = useMutation(AMBASSADOR_REFERRALS_EXPORT);
    const [backButton, setBackButton] = useState(false)
    const [modalOpen, setModalOpen] = useState(false)

    const handleAmbassadorReferralsExport = (values, resetForm) => {
        ambassadorReferralsExport({
            variables: {
                input: {
                    from: moment(values.from).format("YYYY-MM-DD"),
                    to: moment(values.to).format("YYYY-MM-DD"),
                    emails: values.emails
                },
            },
        }).then((response) => {
            window.open(response.data.ambassadorReferralsExport.url);
            enqueueSnackbar(
                intl.formatMessage({ id: "userDetail.ambassadorExport.success" }),
                { variant: "success" }
            );
        }).catch((err) => {
            console.log("[error]", err);
        });
        setModalOpen(false)
        resetForm();
    }


    useEffect(() => {
        setBackButton(location.state ? true : false)
    }, [backButton, location.state]);

    const getUsersCreateActions = () => {
        const actions = [
            {
                title: intl.formatMessage({ id: 'usersPage.actions.newPartner' }),
                onClick: (e) => {
                    history.push(`/admin${paths.users.create.partner}`);
                }
            },
            {
                title: intl.formatMessage({ id: 'usersPage.actions.newAdvertiser' }),
                onClick: (e) => {
                    history.push(`/admin${paths.users.create.advertiser}`);
                }
            },
            {
                title: intl.formatMessage({ id: 'usersPage.actions.newMaintainer' }),
                onClick: (e) => {
                    history.push(`/admin${paths.users.create.maintainer}`);
                }
            },
            {
                title: "Ambasádori export",
                onClick: (e) => {
                    setModalOpen(true);
                }
            }
        ];

        if (isInRoles([RoleEnum.ADMIN, RoleEnum.MAINTAINER])) {
            if (!isInRoles([RoleEnum.ADMIN])) {
                actions.pop();
            }
            return actions;
        }
        return [];
    };

    return (
        <>
            <PageHeader
                title={intl.formatMessage({ id: 'usersPage.title' })}
                actions={getUsersCreateActions()}
                withBackButton={location.state ? true : false}
            />
            <IconCard
                icon={<AssignmentIndIcon />}
                title={intl.formatMessage({ id: 'usersPage.table.title' })}>
                <UserTable
                    queryDataPath={['usersTable', 'items']}
                    query={ALL_USERS}
                    defaultFilter={defaultFilter}
                    history={history}
                />
            </IconCard>
            <Modal>
                <Formik
                    enableReinitialize
                    initialValues={{}}
                    onSubmit={(values, { resetForm }) => handleAmbassadorReferralsExport(values, resetForm)}
                    validationSchema={getAmbassadorExportValidation(intl)}
                >
                    {(formikProps) => (
                        <Modal
                            open={modalOpen}
                            onClose={() => setModalOpen(false)}
                            title={intl.formatMessage({
                                id: "userDetail.ambassadorExport.modal.title",
                            })}
                            maxWidth="sm"
                            fullWidth
                            actions={
                                <>
                                    <Button
                                        onClick={(e) => formikProps.handleSubmit()}
                                        disabled={(!formikProps?.values?.from) || (!formikProps?.values?.to) || (!formikProps?.values?.emails)}
                                        color="success"
                                        round
                                        size="sm"
                                    >
                                        {intl.formatMessage({
                                            id: "userDetail.ambassadorExport.modal.button",
                                        })}
                                    </Button>
                                    <Button
                                        size="sm"
                                        color="primary"
                                        onClick={() => setModalOpen(false)}
                                        round
                                    >
                                        {intl.formatMessage({
                                            id: "invoicesList.assignModal.cancel",
                                        })}
                                    </Button>
                                </>
                            }
                        >
                            <Grid container spacing={3}>
                                <Grid item sm={6}>
                                    <FormikField
                                        name="from"
                                        labelText={intl.formatMessage({ id: 'queryTable.filter.pickerStart' })}
                                        formikProps={formikProps}
                                        data={props.values}
                                        highlightChange
                                        type="datePicker2"
                                    />
                                </Grid>
                                <Grid item sm={6}>
                                    <FormikField
                                        name="to"
                                        labelText={intl.formatMessage({ id: 'queryTable.filter.pickerEnd' })}
                                        formikProps={formikProps}
                                        data={props.values}
                                        highlightChange
                                        type="datePicker2"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormikField
                                        name="emails"
                                        labelText={intl.formatMessage({ id: 'userDetail.ambassadorExport.modal.emails' })}
                                        formikProps={formikProps}
                                        type="emailParser"
                                        highlightChange
                                        helperText={intl.formatMessage({ id: 'notificationsPage.new.basicInfoCard.emailLabel' })}
                                    />
                                </Grid>
                            </Grid>
                        </Modal>
                    )}
                </Formik>

            </Modal>
        </>
    )
};

export default withRouter(injectIntl(UsersPage));