import React, { useState } from "react";
import IconCard from "components-lib/IconCard/IconCard";
import Grid from "@material-ui/core/Grid";
import { injectIntl } from "react-intl";
import FormikField from "components-lib/FormikField/FormikField";
import styles from "common/styles/widgets.js";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import { isEmpty } from "rambda";
import Button from "components/CustomButtons/Button.js";
import { useMutation } from "@apollo/react-hooks";
import { useSnackbar } from "notistack";
import Switch from "@material-ui/core/Switch";
import { Typography } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/core";
import { createMuiTheme } from "@material-ui/core";
import colors from "common/styles/colors";
import AMBASSADOR_ENABLE from "queries/AmbassadorQueries/ambassadorEnable";
import AMBASSADOR_DISABLE from "queries/AmbassadorQueries/ambassadorDisable";
import WcIcon from "@material-ui/icons/Wc";
import ALL_SETTINGS from "queries/SettingsQueries/allSettings";
import { useQuery } from "@apollo/react-hooks";
import Modal from 'components-lib/Modal/Modal';

const useStyles = makeStyles((theme) => styles(theme));

const theme = createMuiTheme({
  overrides: {
    MuiSwitch: {
      switchBase: {
        // Controls default (unchecked) color for the thumb
        color: colors.success,
      },
      colorSecondary: {
        "&$checked": {
          // Controls checked color for the thumb
          color: colors.primary,
        },
      },
      track: {
        // Controls default (unchecked) color for the track
        opacity: 0.7,
        backgroundColor: colors.success,
        "$checked$checked + &": {
          // Controls checked color for the track
          opacity: 0.7,
          backgroundColor: colors.primary,
        },
      },
    },
  },
});

const AmbassadorCard = (props) => {
  const {
    intl,
    formikProps,
    created,
    canEditUser,
    type,
    isEnabled,
    isAmbassador,
    qrcodeUrl,
    disabled = false,
    id,
    refetchUserData,
    editableFields,
  } = props;
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { data } = useQuery(
    ALL_SETTINGS
  );
  const [ambassadorEnable] = useMutation(AMBASSADOR_ENABLE);
  const [ambassadorDisable] = useMutation(AMBASSADOR_DISABLE);

  const [enableAmbassador, setEnableAmbassador] = useState(isEnabled);
  const [typeAmbassador, setTypeAmbassador] = useState(
    type === "Money" ? true : false
  );
  const [openModal, setOpenModal] = useState(false);

  const handleChangeEnableAmbassador = (event) => {
    if (!formikProps.values.ambassador) {
      formikProps.setFieldValue("ambassador.inheritSettings", true)
      formikProps.setFieldValue("ambassador.customerReward", data?.settings?.ambassador?.customerReward)
      if (typeAmbassador === false)
        formikProps.setFieldValue("ambassador.referralReward", data?.settings?.ambassador?.referralCreditReward)
      else
        formikProps.setFieldValue("ambassador.referralReward", data?.settings?.ambassador?.referralMoneyReward)
    }

    setEnableAmbassador(event.target.checked);
  };

  const handleChangeTypeAmbassador = (event) => {
    if (!isAmbassador) {
      if (typeAmbassador === false)
        formikProps.setFieldValue("ambassador.referralReward", data?.settings?.ambassador?.referralMoneyReward)
      else
        formikProps.setFieldValue("ambassador.referralReward", data?.settings?.ambassador?.referralCreditReward)
    }

    setTypeAmbassador(event.target.checked);
  };

  const renderQRCodeModal = () => {
    return <>
      <Modal
        title={intl.formatMessage({ id: "userDetail.ambassadorCart.qrCodeModal.title" })}
        open={openModal}
        onClose={() => setOpenModal(false)}
        actions={
          <Button
            onClick={() => setOpenModal(false)}
            color="primary"
            outlined
            round
            size="sm"
          >
            {intl.formatMessage({ id: "pageHeader.back" })}
          </Button>}
      >
        <img src={qrcodeUrl} alt={qrcodeUrl} />
      </Modal>
    </>
  }

  const handleAmbassadorEnable = (values) => {
    if (enableAmbassador === true) {
      ambassadorEnable({
        variables: {
          userId: id,
          input: {
            type: typeAmbassador === true ? "Money" : "Credit",
            customerReward: values?.ambassador?.customerReward,
            referralReward: values?.ambassador?.referralReward,
            inheritSettings: values.ambassador?.inheritSettings,
          },
        },
      })
        .then((response) => {
          enqueueSnackbar(
            intl.formatMessage({
              id: "userDetail.ambassadorCart.enabled.success",
            }),
            { variant: "success" }
          );
          refetchUserData();
        })
        .catch((err) => {
          console.log("[error]", err);
        });
    } else {
      ambassadorDisable({
        variables: {
          userId: id,
        },
      })
        .then((response) => {
          enqueueSnackbar(
            intl.formatMessage({
              id: "userDetail.ambassadorCart.disabled.success",
            }),
            { variant: "success" }
          );
          refetchUserData();
        })
        .catch((err) => {
          console.log("[error]", err);
        });
    }
  };

  const renderContent = () => {
    return (
      <>
        <Grid item sm={12}>
          <span style={{ marginBottom: "40px" }}>
            {intl.formatMessage({
              id: "userDetail.ambassadorCart.function",
            })}
            :
          </span>
          <Box className={classes.floatRight} style={{ marginTop: "-10px" }}>
            <Grid component="label" container alignItems="center" spacing={0}>
              <Grid item>
                {intl.formatMessage({
                  id: "userDetail.ambassadorCart.function.disabled",
                })}
              </Grid>
              <Grid item>
                <Switch
                  checked={enableAmbassador}
                  onChange={handleChangeEnableAmbassador}
                  name="enableAmbassador"
                  classes={{ disabled: classes.disabledButton }}
                />
              </Grid>
              <Grid item>
                {intl.formatMessage({
                  id: "userDetail.ambassadorCart.function.enabled",
                })}
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <br />
        {enableAmbassador && (
          <>
            <hr style={{ padding: "-10px 0px -10px 0px" }} />

            <Grid item sm={12}>
              <Typography variant="p">
                {intl.formatMessage({
                  id: "userDetail.ambassadorCart.type",
                })}
              </Typography>
              <Box
                className={classes.floatRight}
                style={{ marginTop: "-10px" }}
              >
                <ThemeProvider theme={theme}>
                  <Typography component="div">
                    <Grid
                      component="label"
                      container
                      alignItems="center"
                      spacing={0}
                    >
                      <Grid item>
                        {intl.formatMessage({
                          id: "userDetail.ambassadorCart.type.credit",
                        })}
                      </Grid>
                      <Grid item>
                        <Switch
                          classes={{ disabled: classes.disabledButton }}
                          checked={typeAmbassador}
                          onChange={handleChangeTypeAmbassador}
                          name="typeAmbassador"
                        />
                      </Grid>
                      <Grid item>
                        {intl.formatMessage({
                          id: "userDetail.ambassadorCart.type.money",
                        })}
                      </Grid>
                    </Grid>
                  </Typography>
                </ThemeProvider>
              </Box>
            </Grid>
            <br />
            <FormikField
              disabled={true}
              labelText={intl.formatMessage({
                id: "userDetail.ambassadorCart.inheritSettings",
              })}
              type="checkbox"
              name="ambassador.inheritSettings"
              formikProps={formikProps}
            />
            {!formikProps?.values?.ambassador?.inheritSettings && (
              <>
                <FormikField
                  disabled={formikProps.values.ambassador?.inheritSettings}
                  name="ambassador.customerReward"
                  labelText={intl.formatMessage({
                    id: "settings.ambassadorSection.customerReward",
                  })}
                  formikProps={formikProps}
                  highlightChange
                />
                <FormikField
                  disabled={formikProps.values.ambassador?.inheritSettings}
                  name="ambassador.referralReward"
                  labelText={
                    <>
                      {intl.formatMessage({
                        id: "settings.ambassadorSection.referralCreditReward",
                      })}
                      {typeAmbassador === false ? " (C)" : " (€)"}
                    </>
                  }
                  formikProps={formikProps}
                  highlightChange
                />
              </>
            )}
          </>
        )}
        {!created && (
          <Grid item sm={12}>
            <br />
            <Button
              color="primary"
              size="sm"
              round
              onClick={(e) =>
                isEmpty(formikProps.errors) &&
                handleAmbassadorEnable(formikProps.values)
              }
            >
              {intl.formatMessage({ id: "userDetail.buttons.confirm" })}
            </Button>
            <Button
              color="info"
              size="sm"
              round
              onClick={() => formikProps.resetForm()}
            >
              {intl.formatMessage({ id: "settings.cancel" })}
            </Button>
            <span style={{ float: 'right' }}>
              <Button
                color="info"
                size="sm"
                round
                onClick={() => setOpenModal(true)}
              >
                Zobraziť QR kód
              </Button>
            </span>
          </Grid>
        )}
      </>
    );
  };

  return (
    <>
      <IconCard
        title={intl.formatMessage({
          id: "userDetail.ambassadorCart.title",
        })}
        icon={<WcIcon />}
      >
        {renderContent()}
      </IconCard>
      {renderQRCodeModal()}
    </>
  );
};

export default injectIntl(AmbassadorCard);
