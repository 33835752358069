import React from "react";
import PageHeader from "components-lib/PageHeader/PageHeader";
import IconCard from "components-lib/IconCard/IconCard";
import { Grid } from "@material-ui/core";
import { useState } from "react";
import { useQuery, useMutation } from "react-apollo";
import { Formik } from "formik";
import { useEffect } from "react";
import { pathOr } from "rambda";
import FormikField from "components-lib/FormikField/FormikField";
import Button from "components/CustomButtons/Button.js";
import { removeTypenameFromObject } from "../../helpers/helpers";
import { useSnackbar } from "notistack";
import getValidationSchema from "./validationSchema";
import { injectIntl } from "react-intl";
import TimerIcon from "@material-ui/icons/Timer";
import EuroIcon from "@material-ui/icons/Euro";
import NotificationsIcon from "@material-ui/icons/Notifications";
import AirplayIcon from "@material-ui/icons/Airplay";
import CreditIcon from "components-lib/Icons/CreditIcon/CreditIcon";
import LiveHelpIcon from "@material-ui/icons/LiveHelp";
import ShoppingBasketIcon from "@material-ui/icons/ShoppingBasket";
import Loading from "components-lib/Loading/Loading";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import ALL_SETTINGS from "queries/SettingsQueries/allSettings";
import UPDATE_SETTINGS from "queries/SettingsQueries/updateSettings";
import ABOUT from "queries/SettingsQueries/about";
import Box from "@material-ui/core/Box";
import styles from "common/styles/widgets.js";
import { makeStyles } from "@material-ui/core/styles";
import ContactSupportIcon from "@material-ui/icons/ContactSupport";
import SecurityIcon from "@material-ui/icons/Security";
import WcIcon from "@material-ui/icons/Wc";
import LocalAtmIcon from "@material-ui/icons/LocalAtm";

const useStyles = makeStyles((theme) => styles(theme));

const SettingsPage = ({ intl }) => {
    const classes = useStyles();

    const [settings, setSettings] = useState(null);

    const { data, loading, refetch: refetchSettingsData } = useQuery(
        ALL_SETTINGS
    );
    const { data: dataAbout } = useQuery(ABOUT);
    const [updateSettings] = useMutation(UPDATE_SETTINGS);
    let json = require("../../../package.json");
    const { enqueueSnackbar } = useSnackbar();
    useEffect(() => {
        setSettings(pathOr(null, ["settings"], data));
    }, [data]);

    const handleUpdateSettings = (values) => {
        const result = { ...values };
        delete result.__typename;


        removeTypenameFromObject(values);
        removeTypenameFromObject(values.cashback);
        removeTypenameFromObject(values.pricing);
        removeTypenameFromObject(values.pricing.levels);
        updateSettings({
            variables: {
                input: result
            }
        }).then((response) => {
            enqueueSnackbar(intl.formatMessage({ id: 'settings.update.success' }), { variant: 'success' });
            refetchSettingsData();
        }).catch((err) => {
            console.log('[error]', err)
        });
    }


    if (loading) return <Loading />;
    return (
        <>
            <PageHeader
                withBackButton={false}
                title={intl.formatMessage({ id: "settings.title" })}
            />

            <Formik
                enableReinitialize
                initialValues={settings}
                validationSchema={getValidationSchema(intl)}
                onSubmit={(values) => handleUpdateSettings(values)}
            >

                {(props) => (
                    <>
                        <Grid container spacing={3}>
                            <Grid item md={6} lg={4} sm={12}>
                                <IconCard
                                    icon={<CreditIcon raw />}
                                    title={intl.formatMessage({
                                        id: "settings.creditSection.title",
                                    })}
                                >
                                    <FormikField
                                        name="credit.value"
                                        labelText={intl.formatMessage({
                                            id: "settings.creditSection.creditValue",
                                        })}
                                        formikProps={props}
                                        highlightChange
                                    />
                                    <FormikField
                                        name="credit.registrationAward"
                                        labelText={intl.formatMessage({
                                            id: "settings.creditSection.registrationAward",
                                        })}
                                        formikProps={props}
                                        highlightChange
                                    />
                                </IconCard>
                                <IconCard
                                    icon={<EuroIcon />}
                                    title={intl.formatMessage({
                                        id: "settings.commissionsSection.title",
                                    })}
                                >
                                    <FormikField
                                        name="commission.creditValue"
                                        labelText={intl.formatMessage({
                                            id: "settings.commissionsSection.creditValue",
                                        })}
                                        formikProps={props}
                                        highlightChange
                                    />
                                    <FormikField
                                        name="commission.lowerRate"
                                        labelText={intl.formatMessage({
                                            id: "settings.commissionsSection.lower",
                                        })}
                                        formikProps={props}
                                        highlightChange
                                    />
                                    <FormikField
                                        name="commission.higherRate"
                                        labelText={intl.formatMessage({
                                            id: "settings.commissionsSection.higher",
                                        })}
                                        formikProps={props}
                                        highlightChange
                                    />
                                    <FormikField
                                        name="commission.threshold"
                                        labelText={intl.formatMessage({
                                            id: "settings.commissionsSection.treshold",
                                        })}
                                        formikProps={props}
                                        highlightChange
                                    />
                                    <FormikField
                                        name="commission.photosPerMonthThreshold"
                                        labelText={intl.formatMessage({
                                            id: "settings.commissionsSection.photosPerMonthThreshold",
                                        })}
                                        formikProps={props}
                                        highlightChange
                                    />
                                </IconCard>
                                <IconCard
                                    icon={<WcIcon />}
                                    title={intl.formatMessage({
                                        id: "settings.ambassadorSection.title",
                                    })}
                                >
                                    <FormikField
                                        name="ambassador.customerReward"
                                        labelText={intl.formatMessage({
                                            id: "settings.ambassadorSection.customerReward",
                                        })}
                                        formikProps={props}
                                        highlightChange
                                    />
                                    <FormikField
                                        name="ambassador.referralCreditReward"
                                        labelText={
                                            <>
                                                {intl.formatMessage({
                                                    id: "settings.ambassadorSection.referralCreditReward",
                                                })}{" "}
                                                (C)
                                            </>
                                        }
                                        formikProps={props}
                                        highlightChange
                                    />
                                    <FormikField
                                        name="ambassador.referralMoneyReward"
                                        labelText={
                                            <>
                                                {intl.formatMessage({
                                                    id: "settings.ambassadorSection.referralMoneyReward",
                                                })}{" "}
                                                (€)
                                            </>
                                        }
                                        formikProps={props}
                                        highlightChange
                                    />
                                    {/*<FormikField
                    name="ambassador.claimMinutesLimit"
                    labelText={intl.formatMessage({
                      id: "settings.ambassadorSection.claimMinutesLimit",
                    })}
                    formikProps={props}
                    highlightChange
                  />*/}
                                </IconCard>
                            </Grid>
                            <Grid item md={6} lg={4} sm={12}>
                                <IconCard
                                    icon={<TimerIcon />}
                                    title={intl.formatMessage({
                                        id: "settings.timeSection.title",
                                    })}
                                >
                                    <FormikField
                                        name="credit.expiration"
                                        labelText={intl.formatMessage({
                                            id: "settings.timeSection.creditExpiration",
                                        })}
                                        formikProps={props}
                                        highlightChange
                                    />
                                    <FormikField
                                        name="order.freeFotoExpiration"
                                        labelText={intl.formatMessage({
                                            id: "settings.timeSection.freePhoto",
                                        })}
                                        formikProps={props}
                                        highlightChange
                                    />
                                    <FormikField
                                        name="order.expiration"
                                        labelText={intl.formatMessage({ id: 'settings.timeSection.orderExpiration' })}
                                        formikProps={props}
                                        highlightChange
                                    />
                                    <FormikField
                                        name="order.unlockDuration"
                                        labelText={intl.formatMessage({
                                            id: "settings.timeSection.unlockDuration",
                                        })}
                                        formikProps={props}
                                        highlightChange
                                    />
                                </IconCard>
                                <IconCard
                                    icon={<AirplayIcon />}
                                    title={intl.formatMessage({
                                        id: "settings.signageSection.title",
                                    })}
                                >
                                    <FormikField
                                        name="signage.idleTimeout"
                                        labelText={intl.formatMessage({
                                            id: "settings.signageSection.idleTimeout",
                                        })}
                                        formikProps={props}
                                        highlightChange
                                    />
                                    <FormikField
                                        name="signage.bannerDuration"
                                        labelText={intl.formatMessage({
                                            id: "settings.signageSection.bannerDuration",
                                        })}
                                        formikProps={props}
                                        highlightChange
                                    />
                                </IconCard>

                                <IconCard
                                    icon={<NotificationsIcon />}
                                    title={intl.formatMessage({
                                        id: "settings.notificationsSection.title",
                                    })}
                                >
                                    <FormikField
                                        name="notifications.printsRemainingHSThreshold"
                                        labelText={intl.formatMessage({
                                            id:
                                                "settings.commissionsSection.printsRemainingNTreshold",
                                        })}
                                        formikProps={props}
                                        highlightChange
                                    />
                                </IconCard>
                            </Grid>
                            <Grid item md={6} lg={4} sm={12}>

                                <IconCard icon={<ShoppingBasketIcon />} title={intl.formatMessage({ id: 'settings.ordersSection.title' })}>

                                    <Grid container spacing={1}>
                                        <Grid item xs={3} style={{ verticalAlign: 'center', marginTop: '28px' }}>
                                            <span style={{ marginTop: '15px' }}>Cenník (C):</span>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <FormikField
                                                name={`pricing.photos.S10x15`}
                                                labelText={"10x15"}
                                                formikProps={props}
                                                highlightChange
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <FormikField
                                                name={`pricing.photos.S15x15`}
                                                labelText={"15x15"}
                                                formikProps={props}
                                                highlightChange
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <FormikField
                                                name={`pricing.photos.S20x15`}
                                                labelText={"20x15"}
                                                formikProps={props}
                                                highlightChange
                                            />
                                        </Grid>
                                    </Grid>
                                    < hr />
                                    {settings?.pricing?.levels?.map((item, i) =>
                                        <Grid container spacing={1}>
                                            <Grid item xs={8} style={{ verticalAlign: 'center', marginTop: '28px' }}>
                                                <span style={{ marginTop: '15px' }}>L{item?.number} - {(item.number === 1) ? "Základná " : (item.number === 2) ? "Stredná " : "Vysoká"} cenová hladina :</span>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <FormikField
                                                    disabled={item?.number === 1}
                                                    name={`pricing.levels.${i}.surcharge`}
                                                    labelText={"Prirážka (%)"}
                                                    formikProps={props}
                                                    highlightChange
                                                />
                                            </Grid>
                                        </Grid>
                                    )}

                                </IconCard>
                                <IconCard
                                    icon={<LocalAtmIcon />}
                                    title={intl.formatMessage({ id: 'settings.rewardSection.title' })}
                                >
                                    {settings?.cashback?.map((item, i) =>
                                        <Grid container spacing={1}>
                                            <Grid item xs={4} style={{ verticalAlign: 'center', marginTop: '28px' }}>
                                                <span style={{ marginTop: '15px' }}>Úroveň {i + 1}:</span>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <FormikField
                                                    name={`cashback.${i}.quantity`}
                                                    labelText={"Počet foto"}
                                                    formikProps={props}
                                                    highlightChange
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <FormikField
                                                    name={`cashback.${i}.amount`}
                                                    labelText={"Percentá (%)"}
                                                    formikProps={props}
                                                    highlightChange
                                                />
                                            </Grid>
                                        </Grid>
                                    )}
                                </IconCard>

                                <IconCard
                                    icon={<ContactSupportIcon />}
                                    title={intl.formatMessage({
                                        id: "settings.recepientsSection.title",
                                    })}
                                >
                                    <FormikField
                                        name="notifications.globalNotifcationRecipients"
                                        labelText={intl.formatMessage({
                                            id:
                                                "settings.recepientsSection.globalNotifcationRecipients",
                                        })}
                                        formikProps={props}
                                        type="chips"
                                        highlightChange
                                    />
                                </IconCard>
                                <IconCard
                                    icon={<LiveHelpIcon />}
                                    title={intl.formatMessage({
                                        id: "settings.supportSection.title",
                                    })}
                                >
                                    <FormikField
                                        name="supportMail"
                                        labelText={intl.formatMessage({
                                            id: "settings.supportSection.email",
                                        })}
                                        formikProps={props}
                                        highlightChange
                                    />
                                </IconCard>
                                <IconCard
                                    icon={<SecurityIcon />}
                                    title={intl.formatMessage({ id: "settings.version.title" })}
                                >
                                    <Grid container spacing={2}>
                                        <Grid item sm={12}>
                                            {intl.formatMessage({ id: "settings.version.backend" })}:
                                            <Box className={classes.floatRight}>
                                                {pathOr("", ["about", "version"], dataAbout)}
                                            </Box>
                                        </Grid>
                                        <Grid item sm={12}>
                                            {intl.formatMessage({ id: "settings.version.frontend" })}:
                                            <Box className={classes.floatRight}>{json.version}</Box>
                                        </Grid>
                                    </Grid>
                                </IconCard>
                            </Grid>
                        </Grid>
                        <div style={{ position: "fixed", bottom: "20px", right: "20px", zIndex: 1000 }} >
                            <Button color="primary" onClick={(e) => props.handleSubmit()}>
                                {intl.formatMessage({ id: "settings.save" })}
                            </Button>
                            <Button onClick={() => props.resetForm()}>
                                {intl.formatMessage({ id: "settings.cancel" })}
                            </Button>
                        </div>
                    </>
                )}
            </Formik>
        </>
    );
};

export default injectIntl(SettingsPage);
