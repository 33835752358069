import { Grid } from "@material-ui/core";
import TransferWithinAStationIcon from '@material-ui/icons/TransferWithinAStation';
import IconCard from "components-lib/IconCard/IconCard";
import React from "react";
import { injectIntl } from "react-intl";
import { withRouter } from "react-router-dom";
import FormikField from "components-lib/FormikField/FormikField";
import RoleEnum from "common/enums/RoleEnum";
import { isInRoles } from "helpers/helpers";
import styles from "common/styles/widgets.js";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => styles(theme));

const MaintainerCard = (props) => {
  const { intl, formikProps } = props;
  const classes = useStyles();

  const renderContent = () => {
    if (!isInRoles([RoleEnum.ADMIN, RoleEnum.MAINTAINER])) {
      return (
        <Grid container spacing={1}>
          <Grid item sm={12}>
            {intl.formatMessage({ id: "orderDetail.customerCard.name" })}
            <Box className={classes.floatRight}>
              {formikProps.values.contactFirstName} {formikProps.values.contactLastName}
            </Box>
          </Grid>
          <Grid item sm={12}>
            {intl.formatMessage({ id: "storeDetail.maintainerCard.phone" })}
            <Box className={classes.floatRight}>
              {(formikProps.values.contactPhone) ? formikProps.values.contactPhone : "-"}
            </Box>
          </Grid>
          <Grid item sm={12}>
            {intl.formatMessage({ id: "storeDetail.maintainerCard.email" })}
            <Box className={classes.floatRight}>
              {(formikProps.values.contactEmail) ? formikProps.values.contactEmail : "-"}
            </Box>
          </Grid>
        </Grid>
      )
    }
    return (
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6}>
          <FormikField
            name="contactFirstName"
            labelText={intl.formatMessage({ id: "storeDetail.maintainerCard.firstName" })}
            formikProps={formikProps}
            highlightChange
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormikField
            name="contactLastName"
            labelText={intl.formatMessage({ id: "storeDetail.maintainerCard.lastName" })}
            formikProps={formikProps}
            highlightChange
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormikField
            name="contactPhone"
            labelText={intl.formatMessage({ id: "storeDetail.maintainerCard.phone" })}
            formikProps={formikProps}
            highlightChange
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormikField
            name="contactEmail"
            labelText={intl.formatMessage({ id: "storeDetail.maintainerCard.email" })}
            formikProps={formikProps}
            highlightChange
          />
        </Grid>
      </Grid>
    )
  }

  return (
    <>
      <IconCard
        title={intl.formatMessage({ id: "storeDetail.maintainerCard.title" })}
        icon={<TransferWithinAStationIcon />}
      >
        {renderContent()}
      </IconCard>
    </>
  );
};

export default withRouter(injectIntl(MaintainerCard));
