import React from 'react';
import { Grid } from '@material-ui/core';
import Button from "components/CustomButtons/Button.js";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { makeStyles } from "@material-ui/core/styles";
import styles from './styles';
import { injectIntl } from 'react-intl';

const PageHeader = ({ withBackButton = true, title = '', actions = [], handleBackAction = () => { }, subTitle = '', intl }) => {
    const useStyles = makeStyles(styles);
    const classes = useStyles();

    const actionsArray = (actions) ? actions.length : 0

    const baseFreeCols = withBackButton ? 11 : 12;
    const baseFreeColsLg = withBackButton ? 11 : 12;

    const actionsGridSizeXl = actionsArray;
    const titleGridSizeXl = baseFreeCols - actionsArray;

    const actionsGridSizeLg = actionsArray + 2;
    const titleGridSizeLg = baseFreeColsLg - (actionsArray + 2);

    const actionsGridSizeMd = actionsArray + 3;
    const titleGridSizeMd = baseFreeCols - (actionsArray + 3);

    return (
        <div className={classes.pageHeaderWrapper}>
            <Grid container spacing={3}>
                {withBackButton && (
                    <Grid item lg={1} md={2} sm={2} xs={4}>
                        <Button size="sm" color="info" round onClick={(e) => handleBackAction()}>
                            <ArrowBackIcon className={classes.backIcon} />
                            {intl.formatMessage({ id: 'pageHeader.back' })}
                        </Button>
                    </Grid>
                )}
                <Grid
                    item
                    xs={titleGridSizeXl}
                    lg={titleGridSizeLg}
                    md={titleGridSizeMd}
                    sm={9}
                >
                    <h1 className={classes.title}>{title}</h1>
                    {subTitle && (
                        <p className={classes.subTitle}>{subTitle}</p>
                    )}
                </Grid>
                {actions.length > 0 && (
                    <Grid
                        item
                        xs={actionsGridSizeXl}
                        lg={actionsGridSizeLg}
                        md={actionsGridSizeMd}
                        sm={12}
                    >
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                            }}
                        >
                            {actions.map((action, index) => action.component
                                ?
                                action.component
                                :
                                <Button
                                    key={index}
                                    size="sm"
                                    round
                                    color={action.color || 'info'}
                                    onClick={action.onClick}
                                >
                                    {action.title}
                                </Button>
                            )}
                        </div>
                    </Grid>
                )}
            </Grid>
        </div>
    )
};

export default injectIntl(PageHeader);