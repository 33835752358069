import paths from "paths";
import RoleEnum from "common/enums/RoleEnum";
import { isInRoles } from "helpers/helpers";

//Import PAGES
import LoginPage from "views/Pages/LoginPage.js";
import DashboardPage from "pages/Dashboard/DashboardPage";
import ProfilePage from "pages/Profile/ProfilePage"
import UserListPage from "pages/Users/UserListPage";
import UserDetailPage from "pages/Users/UserDetailPage";
import UserNewPage from "pages/Users/UserNewPage";
import LogoutPage from "pages/Logout/LogoutPage";
//import CreateUserPage from "pages/CreateUserPage/CreateUserPage";
import SettingsPage from "pages/Settings/SettingsPage";
import ManagementPage from "pages/Management/ManagementPage";
import DevicesListPage from "pages/Devices/DevicesListPage";
import DeviceDetailPage from "pages/Devices/DeviceDetailPage";
import StoreListPage from 'pages/Stores/StoreListPage';
import StoreNewPage from 'pages/Stores/StoreNewPage';
import StoreDetailPage from 'pages/Stores/StoreDetailPage';
import StoreDetailPagePartner from 'pages/Stores/StoreDetailPagePartner';
import CampaignListPage from "pages/Campaigns/CampaignListPage";
import CampaignNewPage from "pages/Campaigns/CampaignNewPage";
import CampaignAxiosPage from "pages/Campaigns/CampaignAxiosPage";
import CampaignDetailPage from "pages/Campaigns/CampaignDetailPage";
import CouponSetDetailPage from "pages/Campaigns/CouponSetDetailPage";
import CompanyListPage from 'pages/Companies/CompanyListPage';
import CompanyDetailPage from 'pages/Companies/CompanyDetailPage';
import CompanyDetailPagePartner from 'pages/Companies/CompanyDetailPagePartner';
import CompanyNewPage from 'pages/Companies/CompanyNewPage';
import PaymentListPage from "pages/Payments/PaymentListPage";
import InvoiceListPage from "pages/Invoices/InvoiceListPage";
import InvoiceDetailPage from 'pages/Invoices/InvoiceDetailPage';
import CommissionListPage from "pages/Commissions/CommissionListPage";
import CommissionDetailPage from 'pages/Commissions/CommissionDetailPage';
import OperationListPage from "pages/Operations/OperationListPage";
import ForgottenPassword from "pages/ForgottenPassword/ForgottenPassword";
import NotificationListPage from 'pages/Notifications/NotificationListPage';
import NotificationNewPage from 'pages/Notifications/NotificationNewPage';
import NotificationAutomaticPage from 'pages/Notifications/NotificationAutomaticPage';
import OrderListPage from 'pages/Orders/OrderListPage';
import OrderDetailPage from 'pages/Orders/OrderDetailPage';
import MediaListPage from "./pages/Media/MediaListPage";

//Import ICONS
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import NotificationsIcon from '@material-ui/icons/Notifications';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import DashboardIcon from "@material-ui/icons/Dashboard";
import SettingsIcon from '@material-ui/icons/Settings';
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import CameraEnhanceIcon from '@material-ui/icons/CameraEnhance';
import FFCampaignsIcon from './components-lib/Icons/FFCampaignsIcon/FFCampaignsIcon';
import QRCampaignsIcon from './components-lib/Icons/QRCampaignsIcon/QRCampaignsIcon';
import StoreIcon from '@material-ui/icons/Store';
import BusinessIcon from '@material-ui/icons/Business';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import EuroSymbolIcon from '@material-ui/icons/EuroSymbol';
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import PhotoAlbumIcon from '@material-ui/icons/PhotoAlbum';
import DnsIcon from '@material-ui/icons/Dns';

const getRoutes = (intl) => ([
  {
    path: paths.dashboard,
    name: intl.formatMessage({ id: 'routes.dashboard' }),
    //hideInMenu: true, //TODO
    decorateSpecial: true,
    icon: DashboardIcon,
    component: DashboardPage,
    forRoles: [RoleEnum.ADMIN, RoleEnum.MAINTAINER, RoleEnum.PARTNER],
    layout: '/admin'
  },

  {
    path: paths.devices.list,
    name: intl.formatMessage({ id: 'routes.devices' }),
    icon: CameraEnhanceIcon,
    component: DevicesListPage,
    forRoles: [RoleEnum.ADMIN, RoleEnum.MAINTAINER, RoleEnum.PARTNER],
    layout: '/admin'
  },
  {
    path: paths.devices.detail,
    name: intl.formatMessage({ id: 'routes.devices.detail' }),
    hideInMenu: true,
    icon: CameraEnhanceIcon,
    component: DeviceDetailPage,
    forRoles: [RoleEnum.ADMIN, RoleEnum.MAINTAINER, RoleEnum.PARTNER],
    layout: '/admin'
  },

  {
    path: paths.stores.list,
    name: intl.formatMessage({ id: 'routes.stores' }),
    icon: StoreIcon,
    component: StoreListPage,
    forRoles: [RoleEnum.ADMIN, RoleEnum.MAINTAINER, RoleEnum.PARTNER],
    layout: '/admin'
  },
  {
    path: paths.stores.new,
    name: intl.formatMessage({ id: 'routes.stores.new' }),
    hideInMenu: true,
    icon: StoreIcon,
    component: StoreNewPage,
    forRoles: [RoleEnum.ADMIN, RoleEnum.MAINTAINER],
    layout: '/admin'
  },
  {
    path: paths.stores.detail,
    name: intl.formatMessage({ id: 'routes.stores.detail' }),
    hideInMenu: true,
    icon: StoreIcon,
    component: (!isInRoles([RoleEnum.ADMIN, RoleEnum.MAINTAINER])) ? StoreDetailPagePartner : StoreDetailPage,
    forRoles: [RoleEnum.ADMIN, RoleEnum.MAINTAINER, RoleEnum.PARTNER],
    layout: '/admin'
  },
  {
    path: paths.companies.list,
    name: intl.formatMessage({ id: 'routes.companies' }),
    icon: BusinessIcon,
    component: CompanyListPage,
    forRoles: [RoleEnum.ADMIN, RoleEnum.MAINTAINER, RoleEnum.PARTNER],
    layout: '/admin'
  },
  {
    path: paths.companies.detail,
    name: intl.formatMessage({ id: 'routes.companies.detail' }),
    hideInMenu: true,
    icon: BusinessIcon,
    component: (!isInRoles([RoleEnum.ADMIN, RoleEnum.MAINTAINER])) ? CompanyDetailPagePartner : CompanyDetailPage,
    forRoles: [RoleEnum.ADMIN, RoleEnum.MAINTAINER, RoleEnum.PARTNER],
    layout: '/admin'
  },
  {
    path: paths.companies.new,
    name: intl.formatMessage({ id: 'routes.companies.new' }),
    hideInMenu: true,
    icon: BusinessIcon,
    component: CompanyNewPage,
    forRoles: [RoleEnum.ADMIN, RoleEnum.MAINTAINER, RoleEnum.PARTNER],
    layout: '/admin'
  },
  {
    path: paths.campaigns.FreePhoto,
    name: intl.formatMessage({ id: 'routes.campaigns.freeFoto' }),
    icon: FFCampaignsIcon,
    component: CampaignListPage,
    forRoles: [RoleEnum.ADMIN, RoleEnum.MAINTAINER],
    layout: '/admin'
  },
  {
    path: paths.campaigns.FreePhotoNew,
    hideInMenu: true,
    name: intl.formatMessage({ id: 'routes.campaigns.new' }),
    icon: FFCampaignsIcon,
    component: CampaignNewPage,
    forRoles: [RoleEnum.ADMIN, RoleEnum.MAINTAINER],
    layout: '/admin'
  },
  {
    path: paths.campaigns.FreePhotoNewBid,
    hideInMenu: true,
    name: intl.formatMessage({ id: 'routes.campaigns.new' }),
    icon: FFCampaignsIcon,
    component: CampaignAxiosPage,
    forRoles: [RoleEnum.ADMIN, RoleEnum.MAINTAINER],
    layout: '/admin'
  },
  {
    path: paths.campaigns.FreePhotoDetail,
    hideInMenu: true,
    name: intl.formatMessage({ id: 'routes.campaigns.detail' }),
    icon: FFCampaignsIcon,
    component: CampaignDetailPage,
    forRoles: [RoleEnum.ADMIN, RoleEnum.MAINTAINER],
    layout: '/admin'
  },
  {
    path: paths.campaigns.Coupon,
    name: intl.formatMessage({ id: 'routes.campaigns.qr' }),
    icon: QRCampaignsIcon,
    //hideInMenu: true, // TODO: Milan - for first faze
    component: CampaignListPage,
    forRoles: [RoleEnum.ADMIN, RoleEnum.MAINTAINER],
    layout: '/admin'
  },
  {
    path: paths.campaigns.QRNew,
    hideInMenu: true,
    name: intl.formatMessage({ id: 'routes.campaigns.new' }),
    icon: QRCampaignsIcon,
    component: CampaignNewPage,
    forRoles: [RoleEnum.ADMIN, RoleEnum.MAINTAINER],
    layout: '/admin',

  },
  {
    path: paths.campaigns.QRDetail,
    hideInMenu: true,
    name: intl.formatMessage({ id: 'routes.campaigns.detail' }),
    icon: QRCampaignsIcon,
    component: CampaignDetailPage,
    forRoles: [RoleEnum.ADMIN, RoleEnum.MAINTAINER],
    layout: '/admin'
  },
  {
    path: paths.campaigns.QRCouponSetDetail,
    hideInMenu: true,
    name: intl.formatMessage({ id: 'routes.campaigns.detail' }),
    icon: QRCampaignsIcon,
    component: CouponSetDetailPage,
    forRoles: [RoleEnum.ADMIN, RoleEnum.MAINTAINER],
    layout: '/admin'
  },
  {
    path: paths.campaigns.PQRNew,
    hideInMenu: true,
    name: intl.formatMessage({ id: 'routes.campaigns.new' }),
    icon: QRCampaignsIcon,
    component: CampaignNewPage,
    forRoles: [RoleEnum.ADMIN, RoleEnum.MAINTAINER],
    layout: '/admin',

  },
  {
    path: paths.campaigns.PQRDetail,
    hideInMenu: true,
    name: intl.formatMessage({ id: 'routes.campaigns.detail' }),
    icon: QRCampaignsIcon,
    component: CampaignDetailPage,
    forRoles: [RoleEnum.ADMIN, RoleEnum.MAINTAINER],
    layout: '/admin'
  },
  {
    path: paths.campaigns.PQRCouponSetDetail,
    hideInMenu: true,
    name: intl.formatMessage({ id: 'routes.campaigns.detail' }),
    icon: QRCampaignsIcon,
    component: CouponSetDetailPage,
    forRoles: [RoleEnum.ADMIN, RoleEnum.MAINTAINER],
    layout: '/admin'
  },
  {
    path: paths.notifications.list,
    name: intl.formatMessage({ id: 'routes.notifications' }),
    icon: NotificationsIcon,
    component: NotificationListPage,
    forRoles: [RoleEnum.ADMIN, RoleEnum.MAINTAINER],
    layout: '/admin'
  },
  {
    path: paths.notifications.new,
    name: intl.formatMessage({ id: 'routes.notifications' }),
    icon: NotificationsIcon,
    hideInMenu: true,
    component: NotificationNewPage,
    forRoles: [RoleEnum.ADMIN, RoleEnum.MAINTAINER],
    layout: '/admin'
  },
  {
    path: paths.notifications.automatic,
    name: intl.formatMessage({ id: 'routes.notifications' }),
    icon: NotificationsIcon,
    hideInMenu: true,
    component: NotificationAutomaticPage,
    forRoles: [RoleEnum.ADMIN, RoleEnum.MAINTAINER],
    layout: '/admin'
  },

  {
    path: paths.orders.list,
    name: intl.formatMessage({ id: 'routes.orders' }),
    icon: ShoppingBasketIcon,
    component: OrderListPage,
    forRoles: [RoleEnum.ADMIN, RoleEnum.MAINTAINER],
    layout: '/admin'
  },
  {
    path: paths.orders.detail,
    name: intl.formatMessage({ id: 'routes.orders.detail' }),
    hideInMenu: true,
    icon: ShoppingBasketIcon,
    component: OrderDetailPage,
    forRoles: [RoleEnum.ADMIN, RoleEnum.MAINTAINER],
    layout: '/admin'
  },

  {
    path: paths.users.list,
    name: intl.formatMessage({ id: 'routes.users' }),
    icon: AssignmentIndIcon,
    component: UserListPage,
    forRoles: [RoleEnum.ADMIN, RoleEnum.MAINTAINER],
    layout: '/admin'
  },
  {
    path: paths.users.create.maintainer,
    name: intl.formatMessage({ id: 'routes.users.new' }),
    component: UserNewPage,
    hideInMenu: true,
    forRoles: [RoleEnum.ADMIN],
    layout: '/admin'
  },
  {
    path: paths.users.create.advertiser,
    name: intl.formatMessage({ id: 'routes.users.new' }),
    component: UserNewPage,
    hideInMenu: true,
    forRoles: [RoleEnum.ADMIN, RoleEnum.MAINTAINER],
    layout: '/admin'
  },
  {
    path: paths.users.create.partner,
    name: intl.formatMessage({ id: 'routes.users.new' }),
    component: UserNewPage,
    hideInMenu: true,
    forRoles: [RoleEnum.ADMIN, RoleEnum.MAINTAINER],
    layout: '/admin'
  },
  {
    path: paths.users.detail,
    name: intl.formatMessage({ id: 'routes.users.detail' }),
    hideInMenu: true,
    component: UserDetailPage,
    forRoles: [RoleEnum.ADMIN, RoleEnum.MAINTAINER],
    layout: '/admin'
  },
  {
    path: paths.media.list,
    name: intl.formatMessage({ id: 'routes.media' }),
    icon: PhotoAlbumIcon,
    forRoles: [RoleEnum.ADMIN, RoleEnum.MAINTAINER],
    component: MediaListPage,
    layout: '/admin'
  },
  {
    path: paths.payments.list,
    name: intl.formatMessage({ id: 'routes.payments' }),
    //hideInMenu: true, //TODO
    icon: AccountBalanceWalletIcon,
    component: PaymentListPage,
    forRoles: [RoleEnum.ADMIN, RoleEnum.MAINTAINER],
    layout: '/admin'
  },
  {
    path: paths.invoices.list,
    name: intl.formatMessage({ id: 'routes.invoices' }),
    icon: CreditCardIcon,
    component: InvoiceListPage,
    forRoles: [RoleEnum.ADMIN, RoleEnum.MAINTAINER],
    layout: '/admin'
  },
  {
    path: paths.invoices.detail,
    name: intl.formatMessage({ id: 'routes.invoices.detail' }),
    hideInMenu: true,
    icon: CreditCardIcon,
    component: InvoiceDetailPage,
    forRoles: [RoleEnum.ADMIN, RoleEnum.MAINTAINER],
    layout: '/admin'
  },

  {
    path: paths.commissions.list,
    name: intl.formatMessage({ id: 'routes.commissions' }),
    //hideInMenu: true, //TODO
    icon: EuroSymbolIcon,
    component: CommissionListPage,
    forRoles: [RoleEnum.ADMIN, RoleEnum.MAINTAINER, RoleEnum.PARTNER],
    layout: '/admin'
  },
  {
    path: paths.commissions.detail,
    name: intl.formatMessage({ id: 'routes.commissions.detail' }),
    hideInMenu: true,
    icon: CreditCardIcon,
    component: CommissionDetailPage,
    forRoles: [RoleEnum.ADMIN, RoleEnum.MAINTAINER, RoleEnum.PARTNER],
    layout: '/admin'
  },

  {
    path: paths.operations.list,
    name: intl.formatMessage({ id: 'routes.operations' }),
    icon: SwapHorizIcon,
    component: OperationListPage,
    forRoles: [RoleEnum.ADMIN, RoleEnum.MAINTAINER],
    layout: '/admin'
  },

  {
    path: paths.auth.login,
    name: intl.formatMessage({ id: 'routes.login' }),
    hideInMenu: true,
    component: LoginPage,
    forRoles: [RoleEnum.ADMIN, RoleEnum.MAINTAINER, RoleEnum.PARTNER],
    layout: '/auth'
  },
  {
    path: paths.profile,
    name: intl.formatMessage({ id: 'routes.profile' }),
    decorate: true,
    icon: AccountCircleIcon,
    component: ProfilePage,
    forRoles: [RoleEnum.ADMIN, RoleEnum.MAINTAINER, RoleEnum.PARTNER],
    layout: '/admin'
  },
  {
    path: paths.management,
    name: intl.formatMessage({ id: 'routes.management' }),
    component: ManagementPage,
    forRoles: [RoleEnum.ADMIN, RoleEnum.MAINTAINER],
    icon: DnsIcon,
    layout: '/admin'
  },
  {
    path: paths.settings,
    name: intl.formatMessage({ id: 'routes.settings' }),
    component: SettingsPage,
    forRoles: [RoleEnum.ADMIN, RoleEnum.MAINTAINER],
    icon: SettingsIcon,
    layout: '/admin'
  },
  {
    path: paths.auth.logout,
    name: intl.formatMessage({ id: 'routes.logout' }),
    component: LogoutPage,
    icon: ExitToAppIcon,
    forRoles: [RoleEnum.ADMIN, RoleEnum.MAINTAINER, RoleEnum.PARTNER],
    layout: '/admin'
  },
  {
    path: paths.auth.forgottenPassword,
    name: intl.formatMessage({ id: 'routes.forgottenPassword' }),
    component: ForgottenPassword,
    forRoles: [RoleEnum.ADMIN, RoleEnum.MAINTAINER, RoleEnum.PARTNER],
    hideInMenu: true,
    layout: '/auth'
  }
]);

export default getRoutes;
