import gql from 'graphql-tag';

const NOTIFICATIONS_REACH = gql`
query notificationReach($criteria: NotificationCriteriaInput!){
    notificationReach(criteria: $criteria){
			reach
      }
    }
`;

export default NOTIFICATIONS_REACH;