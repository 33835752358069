import gql from 'graphql-tag';

const STORE_ASSIGN_COMPANY = gql`
    mutation storeAssignCompany($id: ID!, $companyId: ID!) {
        storeAssignCompany(id: $id, companyId: $companyId){
            id  
        }
    }
`;

export default STORE_ASSIGN_COMPANY;

