import gql from 'graphql-tag';

const USER_ACTIVATE = gql`
mutation userActivate ($id: ID!, $input:UserActivationInput) {
    userActivate(id: $id, input:$input) {
        firstName
        lastName
    }
}
`;

export default USER_ACTIVATE;