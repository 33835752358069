import React from "react";
import Button from '@material-ui/core/Button';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

import "assets/scss/material-dashboard-pro-react.scss?v=1.9.0";




const ButtonDetail = (props) => {
    const { 
        onClick, 
        classes       
    } = props;



return (
    <Button
        round
        // className={classes}
        id= "detailBtn"
        className= "detailBtn"
        size="sm"
        color="info"
        onClick={ onClick }
        style={{ margin: "0rem", padding: "0.2rem 0.8rem" }}
    >
        {/* {intl.formatMessage({ id: 'campaigns.detail' })} */}
        <ArrowForwardIcon/>
    </Button>
)
}

export default ButtonDetail;