import { makeStyles } from '@material-ui/core/styles';
import QueryTable from 'components-lib/QueryTable/QueryTable';
import Button from "components/CustomButtons/Button.js";
import paths from 'paths';
import React from 'react';
import { injectIntl } from 'react-intl';
import { generatePath } from 'react-router-dom';
import styles from "common/styles/widgets.js";
import { pathOr } from "rambda";
import CampaignTypeEnum from "common/enums/CampaignType";
import CampaignStatusEnum from "common/enums/CampaignStatusEnum";
import ControlPointDuplicate from 'components-lib/Icons/ControlPointDuplicate';
import ButtonDetail from 'components/ButtonDetail/ButtonDetail';
//import ControlPointDuplicateIcon from '@mui/icons-material/ControlPointDuplicate';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

const useStyles = makeStyles((theme) => styles(theme))

export const CampaignTable = (props) => {
    const {
        history,
        query,
        intl,
        queryDataPath,
        type,
        queryVariables,
        toggle
    } = props
    const classes = useStyles();

    const getStatus = (status) => {
        if (status === CampaignStatusEnum.RUNNING)
            return <p style={{ color: "green" }}>{intl.formatMessage({ id: "campaigns.status.running" })}</p>
        else if (status === CampaignStatusEnum.AWAITING)
            return <p style={{ color: "orange" }}>{intl.formatMessage({ id: "campaigns.status.awaiting" })}</p>
        else if (status === CampaignStatusEnum.REJECTED)
            return <p style={{ color: "red" }}>{intl.formatMessage({ id: "campaigns.status.rejected" })}</p>
        else if (status === CampaignStatusEnum.FINISHED)
            return <p style={{ color: "gray" }}>{intl.formatMessage({ id: "campaigns.status.finished" })}</p>
        else if (status === CampaignStatusEnum.SCHEDULED)
            return <p style={{ color: "#7b1fa2" }}>{intl.formatMessage({ id: "campaigns.status.scheduled" })}</p>
        else if (status === CampaignStatusEnum.INACTIVE)
            return <p style={{ color: "#7b1fa2" }}>{intl.formatMessage({ id: "campaigns.status.inactive" })}</p>
        else
            return <p>{status}</p>
    }

    const handleCampaignDetail = (id, sourceType) => {
        if (type === CampaignTypeEnum.FREEPHOTO) {
            return history.push({
                pathname: `/admin${generatePath(paths.campaigns.FreePhotoDetail, { campaignId: id })}`,
                state: CampaignTypeEnum.FREEPHOTO
            })
        } else
            if (sourceType === "Qr") {
                return history.push({
                    pathname: `/admin${generatePath(paths.campaigns.QRDetail, { campaignId: id })}`,
                    state: CampaignTypeEnum.QR
                })
            }
            else {
                return history.push({
                    pathname: `/admin${generatePath(paths.campaigns.PQRDetail, { campaignId: id })}`,
                    state: CampaignTypeEnum.PQR
                })
            }
    }
    
    const getFreeFotoColumns = () => {
        const columns = [
            {
                Header: intl.formatMessage({ id: "campaigns.table.ID" }),
                accessor: 'bid',
                sortKey: 'bid',
                filterKey: 'bid',
                filterOperator: 'contains'
            },
            {
                Header: intl.formatMessage({ id: "campaigns.table.status" }),
                accessor: (rowData) => getStatus(rowData.status),
                sortKey: 'status',
                filterKey: 'status',
                filterComponent: 'select',
                filterOperator: 'in',
                filterOptions: [
                    {
                        value: CampaignStatusEnum.RUNNING,
                        label: intl.formatMessage({ id: "campaigns.status.running" }),
                    },
                    {
                        value: CampaignStatusEnum.AWAITING,
                        label: intl.formatMessage({ id: "campaigns.status.awaiting" }),
                    },
                    {
                        value: CampaignStatusEnum.SCHEDULED,
                        label: intl.formatMessage({ id: "campaigns.status.scheduled" }),
                    },
                    {
                        value: CampaignStatusEnum.FINISHED,
                        label: intl.formatMessage({ id: "campaigns.status.finished" }),
                    },
                    
                    {
                        value: CampaignStatusEnum.REJECTED,
                        label: intl.formatMessage({ id: "campaigns.status.rejected" }),
                    },
                    {
                        value: CampaignStatusEnum.INACTIVE,
                        label: intl.formatMessage({ id: "campaigns.status.inactive" }),
                    },
                ]
            },
            {
                Header: intl.formatMessage({ id: "campaigns.table.name" }),
                accessor: 'name',
                sortKey: 'name',
                filterKey: 'name',
                filterOperator: 'contains'
            },
            {
                Header: intl.formatMessage({id: "campaigns.budgetCard.budgetTotal"}),
                // Header: (
                //     <div className="custom-header">
                //       {intl.formatMessage({ id: "campaigns.budgetCard.budgetTotal" })}
                //     </div>
                //   ),
                accessor: 'budget',
                sortKey: 'budget',
                filterKey:'budget',
                filterOperator: 'contains',      
                // Cell: ({ value }) => <span className="custom-name-cell">{value}</span>,   
                // getHeaderProps: () => ({
                //     className: 'budget-header'
                // })       
            },
            {
                Header: intl.formatMessage({id: "campaigns.table.stores"}),
                accessor: 'stores',
                sortKey: 'stores',
                filterKey:'stores',
                filterOperator: 'contains',  
            },
            {
                Header: intl.formatMessage({id: "campaigns.table.maxBid"}),
                accessor: 'maxBid',
                sortKey: 'maxBid',
                filterKey:'maxBid',
                filterOperator: 'contains',   
            },
            {
                Header: intl.formatMessage({ id: "campaigns.table.owner" }),
                accessor: 'owner',
                sortKey: 'owner',
                filterKey: 'owner',
                filterOperator: 'contains'
            },
            {
                Header: intl.formatMessage({ id: "campaigns.table.start" }),
                accessor: (rowData) =>
                    new Date(pathOr(0, 'validFrom', rowData)).toLocaleString('sk'),
                sortKey: 'validFrom',
                filterKey: "validFrom",
                filterOperator: 'between',
                filterDataType: "dateBetween",
                filterComponent: 'date',
            },
            {
                Header: intl.formatMessage({ id: "campaigns.table.end" }),
                accessor: (rowData) =>
                    new Date(pathOr(0, 'validTo', rowData)).toLocaleString('sk'),
                sortKey: 'validTo',
                filterKey: "validTo",
                filterOperator: 'between',
                filterDataType: "dateBetween",
                filterComponent: 'date',
            },
            {
                Header: intl.formatMessage({ id: 'campaigns.table.actions' }),
                accessor: (rowData) => (
                    <div style={{ display: "flex", flexDirection: "row",  flexWrap: "wrap",
                    justifyContent: "flex-start", alignItems: "center", gap: "0.2em" }}>
                        <Button 
                            onClick={() => history.push(`/admin${generatePath(paths.campaigns.FreePhotoNewBid)}?id=${rowData.id}`)}
                            round
                            className={classes.tenantButtons}
                            size="sm"
                            color="white"
                            style={{ margin: "0rem", padding: "0.28rem 0.8rem" }}
                        >
                            <ControlPointDuplicate  />
                        </Button>
                   
                        <ButtonDetail
                            classes={classes.tenantButtons}
                            onClick={ () => handleCampaignDetail(rowData.id) }
                        />
                        
                    </div>
                ),
                getHeaderProps: () => ({
                    className: 'actions-header'
                })
            }
            // {
            //     id: 'actions',
            //     Header: () => (
            //         <div className="act-header">
            //         {intl.formatMessage({ id: 'campaigns.table.actions' })}
            //         </div>
            //     ),
            //     accessor: (rowData) => (
            //         <div className="custom-cell">
            //          <Button 
            //                 onClick={() => history.push(`/admin${generatePath(paths.campaigns.FreePhotoNewBid)}?id=${rowData.id}`)}
            //                 round
            //                 className={classes.tenantButtons}
            //                 size="sm"
            //                 color="white"
            //             >
            //                 <ControlPointDuplicate  />
            //             </Button>
                        
            //             <Button
            //                 round
            //                 className={classes.tenantButtons}
            //                 size="sm"
            //                 color="info"
            //                 onClick={() => handleCampaignDetail(rowData.id)}
            //             >
            //                 {intl.formatMessage({ id: 'campaigns.detail' })}
            //             </Button>
            //         </div>
            //     ),
            //     headerClassName: 'custom-header',
            //     className: 'custom-cell'
            // }
            // {
            //     id: 'actions',
            //     Header: () => (
            //       <div className="custom-header">
            //         {intl.formatMessage({ id: 'campaigns.table.actions' })}
            //       </div>
            //     ),
            //     accessor: (rowData) => (
            //       <div className="custom-cell">
            //         {/* Your button components */}
            //       </div>
            //     ),
            //     className: 'actions' // Add className directly to the column definition
            //   },
        ]
        return columns
    }
    const getQRColumns = () => {
        const columns = [
            {
                Header: intl.formatMessage({ id: "campaigns.table.ID" }),
                accessor: 'bid',
                sortKey: 'bid',
                filterKey: 'bid',
                filterOperator: 'contains'
            },
            {
                Header: intl.formatMessage({ id: "campaigns.table.type" }),
                accessor: (rowData) => (rowData.type === "Qr") ? "QR" : "MQR",
                sortKey: 'type',
                filterKey: 'type',
                filterComponent: 'select',
                filterOperator: 'eq',
                filterOptions: [
                    {
                        value: 'Qr',
                        label: intl.formatMessage({ id: "campaigns.type.qr" }),
                    },
                    {
                        value: 'PublicQr',
                        label: intl.formatMessage({ id: "campaigns.type.pqr" }),
                    },
                ]
            },
            {
                Header: intl.formatMessage({ id: "campaigns.table.status" }),
                accessor: (rowData) => getStatus(rowData.status),
                sortKey: 'status',
                filterKey: 'status',
                filterComponent: 'select',
                filterOperator: 'in',
                filterOptions: [
                    {
                        value: CampaignStatusEnum.RUNNING,
                        label: intl.formatMessage({ id: "campaigns.status.running" }),
                    },
                    {
                        value: CampaignStatusEnum.AWAITING,
                        label: intl.formatMessage({ id: "campaigns.status.awaiting" }),
                    },
                    {
                        value: CampaignStatusEnum.SCHEDULED,
                        label: intl.formatMessage({ id: "campaigns.status.scheduled" }),
                    },
                    {
                        value: CampaignStatusEnum.FINISHED,
                        label: intl.formatMessage({ id: "campaigns.status.finished" }),
                    },
                    /*
                    {
                        value: CampaignStatusEnum.REJECTED,
                        label: intl.formatMessage({ id: "campaigns.status.rejected" }),
                    },
                    {
                        value: CampaignStatusEnum.INACTIVE,
                        label: intl.formatMessage({ id: "campaigns.status.inactive" }),
                    },*/
                ]
            },
            {
                Header: intl.formatMessage({ id: "campaigns.table.name" }),
                accessor: 'name',
                sortKey: 'name',
                filterKey: 'name',
                filterOperator: 'contains'
            },
            {
                Header: intl.formatMessage({ id: "campaigns.table.owner" }),
                accessor: 'owner',
                sortKey: 'owner',
                filterKey: 'owner',
                filterOperator: 'contains'
            },
            {
                Header: intl.formatMessage({ id: "campaigns.table.start" }),
                accessor: (rowData) =>
                    new Date(pathOr(0, 'validFrom', rowData)).toLocaleString('sk'),
                sortKey: 'validFrom',
                filterKey: "validFrom",
                filterOperator: 'between',
                filterDataType: "dateBetween",
                filterComponent: 'date',
            },
            {
                Header: intl.formatMessage({ id: "campaigns.table.end" }),
                accessor: (rowData) =>
                    new Date(pathOr(0, 'validTo', rowData)).toLocaleString('sk'),
                sortKey: 'validTo',
                filterKey: "validTo",
                filterOperator: 'between',
                filterDataType: "dateBetween",
                filterComponent: 'date',
            },
            {
                Header: intl.formatMessage({ id: "campaigns.table.cupons" }),
                accessor: (rowData) => rowData.couponsUsedCount + '/' + rowData.couponsTotalCount,
                sortKey: 'couponsTotalCount',
                filterKey: 'couponsTotalCount',
                filterOperator: 'eq',
                filterDataType: 'number',
            },
            {
                Header: intl.formatMessage({ id: 'campaigns.table.actions' }),
                accessor: (rowData) =>
                    <>
                    <div style={{ display: "flex", flexDirection: "row",  flexWrap: "wrap",
                        justifyContent: "flex-start", alignItems: "center", gap: "0.2em" }}>

                    {/* <Button
                        round
                        className={classes.tenantButtons}
                        size="sm"
                        color="info"
                        onClick={() => handleCampaignDetail(rowData.id, rowData.type)}
                    >
                        {intl.formatMessage({ id: 'campaigns.detail' })}
                    </Button> */}
                        <ButtonDetail
                            // classes={classes.tenantButtons}
                            onClick={ () => handleCampaignDetail(rowData.id, rowData.type) }
                        />
                    </div>
                    
                    </>
            }
        ]
        return columns
    }

    return (
        <>
            <QueryTable
                query={query}
                queryDataPath={queryDataPath}
                queryVariables={queryVariables}
                //permanentFilter={{ type: { eq: type } }}
                toggle={toggle}
                columns={(type === CampaignTypeEnum.FREEPHOTO) ? getFreeFotoColumns() : getQRColumns()}
            />
        </>
    )
}

export default injectIntl(CampaignTable);