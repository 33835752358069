import gql from 'graphql-tag';

const COUPON_SET_DETAIL = gql`
query couponSet($id:ID!) {
    couponSet(id: $id) {
      bid    
      couponCount
      couponNominalValue
      name
      totalSetValue
      usedCouponsCount
      usedCouponsValue
    }
}
`;

export default COUPON_SET_DETAIL;
