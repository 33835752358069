import gql from 'graphql-tag';

const SEGMENT_CREATE = gql`
mutation segmentCreate($input: SegmentCreateInput!){
  segmentCreate(input: $input){
		id
    name
    }
  }
`;

export default SEGMENT_CREATE;