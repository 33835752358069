import React, { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { pathOr } from 'rambda';
import { injectIntl } from 'react-intl';
import Box from "@material-ui/core/Box";
import styles from "common/styles/widgets.js";
import { makeStyles } from "@material-ui/core/styles";
import moment from 'moment';
import { useQuery } from 'react-apollo';
import { ALL_TOPICS } from "queries/CampaignsQueries/allTopics"
import MEDIAS_BY_COMPANY from "queries/MediaQueries/mediasByCompany";
import STORES_SELECT from "queries/StoresQueries/storesSelect";
import HoursCard from "components-lib/HoursCard/HoursCard";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import CardMedia from '@material-ui/core/CardMedia';
import Modal from "components-lib/Modal/Modal";
import { defaultFFWatermarkUrl } from 'configFiles/config';

const useStyles = makeStyles((theme) => styles(theme));

const Step5 = ({ 
  intl, 
  criteriaNumber, 
  allData, 
  text, 
  initialData, 
  companyId, 
  times, 
  criteria, 
  rangeMale, 
  rangeFemale,
  rangeUnknownGender, }) => {
  //const bull = <span className={classes.bullet}>•</span>;
  const [mediaTable, setMediaTable] = useState([])
  const [watermark, setWatermark] = useState({})
  const [storeTable, setStoreTable] = useState([])
  const [defaultWatermarkModalOpen, setDefaultWatermarkModalOpen] = useState(false)

  const { data: storesData } = useQuery(STORES_SELECT);
  const { data: topicsData } = useQuery(ALL_TOPICS)
  const { data: mediasData } = useQuery(MEDIAS_BY_COMPANY(Number(companyId)),
    {
      variables: {
        filter: [
          {
            acceptanceStatus: {
              eq: "Approved"
            }
          }

        ]
      },
      skip: !companyId
    }
  );

  console.log( storesData )

  let imgWidth = ((300 * Number(window.innerWidth)) / 1920)
  let topics = initialData?.topicIds
  let media = initialData?.mediaIds
  let watermarkId = initialData?.watermarkId
  let stores = criteria?.zoneIds



  const [modalOpen, setModalOpen] = useState(false)
  const [mediaUrl, setMediaUrl] = useState({})

  const getAge = (ageRange) => {
    if (ageRange === "R13_18") {
      return <span> 13 - 18</span>;
    } else if (ageRange === "R19_34") {
      return <span> 19 - 34</span>;
    } else if (ageRange === "R35_49") {
      return <span> 35 - 49</span>;
    } else if (ageRange === "R50_") {
      return <span> {intl.formatMessage({ id: "campaigns.wizard.step4.gender.R50_" })} </span>;
    }
  };

  const AgeLabels = ( rangeMale ) => (
    <span>
      {rangeMale.map((range, index) => (
      <React.Fragment key={range}>
        {getAge(range)}
        {index !== rangeMale.length - 1 ? ", " : intl.formatMessage({ id: "campaigns.wizard.step4.gender.age" }) }
      </React.Fragment>
    ))}
    </span>
  );

  const getTopics = () => {
    const topicOptions = [];
    for (let i = 0; i < topics?.length; i++)
      pathOr([], ['topics', 'items'], topicsData).filter(top => (top.id) === topics[i]).map(topic => {
        topicOptions.push(topic.name + ", ")
      })
    return (topicOptions?.length > 0) ? topicOptions : intl.formatMessage({ id: 'campaigns.wizard.finalStep.all' })
  }

  useEffect(() => {
    const mediaOptions = [];
    for (let i = 0; i < media?.length; i++)
      pathOr([], ['mediasByCompany', 'items'], mediasData).filter(m => (m.id) === media[i]).map(med => {
        mediaOptions.push({
          name: med.name,
          url: med.url
        })
      })

    setMediaTable(mediaOptions)

    let wat = [];
    pathOr([], ['mediasByCompany', 'items'], mediasData).filter(m => (m.id) === watermarkId).map(med => {
      wat.push({
        name: med.name,
        url: med.url
      })
    });
    setWatermark(wat)
  }, [mediasData]);

  useEffect(() => {
    const storesOptions = [];
    for (let i = 0; i < stores?.length; i++)
      pathOr([], "stores.items", storesData).filter(s => (s.id) === stores[i]).map(store => {
        storesOptions.push({
          bid: store.bid,
          name: store.name
        })
      })
    setStoreTable(storesOptions)
  }, [storesData, stores]);

  const handleSetModal = (data) => {
    setMediaUrl({
      name: data?.name,
      url: data?.url
    })
    setModalOpen(true)
  }

  const getActions = (data) => {
    return <span
      style={{ padding: "10px" }}
      className={classes.pointer}
      onClick={() => handleSetModal(data)}
    >
      <img src={pathOr('', 'url', data)} alt={pathOr('', 'url', data)} width="20px" />
    </span>
  }

  const classes = useStyles();
  return (
    <Grid container spacing={1}>
      <Grid item sm={6}>
        <Grid container spacing={1}>
          <Grid item sm={12}>
            <Card className={classes.root} variant="outlined">
              <CardContent>
                <Typography variant="h5" component="h2">
                  {intl.formatMessage({ id: 'campaigns.wizard.basicInfo' })}:
                </Typography>
                <br />
                <Grid item sm={12} color="textSecondary">
                  <span style={{ color: "gray" }}>
                    {intl.formatMessage({ id: 'campaigns.wizard.finalStep.name' })}:
                  </span>
                  <Box className={classes.floatRight} style={{ marginLeft: "1.5em" }}>
                    <span style={{ color: "gray" }}>
                      {initialData?.name}
                    </span>
                  </Box>
                </Grid>
                <Grid item sm={12}>
                  <span style={{ color: "gray" }}>
                    {intl.formatMessage({ id: 'campaigns.wizard.finalStep.topics' })}:
                  </span>
                  <Box className={classes.floatRight} style={{ marginLeft: "1.5em" }}>
                    <span style={{ color: "gray" }}>
                      {getTopics()}
                    </span>
                  </Box>
                </Grid>
                <Grid item sm={12}>
                  <span style={{ color: "gray" }}>
                    {intl.formatMessage({ id: 'campaigns.wizard.finalStep.start' })}:
                  </span>
                  <Box className={classes.floatRight} style={{ marginLeft: "1.5em" }}>
                    <span style={{ color: "gray" }}>
                      {moment(initialData?.validFrom).format("DD.MM.YYYY HH:mm")}
                    </span>
                  </Box>
                </Grid>
                <Grid item sm={12}>
                  <span style={{ color: "gray" }}>
                    {intl.formatMessage({ id: 'campaigns.wizard.finalStep.end' })}:
                  </span>
                  <Box className={classes.floatRight} style={{ marginLeft: "1.5em" }}>
                    <span style={{ color: "gray" }}>
                      {moment(initialData?.validTo).format("DD.MM.YYYY HH:mm")}
                    </span>
                  </Box>
                </Grid>
                <Grid item sm={12}>
                  <span style={{ color: "gray" }}>
                    {intl.formatMessage({ id: 'campaigns.wizard.finalStep.description' })}:
                  </span>
                  <Box className={classes.floatRight} style={{ marginLeft: "1.5em" }}>
                    <span style={{ color: "gray" }}>
                      {(initialData?.description) ? (initialData?.description) : "-"}
                    </span>
                  </Box>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item sm={12}>
            <Card className={classes.root} variant="outlined">
              <CardContent>
                <Typography variant="h5" component="h2">
                  {intl.formatMessage({ id: 'campaigns.wizard.finalStep.mediaAndBudget' })}
                </Typography>
                <br />
                <span style={{ color: "gray" }}>
                  <b>{intl.formatMessage({ id: 'campaigns.wizard.finalStep.media' })}</b>
                </span>
                < br />
                <Grid container spacing={1}>
                  {
                    mediaTable.map(med =>
                      <Grid item sm={12} key={med.url}>
                        {getActions(med)}
                        {med.name}
                        &nbsp;&nbsp;
                      </Grid>
                    )
                  }
                </Grid>
                <span style={{ color: "gray" }}>
                  <b>{intl.formatMessage({ id: 'campaigns.freeFoto.watermark' })}</b>
                </span>
                < br />
                <Grid container spacing={1}>
                  <Grid item sm={12}>
                    <span
                      style={{ margin: '20px 8px 10px 8px', backgroundColor: '#F36E21' }}
                      className={classes.pointer}
                      onClick={() => setDefaultWatermarkModalOpen(true)}
                    >
                      <img src={(watermark?.length > 0) ? watermark?.[0]?.url : defaultFFWatermarkUrl} alt={(watermark?.length > 0) ? watermark?.[0]?.url : defaultFFWatermarkUrl} width="40px" />
                    </span>
                    {(watermark?.length > 0) ? watermark?.[0]?.name : intl.formatMessage({ id: 'campaigns.freeFoto.watermark.default' })}
                    &nbsp;&nbsp;
                  </Grid>
                </Grid>
                <hr style={{ marginTop: "10px", marginBottom: "10px" }} />
                <span style={{ color: "gray" }}>
                  <b>{intl.formatMessage({ id: 'campaigns.wizard.step3.budget' })}</b>
                </span>
                < br />
                <Grid item sm={12}>
                  <span style={{ color: "gray" }}>
                    {intl.formatMessage({ id: 'campaigns.wizard.finalStep.maxBudget' })}:
                  </span>
                  <Box className={classes.floatRight} style={{ marginLeft: "1.5em" }}>
                    <span style={{ color: "gray" }}>
                      {initialData?.budget} C
                    </span>
                  </Box>
                </Grid>
                <Grid item sm={12}>
                  <span style={{ color: "gray" }}>
                    {intl.formatMessage({ id: 'campaigns.wizard.finalStep.maxBid' })}:
                  </span>
                  <Box className={classes.floatRight} style={{ marginLeft: "1.5em" }}>
                    <span style={{ color: "gray" }}>
                      {initialData?.maxBid} C
                    </span>
                  </Box>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          <Grid item sm={12}>
            <Card className={classes.root} variant="outlined">
              <CardContent>
                <Typography variant="h5" component="h2">
                  {intl.formatMessage({ id: 'campaigns.wizard.step4.title' })}:
                </Typography>
                <br />
                
                <Grid item sm={12}>
                  <span style={{ color: "gray" }}>
                    {intl.formatMessage({ id: 'campaigns.wizard.step4.gender.men' })}:
                  </span>
                  <Box className={classes.floatRight} style={{ marginLeft: "0" }}>
                    <span style={{ color: "gray" }}>
                      {AgeLabels(rangeMale)}
                    </span>
                  </Box>
                </Grid>

                <Grid item sm={12}>
                  <span style={{ color: "gray" }}>
                    {intl.formatMessage({ id: 'campaigns.wizard.step4.gender.women' })}:
                  </span>
                  <Box className={classes.floatRight} style={{ marginLeft: "0" }}>
                    <span style={{ color: "gray" }}>
                      {AgeLabels(rangeFemale)}
                    </span>
                  </Box>
                </Grid>

                <Grid item sm={12}>
                  <span style={{ color: "gray" }}>
                    N/A
                  </span>
                  <Box className={classes.floatRight} style={{ marginLeft: "0" }}>
                    <span style={{ color: "gray" }}>
                      {AgeLabels(rangeUnknownGender)}
                    </span>
                  </Box>
                </Grid>

              </CardContent>
            </Card>
          </Grid>


        </Grid>
      </Grid>

      <Grid item sm={6}>
        <Grid container spacing={1}>
          <Grid item sm={12}>
            <Card className={classes.root} variant="outlined">
              <CardContent>
                <Typography variant="h5" component="h2">
                  {intl.formatMessage({ id: 'campaigns.wizard.finalStep.timePlan' })}
                </Typography>
                <br />
                <Grid item sm={12}>
                  <span style={{ color: "gray" }}>
                    {intl.formatMessage({ id: 'campaigns.wizard.finalStep.minRepeatInterval' })}:
                  </span>
                  <Box className={classes.floatRight} style={{ marginLeft: "1.5em" }}>
                    <span style={{ color: "gray" }}>
                      {<>{initialData?.minRepeatInterval} {intl.formatMessage({ id: 'campaigns.wizard.finalStep.days' })}</>}
                    </span>
                  </Box>
                </Grid>
                <hr style={{ marginTop: "10px", marginBottom: "10px" }} />
                <Grid item sm={12}>
                  <HoursCard
                    tms={times}
                    //callbackTimes={(newValue) => callbackTimes(newValue)}
                    //formikProps={formikProps}
                    withoutOpenInfo={true}
                    readOnly={true}
                  />
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item sm={12}>
            <Card className={classes.root} variant="outlined">
              <CardContent>
                <Typography variant="h5" component="h2">
                  {intl.formatMessage({ id: 'campaigns.wizard.finalStep.stores' })}
                </Typography>
                <br />
                {storeTable?.length === 0 ?

                  <Grid item sm={12}>
                    <span style={{ color: "gray" }}>
                      {intl.formatMessage({ id: 'campaigns.wizard.finalStep.storesList' })}:
                    </span>
                    <Box className={classes.floatRight} style={{ marginLeft: "1.5em" }}>
                      <span style={{ color: "gray" }}>
                        {intl.formatMessage({ id: 'campaigns.wizard.finalStep.all' })}
                      </span>
                    </Box>
                  </Grid>
                  :
                  <>
                    <span style={{ color: "gray" }}>
                      <b>{intl.formatMessage({ id: 'campaigns.wizard.finalStep.storesList' })}</b>
                    </span>
                    <br />
                    <Grid container spacing={1}>
                      {
                        storeTable?.map(store =>
                          <Grid item sm={12} key={store.bid}>
                            <span style={{ color: "gray" }}>
                              {store.bid}
                            </span>
                            <Box className={classes.floatRight} style={{ marginLeft: "1.5em" }}>
                              <span style={{ color: "gray" }}>
                                {store.name}
                              </span>
                            </Box>
                          </Grid>
                        )
                      }
                    </Grid>
                  </>
                }
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>
      <Modal
        title={intl.formatMessage({ id: 'campaigns.spotsCard.banner' })}
        open={modalOpen}
        onClose={() => { setModalOpen(false) }}
      >
        <CardMedia
          component="img"
          style={{ width: imgWidth }}
          alt={mediaUrl.url}
          image={mediaUrl.url}
          title={mediaUrl.url}
        />

      </Modal>
      <Modal
        title={intl.formatMessage({ id: 'campaigns.freeFoto.watermark.title' })}
        open={defaultWatermarkModalOpen}
        onClose={() => { setDefaultWatermarkModalOpen(false) }}
      >
        <CardMedia
          component="img"
          style={{ width: imgWidth, backgroundColor: '#F36E21' }}
          alt={(watermark?.length > 0) ? watermark?.[0]?.url : defaultFFWatermarkUrl}
          image={(watermark?.length > 0) ? watermark?.[0]?.url : defaultFFWatermarkUrl}
          title={(watermark?.length > 0) ? watermark?.[0]?.url : defaultFFWatermarkUrl}
        />

      </Modal>
    </Grid >
  );
}

export default injectIntl(Step5);