import { gql } from "apollo-boost";

const OVERVIEW = gql`
  query Overview($input: OverviewQueryInput) {
    overview(input: $input) {
      credit {
        usersAmount
        companiesAmount
        soldAmount
        totalAmount
      }
      devices {
        totalCount
        assignedCount
        unassignedCount
        offlineCount
        lowPaperCount
      }
      users {
        totalCount
        registeredCounts {
          date
          total
        }
      }
      photos {
        totalCount
        printedCounts {
          date
          FreePhoto
          S10x15
          S15x15
          S20x15
        }
      }
      ambassadors {
        referralCounts {
          date
          count
        }
      }
    }
  }
`;

export default OVERVIEW;
