import gql from 'graphql-tag';

const ORDER_CANCEL = gql`
    mutation orderCancel ($id: ID!) {
        orderCancel (id: $id){
            id
        }
    }
`;

export default ORDER_CANCEL;