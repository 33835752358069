import { makeStyles } from "@material-ui/core/styles";
import QueryTable from "components-lib/QueryTable/QueryTable";
import Button from "components/CustomButtons/Button.js";
import paths from "paths";
import React from "react";
import { injectIntl } from "react-intl";
import { generatePath } from "react-router-dom";
import styles from "common/styles/widgets.js";
import { DateTime } from "luxon";

const useStyles = makeStyles((theme) => styles(theme));

export const AmbassadorCommissionsTable = (props) => {
  const {
    history,
    queryVariables,
    query,
    intl,
    defaultFilter = null,
    numberOfRows = 10,
    queryDataPath = ["companiesTable", "items"],
  } = props;
  const classes = useStyles();

  const handleExportFile = (exportFileUrl) => {
    window.open(exportFileUrl);
  };

  return (
    <QueryTable
      query={query}
      numberOfRows={numberOfRows}
      permanentFilter={defaultFilter}
      queryDataPath={queryDataPath}
      queryVariables={queryVariables}
      columns={[
        {
          Header: intl.formatMessage({
            id: "userDetail.ambassadorCommissionsTable.period",
          }),
          accessor: (rowData) =>
            rowData.claimedAt !== null
              ? DateTime.fromISO(rowData.period).toFormat("MM/yyyy")
              : "",
          sortKey: "period",
          filterKey: "period",
          filterOperator: "between",
          filterDataType: "dateBetween",
          filterComponent: "date",
        },
        {
          Header: intl.formatMessage({
            id: "userDetail.ambassadorCommissionsTable.amount",
          }),
          accessor: "amount",
          accessor: (rowData) => rowData.amount + " €",
          sortKey: "amount",
          filterKey: "amount",
          filterOperator: "eq",
          filterDataType: "number",
        },
        {
          Header: intl.formatMessage({
            id: "userDetail.ambassadorCommissionsTable.referrals",
          }),
          accessor: "referrals",
          sortKey: "referrals",
          filterKey: "referrals",
          filterOperator: "eq",
          filterDataType: "number",
        },
        {
          Header: intl.formatMessage({ id: "companyList.table.actions" }),
          accessor: (rowData) => (
            <Button
              round
              className={classes.tenantButtons}
              size="sm"
              color="info"
              onClick={() => handleExportFile(rowData.exportFileUrl)}
            >
              {intl.formatMessage({
                id: "userDetail.ambassadorCommissionsTable.export",
              })}
            </Button>
          ),
        },
      ]}
    />
  );
};

export default injectIntl(AmbassadorCommissionsTable);
