import React from "react";
import IconCard from "components-lib/IconCard/IconCard";
import Grid from "@material-ui/core/Grid";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import { injectIntl } from "react-intl";
import FormikField from "components-lib/FormikField/FormikField";
import RoleEnum from "common/enums/RoleEnum";
import { isInRoles } from "helpers/helpers";
import styles from "common/styles/widgets.js";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import { isEmpty } from "rambda";
import Button from "components/CustomButtons/Button.js";
import USER_UPDATE from "queries/UsersQueries/userUpdate";
import { useMutation } from "@apollo/react-hooks";
import { useSnackbar } from "notistack";

const useStyles = makeStyles((theme) => styles(theme));

const ContactData = (props) => {
  const {
    intl,
    formikProps,
    created,
    canEditUser,
    disabled = false,
    id,
    refetchUserData,
    editableFields,
  } = props;
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [updateUser] = useMutation(USER_UPDATE);

 
  const handleUserUpdate = (values) => {
    // console.log(values)
    updateUser({
      variables: {
        id: id,
        input: {
          firstName: values.firstName,
          lastName: values.lastName,
          gender: values.gender === "Unknown" ? null : values.gender,
          //email: values.email,
          phone: values.phone,
          age: values.age === "Unknown" ? null : values.age,
        },
      },
    })
      .then((response) => {
        enqueueSnackbar(
          intl.formatMessage({ id: "userDetail.userUpdate.success" }),
          { variant: "success" }
        );
        refetchUserData();
      })
      .catch((err) => {
        console.log("[error]", err);
      });
  };

  const renderContent = () => {
    if (
      (isInRoles([RoleEnum.MAINTAINER]) &&
        isInRoles([RoleEnum.ADMIN]) &&
        !created) ||
      editableFields === false
    ) {
      return (
        <Grid container spacing={2}>
          <Grid item sm={12}>
            {intl.formatMessage({ id: "orderDetail.customerCard.name" })}
            <Box className={classes.floatRight}>
              {formikProps.values.firstName} {formikProps.values.lastName}
            </Box>
          </Grid>
          <Grid item sm={12}>
            {intl.formatMessage({ id: "createUser.contact.gender" })}
            <Box className={classes.floatRight}>
              {!formikProps?.values?.gender
                ? intl.formatMessage({
                  id: "createUser.contact.gender.unspecified",
                })
                : formikProps?.values?.gender === "Male"
                  ? intl.formatMessage({ id: "createUser.contact.gender.male" })
                  : intl.formatMessage({
                    id: "createUser.contact.gender.female",
                  })}
            </Box>
          </Grid>
          <Grid item sm={12}>
            {intl.formatMessage({ id: "createUser.contact.email" })}
            <Box className={classes.floatRight}>{formikProps.values.email}</Box>
          </Grid>
          <Grid item sm={12}>
            {intl.formatMessage({ id: "campaigns.companyCard.phone" })}
            <Box className={classes.floatRight}>
              {formikProps.values.phone ? formikProps.values.phone : "-"}
            </Box>
          </Grid>
        </Grid>
      );
    }
    return (
      <>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <FormikField
              name="firstName"
              labelText={intl.formatMessage({
                id: "createUser.contact.firstName",
              })}
              formikProps={formikProps}
            //disabled={disabled}
            />
          </Grid>
          <Grid item xs={6}>
            <FormikField
              name="lastName"
              labelText={intl.formatMessage({
                id: "createUser.contact.lastName",
              })}
              formikProps={formikProps}
            //disabled={disabled}
            />
          </Grid>
          <Grid item xs={4}>
            <FormikField
              type="select"
              name="gender"
              formikProps={formikProps}
              labelText={intl.formatMessage({ id: "createUser.contact.gender" })}
              options={[
                {
                  label: intl.formatMessage({
                    id: "createUser.contact.gender.male",
                  }),
                  value: "Male",
                },
                {
                  label: intl.formatMessage({
                    id: "createUser.contact.gender.female",
                  }),
                  value: "Female",
                },
                {
                  label: intl.formatMessage({
                    id: "createUser.contact.gender.unspecified",
                  }),
                  value: "Unknown",
                },
              ]}
            />
          </Grid>
          <Grid item xs={8}>
            <FormikField
              disabled={disabled}
              name="email"
              labelText={intl.formatMessage({ id: "createUser.contact.email" })}
              formikProps={formikProps}
            />
          </Grid>
          <Grid item xs={4}>
            <FormikField
              type="select"
              name="age"
              formikProps={formikProps}
              labelText={intl.formatMessage({ id: "campaigns.wizard.step4.age" })}
              options={[
                {
                  label: intl.formatMessage({
                    id: "campaigns.wizard.step4.gender.Unknown",
                  }),
                  value: "Unknown",
                },
                {
                  label: intl.formatMessage({
                    id: "campaigns.wizard.step4.gender.R13_18",
                  }),
                  value: "R13_18",
                },
                {
                  label: intl.formatMessage({
                    id: "campaigns.wizard.step4.gender.R19_34",
                  }),
                  value: "R19_34",
                },
                {
                  label: intl.formatMessage({
                    id: "campaigns.wizard.step4.gender.R35_49",
                  }),
                  value: "R35_49",
                },
                {
                  label: intl.formatMessage({
                    id: "campaigns.wizard.step4.gender.R50_",
                  }),
                  value: "R50_",
                },
              ]}
            />
          </Grid>
          {/*<FormikField
            // disabled={disabled}
            name="phone"
            labelText={intl.formatMessage({ id: "createUser.contact.phone" })}
            formikProps={formikProps}
            />*/}
        </Grid>
        {!created && (
          <Grid item sm={12}>
            <br />
            <Button
              color="primary"
              size="sm"
              round
              onClick={(e) =>
                isEmpty(formikProps.errors) &&
                handleUserUpdate(formikProps.values)
              }
            >
              {intl.formatMessage({ id: "userDetail.buttons.confirm" })}
            </Button>
            <Button
              color="info"
              size="sm"
              round
              onClick={() => formikProps.resetForm()}
            >
              {intl.formatMessage({ id: "settings.cancel" })}
            </Button>
          </Grid>
        )}
      </>
    );
  };

  return (
    <IconCard
      title={
        canEditUser
          ? intl.formatMessage({ id: "createUser.contact.title" })
          : intl.formatMessage({ id: "userDetail.contactInfo.create" })
      }
      icon={<AssignmentIndIcon />}
    >
      {renderContent()}
    </IconCard>
  );
};

export default injectIntl(ContactData);
