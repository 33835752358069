import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import getProjectRoutes from "routes.js";
import { injectIntl } from 'react-intl';
import styles from "assets/jss/material-dashboard-pro-react/layouts/authStyle.js";
import register from "assets/img/register.jpeg";
import login from "assets/img/login.jpeg";
import error from "assets/img/clint-mckoy.jpg";
import paths from 'paths';

const useStyles = makeStyles(styles);

function Pages(props) {
  const { intl } = props;
  // ref for the wrapper div
  const wrapper = React.createRef();

  const routes = getProjectRoutes(intl);
  // styles
  const classes = useStyles();
  React.useEffect(() => {
    document.body.style.overflow = "unset";
    // Specify how to clean up after this effect:
    return function cleanup() { };
  });
  const getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/auth") {
        return (
          <Route
            exact
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  const getBgImage = () => {
    if (window.location.pathname.indexOf(`/auth${paths.auth.registration}`) !== -1) {
      return register;
    } else if (window.location.pathname.indexOf(`/auth${paths.auth.login}`) !== -1) {
      return login;
    } else if (window.location.pathname.indexOf(`/auth${paths.auth.error}`) !== -1) {
      return error;
    }
    return login;
  };

  /*const getActiveRoute = routes => {
    let activeRoute = "Default Brand Text";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (
          window.location.pathname.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };*/

  return (
    <div>
      <div className={classes.wrapper} ref={wrapper}>
        <div
          className={classes.fullPage}
          style={{ backgroundImage: "url(" + getBgImage() + ")" }}
        >
          <Switch>
            {getRoutes(routes)}
            <Redirect from="/auth" to={`/auth${paths.auth.login}`} />
          </Switch>
        </div>
      </div>
    </div>
  );
}

export default injectIntl(Pages);
