import gql from 'graphql-tag';

const SEGMENT_UPDATE = gql`
mutation segmentUpdate ($id: ID!, $input: SegmentUpdateInput!) {
  segmentUpdate (id: $id, input: $input) {
      id
      name
  }
}
`;

export default SEGMENT_UPDATE;