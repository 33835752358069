import { Grid } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import EuroIcon from '@material-ui/icons/Euro';
import IconCard from "components-lib/IconCard/IconCard";
import React from "react";
import { injectIntl } from "react-intl";
import styles from "common/styles/widgets.js"
import FormikField from "../../../components-lib/FormikField/FormikField";

const useStyles = makeStyles((theme) => styles(theme));

const DetailBudgetCard = (props) => {

    const {
        intl,
        budgetTotal,
        budgetSpent,
        budgetRemaining,
        averageBid,
        formikProps,
        isCanceled
    } = props;

    const classes = useStyles();

    return <>
        <IconCard
            title={intl.formatMessage({ id: 'campaigns.budgetCard.title' })}
            icon={<EuroIcon />}
            className= "detailBudget"
        >
            <>
                <Grid container spacing={1}>
                    <Grid item sm={12}>
                        {intl.formatMessage({ id: 'campaigns.budgetCard.budgetSpent' })}
                        <Box className={classes.floatRight}>
                            {(budgetSpent) ? budgetSpent : "0"}
                        </Box>
                    </Grid>
                    <Grid item sm={12}>
                        {intl.formatMessage({ id: 'campaigns.budgetCard.budgetRemaining' })}
                        <Box className={classes.floatRight}>
                            {(budgetRemaining) ? budgetRemaining : "0"}
                        </Box>
                    </Grid>
                    <Grid item sm={12}>
                        {intl.formatMessage({ id: 'campaigns.budgetCard.budgetTotal' })}
                        <Box className={classes.floatRight}>
                            {(budgetTotal) ? budgetTotal : "0"}
                        </Box>
                    </Grid>
                </Grid>
                <hr style={{ margin: "10px" }} />
                <Grid container spacing={1}>
                    <Grid item sm={12}>
                        {intl.formatMessage({ id: 'campaigns.budgetCard.AvgBid' })}
                        <Box className={classes.floatRight}>
                            {(averageBid) ? averageBid : "-"}
                        </Box>
                    </Grid>
                    <Grid item sm={12}>
                        <FormikField
                            name="campaign.maxBid"
                            disabled={isCanceled}
                            labelText={intl.formatMessage({ id: 'campaigns.budgetCard.MaxBid' })}
                            formikProps={formikProps}
                            data={formikProps.values}
                            highlightChange
                        />
                    </Grid>
                </Grid>
            </>
        </IconCard>
    </>
};

export default injectIntl(DetailBudgetCard);
