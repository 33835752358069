import gql from 'graphql-tag';

const COUPON_SET_ASSIGNED = gql`
mutation couponCampaignAssignCouponSet(
  $name: String,
    $id: ID!
    $couponSetId: ID!
) {
  couponCampaignAssignCouponSet(id: $id, couponSetId: $couponSetId,  name: $name) {
    id
  }
}
`;

export default COUPON_SET_ASSIGNED;

