import gql from 'graphql-tag';

const ALL_COUPON_SETS = gql`
query couponSetsTable(
  $offset: Int, 
  $limit: Int, 
  $sort: [CouponSetsTableSortInput!],
  $filter: [CouponSetsTableFilterInput!]) {
    couponSetsTable(offset: $offset, limit: $limit, sort: $sort, filter: $filter) {
        totalCount
        items {
             id
             bid
            status
            count
            type
            name
            usedCount
            couponNominalValue
            totalSetValue
            usedCouponsValue
            createdBy
            createdAt
        }
    }
}
`;


export default ALL_COUPON_SETS;