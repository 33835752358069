import { makeStyles } from "@material-ui/core/styles";
import QueryTable from "components-lib/QueryTable/QueryTable";
import Button from "components/CustomButtons/Button.js";
import paths from "paths";
import React from "react";
import { injectIntl } from "react-intl";
import { generatePath } from "react-router-dom";
import styles from "common/styles/widgets.js";
import { DateTime } from "luxon";

const useStyles = makeStyles((theme) => styles(theme));

export const AmbassadorReferralsTable = (props) => {
  const {
    history,
    queryVariables,
    query,
    intl,
    defaultFilter = null,
    numberOfRows = 10,
    queryDataPath = ["companiesTable", "items"],
  } = props;
  const classes = useStyles();

  const onCompanyDetail = (id) => {
    history.push(
      `/admin${generatePath(paths.companies.detail, { companyId: id })}`
    );
  };

  return (
    <QueryTable
      query={query}
      numberOfRows={numberOfRows}
      permanentFilter={defaultFilter}
      queryDataPath={queryDataPath}
      queryVariables={queryVariables}
      columns={[
        {
          Header: intl.formatMessage({
            id: "userDetail.ambassadorReferralsTable.claimedAt",
          }),
          accessor: (rowData) =>
            rowData.claimedAt !== null
              ? DateTime.fromISO(rowData.claimedAt).toFormat("dd.MM.yyyy, HH:mm:ss")
              : "",
          sortKey: "claimedAt",
          filterKey: "claimedAt",
          filterOperator: "between",
          filterDataType: "dateBetween",
          filterComponent: "date",
        },
        {
          Header: intl.formatMessage({
            id: "userDetail.ambassadorReferralsTable.user",
          }),
          accessor: "email",
          sortKey: "email",
          filterKey: "email",
          filterOperator: "contains",
        },
        {
          Header: intl.formatMessage({
            id: "userDetail.ambassadorReferralsTable.type",
          }),
          accessor: (rowData) =>
            intl.formatMessage({
              id: `usersPage.table.ambassador.${rowData.type}`,
            }),
          sortKey: "type",
          filterKey: "type",
          filterComponent: "select",
          filterOperator: "eq",
          filterOptions: [
            {
              value: "Money",
              label: intl.formatMessage({
                id: `usersPage.table.ambassador.Money`,
              }),
            },
            {
              value: "Credit",
              label: intl.formatMessage({
                id: `usersPage.table.ambassador.Credit`,
              }),
            },
          ],
        },
        {
          Header: intl.formatMessage({
            id: "userDetail.ambassadorReferralsTable.customerReward",
          }),
          accessor: (rowData) => rowData.customerReward + " C",
          sortKey: "customerReward",
          filterKey: "customerReward",
          filterOperator: "eq",
          filterDataType: "number",
        },
        {
          Header: intl.formatMessage({
            id: "userDetail.ambassadorReferralsTable.referralReward",
          }),
          accessor: (rowData) => (
            <>
              {rowData.referralReward} {rowData.type === "Credit" ? " C" : " €"}
            </>
          ),
          sortKey: "referralReward",
          filterKey: "referralReward",
          filterOperator: "eq",
          filterDataType: "number",
        },
      ]}
    />
  );
};

export default injectIntl(AmbassadorReferralsTable);
