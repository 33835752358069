import gql from 'graphql-tag';

const CAMPAIGN_DETAILS = gql`
query campaign($id:ID!){
    campaign(id: $id){
        id
        bid
        name
        description
        acceptanceStatus
        type
        stats {
            avgPrice
        }
        description
        validFrom
        validTo
        status
        minRepeatInterval
        createdAt
        createdBy{
            id
            fullName
        }

        criteria{
            genderMaleAge
            genderFemaleAge
            unknownGenderAge
            locations{
                id
                name
            }
            segments{
                id
                name
            }
            companies{
                id
                name
            }
            zones{
                id
                name
            }
            match{
                locationsCount
                segmentsCount
                companiesCount
                zonesCount
            }
        }

        criteriaMatchedZones{
            id
            bid
            name
            area
            visitors
            rank
            store{
                id
                bid
                name
                segment
                address
                postal
                city
                country
                latitude
                longtitude
                fullAddress
                contactFirstName
                contactLastName
                contactPhone
                contactEmail
            }
            
            device{
                id
            }
            createdBy{
                id
                fullName
            }
        }

        topics{
            id
            name
        }

        media{
            media{
                id
                bid
                name
            }
            url
            usageCount
        }
        watermark{
            id
            bid
            url
            name
        }

        budget{
            total
            spent
            remaining
        }
        
        maxBid

        company{
            id
            bid
            name
            address
            postal
            city
            contactEmail
            contactPhone
            contactFirstName
            contactLastName
            
            stores{
                id
                name
                company{
                    name
                }
                segment
                address
                postal
                city
                country
                latitude
                longtitude
                fullAddress
           }
        }      
        activeTimes{
            weekDay
            from
            to
        }


    }
}
`;

export default CAMPAIGN_DETAILS;
