import React from 'react';
import { Grid } from '@material-ui/core';
import EuroIcon from '@material-ui/icons/Euro';
import styles from "common/styles/widgets.js";
import { makeStyles } from '@material-ui/core/styles';
import { injectIntl } from 'react-intl';
import IconCard from "components-lib/IconCard/IconCard";
import ChartistGraph from "react-chartist";

const useStyles = makeStyles((theme) => styles(theme))

const DetailChartCouponsCard = (props) => {
    const {
        intl,
        remainingCoupons,
        usedCoupons,
        totalCoupons,
        remainingBudget,
        allocatedBudget,
        usedBudget,
        unusedBudget,
        totalBudget,
        pqrType
    } = props;
    const classes = useStyles();

    const pieChartCoupons = {
        data: {
            labels: [`${remainingCoupons} ${intl.formatMessage({ id: 'tableTexts.piece' })}`, `${usedCoupons} ${intl.formatMessage({ id: 'tableTexts.piece' })}`],
            series: [
                {
                    value: remainingCoupons,
                    className: classes.pieChartOne
                },
                {
                    value: usedCoupons,
                    className: classes.pieChartTwo
                }]
        },
    };

    const pieChartAllocated = {
        data: {
            labels: [`${unusedBudget} C`, `${usedBudget} C`],
            series: [
                {
                    value: unusedBudget,
                    className: classes.pieChartOne
                },
                {
                    value: usedBudget,
                    className: classes.pieChartTwo
                }]
        },
    };

    const pieChartBudget = {
        data: {
            labels: [`${remainingBudget} C`, `${allocatedBudget} C`],
            series: [
                {
                    value: remainingBudget,
                    className: classes.pieChartOne
                },
                {
                    value: allocatedBudget,
                    className: classes.pieChartTwo
                }]
        },
    };

    const getProvisionsTable = () => {
        return (
            <Grid container>
                <Grid container spacing={3} style={{ marginLeft: "55px" }}>
                    <Grid item sm={4}>
                        <p >
                            <span className={classes.oneCircle} ></span>
                            {intl.formatMessage({ id: 'campaigns.couponsChart.remainingBudget' })}: {remainingBudget} C
                            </p>
                        <p>
                            <span className={classes.twoCircle}></span>
                            {intl.formatMessage({ id: 'campaigns.couponsChart.allocatedBudget' })}: {allocatedBudget} C
                            </p>
                        <p style={{ marginLeft: "15px" }}>
                            {intl.formatMessage({ id: 'campaigns.couponsChart.allBudget' })}: {totalBudget} C
                            </p>
                    </Grid>
                    {!pqrType &&
                        <>
                            <Grid item sm={4}>
                                <p>
                                    <span className={classes.oneCircle} ></span>
                                    {intl.formatMessage({ id: 'campaigns.couponsChart.unusedBudget' })}: {unusedBudget} C
                            </p>
                                <p>
                                    <span className={classes.twoCircle}></span>
                                    {intl.formatMessage({ id: 'campaigns.couponsChart.usedBudget' })}: {usedBudget} C
                            </p>
                                <p style={{ marginLeft: "15px" }}>
                                    {intl.formatMessage({ id: 'campaigns.couponsChart.allocatedBudget' })}: {allocatedBudget} C
                            </p>
                            </Grid>

                            <Grid item sm={4}>
                                <p >
                                    <span className={classes.oneCircle} ></span>
                                    {intl.formatMessage({ id: 'campaigns.couponsChart.remainingCoupons' })}: {remainingCoupons} {intl.formatMessage({ id: 'tableTexts.piece' })}
                                </p>
                                <p >
                                    <span className={classes.twoCircle}></span>
                                    {intl.formatMessage({ id: 'campaigns.couponsChart.usedCoupons' })}: {usedCoupons} {intl.formatMessage({ id: 'tableTexts.piece' })}
                                </p>
                                <p style={{ marginLeft: "15px" }}>
                                    {intl.formatMessage({ id: 'campaigns.couponsChart.allCoupons' })}: {totalCoupons} {intl.formatMessage({ id: 'tableTexts.piece' })}
                                </p>
                            </Grid>
                        </>
                    }
                </Grid>
            </Grid>
        )
    }
    return (
        <>
            <IconCard
                title={(!pqrType)
                    ? intl.formatMessage({ id: 'campaigns.couponsChart.title' })
                    : "Štatistiky"
                }
                icon={<EuroIcon />}
            >
                <Grid container>
                    <Grid container spacing={3}>
                        <Grid item sm={4}>
                            <ChartistGraph
                                data={pieChartBudget.data}
                                type="Pie"
                                options={pieChartBudget.options}
                            />
                        </Grid>
                        {!pqrType &&
                            <>
                                <Grid item sm={4}>
                                    {(allocatedBudget || usedBudget) ?
                                        <ChartistGraph
                                            data={pieChartAllocated.data}
                                            type="Pie"
                                            options={pieChartAllocated.options}
                                        />
                                        :
                                        <p style={{ textAlign: "center" }}>{intl.formatMessage({ id: 'campaigns.couponsChart.noAllocated' })}</p>
                                    }
                                </Grid>

                                <Grid item sm={4}>
                                    {(remainingCoupons || usedCoupons) ?
                                        <>
                                            <ChartistGraph
                                                data={pieChartCoupons.data}
                                                type="Pie"
                                                options={pieChartCoupons.options}
                                            />
                                        </>
                                        :
                                        <p style={{ textAlign: "center" }}>{intl.formatMessage({ id: 'campaigns.couponsChart.noCoupons' })}</p>
                                    }
                                </Grid>
                            </>
                        }
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item sm={12}>
                        <hr />
                        {getProvisionsTable()}
                    </Grid>
                </Grid>
            </IconCard>
        </>
    )
}

export default injectIntl(DetailChartCouponsCard);
