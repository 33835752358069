import QueryTable from 'components-lib/QueryTable/QueryTable';
import paths from 'paths';
import React from 'react';
import { injectIntl } from 'react-intl';
import { generatePath } from 'react-router-dom';
import Box from '@material-ui/core/Box';

export const PaymentTable = (props) => {
    const { history, query, detail, intl, defaultFilter, queryVariables, numberOfRows, toggle, queryDataPath } = props

    const onOperationDetail = (id) => {
        history.push({
            pathname: `/admin${generatePath(paths.operations.list)}`,
            search: `?filter[bid][contains]=${id}`,
        });
    }

    const onUserDetail = (id) => {
        history.push(`/admin${generatePath(paths.users.detail, { userId: id })}`);
    }

    const getStatus = (status) => {
        switch (status) {
            case "Pending":
                return <Box color="#9e9e9e">{intl.formatMessage({ id: 'paymentsList.status.Pending' })}</Box>;
            case "Processing":
                return <Box color="#9e9e9e">{intl.formatMessage({ id: 'paymentsList.status.Processing' })}</Box>;
            case "Succeeded":
                return <Box color="success.main">{intl.formatMessage({ id: 'paymentsList.status.Succeeded' })}</Box>;
            case "Expired":
                return <Box color="#9e9e9e">{intl.formatMessage({ id: 'paymentsList.status.Expired' })}</Box>;
            default:
                return status;
        }
    }

    const getColumns = () => {
        return (
            [
                {
                    Header: intl.formatMessage({ id: 'paymentsList.table.ID' }),
                    accessor: 'bid',
                    sortKey: 'bid',
                    filterKey: 'bid',
                    filterOperator: 'contains'
                },
                {
                    Header: intl.formatMessage({ id: 'paymentsList.table.payoutID' }),
                    accessor: 'checkoutId',
                    sortKey: 'checkoutId',
                    filterKey: 'checkoutId',
                    filterOperator: 'contains'
                },
                {
                    Header: intl.formatMessage({ id: 'paymentsList.table.value' }),
                    accessor: (rowData) => rowData.amount + " €",
                    sortKey: "amount",
                    filterKey: "amount",
                    filterOperator: 'eq',
                    filterDataType: 'number',
                },
                {
                    Header: intl.formatMessage({ id: 'paymentsList.table.price' }),
                    accessor: (rowData) => rowData.credit + " C",
                    sortKey: "credit",
                    filterKey: "credit",
                    filterOperator: 'eq',
                    filterDataType: 'number',
                },
                {
                    Header: intl.formatMessage({ id: 'paymentsList.table.date' }),
                    /*accessor: (rowData) => rowData.completedAt !== null
                        ? DateTime.fromISO(rowData.completedAt).toFormat("dd.MM.yyyy hh:mm")
                        : "",*/
                    accessor: (rowData) => new Date(rowData.createdAt).toLocaleString('sk'),
                    sortKey: "createdAt",
                    filterKey: "createdAt",
                    filterOperator: 'between',
                    filterDataType: "dateBetween",
                    filterComponent: 'date',
                },
                {
                    Header: intl.formatMessage({ id: 'paymentsList.table.purpose' }),
                    accessor: (rowData) => (rowData.purpose === 'OrderPayment')
                        ? intl.formatMessage({ id: 'paymentsList.table.purpose.OrderPayment' })
                        : intl.formatMessage({ id: 'paymentsList.table.purpose.Subscription' }),
                    sortKey: 'purpose',
                    filterKey: 'purpose',
                    filterComponent: 'select',
                    filterOperator: 'eq',
                    filterOptions: [
                        {
                            value: 'OrderPayment',
                            label: intl.formatMessage({ id: 'paymentsList.table.purpose.OrderPayment' }),
                        },
                        {
                            value: 'Subscription',
                            label: intl.formatMessage({ id: 'paymentsList.table.purpose.Subscription' }),
                        }
                    ]
                },
                {
                    Header: intl.formatMessage({ id: 'paymentsList.table.status' }),
                    accessor: (rowData) => getStatus(rowData.status),
                    sortKey: 'status',
                    filterKey: 'status',
                    filterComponent: 'select',
                    filterOperator: 'in',
                    filterOptions: [
                        {
                            value: 'Pending',
                            label: intl.formatMessage({ id: 'paymentsList.status.Pending' })
                        },
                        {
                            value: 'Processing',
                            label: intl.formatMessage({ id: 'paymentsList.status.Processing' }),
                        },
                        {
                            value: 'Succeeded',
                            label: intl.formatMessage({ id: 'paymentsList.status.Succeeded' })
                        },
                        {
                            value: 'Expired',
                            label: intl.formatMessage({ id: 'paymentsList.status.Expired' }),
                        },
                    ]
                },
                {
                    Header: intl.formatMessage({ id: 'paymentsList.table.user' }),
                    accessor: (rowData) => <span style={{ textDecoration: "underline", cursor: "pointer" }} onClick={() => onUserDetail(rowData.userId)} >
                        {rowData.userEmail}</span>,
                    sortKey: 'userEmail',
                    filterKey: 'userEmail',
                    filterOperator: 'contains'
                },
                {
                    Header: intl.formatMessage({ id: 'paymentsList.table.operationID' }),
                    accessor: (rowData) => (rowData.operationBid)
                        ? <span style={{ cursor: "pointer" }} onClick={() => onOperationDetail(rowData.operationBid)} >
                            {rowData.operationBid}
                        </span>
                        : "-",
                    sortKey: 'operationBid',
                    filterKey: 'operationBid',
                    filterOperator: 'contains'
                },
                {
                    Header: " ",
                }
            ])
    }


    const getColumnsDetail = () => {
        return (
            [
                {
                    Header: intl.formatMessage({ id: 'paymentsList.table.ID' }),
                    accessor: 'bid',
                    sortKey: 'bid',
                    filterKey: 'bid',
                    filterOperator: 'contains'
                },
                {
                    Header: intl.formatMessage({ id: 'paymentsList.table.payoutID' }),
                    accessor: 'checkoutId',
                    sortKey: 'checkoutId',
                    filterKey: 'checkoutId',
                    filterOperator: 'contains'
                },
                {
                    Header: intl.formatMessage({ id: 'paymentsList.table.purpose' }),
                    accessor: (rowData) => (rowData.purpose === 'OrderPayment')
                        ? intl.formatMessage({ id: 'paymentsList.table.purpose.OrderPayment' })
                        : intl.formatMessage({ id: 'paymentsList.table.purpose.Subscription' }),
                    sortKey: 'purpose',
                    filterKey: 'purpose',
                    filterComponent: 'select',
                    filterOperator: 'eq',
                    filterOptions: [
                        {
                            value: 'OrderPayment',
                            label: intl.formatMessage({ id: 'paymentsList.table.purpose.OrderPayment' }),
                        },
                        {
                            value: 'Subscription',
                            label: intl.formatMessage({ id: 'paymentsList.table.purpose.Subscription' }),
                        }
                    ]
                },
                {
                    Header: intl.formatMessage({ id: 'paymentsList.table.date' }),
                    accessor: (rowData) => new Date(rowData.createdAt).toLocaleString('sk'),
                    sortKey: "createdAt",
                    filterKey: "createdAt",
                    filterOperator: 'between',
                    filterDataType: "dateBetween",
                    filterComponent: 'date',
                },
                {
                    Header: intl.formatMessage({ id: 'paymentsList.table.status' }),
                    accessor: (rowData) => getStatus(rowData.status),
                    sortKey: 'status',
                    filterKey: 'status',
                    filterComponent: 'select',
                    filterOperator: 'in',
                    filterOptions: [
                        {
                            value: 'Pending',
                            label: intl.formatMessage({ id: 'paymentsList.status.Pending' })
                        },
                        {
                            value: 'Processing',
                            label: intl.formatMessage({ id: 'paymentsList.status.Processing' }),
                        },
                        {
                            value: 'Succeeded',
                            label: intl.formatMessage({ id: 'paymentsList.status.Succeeded' })
                        },
                        {
                            value: 'Expired',
                            label: intl.formatMessage({ id: 'paymentsList.status.Expired' }),
                        },
                    ]
                },
                {
                    Header: intl.formatMessage({ id: 'paymentsList.table.price' }),
                    accessor: (rowData) => rowData.credit + " C",
                    sortKey: "credit",
                    filterKey: "credit",
                    filterOperator: 'eq',
                    filterDataType: 'number',
                },
                {
                    Header: intl.formatMessage({ id: 'paymentsList.table.value' }),
                    accessor: (rowData) => rowData.amount + " €",
                    sortKey: "amount",
                    filterKey: "amount",
                    filterOperator: 'eq',
                    filterDataType: 'number',
                },
                {
                    Header: " ",
                }
            ])
    }


    return (
        <QueryTable
            query={query}
            toggle={toggle}
            queryVariables={queryVariables}
            permanentFilter={defaultFilter}
            numberOfRows={numberOfRows}
            queryDataPath={queryDataPath}
            columns={(detail) ? getColumnsDetail() : getColumns()}
        />
    )
}

export default injectIntl(PaymentTable);