import gql from 'graphql-tag';

const ALL_COMPANIES = gql`
query companiesTable(
  $offset: Int
  $limit: Int
  $sort: [CompaniesTableSortInput!]
  $filter: [CompaniesTableFilterInput]
) {
  companiesTable(offset: $offset, limit: $limit, sort: $sort, filter: $filter) {
    totalCount,
    items {
      id
      bid
      name
      stores
    }
  }
}
`;

export default ALL_COMPANIES;