import gql from 'graphql-tag';

const PLAYLISTS = gql`
query playlists{
  playlists{
    totalCount
    items{
      name
    }
  }
}
`;

export default PLAYLISTS;