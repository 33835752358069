import React from "react";
import { injectIntl } from "react-intl";
import QueryTable from "components-lib/QueryTable/QueryTable";
import paths from "paths";
import { pathOr } from "rambda";
import Button from "components/CustomButtons/Button.js";
import colors from "common/styles/colors";
import { generatePath } from "react-router-dom";
import RoleEnum from "common/enums/RoleEnum";
import { isInRoles } from "helpers/helpers";
import Box from "@material-ui/core/Box";
import ButtonDetail from 'components/ButtonDetail/ButtonDetail';

export const NotificationTable = (props) => {
  const { history, query, intl, defaultFilter, queryDataPath } = props;

  const getSubscription = (role, subscription, subscriptionStatus) => {
    if (role === RoleEnum.USER) {
      if (subscription !== "None") {
        if (subscriptionStatus === "Active")
          return <Box color="success.main">{subscription}</Box>;
        else return <Box>{subscription}</Box>;
      } else
        return (
          <Box style={{ color: "gray" }}>
            {" "}
            {intl.formatMessage({ id: "userSubscription.Nothing" })}
          </Box>
        );
    } else return "-";
  };

  return (
    <QueryTable
      query={query}
      queryDataPath={queryDataPath}
      permanentFilter={defaultFilter}
      columns={[
        {
          Header: intl.formatMessage({ id: "usersPage.table.id" }),
          accessor: "bid",
          sortKey: "bid",
          filterKey: "bid",
          filterOperator: "contains",
        },
        {
          Header: intl.formatMessage({ id: "usersPage.table.name" }),
          accessor: "fullName",
          filterKey: "fullName",
          filterOperator: "contains",
          sortKey: "fullName",
        },
        {
          Header: intl.formatMessage({ id: "usersPage.table.email" }),
          accessor: "email",
          filterKey: "email",
          filterOperator: "contains",
          sortKey: "email",
        },
        {
          Header: intl.formatMessage({ id: "usersPage.table.role" }),
          accessor: (rowData) =>
            intl.formatMessage({
              id: `userRoles.${rowData.role.toLowerCase()}`,
            }),
          sortKey: "role",
          filterKey: "role",
          filterComponent: "select",
          filterOperator: "in",
          filterOptions: isInRoles([RoleEnum.ADMIN])
            ? [
                {
                  value: RoleEnum.PARTNER,
                  label: intl.formatMessage({ id: "userRoles.partner" }),
                },
                {
                  value: RoleEnum.ADVERTISER,
                  label: intl.formatMessage({ id: "userRoles.advertiser" }),
                },
                {
                  value: RoleEnum.USER,
                  label: intl.formatMessage({ id: "userRoles.user" }),
                },
                {
                  value: RoleEnum.MAINTAINER,
                  label: intl.formatMessage({ id: "userRoles.maintainer" }),
                },
                {
                  value: RoleEnum.ADMIN,
                  label: intl.formatMessage({ id: "userRoles.admin" }),
                },
              ]
            : [
                {
                  value: RoleEnum.PARTNER,
                  label: intl.formatMessage({ id: "userRoles.partner" }),
                },
                {
                  value: RoleEnum.ADVERTISER,
                  label: intl.formatMessage({ id: "userRoles.advertiser" }),
                },
                {
                  value: RoleEnum.USER,
                  label: intl.formatMessage({ id: "userRoles.user" }),
                },
                {
                  value: RoleEnum.MAINTAINER,
                  label: intl.formatMessage({ id: "userRoles.maintainer" }),
                },
              ],
        },
        {
          Header: intl.formatMessage({ id: "usersPage.table.ambassador" }),
          accessor: (rowData) =>
            intl.formatMessage({
              id: `usersPage.table.ambassador.${rowData.ambassador}`,
            }),
          sortKey: "ambassador",
          filterKey: "ambassador",
          filterComponent: "select",
          filterOperator: "in",
          filterOptions: [
            {
              value: "Money",
              label: intl.formatMessage({
                id: `usersPage.table.ambassador.Money`,
              }),
            },
            {
              value: "Credit",
              label: intl.formatMessage({
                id: `usersPage.table.ambassador.Credit`,
              }),
            },
            {
              value: "Inactive",
              label: intl.formatMessage({
                id: `usersPage.table.ambassador.Inactive`,
              }),
            },
          ],
        },
        {
          Header: intl.formatMessage({ id: "usersPage.table.status" }),
          accessor: (rowData) => {
            const status = pathOr(false, ["status"], rowData);
            if (status === "Activated") {
              return (
                <span style={{ color: colors.success }}>
                  {intl
                    .formatMessage({ id: "userStatus.activated" })
                    .toUpperCase()}
                </span>
              );
            } else if (status === "Blocked") {
              return (
                <span style={{ color: colors.danger }}>
                  {intl
                    .formatMessage({ id: "userStatus.blocked" })
                    .toUpperCase()}
                </span>
              );
            } else if (status === "Unactivated") {
              return (
                <span style={{ color: colors.warning }}>
                  {intl
                    .formatMessage({ id: "userStatus.unactivated" })
                    .toUpperCase()}
                </span>
              );
            }
          },
          sortKey: "status",
          filterKey: "status",
          filterComponent: "select",
          filterOperator: "in",
          filterOptions: [
            {
              value: "Activated",
              label: intl.formatMessage({ id: "userStatus.activated" }),
            },
            {
              value: "Blocked",
              label: intl.formatMessage({ id: "userStatus.blocked" }),
            },
            {
              value: "Unactivated",
              label: intl.formatMessage({ id: "userStatus.unactivated" }),
            },
          ],
        },
        {
          Header: intl.formatMessage({ id: "usersPage.table.credits" }),
          accessor: (rowData) =>
            rowData.role === "User" ? rowData.balance + " C" : "-",
          sortKey: "balance",
          filterKey: "balance",
          filterOperator: "eq",
          filterDataType: "number",
        },
        {
          Header: intl.formatMessage({ id: "usersPage.table.subscription" }),
          accessor: (rowData) =>
            getSubscription(
              rowData.role,
              rowData.subscription,
              rowData.subscriptionStatus
            ),
          sortKey: "subscription",
          filterKey: "subscription",
          filterComponent: "select",
          nullFilter: true,
          filterOperator: "in",
          filterOptions: [
            {
              value: "None",
              label: (
                <p style={{ color: "gray" }}>
                  {intl.formatMessage({ id: "userSubscription.Nothing" })}
                </p>
              ),
            },
            {
              value: "Family",
              label: "Family",
            },
            {
              value: "Single",
              label: "Single",
            },
          ],
        },

        {
          Header: intl.formatMessage({ id: "usersPage.table.actions" }),
          accessor: (rowData) => (
            <div style={{ display: "flex", flexDirection: "row",  flexWrap: "wrap",
              justifyContent: "flex-start", alignItems: "center", gap: "0.2em" }}>

              <ButtonDetail
              // classes={classes.tenantButtons}
              onClick={ () =>
                history.push(
                  `/admin${generatePath(paths.users.detail, {
                    userId: rowData.id, })}`
                ) }
              />
            </div>
          ),
        },
      ]}
    />
  );
};

export default injectIntl(NotificationTable);
