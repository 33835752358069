import gql from 'graphql-tag';

const TOPIC_CREATE = gql`
mutation topicCreate($input: TopicCreateInput!){
  topicCreate(input: $input){
		id
    name
    }
  }
`;

export default TOPIC_CREATE;