import { Grid } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import InfoIcon from '@material-ui/icons/Info';
import IconCard from "components-lib/IconCard/IconCard";
import React from "react";
import { injectIntl } from "react-intl";
import styles from "common/styles/widgets.js"
import moment from "moment";
import FormikField from "../../../components-lib/FormikField/FormikField";
import { pathOr } from "rambda";

const useStyles = makeStyles((theme) => styles(theme));

const InfoCard = (props) => {
    const {
        formikProps,
        intl,
        type,
        name,
        company,
        topics,
        startDate,
        endDate,
        description,
        createdAt,
        createdBy,
        total,
        isFreePhoto,
        isCanceled
    } = props;
    const classes = useStyles();


    return <>
        <IconCard
            title={intl.formatMessage({ id: 'campaigns.infoCard.title' })}
            icon={<InfoIcon />}
            className= "detailInfo"
        >
            <Grid container spacing={2}>
                <Grid item sm={12}>
                    {intl.formatMessage({ id: 'campaigns.infoCard.type' })}
                    <Box className={classes.floatRight}>
                        {type === "PublicQr" ? "MQR" : (type === "Qr") ? "QR" : "FreeFoto"}
                    </Box>
                </Grid>
                <Grid item sm={12}>
                    {intl.formatMessage({ id: 'campaigns.infoCard.name' })}
                    <Box className={classes.floatRight}>
                        {name}
                    </Box>
                </Grid>
                <Grid item sm={12}>
                    {intl.formatMessage({ id: 'campaigns.infoCard.company' })}
                    <Box className={classes.floatRight}>
                        {company}
                    </Box>
                </Grid>
                {isFreePhoto &&
                    <Grid item sm={12}>
                        {intl.formatMessage({ id: 'campaigns.infoCard.topics' })}
                        <Box className={classes.floatRight}>
                            <Box className={classes.floatRight}>
                                {topics?.map((item, idx) => (
                                    <>{item.name}, </>
                                ))}
                            </Box>
                        </Box>
                    </Grid>
                }
                {!isFreePhoto &&
                    <>
                        <Grid item sm={12}>
                            {intl.formatMessage({ id: 'campaigns.infoCard.total' })}
                            <Box className={classes.floatRight}>
                                {total} C
                            </Box>
                        </Grid>

                        <Grid item sm={12}>
                            {intl.formatMessage({ id: 'campaigns.infoCard.startDate' })}
                            <Box className={classes.floatRight}>
                                {moment(startDate).format("DD.MM.YYYY HH:mm:ss")}
                            </Box>
                        </Grid>
                    </>
                }
                {!isFreePhoto ?
                    <>
                        <Grid item sm={12}>
                            {intl.formatMessage({ id: 'campaigns.infoCard.endDate' })}
                            <Box className={classes.floatRight}>
                                {moment(endDate).format("DD.MM.YYYY HH:mm:ss")}
                            </Box>
                        </Grid>
                        <Grid item sm={12}>
                            {intl.formatMessage({ id: 'campaigns.infoCard.description' })}
                            <Box className={classes.floatRight}>
                                <p>{description.substring(0, 40)}</p>
                                <p>{description.substring(41, 80)}</p>
                                <p>{description.substring(81, 120)}</p>
                            </Box>
                        </Grid>
                    </>
                    :
                    <Grid container>
                        <Grid container spacing={3}>
                            <Grid item sm={6}>
                                <br />
                                <FormikField
                                    disabled={true}
                                    name="campaign.validFrom"
                                    labelText={intl.formatMessage({ id: 'campaigns.infoCard.startDate' })}
                                    formikProps={formikProps}
                                    data={formikProps.values}
                                    highlightChange
                                    type="picker2"
                                />
                            </Grid>
                            <Grid item sm={6}>
                                <br />
                                <FormikField
                                    disabled={isCanceled}
                                    name="campaign.validTo"
                                    labelText={intl.formatMessage({ id: 'campaigns.infoCard.endDate' })}
                                    formikProps={formikProps}
                                    data={formikProps.values}
                                    highlightChange
                                    type="picker2"
                                />
                            </Grid>
                        </Grid>
                        {isCanceled
                            ?
                            <Grid item sm={12}>
                                <br />
                                {intl.formatMessage({ id: 'campaigns.infoCard.description' })}
                                <Box className={classes.floatRight}>
                                    <p>{description.substring(0, 40)}</p>
                                    <p>{description.substring(41, 80)}</p>
                                    <p>{description.substring(81, 120)}</p>
                                </Box>
                            </Grid>
                            :
                            <FormikField
                                name="campaign.description"
                                type="multiline"
                                disabled={isCanceled}
                                inputProps={{ rows: 2 }}
                                labelText={intl.formatMessage({ id: 'campaigns.infoCard.description' })}
                                helperText={170 - pathOr('', ['values', 'campaign', 'description'], formikProps).length}
                                formikProps={formikProps}
                                data={formikProps.values}
                                highlightChange
                            />

                        }
                    </Grid>
                }
            </Grid>
            <Grid container spacing={2}>
                <Grid item sm={12}>
                    <hr style={{ marginTop: "15px" }} />
                    {intl.formatMessage({ id: 'campaigns.infoCard.createdBy' })}
                    <Box className={classes.floatRight}>
                        {createdBy}
                    </Box>
                </Grid>
                <Grid item sm={12}>
                    {intl.formatMessage({ id: 'campaigns.infoCard.createdAt' })}
                    <Box className={classes.floatRight}>
                        {moment(createdAt).format("DD.MM.YYYY HH:mm:ss")}
                    </Box>
                </Grid>
            </Grid>
        </IconCard>
    </>
};

export default injectIntl(InfoCard);
