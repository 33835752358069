import { pathOr } from "rambda";
import RoleEnum from '../common/enums/RoleEnum';

export const phoneRegex = /^\+(?:[0-9] ?){6,14}[0-9]$/;
export const ibanRegex = /[a-zA-Z]{2}[0-9]{2}[a-zA-Z0-9]{4}[0-9]{7}([a-zA-Z0-9]?){0,16}/;
export const passwordRegex = /(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/

export const getLoggedUser = () => window.localStorage.getItem('user');

export const getLoggedUserRole = () => pathOr('', ['role'], JSON.parse(window.localStorage.getItem('user')));

export const isInRoles = (roles) => {
    return roles.includes(getLoggedUserRole());
}

export const getUserToken = () => window.localStorage.getItem('token');

export const processLogin = (data) => {
    const token = pathOr('', ['data', 'login', 'token'], data);
    const user = pathOr('', ['data', 'login', 'user'], data);
    const lang = pathOr('sk', ['data', 'login', 'user', 'preferences', 'language'], data);

    window.localStorage.setItem('token', token);
    window.localStorage.setItem('lang', lang);
    window.localStorage.setItem('user', JSON.stringify(user));
};

export const processLogout = () => {
    window.localStorage.removeItem('token');
    window.localStorage.removeItem('user');
};

export const userIsLogged = () => !!window.localStorage.getItem('user');

export const getRoleEnumBySlug = (slug) => RoleEnum[slug.toUpperCase()];

export const removeTypenameFromObject = (values) => {
    Object.keys(values).map((key) => {
        if (typeof values[key] === 'object' && values[key] !== null && !Array.isArray(values[key])) {
            removeTypenameFromObject(values[key]);
        } else if (typeof values[key] === 'object' && values[key] !== null && Array.isArray(values[key])) {
            //process array of objects
        } else if (key === '__typename') {
            delete values[key];
        }
        return "";
    });

    return values;
};

export const getSelectedLanguage = () => window.localStorage.getItem('lang') || 'sk';

export const setPreferedLanguage = (lang) => window.localStorage.setItem('lang', lang);

export const getErrorMessageByErrorCode = (errorCode, intl) => intl.formatMessage( { id: `apiErrors.${errorCode}` });
    // intl.formatMessage({ id: '' });
