import gql from 'graphql-tag';

const CAMPAIGN_UPDATE = gql`
    mutation campaignUpdate($id: ID!, $input: CampaignUpdateInput!){
        campaignUpdate(id: $id, input: $input){
            id
            bid
            name
        }
    }
`;

export default CAMPAIGN_UPDATE;