import gql from 'graphql-tag';

const PROFILE_QUERY = gql`
query user{
  me{
    user{
      id
      bid
      firstName,
      lastName,
      gender,
      email,
      birth,
      phone,
      role,
    }
  }
}
`;

export default PROFILE_QUERY;