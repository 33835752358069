import gql from 'graphql-tag';

const DEVICE_REMOVE_ZONE = gql`
    mutation deviceRemoveZone ($id: ID!) {
        deviceRemoveZone (id: $id) {
            id
            bid
        }
    }
`;

export default DEVICE_REMOVE_ZONE;