import React from 'react';
import { Grid } from '@material-ui/core';
import { pathOr } from 'rambda';
import { injectIntl } from 'react-intl';
import Box from "@material-ui/core/Box";
import styles from "common/styles/widgets.js";
import { makeStyles } from "@material-ui/core/styles";
import moment from 'moment';

const useStyles = makeStyles((theme) => styles(theme));

const Step3 = ({ intl, criteriaNumber, allData, text }) => {
  const classes = useStyles();
  return (
    <Grid>
      <Grid container spacing={1}>
        <Grid item sm={12}>
          <b>{intl.formatMessage({ id: 'notificationsPage.new.modal.totalUsers' })}</b>
          <Box className={classes.floatRight} style={{ marginLeft: "1.5em" }}>
            <b>{(criteriaNumber === -1) ? "Všetci" : criteriaNumber}</b>
          </Box>
        </Grid>
        <Grid item sm={12}>
          <b>{intl.formatMessage({ id: 'notificationsPage.modal.header' })}:</b>
          <Box className={classes.floatRight} style={{ marginLeft: "1.5em" }}>
            {pathOr('', ['title'], allData)}
          </Box>
        </Grid>
        <Grid item sm={12}>
          <b>{intl.formatMessage({ id: 'notificationsPage.modal.text' })}:</b>
          <Box className={classes.floatRight} style={{ marginLeft: "1.5em" }}>
            {text()}
          </Box>
        </Grid>
        <Grid item sm={12}>
          <b>{intl.formatMessage({ id: 'notificationsPage.modal.screen' })}:</b>
          <Box className={classes.floatRight} style={{ marginLeft: "1.5em" }}>
            {pathOr('', ['screen'], allData)}
          </Box>

        </Grid>
        <Grid item sm={12}>
          <b>{intl.formatMessage({ id: 'notificationsPage.new.modal.sendDate' })}</b>
          <Box className={classes.floatRight} style={{ marginLeft: "1.5em" }}>
            {(pathOr('', ['scheduledTo'], allData))
              ? moment(pathOr('', ['scheduledTo'], allData)).format("DD.MM.YYYY HH:mm")
              : intl.formatMessage({ id: 'notificationsPage.modal.scheduled' })
            }
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default injectIntl(Step3);