import React, { useCallback } from 'react';
import { Grid } from '@material-ui/core';
import CheckBoxField from 'components-lib/CheckBoxField/CheckBoxField';
import { injectIntl } from 'react-intl';
import Divider from "@material-ui/core/Divider";
import Typography from '@material-ui/core/Typography';



const GenderCheckBox = ({ 
    intl, 
    checkedMen,
    checkedWomen,
    checkedNA,
    rangeMaleChecked,
    rangeFemaleChecked,
    rangeUnknownGenderChecked,
    onCheckedAge,
    onCheckBox,
    page,
    status,
    }) => {

    const range = [ 'R13_18', 'R19_34', 'R35_49', 'R50_' ]
   
    return (

        <Grid container rowSpacing={1} columnSpacing={3} xs={12} justify="center">
            
            <Grid  xs={4} direction="column">
            <Grid item >
                <CheckBoxField
                value= "men"
                checked={ checkedMen }
                label= { intl.formatMessage({ id: "campaigns.wizard.step4.gender.men" }) }
                onChecked={ onCheckedAge }
                page={ page }
                status={ status }
                />
            </Grid>

            <Divider style={{ marginLeft:"0rem",  }} />

            <Grid container className='men' direction="column"   >

                { range.map(( item, index ) => (
                    <CheckBoxField
                    value= {`men${item}`}
                    checked={ rangeMaleChecked[item] }
                    label= { intl.formatMessage({ id: `campaigns.wizard.step4.gender.${item}` }) }
                    onChecked={ onCheckBox }
                    page={ page }
                    status={ status }
                    />  
                ) )}
            </Grid>

            </Grid>

            <Grid  className='women' xs={4} direction="column" 
            // style={{paddingLeft:"2.8rem" }} 
            >
            <Grid item >
                <CheckBoxField
                value= "women"
                checked={ checkedWomen }
                label= { intl.formatMessage({ id: "campaigns.wizard.step4.gender.women" }) }
                onChecked={ onCheckedAge }
                page={ page }
                status={ status }
                /> 
            </Grid>

            <Divider style={{ marginLeft:"0rem" }}/>

            { range.map(( item, index ) => (
                    <CheckBoxField
                    value= {`women${item}`}
                    checked={ rangeFemaleChecked[item] }
                    label= { intl.formatMessage({ id: `campaigns.wizard.step4.gender.${item}` }) }
                    onChecked={ onCheckBox }
                    page={ page }
                    status={ status }
                    />  
                ) )}

            </Grid>


            <Grid className='na' xs={4} direction="column" 
            // style={{ width: "33.3%" }} 
            >
            <Grid item >
                <CheckBoxField
                value= "na"
                checked={ checkedNA }
                label= "N/A"
                onChecked={ onCheckedAge }
                page={ page }
                status={ status }
                /> 
            </Grid>

            <Divider style={{ marginLeft:"0rem", marginRight:"0rem" }}  />

            { range.map(( item, index ) => (
                    <CheckBoxField
                    value= {`na${item}`}
                    checked={ rangeUnknownGenderChecked[item] }
                    label= { intl.formatMessage({ id: `campaigns.wizard.step4.gender.${item}` }) }
                    onChecked={ onCheckBox }
                    page={ page }
                    status={ status }
                    />  
                ) )}

            </Grid>
        </Grid>
    );
}

export default injectIntl(GenderCheckBox);