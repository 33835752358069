import gql from "graphql-tag";

const AMBASSADOR_REFERRALS_CHART = gql`
  query ambassadorReferralsChart($ambassadorId: ID!) {
    ambassadorReferralsChart(ambassadorId: $ambassadorId) {
      date
      count
    }
  }
`;

export default AMBASSADOR_REFERRALS_CHART;
