import gql from "graphql-tag";

const DEVICE_DETAIL = gql`
  query device($id: ID!) {
    device(id: $id) {
      id
      bid
      warnings
      errors
      issue {
        id
        date
        code
        description
      }
      status
      connection {
        mobile
        wifi
        wan
        error
      }
      isOnline
      appStatus
      details {
        printedPhotos
        remainingPhotos
        remainingPhotosWarning
        photosPerMonth
        photosPerMonthWarning
      }
      printer {
        printedTotalPhotos
      }
      onlineAt
      statusUpdatedAt
      isEnabled
      createdAt
      updatedAt

      zone {
        id
        bid
        name
        area
        visitors
        rank
        createdAt
        updatedAt
        preferences {
          internalSpotsCount
          commercialSpotsCount
          isOpenedDuringHolidays

          spotsPlaybackInterval
          spotsPlaybackStartOffset
          spotsPlaybackEndOffset

          forbiddenTopics {
            id
            name
          }
          activeTimes {
            weekDay
            from
            to
          }
        }
        store {
          id
          bid
          name
          segment
          address
          postal
          city
          country
          latitude
          longtitude
          fullAddress
          contactFirstName
          contactLastName
          contactPhone
          contactEmail
          createdAt
          updatedAt
          zones {
            area
            visitors
          }
          company {
            id
            bid
            name
            ico
            dic
            icDPH
            registrationCourt
            address
            postal
            city
            country
            latitude
            longtitude
            bankName
            bankIBAN
            bankBIC
            contactFirstName
            contactLastName
            contactPhone
            contactEmail
            commissionRate
            createdAt
            updatedAt
          }
        }
      }
    }
  }
`;

export default DEVICE_DETAIL;
