import React from 'react';
import { Grid } from '@material-ui/core';
import { Formik } from 'formik';
import { pathOr } from 'rambda';
import FormikField from 'components-lib/FormikField/FormikField';
import { validationSchema } from "../validationSchema";
import { injectIntl } from 'react-intl';
import NotificationScreenEnum from "common/enums/NotificationScreenEnum";

const Step2 = ({ intl, history, callbackValid, callback, initialData }) => {

  return (
    <Formik
      enableReinitialize
      initialValues={initialData}
      validationSchema={validationSchema(intl)}
    >
      {props => (
        <>
          {callbackValid(props.isValid)}
          {callback(props.values)}
          <FormikField
            name="title"
            formikProps={props}
            helperText={38 - pathOr('', ['values', "title"], props).length}
            labelText={intl.formatMessage({ id: 'notificationsPage.modal.header' })}
          />
          <FormikField
            type="placeholderText"
            name="text"
            formikProps={props}
            labelText={intl.formatMessage({ id: 'notificationsPage.modal.text' })}
            helperText={170 - pathOr('', ['values', 'text'], props)?.length}
            inputProps={{ rows: 2 }}
          />

          <Grid item sm={12}>
            <br />
            <FormikField
              name="screen"
              type="select"
              formikProps={props}
              labelText={intl.formatMessage({ id: 'notificationsPage.modal.screen' })}
              options={[
                {
                  label: intl.formatMessage({ id: 'screenType.Home' }),
                  value: NotificationScreenEnum.HOME
                },
                {
                  label: intl.formatMessage({ id: 'screenType.Menu' }),
                  value: NotificationScreenEnum.MENU
                },
                {
                  label: intl.formatMessage({ id: 'screenType.Profile' }),
                  value: NotificationScreenEnum.PROFILE
                },
                {
                  label: intl.formatMessage({ id: 'screenType.Credits' }),
                  value: NotificationScreenEnum.CREDIT
                },
                {
                  label: intl.formatMessage({ id: 'screenType.Orders' }),
                  value: NotificationScreenEnum.ORDERS
                },
                {
                  label: intl.formatMessage({ id: 'screenType.Fotomats' }),
                  value: NotificationScreenEnum.FOTOMATS
                },
              ]}
            />
            <br />
          </Grid>
          <Grid item> <br /> </Grid>
          <FormikField
            name="delay"
            labelText={intl.formatMessage({ id: 'notificationsPage.new.notificationCard.delay' })}
            checkedLabelText={intl.formatMessage({ id: 'notificationsPage.new.notificationCard.delay.on' })}
            uncheckedLabelText={intl.formatMessage({ id: 'notificationsPage.new.notificationCard.delay.off' })}
            formikProps={props}
            type="switch"
            highlightChange
          />

          {props?.values?.delay &&
            <FormikField
              name="scheduledTo"
              labelText={intl.formatMessage({ id: 'notificationsPage.new.notificationCard.sendDate' })}
              formikProps={props}
              data={props.values}
              highlightChange
              type="picker2"
            />
          }
        </>
      )}
    </Formik >
  );
}

export default injectIntl(Step2);