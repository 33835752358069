import gql from 'graphql-tag';

const CAMPAIGN_REJECT = gql`
mutation campaignReject(
    $id: ID!
    $description: String
) {
  campaignReject(id: $id, description: $description) {
    id
  }
}
`;

export default CAMPAIGN_REJECT;