import gql from "graphql-tag";

export const OPERATIONS_TABLE_BY_USER = (id) => gql`
  query operationsTableByUser($offset: Int, $limit: Int, $sort: [OperationsTableSortInput!], $filter: [OperationsTableFilterInput]){
    operationsTableByUser(userId: ${id}, offset:$offset, limit: $limit, sort: $sort, filter: $filter){
      totalCount,  
      items{
        id
        bid
        amount
        type
        completedAt
        sourceId
        sourceBid
        sourceType
        targetId
        targetBid
        targetType
        }
    }
  }
`;

export const OPERATIONS_TABLE_BY_USER_TOTAL_COUNT = (id) => gql`
  query operationsTableByUser($offset: Int, $limit: Int, $sort: [OperationsTableSortInput!], $filter: [OperationsTableFilterInput]){
    operationsTableByUser(userId: ${id}, offset:$offset, limit: $limit, sort: $sort, filter: $filter){
      totalCount
    }
  }
`;

