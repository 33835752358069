import { makeStyles } from '@material-ui/core/styles';
import QueryTableRefresh from 'components-lib/QueryTable/QueryTableRefresh';
import Button from "components/CustomButtons/Button.js";
import paths from 'paths';
import React, { useState } from 'react';
import { injectIntl } from 'react-intl';
import { generatePath } from 'react-router-dom';
import styles from "common/styles/widgets.js";
import { pathOr } from "rambda";
import COUPON_SET_DELETE from 'queries/CampaignsQueries/couponSetDelete'
import COUPON_SET_EXPORT from 'queries/CampaignsQueries/couponSetExport'
import { useSnackbar } from 'notistack'
import { useMutation } from "@apollo/react-hooks";
import Modal from 'components-lib/Modal/Modal';
import { Grid } from "@material-ui/core";
import GetAppIcon from '@material-ui/icons/GetApp';
import DeleteIcon from '@material-ui/icons/Delete';
import InfoIcon from '@material-ui/icons/Info';

const useStyles = makeStyles((theme) => styles(theme))

export const CouponTable = (props) => {
    const {
        history,
        data,
        loadData,
        refetch,
        numberOfRows,
        detail,
        intl,
        queryDataPath,
        queryVariables,
        defaultFilter,
        pqrType
    } = props
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();

    const [removeModalOpen, setRemoveModalOpen] = useState(false);
    const [couponSetId, setCouponSetId] = useState(-1)
    const [removeCouponSet] = useMutation(COUPON_SET_DELETE);
    const [exportCouponSet] = useMutation(COUPON_SET_EXPORT);

    const handleRemoveCouponSet = () => {
        removeCouponSet({
            variables: {
                id: couponSetId,
            },
        }).then((response) => {
            enqueueSnackbar(intl.formatMessage({ id: 'campaigns.coupons.removeSuccess' }), { variant: "success" });
            refetch()
            setRemoveModalOpen(false);
        }).catch((err) => {
            console.log('[error]', err)
        });
    }

    const handleExportCouponSet = (id) => {
        exportCouponSet({
            variables: {
                id: id,
            },
        }).then((response) => {
            enqueueSnackbar(intl.formatMessage({ id: 'campaigns.coupons.couponSetExport' }), { variant: "success" });
            window.open(response.data.couponSetExport.url);
        }).catch((err) => {
            console.log('[error]', err)
        });
    }

    const handleCouponSetDetail = (id) => {
        if (!pqrType)
            history.push({
                pathname: `/admin${generatePath(paths.campaigns.QRCouponSetDetail, { couponsetId: id })}`
            })
        else
            history.push({
                pathname: `/admin${generatePath(paths.campaigns.PQRCouponSetDetail, { couponsetId: id })}`
            })
    }

    const renderAssignActions = () => {
        return (
            <>
                <Button
                    size="sm"
                    color="danger"
                    onClick={handleRemoveCouponSet}
                    round
                >
                    {intl.formatMessage({ id: 'campaigns.coupons.removeButton' })}
                </Button>

                <Button
                    size="sm"
                    color="primary"
                    onClick={() => setRemoveModalOpen(false)}
                    round
                >
                    {intl.formatMessage({ id: "invoicesList.assignModal.cancel" })}
                </Button>
            </>
        );
    };

    const getColumns = () => {
        return ([
            {
                Header: intl.formatMessage({ id: "campaigns.table.ID" }),
                accessor: 'bid',
                sortKey: 'bid',
                filterKey: 'bid',
                filterOperator: 'contains'
            },
            {
                Header: intl.formatMessage({ id: "campaigns.table.type" }),
                accessor: (rowData) => (rowData.type === "Qr") ? "QR" : "MQR",
                sortKey: 'type',
                filterKey: 'type',
                filterComponent: 'select',
                filterOperator: 'eq',
                filterOptions: [
                    {
                        value: 'Qr',
                        label: intl.formatMessage({ id: "campaigns.type.qr" }),
                    },
                    {
                        value: 'PublicQr',
                        label: intl.formatMessage({ id: "campaigns.type.pqr" }),
                    },
                ]
            },
            {
                Header: intl.formatMessage({ id: 'campaigns.table.couponCount' }),
                accessor: (rowData) => rowData.count + intl.formatMessage({ id: 'tableTexts.piece' }),
                sortKey: 'count',
                filterKey: 'count',
                filterOperator: 'eq',
                filterDataType: 'number',
            },
            {
                Header: intl.formatMessage({ id: 'campaigns.table.couponNominalValue' }),
                accessor: (rowData) => rowData.couponNominalValue + ' C',
                sortKey: 'couponNominalValue',
                filterKey: 'couponNominalValue',
                filterOperator: 'eq',
                filterDataType: 'number',
            },
            {
                Header: intl.formatMessage({ id: 'campaigns.table.author' }),
                accessor: 'createdBy',
                sortKey: 'createdBy',
                filterKey: 'createdBy',
                filterOperator: 'contains'
            },
            {
                Header: intl.formatMessage({ id: 'campaigns.table.updatedAt' }),
                accessor: (rowData) =>
                    new Date(pathOr(0, 'createdAt', rowData)).toLocaleString('sk'),
                sortKey: 'createdAt',
                filterKey: 'createdAt',
                filterOperator: 'between',
                filterDataType: "dateBetween",
                filterComponent: 'date',
            },
            {
                Header: intl.formatMessage({ id: 'campaigns.table.totalSetValue' }),
                accessor: (rowData) => rowData.totalSetValue + ' C',
                sortKey: 'totalSetValue',
                filterKey: 'totalSetValue',
                filterOperator: 'eq',
                filterDataType: 'number',
            },
            {
                Header: intl.formatMessage({ id: 'campaigns.table.actions' }),
                accessor: (rowData) =>
                    <Grid container spacing={2}>
                        <GetAppIcon
                            className={classes.pointer}
                            onClick={() => handleExportCouponSet(rowData.id)}
                        />
                        <DeleteIcon
                            className={classes.pointer}
                            onClick={() => { setRemoveModalOpen(true); setCouponSetId(rowData.id) }}
                        />
                    </Grid>
            }
        ])
    }

    const getDetailColumns = () => {
        return ([
            {
                Header: intl.formatMessage({ id: "campaigns.table.couponIdDetail" }),
                accessor: 'bid',
                sortKey: 'bid',
                filterKey: 'bid',
                filterOperator: 'contains'
            },
            {
                Header: intl.formatMessage({ id: "campaigns.table.couponStatusDetail" }),
                accessor: (rowData) => (rowData.status === "Active")
                    ? <p style={{ color: "green" }}>{intl.formatMessage({ id: 'campaigns.table.coupon.active' })}</p>
                    : <p style={{ color: "gray" }}>{intl.formatMessage({ id: 'campaigns.table.coupon.depleted' })}</p>,
                sortKey: 'status',
                filterKey: 'status',
                filterComponent: 'select',
                filterOperator: 'eq',
                filterOptions: [
                    {
                        value: 'Active',
                        label: <p style={{ color: "green" }}>{intl.formatMessage({ id: 'campaigns.table.coupon.active' })}</p>
                    },
                    {
                        value: 'Depleted',
                        label: <p style={{ color: "gray" }}>{intl.formatMessage({ id: 'campaigns.table.coupon.depleted' })}</p>
                    }
                ]
            },
            {
                Header: intl.formatMessage({ id: 'campaigns.table.couponNominalValueDetail' }),
                accessor: (rowData) => rowData.couponNominalValue + ' C',
                sortKey: 'couponNominalValue',
                filterKey: 'couponNominalValue',
                filterOperator: 'eq',
                filterDataType: 'number',
            },
            {
                Header: intl.formatMessage({ id: 'campaigns.table.couponCountDetail' }),
                accessor: (rowData) => rowData.count + intl.formatMessage({ id: 'tableTexts.piece' }),
                sortKey: 'count',
                filterKey: 'count',
                filterOperator: 'eq',
                filterDataType: 'number',
            },
            {
                Header: intl.formatMessage({ id: 'campaigns.table.couponUsedDetail' }),
                accessor: (rowData) => rowData.usedCount + intl.formatMessage({ id: 'tableTexts.piece' }),
                sortKey: 'usedCount',
                filterKey: 'usedCount',
                filterOperator: 'eq',
                filterDataType: 'number',
            },
            {
                Header: intl.formatMessage({ id: 'campaigns.table.totalSetValueDetail' }),
                accessor: (rowData) => rowData.totalSetValue + ' C',
                sortKey: 'totalSetValue',
                filterKey: 'totalSetValue',
                filterOperator: 'eq',
                filterDataType: 'number',
            },
            {
                Header: intl.formatMessage({ id: 'campaigns.table.couponUsedCreditsDetail' }),
                accessor: (rowData) => rowData.usedCouponsValue + ' C',
                sortKey: 'usedCouponsValue',
                filterKey: 'usedCouponsValue',
                filterOperator: 'eq',
                filterDataType: 'number',
            },
            {
                Header: intl.formatMessage({ id: 'campaigns.table.actions' }),
                accessor: (rowData) =>
                    <Grid container spacing={2}>
                        <GetAppIcon
                            className={classes.pointer}
                            onClick={() => handleExportCouponSet(rowData.id)}
                        />
                        <InfoIcon
                            className={classes.pointer}
                            onClick={() => handleCouponSetDetail(rowData.id)}
                        />
                    </Grid>
            }
        ])
    }

    const getPQRColumns = () => {
        return ([
            {
                Header: intl.formatMessage({ id: "campaigns.table.couponIdDetail" }),
                accessor: 'bid',
                sortKey: 'bid',
                filterKey: 'bid',
                filterOperator: 'contains'
            },
            {
                Header: intl.formatMessage({ id: "campaigns.table.description" }),
                accessor: 'name',
                sortKey: 'name',
                filterKey: 'name',
                filterOperator: 'contains'
            },
            {
                Header: intl.formatMessage({ id: 'campaigns.table.couponNominalValueDetail' }),
                accessor: (rowData) => rowData.couponNominalValue + ' C',
                sortKey: 'couponNominalValue',
                filterKey: 'couponNominalValue',
                filterOperator: 'eq',
                filterDataType: 'number',
            },
            {
                Header: intl.formatMessage({ id: 'campaigns.table.scans' }),
                accessor: 'usedCount',
                sortKey: 'usedCount',
                filterKey: 'usedCount',
                filterOperator: 'eq',
                filterDataType: 'number',
            },
            {
                Header: intl.formatMessage({ id: 'campaigns.table.actions' }),
                accessor: (rowData) =>
                    <Grid container spacing={2}>
                        <GetAppIcon
                            className={classes.pointer}
                            onClick={() => handleExportCouponSet(rowData.id)}
                        />
                        <InfoIcon
                            className={classes.pointer}
                            onClick={() => handleCouponSetDetail(rowData.id)}
                        />
                    </Grid>
            }
        ])
    }

    return (
        <>
            <QueryTableRefresh
                data={data}
                loadData={loadData}
                queryDataPath={queryDataPath}
                queryVariables={queryVariables}
                numberOfRows={numberOfRows}
                permanentFilter={defaultFilter}
                toggle={(detail) ? false : true}
                columns={(!detail) ? getColumns() : (pqrType) ? getPQRColumns() : getDetailColumns()}
            />
            <Modal
                title={intl.formatMessage({ id: 'campaigns.coupons.removeWarning' })}
                open={removeModalOpen}
                onClose={() => setRemoveModalOpen(false)}
                actions={renderAssignActions()}
            />
        </>
    )
}

export default injectIntl(CouponTable);