import gql from 'graphql-tag';

const TOPIC_UPDATE = gql`
mutation topicUpdate ($id: ID!, $input: TopicUpdateInput!) {
  topicUpdate (id: $id, input: $input) {
      id
      name
  }
}
`;

export default TOPIC_UPDATE;