import React, { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { useQuery } from 'react-apollo';
import { Formik } from 'formik';
import { pathOr } from 'rambda';
import FormikField from 'components-lib/FormikField/FormikField';
import { validationSchemaStep1 } from './validationSchema';
import { injectIntl } from 'react-intl';
import ALL_COMPANIES from 'queries/CompaniesQueries/selectCompanies';
import { ALL_TOPICS } from 'queries/CampaignsQueries/allTopics';
import COMPANY_DETAIL from 'queries/CompaniesQueries/companyDetail';
import CAMPAIGN_DETAILS from 'queries/CampaignsQueries/campaignDetails';

const Step1 = ({ intl, callback, callbackValid, initialData, onGetData, isBack, }) => {
  const { data: companiesData } = useQuery(ALL_COMPANIES);
  const { data: topicsData } = useQuery(ALL_TOPICS);
  const [companyId, setCompanyId] = useState(null);
  const [campaignId, setCampaignId] = useState(null);
  const [campaignData, setCampaignData] = useState(null);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get('id');
    setCampaignId(id);
  }, []);
 
  useEffect(() => {
    onGetData( campaignData )
  }, [ campaignData ]);

  const getCompaniesOptions = () => {
    const companiesOptions = [];
    pathOr([], ['companies', 'items'], companiesData).map(company => {
      return companiesOptions.push({
        value: pathOr('', 'id', company),
        label: `${pathOr('', 'name', company)}`,
      });
    });
    return companiesOptions;
  };

  const getTopicOptions = () => {
    const topicOptions = [];
    pathOr([], ['topics', 'items'], topicsData).map(topic => {
      return topicOptions.push({
        label: topic.name,
        value: topic.id,
      });
    });
    return topicOptions;
  };

  const { data: companyData } = useQuery(COMPANY_DETAIL, { variables: { id: companyId }, skip: !companyId });
  const { data: campData } = useQuery(CAMPAIGN_DETAILS, { variables: { id: campaignId }, skip: !campaignId });

  useEffect(() => {
    if (campData) {
      setCampaignData(campData.campaign);      
    }
  }, [campData]);
  
  
  let balance = pathOr('', ['company', 'account', 'balance'], companyData);

  const initialValues = campaignData
    ? {    
      ...initialData,        
      name: campaignData.name,
      companyId: campaignData.company.id,
      description: campaignData.description,
      topicIds: campaignData.topics.map(topic => topic.id),
  }
  : initialData;

  return (
    <>
      <Formik enableReinitialize initialValues={ isBack ? initialData : initialValues } validationSchema={validationSchemaStep1(intl)}>
        {formikProps => (
    // console.log( formikProps.values.topicIds )
          <Grid container>
            {callbackValid(
              formikProps && formikProps.isValid &&
                formikProps.values.name &&
                formikProps.values.companyId &&
                formikProps.values.validFrom &&
                formikProps.values.validTo &&
                balance >= 1 &&
                formikProps.values?.topicIds?.length > 0 &&
                formikProps.values?.topicIds?.[0] !== 'AllNotifications'
            )}
            {callback(formikProps.values)}
            {setCompanyId(formikProps.values?.companyId)}            
            <Grid item sm={12}>
              <FormikField
                name="name"
                labelText={intl.formatMessage({ id: 'campaigns.infoCard.name' })}
                formikProps={formikProps}
                data={formikProps.values}
                highlightChange
              />
            </Grid>
            <Grid item sm={12}>
              <FormikField
                type="select"
                name="companyId"
                labelText={intl.formatMessage({ id: 'campaigns.infoCard.company' })}
                options={getCompaniesOptions()}
                formikProps={formikProps}
                data={formikProps.values}
                disabled={formikProps.initialValues.companyId !== undefined}
                highlightChange
              />
            </Grid>
            {formikProps.values.companyId && balance < 11 && (
              <span style={{ fontSize: '0.8em', color: 'red' }}>{intl.formatMessage({ id: 'campaigns.infoCard.withoutCredits' })}</span>
            )}
            <Grid item sm={12}>
              <FormikField
                name="topicIds"
                type="multiSelect"
                labelText={intl.formatMessage({ id: 'campaigns.infoCard.topics' })}
                options={getTopicOptions()}
                formikProps={formikProps}
                data={formikProps.values}
                highlightChange
              />
            </Grid>
            <Grid container spacing={3}>
              <Grid item sm={6}>
                <br />
                <FormikField
                  name="validFrom"
                  labelText={intl.formatMessage({ id: 'campaigns.infoCard.startDate' })}
                  formikProps={formikProps}
                  data={formikProps.values}
                  highlightChange
                  // initialValues={new Date(initialValues.validFrom)}
                  type="picker2"
                />
              </Grid>
              <Grid item sm={6}>
                <br />
                <FormikField
                  name="validTo"
                  labelText={intl.formatMessage({ id: 'campaigns.infoCard.endDate' })}
                  formikProps={formikProps}
                  data={formikProps.values}
                  highlightChange
                  // initialValues={new Date(initialValues.validTo)}
                  type="picker2"
                />
              </Grid>
            </Grid>
            <Grid item sm={12}>
              <br />
              <FormikField
                name="description"
                type="multiline"
                labelText={intl.formatMessage({ id: 'invoicesList.assignModal.description' })}
                helperText={170 - pathOr('', ['values', 'description'], formikProps).length}
                formikProps={formikProps}
                inputProps={{ rows: 2 }}
                highlightChange
                
              />
            </Grid>
          </Grid>
        )}
      </Formik>
    </>
  );
};

export default injectIntl(Step1);


