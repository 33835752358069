import * as Yup from 'yup';

export const getValidationSchemaNewQRCouponSet = (intl) =>
    Yup.object().shape({
        nominalValue: Yup.number()
            .min(1, (intl.formatMessage({ id: 'validation.number.positive' })))
            .typeError(intl.formatMessage({ id: 'validation.number' }))
            .required(intl.formatMessage({ id: 'validation.required' })).nullable(),
        count: Yup.number()
            .min(1, (intl.formatMessage({ id: 'validation.number.positive' })))
            .typeError(intl.formatMessage({ id: 'validation.number' }))
            .required(intl.formatMessage({ id: 'validation.required' })).nullable(),
    });

export const getValidationSchemaNewPQRCoupon = (intl) =>
    Yup.object().shape({
        nominalValue: Yup.number()
            .min(1, (intl.formatMessage({ id: 'validation.number.positive' })))
            .typeError(intl.formatMessage({ id: 'validation.number' }))
            .required(intl.formatMessage({ id: 'validation.required' })).nullable(),
    });

export const getValidationSchemaNew = (intl, parsed) =>
    Yup.object().shape({
        campaign: Yup.object().shape({
            name: Yup.string().required(intl.formatMessage({ id: 'validation.required' })).nullable(),
            companyId: Yup.string().required(intl.formatMessage({ id: 'validation.required' })).nullable(),
            validFrom: Yup.date()
                .required(intl.formatMessage({ id: 'validation.required' }))
                .typeError(intl.formatMessage({ id: 'validation.date' }))
                .min(new Date(Date.now()), intl.formatMessage({ id: 'validation.pastDate' })).nullable(),
            validTo: Yup.date()
                .required(intl.formatMessage({ id: 'validation.required' }))
                .typeError(intl.formatMessage({ id: 'validation.date' }))
                .min(Yup.ref('validFrom'), intl.formatMessage({ id: 'validation.graterDate' })).nullable(),
            budget: Yup.number()
                .min(11, (intl.formatMessage({ id: 'validation.number.min' }, [11])))
                .max(parsed, (intl.formatMessage({ id: 'validation.number.max' }, [parsed])))
                .typeError(intl.formatMessage({ id: 'validation.number' }))
                .required(intl.formatMessage({ id: 'validation.required' })).nullable(),
            description: Yup.string()
                .max(170, intl.formatMessage({ id: 'validation.string.max' }, [170]))
                .nullable()
        })
    })

export const validationCreditUp = (intl, maxCredits) =>
    Yup.object().shape({
        credit: Yup.number()
            .min(1, (intl.formatMessage({ id: 'validation.number.positive' })))
            .max(maxCredits, (intl.formatMessage({ id: 'validation.number.max' }, [maxCredits])))
            .typeError(intl.formatMessage({ id: 'validation.number' }))
            .required(intl.formatMessage({ id: 'validation.required' })).nullable(),
    });

export const validationQRCouponSet = (intl) =>
    Yup.object().shape({
        couponSet: Yup.string().required(intl.formatMessage({ id: 'validation.required' })).nullable(),
    });

export const validationPQRCouponSet = (intl) =>
    Yup.object().shape({
        description: Yup.string()
            .required(intl.formatMessage({ id: 'validation.required' })).nullable(),
        couponSet: Yup.string().required(intl.formatMessage({ id: 'validation.required' })).nullable(),
    });

export const getValidationSchemaDetail = (intl, budget) =>
    Yup.object().shape({
        campaign: Yup.object().shape({
            validTo: Yup.string().required(intl.formatMessage({ id: 'validation.required' })).nullable(),
            maxBid: Yup.number()
                .max(budget, (intl.formatMessage({ id: 'validation.number.max' }, [budget])))
                .min(11, (intl.formatMessage({ id: 'validation.number.min' }, [11])))
                .typeError(intl.formatMessage({ id: 'validation.number' }))
                .required(intl.formatMessage({ id: 'validation.required' })).nullable(),
            description: Yup.string()
                .max(100, intl.formatMessage({ id: 'validation.string.max' }, [100]))
                .nullable(),
            minRepeatInterval: Yup.number()
                .min(0, intl.formatMessage({ id: 'validation.number.positive' }))
                .typeError(intl.formatMessage({ id: 'validation.number' }))
                .required(intl.formatMessage({ id: 'validation.required' })).nullable(),
        })
    })