import React from "react";
import { injectIntl } from "react-intl";
import { withRouter } from "react-router-dom";
import PageHeader from "components-lib/PageHeader/PageHeader";
import { Grid } from "@material-ui/core";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { makeStyles } from "@material-ui/core/styles";
import styles from "common/styles/widgets.js";
import { useSnackbar } from "notistack";
import IconCard from "components-lib/IconCard/IconCard";
import ALL_COUPONS from "queries/CampaignsQueries/allCoupons"
import Box from "@material-ui/core/Box";
import InfoIcon from '@material-ui/icons/Info';
import DetailUseCouponSetCard from "./CampaignCards/DetailUseCouponSetCard";
import CouponsTable from "./CampaignCards/CouponsTable";
import QRCampaignsIcon from 'components-lib/Icons/QRCampaignsIcon/QRCampaignsIcon';
import Loading from "components-lib/Loading/Loading";
import { pathOr } from "rambda";
import COUPON_SET_EXPORT from 'queries/CampaignsQueries/couponSetExport'
import COUPON_SET_DETAIL from "queries/CampaignsQueries/couponSetDetail";
import CampaignTypeEnum from "common/enums/CampaignType";

const useStyles = makeStyles((theme) => styles(theme));

const CouponSetDetailPage = (props) => {
    const { intl, history } = props;
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const couponSetId = props.match.params.couponsetId

    let campaignType = ""
    let url = window.location.pathname;

    if (url.includes("coupon-campaigns/pqrset")) campaignType = CampaignTypeEnum.PQR
    else campaignType = CampaignTypeEnum.QR

    const { data: couponSetData, loading: couponSetLoading } = useQuery(COUPON_SET_DETAIL, { variables: { id: couponSetId } });
    const [exportCouponSet] = useMutation(COUPON_SET_EXPORT);

    const handleExportCouponSet = () => {
        exportCouponSet({
            variables: {
                id: couponSetId,
            },
        }).then((response) => {
            enqueueSnackbar(intl.formatMessage({ id: 'campaigns.coupons.couponSetExport' }), { variant: "success" });
            window.open(response.data.couponSetExport.url);
        }).catch((err) => {
            console.log('[error]', err)
        });
    }

    const getActions = () => {
        return (
            [
                {
                    title: intl.formatMessage({ id: 'campaigns.couponSetDetail.exportButton' }),
                    onClick: () => handleExportCouponSet()
                }
            ]
        )
    }

    const getSubtitle = () => {
        return pathOr(0, ['couponSet', 'bid'], couponSetData)
    }

    if (couponSetLoading) return <Loading />
    return (
        <>
            <PageHeader
                title={(campaignType === CampaignTypeEnum.QR)
                    ? intl.formatMessage({ id: 'campaigns.couponSetDetail.title' })
                    : "MQR kupón"
                }
                subTitle={getSubtitle()}
                actions={getActions()}
                handleBackAction={(e) => history.goBack()}
            />
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={6} lg={4}>
                    <IconCard
                        title={intl.formatMessage({ id: 'campaigns.infoCard.title' })}
                        icon={<InfoIcon />}
                    >
                        {(campaignType === CampaignTypeEnum.QR) ?
                            <Grid container spacing={2}>
                                <Grid item sm={12}>
                                    {intl.formatMessage({ id: 'campaigns.couponSetInfo.couponsNumber' })}
                                    <Box className={classes.floatRight}>
                                        {pathOr(0, ['couponSet', 'couponCount'], couponSetData)}{intl.formatMessage({ id: 'tableTexts.piece' })}
                                    </Box>
                                </Grid>
                                <Grid item sm={12}>
                                    {intl.formatMessage({ id: 'campaigns.couponSetInfo.couponsNominalValue' })}
                                    <Box className={classes.floatRight}>
                                        {pathOr(0, ['couponSet', 'couponNominalValue'], couponSetData)} C
                                </Box>
                                </Grid>

                                <Grid item sm={12}>
                                    {intl.formatMessage({ id: 'campaigns.couponSetInfo.couponsTotalValue' })}
                                    <Box className={classes.floatRight}>
                                        {pathOr(0, ['couponSet', 'totalSetValue'], couponSetData)} C
                                </Box>
                                </Grid>
                            </Grid>
                            :
                            <Grid container spacing={2}>
                                <Grid item sm={12}>
                                {intl.formatMessage({ id: 'campaigns.couponSetInfo.pqrName' })} 
                                    <Box className={classes.floatRight}>
                                        {pathOr(0, ['couponSet', 'name'], couponSetData)}
                                    </Box>
                                </Grid>
                                <Grid item sm={12}>
                                {intl.formatMessage({ id: 'campaigns.couponSetInfo.pqrNominalValue' })} 
                                    <Box className={classes.floatRight}>
                                        {pathOr(0, ['couponSet', 'couponNominalValue'], couponSetData)} C
                                 </Box>
                                </Grid>
                            </Grid>
                        }
                    </IconCard>

                    <DetailUseCouponSetCard
                        allCoupons={pathOr(0, ['couponSet', 'couponCount'], couponSetData)}
                        usedCoupons={pathOr(0, ['couponSet', 'usedCouponsCount'], couponSetData)}
                        allCredits={pathOr(0, ['couponSet', 'totalSetValue'], couponSetData)}
                        usedCredits={pathOr(0, ['couponSet', 'usedCouponsValue'], couponSetData)}
                        pqrType={(campaignType === CampaignTypeEnum.PQR) ? true : false} //TODO
                    />

                </Grid>
                <Grid item md={6} lg={8} sm={12}>
                    <IconCard
                        icon={<QRCampaignsIcon raw={true} />}
                        title={(campaignType === CampaignTypeEnum.QR)
                            ? intl.formatMessage({ id: 'campaigns.couponSetInfo.table' })
                            : "MQR kupón"
                        }
                    >
                        <CouponsTable
                            query={ALL_COUPONS}
                            history={history}
                            defaultFilter={{ couponSetId: { eq: Number(couponSetId) } }}
                            queryDataPath={['couponsTable', 'items']}
                            numberOfRows={10}
                        />
                    </IconCard>
                </Grid>
            </Grid>
        </>
    )
}

export default injectIntl(withRouter(CouponSetDetailPage))