export const getAPIUrl = () => {
    if (process.env.NODE_ENV === 'development')
        return process.env.REACT_APP_API_URL;
    else
        return window.env.API_URL
}


export const defaultFFWatermarkUrl = `${(process.env.NODE_ENV === 'development') ? process.env.REACT_APP_API_HOST : window.env.API_HOST}/static/img/fotomat-watermark.png`

export const apiHost = `${(process.env.NODE_ENV === 'development') ? process.env.REACT_APP_API_HOST : window.env.API_HOST}`
