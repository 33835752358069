import gql from 'graphql-tag';

const ALL_COUPON_CAMPAIGNS = gql`
query couponCampaignsTable (
  $offset: Int
  $limit: Int
  $sort: [CouponCampaignsSortInput!]
  $filter: [CouponCampaignsFilterInput]
) {
  couponCampaignsTable (offset: $offset, limit: $limit, sort: $sort, filter: $filter) {
    totalCount,
    items {
      id
      bid
      type
      name
      owner
      status
      validFrom
      validTo
      couponsTotalCount
      couponsUsedCount
    }
  }
}
`;


export default ALL_COUPON_CAMPAIGNS;