import gql from "graphql-tag";

const DEVICES_QUERY_DISABLED = gql`
  query devicesTable(
    $offset: Int
    $limit: Int
    $sort: [DevicesTableSortInput!]
    $filter: [DevicesTableFilterInput!]
  ) {
    devicesTable(offset: $offset, limit: $limit, sort: $sort, filter: $filter) {
      totalCount
      items {
        id
        bid
        store
        isEnabled
        address
        printedPhotos
        remainingPhotos
        company
        heartbeat
        issueCode
        issueDescription
        status
      }
    }
  }
`;

export default DEVICES_QUERY_DISABLED;
