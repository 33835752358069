import * as Yup from 'yup';
import { ibanRegex, phoneRegex, passwordRegex } from "../../helpers/helpers";

export const getValidationSchema = (intl) =>
    Yup.object().shape({
        firstName: Yup.string().required(intl.formatMessage({ id: 'validation.required' })).nullable(),
        lastName: Yup.string().required(intl.formatMessage({ id: 'validation.required' })).nullable(),
        email: Yup.string()
            .email(intl.formatMessage({ id: 'validation.email' }))
            .required(intl.formatMessage({ id: 'validation.required' })).nullable(),
        phone: Yup.string()
            .matches(phoneRegex, intl.formatMessage({ id: 'validation.phoneNumber' }))
            .nullable(),
        birth: Yup.date().typeError(),
        company: Yup.object().shape({
            name: Yup.string().required(intl.formatMessage({ id: 'validation.required' })).nullable(),
            ico: Yup.string().nullable(),
            dic: Yup.string().nullable(),
            icDPH: Yup.string().nullable(),
            address: Yup.string().nullable(),
            postal: Yup.number().typeError(intl.formatMessage({ id: 'validation.number' })).nullable(),
            city: Yup.string().nullable(),
            registrationCourt: Yup.string().nullable(),
            country: Yup.string().nullable(),
            bank: Yup.object().shape({
                name: Yup.string().nullable(),
                iban: Yup.string()
                    .matches(ibanRegex, intl.formatMessage({ id: 'validation.iban' }))
                    .nullable(),
                bic: Yup.string().nullable()
            })
        })
    });

export const getMaintainerValidationSchema = (intl) =>
    Yup.object().shape({
        firstName: Yup.string().required(intl.formatMessage({ id: 'validation.required' })).nullable(),
        lastName: Yup.string().required(intl.formatMessage({ id: 'validation.required' })).nullable(),
        email: Yup.string()
            .email(intl.formatMessage({ id: 'validation.email' }))
            .required(intl.formatMessage({ id: 'validation.required' }))
            .nullable(),
        phone: Yup.string()
            .matches(phoneRegex, intl.formatMessage({ id: 'validation.phoneNumber' }))
            .nullable()
    });

export const getValidationSchemaDetail = (intl) =>
    Yup.object().shape({
        firstName: Yup.string().required(intl.formatMessage({ id: 'validation.required' })).nullable(),
        lastName: Yup.string().required(intl.formatMessage({ id: 'validation.required' })).nullable(),
        email: Yup.string()
            .email(intl.formatMessage({ id: 'validation.email' }))
            .required(intl.formatMessage({ id: 'validation.required' }))
            .nullable(),
        phone: Yup.string()
            .matches(phoneRegex, intl.formatMessage({ id: 'validation.phoneNumber' }))
            .nullable()
    });

export const getPasswordValidation = (intl) =>
    Yup.object().shape({
        password: Yup.string()
            .required(intl.formatMessage({ id: 'validation.required' }))
            .matches(passwordRegex, intl.formatMessage({ id: 'validation.password' }))
            .nullable()
    });

export const getAmbassadorExportValidation= (intl) =>
    Yup.object().shape({
        emails: Yup.string().required(intl.formatMessage({ id: 'validation.required' })).nullable(),
        from: Yup.date()
            .required(intl.formatMessage({ id: 'validation.required' }))
            .typeError(intl.formatMessage({ id: 'validation.date' }))
            .max(new Date(Date.now()), intl.formatMessage({ id: 'validation.maxDate' })).nullable(),
        to: Yup.date()
            .required(intl.formatMessage({ id: 'validation.required' }))
            .typeError(intl.formatMessage({ id: 'validation.date' }))
            .max(new Date(Date.now()), intl.formatMessage({ id: 'validation.maxDate' }))
            .min(Yup.ref('from'), intl.formatMessage({ id: 'validation.graterDate' })).nullable(),
    });

export default getValidationSchema;