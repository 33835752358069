import { Grid } from "@material-ui/core";
import StoreIcon from "@material-ui/icons/Store";
import FormikField from "components-lib/FormikField/FormikField";
import IconCard from "components-lib/IconCard/IconCard";
import { injectIntl } from "react-intl";
import { withRouter } from "react-router-dom";
import RoleEnum from "common/enums/RoleEnum";
import { isInRoles } from "helpers/helpers";
import styles from "common/styles/widgets.js";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import PLAYLISTS from "queries/StoresQueries/playlists";
import React, { useEffect, useState } from "react";
import { useQuery } from "@apollo/react-hooks";
import { pathOr } from "rambda";
import ALL_SEGMENTS from "queries/StoresQueries/allSegments";
import Button from "components/CustomButtons/Button";
import { generatePath } from "react-router-dom";
import paths from "paths";
import AutocompleteSelect from "components-lib/AutocompleteSelect/AutocompleteSelect";
import ALL_VILLAGES from "queries/StoresQueries/allVillages";
import Input from "@material-ui/core/Input";
import ALL_SETTINGS from 'queries/SettingsQueries/allSettings';


const useStyles = makeStyles((theme) => styles(theme));

const StoreDataCard = (props) => {
  const { intl, history, formikProps, city, callbackCity } = props;
  const [pricingLevels, setPricingLevels] = useState([]);


  const { data: villagesData } = useQuery(ALL_VILLAGES)
  const { data: settingsData } = useQuery(ALL_SETTINGS);


  const classes = useStyles();

  useEffect(() => {
    let pricingLevelsSelectItems = pathOr([], "settings.pricing.levels", settingsData).map(
      (pricingLevel) => {
        return {
          value: pathOr("", "number", pricingLevel),
          label: `L ${pathOr("", "number", pricingLevel)} - ${(pathOr("", "number", pricingLevel) === 1) ? "Základná " : (pathOr("", "number", pricingLevel) === 2) ? "Stredná " : "Vysoká"} cenová hladina (${pathOr("", "surcharge", pricingLevel)}%)`
        };
      }
    );

    setPricingLevels(pricingLevelsSelectItems);
  }, [settingsData, intl]);


  const getVillagesOptions = () => {
    const villageOptions = [];
    pathOr([], ['villages', 'items'], villagesData).map(village => {
      villageOptions.push({
        value: village.id,
        label: village.name

      })
      return "";
    })
    return villageOptions
  }

  const [playlists, setPlaylists] = useState([]);
  const { data: playlistsData } = useQuery(PLAYLISTS, {
    skip: !isInRoles([RoleEnum.ADMIN, RoleEnum.MAINTAINER]),
  });
  const { data: segmentData } = useQuery(ALL_SEGMENTS);
  const [availableSegments, setAvailableSegments] = useState([]);

  useEffect(() => {
    let playlistsSelectItems = pathOr([], "playlists.items", playlistsData).map(
      (playlist) => {
        return {
          value: pathOr("", "name", playlist),
          label: pathOr("", "name", playlist),
        };
      }
    );
    playlistsSelectItems.unshift({
      label: intl.formatMessage({
        id: "storeDetail.storeCard.playlist.without",
      }),
      value: "without",
    });
    setPlaylists(playlistsSelectItems);
  }, [playlistsData, intl]);

  useEffect(() => {
    const segments = pathOr([], ["segments", "items"], segmentData).map(
      (segment) => {
        return {
          label: pathOr("", "name", segment),
          value: pathOr("", "id", segment),
        };
      }
    );
    setAvailableSegments(segments);
  }, [segmentData]);

  const renderContent = () => {
    if (!isInRoles([RoleEnum.ADMIN, RoleEnum.MAINTAINER])) {
      return (
        <Grid container spacing={2}>
          <Grid item sm={12}>
            {intl.formatMessage({ id: "storeDetail.storeCard.name" })}
            <Box className={classes.floatRight}>{formikProps.values.name}</Box>
          </Grid>
          <Grid item sm={12}>
            {intl.formatMessage({ id: "storeDetail.storeCard.category" })}
            <Box className={classes.floatRight}>
              {formikProps.values.zones?.[0].preferences.segment.id
                ? formikProps.values.zones[0].preferences.segment.name
                : "-"}
            </Box>
          </Grid>
          <Grid item sm={12}>
            {intl.formatMessage({ id: "storeDetail.storeCard.playlist" })}
            <Box className={classes.floatRight}>
              {formikProps?.values?.zones?.[0]?.preferences?.playlist
                ? formikProps?.values?.zones?.[0]?.preferences?.playlist
                : intl.formatMessage({
                  id: "storeDetail.storeCard.playlist.without",
                })}
            </Box>
          </Grid>
          <Grid item sm={12}>
            {intl.formatMessage({ id: "storeDetail.storeAddressCard.address" })}
            <Box className={classes.floatRight}>
              {(formikProps.values.address) ? formikProps.values.address : "-"}
            </Box>
          </Grid>
          <Grid item sm={12}>
            {intl.formatMessage({ id: "companyDetail.addressCard.postal" })}
            <Box className={classes.floatRight}>
              {(formikProps.values.postal) ? formikProps.values.postal : "-"}
            </Box>
          </Grid>
          <Grid item sm={12}>
            {intl.formatMessage({ id: "storeDetail.storeAddressCard.city" })}
            <Box className={classes.floatRight}>
              {(formikProps.values.city) ? formikProps.values.city : "-"}
            </Box>
          </Grid>
          <Grid item sm={12}>
            {intl.formatMessage({ id: "storeDetail.storeAddressCard.country" })}
            <Box className={classes.floatRight}>
              {(formikProps.values.country) ? formikProps.values.country : "-"}
            </Box>
          </Grid>
        </Grid>
      );
    }
    return (
      <Grid container spacing={1}>
        <Grid item sm={12}>
          {formikProps?.values?.company?.name}
          <Box className={classes.floatRight}>
            <Button
              className={classes.floatRight}
              color="info"
              style={{ margin: "-5px 0px", }}
              size="sm"
              round
              onClick={() => { history.push(`/admin${generatePath(paths.companies.detail, { companyId: formikProps?.values?.company?.id })}`) }}
            >
              Detail spoločnosti
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <FormikField
            name="name"
            labelText={intl.formatMessage({ id: "storeDetail.storeCard.name" })}
            formikProps={formikProps}
            highlightChange
          />
        </Grid>
        <Grid item xs={6}>
          <FormikField
            name={"zones.0.preferences.segment.id"}
            labelText={intl.formatMessage({
              id: "storeDetail.storeCard.category",
            })}
            formikProps={formikProps}
            type="select"
            options={availableSegments}
          />
        </Grid>
        <Grid item xs={6}>
          <FormikField
            name="zones.0.preferences.playlist"
            labelText={intl.formatMessage({
              id: "storeDetail.storeCard.playlist",
            })}
            formikProps={formikProps}
            value={formikProps}
            type="select"
            options={playlists}
          />
        </Grid>
        <Grid item xs={12}>

          <FormikField
            name="zones.0.pricingLevel.number"
            labelText={"Cenová hladina"}
            formikProps={formikProps}
            value={formikProps}
            type="select"
            options={pricingLevels}
          />
        </Grid>

        <Grid item xs={12} sm={8}>
          <FormikField
            name="address"
            labelText={intl.formatMessage({ id: "storeDetail.storeAddressCard.address" })}
            formikProps={formikProps}
            highlightChange
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormikField
            name="postal"
            labelText={intl.formatMessage({ id: "storeDetail.storeAddressCard.postal" })}
            formikProps={formikProps}
            highlightChange
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormikField
            name="city"
            type="autocomplete"
            labelText={intl.formatMessage({ id: "storeDetail.storeAddressCard.city" })}
            formikProps={formikProps}
            options={getVillagesOptions()}
            highlightChange
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormikField
            name="country"
            labelText={intl.formatMessage({ id: "storeDetail.storeAddressCard.country" })}
            formikProps={formikProps}
            highlightChange
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormikField
            name="latitude"
            labelText={intl.formatMessage({ id: "storeDetail.storeAddressCard.latitude" })}
            formikProps={formikProps}
            highlightChange
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormikField
            name="longtitude"
            labelText={intl.formatMessage({ id: "storeDetail.storeAddressCard.longtitude" })}
            formikProps={formikProps}
            highlightChange
          />
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      <IconCard
        title={intl.formatMessage({ id: "storeDetail.title" })}
        icon={<StoreIcon />}
      >
        {renderContent()}
      </IconCard>
    </>
  );
};

export default withRouter(injectIntl(StoreDataCard));
