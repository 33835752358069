import * as Yup from "yup";

const getValidationSchema = (intl) =>
    Yup.object().shape({
        credit: Yup.object().shape({
            value: Yup.number()
                .required(intl.formatMessage({ id: "validation.required" }))
                .typeError(intl.formatMessage({ id: "validation.number" }))
                .min(0, intl.formatMessage({ id: "validation.number.min" }, [0]))
                .nullable(),
            expiration: Yup.number()
                .required(intl.formatMessage({ id: "validation.required" }))
                .integer(intl.formatMessage({ id: "validation.number.integer" }))
                .typeError(intl.formatMessage({ id: "validation.number" }))
                .min(0, intl.formatMessage({ id: "validation.number.min" }, [0]))
                .nullable(),
            registrationAward: Yup.number()
                .required(intl.formatMessage({ id: "validation.required" }))
                .integer(intl.formatMessage({ id: "validation.number.integer" }))
                .typeError(intl.formatMessage({ id: "validation.number" }))
                .min(0, intl.formatMessage({ id: "validation.number.min" }, [0]))
                .nullable(),
        }),
        commission: Yup.object().shape({
            creditValue: Yup.number()
                .required(intl.formatMessage({ id: "validation.required" }))
                .typeError(intl.formatMessage({ id: "validation.number" }))
                .min(0, intl.formatMessage({ id: "validation.number.min" }, [0]))
                .nullable(),
            lowerRate: Yup.number()
                .required(intl.formatMessage({ id: "validation.required" }))
                .integer(intl.formatMessage({ id: "validation.number.integer" }))
                .typeError(intl.formatMessage({ id: "validation.number" }))
                .min(0, intl.formatMessage({ id: "validation.number.min" }, [0]))
                .nullable(),
            higherRate: Yup.number()
                .required(intl.formatMessage({ id: "validation.required" }))
                .integer(intl.formatMessage({ id: "validation.number.integer" }))
                .typeError(intl.formatMessage({ id: "validation.number" }))
                .min(0, intl.formatMessage({ id: "validation.number.min" }, [0]))
                .nullable(),
            threshold: Yup.number()
                .required(intl.formatMessage({ id: "validation.required" }))
                .integer(intl.formatMessage({ id: "validation.number.integer" }))
                .typeError(intl.formatMessage({ id: "validation.number" }))
                .min(0, intl.formatMessage({ id: "validation.number.min" }, [0]))
                .nullable(),
        }),
        order: Yup.object().shape({
            expiration: Yup.number()
                .required(intl.formatMessage({ id: "validation.required" }))
                .integer(intl.formatMessage({ id: "validation.number.integer" }))
                .typeError(intl.formatMessage({ id: "validation.number" }))
                .min(0, intl.formatMessage({ id: "validation.number.min" }, [0]))
                .nullable(),
            freeFotoExpiration: Yup.number()
                .required(intl.formatMessage({ id: "validation.required" }))
                .integer(intl.formatMessage({ id: "validation.number.integer" }))
                .typeError(intl.formatMessage({ id: "validation.number" }))
                .min(0, intl.formatMessage({ id: "validation.number.min" }, [0]))
                .nullable(),
        }),
        ambassador: Yup.object().shape({
            customerReward: Yup.number()
                .required(intl.formatMessage({ id: "validation.required" }))
                .typeError(intl.formatMessage({ id: "validation.number" }))
                .min(0, intl.formatMessage({ id: "validation.number.min" }, [0]))
                .nullable(),
            referralCreditReward: Yup.number()
                .required(intl.formatMessage({ id: "validation.required" }))
                .typeError(intl.formatMessage({ id: "validation.number" }))
                .min(0, intl.formatMessage({ id: "validation.number.min" }, [0]))
                .nullable(),
            referralMoneyReward: Yup.number()
                .required(intl.formatMessage({ id: "validation.required" }))
                .typeError(intl.formatMessage({ id: "validation.number" }))
                .min(0, intl.formatMessage({ id: "validation.number.min" }, [0]))
                .nullable(),
            claimMinutesLimit: Yup.number()
                .required(intl.formatMessage({ id: "validation.required" }))
                .typeError(intl.formatMessage({ id: "validation.number" }))
                .min(0, intl.formatMessage({ id: "validation.number.min" }, [0]))
                .nullable(),
        }),
        signage: Yup.object().shape({
            idleTimeout: Yup.number()
                .required(intl.formatMessage({ id: "validation.required" }))
                .integer(intl.formatMessage({ id: "validation.number.integer" }))
                .typeError(intl.formatMessage({ id: "validation.number" }))
                .min(0, intl.formatMessage({ id: "validation.number.min" }, [0]))
                .nullable(),
            bannerDuration: Yup.number()
                .required(intl.formatMessage({ id: "validation.required" }))
                .integer(intl.formatMessage({ id: "validation.number.integer" }))
                .typeError(intl.formatMessage({ id: "validation.number" }))
                .min(0, intl.formatMessage({ id: "validation.number.min" }, [0]))
                .nullable(),
        }),
        notifications: Yup.object().shape({
            printsRemainingHSThreshold: Yup.number()
                .required(intl.formatMessage({ id: "validation.required" }))
                .integer(intl.formatMessage({ id: "validation.number.integer" }))
                .typeError(intl.formatMessage({ id: "validation.number" }))
                .min(0, intl.formatMessage({ id: "validation.number.min" }, [0]))
                .nullable(),
        }),
        supportMail: Yup.string()
            .email(intl.formatMessage({ id: "validation.email" }))
            .nullable(),

        cashback: Yup
            .array()
            .of(
                Yup.object().shape({
                    amount: Yup.number()
                        .required(intl.formatMessage({ id: 'validation.required' }))
                        .integer(intl.formatMessage({ id: 'validation.number.integer' }))
                        .typeError(intl.formatMessage({ id: 'validation.number' }))
                        .min(0, intl.formatMessage({ id: 'validation.number.min' }, [0]))
                        .nullable(),
                    quantity: Yup.number()
                        .required(intl.formatMessage({ id: 'validation.required' }))
                        .integer(intl.formatMessage({ id: 'validation.number.integer' }))
                        .typeError(intl.formatMessage({ id: 'validation.number' }))
                        .min(0, intl.formatMessage({ id: 'validation.number.min' }, [0]))
                        .nullable(),
                }),
            ),
        pricing: Yup.object().shape({
            photos: Yup.object().shape({
                S10x15: Yup.number()
                    .required(intl.formatMessage({ id: 'validation.required' }))
                    .integer(intl.formatMessage({ id: 'validation.number.integer' }))
                    .typeError(intl.formatMessage({ id: 'validation.number' }))
                    .min(0, intl.formatMessage({ id: 'validation.number.min' }, [0]))
                    .nullable(),
                S15x15: Yup.number()
                    .required(intl.formatMessage({ id: 'validation.required' }))
                    .integer(intl.formatMessage({ id: 'validation.number.integer' }))
                    .typeError(intl.formatMessage({ id: 'validation.number' }))
                    .min(0, intl.formatMessage({ id: 'validation.number.min' }, [0]))
                    .nullable(),
                S20x15: Yup.number()
                    .required(intl.formatMessage({ id: 'validation.required' }))
                    .integer(intl.formatMessage({ id: 'validation.number.integer' }))
                    .typeError(intl.formatMessage({ id: 'validation.number' }))
                    .min(0, intl.formatMessage({ id: 'validation.number.min' }, [0]))
                    .nullable(),
            }),
        }),
    });

export default getValidationSchema;

