import gql from 'graphql-tag';

const ALL_COUPONS = gql`
query couponsTable (
  $offset: Int
  $limit: Int
  $sort: [CouponsTableSortInput!]
  $filter: [CouponsTableFilterInput!]
) {
  couponsTable (offset: $offset, limit: $limit, sort: $sort, filter: $filter) {
    totalCount,
    items {
      id
      bid
      serialNumber
      status
      value
      appliedByUser
      appliedByUserId
      appliedAtTimestamp
      qrToken
    }
  }
}
`;

export default ALL_COUPONS;