import gql from 'graphql-tag';

const CAMPAIGN_APPROVE = gql`
mutation campaignApprove(
    $id: ID!
    $description: String
) {
    campaignApprove(id: $id, description: $description) {
    id
  }
}
`;

export default CAMPAIGN_APPROVE;