import { Grid } from "@material-ui/core";
import { useQuery, useMutation } from '@apollo/react-hooks';
import { pathOr } from 'rambda';
import { makeStyles } from "@material-ui/core/styles";
import IconCard from "components-lib/IconCard/IconCard";
import Button from "components/CustomButtons/Button.js";
import React, { useState, useEffect } from "react";
import { injectIntl } from "react-intl";
import { withRouter } from "react-router-dom";
import styles from "common/styles/widgets.js";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import InfoIcon from '@material-ui/icons/Info';
import Modal from "components-lib/Modal/Modal";
import Input from "@material-ui/core/Input";
import AutocompleteSelect from "components-lib/AutocompleteSelect/AutocompleteSelect";
import { useSnackbar } from 'notistack';
import FormikField from 'components-lib/FormikField/FormikField';
import { Formik } from 'formik';
import { validationSchema } from './validationSchema';
import paths from 'paths';
import { generatePath } from "react-router-dom";
import moment from 'moment';
import ALL_USERS_FOR_TRANSFER from 'queries/UsersQueries/allUsersForTransfer';
import USER_TRANSFER_CREDIT from 'queries/UsersQueries/userTransferCredit';
import USER_SUBSCRIPTION_DEACTIVATE from 'queries/UsersQueries/userSubscriptionDeactivate';
import { getApolloClient } from "configFiles/apollo";
import { colors } from 'common/styles/configLayout';
import { styled } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';

const useStyles = makeStyles((theme) => styles(theme));

const StyledTextField = styled(TextField)({
  "& label": {
    //color: "white"
  },
  "& label.Mui-focused": {
    color: colors.main,
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: colors.main,
  },
  "& .MuiOutlinedInput-root": {
    "&:hover fieldset": {
      borderWidth: 1
    },
    "&.Mui-focused fieldset": {
    }
  }
});

const StoreCard = (props) => {
  const {
    intl,
    history,
    status,
    credits,
    subscriptionCredits,
    subscriptionStatus,
    canceledStatus,
    orders,
    photos,
    userId,
    userName,
    userEmail,
    createdAt,
    signInMethod,
    refetch,
  } = props;
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [modalOpen, setModalOpen] = useState(false);
  const [deactivateSubscriptionModalOpen, setDeactivateSubscriptionModalOpen] = useState(false);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [createTransfer] = useMutation(USER_TRANSFER_CREDIT);
  const [deactivateSubscription] = useMutation(USER_SUBSCRIPTION_DEACTIVATE);
  const [destinationUser, setDestinationUser] = useState('')
  const [usersForTransfer, setUsersForTransfer] = useState([]);
  const [allCredits, setAllCredits] = useState(null);
  const client = getApolloClient(enqueueSnackbar, history, intl);

  const [value, setValue] = useState("");


  const getUsersForTransfer = async (value) => {
    try {
      const result = await client.query({
        query: ALL_USERS_FOR_TRANSFER,
        variables: {
          "limit": 50,
          "filter": [
            {
              fullName: {
                contains: value
              },
              role: {
                eq: "User"
              },
              id: {
                ne: Number(userId)
              }
            },
            {

              email: {
                contains: value
              },
              role: {
                eq: "User"
              },
              id: {
                ne: Number(userId)
              }
            }
          ]
        }
      });
      if (result.error) {
        throw result.error;
      }
      if (result.errors) {
        throw result.errors[0];
      }

      let clubMembersSelectItems = (result?.data?.users?.items).map(
        (item) => {
          return {
            value: item?.id,
            label: `${item?.email} (${item?.fullName})`,
          };
        });
      setUsersForTransfer(clubMembersSelectItems);
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (value.length > 2) {
        getUsersForTransfer(value);
      } else {
        setUsersForTransfer([])
      }
    }, 600);

    return () => clearTimeout(delayDebounceFn);
  }, [value]);



  const confirmationHandle = (values, resetForm) => {
    resetForm()
    setAllCredits(values.credits)
    setModalOpen(false);
    setConfirmationModalOpen(true);
  };

  const handleCreateTransfer = () => {
    createTransfer({
      variables: {
        amount: allCredits,
        sourceUserId: userId,
        targetUserId: destinationUser.value
      }
    }).then((response) => {
      enqueueSnackbar(intl.formatMessage({ id: "userDetail.statusCard.successTransform" }), { variant: 'success' });
      setModalOpen(false);
      setConfirmationModalOpen(false);
      setDestinationUser('');
      setAllCredits(null);
      refetch();
    }).catch((err) => {
      console.log('[error]', err);
    })
  };

  const handleDeactivateSubscription = () => {
    deactivateSubscription({
      variables: {
        userId: userId,
      }
    }).then((response) => {
      enqueueSnackbar(intl.formatMessage({ id: "userDetail.statusCard.successDeactivateSubscription" }), { variant: 'success' });
      setDeactivateSubscriptionModalOpen()
      refetch();
    }).catch((err) => {
      console.log('[error]', err);
    })
  };

  const getModalTitle = () => {
    return <>{intl.formatMessage({ id: "userDetail.statusCard.creditTransfer" })} ({userEmail})</>
  }

  const getRemains = (cred) => {
    let final = (cred)
      ? Number(credits) - Number(cred)
      : credits
    return <>
      {(final >= 0)
        ? <span style={{ color: "black" }}>{intl.formatMessage({ id: 'campaigns.budgetCard.balanceOfCredits' })} {final} C</span>
        : <span style={{ color: "red" }}>
          {intl.formatMessage({ id: "userDetail.statusCard.remains" })} : {intl.formatMessage({ id: "userDetail.statusCard.lessThanZero" })} {intl.formatMessage({ id: "userDetail.statusCard.credits" })}
        </span>}
    </>
  }

  const subscriptionAcountStatus = () => {
    if (subscriptionStatus && canceledStatus !== 'Canceled') {
      return <Box color="success.main">{subscriptionStatus}</Box>
    } else if (subscriptionStatus && canceledStatus === 'Canceled') {
      return <Box>{subscriptionStatus} ({intl.formatMessage({ id: 'userDetail.statusCard.subscriptionStatus.canceled' })})</Box>
    } else {
      return <Box color="gray">{intl.formatMessage({ id: 'userDetail.statusCard.subscriptionStatus.unactivated' })}</Box>
    }
  };

  const handleCloseModal = (resetForm) => {
    setDestinationUser('');
    setModalOpen(false);
    setConfirmationModalOpen(false);
    resetForm();
  };

  const getDeactivateSubscriptionModalActions = () => {
    return (
      <>
        <Button
          onClick={() => handleDeactivateSubscription()}
          color="danger"
          round
          size="sm"
        >
          {intl.formatMessage({ id: 'userDetail.statusCard.subscriptionDelete' })}
        </Button>
        <Button
          onClick={() => setDeactivateSubscriptionModalOpen(false)}
          color="primary" round size="sm"
        >
          {intl.formatMessage({ id: 'deviceDetail.status.back' })}
        </Button>
      </>
    )
  }

  const renderConfirmActions = () => {
    return (
      <>
        <Button
          onClick={() => { handleCreateTransfer() }}
          color="success"
          round
          size="sm"
        >
          {intl.formatMessage({ id: "userDetail.statusCard.confirmButton" })}
        </Button>
        <Button
          onClick={() => setConfirmationModalOpen(false)}
          color="primary"
          size="sm"
          round
        >
          {intl.formatMessage({ id: 'notificationsPage.modal.cancel' })}
        </Button>
      </>
    );
  };

  const renderTransferActions = (formikProps) => {
    return (
      <>
        <Button
          disabled={!(formikProps.values.credits) || !destinationUser}
          onClick={() => { formikProps.handleSubmit() }}
          color="success"
          round
          size="sm"
        >
          {intl.formatMessage({ id: "userDetail.statusCard.confirmButton" })}
        </Button>
        <Button
          onClick={() => { handleCloseModal(formikProps.resetForm) }}
          color="primary"
          size="sm"
          round
        >
          {intl.formatMessage({ id: 'notificationsPage.modal.cancel' })}
        </Button>
      </>
    );
  };

  return (
    <>
      <IconCard
        title={intl.formatMessage({ id: "userDetail.statusCard.title" })}
        icon={<InfoIcon />}
      >
        <Grid container spacing={1}>
          <Grid item sm={12}>
            {intl.formatMessage({ id: "userDetail.statusCard.status" })}
            <Box className={classes.floatRight}>
              {status()}
            </Box>
          </Grid>
          <Grid item sm={12}>
            {intl.formatMessage({ id: "userDetail.statusCard.subscriptionStatus" })}
            <Box className={classes.floatRight}>
              {subscriptionAcountStatus()}
            </Box>
          </Grid>
          <Grid item sm={12}>
            {intl.formatMessage({ id: "userDetail.statusCard.numberOfSubscriptionCredits" })}
            <Box className={classes.floatRight}>
              {subscriptionCredits} C
            </Box>
          </Grid>
          <Grid item sm={12}>
            {intl.formatMessage({ id: "userDetail.statusCard.numberOfCredits" })}
            <Box className={classes.floatRight}>
              {credits} C
            </Box>
          </Grid>
          <Grid item sm={12}>
            {intl.formatMessage({ id: "userDetail.statusCard.numberOfOrders" })}
            <Box className={classes.floatRight}>
              {orders}
            </Box>
          </Grid>
          <Grid item sm={12}>
            {intl.formatMessage({ id: "userDetail.statusCard.numberOfPhotos" })}
            <Box className={classes.floatRight}>
              {photos}
            </Box>
          </Grid>
          <Grid item sm={12}>
            {intl.formatMessage({ id: "userDetail.statusCard.registrationDate" })}
            <Box className={classes.floatRight}>
              {moment(createdAt).format("DD.MM.YYYY HH:mm")}
            </Box>
          </Grid>
          <Grid item sm={12}>
            {intl.formatMessage({ id: "userDetail.statusCard.registrationType" })}
            <Box className={classes.floatRight}>
              {signInMethod}
            </Box>
            <hr />
          </Grid>
          {/*<Grid item sm={12}>
            {intl.formatMessage({ id: "userDetail.statusCard.lastActivity" })}
            <Box className={classes.floatRight}>
              {moment(updatedAt).format("DD.MM.YYYY HH:mm")}
            </Box>
            <hr />
          </Grid> */}
        </Grid>
        {(subscriptionStatus && canceledStatus !== 'Canceled') ?
          <Grid item sm={12}>
            <Button
              className={classes.floatRight}
              color="info"
              size="sm"
              round
              onClick={() => setDeactivateSubscriptionModalOpen(true)}
            >
              {intl.formatMessage({ id: "userDetail.statusCard.subscriptionDelete" })}
            </Button>
            <Button
              className={classes.floatRight}

              color="info"
              size="sm"
              round
              disabled={(credits <= 0)}
              onClick={() => setModalOpen(true)}
            >
              {intl.formatMessage({ id: "userDetail.statusCard.creditTransfer" })}
            </Button>
            <Button
              className={classes.floatRight}
              color="info"
              size="sm"
              round
              onClick={() => history.push({
                pathname: `/admin${generatePath(paths.operations.list)}`,
                state: userId
              })}
            >
              {intl.formatMessage({ id: "userDetail.statusCard.operationList" })}
            </Button>
          </Grid>
          :
          <Grid item sm={12}>
            <br />
            <Button
              className={classes.floatRight}
              color="info"
              size="sm"
              round
              onClick={() => history.push({
                pathname: `/admin${generatePath(paths.operations.list)}`,
                state: userId
              })}
            >
              {intl.formatMessage({ id: "userDetail.statusCard.operationList" })}
            </Button>
            <Button
              className={classes.floatRight}

              color="info"
              size="sm"
              round
              disabled={(credits <= 0)}
              onClick={() => setModalOpen(true)}
            >
              {intl.formatMessage({ id: "userDetail.statusCard.creditTransfer" })}
            </Button>
          </Grid>
        }
      </IconCard >
      <Formik
        initialValues={{
          title: '',
          text: ''
        }}
        onSubmit={(values, { resetForm }) => { confirmationHandle(values, resetForm) }}
        validationSchema={validationSchema(intl, credits)}
      >
        {(formikProps) => (
          <Modal
            title={getModalTitle()}
            open={modalOpen}
            onClose={() => { setModalOpen(false) }}
            actions={renderTransferActions(formikProps)}
          >


            <Grid container>
              <Grid item sm={12}>
                <Autocomplete
                  className={classes.inputRoot}
                  classes={{ inputRoot: classes.inputRoot }}
                  options={usersForTransfer}
                  value={destinationUser}
                  onChange={(event, value) => setDestinationUser(value)}
                  getOptionLabel={(option) => option?.label}
                  size="small"
                  renderInput={(params) => <>
                    <StyledTextField
                      {...params}
                      type="text"
                      label={intl.formatMessage({ id: "userDetail.statusCard.destinationUser.label" })}
                      placeholder={intl.formatMessage({ id: "userDetail.statusCard.destinationUser.placeholder" })}
                      autoComplete="off"
                    />
                    {setValue(params.inputProps.value)}
                  </>}
                  inputProps={{
                    classes: {
                      root: classes.label,
                    },
                  }}
                />
              </Grid>
              <Grid item sm={12}>
                <FormikField
                  name="credits"
                  formikProps={formikProps}
                  labelText={<p>{intl.formatMessage({ id: "userDetail.statusCard.maxCredits" })}{credits} {intl.formatMessage({ id: "userDetail.statusCard.credits" })}</p>}
                />
                <Grid item sm={12}>
                  {getRemains(formikProps.values.credits)}
                </Grid>
              </Grid>
            </Grid>

          </Modal>
        )}
      </Formik>

      <Modal
        title={intl.formatMessage({ id: "userDetail.statusCard.modalTitle" })}
        open={confirmationModalOpen}
        onClose={() => { setConfirmationModalOpen(false) }}
        actions={renderConfirmActions()}
      >
        <Grid>
          <hr />
          <Grid container spacing={1}>
            <Grid item sm={12}>
              <b>{intl.formatMessage({ id: "userDetail.statusCard.sourceUser" })}:</b>
              <Box className={classes.floatRight} style={{ marginLeft: "1.5em" }}>
                {userEmail} ({userName})
              </Box>
            </Grid>
            <Grid item sm={12}>
              <b>{intl.formatMessage({ id: "userDetail.statusCard.destinationUser.label" })}:</b>
              <Box className={classes.floatRight} style={{ marginLeft: "1.5em" }}>
                {destinationUser?.label}
              </Box>
            </Grid>
            <Grid item sm={12}>
              <b>{intl.formatMessage({ id: "userDetail.statusCard.sendCredits" })}</b>
              <Box className={classes.floatRight} style={{ marginLeft: "1.5em" }}>
                <b>{allCredits} C / {credits} C</b>
              </Box>
              <hr />
            </Grid>
          </Grid>
        </Grid>
      </Modal>

      <Modal
        title={intl.formatMessage({ id: "userDetail.statusCard.subscriptionDelete" })}
        open={deactivateSubscriptionModalOpen}
        onClose={() => { setDeactivateSubscriptionModalOpen(false) }}
        actions={getDeactivateSubscriptionModalActions()}
      >
        {intl.formatMessage({ id: 'userDetail.statusCard.deleteModal.text' })}
      </Modal>
    </>
  );
};

export default withRouter(injectIntl(StoreCard));