import React, { useState } from "react";
import { injectIntl } from 'react-intl';
import PageHeader from 'components-lib/PageHeader/PageHeader';
import { Grid } from '@material-ui/core';
import IconCard from 'components-lib/IconCard/IconCard';
import NotificationsIcon from '@material-ui/icons/Notifications';
import Modal from 'components-lib/Modal/Modal';
import FormikField from 'components-lib/FormikField/FormikField';
import Button from "components/CustomButtons/Button.js";
import { Formik } from 'formik';
//import validationSchema from './validationSchema';
import { useMutation, useLazyQuery } from '@apollo/react-hooks';
import { useSnackbar } from 'notistack';
import { pathOr } from 'rambda';
import NotificationTable from './NotificationTable'
import NotificationScreenEnum from "common/enums/NotificationScreenEnum";
import Box from "@material-ui/core/Box";
import styles from "common/styles/widgets.js";
import { makeStyles } from "@material-ui/core/styles";
import CREATE_NOTIFICATION from 'queries/NotificationsQueries/createNotification';
import ALL_NOTIFICATIONS from 'queries/NotificationsQueries/allNotifications';
import { generatePath } from "react-router-dom";
import paths from "paths";

const useStyles = makeStyles((theme) => styles(theme));

const NotificationListPage = ({ intl, history }) => {
    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();


    const [openCreateNotificationModal, setOpenCreateNotificationModal] = React.useState(false);
    const [openConfirmNotificationModal, setOpenConfirmNotificationModal] = React.useState(false);
    const [notificationModalData, setNotificationModalData] = React.useState({});
    const [userData] = useState({});
    const [loadNotifications, { data: dataNotifications, refetch: refetchNotifications }] = useLazyQuery(ALL_NOTIFICATIONS);
    const [loadWaitingNotifications, { data: dataWaitingNotifications, refetch: refetchWaitingNotifications }] = useLazyQuery(ALL_NOTIFICATIONS);
    const [createNotification] = useMutation(CREATE_NOTIFICATION);


    const handleCreateNotification = () => {
        const values = {
            title: pathOr('', ['values', 'title'], notificationModalData),
            text: pathOr('', ['values', 'text'], notificationModalData),
            action: {
                screen: pathOr('', ['values', 'screen'], notificationModalData)
            },
        };

        if (!pathOr(null, ['values', 'screen'], notificationModalData)) {
            delete values.action;
        }
        createNotification({
            variables: {
                input: values
            }
        }).then((response) => {
            enqueueSnackbar(intl.formatMessage({ id: 'notificationsPage.create.success' }), { variant: 'success' });
            //notificationModalData.resetForm();
            refetchNotifications();
            setOpenConfirmNotificationModal(false);
            setOpenCreateNotificationModal(false);
        }).catch((err) => {
            console.log('[error]', err);
        })
    };

    const goToNotificationConfirmation = (values, resetForm) => {
        setNotificationModalData({
            values,
            resetForm
        });
        setOpenCreateNotificationModal(false);
        setOpenConfirmNotificationModal(true);
    };

    const handleCancelNotification = () => {
        setOpenConfirmNotificationModal(false);
        setOpenCreateNotificationModal(true);
    };

    const handleCloseCreateNotificationModal = (resetForm) => {
        setOpenCreateNotificationModal(false);
        resetForm();
    };

    const renderConfirmActions = () => {
        return (
            <>
                <Button
                    onClick={() => { handleCreateNotification() }}
                    color="success"
                    round
                    size="sm"
                >
                    {intl.formatMessage({ id: 'notificationsPage.modal.confirm.submit' })}
                </Button>
                <Button
                    onClick={() => { handleCancelNotification() }}
                    color="primary"
                    round
                    size="sm"
                >
                    {intl.formatMessage({ id: 'notificationsPage.modal.confirm.cancel' })}
                </Button>
            </>
        );
    };


    const renderAssignActions = (formikProps) => {
        return (
            <>
                <Button
                    disabled={!formikProps.values.title || !formikProps.values.text || !formikProps.values.screen}
                    onClick={() => { formikProps.handleSubmit() }}
                    color="success"
                    round
                    size="sm"
                >
                    {intl.formatMessage({ id: 'notificationsPage.modal.submit' })}
                </Button>
                <Button
                    onClick={() => handleCloseCreateNotificationModal(formikProps.resetForm)}
                    color="primary"
                    size="sm"
                    round
                >
                    {intl.formatMessage({ id: 'notificationsPage.modal.cancel' })}
                </Button>
            </>
        );
    };

    return (
        <>
            <PageHeader
                withBackButton={false}
                title={intl.formatMessage({ id: 'notificationsPage.title' })}
                actions={[
                    {
                        title: intl.formatMessage({ id: 'notificationsPage.create' }),
                        onClick: () => history.push(`/admin${generatePath(paths.notifications.new)}`),
                    },
                    {
                        title: intl.formatMessage({ id: 'notificationsPage.automatic.title' }),
                        onClick: () => history.push(`/admin${generatePath(paths.notifications.automatic)}`),
                    }

                ]}
            />

            <Modal
                title={intl.formatMessage({ id: 'notificationsPage.modal.confirm.text' })}
                open={openConfirmNotificationModal}
                onClose={() => handleCancelNotification()}
                actions={renderConfirmActions()}
            >
                <Grid>
                    <hr />
                    <Grid container spacing={1}>
                        <Grid item sm={12}>
                            <b>{intl.formatMessage({ id: 'notificationsPage.modal.header' })}:</b>
                            <Box className={classes.floatRight} style={{ marginLeft: "1.5em" }}>
                                {pathOr('', ['values', 'title'], notificationModalData)}
                            </Box>
                        </Grid>
                        <Grid item sm={12}>
                            <b>{intl.formatMessage({ id: 'notificationsPage.modal.text' })}:</b>
                            <Box className={classes.floatRight} style={{ marginLeft: "1.5em" }}>
                                {pathOr('', ['values', 'text'], notificationModalData)}
                            </Box>
                        </Grid>
                        <Grid item sm={12}>
                            <b>{intl.formatMessage({ id: 'notificationsPage.modal.screen' })}:</b>
                            <Box className={classes.floatRight} style={{ marginLeft: "1.5em" }}>
                                {pathOr('', ['values', 'screen'], notificationModalData)}
                            </Box>
                            <hr />
                        </Grid>
                    </Grid>
                </Grid>
            </Modal>
            <Formik
                initialValues={userData}
                onSubmit={(values, { resetForm }) => { goToNotificationConfirmation(values, resetForm) }}
            //validationSchema={validationSchema(intl)}
            >
                {(formikProps) => (
                    <Modal
                        title={intl.formatMessage({ id: 'notificationsPage.modal.title' })}
                        open={openCreateNotificationModal}
                        onClose={() => handleCloseCreateNotificationModal(formikProps.resetForm)}
                        actions={renderAssignActions(formikProps)}
                    >
                        <Grid container>
                            <FormikField
                                name="title"
                                formikProps={formikProps}
                                labelText={intl.formatMessage({ id: 'notificationsPage.modal.header' })}
                            />
                            <FormikField
                                type="multiline"
                                name="text"
                                formikProps={formikProps}
                                labelText={intl.formatMessage({ id: 'notificationsPage.modal.text' })}
                                helperText={170 - pathOr('', ['values', 'text'], formikProps).length}
                                inputProps={{ rows: 2 }}
                            />
                            <Grid item sm={12}>
                                <br />
                                <FormikField
                                    type="select"
                                    name="screen"
                                    formikProps={formikProps}
                                    labelText={intl.formatMessage({ id: 'notificationsPage.modal.screen' })}
                                    options={[
                                        {
                                            label: intl.formatMessage({ id: 'screenType.Home' }),
                                            value: NotificationScreenEnum.HOME
                                        },
                                        {
                                            label: intl.formatMessage({ id: 'screenType.Menu' }),
                                            value: NotificationScreenEnum.MENU
                                        },
                                        {
                                            label: intl.formatMessage({ id: 'screenType.Profile' }),
                                            value: NotificationScreenEnum.PROFILE
                                        },
                                        {
                                            label: intl.formatMessage({ id: 'screenType.Credits' }),
                                            value: NotificationScreenEnum.CREDIT
                                        },
                                        {
                                            label: intl.formatMessage({ id: 'screenType.Orders' }),
                                            value: NotificationScreenEnum.ORDERS
                                        },
                                        {
                                            label: intl.formatMessage({ id: 'screenType.Fotomats' }),
                                            value: NotificationScreenEnum.FOTOMATS
                                        },
                                    ]}
                                />
                            </Grid>
                        </Grid>
                    </Modal>
                )}
            </Formik>
            <IconCard
                icon={<NotificationsIcon />}
                title={intl.formatMessage({ id: 'notificationsPage.table.sending.title' })}
            >

                <NotificationTable
                    data={dataWaitingNotifications}
                    loadData={loadWaitingNotifications}
                    defaultFilter={{ sentAt: { isNull: true }, canceledAt: { isNull: true } }}
                    waiting={true}
                    refetch={refetchWaitingNotifications}
                    history={history}
                />

            </IconCard>

            <IconCard
                icon={<NotificationsIcon />}
                title={intl.formatMessage({ id: 'notificationsPage.table.history.title' })}
            >
                <NotificationTable
                    data={dataNotifications}
                    loadData={loadNotifications}
                    defaultFilter={{ sentAt: { isNull: false } }}
                    history={history}

                />

            </IconCard>
        </>
    );
};

export default injectIntl(NotificationListPage);