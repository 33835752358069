import { Grid } from "@material-ui/core";
import AddPhotoAlternateIcon from '@material-ui/icons/AddPhotoAlternate';
import IconCard from "components-lib/IconCard/IconCard";
import React, { useState } from "react";
import { injectIntl } from "react-intl";
import { useMutation } from "@apollo/react-hooks";
import MEDIA_UPLOAD from "queries/MediaQueries/mediaUpload";
import * as axios from "axios";
import { useSnackbar } from "notistack";

const BudgetCard = (props) => {
    const {
        intl,
        companyId,
        callbackMediaId,
        callbackMediaUrl
    } = props;
    const { enqueueSnackbar } = useSnackbar();

    const [uploadFile] = useMutation(MEDIA_UPLOAD);
    const [mediaUrl, setMediaUrl] = useState(null);

    const handleMediaUpload = (values) => {
        let _URL = window.URL || window.webkitURL;
        let file = values;
        let img = new Image();
        let imgwidth = 0;
        let imgheight = 0;
        //let url = "";
        let maxwidth = 600;
        let maxheight = 1200;

        if (_URL.createObjectURL(file)) {
            img.src = _URL.createObjectURL(file);
            img.onload = function () {
                imgwidth = this.width;
                imgheight = this.height;
                //url = this.url
                if (imgwidth !== maxwidth || imgheight !== maxheight) {
                    enqueueSnackbar(intl.formatMessage({ id: 'campaigns.graficCard.errorResolution' }), { variant: "error" })
                } else {
                    callbackMediaUrl(img.src)
                    setMediaUrl(img.src)
                    uploadFile({
                        variables: {
                            input: {
                                category: "FreePhotoAdvertisement",
                                name: values.name,
                                companyId: companyId
                            }
                        }
                    }).then((response) => {
                        axios.put(response.data.mediaUpload[0].url.url, values);
                        //setMediaUrl(response.data.mediaUpload[0].url.url);
                        callbackMediaId(response.data.mediaUpload[0].media.id)
                        enqueueSnackbar(intl.formatMessage({ id: "campaigns.graficCard.successUpload" }), { variant: "success" })
                    }).catch(err => {
                        console.log('[error]', err)
                    });
                }
            }
        }
    }

    return <>
        <IconCard
            title={intl.formatMessage({ id: "campaigns.graficCard.title" })}
            icon={<AddPhotoAlternateIcon />}
        >
            <Grid>
                <p><b>{intl.formatMessage({ id: "campaigns.graficCard.rules" })}</b></p>
                <p>{intl.formatMessage({ id: "campaigns.graficCard.firstRule" })}</p>
                <p>{intl.formatMessage({ id: "campaigns.graficCard.secondRule" })}</p>
            </Grid>
            <hr />
            {(mediaUrl)
                ? <p>{intl.formatMessage({ id: "campaigns.graficCard.changePicture" })}</p>
                : <p>{intl.formatMessage({ id: "campaigns.graficCard.choosePicture" })}</p>
            }

            <input
                id="baner"
                name="baner"
                type='file'
                style={{ display: "true" }}
                width={300}
                accept="image/png, image/jpeg"
                onChange={({ target: { files } }) => {
                    handleMediaUpload(files[0])
                }}
            />
        </IconCard>
    </>
};

export default injectIntl(BudgetCard);
