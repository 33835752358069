import gql from "graphql-tag";

export const ORDERS_BY_ZONE = (id) => gql`
  query ordersTableByZone($offset: Int, $limit: Int, $sort: [OrdersTableSortInput!], $filter: [OrdersTableFilterInput]){
    ordersTableByZone(zoneId: ${id}, offset:$offset, limit: $limit, sort: $sort, filter: $filter){
    totalCount,  
    items{
      id
      bid
      type
      status
      isPaid
      email
      price
      expiresAt
      createdAt
      paymentSource
      purchaseAmount
      }
    }
  }
`;
export default ORDERS_BY_ZONE;
