//import { dangerColor } from "assets/jss/material-dashboard-pro-react.js";
import { chartColors } from "common/styles/configLayout.js";
const devicesListPageStyles = (theme) => {
  return {
    iconAlign: {
      verticalAlign: "text-bottom",
    },
    floatRight: {
      float: "right",
    },
    tenantButtons: {
      marginRight: theme.spacing(3),
    },
    marginBottom: {
      marginBottom: theme.spacing(6),
    },
    tenantCardButtons: {
      position: "absolute",
      width: "100%",
      bottom: "0",
    },
    tenantCardButtonsMargin: {
      float: "right",
      marginRight: theme.spacing(2),
    },
    selectStyle: {
      margin: "0 10px 20px 10px",
      width: "300px",
    },
    warning: {
      color: "#EE9600",
    },
    warningIcon: {
      marginRight: "15px",
      verticalAlign: "middle",
      lineHeight: "normal",
    },

    circleTable: {
      height: "20px",
      width: "20px",
      borderRadius: "50%",
      display: "inline-block",
      marginRight: "5px",
      marginBottom: "-5px",
      marginTop: "12px",
    },
    disabledButton: {
      backgroundColor: "red",
    },

    successCircle: {
      height: "10px",
      width: "10px",
      backgroundColor: "green",
      borderRadius: "50%",
      display: "inline-block",
      marginRight: "10px",
    },

    warningCircle: {
      height: "10px",
      width: "10px",
      backgroundColor: "orange",
      borderRadius: "50%",
      display: "inline-block",
      marginRight: "10px",
    },

    errorCircle: {
      height: "10px",
      width: "10px",
      backgroundColor: "red",
      borderRadius: "50%",
      display: "inline-block",
      marginRight: "10px",
    },
    oneCircle: {
      height: "10px",
      width: "10px",
      backgroundColor: chartColors.one,
      borderRadius: "50%",
      display: "inline-block",
      marginRight: "5px",
    },
    twoCircle: {
      height: "10px",
      width: "10px",
      backgroundColor: chartColors.two,
      borderRadius: "50%",
      display: "inline-block",
      marginRight: "5px",
    },
    threeCircle: {
      height: "10px",
      width: "10px",
      backgroundColor: chartColors.three,
      borderRadius: "50%",
      display: "inline-block",
      marginRight: "5px",
    },
    fourCircle: {
      height: "10px",
      width: "10px",
      backgroundColor: chartColors.four,
      borderRadius: "50%",
      display: "inline-block",
      marginRight: "5px",
    },
    fiveCircle: {
      height: "10px",
      width: "10px",
      backgroundColor: chartColors.five,
      borderRadius: "50%",
      display: "inline-block",
      marginRight: "5px",
    },
    pointer: {
      cursor: "pointer",
    },

    //Chart Colors
    pieChartOne: {
      fill: chartColors.one,
      stroke: chartColors.one,
    },
    pieChartTwo: {
      fill: chartColors.two,
      stroke: chartColors.two,
    },
    pieChartThree: {
      fill: chartColors.three,
      stroke: chartColors.three,
    },
    pieChartFour: {
      fill: chartColors.four,
      stroke: chartColors.four,
    },
    pieChartFive: {
      fill: chartColors.five,
      stroke: chartColors.five,
    },
  };
};
export default devicesListPageStyles;
