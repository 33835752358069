import { gql } from "apollo-boost";

const AMBASSADOR_ENABLE = gql`
  mutation ambassadorEnable($userId: ID!, $input: AmbassadorInput) {
    ambassadorEnable(userId: $userId, input: $input) {
      id
    }
  }
`;

export default AMBASSADOR_ENABLE;
