import React from 'react';
import PageHeader from 'components-lib/PageHeader/PageHeader';
import IconCard from 'components-lib/IconCard/IconCard';
import { Grid } from '@material-ui/core';
import { useState } from 'react';
import { useMutation, useLazyQuery } from 'react-apollo';
import { Formik } from 'formik';
import FormikField from 'components-lib/FormikField/FormikField';
import Button from "components/CustomButtons/Button.js";
import { useSnackbar } from "notistack";
import getValidationSchema from "./validationSchema";
import { injectIntl } from 'react-intl';
import TopicsTable from './TopicsTable';
import SegmentsTable from './SegmentsTable';
import ALL_TOPICS from "queries/ManagementQueries/allTopics";
import ALL_SEGMENTS from "queries/ManagementQueries/allSegments";
import CategoryIcon from '@material-ui/icons/Category';
import TOPIC_CREATE from 'queries/ManagementQueries/topicCreate';
import SEGMENT_CREATE from 'queries/ManagementQueries/segmentCreate';
import Modal from 'components-lib/Modal/Modal';
import BubbleChartIcon from '@material-ui/icons/BubbleChart';

const ManagementPage = ({ intl, history }) => {
    const [settings, setSettings] = useState(null);
    const { enqueueSnackbar } = useSnackbar();

    const [loadTopicsData, { data: dataTopics, refetch: refetchTopics }] = useLazyQuery(ALL_TOPICS);
    const [loadSegmantsData, { data: dataSegments, refetch: refetchSegments }] = useLazyQuery(ALL_SEGMENTS);

    const [createTopic] = useMutation(TOPIC_CREATE);
    const [createSegment] = useMutation(SEGMENT_CREATE);
    const [createTopicModalOpen, setCreateTopicModalOpen] = useState(false)
    const [createSegmentModalOpen, setCreateSegmentModalOpen] = useState(false)

    const handleCreateTopic = (formikProps) => {
        createTopic({
            variables: {
                input: {
                    ...formikProps?.values
                }
            }
        }).then((response) => {
            enqueueSnackbar(intl.formatMessage({ id: 'management.topics.snackbar.createSuccessfull' }), { variant: 'success' });
            refetchTopics();
            setCreateTopicModalOpen(false);
        }).catch((err) => {
            console.log('[error]', err);
        })
        formikProps.resetForm();
    }

    const handleCreateSegment = (formikProps) => {
        createSegment({
            variables: {
                input: {
                    ...formikProps?.values
                }
            }
        }).then((response) => {
            enqueueSnackbar(intl.formatMessage({ id: 'management.segments.snackbar.createSuccessfull' }), { variant: 'success' });
            refetchSegments();
            setCreateSegmentModalOpen(false);
        }).catch((err) => {
            console.log('[error]', err);
        })
        formikProps.resetForm();
    }

    const renderCreateModalButtons = (formikProps, category) => {
        return (
            <>
                <Button
                    size="sm"
                    color="success"
                    onClick={() => (category === "segment") ? handleCreateSegment(formikProps) : handleCreateTopic(formikProps)}
                    round
                >
                    {intl.formatMessage({ id: 'management.modalButton.create' })}
                </Button>

                <Button
                    size="sm"
                    color="primary"
                    onClick={() => { setCreateTopicModalOpen(false); formikProps.resetForm() }}
                    round
                >
                    {intl.formatMessage({ id: 'management.modalButton.cancel' })}
                </Button>
            </>
        );
    };

    //if (loading) return <Loading />;
    return (
        <>
            <PageHeader
                withBackButton={false}
                title={intl.formatMessage({ id: 'management.title' })}
            />
            <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                    <IconCard
                        title={intl.formatMessage({ id: 'management.topics.table.title' })}
                        icon={<CategoryIcon />}
                    >
                        <TopicsTable
                            history={history}
                            data={dataTopics}
                            loadData={loadTopicsData}
                            refetch={refetchTopics}
                            queryDataPath={['topics', 'items']}
                            numberOfRows={10}
                        />
                        <Grid item sm={12}>
                            <hr />
                            <Button
                                color="primary"
                                size="sm"
                                round
                                onClick={() => setCreateTopicModalOpen(true)}
                            >
                                {intl.formatMessage({ id: "management.topics.buttons.add" })}
                            </Button>
                        </Grid>
                    </IconCard>
                </Grid>
                <Grid item xs={12} md={6}>
                    <IconCard
                        title={intl.formatMessage({ id: 'management.segments.table.title' })}
                        icon={<BubbleChartIcon />}
                    >
                        <SegmentsTable
                            history={history}
                            data={dataSegments}
                            loadData={loadSegmantsData}
                            refetch={refetchSegments}
                            queryDataPath={['segments', 'items']}
                            numberOfRows={10}
                        />
                        <Grid item sm={12}>
                            <hr />
                            <Button
                                color="primary"
                                size="sm"
                                round
                                onClick={() => setCreateSegmentModalOpen(true)}
                            >
                                {intl.formatMessage({ id: "management.segments.buttons.add" })}
                            </Button>
                        </Grid>
                    </IconCard>
                </Grid>
            </Grid>
            <Formik
                onSubmit={(values, { resetForm }) => handleCreateTopic(values, resetForm)}
                initialValues={{}}
                validationSchema={getValidationSchema(intl)}
            >
                {(formikProps) => (
                    <Modal
                        title={intl.formatMessage({ id: 'management.topics.createModal.title' })}
                        open={createTopicModalOpen}
                        onClose={() => setCreateTopicModalOpen(false)}
                        actions={renderCreateModalButtons(formikProps, "topic")}
                        fullWidth
                    >
                        <FormikField
                            name="name"
                            labelText={intl.formatMessage({ id: "management.topics.input.name" })}
                            formikProps={formikProps}
                            highlightChange
                        />

                    </Modal>
                )}
            </Formik>
            <Formik
                onSubmit={(values, { resetForm }) => handleCreateSegment(values, resetForm)}
                initialValues={{}}
                validationSchema={getValidationSchema(intl)}
            >
                {(formikProps) => (
                    <Modal
                        title={intl.formatMessage({ id: 'management.segments.createModal.title' })}
                        open={createSegmentModalOpen}
                        onClose={() => setCreateSegmentModalOpen(false)}
                        actions={renderCreateModalButtons(formikProps, "segment")}
                        fullWidth
                    >
                        <FormikField
                            name="name"
                            labelText={intl.formatMessage({ id: "management.segments.input.name" })}
                            formikProps={formikProps}
                            highlightChange
                        />

                    </Modal>
                )}
            </Formik>


        </>
    );
}

export default injectIntl(ManagementPage);