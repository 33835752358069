import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import IconCard from "components-lib/IconCard/IconCard";
import React from "react";
import { injectIntl } from "react-intl";
import { withRouter } from 'react-router';
import Box from "@material-ui/core/Box";
import styles from "common/styles/widgets.js";
import OrderStatusEnum from 'common/enums/OrderStatusEnum';
import moment from "moment";
import { generatePath } from 'react-router-dom';
import paths from 'paths';
import TimelineIcon from '@material-ui/icons/Timeline';

const useStyles = makeStyles((theme) => styles(theme));

const OrderHistoryCard = (props) => {
  const classes = useStyles();
  const {
    history,
    intl,
    order,
    photos,
    allPhotos,
  } = props;

  const onDetail = (id) => {
    history.push(`/admin${generatePath(paths.stores.detail, { storeId: id })}`);
  }

  const getStatus = (status, date, storeDetail) => {
    let ss = 0;
    if (status === OrderStatusEnum.INCOMPLETE || status === OrderStatusEnum.COMPLETED) {
    photos.map((item) => {
        item.history.map((hist, i) => {
          if (hist.status === "Printed") {
            let helpDate = moment(date) //.add(1, 'hours')
            let histDate = moment(hist.date) //.add(1, 'hours') //.format("DD.MM.YYYY HH:mm:ss")
            if (helpDate.isSameOrAfter(histDate)) {// && hist.date <= "2020-12-22T18:56:14.878Z")
              ss = ss + 1;
              //console.log(hist.date, helpDate, ss, hist.status)
            }
          }
          return "";
        })
        return "";
      })
    }
    let storeId = storeDetail?.store?.id
    let storeBid = storeDetail?.store?.bid
    let createdDate = moment(date).format("DD.MM.YYYY HH:mm:ss")
    let renderSpan

    if (status === OrderStatusEnum.INCOMPLETE || status === OrderStatusEnum.PARTIAL
      || status === OrderStatusEnum.EXPIRED)
      renderSpan = <span className={classes.warningCircle}></span>
    else if (status === OrderStatusEnum.CANCELED)
      renderSpan = <span className={classes.errorCircle}></span>
    else
      renderSpan = <span className={classes.successCircle}></span>

    return (
      <>
        {renderSpan}
        {intl.formatMessage({ id: `orderDetail.historyCard.${status}` })} { (status === OrderStatusEnum.CANCELED ||
          status === OrderStatusEnum.INCOMPLETE || status === OrderStatusEnum.PARTIAL ||
          status === OrderStatusEnum.EXPIRED || status === OrderStatusEnum.COMPLETED) ? <>{ss}/{allPhotos}</> : ""}
        { (storeId) ?
          <><br /><span style={{ textDecoration: "underline", cursor: "pointer", marginLeft: "1.6em" }} onClick={() => onDetail(storeId)} > {intl.formatMessage({ id: 'deviceList.table.store' })}: {storeBid}</span></> : ""}
        <Box className={classes.floatRight}>
          {(createdDate) ? createdDate : "-"}
        </Box>
      </>
    )
  }

  return (
    <>
      <IconCard
        title={intl.formatMessage({ id: "orderDetail.historyCard.title" })}
        className={classes.cardStyle}
        icon={<TimelineIcon />}
      >
        <Grid container spacing={1}>
          {
            Object.values(order).map((item, idx) => (
              <Grid item sm={12} key={idx}>{getStatus(item.status, item.date, item?.zone)}</Grid>
            ))}
        </Grid>
      </IconCard>
    </>
  );
};
export default withRouter(injectIntl(OrderHistoryCard));
