import gql from 'graphql-tag';

const COUPON_CAMPAIGN_ADD_BUDGET = gql`
mutation CouponCampaignAddBudget($id: ID!, $budget: Float!) {
  CouponCampaignAddBudget(id: $id, budget: $budget) {
    id
  }
}
`;

export default COUPON_CAMPAIGN_ADD_BUDGET;

