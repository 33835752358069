import React from 'react';
import { Grid } from '@material-ui/core';
import { injectIntl } from 'react-intl';
import { pathOr } from 'rambda';
import { useQuery } from 'react-apollo';
import ImageIcon from '@material-ui/icons/Image';
import IconCard from "components-lib/IconCard/IconCard";
import ALL_SETTINGS from 'queries/SettingsQueries/allSettings';
import {
  ComposedChart, Line, Bar, XAxis, YAxis, CartesianGrid,
  Tooltip, Legend, ResponsiveContainer
} from 'recharts';
import { chartColors } from "common/styles/configLayout.js";
import moment from "moment";

const DetailPhotosCard = (props) => {
  const { intl, allPhotos } = props;
  const { data: settingsData } = useQuery(ALL_SETTINGS);

  let threshold = pathOr('', ['settings', 'commission', 'threshold'], settingsData)
  let data = []
  let sumPhotos = 0
  for (let i = 0; i < allPhotos?.length; i++) {
    let helpDate = moment(allPhotos[i]?.date).format("DD.MM.YYYY")
    let helpPhotos = allPhotos[i]?.count
    data[i] = {
      name: helpDate,
      thresh: threshold,
      photos: helpPhotos
    }
    sumPhotos += data[i].photos
  }

  return (
    <>
      <IconCard
        title={intl.formatMessage({ id: "storeDetail.printedPhotosCard.title" })}
        icon={<ImageIcon />}
      >
        <ResponsiveContainer width="100%" height={310}>
          <ComposedChart
            data={data}
            margin={{ top: 5, left: -15, right: 10, bottom: 15 }}
          >
            <CartesianGrid stroke="#f5f5f5" />
            <XAxis dataKey="name" tickLine={false} />
            <YAxis />
            <Legend
              wrapperStyle={{ bottom: -20 }}
            />
            <Tooltip />
            <Bar name={intl.formatMessage({ id: "storeDetail.printedPhotosCard.photos" })} dataKey="photos" barSize={10} fill={chartColors.one} />
            <Line name={intl.formatMessage({ id: "storeDetail.printedPhotosCard.threshold" })} dot={false} type="monotone" dataKey="thresh" stroke={chartColors.three} />

          </ComposedChart>
        </ResponsiveContainer>
        <Grid item sm={12}>
          <br />
          <div style={{ paddingBottom: '28px' }} />
          <hr />
          <b>{intl.formatMessage({ id: "storeDetail.printedPhotosCard.allPhotos" })}: {sumPhotos}{intl.formatMessage({ id: 'tableTexts.piece' })}</b>
        </Grid>
      </IconCard>
    </>
  )
}

export default injectIntl(DetailPhotosCard);
