const paths = {
    dashboard: '/dashboard',
    profile: '/profile',
    management: '/management',
    auth: {
        login: '/login',
        error: '/error',
        logout: '/logout',
        forgottenPassword: '/forgotten-password'
    },
    settings: '/settings',
    devices: {
        list: '/devices',
        detail: '/devices/detail/:deviceId',
        userDevices: '/devices/:userId'
    },
    stores: {
        list: '/stores',
        detail: '/stores/detail/:storeId',
        new: '/stores/new'
    },
    campaigns: {
        FreePhoto: '/freephoto-campaigns',
        FreePhotoNew: '/freephoto-campaigns/new',
        FreePhotoNewBid: '/freephoto-campaigns/new-bid',
        FreePhotoDetail: '/freephoto-campaigns/detail/:campaignId',
        Coupon: '/coupon-campaigns',
        QRNew: '/coupon-campaigns/qr/new',
        QRDetail: '/coupon-campaigns/qr/detail/:campaignId',
        QRCouponSetDetail: '/coupon-campaigns/qrset/detail/:couponsetId',
        PQRNew: '/coupon-campaigns/pqr/new',
        PQRDetail: '/coupon-campaigns/pqr/detail/:campaignId',
        PQRCouponSetDetail: '/coupon-campaigns/pqrset/detail/:couponsetId',
    },

    notifications: {
        list: '/notifications',
        new: '/notifications/new',
        automatic: '/notifications/automatic'
    },
    orders: {
        list: '/orders',
        detail: '/orders/detail/:orderId',
    },
    users: {
        list: '/users',
        create: {
            partner: '/users/create/partner',
            advertiser: '/users/create/advertiser',
            maintainer: '/users/create/maintainer'
        },
        detail: '/users/detail/:userId'
    },
    companies: {
        list: '/companies',
        detail: '/companies/detail/:companyId',
        uesrDevices: '/operations/:userId',
        new: '/companies/new'
    },
    media: {
        list: '/media'
    },
    payments: {
        list: '/payments'
    },
    invoices: {
        list: '/invoices',
        detail: '/invoices/detail/:invoiceId'
    },
    commissions:
    {
        list: '/commissions',
        detail: '/commissions/detail/:commissionId'
    },
    operations: {
        list: '/activities'
    }

}

export default paths;