import React, { useCallback } from 'react';
import { Grid } from '@material-ui/core';
import GenderCheckBox from 'components-lib/GenderCheckBox/GenderCheckBox';
import { injectIntl } from 'react-intl';
import Typography from '@material-ui/core/Typography';

// import STORES_SELECT from "queries/StoresQueries/storesSelect";

const Step4 = ({ 
  intl,
  checkedMen,
  checkedWomen,
  checkedNA,
  rangeMaleChecked,
  rangeFemaleChecked,
  rangeUnknownGenderChecked,
  onCheckedAge,
  onCheckBox,
  page,
  }) => {

 
  return (
    <Grid container
      style={{ 
      display: "flex",
      justifyContent: "center",
      width: "100%",
      }}
    >
      <Grid
          style={{ 
            display: "flex",
            flexDirection: "column",
            paddingBottom:"1.7rem", 
            paddingTop:"0.4rem",
            width: "60%",
            }}
      >
        <Typography variant="h5" component="h2" 
          style={{ paddingTop:"0.625rem", paddingBottom:"0.625rem", }} >
          {intl.formatMessage({ id: 'campaigns.wizard.step4.title' })}
        </Typography>

        <GenderCheckBox 
          checkedMen={checkedMen}
          checkedWomen={checkedWomen}
          checkedNA={checkedNA}
          rangeMaleChecked={rangeMaleChecked}
          rangeFemaleChecked={rangeFemaleChecked}
          rangeUnknownGenderChecked={rangeUnknownGenderChecked}
          onCheckedAge={onCheckedAge}
          onCheckBox={onCheckBox}
          page={ page }
        />
        
      </Grid>
    </Grid>

  );
}

export default injectIntl(Step4);