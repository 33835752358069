import React, { useState } from 'react';
import { injectIntl } from 'react-intl';
import OrderStatusCard from './OrderCards/OrderStatusCard'
import OrderPaymentCard from './OrderCards/OrderPaymentCard'
import OrderCustomerCard from './OrderCards/OrderCustomerCard'
import OrderHistoryCard from './OrderCards/OrderHistoryCard'
import PageHeader from 'components-lib/PageHeader/PageHeader';
import { Grid } from '@material-ui/core';
import Modal from "components-lib/Modal/Modal";
import Button from "components/CustomButtons/Button.js";
import { useQuery, useMutation } from '@apollo/react-hooks';
import { pathOr } from 'rambda';
import { useSnackbar } from "notistack";
import OrderTypeEnum from 'common/enums/OrderTypeEnum';
import OrderStatusEnum from 'common/enums/OrderStatusEnum';
import Loading from "components-lib/Loading/Loading";
import ORDER_CANCEL from "queries/OrdersQueries/orderCancel";
import ORDER_DETAIL from 'queries/OrdersQueries/orderDetail';

const OrderDetailPage = (props) => {
    const { intl, history } = props;
    const { enqueueSnackbar } = useSnackbar();
    const orderId = props.match.params.orderId;
    const queryParams = { id: orderId }

    const { data, loading, refetch: refetchData } = useQuery(ORDER_DETAIL, { variables: queryParams })
    const [orderCancel] = useMutation(ORDER_CANCEL);
    const [cancelOrderModal, setCancelOrderModal] = useState(false);

    let type = pathOr('', ['order', 'type'], data)
    let status = pathOr('', ['order', 'status'], data)
    let firstPhotos = pathOr([], ['order', 'photos'], data).filter(key => key.size === "S10x15")?.map((item) => { return item.price })
    let secondPhotos = pathOr([], ['order', 'photos'], data).filter(key => key.size === "S15x15")?.map((item) => { return item.price })
    let thirdPhotos = pathOr([], ['order', 'photos'], data).filter(key => key.size === "S20x15")?.map((item) => { return item.price })
    let firstPhotoCredit = firstPhotos.reduce(function (sum, item) { return sum = sum + item; }, 0);
    let secondPhotoCredit = secondPhotos.reduce(function (sum, item) { return sum = sum + item; }, 0);
    let thirdPhotoCredit = thirdPhotos.reduce(function (sum, item) { return sum = sum + item; }, 0);
    let totalPhotoCount = firstPhotos.length + secondPhotos.length + thirdPhotos.length
    let totalPhotoCredit = firstPhotoCredit + secondPhotoCredit + thirdPhotoCredit
    let printedPhotos = pathOr([], ['order', 'photos'], data).filter(key => key.status === "Printed").length
    let allPhotos = pathOr([], ['order', 'photos'], data).length

    let totalPaid = pathOr([], ['order', 'operations'], data).filter(key => key.type === "Payment")?.map((item) => { return item.amount })
    let sumTotalPaid = totalPaid.reduce(function (sum, item) { return sum = sum + item; }, 0);

    const handleDeteleOrder = () => {
        orderCancel({
            variables: {
                id: orderId,
            },
        })
            .then((response) => {
                enqueueSnackbar(intl.formatMessage({ id: "orderDetail.removeOrder.successful", }), { variant: "success" });
                refetchData();
                setCancelOrderModal(false);

            })
            .catch((err) => {
                console.log('[error]', err);
            });
    };

    const getActions = () => {
        let action
        action =
            (status !== OrderStatusEnum.CANCELED && status !== OrderStatusEnum.EXPIRED
                && status !== OrderStatusEnum.COMPLETED) &&
            [
                {
                    title: intl.formatMessage({ id: "orderDetail.cancel.button" }),
                    color: "danger",
                    onClick: () => setCancelOrderModal(true),
                }
            ]

        return action
    }

    const renderCancelOrderAction = () => {
        return (
            <>
                <Button
                    onClick={() => handleDeteleOrder()}
                    color="danger"
                    round
                    size="sm">
                    {intl.formatMessage({ id: "orderDetail.cancelModal.button" })}
                </Button>

                <Button
                    onClick={() => setCancelOrderModal(false)}
                    color="primary"
                    round
                    size="sm"
                >
                    {intl.formatMessage({ id: "companyList.addModal.cancel" })}
                </Button>
            </>
        );
    };

    const getType = () => {

        if (pathOr('', ['order', 'payment', 'credit'], data) && pathOr('', ['order', 'payment', 'credit'], data) < totalPhotoCredit)
            return intl.formatMessage({ id: 'orderList.table.creditAndPaymentType' })
        else if (pathOr('', ['order', 'payment', 'credit'], data) && pathOr('', ['order', 'payment', 'credit'], data) === totalPhotoCredit)
            return intl.formatMessage({ id: 'orderList.table.paymentType' })
        else if (!pathOr('', ['order', 'payment', 'credit'], data))
            if ((type === OrderTypeEnum.FREEPHOTO))
                return intl.formatMessage({ id: 'orderList.table.freeFotoType' })
            else if (type === OrderTypeEnum.CREDIT)
                return intl.formatMessage({ id: 'orderList.table.creditType' })
            else
                return ' '
        else
            return ' '
    }

    if (loading) return <Loading />

    
    return (
        <>
            <PageHeader
                title={<>{intl.formatMessage({ id: 'orderDetail.title' })}: {getType()}</>}
                subTitle={pathOr('', ['order', 'bid'], data)}
                actions={getActions()}
                handleBackAction={(e) => history.goBack()}
            />
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={6} lg={4}>
                    <OrderStatusCard
                        isPaid={pathOr('', ['order', 'isPaid'], data)}
                        firstPhotoCount={firstPhotos.length}
                        secondPhotoCount={secondPhotos.length}
                        thirdPhotoCount={thirdPhotos.length}
                        firstPhotoCredit={firstPhotoCredit}
                        secondPhotoCredit={secondPhotoCredit}
                        thirdPhotoCredit={thirdPhotoCredit}
                        totalCount={totalPhotoCount}
                        totalCredit={totalPhotoCredit}
                    />
                    <OrderCustomerCard
                        history={history}
                        userId={pathOr('', ['order', 'owner', 'id'], data)}
                        fullName={pathOr('', ['order', 'owner', 'fullName'], data)}
                        email={pathOr('', ['order', 'owner', 'email'], data)}
                        phone={pathOr('', ['order', 'owner', 'phone'], data)}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4}>
                    <OrderPaymentCard
                        isPaid={pathOr('', ['order', 'isPaid'], data)}
                        amount={pathOr('', ['order', 'payment', 'amount'], data)}
                        payment={pathOr('', ['order', 'payment', 'credit'], data)}
                        credit={pathOr('', ['order', 'operations', [0], 'amount'], data)}
                        totalCredit={totalPhotoCredit}
                        totalPaid={sumTotalPaid}
                        type={getType()}
                        apiType={type}
                    />
                    <OrderHistoryCard
                        order={pathOr('', ['order', 'history'], data)} //TODO
                        photos={pathOr('', ['order', 'photos'], data)} //TODO
                        printedPhotos={printedPhotos}
                        allPhotos={allPhotos}
                        zoneId={pathOr([], ['order', 'zone', [0], 'id'], data)}
                        zoneBid={pathOr([], ['order', 'zone', [0], 'bid'], data)}
                    />
                </Grid>
            </Grid>
            <Modal
                title={intl.formatMessage({ id: "orderDetail.cancelModal.title" })}
                open={cancelOrderModal}
                onClose={() => setCancelOrderModal(false)}
                actions={renderCancelOrderAction()}
            />
        </>
    );
};

export default injectIntl(OrderDetailPage);