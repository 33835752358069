import QueryTable from 'components-lib/QueryTable/QueryTable';
import paths from 'paths';
import React from 'react';
import { injectIntl } from 'react-intl';
import { generatePath } from 'react-router-dom';

export const OperationsTable = (props) => {

    const { history, query, intl, defaultFilter, orFilter, queryDataPath = ['operationsTable', 'items'], numberOfRows = 25 } = props

    const onDetail = (id, type, bid) => {
        console.log( type, id, bid )
        if (type === "Company")
            history.push(`/admin${generatePath(paths.companies.detail, { companyId: id })}`);
        else if (type === "FreePhotoCampaign")
            history.push({
                pathname: `/admin${generatePath(paths.campaigns.FreePhotoDetail, { campaignId: id })}`,
                state: "freephoto"
            });
        else if (type === "Payment")
            history.push({
                pathname: `/admin${generatePath(paths.payments.list)}`,
                search: `?filter[bid][contains]=${bid}`,
            });
        /*else if (type === "InternalCampaign")
            history.push(`/admin${generatePath(paths.campaigns.internalDetail, { campaignId: id })}`);*/ // TODO - campaigns
        else if (type === "Invoice")
            history.push({
                pathname: `/admin${generatePath(paths.invoices.detail, { invoiceId: id })}`,
                state: "invoice"
            });
        else if (type === "Payout")
            history.push({
                pathname: `/admin${generatePath(paths.invoices.detail, { invoiceId: id })}`,
                state: "payout"
            });
        else if (type === "User")           
            history.push(`/admin${generatePath(paths.users.detail, { userId: id })}`);
        else if (type === "PQRCampaign")
            history.push({
                pathname: `/admin${generatePath(paths.campaigns.PQRCouponSetDetail, { couponsetId: id })}`
            });
        else if (type === "QRCampaign")
            history.push({
                pathname: `/admin${generatePath(paths.campaigns.QRCouponSetDetail, { couponsetId: id })}`                
            });
        else
            console.log("[error]")
    }

    return (
        <QueryTable
            query={query}
            numberOfRows={numberOfRows}
            permanentFilter={defaultFilter}
            orFilter={orFilter}
            queryDataPath={queryDataPath}
            columns={[
                {
                    Header: intl.formatMessage({ id: 'operationsList.table.ID' }),
                    accessor: 'bid',
                    sortKey: 'bid',
                    filterKey: 'bid',
                    filterOperator: 'contains'
                },
                {
                    Header: intl.formatMessage({ id: 'operationsList.table.date' }),
                    accessor: (rowData) => new Date(rowData.completedAt).toLocaleString('sk'),
                    sortKey: "completedAt",
                    filterKey: "completedAt",
                    filterOperator: 'between',
                    filterDataType: "dateBetween",
                    filterComponent: 'date',
                },
                {
                    Header: intl.formatMessage({ id: 'operationsList.table.sourceID' }),
                    accessor: (rowData) => <span
                        style={{ textDecoration: "underline", cursor: "pointer" }} 
                        onClick={() => onDetail(rowData.sourceId, rowData.sourceType, rowData.sourceBid)} >
                        {rowData.sourceBid}</span>,
                    sortKey: 'sourceBid',
                    filterKey: 'sourceBid',
                    filterOperator: 'contains'
                },
                {
                    Header: intl.formatMessage({ id: 'operationsList.table.operation' }),
                    accessor: "type",
                    sortKey: 'type',
                    filterKey: 'type',
                    filterComponent: 'select',
                    filterOperator: 'in',
                    filterOptions: [
                        {
                            value: 'Income',
                            label: 'Income'
                        },
                        {
                            value: 'Outcome',
                            label: 'Outcome'
                        },
                        {
                            value: 'Transfer',
                            label: 'Transfer'
                        }
                    ]
                },
                {
                    Header: intl.formatMessage({ id: 'operationsList.table.destinationID' }),
                    accessor: (rowData) => <span style={{ textDecoration: "underline", cursor: "pointer" }} onClick={() => onDetail(rowData.targetId, rowData.targetType, rowData.targetBid)}>
                        {rowData.targetBid}</span>,
                    sortKey: "targetBid",
                    filterKey: "targetBid",
                    filterOperator: 'contains'
                },
                {
                    Header: intl.formatMessage({ id: 'operationsList.table.price' }),
                    accessor: (rowData) => rowData.amount + " C",
                    sortKey: "amount",
                    filterKey: "amount",
                    filterOperator: 'eq',
                    filterDataType: 'number',
                },
                {
                    Header: " ",
                }
            ]}
        />
    )
}

export default injectIntl(OperationsTable);