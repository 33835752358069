import gql from 'graphql-tag';

const COUPON_SET_EXPORT = gql`
mutation couponSetExport($id: ID!) {
  couponSetExport(id: $id) {
    type
    url
    expiresAt
}
}`

export default COUPON_SET_EXPORT