import gql from 'graphql-tag';

const ORDER_DETAIL = gql`
query order($id: ID!){
	order(id:$id){
  	id
    bid
    type
    status
    price
    isPaid
    operations{
      type
      amount
    }
    payment{
      id
      bid
      amount
      credit
      status
    }
    owner{
      id
      fullName
      email
      phone
    }     
    history{
      status
      date
      zone{
        id
        bid
        store{
          id
          bid
        }
      }
    }
    photos{
      history{
        date
        status
      }
      status
      size
      price
    }
  }
}
`;

export default ORDER_DETAIL;