import React from 'react';
import { injectIntl } from 'react-intl';
import PageHeader from 'components-lib/PageHeader/PageHeader';
import { Grid } from '@material-ui/core';
import { useQuery } from '@apollo/react-hooks';
import { pathOr } from 'rambda';
import StoreCommissionTableCard from "./CommissionCards/StoreCommissionTableCard";
import Loading from "components-lib/Loading/Loading";
import EuroSymbolIcon from '@material-ui/icons/EuroSymbol';
import ImageIcon from '@material-ui/icons/Image';
import IconCard from "components-lib/IconCard/IconCard";
import IconCardOneItem from "components-lib/IconCard/IconCardOneItem";
import ALL_COMMISSION_STORES from 'queries/CommissionsQueries/allCommissionsStores';
import ALL_COMMISSIONS from 'queries/CommissionsQueries/allCommissions';

const CommissionDetailPage = (props) => {
    const { intl, history } = props;
    const commissionId = props.match.params.commissionId;

    const { data: companyCommissionsData, loading: companyCommissionsLoading } = useQuery(ALL_COMMISSIONS,
        {
            variables: {
                filter: {
                    id: {
                        eq: Number(commissionId)
                    }
                }
            }
        }
    )
    let data = pathOr([], ['companyCommissionsTable', 'items', [0]], companyCommissionsData)
    let companyId = pathOr('', ['companyId'], data)
    let period = pathOr('', ['period'], data).substring(0, 7);

    const getTitle = (subtitle) => {
        let year = period.substring(0, 4);
        let month = period.substring(5, 7);
        switch (month) {
            case '01': month = <span>{intl.formatMessage({ id: 'commissionsList.month.january' })} / {year}</span>; break;
            case '02': month = <span>{intl.formatMessage({ id: 'commissionsList.month.february' })} / {year}</span>; break;
            case '03': month = <span>{intl.formatMessage({ id: 'commissionsList.month.march' })} / {year}</span>; break;
            case '04': month = <span>{intl.formatMessage({ id: 'commissionsList.month.april' })} / {year}</span>; break;
            case '05': month = <span>{intl.formatMessage({ id: 'commissionsList.month.may' })} / {year}</span>; break;
            case '06': month = <span>{intl.formatMessage({ id: 'commissionsList.month.june' })} / {year}</span>; break;
            case '07': month = <span>{intl.formatMessage({ id: 'commissionsList.month.july' })} / {year}</span>; break;
            case '08': month = <span>{intl.formatMessage({ id: 'commissionsList.month.august' })} / {year}</span>; break;
            case '09': month = <span>{intl.formatMessage({ id: 'commissionsList.month.september' })} / {year}</span>; break;
            case '10': month = <span>{intl.formatMessage({ id: 'commissionsList.month.october' })} / {year}</span>; break;
            case '11': month = <span>{intl.formatMessage({ id: 'commissionsList.month.november' })} / {year}</span>; break;
            case '12': month = <span>{intl.formatMessage({ id: 'commissionsList.month.december' })} / {year}</span>; break;
            default: month = <span>{month}</span>;
        }
        return <>
            {(!subtitle)
                ? <>{intl.formatMessage({ id: 'commissionDetail.title' })} ({month})</>
                : <> {pathOr('', ['companyName'], data)} ({pathOr('', ['companyBid'], data)})</>
            }
        </>
    }
    const getTableTitle = () => {
        return <>
            {intl.formatMessage({ id: 'storeList.table.title' })}
            <span style={{ color: "gray", marginLeft: "4px", fontSize: ".7em" }}>
                ({pathOr('', ['storesCount'], data)} {intl.formatMessage({ id: 'commissionList.table.subtitle' })})
            </span>
        </>
    }

    const getBody = (body, unit) => {
        return <> {body} {unit}</>
    }

    if (companyCommissionsLoading) return <Loading />
    return (
        <>
            <PageHeader
                title={getTitle()}
                subTitle={getTitle(true)}
                handleBackAction={(e) => history.goBack()}
            />
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={6} lg={4}>
                    <IconCardOneItem
                        icon={<ImageIcon />}
                        headerText={intl.formatMessage({ id: 'commissionDetail.photo.body' })}
                        bodyText={getBody(pathOr('', ['photosCount'], data), intl.formatMessage({ id: 'tableTexts.piece' }))}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4}>
                    <IconCardOneItem
                        icon={<EuroSymbolIcon />}
                        headerText={intl.formatMessage({ id: 'commissionDetail.commission.body' })}
                        bodyText={getBody(pathOr('', ['value'], data), "€")}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={12} style={{ marginTop: "-50px" }}>
                    <IconCard
                        icon={<EuroSymbolIcon />}
                        title={getTableTitle()}
                    >

                        <StoreCommissionTableCard
                            queryDataPath={['zoneCommissionsTable', 'items']}
                            query={ALL_COMMISSION_STORES}
                            defaultFilter={
                                {
                                    companyId: {
                                        eq: Number(companyId)
                                    },
                                    period: {
                                        between: {
                                            start: period + "-01",
                                            end: period + "-31"
                                        }
                                    },
                                    interval: {
                                        eq: "Monthly"
                                    }
                                }
                            }
                            history={history}
                        />
                    </IconCard>
                </Grid>
            </Grid>
        </>
    );
};

export default injectIntl(CommissionDetailPage);