import gql from 'graphql-tag';

const COUPON_CAMPAIGN_DETAIL = gql`
query couponCampaign($id:ID!){
    couponCampaign(id: $id){
        id
        bid
        type
        name
        description
        validFrom
        validTo
        status
        createdAt  
        couponCounts{
            totalCount
            usedCount
            unusedCount    
        }
        budget{
          total
          free
          allocated
          used
          unused
        }
    		couponSets{
          couponCount
          couponNominalValue
          totalSetValue
          usedCouponsCount
          usedCouponsValue
        }
        createdBy{
            id
            fullName
        }
        account{
      		balance
      		allocated
    	}
        company{
            id
            bid
            name
            address
            postal
            city
            contactEmail
            contactPhone
            contactFirstName
            contactLastName 
            account{
                balance
            }        
        }
    }
}
`;

export default COUPON_CAMPAIGN_DETAIL;

