import * as Yup from 'yup';

export const validationSchema = (intl, credits) =>
    Yup.object().shape({
        credits: Yup.number()
            .min(1, intl.formatMessage({ id: 'validation.number.positive' }))
            .max(credits, intl.formatMessage({ id: 'validation.number.max' }, [credits]))
            .typeError(intl.formatMessage({ id: 'validation.number' })),

    });

