import gql from "graphql-tag";

const STORE_DETAIL = gql`
  query store($id: ID!) {
    store(id: $id) {
      id
      bid
      name
      segment
      contactFirstName
      contactLastName
      contactPhone
      contactEmail
      address
      postal
      latitude
      longtitude
      city
      country
      zones {
        id
        bid
        name
        area
        visitors
        rank
        createdAt
        pricingLevel {
          number
          surcharge
        }
        stats {
          photos {
            perMonthCount
            perMonthWarning
          }
        }
        updatedAt
        preferences {
          freePhotoLimit
          wifiSsid
          wifiPassword
          powerSavingMode
          freePhotoLimit
          isOnlyOwnCampaignAllowed
          isOpenedDuringHolidays
          forbiddenTopics {
            name
            id
          }
          activeTimes {
            weekDay
            from
            to
          }
          inactiveTimes {
            from
            to
          }
          playlist
          segment {
            id
            name
          }
        }
        device {
          id
          bid
          connection {
            mobile
            wifi
            wan
            error
          }
          onlineAt
          issue {
            id
            date
            code
            description
          }
          statusUpdatedAt
          printer {
            printedTotalPhotos
          }
          details {
            printedPhotos
            remainingPhotos
            photosPerMonth
            photosPerMonthWarning
          }
          isOnline
          onlineAt
          status
        }
      }

      company {
        id
        bid
        name
        ico
        dic
        icDPH
        registrationCourt
        address
        postal
        city
        country
        latitude
        longtitude
        bankName
        bankIBAN
        bankBIC
        contactFirstName
        contactLastName
        contactPhone
        contactEmail
        commissionRate
        createdAt
        updatedAt
        users {
          id
          firstName
          lastName
          phone
          email
        }
      }
    }
  }
`;

export default STORE_DETAIL;
