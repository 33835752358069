import React, { useEffect, useState } from "react";
import { injectIntl } from "react-intl";
import { withRouter } from "react-router-dom";
import CampaignTypeEnum from "common/enums/CampaignType";
import PageHeader from "components-lib/PageHeader/PageHeader";
import { Formik } from "formik";
import { Grid } from "@material-ui/core";
import { useMutation } from "@apollo/react-hooks";
import paths from "paths";
import Button from "components/CustomButtons/Button.js";
import InfoCard from './CampaignCards/NewInfoCard'
import BudgetCard from './CampaignCards/NewBudgetCard'
import NewGraficCard from './CampaignCards/NewGraficCard'
import { useSnackbar } from "notistack";
import { pathOr } from "rambda";
import { generatePath } from "react-router-dom";
import { getValidationSchemaNew } from './validationSchema';
import ImageIcon from '@material-ui/icons/Image';
import IconCard from "components-lib/IconCard/IconCard";
import CardMedia from '@material-ui/core/CardMedia';
import CAMPAIGN_CREATE from "queries/CampaignsQueries/campaignCreate"
import COUPON_CAMPAIGN_CREATE from "queries/CampaignsQueries/couponCampaignCreate"

const CampaignNewPage = (props) => {
    const { intl, history } = props
    const { enqueueSnackbar } = useSnackbar();

    const [campaignType, setCampaignType] = useState(null);
    const [companyId, setCompanyId] = useState(null);
    const [mediaId, setMediaId] = useState(null);
    const [mediaUrl, setMediaUrl] = useState(null);
    const [balance, setBalance] = useState(null);

    const [createCampaign] = useMutation(CAMPAIGN_CREATE);
    const [createCouponCampaign] = useMutation(COUPON_CAMPAIGN_CREATE);

    useEffect(() => {
        if (history.location.pathname.indexOf(CampaignTypeEnum.FREEPHOTO) > -1) {
            setCampaignType(CampaignTypeEnum.FREEPHOTO);
        }
        if (history.location.pathname.indexOf(CampaignTypeEnum.QR) > -1) {
            setCampaignType(CampaignTypeEnum.QR);
        }
        if (history.location.pathname.indexOf(CampaignTypeEnum.PQR) > -1) {
            setCampaignType(CampaignTypeEnum.PQR);
        }
    }, [history.location])

    const handleCreateCampaign = (values) => {
        if (campaignType === CampaignTypeEnum.FREEPHOTO) {
            createCampaign({
                variables: {
                    input: {
                        type: "FreePhoto",
                        name: values.campaign.name,
                        companyId: values.campaign.companyId,
                        description: values.campaign.description,
                        validFrom: values.campaign.validFrom,
                        validTo: values.campaign.validTo,
                        budget: values.campaign.budget,
                        maxBid: 10,
                        mediaIds: mediaId
                    }
                }
            }).then((response) => {
                const id = pathOr("", ["data", "campaignCreate", "id"], response);
                history.push({
                    pathname: `/admin${generatePath(paths.campaigns.FreePhotoDetail, { campaignId: id })}`,
                    state: CampaignTypeEnum.FREEPHOTO
                })
                enqueueSnackbar(intl.formatMessage({ id: 'campaign.new.successCreated' }), { variant: 'success' });
            }).catch((err) => {
                console.log('[error]', err);
            });
        } else if (campaignType === CampaignTypeEnum.QR || campaignType === CampaignTypeEnum.PQR) {
            let sourceType = (campaignType === CampaignTypeEnum.QR) ? "Qr" : "PublicQr"
            createCouponCampaign({
                variables: {
                    input: {
                        type: sourceType,
                        name: values.campaign.name,
                        companyId: values.campaign.companyId,
                        description: values.campaign.description,
                        validFrom: values.campaign.validFrom,
                        validTo: values.campaign.validTo,
                        budget: Number(values.campaign.budget),
                    }
                }
            }).then((response) => {
                const id = pathOr("", ["data", "couponCampaignCreate", "id"], response);
                if (campaignType === CampaignTypeEnum.QR)
                    history.push({
                        pathname: `/admin${generatePath(paths.campaigns.QRDetail, { campaignId: id })}`,
                        state: CampaignTypeEnum.QR
                    })
                else
                    history.push({
                        pathname: `/admin${generatePath(paths.campaigns.PQRDetail, { campaignId: id })}`,
                        state: CampaignTypeEnum.PQR
                    })
                enqueueSnackbar(intl.formatMessage({ id: 'campaign.new.successCreated' }), { variant: 'success' });
            }).catch((err) => {
                console.log('[error]', err);
            });
        } else {
            console.log("[error]")
        }

    };

    const getTitle = () => {
        if (campaignType === CampaignTypeEnum.FREEPHOTO) return intl.formatMessage({ id: "campaigns.new.freeFoto.title" })
        if (campaignType === CampaignTypeEnum.QR) return intl.formatMessage({ id: "campaigns.new.qr.title" })
        if (campaignType === CampaignTypeEnum.PQR) return intl.formatMessage({ id: "campaigns.new.pqr.title" })
    }

    return (
        <>
            <PageHeader
                title={getTitle()}
                handleBackAction={(e) => history.goBack()}
            />

            <Formik
                initialValues={{}}
                onSubmit={(values) => { handleCreateCampaign(values); }}
                validationSchema={getValidationSchemaNew(intl, balance)}
            >
                {(formikProps) =>
                    <>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={12} md={6} lg={5}>

                                <InfoCard
                                    data={formikProps.values}
                                    formikProps={formikProps}
                                    callbackCompanyId={(newValue) => { setCompanyId(newValue); }}
                                />
                                <BudgetCard
                                    data={formikProps.values}
                                    formikProps={formikProps}
                                    companyId={companyId}
                                    campaignType={campaignType}
                                    callbackBalance={(newValue) => { setBalance(newValue); }}

                                />
                            </Grid>
                            {campaignType === CampaignTypeEnum.FREEPHOTO ?
                                (companyId)
                                    ?
                                    <>
                                        <Grid item xs={12} sm={12} md={6} lg={5}>
                                            <NewGraficCard
                                                companyId={companyId}
                                                callbackMediaId={(newValue) => { setMediaId(newValue); }}
                                                callbackMediaUrl={(newValue) => { setMediaUrl(newValue); }}
                                            />
                                        </Grid>
                                        {mediaId ?
                                            <Grid item xs={12} sm={12} md={6} lg={2}>
                                                <IconCard
                                                    title={intl.formatMessage({ id: 'campaigns.pictureCard.title' })}
                                                    icon={<ImageIcon />}
                                                >
                                                    <Grid container>
                                                        <Grid item sm={12}>
                                                            <CardMedia
                                                                component="img"
                                                                alt={mediaUrl}
                                                                image={mediaUrl}
                                                                title={mediaUrl}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </IconCard>
                                            </Grid>
                                            : ""}
                                    </>
                                    : ""
                                : ""
                            }
                        </Grid>
                        <Button
                            disabled={(campaignType === CampaignTypeEnum.FREEPHOTO)
                                ? (!mediaId || !companyId || !formikProps.values.campaign.name || !formikProps.values.campaign.validFrom
                                    || !formikProps.values.campaign.validTo || !formikProps.values.campaign.budget)
                                : (!companyId || !formikProps.values.campaign.name || !formikProps.values.campaign.validFrom
                                    || !formikProps.values.campaign.validTo || !formikProps.values.campaign.budget)}
                            color="primary"
                            onClick={(e) => {
                                formikProps.handleSubmit();
                            }}
                        >
                            {intl.formatMessage({ id: 'campaigns.new.createButton' })}
                        </Button>
                        <Button
                            onClick={() =>
                                campaignType === CampaignTypeEnum.FREEPHOTO
                                    ? history.push(`/admin${generatePath(paths.campaigns.FreePhoto)}`)
                                    : history.push(`/admin${generatePath(paths.campaigns.Coupon)}`)
                            }
                        >
                            {intl.formatMessage({ id: 'settings.cancel' })}
                        </Button>
                    </>
                }
            </Formik>
        </>
    )
}

export default injectIntl(withRouter(CampaignNewPage))