import { useMutation, useQuery } from "@apollo/react-hooks";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "components-lib/Modal/Modal";
import PageHeader from "components-lib/PageHeader/PageHeader";
import Button from "components/CustomButtons/Button.js";
import { useSnackbar } from "notistack";
import paths from "paths";
import { pathOr } from "rambda";
import { default as React, useEffect, useState } from "react";
import { injectIntl } from "react-intl";
import { generatePath, withRouter } from "react-router-dom";
import styles from "common/styles/widgets.js";
import DetailStoreCard from "./StoreCards/DetailStoreCard";
import DetailCampaignCard from "./StoreCards/DetailCampaignCard";
import DetailCompanyCard from "./StoreCards/DetailCompanyCard";
import DetailMaintainerCard from "./StoreCards/DetailMaintainerCard";
import DetailAddressCard from "./StoreCards/DetailAddressCard";
import DetailCommissionCard from "./StoreCards/DetailCommissionCard";
import TimePlanCard from "./StoreCards/TimePlanCard";
import DetailPhotosChart from "./StoreCards/DetailPhotosChart";
import OrderTable from "../Orders/OrderTable";
import Loading from "components-lib/Loading/Loading";
import DetailDeviceStatus from "./StoreCards/DetailDeviceStatus";
import { getDetailValidationSchema } from "./validationSchema";
import { Formik } from "formik";
import ShoppingBasketIcon from "@material-ui/icons/ShoppingBasket";
import IconCard from "components-lib/IconCard/IconCard";
import RoleEnum from "common/enums/RoleEnum";
import { isInRoles } from "helpers/helpers";
import moment from "moment";
import DEVICE_REMOVE_ZONE from "queries/DevicesQueries/deviceRemoveZone";
import STORE_DELETE_MUTATION from "queries/StoresQueries/storeDelete";
import STORE_DETAIL from "queries/StoresQueries/storeDetail";
import STORE_STATS from "queries/StoresQueries/storeStats";
import STORE_UPDATE from "queries/StoresQueries/storeUpdate";
import ORDERS_BY_ZONE from "queries/StoresQueries/ordersByZone";
import DEVICES_QUERY from "queries/DevicesQueries/getDevices";

const useStyles = makeStyles((theme) => styles(theme));

const StoreDetailPage = (props) => {
  const { intl, history } = props;
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const storeId = props.match.params.storeId;

  const {
    data: storeData,
    loading,
    refetch: refetchData,
  } = useQuery(STORE_DETAIL, { variables: { id: storeId } });

  // Hooks
  const [removePlayerOpen, setRemovePlayerOpen] = useState(false);
  const [removeStoreOpen, setRemoveStoreOpen] = useState(false);
  const [formikStoreData, setFormikStoreData] = useState({});
  const [deviceId, setDeviceId] = useState(null);
  const [city, setCity] = useState(null);
  const [forbiddenTopics, setForbiddenTopics] = useState([]);
  const [filterOrders, setFilterOrders] = useState([]);
  const [times, setTimes] = useState(
    pathOr("", ["store", "zones", [0], "preferences", "activeTimes"], storeData)
  );
  const [inactiveTimes, setInactiveTimes] = useState([]);

  //Queries and Mutations
  const { data: dataOrders } = useQuery(ORDERS_BY_ZONE(storeId));
  const [deviceRemoveZone] = useMutation(DEVICE_REMOVE_ZONE);
  const [storeDelete] = useMutation(STORE_DELETE_MUTATION);
  const [updateStore] = useMutation(STORE_UPDATE);

  const { data: storeStats } = useQuery(STORE_STATS, {
    variables: { id: storeId },
  });
  const [availableDevices, setAvailableDevices] = useState({});
  const { data: devicesData, refetch: refetchDevice } = useQuery(
    DEVICES_QUERY,
    {
      variables: {
        filter: [
          {
            zone: {
              isNull: true,
            },
          },
        ],
      },
    }
  );

  useEffect(() => {
    let deviceSelectItems = pathOr([], "devices.items", devicesData).map(
      (device) => {
        return {
          value: pathOr("", "id", device),
          label: `${pathOr("", "id", device)} - ${pathOr("", "bid", device)}`,
        };
      }
    );
    setAvailableDevices(deviceSelectItems);

  }, [devicesData]);

  //Mount component use effect
  useEffect(() => {
    let formikHelp = pathOr({}, ["store"], storeData);
    if (formikHelp?.zones?.[0]?.preferences?.playlist === null) {
      formikHelp.zones[0].preferences.playlist = "without";
    }
    setFormikStoreData(formikHelp);
    setCity(pathOr("", ["store", "city"], storeData));
    setDeviceId(pathOr("", ["store", "zones", [0], "device", "id"], storeData));
    setTimes(
      pathOr(
        "",
        ["store", "zones", [0], "preferences", "activeTimes"],
        storeData
      )
    );
    setInactiveTimes(
      Object.values(
        pathOr(
          "",
          ["store", "zones", [0], "preferences", "inactiveTimes"],
          storeData
        )
      )?.map((item) => ({
        from: moment(item?.from),
        to: moment(item?.to),
      }))
    );
    setForbiddenTopics(
      Object.values(
        pathOr(
          "",
          ["store", "zones", [0], "preferences", "forbiddenTopics"],
          storeData
        )
      )?.map((item) => ({
        value: item.id,
        label: item.name,
      }))
    );
  }, [storeData]);
  useEffect(() => {
    let ordersSelectItems = pathOr(
      [],
      "ordersTableByZone.items",
      dataOrders
    ).map((order) => {
      // TODO: ordersTableByStore
      return Number(order.id);
    });
    setFilterOrders(ordersSelectItems);
  }, [dataOrders]);

  //Detail Page Head functionality
  const getStoreBid = () => {
    return (
      <>
        {intl.formatMessage({ id: "storeDetail.title" })} &nbsp;
        {pathOr("", ["store", "bid"], storeData)}
      </>
    );
  };


  /**
   * MUTATIONS
   */
  //Update store mutation
  const handleUpdateStore = (values) => {
    const result = { ...values };
    delete result.id;
    delete result.bid;
    delete result.segment;
    delete result.zones;
    delete result.latitude;
    delete result.longtitude;
    delete result.company;
    delete result.__typename;

    let setForbiddenTopicsIds = forbiddenTopics.map((topic) => topic.value);
    let setCityLabel = city.label;

    for (let i = 0; i < times.length; i++) {
      if (times[i].__typename) delete times[i].__typename;
    }

    let setInTimes = [];
    for (let i = 0; i < inactiveTimes.length; i++) {
      if (
        inactiveTimes[i]?.from !== undefined ||
        inactiveTimes[i]?.to !== undefined
      )
        setInTimes.push({
          from: inactiveTimes[i]?.from,
          to: inactiveTimes[i]?.to,
        });
    }

    updateStore({
      variables: {
        id: values.id,
        input: {
          ...result,
          contactFirstName: values.contactFirstName,
          contactLastName: values.contactLastName,
          contactPhone: values.contactPhone,
          contactEmail: values.contactEmail,
          latitude: Number(values.latitude),
          longtitude: Number(values.longtitude),
          city: (values.city?.label) ? values?.city?.label : undefined,
          pricingLevelNumber: (values.zones[0].pricingLevel.number) ? values.zones[0].pricingLevel.number : undefined,
          preferences: {
            forbiddenTopicIds: setForbiddenTopicsIds,
            isOnlyOwnCampaignAllowed:
              values.zones[0].preferences.isOnlyOwnCampaignAllowed,
            segmentId: values.zones[0].preferences.segment?.id
              ? Number(values.zones[0].preferences.segment.id)
              : undefined,
            activeTimes: times,
            inactiveTimes: setInTimes,
            powerSavingMode: values.zones[0].preferences.powerSavingMode,
            wifiSsid: values.zones[0].preferences.wifiSsid,
            wifiPassword: values.zones[0].preferences.wifiPassword,
            freePhotoLimit: values.zones[0].preferences.freePhotoLimit
              ? Number(values.zones[0].preferences.freePhotoLimit)
              : undefined,

            isOpenedDuringHolidays:
              values.zones[0].preferences.isOpenedDuringHolidays,
            playlist:
              values.zones[0].preferences.playlist === "without"
                ? null
                : values.zones[0].preferences.playlist,
          },
        },
      },
    })
      .then((response) => {
        enqueueSnackbar(
          intl.formatMessage({ id: "storeDetail.store.updatedSuccessful" }),
          { variant: "success" }
        );
        refetchData();
      })
      .catch((err) => {
        console.log("[error]", err);
      });
  };

  //Remove device from store mutation
  const removeDevice = () => {
    deviceRemoveZone({
      variables: {
        id: deviceId,
      },
    })
      .then((response) => {
        enqueueSnackbar(
          intl.formatMessage({ id: "storeDetail.removePlayer.successful" }),
          { variant: "success" }
        );
        setRemovePlayerOpen(false);
        refetchData();
        refetchDevice();
      })
      .catch((err) => {
        console.log("[error]", err);
      });
  };

  //Delete store mutation
  const deleteStore = () => {
    storeDelete({
      variables: {
        id: storeId,
      },
    })
      .then((response) => {
        history.push(`/admin${generatePath(paths.stores.list)}`);
        enqueueSnackbar(
          intl.formatMessage({ id: "storeDetail.removeStore.successful" }),
          { variant: "success" }
        );
      })
      .catch((err) => {
        console.log("[error]", err);
      });
  };

  // Headers Buttons
  const getActions = () => {
    if (!isInRoles([RoleEnum.ADMIN, RoleEnum.MAINTAINER])) return;

    let action = pathOr("", ["store", "zones", [0], "device"], storeData)
      ? [
        {
          title: intl.formatMessage({ id: "storeDetail.removePlayer.title" }),
          onClick: () => setRemovePlayerOpen(true),
        },
        {
          title: intl.formatMessage({ id: "storeDetail.removeStore.title" }),
          onClick: () => setRemoveStoreOpen(true),
          color: "danger",
        },
      ]
      : [
        {
          title: intl.formatMessage({ id: "storeDetail.removeStore.title" }),
          onClick: () => setRemoveStoreOpen(true),
          color: "danger",
        },
      ];
    return action;
  };

  //Remove Player Buttons
  const renderRemovePlayerActions = () => {
    return (
      <>
        <Button onClick={() => removeDevice()} color="warning" round size="sm">
          {intl.formatMessage({ id: "storeDetail.device.remove" })}
        </Button>

        <Button
          onClick={() => setRemovePlayerOpen(false)}
          color="primary"
          round
          size="sm"
        >
          {intl.formatMessage({ id: "companyList.addModal.cancel" })}
        </Button>
      </>
    );
  };

  //Remove Store Buttons
  const renderRemoveStoreActions = () => {
    return (
      <>
        <Button onClick={() => deleteStore()} color="danger" round size="sm">
          {intl.formatMessage({ id: "companyDetail.btn.remove" })}
        </Button>
        <Button
          onClick={() => setRemoveStoreOpen(false)}
          color="primary"
          round
          size="sm"
        >
          {intl.formatMessage({ id: "companyList.addModal.cancel" })}
        </Button>
      </>
    );
  };

  const renderOrdersButtons = () => {
    return (
      <>
        <hr />
        <Button
          disabled={filterOrders.length <= 0}
          color="info"
          size="sm"
          round
          className={classes.floatRight}
          onClick={() =>
            history.push({
              pathname: `/admin${generatePath(paths.orders.list)}`,
              state: { id: { in: filterOrders } },
            })
          }
        >
          {intl.formatMessage({ id: "storeDetail.partnerCard.allOrders" })}
        </Button>
      </>
    );
  };

  if (loading) return <Loading />;
  return (
    <>
      <Formik
        onSubmit={(values) => {
          handleUpdateStore(values);
        }}
        enableReinitialize
        initialValues={formikStoreData}
        validationSchema={getDetailValidationSchema(intl)}
      >
        {(formikProps) => (
          <>
            <PageHeader
              title={pathOr("", ["store", "name"], storeData)}
              subTitle={getStoreBid()}
              actions={getActions()}
              handleBackAction={(e) => history.goBack()}
            />
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={4}>
                <DetailStoreCard
                  data={formikProps.values}
                  formikProps={formikProps}
                  storeData={storeData}
                  city={city}
                  callbackCity={(newValue) => setCity(newValue)}
                />
                <DetailMaintainerCard
                  data={formikProps.values}
                  formikProps={formikProps}
                  storeData={storeData}
                />
                {/*<DetailAddressCard
                      data={formikProps.values}
                      formikProps={formikProps}
                      storeData={storeData}
                      city={city}
                      callbackCity={(newValue) => setCity(newValue)}
                    />*/}
                <DetailCampaignCard
                  data={formikProps.values}
                  formikProps={formikProps}
                  storeData={storeData}
                  forbidden={forbiddenTopics}
                  callbackForbidden={(newValue) => {
                    setForbiddenTopics(newValue);
                  }}
                />

              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={8}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={12} lg={6}>
                    <DetailDeviceStatus
                      formikProps={formikProps}
                      deviceId={pathOr(
                        "",
                        ["store", "zones", [0], "device", "id"],
                        storeData
                      )}
                      deviceBid={pathOr(
                        "",
                        ["store", "zones", [0], "device", "bid"],
                        storeData
                      )}
                      zoneId={pathOr(
                        "",
                        ["store", "zones", [0], "id"],
                        storeData
                      )}
                      issue={pathOr(
                        false,
                        ["store", "zones", [0], "device", "issue"],
                        storeData
                      )}
                      status={pathOr(
                        "",
                        ["store", "zones", [0], "device", "status"],
                        storeData
                      )}
                      lastHeartbeat={pathOr(
                        "",
                        ["store", "zones", [0], "device", "onlineAt"],
                        storeData
                      )}
                      isAssigned={pathOr(
                        "",
                        ["store", "zones", [0], "device"],
                        storeData
                      )}
                      refetchData={refetchData}
                      remainingPhotos={pathOr(
                        "",
                        [
                          "store",
                          "zones",
                          [0],
                          "device",
                          "details",
                          "remainingPhotos",
                        ],
                        storeData
                      )}
                      remainingPhotosWarning={pathOr(
                        "",
                        [
                          "store",
                          "zones",
                          [0],
                          "device",
                          "details",
                          "remainingPhotosWarning",
                        ],
                        storeData
                      )}
                      printedPhotos={pathOr(
                        "",
                        [
                          "store",
                          "zones",
                          [0],
                          "device",
                          "printer",
                          "printedTotalPhotos",
                        ],
                        storeData
                      )}
                      photosPerMonth={pathOr(
                        "",
                        [
                          "store",
                          "zones",
                          [0],
                          "stats",
                          "photos",
                          "perMonthCount",
                        ],
                        storeData
                      )}
                      photosPerMonthWarning={pathOr(
                        "",
                        [
                          "store",
                          "zones",
                          [0],
                          "stats",
                          "photos",
                          "perMonthWarning",
                        ],
                        storeData
                      )}
                      availableDevices={availableDevices}
                      refetchDevice={refetchDevice}
                      data={formikProps}
                    />
                    <TimePlanCard
                      times={times}
                      callbackTimes={(newValue) => setTimes(newValue)}
                      data={formikProps.values}
                      formikProps={formikProps}
                      storeData={storeData}
                      inactiveTimes={inactiveTimes}
                      callbackInactiveTimes={(newValue) =>
                        setInactiveTimes(newValue)
                      }
                      storeId={storeId}
                      storeUpdate={true}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={6} >
                    <DetailCommissionCard
                      storeBid={pathOr("", ["store", "bid"], storeData)}
                      history={history}
                    />
                    

                    {!isInRoles([RoleEnum.ADMIN, RoleEnum.MAINTAINER]) ? (
                      ""
                    ) : (
                      <DetailPhotosChart
                        storeBid={pathOr("", ["store", "bid"], storeData)}
                        history={history}
                        allPhotos={pathOr(
                          "",
                          ["storeStats", "printedPhotos"],
                          storeStats
                        )}
                      />

                    )}
                  </Grid>

                </Grid>

                {!isInRoles([RoleEnum.ADMIN, RoleEnum.MAINTAINER]) ? (
                  ""
                ) : (
                  <div style={{ position: "fixed", bottom: "20px", right: "20px", zIndex: 1000 }} >

                    <Button
                      color="primary"
                      onClick={(e) => {
                        formikProps.handleSubmit();
                      }}
                    >
                      
                      {intl.formatMessage({ id: "userDetail.buttons.confirm" })}
                    </Button>
                    <Button onClick={() => formikProps.resetForm()}>
                      {intl.formatMessage({ id: "settings.cancel" })}
                    </Button>
                  </div>
                )}
              </Grid>
              {!isInRoles([RoleEnum.ADMIN, RoleEnum.MAINTAINER]) ? (
                ""
              ) : (
                <Grid item xs={12} sm={12} md={12} lg={12} style={{ marginTop: "-47px" }}>
                  <IconCard
                    title={intl.formatMessage({
                      id: "orderList.table.title",
                    })}
                    icon={<ShoppingBasketIcon />}
                  >
                    <OrderTable
                      history={history}
                      query={ORDERS_BY_ZONE(storeId)}
                      queryDataPath={["ordersTableByZone", "items"]}
                      numberOfRows={10}
                    />
                    {renderOrdersButtons()}
                  </IconCard>
                </Grid>
              )}
            </Grid>
            <Modal
              title={intl.formatMessage({
                id: "storeDetail.removePlayer.title",
              })}
              open={removePlayerOpen}
              onClose={() => setRemovePlayerOpen(false)}
              actions={renderRemovePlayerActions()}
            >
              {`${intl.formatMessage({
                id: "deviceDetail.status.removeModal.text-1",
              })} 
                            ${deviceId} ${intl.formatMessage({
                id: "deviceDetail.status.removeModal.text-2",
              })}
                            ${pathOr("", "store.name", storeData)} ${pathOr(
                "",
                "store.bid",
                storeData
              )}?`}
            </Modal>
            <Modal
              title={intl.formatMessage({
                id: "storeDetail.removeStore.title",
              })}
              open={removeStoreOpen}
              onClose={() => setRemoveStoreOpen(false)}
              actions={renderRemoveStoreActions()}
            >
              {`${intl.formatMessage({ id: "storeDetail.removeStore.text" })} 
                            ${pathOr("", "store.name", storeData)} ${pathOr(
                "",
                "store.bid",
                storeData
              )}?`}
            </Modal>
          </>
        )}
      </Formik>
    </>
  );
};

export default injectIntl(withRouter(StoreDetailPage));
