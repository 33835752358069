import React, { useState, useEffect } from "react";
import { injectIntl } from "react-intl";
import { useLocation } from 'react-router-dom';
import { Grid } from "@material-ui/core";
import { pathOr } from "rambda";
import { Formik } from "formik";
import { isInRoles } from "helpers/helpers";
import RoleEnum from "common/enums/RoleEnum";
import styles from "common/styles/widgets.js";
import EuroSymbolIcon from '@material-ui/icons/EuroSymbol';
import IconCard from "components-lib/IconCard/IconCard";
import PageHeader from "components-lib/PageHeader/PageHeader";
import CommissionTable from "./CommissionTable";
import Modal from "components-lib/Modal/Modal";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import AutocompleteSelect from "components-lib/AutocompleteSelect/AutocompleteSelect";
import Button from "components/CustomButtons/Button.js";
import { useMutation, useQuery } from "@apollo/react-hooks";
import moment from "moment";
import { DatePicker } from "@material-ui/pickers";
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { makeStyles } from '@material-ui/core/styles';
import DateFnsUtils from '@date-io/date-fns';
import { useSnackbar } from 'notistack'
import COMMISSIONS_EXPORT from "queries/CommissionsQueries/commissionsExport"
import ALL_COMMISSIONS from 'queries/CommissionsQueries/allCommissions';
import SELECT_COMPANIES from "queries/CompaniesQueries/selectCompanies"

const useStyles = makeStyles((theme) => styles(theme))

const CommissionListPage = (props) => {
  const { intl, history } = props;
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  let location = useLocation();
  let defaultFilter = location.state ? location.state : null

  const [exportInput] = useMutation(COMMISSIONS_EXPORT);
  const { data: companiesData } = useQuery(SELECT_COMPANIES)

  const [assignModalOpen, setAssignModalOpen] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [companyIds, setCompanyIds] = useState(null);
  const [userData] = useState({});
  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);

  useEffect(() => {
    let companiesSelectItems = pathOr([], "companies.items", companiesData).map(
      (company) => {
        return {
          value: pathOr("", "id", company),
          label: `${pathOr("", "name", company)}`,
        };
      }
    );
    setCompanies(companiesSelectItems);
  }, [companiesData]);

  const getActions = () => {
    if (!isInRoles([RoleEnum.ADMIN, RoleEnum.MAINTAINER]))
      return;
    return [
      {
        title: intl.formatMessage({ id: "commissionsList.addModal.title" }),
        onClick: () => { setAssignModalOpen(true) },
      },
    ];
  };

  const handleExportData = (values) => {
    let start = ""
    let end = ""
    let companies = companyIds?.map(id => id.value);
    if (dateFrom) {
      if ((moment(dateFrom).month() + 1) < 10) start = moment(dateFrom).year() + "-0" + (moment(dateFrom).month() + 1) + "-01T00:00"
      else start = moment(dateFrom).year() + "-" + (moment(dateFrom).month() + 1) + "-01T00:00"
    } else {
      start = null
    }

    if (dateTo) {
      if ((moment(dateTo).month() + 1) < 10) end = moment(dateTo).year() + "-0" + (moment(dateTo).month() + 1) + "-31T23:59"
      else end = moment(dateTo).year() + "-" + (moment(dateTo).month() + 1) + "-31T23:59"
    } else {
      end = null
    }

    exportInput({
      variables:
      {
        input: {
          companyIds: companies,
          periodFrom: start,
          periodTo: end
        }
      }
    })
      .then((response) => {
        window.open(response.data.companyCommissionsExport.url);
        enqueueSnackbar(intl.formatMessage({ id: 'commissionsList.export.success' }), { variant: "success" });
        setAssignModalOpen(false)
        setCompanyIds(null)
        setDateFrom(null)
        setDateTo(null)
      })
      .catch((err) => {
        console.log("[error]", err)
      });

  };

  const handleAssignedModalOpen = () => {
    setAssignModalOpen(false)
    setCompanyIds(null)
    setDateFrom(null)
    setDateTo(null)
  }

  const renderAssignActions = (formikProps) => {
    return (
      <>
        <Button
          size="sm"
          color="success"
          onClick={(e) => formikProps.handleSubmit()}
          round
        >
          {intl.formatMessage({ id: "commissionsList.addModal.title" })}
        </Button>
        <Button
          size="sm"
          color="primary"
          onClick={() => { handleAssignedModalOpen() }}
          round
        >
          {intl.formatMessage({ id: "invoicesList.assignModal.cancel" })}
        </Button>
      </>
    );
  };

  return (
    <>
      <PageHeader
        title={intl.formatMessage({ id: "commissionsList.title" })}
        withBackButton={false}
        actions={getActions()}
      />
      <Formik
        initialValues={userData}
        onSubmit={(values) => { handleExportData(values) }}
      >
        {(formikProps) => (
          <Modal
            title={intl.formatMessage({ id: "commissionsList.assignModal.title" })}
            open={assignModalOpen}
            onClose={() => setAssignModalOpen(false)}
            actions={renderAssignActions(formikProps)}
          >
            <>
              <AutocompleteSelect
                style={{ marginBottom: "15px" }}
                placeholder={intl.formatMessage({ id: "companyList.assignModal.company" })}
                label={intl.formatMessage({ id: "companyList.table.company" })}
                isMulti
                options={companies}
                onChange={(e) => setCompanyIds(e)}
                value={companyIds}
                input={<Input />}
              />
              <br /><br />
              <Grid container spacing={3}>
                <Grid item sm={6}>
                  <FormControl fullWidth>
                    <span style={{ color: "gray" }}>{intl.formatMessage({ id: "commissionsList.assignModal.pickerStart" })}
                      {(dateFrom) &&
                        <span className={classes.pointer} onClick={() => setDateFrom(null)} style={{ color: "red" }}>
                          ({intl.formatMessage({ id: "campaigns.removeModal.no" })})
                        </span>
                      }
                    </span>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <DatePicker
                        views={["year", "month"]}
                        clearable
                        value={dateFrom}
                        onChange={setDateFrom}
                        helperText={intl.formatMessage({ id: "commissionsList.assignModal.monthFrom" })}
                      />
                    </MuiPickersUtilsProvider>
                  </FormControl>
                </Grid>
                <Grid item sm={6}>
                  <FormControl fullWidth>
                    <span style={{ color: "gray" }}>{intl.formatMessage({ id: "commissionsList.assignModal.pickerEnd" })}
                      {(dateTo) &&
                        <span className={classes.pointer} onClick={() => setDateTo(null)} style={{ color: "red" }}>
                          ({intl.formatMessage({ id: "campaigns.removeModal.no" })})
                        </span>
                      }
                    </span>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <DatePicker
                        views={["year", "month"]}
                        clearable
                        value={dateTo}
                        onChange={setDateTo}
                        helperText={intl.formatMessage({ id: "commissionsList.assignModal.monthTo" })}
                      />
                    </MuiPickersUtilsProvider>
                  </FormControl>
                </Grid>
              </Grid>
            </>
          </Modal>
        )}
      </Formik>

      <IconCard
        icon={<EuroSymbolIcon />}
        title={intl.formatMessage({ id: "commissionsList.table.title" })}
      >
        <CommissionTable
          queryDataPath={['companyCommissionsTable', 'items']}
          query={ALL_COMMISSIONS}
          defaultFilter={defaultFilter}
          history={history}
        />
      </IconCard>
    </>
  );
};
export default injectIntl(CommissionListPage);