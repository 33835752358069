import gql from 'graphql-tag';

const ALL_STORES = gql`
query storesTable($offset: Int, $limit: Int, $sort: [StoresTableSortInput!], $filter: [StoresTableFilterInput]){
  storesTable(offset:$offset, limit: $limit, sort: $sort, filter: $filter){
    totalCount,
    items{
      id
      bid
      name
      segment
      pricingLevel
      address
      company
      status        
    }
  }
}
`;

export default ALL_STORES;