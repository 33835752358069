import gql from 'graphql-tag';

const CREATE_NOTIFICATION = gql`
    mutation createNotification($input: NotificationSendInput!) {
        notificationSend(input: $input) {
            id
        }
    }
`;

export default CREATE_NOTIFICATION;