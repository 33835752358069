import React from "react";
import Datetime from "react-datetime";
import CustomInput from "components/CustomInput/CustomInput";
import { pathOr } from "rambda";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import styles from "common/styles/widgets.js";
import selectStyles from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import FormHelperText from "@material-ui/core/FormHelperText";
import CustomCheckbox from "../CustomCheckbox/CustomCheckbox";
import Switch from "@material-ui/core/Switch";
import Box from "@material-ui/core/Box";
import { withStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import Slider from "@material-ui/core/Slider";
import { ThemeProvider } from "@material-ui/styles";
import Input from "@material-ui/core/Input";
import { createMuiTheme } from "@material-ui/core/styles";
import { colors } from "common/styles/configLayout.js";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import {
  KeyboardDatePicker,
  DateTimePicker,
  DatePicker,
} from "@material-ui/pickers";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import ChipInput from "material-ui-chip-input";
import TextPlaceholder from "./TextPlaceholder";
import { MentionsInput, Mention } from "react-mentions";
import Linkify from "./Linkify";
import "./styles.css";
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { Typography } from "@material-ui/core";


const materialTheme = createMuiTheme({
  palette: {
    primary: {
      main: colors.main,
    },
  },
});

const MainSwitch = withStyles({
  switchBase: {
    color: colors.main,
    "&$checked": {
      color: colors.main,
    },
    "&$checked + $track": {
      backgroundColor: colors.main,
    },
  },
  checked: {},
  track: {},
})(Switch);

const useStyles = makeStyles((theme) => styles(theme));
const useSelectStyles = makeStyles(selectStyles);

const FormikField = ({
  value,
  formControlProps = null,
  inputProps = null,
  formikProps = null,
  name = undefined,
  type = "text",
  options = [],
  labelText = undefined,
  highlightChange = true,
  helperText = undefined,
  disabled = false,
  placeholder = false,
  checkedLabelText = true,
  uncheckedLabelText = false,
  body = null,
  defaultValue = null,
  minValueSlider,
  dateFormat = "yyyy/MM/dd",
  maxValueSlider,
  defaultValueSlider,
  marks = [
    {
      value: minValueSlider,
      label: <p style={{ paddingTop: "5px" }}>0</p>,
    },
    {
      value: 20,
      label: <p style={{ paddingTop: "5px" }}>20</p>,
    },
    {
      value: 30,
      label: <p style={{ paddingTop: "5px" }}>30</p>,
    },
    {
      value: 40,
      label: <p style={{ paddingTop: "5px" }}>40</p>,
    },
    {
      value: defaultValueSlider,
      label: <p style={{ paddingTop: "5px" }}>50</p>,
    },
    {
      value: maxValueSlider - 1,
      label: <p style={{ paddingTop: "5px" }}>100</p>,
    },
  ],
  valueLabel,
  icon = false,
  ...rest
}) => {
  const { values, setFieldValue, errors } = formikProps;
  const classes = useStyles();
  const selectclasses = useSelectStyles();

  const formikFieldClasses = makeStyles(styles)();

  const originalValue = pathOr(
    "",
    ["initialValues", ...name.split(".")],
    formikProps
  );
  const currentValue = pathOr("", name.split("."), values);
  const inputStyles =
    highlightChange && originalValue.toString() !== currentValue.toString()
      ? { backgroundColor: "#FFF8CC" }
      : {};
  const inputSelectedStyles =
    highlightChange && originalValue.toString() !== currentValue.toString()
      ? { marginTop: "22px", backgroundColor: "#FFF8CC" }
      : {};

  const helperTextPreferError =
    pathOr("", name.split("."), errors) || helperText;

  if (pathOr(null, name.split("."), values) === "") {
    formikProps.setFieldValue(name, null);
  }

  const [item, setItem] = React.useState(pathOr("", name, values));
  const [disabledField, setDisabledField] = React.useState(disabled);

  React.useEffect(() => {
    setDisabledField(disabled);
  });

  const checkbox = (
    <CustomCheckbox
      checked={pathOr(false, name.split("."), values)}
      label={labelText}
      onChange={() =>
        setFieldValue(name, !pathOr(false, name.split("."), values))
      }
    />
  );

  const textField = (
    <CustomInput
      type="text"
      name={name}
      formControlProps={{
        fullWidth: true,
        ...formControlProps,
      }}
      inputProps={{
        value: pathOr("", name.split("."), values),
        onChange: (e) => setFieldValue(name, e.target.value),
        style: inputStyles,
        disabled: disabled,
        ...inputProps,
      }}
      helperText={helperTextPreferError}
      error={!!pathOr(false, name.split("."), errors)} //MILAN edited
      labelText={labelText}
      defaultValue={defaultValue}
      {...rest}
      body={body}
    // labelProps={{
    //    shrink: (placeholder) ? true : false,
    // }}
    />
  );


  const textArea = (
    <CustomInput
      disabled={disabled}
      type="text"
      name={name}
      formControlProps={{
        fullWidth: true,
        ...formControlProps,
      }}
      inputProps={{
        value: pathOr("", name.split("."), values),
        onChange: (e) => setFieldValue(name, e.target.value),
        style: inputStyles,
        multiline: true,
        ...inputProps,
      }}
      helperText={helperTextPreferError}
      error={!!pathOr(false, name.split("."), errors)}
      labelText={labelText}
      {...rest}
    />
  );

  const textPlaceholder = (
    <TextPlaceholder
      disabled={disabled}
      type="text"
      name={name}
      formControlProps={{
        fullWidth: true,
        ...formControlProps,
      }}
      callback={(newValue) => setFieldValue(name, newValue)}
      inputProps={{
        value: pathOr("", name.split("."), values),
        onChange: (e) => setFieldValue(name, e.target.value),
        style: inputStyles,
        multiline: true,
        ...inputProps,
      }}
      helperText={helperTextPreferError}
      error={!!pathOr(false, name.split("."), errors)}
      labelText={labelText}
      {...rest}
    />
  );


  const autocomplete = (
    <FormControl
      fullWidth
      className={selectclasses.selectFormControl}
      error={!!pathOr(false, name.split("."), errors)}
      style={{ marginTop: '10px' }}
    >
      <Autocomplete
        noOptionsText={'Žiadne možnosti'}
        id="combo-box-demo"
        options={options}
        fullWidth
        getOptionLabel={(option) => option.label || ""}
        renderInput={(params) =>
          <TextField
            style={inputStyles}
            InputProps={{
              ...inputProps,

            }}
            disabled={disabledField}

            {...params}

            label={<span style={{
              color: '#AAAAAA !important',
            }}>{labelText}</span>}

          />}
        onChange={(event, value) => setFieldValue(name, value)}
        value={(pathOr('', name, values)?.label && pathOr('', name, values).value) ? pathOr('', name, values) : {
          value: pathOr('', name, values),
          label: pathOr('', name, values)
        }}
        InputProps={{
          style: inputStyles,
          multiline: true,
          ...inputProps,
        }}
        label={labelText}
        name={name}
        formControlProps={{
          fullWidth: true,
          ...formControlProps
        }}
        error={!!pathOr(false, name.split('.'), errors)}
        {...rest}
      />
      <FormHelperText style={{ color: (pathOr(false, name.split('.'), errors)) ? '#F44336' : 'white' }}>{helperTextPreferError}</FormHelperText>
    </FormControl>
  );

  const select = (
    <FormControl
      fullWidth
      className={selectclasses.selectFormControl}
      error={!!pathOr(false, name.split("."), errors)}
    >
      <InputLabel
        htmlFor="simple-select"
        style={{ marginBottom: "20px" }}
        className={`${selectclasses.selectLabel} ${!!pathOr(false, name.split("."), errors)
          ? selectclasses.selectLabelError
          : ""
          }`}
      >
        {labelText}
      </InputLabel>
      <Select
        style={inputSelectedStyles}
        MenuProps={{
          className: selectclasses.selectMenu,
        }}
        classes={{
          select: selectclasses.select,
        }}
        //multiple={true}
        disabled={disabled}
        value={pathOr("", name, values)}
        onChange={(e) => setFieldValue(name, e.target.value)}
        inputProps={{
          ...inputProps,
        }}
        inputlabelprops={{
          style: {
            color: "red",
          },
          shrink: placeholder && true,
        }}
        {...rest}
      >
        {options?.map((option) => (
          <MenuItem
            key={option.value}
            classes={{
              root: selectclasses.selectMenuItem,
              selected: selectclasses.selectMenuItemSelected,
            }}
            value={option.value}
          >
            {option.label}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>{helperTextPreferError}</FormHelperText>
    </FormControl>
  );

  const datePicker = (
    <FormControl fullWidth className={selectclasses.selectFormControl}>
      <InputLabel className={formikFieldClasses.datePickerLabel}>
        {pathOr("", name.split("."), values) === "" ? "" : labelText}
      </InputLabel>
      <Datetime
        isDisabled={disabled}
        timeFormat={false}
        inputProps={{
          placeholder: labelText,
          ...inputProps,
        }}
        onChange={(date) => setFieldValue(name, date)}
        value={
          pathOr("", name.split("."), values) === ""
            ? ""
            : moment(pathOr("", name.split("."), values)).format("DD.MM.YYYY")
        }
        {...rest}
      />
    </FormControl>
  );

  const dateTimePicker = (
    <FormControl
      style={inputStyles}
      fullWidth
      className={selectclasses.selectFormControl}
      error={!!pathOr(false, name.split("."), errors)}
    >
      <InputLabel
        htmlFor="simple-select"
        className={`${selectclasses.selectLabel} ${!!pathOr(false, name.split("."), errors)
          ? selectclasses.selectLabelError
          : ""
          }`}
      >
        {labelText}
      </InputLabel>
      <Datetime
        //timeFormat={false}
        inputProps={{
          disabled: disabled,
          error: !!pathOr(false, name.split("."), errors),
          ...inputProps,
        }}
        onChange={(date) => setFieldValue(name, date)}
        value={new Date(pathOr("", name.split("."), values))} // === '' ? '' : moment(pathOr('', name.split('.'), values)).toISOString()}
        {...rest}
      />
    </FormControl>
  );

  const monthPicker = (
    <div style={{ marginBottom: "1.5rem" }}>
      <label
        htmlFor="start"
        style={{ display: "block", margin: ".4rem 0" }}
        name={name}
      >
        {labelText}
      </label>
      <input
        type="month"
        style={{ margin: ".4rem 0" }}
        min="2018-03"
        value={pathOr("", name.split("."), values)}
        onChange={(e) => setFieldValue(name, e.target.value)}
      />
      <IconButton
        style={{ marginLeft: ".5rem" }}
        size="small"
        color="secondary"
        onClick={(e) => setFieldValue(name, null)}
      >
        <CloseIcon />
      </IconButton>
    </div>
  );

  const dayAndMonthPicker = (
    <FormControl
      style={inputStyles}
      fullWidth
      className={selectclasses.selectFormControl}
      error={!!pathOr(false, name.split("."), errors)}
    >
      <a href={() => false} style={{ color: "gray" }}>
        {labelText}
      </a>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <ThemeProvider theme={materialTheme}>
          <KeyboardDatePicker
            variant="inline"
            views={["year", "month"]}
            format="yyyy/MM"
            placeholder="YYYY/MM"
            disabled={disabled}
            style={inputStyles}
            onChange={(date) => setFieldValue(name, date)}
            value={pathOr("", name.split("."), values)} // === '' ? '' : moment(pathOr('', name.split('.'), values)).toISOString()}
            helperText={helperTextPreferError}
            error={!!pathOr(false, name.split("."), errors)}
            {...rest}
            {...inputProps}
          />
        </ThemeProvider>
      </MuiPickersUtilsProvider>
    </FormControl>
  );

  const handleSetItem = (event, n) => {
    let lastItem = n.key;
    const index = event.indexOf("AllNotifications");
    if (event.includes("AllNotifications") && lastItem !== "AllNotifications") {
      event.splice(index, 1);
    }

    if (lastItem === "AllNotifications") {
      setItem([]);
      setItem(["AllNotifications"]);
    } else {
      setItem(event);
    }

    if (event.length === 0) setItem(["AllNotifications"]);

    //FUNCTIONALITY FOR ALL OPTIONS
    /* if (lastItem === 'AllNotifications') {
             if (fullStack === false) {
                 setItem(options?.map((option) => (option.value)))
                 setFullStack(true)
             } else {
                 setItem([])
                 setFullStack(false)
             }
         } else {
             if (fullStack === false) {
                 if (event.length === options.length - 1) {
                     setItem(options?.map((option) => (option.value)))
                     setFullStack(true)
                 } else {
                     if (event.length === options.length - 1)
                         event.splice(index, 1);
                     setItem(event)
                     setFullStack(false)
 
                 }
             } else {
                 if (event.length === options.length - 1)
                     event.splice(index, 1);
                 setItem(event)
                 setFullStack(false)
             }
         }*/
  };

  const multiSelect = (
    <FormControl
      fullWidth
      className={selectclasses.selectFormControl}
      error={!!pathOr(false, name.split("."), errors)}
    >
      <InputLabel
        // htmlFor="simple-select"
        className={`${selectclasses.selectLabel} ${!!pathOr(false, name.split("."), errors)
          ? selectclasses.selectLabelError
          : ""
          }`}
      >
        {labelText}
      </InputLabel>

      <Select
        style={inputSelectedStyles}
        MenuProps={{
          className: selectclasses.selectMenu,
        }}
        classes={{
          select: selectclasses.select,
        }}
        //multiple={true}
        disabled={disabled}
        inputProps={{
          ...inputProps,
        }}
        inputlabelprops={{
          style: {
            color: "red",
          },
          shrink: placeholder && true,
        }}
        {...rest}
        multiple
        selectclasses={{
          select: selectclasses.select,
        }}
        // value={item}
        value={pathOr("", name, values)}
        onChange={(e, name) => {
          handleSetItem(e.target.value, name);
        }}
        onClick={(e) => {
          setFieldValue(name, item);
        }}
      >
        {options?.map((option) => (
          <MenuItem
            key={option.value}
            value={option.value}
            classes={{
              root: selectclasses.selectMenuItem,
              selected: selectclasses.selectMenuItemSelectedMultiple,
            }}
          >
            {option.label}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>{helperTextPreferError}</FormHelperText>
    </FormControl>
  );


  const toggle = (
    <Grid
      container
      spacing={1}
      style={{ ...inputStyles, paddingTop: '10px' }}
      fullWidth
      className={classes.selectFormControl}
      error={!!pathOr(false, name.split("."), errors)}
    >
      <Grid item sm={12} >
        <Typography variant="p">
          {labelText}
        </Typography>
        <Box
          className={classes.floatRight}
          style={{ marginTop: "-5px" }}
        >
          <Typography component="div">
            <Grid
              component="label"
              container
              alignItems="center"
              spacing={0}
            >
              <Grid item>
                {uncheckedLabelText}
              </Grid>
              <Grid item>
                <MainSwitch
                  classes={{ disabled: classes.disabledButton }}
                  checked={pathOr(false, name.split("."), values)}
                  onChange={() =>
                    setFieldValue(name, !pathOr(false, name.split("."), values))
                  }
                />
              </Grid>
              <Grid item>
                {checkedLabelText}
              </Grid>
            </Grid>
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );






  /*const toggle = (
    <Grid
      container
      spacing={1}
      style={inputStyles}
      fullWidth
      className={classes.selectFormControl}
      error={!!pathOr(false, name.split("."), errors)}
    >
      <Grid item sm={12}>
        {labelText}
        {body && <Box className={classes.floatRight}>{body}</Box>}
        <Box className={classes.floatRight}>
          <MainSwitch
            checked={pathOr(false, name.split("."), values)}
            onChange={() =>
              setFieldValue(name, !pathOr(false, name.split("."), values))
            }
            MenuProps={{
              className: classes.selectMenu,
            }}
            classes={{
              select: classes.select,
            }}
            disabled={disabled}
            value={pathOr("", name, values)}
            inputProps={{
              error: !!pathOr(false, name.split("."), errors),
              placeholder: placeholder,
              ...inputProps,
            }}
            inputlabelprops={{
              style: {
                color: "red",
              },
              shrink: placeholder && true,
            }}
            {...rest}
          />
        </Box>
        {pathOr(false, name.split("."), values) ? (
          <>
            <br />
            <label>{checkedLabelText}</label>
          </>
        ) : (
          <>
            <br />
            <label>{uncheckedLabelText}</label>
          </>
        )}
        {helperText && (
          <Grid item sm={12}>
            <FormHelperText>{helperText}</FormHelperText>
            <hr style={{ marginTop: "1px", marginBottom: "0px" }} />
          </Grid>
        )}
      </Grid>
    </Grid>
  );*/

  const handleSliderChange = (event, newValue) => {
    setFieldValue(name, newValue);
  };

  const handleInputChange = (event) => {
    setFieldValue(
      name,
      event.target.value === "" ? "" : Number(event.target.value)
    );
  };

  const handleDoubleInputChange = (event, newValue) => {
    setFieldValue(name, newValue);
    //setValue(newValue);
  };

  const handleBlur = () => {
    if (pathOr(0, name, values) < minValueSlider) {
      setFieldValue(name, minValueSlider);
    } else if (pathOr(0, name, values) > maxValueSlider) {
      setFieldValue(name, maxValueSlider);
    }
  };

  const slider = (
    <Grid
      container
      spacing={1}
      style={inputStyles}
      error={!!pathOr(false, name.split("."), errors)}
    >
      <Grid item sm={12}>
        <>
          <Grid item sm={12}>
            <p>{labelText}</p>
            <Grid container spacing={2}>
              {icon && <Grid item>{icon}</Grid>}
              <Grid item xs style={{ paddingTop: "0.2em" }}>
                <ThemeProvider theme={materialTheme}>
                  <Slider
                    className={classes.slider}
                    valueLabelDisplay="auto"
                    aria-label="pretto slider"
                    defaultValue={defaultValueSlider}
                    disabled={disabledField}
                    step={1}
                    min={minValueSlider}
                    max={maxValueSlider}
                    value={
                      (typeof value === "number" ? value : 0) ||
                      (typeof pathOr(0, name, values) === "number"
                        ? pathOr(0, name, values)
                        : 0)
                    }
                    valueLabelFormat={valueLabel}
                    onChange={handleSliderChange}
                    aria-labelledby="input-slider"
                    marks={marks}
                  //valueLabelFormat={valueLabelFormat}
                  />
                </ThemeProvider>
              </Grid>
              <Grid item style={{ paddingLeft: "2em" }}>
                <Input
                  //style={(value !== originalValue ) ? { backgroundColor: '#FFF8CC' } : { backgroundColor: '' }}
                  disabled={disabledField}
                  defaultValue={value}
                  className={classes.input}
                  value={pathOr(0, name, values)}
                  margin="dense"
                  onChange={handleInputChange}
                  onBlur={handleBlur()}
                  inputProps={{
                    step: 1,
                    min: 0,
                    max: 1000,
                    type: "number",
                    "aria-labelledby": "input-slider",
                  }}
                />
              </Grid>
            </Grid>
            {helperText && (
              <Grid item sm={12}>
                <FormHelperText>{helperText}</FormHelperText>
                <hr style={{ marginTop: "1px", marginBottom: "0px" }} />
              </Grid>
            )}
          </Grid>
        </>
      </Grid>
    </Grid>
  );

  const users = [
    {
      id: "1",
      display: "@firstName",
    },
    {
      id: "2",
      display: "@lastName",
    },
    {
      id: "3",
      display: "@balance",
    },
  ];

  const [st, setSt] = React.useState({
    comments: [],
    comment: "",
  });

  const { comments, comment } = st;

  const placeholderText = (
    <>
      <Grid>
        <br />
      </Grid>
      <FormControl
        style={inputStyles}
        fullWidth
        className={selectclasses.selectFormControl}
        error={!!pathOr(false, name.split("."), errors)}
      >
        {comments?.map((comment) => (
          <Linkify>{comment}</Linkify>
        ))}

        <label
          htmlFor="simple-select"
          className={`${selectclasses.selectLabel} ${!!pathOr(false, name.split("."), errors)
            ? selectclasses.selectLabelError
            : ""
            }`}
        >
          {labelText}
        </label>

        <MentionsInput
          style={{
            width: "100%",
            height: "100px",
            padding: "0 1rem",
            font: "inherit",
            color: " #565656",
          }}
          markup="@[__display__](__id__)"
          value={pathOr("", name.split("."), values)}
          onChange={(e) => setFieldValue(name, e.target.value)}
          {...rest}
          inputProps={{
            error: !!pathOr("", name.split("."), errors),
            ...inputProps,
          }}
        >
          <Mention trigger="@" data={users} />
        </MentionsInput>
        <FormHelperText>{helperTextPreferError}</FormHelperText>
      </FormControl>
    </>
  );

  const doubleSlider = (
    <Grid
      container
      spacing={1}
      style={inputStyles}
      error={!!pathOr(false, name.split("."), errors)}
    >
      <Grid item sm={12}>
        <>
          <Grid item sm={12}>
            <p>{labelText}</p>
            <Grid container spacing={2}>
              {icon && <Grid item>{icon}</Grid>}
              <Grid item xs style={{ paddingTop: "0.2em" }}>
                <ThemeProvider theme={materialTheme}>
                  <Slider
                    //value={[25, 60]}
                    //onChange={handleSliderChange}
                    valueLabelDisplay="auto"
                    aria-labelledby="range-slider"
                    value={pathOr(0, name, values)}
                    onChange={handleDoubleInputChange}
                    //onClick={(e) => { setFieldValue(name, item) }}
                    // aria-labelledby="range-slider"
                    //className={classes.slider}
                    // valueLabelDisplay="on"
                    //aria-label="pretto slider"
                    marks={marks}
                    disabled={disabledField}
                  //step={1}
                  // min={minValueSlider}
                  // max={maxValueSlider}
                  //value={(typeof value === "number" ? value : 0) || (typeof pathOr(0, name, values) === "number" ? pathOr(0, name, values) : 0)}
                  //valueLabelFormat={valueLabel}
                  //onChange={handleSliderChange}
                  // aria-labelledby="input-slider"
                  // marks={marks}
                  //valueLabelFormat={valueLabelFormat}
                  />
                </ThemeProvider>
              </Grid>
            </Grid>
          </Grid>
        </>
      </Grid>
    </Grid>
  );

  const [clearedDate, handleClearedDateChange] = React.useState(
    pathOr(0, name, values) ? pathOr(0, name, values) : null
  );

  const picker2 = (
    <FormControl
      fullWidth
      className={selectclasses.selectFormControl}
      error={!!pathOr(false, name.split("."), errors)}
    >
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <ThemeProvider theme={materialTheme}>
          <DateTimePicker
            style={inputStyles}
            clearable
            value={clearedDate}
            onChange={(date) => {
              setFieldValue(name, date);
              handleClearedDateChange(date);
            }}
            helperText={helperTextPreferError}
            label={labelText}
            error={!!pathOr(false, name.split("."), errors)}
            format="yyyy/MM/dd HH:mm"
            disabled={disabled}
            {...rest}
            {...inputProps}
          />
        </ThemeProvider>
      </MuiPickersUtilsProvider>
    </FormControl>
  );

  const datePicker2 = (
    <FormControl
      style={inputStyles}
      fullWidth
      className={selectclasses.selectFormControl}
      error={!!pathOr(false, name.split("."), errors)}
    >
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <ThemeProvider theme={materialTheme}>
          <DatePicker
            //views={false}
            clearable
            value={clearedDate}
            onChange={(date) => {
              setFieldValue(name, date);
              handleClearedDateChange(date);
            }}
            helperText={helperTextPreferError}
            label={labelText}
            error={!!pathOr(false, name.split("."), errors)}
            format={dateFormat}
            disabled={disabled}
            {...rest}
          />
        </ThemeProvider>
      </MuiPickersUtilsProvider>
    </FormControl>
  );

  const chips = (
    <Grid
      container
      spacing={1}
      style={inputStyles}
      fullWidth
      className={classes.selectFormControl}
      error={!!pathOr(false, name.split("."), errors)}
    >
      <ChipInput
        //value={item}
        onChange={(e) => setFieldValue(name, e)}
        helperText={helperTextPreferError}
        label={labelText}
        //error={!!pathOr(false, name.split('.'), errors)}
        disabled={disabled}
        {...rest}
        {...inputProps}
        defaultValue={pathOr([], name, values)}
        fullWidth
        name={name}
        formControlProps={{
          fullWidth: true,
          ...formControlProps,
        }}
        error={!!pathOr(false, name.split("."), errors)}
        {...rest}
      />
    </Grid>
  );

  const [state, setState] = React.useState({
    value: "",
    error: null,
  });

  const [items, setItems] = React.useState(
    Array.isArray(pathOr(0, name, values)) && pathOr(0, name, values).length > 0
      ? pathOr(0, name, values)
      : []
  );

  const handleKeyDown = (evt) => {
    if (["Enter", "Tab", ","].includes(evt.key)) {
      evt.preventDefault();

      var value = state.value;

      if (value && isValid(value)) {
        setItems([...items, state.value]);
        setFieldValue(name, [...items, state.value]);

        setState({ value: "" });
      }
    }
  };

  const handleChange = (evt) => {
    setState({
      value: evt.target.value,
      error: null,
    });
  };

  const handleDelete = (item) => {
    setItems(items?.filter((i) => i !== item));
    setFieldValue(
      name,
      items?.filter((i) => i !== item)
    );
  };

  const handlePaste = (evt) => {
    evt.preventDefault();

    var paste = evt.clipboardData.getData("text");
    var emails = paste.match(/[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/g);

    if (emails) {
      var toBeAdded = emails?.filter((email) => !isInList(email));
      setItems([...items, ...toBeAdded]);
      setFieldValue(name, [...items, ...toBeAdded]);
    }
  };

  function isValid(email) {
    let error = null;

    if (isInList(email)) {
      error = `${email} has already been added.`;
    }

    if (!isEmail(email)) {
      error = `${email} is not a valid email address.`;
    }

    if (error) {
      setState({ error });

      return false;
    }

    return true;
  }

  function isInList(email) {
    return items?.includes(email);
  }

  function isEmail(email) {
    return /[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/.test(email);
  }

  const emailParser = (
    <>
      <Grid
        item
        error={!!pathOr(false, name.split("."), errors)}
        className={selectclasses.selectFormControl}
        style={inputStyles}
      >

        {items?.map((item) => (
          <div className="tag-item" key={item}>
            {item}
            <button
              type="button"
              className="button"
              onClick={() => handleDelete(item)}
            >
              &times;
            </button>
          </div>
        ))}

        <TextField
          className={"input " + (state.error && " has-error")}
          value={state.value}
          placeholder={labelText}
          helperText={helperText}
          onKeyDown={handleKeyDown}
          onChange={handleChange}
          onPaste={handlePaste}
        />

        {state.error && <p className="error">{state.error}</p>}
      </Grid>
    </>
  );

  const getInputByType = () => {
    if (type === "select") {
      return select;
    } else if (type === "multiline") {
      return textArea;
    } else if (type === "text") {
      return textField;
    } else if (type === "date") {
      return datePicker;
    } else if (type === "checkbox") {
      return checkbox;
    } else if (type === "dateTime") {
      return dateTimePicker;
    } else if (type === "monthPicker") {
      return monthPicker;
    } else if (type === "multiSelect") {
      return multiSelect;
    } else if (type === "switch") {
      return toggle;
    } else if (type === "picker2") {
      return picker2;
    } else if (type === "datePicker2") {
      return datePicker2;
    } else if (type === "chips") {
      return chips;
    } else if (type === "doubleSlider") {
      return doubleSlider;
    } else if (type === "dayAndMonthPicker") {
      return dayAndMonthPicker;
    } else if (type === "textPlaceholder") {
      return textPlaceholder;
    } else if (type === "placeholderText") {
      return placeholderText;
    } else if (type === "emailParser") {
      return emailParser;
    } else if (type === 'autocomplete') {
      return autocomplete;
    } else if (type === "slider") {
      return slider;
    } else {
      return textField;
    }
  };

  return getInputByType();
};

export default FormikField;
