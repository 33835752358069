import gql from 'graphql-tag';

const COUPON_SET_CREATE = gql`
mutation couponSetCreate($nominalValue: Float!, $type: CouponSetType, $count: UnsignedInt){
  couponSetCreate(nominalValue: $nominalValue, type: $type, count: $count){
    couponCount
  }
}
`;

export default COUPON_SET_CREATE;