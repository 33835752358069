import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import { useQuery } from "react-apollo";
import { Formik } from "formik";
import { pathOr } from "rambda";
import FormikField from "components-lib/FormikField/FormikField";
import { validationSchemaStep2 } from "./validationSchema";
import { injectIntl } from "react-intl";
import MEDIAS_BY_COMPANY from "queries/MediaQueries/mediasByCompany";
import styles from "common/styles/widgets.js";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import defaultLogo from "assets/img/logo-whitee.svg";
import { defaultFFWatermarkUrl } from "configFiles/config";
import imagePlaceholder from "assets/img/image_placeholder.jpg";
import defaultImage from "assets/img/lockResized.jpeg";

const useStyles = makeStyles((theme) => styles(theme));

const Step2 = ({
  intl,
  callback,
  values,
  initialValues,
  callbackValid,
  initialData,
  companyId,
  isBack,
}) => {
  const { data: mediasData } = useQuery(MEDIAS_BY_COMPANY(Number(companyId)), {
    variables: {
      filter: [
        {
          acceptanceStatus: {
            eq: "Approved",
          },
          category: {
            in: ["FreePhotoAdvertisement", "FreePhotoWatermark"], //TODO - change to "FreePhotoWatermark"
          },
        },
      ],
    },
    skip: !companyId,
  });

  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (currentIndex >= values?.mediaIds?.length - 1) {
        setCurrentIndex(0);
      } else {
        setCurrentIndex(currentIndex + 1);
      }
    }, 1500);
    return () => clearInterval(intervalId);
  }, [currentIndex, values?.mediaIds]);

  const getMediasOptions = (category) => {
    const mediasOptions = [];
    pathOr([], ["mediasByCompany", "items"], mediasData)
      .filter((i) => i?.category === category)
      .map((media) => {
        mediasOptions.push({
          label: media.name,
          value: media.id,
        });
        return "";
      });
    if (category === "FreePhotoWatermark")
      mediasOptions.unshift({
        label: intl.formatMessage({
          id: "campaigns.freeFoto.watermark.default",
        }),
        value: "Default",
      });
    return mediasOptions;
  };

  const getMedia = (media, param) => {
    let mediaOptions = null;
    for (let i = 0; i < media?.length; i++)
      pathOr([], ["mediasByCompany", "items"], mediasData)
        .filter((m) => m.id === media)
        .map((med) => {
          mediaOptions =
            param === "name" ? med.name : (param = "url") ? med.url : med.bid;
        });
    return mediaOptions;
  };

  const classes = useStyles();
  
  const initialVal = initialValues
    ? {    
      ...initialData,        
      mediaIds: initialValues?.media.map(media => media.media.id),
  }
  : initialData;

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={isBack ? initialData : initialVal}
        validationSchema={validationSchemaStep2(intl)}
      >
        {(formikProps) => (
          <Grid container spacing={3}>
            {callbackValid(
              formikProps.isValid &&
                formikProps.values?.mediaIds?.length > 0 &&
                formikProps.values?.mediaIds?.[0] !== "AllNotifications"
            )}

            {callback(formikProps.values)}

            <Grid item xs={12} sm={6}>
              <FormikField
                name="mediaIds"
                labelText={intl.formatMessage({
                  id: "campaigns.wizard.step2.pictures",
                })}
                formikProps={formikProps}
                data={formikProps.values}
                type="multiSelect"
                options={getMediasOptions("FreePhotoAdvertisement")}
                highlightChange
              />

              {/*formikProps.values?.mediaIds?.length > 0 &&
                formikProps.values?.mediaIds?.[0] !== "AllNotifications" && (
                  <>
                    {getAllBannerUrls(formikProps?.values?.mediaIds)}
                    <Grid container spacing={1}>
                      {formikProps.values?.mediaIds?.map((item, idx) => (
                        <Grid item sm={4}>
                          {idx < 3 && <br />}
                          <img
                            style={{ position: "relative", top: 0, left: 0 }}
                            src={getMedia(item, "url")}
                            alt={getMedia(item, "url")}
                            width={"100%"}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </>
                      )*/}
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormikField
                name="watermarkId"
                labelText={intl.formatMessage({
                  id: "campaigns.freeFoto.watermark.title",
                })}
                formikProps={formikProps}
                data={formikProps.values}
                type="select"
                options={getMediasOptions("FreePhotoWatermark")}
                highlightChange
              />
            </Grid>
            <Grid
              container
              spacing={0}
              direction="column"
              alignItems="center"
              justifyContent="center"
            >
              <Grid item xs={12} md={10} lg={8}>
                <br />
                <div
                  style={{
                    position: "relative",
                    top: 0,
                    left: 0,
                    borderRadius: "12px",
                  }}
                >
                  <img
                    style={{
                      position: "relative",
                      top: 0,
                      left: 0,
                      borderRadius: "12px",
                    }}
                    src={defaultImage}
                    alt={defaultImage}
                    width={"100%"}
                  />
                  <img
                    style={{
                      position: "absolute",
                      bottom: "0%",
                      right: "33.2%",
                      height:"50%",
                    }}
                    src={
                      formikProps.values?.watermarkId &&
                      formikProps.values?.watermarkId !== "Default"
                        ? getMedia(formikProps.values?.watermarkId, "url")
                        : defaultFFWatermarkUrl
                    }
                    alt={
                      formikProps.values?.watermarkId &&
                      formikProps.values?.watermarkId !== "Default"
                        ? getMedia(formikProps.values?.watermarkId, "url")
                        : defaultFFWatermarkUrl
                    }
                    
                  />
                  {formikProps.values?.mediaIds?.length > 0 &&
                    formikProps.values?.mediaIds?.[0] !==
                      "AllNotifications" && (
                      <>
                        <img
                          style={{
                            position: "absolute",
                            top: 0,
                            left: "66.7%",
                            borderTopRightRadius: "12px",
                            borderBottomRightRadius: "12px",
                          }}
                          src={getMedia(
                            values?.mediaIds?.[currentIndex],
                            "url"
                          )}
                          alt={getMedia(
                            values?.mediaIds?.[currentIndex],
                            "url"
                          )}
                          width={"33.33%"}
                        />
                      </>
                    )}
                </div>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Formik>
      <hr />
    </>
  );
};

export default injectIntl(Step2);
