import gql from 'graphql-tag';

const ALL_STORES_CAMPAIGN = gql`
  query storesTableByCampaign($campaignId: ID!, $offset: Int, $limit: Int, $sort: [StoresTableSortInput!], $filter: [StoresTableFilterInput]) {
    storesTableByCampaign(campaignId: $campaignId, offset:$offset, limit: $limit, sort: $sort, filter: $filter ) {
      totalCount
      items {
        id
        bid
        name
        segment
        company
        address
        status        
        pricingLevel
      }
    }
  }
`;

export default ALL_STORES_CAMPAIGN;