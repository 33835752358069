export let colors = {
    main: '#F36E21',
    buttons: "#111111",
    selectedItemInSidebar: '#F36E21',
    background: '#eceff1',
    sidebarHidden: "white",
    sidebar: 'white'
};

export const shadows = {
    icons: false,
};

export const rounded = {
    buttons: "30px",
};

export const chartColors = {
    one: '#F36E21',
    two: '#78909c',
    three: '#283593',
    four: '#2196f3',
    five: '#00695c'
}

export const defaultColors = {
    one: '#F36E21',
    two: '#78909c',
    three: '#283593',
    four: '#2196f3',
    five: '#00695c'
}
