import gql from 'graphql-tag';

const ALL_ORDERS = gql`
query ordersTable(
  $offset: Int, 
  $limit:Int, 
  $sort:[OrdersTableSortInput!],
  $filter:[OrdersTableFilterInput]
) {
  ordersTable(
  offset: $offset, limit:$limit, sort: $sort, filter: $filter
  ) {
      totalCount,
      items {
          id
          bid
          type
          status
          isPaid
          email
          price
          expiresAt
          createdAt
          paymentSource
          purchaseAmount
      }
  }
}
`;

export default ALL_ORDERS;