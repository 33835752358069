import React from "react";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import { DateTime } from "luxon";
import QueryTable from "components-lib/QueryTable/QueryTable";
import Button from "components/CustomButtons/Button.js";
import DeviceErrorCodesEnum from "common/enums/DeviceErrorCodesEnum";
import Box from "@material-ui/core/Box";
import paths from "paths";
import { generatePath } from "react-router-dom";
import styles from "common/styles/widgets.js";
import { makeStyles } from "@material-ui/core/styles";
import { injectIntl } from "react-intl";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import WarningIcon from "@material-ui/icons/Warning";
import Tooltip from "@material-ui/core/Tooltip";
import PowerOffIcon from "@material-ui/icons/PowerOff";
import ButtonDetail from 'components/ButtonDetail/ButtonDetail';

const useStyles = makeStyles((theme) => styles(theme));

const DevicesTable = (props) => {
  const {
    history,
    query,
    intl,
    defaultFilter = null,
    queryDataPath = ["devicesTable", "items"],
    disabled,
  } = props;
  const classes = useStyles();

  const onDeviceDetail = (id) => {
    history.push(
      `/admin${generatePath(paths.devices.detail, { deviceId: id })}`
    );
  };

  const getStatus = (status, errorCode, errorDescription) => {
    switch (status) {
      case DeviceErrorCodesEnum.ONLINE.text:
        return (
          <p style={{ color: "green" }}>
            <CheckCircleIcon className={classes.circleTable} />
            Online
          </p>
        );
      case "Error":
        return (
          <Tooltip
            title={
              <span style={{ fontSize: "1.4em" }}>
                {intl.formatMessage({ id: "storeList.error" })} {errorCode}:{" "}
                {errorDescription}{" "}
              </span>
            }
          >
            <p style={{ color: "#f57c00" }}>
              <WarningIcon className={classes.circleTable} />
              {intl.formatMessage({ id: "storeList.error" })} ({errorCode})
            </p>
          </Tooltip>
        );
      case DeviceErrorCodesEnum.OFFLINE.text:
        return (
          <p style={{ color: "red" }}>
            <PowerOffIcon className={classes.circleTable} />
            Offline
          </p>
        );
      default:
        return "-";
    }
  };

  const getEnabledColumns = () => {
    return [
      {
        Header: intl.formatMessage({ id: "deviceList.table.ID" }),
        accessor: "bid",
        sortKey: "bid",
        filterKey: "bid",
        filterOperator: "contains",
      },
      {
        Header: intl.formatMessage({ id: "deviceList.table.company" }),
        accessor: "company",
        sortKey: "company",
        filterKey: "company",
        filterOperator: "contains",
      },
      {
        Header: intl.formatMessage({ id: "deviceList.table.store" }),
        accessor: "store",
        sortKey: "store",
        filterKey: "store",
        filterOperator: "contains",
      },
      {
        Header: intl.formatMessage({ id: "deviceList.table.address" }),
        accessor: "address",
        sortKey: "address",
        filterKey: "address",
        filterOperator: "contains",
      },
      {
        Header: intl.formatMessage({ id: "deviceList.table.remainingPhotos" }),
        accessor: (rowData) =>
          rowData.remainingPhotos +
          intl.formatMessage({ id: "tableTexts.piece" }),
        sortKey: "remainingPhotos",
        filterKey: "remainingPhotos",
        filterOperator: "eq",
        filterDataType: "number",
      },
      {
        Header: intl.formatMessage({ id: "deviceList.table.state" }),
        accessor: (rowData) =>
          getStatus(
            rowData.status,
            rowData.issueCode,
            rowData.issueDescription
          ),
        sortKey: "status",
        filterKey: "status",
        filterComponent: "select",
        filterOperator: "in",
        filterOptions: [
          {
            value: "Online",
            label: intl.formatMessage({ id: "deviceStatus.online" }),
          },
          {
            value: "Offline",
            label: intl.formatMessage({ id: "deviceStatus.offline" }),
          },
          {
            value: "Error",
            label: intl.formatMessage({ id: "deviceStatus.error" }),
          },
        ],
      },
      {
        Header: intl.formatMessage({ id: "deviceList.table.heartbeat" }),
        accessor: (rowData) =>
          rowData.heartbeat !== null ? (
            DateTime.fromISO(rowData.heartbeat).toFormat("dd.MM.yyyy HH:mm:ss")
          ) : (
            <p> - </p>
          ),
        sortKey: "heartbeat",
        filterKey: "heartbeat",
        filterOperator: "between",
        filterDataType: "dateBetween",
        filterComponent: "date",
      },
      {
        Header: intl.formatMessage({ id: "deviceList.table.actions" }),
        accessor: (rowData) => (
          <div style={{ display: "flex", flexDirection: "row",  flexWrap: "wrap",
            justifyContent: "flex-start", alignItems: "center", gap: "0.2em" }}>

          <ButtonDetail
            onClick={ () => onDeviceDetail(rowData.id) }
          />
          </div>
        ),
      },
    ];
  };

  const getDisabledColumns = () => {
    return [
      {
        Header: intl.formatMessage({ id: "deviceList.table.ID" }),
        accessor: "bid",
        sortKey: "bid",
        filterKey: "bid",
        filterOperator: "contains",
      },
      {
        Header: intl.formatMessage({ id: "deviceList.table.remainingPhotos" }),
        accessor: (rowData) =>
          rowData.remainingPhotos +
          intl.formatMessage({ id: "tableTexts.piece" }),
        sortKey: "remainingPhotos",
        filterKey: "remainingPhotos",
        filterOperator: "eq",
        filterDataType: "number",
      },
      {
        Header: intl.formatMessage({ id: "deviceList.table.state" }),
        accessor: (rowData) =>
          getStatus(
            rowData.status,
            rowData.issueCode,
            rowData.issueDescription
          ),
        sortKey: "status",
        filterKey: "status",
        filterComponent: "select",
        filterOperator: "in",
        filterOptions: [
          {
            value: "Online",
            label: intl.formatMessage({ id: "deviceStatus.online" }),
          },
          {
            value: "Offline",
            label: intl.formatMessage({ id: "deviceStatus.offline" }),
          },
          {
            value: "Error",
            label: intl.formatMessage({ id: "deviceStatus.error" }),
          },
        ],
      },
      {
        Header: intl.formatMessage({ id: "deviceList.table.heartbeat" }),
        accessor: (rowData) =>
          rowData.heartbeat !== null ? (
            DateTime.fromISO(rowData.heartbeat).toFormat("dd.MM.yyyy HH:mm:ss")
          ) : (
            <p> - </p>
          ),
        sortKey: "heartbeat",
        filterKey: "heartbeat",
        filterOperator: "between",
        filterDataType: "dateBetween",
        filterComponent: "date",
      },
      {
        Header: intl.formatMessage({ id: "deviceList.table.actions" }),
        accessor: (rowData) => (
          <div style={{ display: "flex", flexDirection: "row",  flexWrap: "wrap",
            justifyContent: "flex-start", alignItems: "center", gap: "0.2em" }}>
            
          {/* <Button
             round
             className={classes.tenantButtons}
             size="sm"
             color="info"
             onClick={() => onDeviceDetail(rowData.id)}
           >
             {intl.formatMessage({ id: "deviceList.table.actions.detail" })}
           </Button> */}

            <ButtonDetail
              onClick={ () => onDeviceDetail(rowData.id) }
            />
          </div>
        ),
      },
    ];
  };

  return (
    <QueryTable
      permanentFilter={defaultFilter}
      queryDataPath={queryDataPath}
      columns={disabled ? getDisabledColumns() : getEnabledColumns()}
      query={query}
    />
  );
};

export default injectIntl(DevicesTable);
