import React, { useState } from 'react';
import { injectIntl } from 'react-intl';
import QueryTableRefresh from 'components-lib/QueryTable/QueryTableRefresh';
import moment from 'moment';
import { generatePath } from 'react-router-dom';
import styles from "common/styles/widgets.js";
import Button from "components/CustomButtons/Button.js";
import paths from 'paths';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import ControlPointDuplicateIcon from '@material-ui/icons/ControlPointDuplicate';
import DeleteIcon from '@material-ui/icons/Delete';
import Modal from 'components-lib/Modal/Modal';
import NOTIFICATION_DELETE from 'queries/NotificationsQueries/deleteNotification'
import { useMutation } from 'react-apollo';
import { useSnackbar } from 'notistack'
import NOTIFICATION_DETAIL from 'queries/NotificationsQueries/detailNotification';
import { getApolloClient } from "configFiles/apollo";

const useStyles = makeStyles((theme) => styles(theme))

export const NotificationTable = (props) => {
    const { data, loadData, intl, history, defaultFilter = null, waiting, refetch } = props
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const client = getApolloClient(enqueueSnackbar, history, intl);

    const [removeModalOpen, setRemoveModalOpen] = useState(false);
    const [notificationId, seNotificationId] = useState(-1)
    const [deltetNotification] = useMutation(NOTIFICATION_DELETE)

    const handleSetCriteria = async (notifId) => {
        try {
            const result = await client.query({
                query: NOTIFICATION_DETAIL,
                variables: {
                    id: notifId
                }
            });
            if (result.error) {
                throw result.error;
            }
            if (result.errors) {
                throw result.errors[0];
            }
            console.log(result.data)
            history.push({
                pathname: `/admin${generatePath(paths.notifications.new)}`,
                state: result.data
            })

        } catch (error) {
            throw error;
        }
    };


    const handleRemoveNotification = () => {
        deltetNotification({
            variables: {
                id: notificationId,
            },
        }).then((response) => {
            enqueueSnackbar(intl.formatMessage({ id: 'notificationsPage.delete.success' }), { variant: "success" });
            refetch()
            setRemoveModalOpen(false);
        }).catch((err) => {
            console.log('[error]', err)
        });
    }

    const renderAssignActions = () => {
        return (
            <>
                <Button
                    size="sm"
                    color="danger"
                    onClick={handleRemoveNotification}
                    round
                >
                    {intl.formatMessage({ id: 'campaigns.coupons.removeButton' })}
                </Button>

                <Button
                    size="sm"
                    color="primary"
                    onClick={() => setRemoveModalOpen(false)}
                    round
                >
                    {intl.formatMessage({ id: "invoicesList.assignModal.cancel" })}
                </Button>
            </>
        );
    };

    const getColumnsDetail = () => {
        return (
            [
                {
                    Header: intl.formatMessage({ id: 'notificationsPage.table.id' }),
                    accessor: 'bid',
                    sortKey: 'bid',
                    filterKey: 'bid',
                    filterOperator: 'contains'
                },
                {
                    Header: intl.formatMessage({ id: 'notificationsPage.table.date' }),
                    accessor: (rowData) => moment(rowData.issuedAt).format('DD.MM.YYYY H:mm'),
                    sortKey: "issuedAt",
                    filterKey: "issuedAt",
                    filterOperator: 'between',
                    filterDataType: "dateBetween",
                    filterComponent: 'date',
                },
                {
                    Header: intl.formatMessage({ id: 'notificationsPage.table.author' }),
                    accessor: 'author',
                    sortKey: 'author',
                    filterKey: 'author',
                    filterOperator: 'contains'

                },
                {
                    Header: intl.formatMessage({ id: 'notificationsPage.table.header' }),
                    accessor: 'title',
                    sortKey: "title",
                    filterKey: 'title',
                    filterOperator: 'contains'
                },
                {
                    Header: intl.formatMessage({ id: 'notificationsPage.table.text' }),
                    accessor: 'text',
                    sortKey: "text",
                    filterKey: 'text',
                    filterOperator: 'contains'
                },
                {
                    Header: intl.formatMessage({ id: 'notificationsPage.table.sendDate' }),
                    accessor: (rowData) => (rowData.sentAt) ? moment(rowData.sentAt).format('DD.MM.YYYY H:mm') : "-",
                    sortKey: "sentAt",
                    filterKey: "sentAt",
                    filterOperator: 'between',
                    filterDataType: "dateBetween",
                    filterComponent: 'date',
                },
                {
                    Header: intl.formatMessage({ id: 'storeList.table.actions' }),
                    accessor: (rowData) =>
                        <Grid container>
                            <ControlPointDuplicateIcon
                                disabled={true}
                                className={classes.pointer}
                                onClick={() => handleSetCriteria(rowData.id)}
                            />
                        </Grid>
                }
            ]
        )
    }

    const getColumnsWaitingDetail = () => {
        return (
            [
                {
                    Header: intl.formatMessage({ id: 'notificationsPage.table.id' }),
                    accessor: 'bid',
                    sortKey: 'bid',
                    filterKey: 'bid',
                    filterOperator: 'contains'
                },
                {
                    Header: intl.formatMessage({ id: 'notificationsPage.table.date' }),
                    accessor: (rowData) => moment(rowData.issuedAt).format('DD.MM.YYYY H:mm'),
                    sortKey: "issuedAt",
                    filterKey: "issuedAt",
                    filterOperator: 'between',
                    filterDataType: "dateBetween",
                    filterComponent: 'date',
                },
                {
                    Header: intl.formatMessage({ id: 'notificationsPage.table.author' }),
                    accessor: 'author',
                    sortKey: 'author',
                    filterKey: 'author',
                    filterOperator: 'contains'

                },
                {
                    Header: intl.formatMessage({ id: 'notificationsPage.table.header' }),
                    accessor: 'title',
                    sortKey: "title",
                    filterKey: 'title',
                    filterOperator: 'contains'
                },
                {
                    Header: intl.formatMessage({ id: 'notificationsPage.table.text' }),
                    accessor: 'text',
                    sortKey: "text",
                    filterKey: 'text',
                    filterOperator: 'contains'
                },
                {
                    Header: intl.formatMessage({ id: 'notificationsPage.table.sendDateWill' }),
                    accessor: (rowData) => (rowData.scheduledTo) ? moment(rowData.scheduledTo).format('DD.MM.YYYY H:mm') : "-",
                    sortKey: "scheduledTo",
                    filterKey: "scheduledTo",
                    filterOperator: 'between',
                    filterDataType: "dateBetween",
                    filterComponent: 'date',
                },
                {
                    Header: intl.formatMessage({ id: 'storeList.table.actions' }),
                    accessor: (rowData) =>
                        <Grid container >
                            <ControlPointDuplicateIcon
                                disabled={true}
                                className={classes.pointer}
                                onClick={() => handleSetCriteria(rowData.id)}
                            />
                            <DeleteIcon
                                disabled={true}
                                className={classes.pointer}
                                onClick={() => { setRemoveModalOpen(true); seNotificationId(rowData.id) }}
                            />
                        </Grid>
                }
            ]
        )
    }



    return (
        <>
            <QueryTableRefresh
                data={data}
                loadData={loadData}
                queryDataPath={['notificationsTable', 'items']}
                permanentFilter={defaultFilter}
                defaultSort={[
                    {
                        field: 'issuedAt',
                        order: 'DESC'
                    }
                ]}
                columns={(waiting) ? getColumnsWaitingDetail() : getColumnsDetail()}
            />
            <Modal
                title="Naozaj chcete odstrániť túto notifikáciu?"
                open={removeModalOpen}
                onClose={() => setRemoveModalOpen(false)}
                actions={renderAssignActions()}
            />
        </>
    )
}

export default injectIntl(NotificationTable);