import { Divider, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CameraEnhanceIcon from "@material-ui/icons/CameraEnhance";
import IconCard from "components-lib/IconCard/IconCard";
import React from "react";
import { injectIntl } from "react-intl";
import { withRouter } from "react-router";
import Box from "@material-ui/core/Box";
import styles from "common/styles/widgets.js";
import DeviceErrorCodesEnum from "common/enums/DeviceErrorCodesEnum";
import DeviceAppStatusEnum from "common/enums/DeviceAppStatusEnum";
import moment from "moment";
import { isInRoles } from "helpers/helpers";
import RoleEnum from "common/enums/RoleEnum";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import WarningIcon from "@material-ui/icons/Warning";
import Tooltip from "@material-ui/core/Tooltip";
import PowerOffIcon from "@material-ui/icons/PowerOff";

const colors = {
  use: "#4caf50",
  connected: "#1e88e5",
  disconnected: "#E30404",
  unavailable: "#b0bec5",
}

const useStyles = makeStyles((theme) => styles(theme));

const StatusAndVolumeCard = (props) => {
  const classes = useStyles();
  const {
    intl,
    status,
    connection,
    issue,
    isEnabled,
    lastHeartbeat,
    appStatus,
    remainingPhotos,
    remainingPhotosWarning,
    printedPhotos,
  } = props;

  const renderRemainingPhotos = () => {
    if (remainingPhotosWarning) {
      return (
        <div style={{ color: "#E30404" }}>
          {intl.formatMessage({
            id: "storeDetail.deviceStatusCard.numberOfPhotos",
          })}
          <Box className={classes.floatRight}>
            <WarningIcon className={classes.warningIcon} />
            <span>{remainingPhotos}</span>
          </Box>
        </div>
      );
    }

    return (
      <>
        {intl.formatMessage({
          id: "storeDetail.deviceStatusCard.numberOfPhotos",
        })}
        <Box className={classes.floatRight}>{remainingPhotos}</Box>
      </>
    );
  };

  const getStatus = (status) => {
    switch (status) {
      case "Online":
        return (
          <Box className={classes.floatRight} color="success.main">
            <span>Online</span>
          </Box>
        );
      case "Offline":
        return (
          <Box className={classes.floatRight} style={{ color: "red" }}>
            <span>Offline</span>
          </Box>
        );
      case "Error":
        return (
          <Box className={classes.floatRight} style={{ color: "#f57c00" }}>
            <span>
              {intl.formatMessage({ id: "storeList.error" })} ({issue.code}):{" "}
              {issue.description}
            </span>
          </Box>
        );
      default:
        return "-";
    }
  };


  const getConnection = (connectionStatus) => {
    switch (connectionStatus) {
      case "InUse": return { text: intl.formatMessage({ id: "storeDetail.deviceStatusCard.connectionStatus.InUse" }), color: colors.use };
      case "Connected": return { text: intl.formatMessage({ id: "storeDetail.deviceStatusCard.connectionStatus.Connected" }), color: colors.connected };
      case "Disconnected": return { text: intl.formatMessage({ id: "storeDetail.deviceStatusCard.connectionStatus.Disconnected" }), color: colors.disconnected };
      case "Unavailable": return { text: intl.formatMessage({ id: "storeDetail.deviceStatusCard.connectionStatus.Unavailable" }), color: colors.unavailable };
      default: return { text: intl.formatMessage({ id: "storeDetail.deviceStatusCard.connectionStatus.Unknown" }), color: colors.unavailable };
    }
  }
  const mobileIcon = <path d="M0 11.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-2zm4-3a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5v5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-5zm4-3a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5v8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-8zm4-3a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5v11a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-11z" />
  const wifiIcon = <>
    <path d="M15.384 6.115a.485.485 0 0 0-.047-.736A12.444 12.444 0 0 0 8 3C5.259 3 2.723 3.882.663 5.379a.485.485 0 0 0-.048.736.518.518 0 0 0 .668.05A11.448 11.448 0 0 1 8 4c2.507 0 4.827.802 6.716 2.164.205.148.49.13.668-.049z" />
    <path d="M13.229 8.271a.482.482 0 0 0-.063-.745A9.455 9.455 0 0 0 8 6c-1.905 0-3.68.56-5.166 1.526a.48.48 0 0 0-.063.745.525.525 0 0 0 .652.065A8.46 8.46 0 0 1 8 7a8.46 8.46 0 0 1 4.576 1.336c.206.132.48.108.653-.065zm-2.183 2.183c.226-.226.185-.605-.1-.75A6.473 6.473 0 0 0 8 9c-1.06 0-2.062.254-2.946.704-.285.145-.326.524-.1.75l.015.015c.16.16.407.19.611.09A5.478 5.478 0 0 1 8 10c.868 0 1.69.201 2.42.56.203.1.45.07.61-.091l.016-.015zM9.06 12.44c.196-.196.198-.52-.04-.66A1.99 1.99 0 0 0 8 11.5a1.99 1.99 0 0 0-1.02.28c-.238.14-.236.464-.04.66l.706.706a.5.5 0 0 0 .707 0l.707-.707z" />
  </>
  const ethernetIcon = <>
    <path d="M14 13.5v-7a.5.5 0 0 0-.5-.5H12V4.5a.5.5 0 0 0-.5-.5h-1v-.5A.5.5 0 0 0 10 3H6a.5.5 0 0 0-.5.5V4h-1a.5.5 0 0 0-.5.5V6H2.5a.5.5 0 0 0-.5.5v7a.5.5 0 0 0 .5.5h11a.5.5 0 0 0 .5-.5ZM3.75 11h.5a.25.25 0 0 1 .25.25v1.5a.25.25 0 0 1-.25.25h-.5a.25.25 0 0 1-.25-.25v-1.5a.25.25 0 0 1 .25-.25Zm2 0h.5a.25.25 0 0 1 .25.25v1.5a.25.25 0 0 1-.25.25h-.5a.25.25 0 0 1-.25-.25v-1.5a.25.25 0 0 1 .25-.25Zm1.75.25a.25.25 0 0 1 .25-.25h.5a.25.25 0 0 1 .25.25v1.5a.25.25 0 0 1-.25.25h-.5a.25.25 0 0 1-.25-.25v-1.5ZM9.75 11h.5a.25.25 0 0 1 .25.25v1.5a.25.25 0 0 1-.25.25h-.5a.25.25 0 0 1-.25-.25v-1.5a.25.25 0 0 1 .25-.25Zm1.75.25a.25.25 0 0 1 .25-.25h.5a.25.25 0 0 1 .25.25v1.5a.25.25 0 0 1-.25.25h-.5a.25.25 0 0 1-.25-.25v-1.5Z" />
    <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2ZM1 2a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2Z" />
  </>

  const renderDeviceStatus = () => {
    return (
      <>
        <br />
        <Grid container spacing={2}>
          <Grid item sm={12}>
            {intl.formatMessage({ id: "storeDetail.deviceStatusCard.status" })}
            {getStatus(status)}
          </Grid>
          <Grid item sm={12}>
            {intl.formatMessage({ id: "storeDetail.deviceStatusCard.connection" })}
            <Box className={classes.floatRight}>
              <Tooltip
                style={{ cursor: 'pointer' }}

                id="tooltip-top"
                title={`${intl.formatMessage({ id: "storeDetail.deviceStatusCard.connection.ethernet" })}: ${getConnection(connection?.wan).text}`}
                placement="bottom"
              >

                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill={getConnection(connection?.wan).color} class="bi bi-ethernet" viewBox="0 0 16 16">
                  {ethernetIcon}
                </svg>
              </Tooltip>
              &nbsp; &nbsp;
              <Tooltip
                style={{ cursor: 'pointer' }}

                id="tooltip-top"
                title={`${intl.formatMessage({ id: "storeDetail.deviceStatusCard.connection.wifi" })}: ${getConnection(connection?.wifi).text}`}
                placement="bottom"
              >

                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill={getConnection(connection?.wifi).color} class="bi bi-wifi" viewBox="0 0 16 16">
                  {wifiIcon}
                </svg>
              </Tooltip>
              &nbsp; &nbsp;

              <Tooltip
                style={{ cursor: 'pointer' }}
                id="tooltip-top"
                title={`${intl.formatMessage({ id: "storeDetail.deviceStatusCard.connection.mobile" })}: ${getConnection(connection?.mobile).text}`}
                placement="bottom"
              >

                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill={getConnection(connection?.mobile).color} class="bi bi-reception-4" viewBox="0 0 16 16">
                  {mobileIcon}
                </svg>
              </Tooltip>

            </Box>
          </Grid>
          <Grid item sm={12}>
            {intl.formatMessage({
              id: "storeDetail.deviceStatusCard.isEnabled",
            })}
            {!isEnabled ? (
              <Box color="error.main" className={classes.floatRight}>
                {intl.formatMessage({
                  id: "deviceDetail.deviceStatusCard.fotomatDeactivated",
                })}
              </Box>
            ) : (
              <Box color="success.main" className={classes.floatRight}>
                {intl.formatMessage({
                  id: "deviceDetail.deviceStatusCard.fotomatActivated",
                })}
              </Box>
            )}
          </Grid>
          <Grid item sm={12}>
            {intl.formatMessage({
              id: "storeDetail.deviceStatusCard.lastHeartbeat",
            })}
            <Box className={classes.floatRight}>
              {lastHeartbeat ? (
                <span className={classes.floatRight}>
                  {moment(lastHeartbeat).format("DD.MM.YYYY HH:mm:ss")}
                </span>
              ) : (
                <span> - </span>
              )}
            </Box>
          </Grid>
          <Grid item sm={12}>
            {intl.formatMessage({
              id: "deviceDetail.deviceStatusCard.fotomatApp",
            })}
            {appStatus === DeviceAppStatusEnum.STOPPED ? (
              <Box color="error.main" className={classes.floatRight}>
                {intl.formatMessage({
                  id: "deviceDetail.deviceStatusCard.fotomatAppStopped",
                })}
              </Box>
            ) : (
              <Box color="success.main" className={classes.floatRight}>
                {intl.formatMessage({
                  id: "deviceDetail.deviceStatusCard.fotomatAppRunning",
                })}
              </Box>
            )}
          </Grid>
          <Grid item sm={12}>
            <hr style={{ marginTop: "4px" }} />
            {renderRemainingPhotos()}
          </Grid>
          {isInRoles([RoleEnum.ADMIN, RoleEnum.MAINTAINER]) ? (
            <Grid item sm={12}>
              {intl.formatMessage({
                id: "storeDetail.deviceStatusCard.printerCounter",
              })}
              <Box className={classes.floatRight}>{printedPhotos}</Box>
            </Grid>
          ) : (
            ""
          )}
        </Grid>
      </>
    );
  };

  return (
    <>
      <IconCard
        title={intl.formatMessage({ id: "storeDetail.deviceStatusCard.title" })}
        className={classes.cardStyle}
        icon={<CameraEnhanceIcon />}
      >
        {renderDeviceStatus()}
      </IconCard>
    </>
  );
};

export default withRouter(injectIntl(StatusAndVolumeCard));
