import * as React from "react";
import PageHeader from "components-lib/PageHeader/PageHeader";
import { injectIntl } from "react-intl";
import { generatePath, withRouter } from "react-router-dom";
import { pathOr } from "rambda";
import paths from "paths";
import { useMutation, useQuery, useLazyQuery } from "@apollo/react-hooks";
import { useState } from "react";
import { useEffect } from "react";
import IconCard from "components-lib/IconCard/IconCard";
import Grid from "@material-ui/core/Grid";
import Button from "components/CustomButtons/Button.js";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Modal from "components-lib/Modal/Modal";
import { useSnackbar } from "notistack";
import ContactData from "./UserCards/ContactData";
import { Formik } from "formik";
import {
  getValidationSchemaDetail,
  getPasswordValidation,
} from "./validationSchema";
import styles from "common/styles/widgets.js";
import ShoppingBasketIcon from "@material-ui/icons/ShoppingBasket";
import RoleEnum from "common/enums/RoleEnum";
import { isInRoles } from "helpers/helpers";
import Box from "@material-ui/core/Box";
import StoreTable from "../Stores/StoreTable";
import OrderTable from "../Orders/OrderTable";
import PaymentTable from "../Payments/PaymentTable";
import CompanyTable from "../Companies/CompanyTable";
import NotificationsIcon from "@material-ui/icons/Notifications";
import IconCardOneItem from "components-lib/IconCard/IconCardOneItem";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import StatusCard from "./UserCards/StatusCard";
import FormikField from "components-lib/FormikField/FormikField";
import { Business } from "@material-ui/icons";
import Loading from "components-lib/Loading/Loading";
import USER_DETAIL from "queries/UsersQueries/userDetail";
import USER_BLOCK from "queries/UsersQueries/userBlock";
import USER_UNBLOCK from "queries/UsersQueries/userUnblock";
import USER_ACTIVATE from "queries/UsersQueries/userActivate";
import USER_SEND_ACTIVATION_EMAIL from "queries/UsersQueries/userSendActivationEmail";
import AMBASSADOR_REFERRALS_TABLE from "queries/AmbassadorQueries/ambassadorReferralsTable";
import AMBASSADOR_COMMISSIONS_TABLE from "queries/AmbassadorQueries/ambassadorCommissionsTable";
import AMBASSORD_SEND_INFO_EMAIL from "queries/AmbassadorQueries/ambassadorSendInfoEmail";
//import USER_UPDATE from "queries/UsersQueries/userUpdate";
import USER_DELETE from "queries/UsersQueries/userDelete";
import PASSWORD_RESET from "queries/UsersQueries/passwordReset";
import COMPANY_BY_USER from "queries/UsersQueries/companyByUser";
import STORES_BY_USER from "queries/UsersQueries/storesByUser";
import ORDERS_BY_USER from "queries/UsersQueries/ordersByUser";
import PAYMENTS_BY_USER from "queries/UsersQueries/paymentsByUser";
import PROFILE_QUERY from "queries/ProfileQueries/userData";
import {
  OPERATIONS_TABLE_BY_USER,
  OPERATIONS_TABLE_BY_USER_TOTAL_COUNT,
} from "queries/UsersQueries/operationsTableByUser";
import AmbassadorCard from "./UserCards/AmbassadorCard";
import AmbassadorReferralsTable from "./UserCards/AmbassadorReferralsTable";
import AmbassadorCommissonsTable from "./UserCards/AmbassadorCommissionsTable";
import AddToHomeScreenIcon from "@material-ui/icons/AddToHomeScreen";
import SubjectIcon from "@material-ui/icons/Subject";
import AmbassadorReferralsChart from "./UserCards/AmbassadorReferralsChart";
import AMBASSADOR_REFERRALS_CHART from "queries/AmbassadorQueries/ambassadorReferralsChart";
import EqualizerIcon from "@material-ui/icons/Equalizer";
import OperationTable from "pages/Operations/OperationTable";
import SwapHorizIcon from "@material-ui/icons/SwapHoriz";

const useStyles = makeStyles((theme) => styles(theme));

const UserDetailPage = ({ intl, match, history }) => {
  const id = pathOr(null, ["params", "userId"], match);
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [userData, setUserData] = useState({});
  const [blockUserModalOpen, setBlockUserModalOpen] = useState(false);
  const [unblockModalOpen, setUnblockModalOpen] = useState(false);
  const [activateModalOpen, setActivateModalOpen] = useState(false);
  const [deleteUserModalOpen, setDeleteUserModalOpen] = useState(false);
  const [blockUserReason, setBlockuserReason] = useState("");
  const [filterStores, setFilterStores] = useState([]);
  const [filterCompanies, setFilterCompanies] = useState([]);
  const [filterOrders, setFilterOrders] = useState([]);
  const [filterPayments, setFilterPayments] = useState([]);
  const [ordersTotalCount, setOrdersTotalCount] = useState(0);
  const [paymentsTotalCount, setPaymentsTotalCount] = useState(0);
  const [operationsTotalCount, setOperationsTotalCount] = useState(0);
  const [
    ambassadorRefferalsTotalCount,
    setAmbassadorRefferalsTotalCount,
  ] = useState(0);

  const [
    ambassadorCommissoonsTotalCount,
    setAmbassadorCommissionsTotalCount,
  ] = useState(0);

  const [
    ambassadorReferralsChartTotalCount,
    setAmbassadorReferralsChartTotalCount,
  ] = useState(0);

  const [blockUser] = useMutation(USER_BLOCK);
  const [deleteUser] = useMutation(USER_DELETE);
  //const [updateUser] = useMutation(USER_UPDATE);
  const [unblockUser] = useMutation(USER_UNBLOCK);
  const [activateUser] = useMutation(USER_ACTIVATE);
  const [sendActivationEmailUser] = useMutation(USER_SEND_ACTIVATION_EMAIL);
  const [resetPassword] = useMutation(PASSWORD_RESET);
  const [ambassadorSendInfoEmail] = useMutation(AMBASSORD_SEND_INFO_EMAIL);

  const { data, loading, refetch: refetchUserData } = useQuery(USER_DETAIL, {
    variables: { id },
  });
  const { data: dataCompanies } = useQuery(COMPANY_BY_USER(id));
  const { data: dataOrders } = useQuery(ORDERS_BY_USER(id));
  const { data: dataPayments } = useQuery(PAYMENTS_BY_USER(id));
  const { data: dataOperations } = useQuery(OPERATIONS_TABLE_BY_USER_TOTAL_COUNT(id));
  const { data: dataAmbassadorReferrals } = useQuery(
    AMBASSADOR_REFERRALS_TABLE,
    {
      variables: {
        filter: { ambassadorId: { eq: Number(data?.user?.ambassador?.id) } },
      },
    }
  );

  const { data: dataAmbassadorReferralsChart } = useQuery(
    AMBASSADOR_REFERRALS_CHART,
    {
      variables: {
        ambassadorId: data?.user?.ambassador?.id
          ? Number(data?.user?.ambassador?.id)
          : -1,
      },
    }
  );

  const { data: dataAmbassadorCommissions } = useQuery(
    AMBASSADOR_COMMISSIONS_TABLE,
    {
      variables: {
        filter: { ambassadorId: { eq: Number(data?.user?.ambassador?.id) } },
      },
    }
  );

  const { data: me } = useQuery(PROFILE_QUERY);

  const [loadStores, { data: dataStores }] = useLazyQuery(STORES_BY_USER);
  let editableFields =
    pathOr("", ["me", "user", "id", ], me) === id ? false : true;
  let backButton = history.length > 1 ? true : false;

  // console.log({data: me})

  useEffect(() => {
    let companiesSelectItems = pathOr(
      [],
      "companiesTableByMember.items",
      dataCompanies
    ).map((company) => {
      return Number(company.id);
    });
    setFilterCompanies(companiesSelectItems);
  }, [dataCompanies]);

  useEffect(() => {
    let storesSelectItems = pathOr(
      [],
      "storesTableByUser.items",
      dataStores
    ).map((store) => {
      return Number(store.id);
    });
    setFilterStores(storesSelectItems);
  }, [dataStores]);

  useEffect(() => {
    let ordersSelectItems = pathOr(
      [],
      "ordersTableByUser.items",
      dataOrders
    ).map((order) => {
      return Number(order.id);
    });
    setFilterOrders(ordersSelectItems);
    setOrdersTotalCount(pathOr([], "ordersTableByUser.totalCount", dataOrders));
  }, [dataOrders]);

  useEffect(() => {
    setAmbassadorRefferalsTotalCount(
      pathOr([], "ambassadorReferralsTable.totalCount", dataAmbassadorReferrals)
    );
  }, [dataAmbassadorReferrals]);

  useEffect(() => {
    setAmbassadorCommissionsTotalCount(
      pathOr(
        [],
        "ambassadorCommissionsTable.totalCount",
        dataAmbassadorCommissions
      )
    );
  }, [dataAmbassadorCommissions]);

  useEffect(() => {
    setAmbassadorCommissionsTotalCount(
      pathOr(
        [],
        "ambassadorCommissionsTable.totalCount",
        dataAmbassadorCommissions
      )
    );
  }, [dataAmbassadorCommissions]);

  useEffect(() => {
    let paymentsSelectItems = pathOr(
      [],
      "paymentsTableByUser.items",
      dataPayments
    ).map((payment) => {
      return Number(payment.id);
    });
    setFilterPayments(paymentsSelectItems);
    setPaymentsTotalCount(
      pathOr([], "paymentsTableByUser.totalCount", dataPayments)
    );
  }, [dataPayments]);

  useEffect(() => {
    setOperationsTotalCount(
      pathOr([], "operationsTableByUser.totalCount", dataOperations)
    );
  }, [dataOperations]);

  const userRole = userData.role || "";

  let canEditUser = true;
  if (isInRoles([RoleEnum.MAINTAINER]) && userRole === "Maintainer") {
    canEditUser = false;
  }
  if (!id) {
    history.push(paths.dashboard);
  }

  useEffect(() => {
    setUserData(pathOr({}, ["user"], data));
  }, [data]);

  const handleUserBlock = () => {
    blockUser({
      variables: {
        id,
        reason: blockUserReason,
      },
    })
      .then((response) => {
        const firstName = pathOr(
          "",
          ["data", "userBlock", "firstName"],
          response
        );
        const lastName = pathOr(
          "",
          ["data", "userBlock", "lastName"],
          response
        );
        setBlockuserReason("");
        enqueueSnackbar(
          intl.formatMessage({ id: "userDetail.toast.block.success" }, [
            firstName,
            lastName,
          ]),
          { variant: "success" }
        );
        refetchUserData();
        setBlockUserModalOpen(false);
      })
      .catch((err) => {
        console.log("[error]", err);
      });
  };

  const handleUserDelete = () => {
    deleteUser({
      variables: {
        id: id,
      },
    })
      .then(() => {
        history.push(`/admin${generatePath(paths.users.list)}`);
        enqueueSnackbar(
          intl.formatMessage({ id: "userDetail.toast.delete.success" }),
          { variant: "success" }
        );
      })
      .catch((err) => {
        console.log("[error]", err);
        enqueueSnackbar(
          intl.formatMessage({ id: "userDetail.toast.delete.success" }),
          { variant: "error" }
        );
      });
  };

  const handleResetPassword = () => {
    resetPassword({
      variables: {
        email: userData.email,
      },
    })
      .then((response) => {
        enqueueSnackbar(
          intl.formatMessage({ id: "userDetail.resetPassword.success" }, [
            pathOr("", ["user", "email"], data),
          ]),
          { variant: "success" }
        );
      })
      .catch((err) => {
        console.log("[error]", err);
      });
  };

  const handleAmbassadorSendInfoMail = () => {
    ambassadorSendInfoEmail({
      variables: {
        ambassadorId: Number(data?.user?.ambassador?.id),
      },
    })
      .then((response) => {
        enqueueSnackbar(
          intl.formatMessage({ id: "userDetail.ambassadorInvite.success" }),
          { variant: "success" }
        );
      })
      .catch((err) => {
        console.log("[error]", err);
      });
  };

  const handleUserUnblock = () => {
    unblockUser({
      variables: {
        id: userData.id,
      },
    })
      .then((response) => {
        const firstName = pathOr(
          "",
          ["data", "userBlock", "firstName"],
          response
        );
        const lastName = pathOr(
          "",
          ["data", "userBlock", "lastName"],
          response
        );
        enqueueSnackbar(
          intl.formatMessage({ id: "userDetail.toast.unblock.success" }, [
            firstName,
            lastName,
          ]),
          { variant: "success" }
        );
        refetchUserData();
        setUnblockModalOpen(false);
      })
      .catch((err) => {
        console.log("[error]", err);
      });
  };

  const handleUserActivate = (values) => {
    if (userRole === RoleEnum.USER) {
      activateUser({
        variables: {
          id: userData.id,
        },
      })
        .then((response) => {
          const firstName = pathOr(
            "",
            ["data", "userBlock", "firstName"],
            response
          );
          const lastName = pathOr(
            "",
            ["data", "userBlock", "lastName"],
            response
          );
          enqueueSnackbar(
            intl.formatMessage({ id: "userDetail.toast.activate.success" }, [
              firstName,
              lastName,
            ]),
            { variant: "success" }
          );
          refetchUserData();
          setActivateModalOpen(false);
        })
        .catch((err) => {
          console.log("[error]", err);
        });
    } else {
      activateUser({
        variables: {
          id: userData.id,
          input: {
            password: values.password,
          },
        },
      })
        .then((response) => {
          const firstName = pathOr(
            "",
            ["data", "userBlock", "firstName"],
            response
          );
          const lastName = pathOr(
            "",
            ["data", "userBlock", "lastName"],
            response
          );
          enqueueSnackbar(
            intl.formatMessage({ id: "userDetail.toast.activate.success" }, [
              firstName,
              lastName,
            ]),
            { variant: "success" }
          );
          refetchUserData();
          setActivateModalOpen(false);
        })
        .catch((err) => {
          console.log("[error]", err);
        });
    }
  };

  const handleUserSendActivationEmail = () => {
    sendActivationEmailUser({
      variables: {
        id: userData.id,
      },
    })
      .then((response) => {
        const firstName = pathOr(
          "",
          ["data", "userBlock", "firstName"],
          response
        );
        const lastName = pathOr(
          "",
          ["data", "userBlock", "lastName"],
          response
        );
        enqueueSnackbar(
          intl.formatMessage(
            { id: "userDetail.toast.sendActivationUser.success" },
            [firstName, lastName]
          ),
          { variant: "success" }
        );
        refetchUserData();
      })
      .catch((err) => {
        console.log("[error]", err);
      });
  };

  const renderOrdersButtons = () => {
    return (
      <>
        <hr />
        <Button
          disabled={filterOrders.length <= 0}
          color="info"
          size="sm"
          round
          className={classes.floatRight}
          onClick={() =>
            history.push({
              pathname: `/admin${generatePath(paths.orders.list)}`,
              state: { id: { in: filterOrders } },
            })
          }
        >
          {intl.formatMessage({ id: "storeDetail.partnerCard.allOrders" })}
        </Button>
      </>
    );
  };

  const renderPaymentsButtons = () => {
    return (
      <>
        <hr />
        <Button
          disabled={filterPayments.length <= 0}
          color="info"
          size="sm"
          round
          className={classes.floatRight}
          onClick={() =>
            history.push({
              pathname: `/admin${generatePath(paths.payments.list)}`,
              state: { id: { in: filterPayments } },
            })
          }
        >
          {intl.formatMessage({ id: "storeDetail.partnerCard.allPayments" })}
        </Button>
      </>
    );
  };

  const renderOperationsButtons = () => {
    return (
      <>
        <hr />
        <Button
          disabled={!operationsTotalCount}
          color="info"
          size="sm"
          round
          className={classes.floatRight}
          onClick={() =>
            history.push({
              pathname: `/admin${generatePath(paths.operations.list)}`,
            })
          }
        >
          {intl.formatMessage({ id: "storeDetail.partnerCard.allOperations" })}
        </Button>
      </>
    );
  };

  const renderStoreButtons = () => {
    return (
      <>
        <hr />
        <Button
          color="info"
          size="sm"
          round
          disabled={filterStores.length <= 0}
          className={classes.floatRight}
          onClick={() =>
            history.push({
              pathname: `/admin${generatePath(paths.stores.list)}`,
              state: { id: { in: filterStores } },
            })
          }
        >
          {intl.formatMessage({ id: "storeList.table.allStore" })}
        </Button>
        <Button
          color="info"
          size="sm"
          round
          className={classes.floatRight}
          onClick={() =>
            history.push(`/admin${generatePath(paths.stores.new)}`)
          }
        >
          {intl.formatMessage({ id: "storeList.table.addStore" })}
        </Button>
      </>
    );
  };

  const renderCompanyButtons = () => {
    return (
      <>
        <hr />
        <Button
          color="info"
          size="sm"
          round
          disabled={filterCompanies.length <= 0}
          className={classes.floatRight}
          onClick={() =>
            history.push({
              pathname: `/admin${generatePath(paths.companies.list)}`,
              state: { id: { in: filterCompanies } },
            })
          }
        >
          {intl.formatMessage({ id: "companyList.table.allCompanies" })}
        </Button>
        <Button
          color="info"
          size="sm"
          round
          className={classes.floatRight}
          onClick={() =>
            history.push(`/admin${generatePath(paths.companies.new)}`)
          }
        >
          {intl.formatMessage({ id: "companyList.table.addCompany" })}
        </Button>
      </>
    );
  };

  const processAccountStatus = () => {
    const isActivated = pathOr(false, ["isActivated"], userData);
    const isBlocked = pathOr(false, ["isBlocked"], userData);

    if (isActivated && !isBlocked) {
      return (
        <Box color="success.main">
          {intl.formatMessage({ id: "userStatus.activated" })}
        </Box>
      );
    } else if (isBlocked) {
      return (
        <Box color="error.main">
          {intl.formatMessage({ id: "userStatus.blocked" })}
        </Box>
      );
    } else if (!isActivated && !isBlocked) {
      return (
        <Box color="warning.main">
          {intl.formatMessage({ id: "userStatus.unactivated" })}
        </Box>
      );
    }
  };

  let checkStatus = pathOr(false, ["isActivated"], userData) ? false : true;

  let headerActions = [];
  if (
    checkStatus &&
    pathOr("", ["user", "signInMethod"], data) === "Credentials"
  ) {
    headerActions.push(
      {
        title: intl.formatMessage({
          id: "userDetail.headerActions.activateUser",
        }),
        onClick: (e) => setActivateModalOpen(true),
        color: "success",
      },
      {
        title: intl.formatMessage({
          id: "userDetail.headerActions.sendActivateEmail",
        }),
        onClick: (e) => handleUserSendActivationEmail(),
      }
    );
  }

  if (userRole !== RoleEnum.ADMIN) {
    headerActions.push(
      {
        id: "blockUserAction",
        title: intl.formatMessage({ id: "userDetail.headerActions.blockUser" }),
        onClick: (e) => setBlockUserModalOpen(true),
      },
      {
        id: "resetPasswordAction",
        title: intl.formatMessage({
          id: "userDetail.headerActions.resetPassword",
        }),
        onClick: (e) => handleResetPassword(),
      },
      {
        title: intl.formatMessage({
          id: "userDetail.headerActions.deleteUser",
        }),
        color: "danger",
        onClick: (e) => setDeleteUserModalOpen(true),
      }
    );
  } else
    headerActions.push(
      {
        id: "blockUserAction",
        title: intl.formatMessage({ id: "userDetail.headerActions.blockUser" }),
        onClick: (e) => setBlockUserModalOpen(true),
      },
      {
        id: "resetPasswordAction",
        title: intl.formatMessage({
          id: "userDetail.headerActions.resetPassword",
        }),
        onClick: (e) => handleResetPassword(),
      }
    );

  if (userData.isBlocked) {
    headerActions = headerActions.filter(
      (action) => action.id !== "blockUserAction"
    );
    headerActions.unshift({
      id: "allowUserAction",
      title: intl.formatMessage({ id: "userDetail.headerActions.allowUser" }),
      color: "success",
      onClick: (e) => setUnblockModalOpen(true),
    });
  }
  if (data?.user?.ambassador?.isEnabled) {
    headerActions.push({
      id: "inviteAmbassador",
      title: intl.formatMessage({
        id: "userDetail.headerActions.inviteAmbassador",
      }),
      onClick: (e) => handleAmbassadorSendInfoMail(),
    });
  }

  if (["Facebook", "Google"].includes(userData.signInMethod)) {
    headerActions = headerActions.filter(
      (action) => action.id !== "resetPasswordAction"
    );
  }

  const getPartnerContent = (formikProps) => (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={12} md={6} lg={4}>
        <Grid>
          <IconCardOneItem
            icon={<NotificationsIcon />}
            headerText={intl.formatMessage({
              id: "userDetail.statusCard.status",
            })}
            bodyText={processAccountStatus()}
          />
        </Grid>
        <Grid>
          <ContactData
            data={formikProps.values}
            formikProps={formikProps}
            disabled={true}
            created={false}
            canEditUser={canEditUser}
            id={id}
            refetchUserData={refetchUserData}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={8}>
        <IconCard
          icon={<Business />}
          title={intl.formatMessage({ id: "userDetail.companyTable.title" })}
        >
          <CompanyTable
            query={COMPANY_BY_USER(id)}
            queryDataPath={["companiesTableByMember", "items"]}
            userId={id}
            defaultFilter={null}
            history={history}
            numberOfRows={5}
          />
          {renderCompanyButtons()}
        </IconCard>

        <IconCard
          icon={<ShoppingBasketIcon />}
          title={intl.formatMessage({ id: "userDetail.storeTable.title" })}
        >
          <StoreTable
            data={dataStores}
            loadData={loadStores}
            queryDataPath={["storesTableByUser", "items"]}
            queryVariables={{ userId: id }}
            userId={id}
            defaultFilter={null}
            history={history}
            detail={true}
            numberOfRows={5}
          />
          {renderStoreButtons()}
        </IconCard>
      </Grid>
    </Grid>
  );

  const getUserContent = (formikProps) => (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={12} md={6} lg={4}>
        <ContactData
          data={formikProps.values}
          formikProps={formikProps}
          disabled={true}
          created={false}
          canEditUser={canEditUser}
          id={id}
          refetchUserData={refetchUserData}
        />
        <StatusCard
          status={processAccountStatus}
          credits={pathOr("", ["user", "primaryAccount", "balance"], data)}
          subscriptionCredits={pathOr(
            "",
            ["user", "subscriptionAccount", "balance"],
            data
          )}
          subscriptionStatus={pathOr(
            "",
            ["user", "subscription", "plan", "name"],
            data
          )}
          canceledStatus={pathOr("", ["user", "subscription", "status"], data)}
          orders={pathOr("", ["user", "stats", "orders", "totalCount"], data)}
          photos={pathOr("", ["user", "stats", "photos", "printedCount"], data)}
          userId={pathOr("", ["user", "id"], data)}
          userName={pathOr("", ["user", "fullName"], data)}
          userEmail={pathOr("", ["user", "email"], data)}
          createdAt={pathOr("", ["user", "createdAt"], data)}
          signInMethod={pathOr("", ["user", "signInMethod"], data)}
          updatedAt={pathOr("", ["user", "updatedAt"], data)}
          refetch={refetchUserData}
        />
        <AmbassadorCard
          data={formikProps.values}
          formikProps={formikProps}
          isAmbassador={pathOr("", ["user", "ambassador"], data)}
          isEnabled={pathOr("", ["user", "ambassador", "isEnabled"], data)}
          type={pathOr("", ["user", "ambassador", "type"], data)}
          qrcodeUrl={pathOr("", ["user", "ambassador", "qrcodeUrl"], data)} 
          disabled={true}
          created={false}
          canEditUser={canEditUser}
          defaultFilter={{ id: 1 }}
          id={id}
          refetchUserData={refetchUserData}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={8}>
        <IconCard
          title={intl.formatMessage({ id: "userDetail.orders" })}
          icon={<ShoppingBasketIcon />}
        >
          {ordersTotalCount > 0 ? (
            <>
              <OrderTable
                toggle={true}
                history={history}
                query={ORDERS_BY_USER(id)}
                detail={true}
                queryDataPath={["ordersTableByUser", "items"]}
                numberOfRows={5}
              />
              {renderOrdersButtons()}
            </>
          ) : (
            <>{intl.formatMessage({ id: "userDetail.ordersTable.empty" })}</>
          )}
        </IconCard>
        <IconCard
          icon={<AccountBalanceWalletIcon />}
          title={intl.formatMessage({ id: "userDetail.payments" })}
        >
          {paymentsTotalCount > 0 ? (
            <>
              <PaymentTable
                toggle={true}
                history={history}
                query={PAYMENTS_BY_USER(id)}
                detail={true}
                queryDataPath={["paymentsTableByUser", "items"]}
                numberOfRows={5}
              />
              {renderPaymentsButtons()}
            </>
          ) : (
            <>{intl.formatMessage({ id: "userDetail.paymentsTable.empty" })}</>
          )}
        </IconCard>
        <IconCard
          icon={<SwapHorizIcon />}
          title={intl.formatMessage({ id: "userDetail.operations" })}
        >
          {operationsTotalCount > 0 ? (
            <>
              <OperationTable
                toggle={true}
                history={history}
                query={OPERATIONS_TABLE_BY_USER(id)}
                queryDataPath={["operationsTableByUser", "items"]}
                numberOfRows={5}
              />
              {renderOperationsButtons()}
            </>
          ) : (
            <>
              {intl.formatMessage({ id: "userDetail.operationsTable.empty" })}
            </>
          )}
        </IconCard>
        {data?.user?.ambassador && (
          <>
            <IconCard
              icon={<AddToHomeScreenIcon />}
              title={intl.formatMessage({
                id: "userDetail.ambassadorReferralsTable.title",
              })}
            >
              {ambassadorRefferalsTotalCount > 0 ? (
                <AmbassadorReferralsTable
                  toggle={true}
                  history={history}
                  query={AMBASSADOR_REFERRALS_TABLE}
                  detail={true}
                  defaultFilter={{
                    ambassadorId: { eq: Number(data?.user?.ambassador?.id) },
                  }}
                  queryDataPath={["ambassadorReferralsTable", "items"]}
                  numberOfRows={5}
                />
              ) : (
                <>
                  {intl.formatMessage({
                    id: "userDetail.ambassadorReferralsTable.empty",
                  })}
                </>
              )}
            </IconCard>

            <IconCard
              icon={<SubjectIcon />}
              title={intl.formatMessage({
                id: "userDetail.ambassadorCommissionsTable.title",
              })}
            >
              {ambassadorCommissoonsTotalCount > 0 ? (
                <AmbassadorCommissonsTable
                  toggle={true}
                  history={history}
                  query={AMBASSADOR_COMMISSIONS_TABLE}
                  detail={true}
                  defaultFilter={{
                    ambassadorId: { eq: Number(data?.user?.ambassador?.id) },
                  }}
                  queryDataPath={["ambassadorCommissionsTable", "items"]}
                  numberOfRows={3}
                />
              ) : (
                <>
                  {intl.formatMessage({
                    id: "userDetail.ambassadorCommissionsTable.empty",
                  })}
                </>
              )}
            </IconCard>
            <IconCard
              icon={<EqualizerIcon />}
              title={intl.formatMessage({
                id: "userDetail.ambassadorReferralsChart.title",
              })}
            >
              <AmbassadorReferralsChart
                allReferrals={pathOr(
                  "",
                  ["ambassadorReferralsChart"],
                  dataAmbassadorReferralsChart
                )}
              />
            </IconCard>
          </>
        )}
      </Grid>
    </Grid>
  );

  const getMaintainerContent = (formikProps) => (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={12} md={6} lg={4}>
        <ContactData
          data={formikProps.values}
          formikProps={formikProps}
          disabled={true}
          canEditUser={editableFields}
          editableFields={editableFields}
          id={id}
          refetchUserData={refetchUserData}
          created={false}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={4}>
        <IconCardOneItem
          icon={<NotificationsIcon />}
          headerText={intl.formatMessage({
            id: "userDetail.statusCard.status",
          })}
          bodyText={processAccountStatus()}
        />
      </Grid>
    </Grid>
  );

  const getAdvertiserContent = (formikProps) => (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={12} md={6} lg={4}>
        <Grid>
          <IconCardOneItem
            icon={<NotificationsIcon />}
            headerText={intl.formatMessage({
              id: "userDetail.statusCard.status",
            })}
            bodyText={processAccountStatus()}
          />
        </Grid>
        <Grid>
          <ContactData
            data={formikProps.values}
            formikProps={formikProps}
            disabled={true}
            created={false}
            canEditUser={canEditUser}
            id={id}
            refetchUserData={refetchUserData}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={8}>
        <IconCard
          icon={<Business />}
          title={intl.formatMessage({ id: "userDetail.companyTable.title" })}
        >
          <CompanyTable
            query={COMPANY_BY_USER(id)}
            queryDataPath={["companiesTableByMember", "items"]}
            queryVariables={{ userId: id }}
            userId={id}
            partnerPage={true}
            defaultFilter={null}
            history={history}
            numberOfRows={5}
          />
          {renderCompanyButtons()}
        </IconCard>
      </Grid>
    </Grid>
  );

  const getContentByUserRole = (formikProps) => {
    if (userRole === RoleEnum.USER) {
      return getUserContent(formikProps);
    } else if (userRole === RoleEnum.PARTNER) {
      return getPartnerContent(formikProps);
    } else if (
      userRole === RoleEnum.MAINTAINER ||
      userRole === RoleEnum.ADMIN
    ) {
      return getMaintainerContent(formikProps);
    } else if (userRole === RoleEnum.ADVERTISER) {
      return getAdvertiserContent(formikProps);
    }
    return null;
  };

  if (loading) return <Loading />;
  return (
    <>
      <Formik
        enableReinitialize
        initialValues={userData}
        onSubmit={(values) => handleUserActivate(values)}
        validationSchema={
          userRole !== RoleEnum.USER && getPasswordValidation(intl)
        }
      >
        {(formikProps) => (
          <Modal
            open={activateModalOpen}
            onClose={() => setActivateModalOpen(false)}
            title={intl.formatMessage({
              id: "userDetail.activateUser.modalTitle",
            })}
            maxWidth="sm"
            fullWidth
            actions={
              <>
                <Button
                  onClick={(e) => formikProps.handleSubmit()}
                  color="success"
                  round
                  size="sm"
                >
                  {intl.formatMessage({
                    id: "userDetail.activateUser.modalConfirm",
                  })}
                </Button>
                <Button
                  size="sm"
                  color="primary"
                  onClick={() => setActivateModalOpen(false)}
                  round
                >
                  {intl.formatMessage({
                    id: "invoicesList.assignModal.cancel",
                  })}
                </Button>
              </>
            }
          >
            {userRole === RoleEnum.USER ? (
              <p>
                {intl.formatMessage({
                  id: "userDetail.activateUser.modalText",
                })}
              </p>
            ) : (
              <FormikField
                name="password"
                labelText={intl.formatMessage({
                  id: "userDetail.activateUser.passwordLabel",
                })}
                formikProps={formikProps}
                highlightChange
              />
            )}
          </Modal>
        )}
      </Formik>

      <Modal
        open={unblockModalOpen}
        onClose={() => setUnblockModalOpen(false)}
        title={intl.formatMessage({ id: "userDetail.unblockUser.modalTitle" })}
        maxWidth="sm"
        fullWidth
        actions={
          <>
            <Button
              onClick={() => handleUserUnblock()}
              color="success"
              round
              size="sm"
            >
              {intl.formatMessage({
                id: "userDetail.unblockUser.modalConfirm",
              })}
            </Button>
            <Button
              size="sm"
              color="primary"
              onClick={() => setUnblockModalOpen(false)}
              round
            >
              {intl.formatMessage({ id: "invoicesList.assignModal.cancel" })}
            </Button>
          </>
        }
      >
        <p>{intl.formatMessage({ id: "userDetail.unblockUser.modalText" })}</p>
      </Modal>
      <Modal
        title={intl.formatMessage({ id: "userDetail.blockUser.modalTitle" })}
        open={blockUserModalOpen}
        onClose={() => setBlockUserModalOpen(false)}
        maxWidth="xs"
        fullWidth
        actions={
          <>
            <Button
              onClick={() => handleUserBlock()}
              color="danger"
              round
              size="sm"
            >
              {intl.formatMessage({ id: "userDetail.blockUser.modalConfirm" })}
            </Button>
            <Button
              size="sm"
              color="primary"
              onClick={() => setBlockUserModalOpen(false)}
              round
            >
              {intl.formatMessage({ id: "invoicesList.assignModal.cancel" })}
            </Button>
          </>
        }
      >
        <p>
          {intl.formatMessage({ id: "userDetail.blockUser.modalSubtitle" })}:{" "}
        </p>
        <TextField
          multiline
          fullWidth
          value={blockUserReason}
          onChange={(e) => setBlockuserReason(e.target.value)}
        />
      </Modal>
      <Modal
        title={intl.formatMessage({ id: "userDetail.deleteUser.modalTitle" })}
        open={deleteUserModalOpen}
        onClose={() => setDeleteUserModalOpen(false)}
        maxWidth="xs"
        fullWidth
        actions={
          <>
            <Button
              onClick={() => handleUserDelete()}
              color="danger"
              round
              size="sm"
            >
              {intl.formatMessage({ id: "userDetail.deleteUser.modalConfirm" })}
            </Button>
            <Button
              size="sm"
              color="primary"
              onClick={() => setDeleteUserModalOpen(false)}
              round
            >
              {intl.formatMessage({ id: "invoicesList.assignModal.cancel" })}
            </Button>
          </>
        }
      />
      <PageHeader
        title={intl.formatMessage(
          { id: "userDetail.title" },
          { userName: `${userData.firstName} ${userData.lastName}` }
        )}
        subTitle={
          userRole &&
          `${pathOr("", ["user", "bid"], data)}  ${intl.formatMessage({
            id: `userRoles.${userRole.toLowerCase()}`,
          })}`
        }
        handleBackAction={(e) => history.goBack()}
        actions={canEditUser ? headerActions : []}
        withBackButton={backButton}
      />
      <Formik
        enableReinitialize
        initialValues={userData}
        validationSchema={getValidationSchemaDetail(intl)}
      >
        {(formikProps) => getContentByUserRole(formikProps)}
      </Formik>
    </>
  );
};

export default injectIntl(withRouter(UserDetailPage));
