import gql from 'graphql-tag';

const COUPON_CAMPAIGN_CREATE = gql`
mutation couponCampaignCreate($input: CouponCampaignCreateInput!){
    couponCampaignCreate(input: $input){
        name
        id
        bid
    }
}
`;

export default COUPON_CAMPAIGN_CREATE;