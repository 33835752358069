import * as Yup from 'yup';

export const validationSchemaStep1 = (intl) =>
    Yup.object().shape({
        name: Yup.string().required(intl.formatMessage({ id: 'validation.required' })).nullable(),
        companyId: Yup.string()
            //.max(balance, (intl.formatMessage({ id: 'validation.number.max' }, [balance])))
            .required(intl.formatMessage({ id: 'validation.required' })).nullable(),
        validFrom: Yup.date()
            .required(intl.formatMessage({ id: 'validation.required' }))
            .typeError(intl.formatMessage({ id: 'validation.date' }))
            .nullable(),
        validTo: Yup.date()
            .required(intl.formatMessage({ id: 'validation.required' }))
            .typeError(intl.formatMessage({ id: 'validation.date' }))
            .min(Yup.ref('validFrom'), intl.formatMessage({ id: 'validation.graterDate' })).nullable(),
        topicIds: Yup.string().required(intl.formatMessage({ id: 'validation.required' })).nullable(),
        description: Yup.string()
            .max(100, intl.formatMessage({ id: 'validation.string.max' }, [100]))
            .nullable()
    });

export const validationSchemaStep2 = (intl) =>
    Yup.object().shape({
        mediaIds: Yup.string().required(intl.formatMessage({ id: 'validation.required' })).nullable(),
    });

export const validationSchemaStep3 = (intl, balance) =>
    Yup.object().shape({
        budget: Yup.number()
            .min(11, (intl.formatMessage({ id: 'validation.number.min' }, [11])))
            .max(balance, (intl.formatMessage({ id: 'validation.number.maxBudget' }, [balance])))
            .typeError(intl.formatMessage({ id: 'validation.number' }))
            .required(intl.formatMessage({ id: 'validation.required' })).nullable(),
        maxBid: Yup.number()
            .min(11, (intl.formatMessage({ id: 'validation.number.min' }, [11])))
            .typeError(intl.formatMessage({ id: 'validation.number' }))
            .required(intl.formatMessage({ id: 'validation.required' })).nullable(),
        minRepeatInterval: Yup.number()
            .min(0, intl.formatMessage({ id: 'validation.number.positive' }))
            .typeError(intl.formatMessage({ id: 'validation.number' }))
            .required(intl.formatMessage({ id: 'validation.required' })).nullable(),
    });

export const validationSchemaStep4 = (intl) =>
Yup.object().shape({
    criteria: Yup.object().shape({
        creditAccountBalance: Yup.number()
            .integer(intl.formatMessage({ id: 'validation.number.integer' }))
            .typeError(intl.formatMessage({ id: 'validation.number' }))
            .min(0, intl.formatMessage({ id: 'validation.number.min' }, [0]))
            .nullable(),
        revenue: Yup.number()
            .integer(intl.formatMessage({ id: 'validation.number.integer' }))
            .typeError(intl.formatMessage({ id: 'validation.number' }))
            .min(0, intl.formatMessage({ id: 'validation.number.min' }, [0]))
            .nullable(),
    }),
});

export const validationSchemaStep5 = (intl) =>
    Yup.object().shape({
        minRepeatInterval: Yup.number()
            .min(0, intl.formatMessage({ id: 'validation.number.positive' }))
            .typeError(intl.formatMessage({ id: 'validation.number' }))
            .nullable(),
    });

