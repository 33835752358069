import React, { useEffect, useState } from "react";
import { injectIntl } from "react-intl";
import { generatePath, withRouter } from "react-router-dom";
import CampaignTypeEnum from "common/enums/CampaignType";
import paths from "paths";
import PageHeader from "components-lib/PageHeader/PageHeader";
import IconCard from "components-lib/IconCard/IconCard";
import FFCampaignsIcon from 'components-lib/Icons/FFCampaignsIcon/FFCampaignsIcon';
import QRCampaignsIcon from 'components-lib/Icons/QRCampaignsIcon/QRCampaignsIcon';
import CampaignTable from "./CampaignTable";
import CouponTable from "./CouponTable";
import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import NavPills from "components/NavPills/NavPills.js";
import Modal from "components-lib/Modal/Modal";
import Button from "components/CustomButtons/Button.js";
import { Formik } from 'formik';
import { useSnackbar } from "notistack";
import ListIcon from '@material-ui/icons/List';
import { getValidationSchemaNewQRCouponSet, getValidationSchemaNewPQRCoupon } from './validationSchema';
import FormikField from 'components-lib/FormikField/FormikField';
import COUPON_SET_CREATE from "queries/CampaignsQueries/couponSetCreate"
import ALL_CAMPAIGNS from "queries/CampaignsQueries/allCampaigns"
import ALL_COUPON_CAMPAIGNS from "queries/CampaignsQueries/allCouponCampaigns"
import ALL_COUPON_SETS from "queries/CampaignsQueries/allCouponSets"
import CouponsFinderBox from "./CampaignCards/CouponsFinderBox"


const CampaignListPage = (props) => {
    const { intl, history } = props
    const { enqueueSnackbar } = useSnackbar();

    const [assignModalOpen, setAssignModalOpen] = useState(false);
    const [campaignType, setCampaignType] = useState(null);
    const [queryVariables, setQueryVariables] = useState(null);
    const [activeState, setActiveState] = useState(0);
    const [type, setType] = useState(CampaignTypeEnum.QR)

    const [createCouponSet] = useMutation(COUPON_SET_CREATE);
    const [loadCoupons, { data: dataCoupons, refetch: refetchCoupons }] = useLazyQuery(ALL_COUPON_SETS);

    useEffect(() => {
        if (history.location.pathname.indexOf(CampaignTypeEnum.FREEPHOTO) > -1) {
            setCampaignType(CampaignTypeEnum.FREEPHOTO);
            setQueryVariables({ type: CampaignTypeEnum.FREEPHOTO });
        }
        if (history.location.pathname.indexOf(CampaignTypeEnum.QR) > -1) {
            setCampaignType(CampaignTypeEnum.QR);
            setQueryVariables({ type: CampaignTypeEnum.QR });
        }
        return () => {
            setCampaignType(null);
            setQueryVariables(null);
        };
    }, [history.location.pathname]);

    const createSetOfCoupons = (values, resetForm) => {
        let sourceType = (type === CampaignTypeEnum.QR) ? "Qr" : "PublicQr"
        createCouponSet({
            variables: {
                type: sourceType,
                nominalValue: Number(values.nominalValue),
                count: (type === CampaignTypeEnum.QR) ? Number(values.count) : undefined,
            },
        }).then((response) => {
            resetForm();
            refetchCoupons();
            enqueueSnackbar(intl.formatMessage({ id: "campaigns.newCoupons.success" }), { variant: "success", });
        }).catch((err) => {
            console.log('[error]', err)
        });
        setAssignModalOpen(false);
    };

    const getActions = () => {
        let actions = []
        if (campaignType === CampaignTypeEnum.FREEPHOTO) {
            actions = [
                {
                    title: intl.formatMessage({ id: "campaigns.button.newCampaign" }),
                    //onClick: () => history.push(`/admin${generatePath(paths.campaigns.FreePhotoNew)}`),
                    onClick: () => history.push(`/admin${generatePath(paths.campaigns.FreePhotoNewBid)}`)
                },
            ]
        } else {
            if (activeState === 0) {
                actions = [
                    {
                        title: intl.formatMessage({ id: "campaigns.button.newQRCampaign" }),
                        onClick: () => history.push(`/admin${generatePath(paths.campaigns.QRNew)}`)
                    },
                    {
                        title: intl.formatMessage({ id: "campaigns.button.newPQRCampaign" }),
                        onClick: () => history.push(`/admin${generatePath(paths.campaigns.PQRNew)}`)
                    }
                ]
            }
            else {
                actions = [
                    {
                        title: intl.formatMessage({ id: "campaigns.button.newCouponSet" }),
                        onClick: () => { setType(CampaignTypeEnum.QR); setAssignModalOpen(true) },
                    },
                    {
                        title: intl.formatMessage({ id: "campaigns.button.newPQRCoupon" }),
                        onClick: () => { setType(CampaignTypeEnum.PQR); setAssignModalOpen(true) },
                    }
                ]
            }
        }

        return actions
    };
    //console.log(intl.formatMessage({ id: "campaigns.newCoupons.create" }));
    const renderAssignActions = (formikProps) => {
        return (
            <>
                <Button
                    disabled={!formikProps.values.nominalValue || !formikProps.isValid}
                    size="sm"
                    color="success"
                    onClick={(e) => formikProps.handleSubmit()}
                    round
                    
                >
                    {intl.formatMessage({ id: "campaigns.newCoupons.create" })}
                </Button>
                <Button
                    size="sm"
                    color="primary"
                    onClick={() => { setAssignModalOpen(false); formikProps.resetForm() }}
                    round
                >
                    {intl.formatMessage({ id: "invoicesList.assignModal.cancel" })}
                </Button>
            </>
        );
    };
    const renderTable = () => {
        if (campaignType === CampaignTypeEnum.FREEPHOTO) {
            return <CampaignTable
                query={ALL_CAMPAIGNS}
                queryVariables={queryVariables}
                type={campaignType}
                toggle={false}
                history={history}
                queryDataPath={['campaignsTable', 'items']}
            />
        }
        else {
            return <NavPills
                color="info"
                active={activeState}
                callback={(newValue) => setActiveState(newValue)}
                tabs={[
                    {
                        tabButton: intl.formatMessage({ id: "campaigns.toggle.qrCampaign" }),
                        tabContent: (
                            <CampaignTable
                                query={ALL_COUPON_CAMPAIGNS}
                                //queryVariables={queryVariables}
                                type={CampaignTypeEnum.QR}
                                history={history}
                                toggle={true}
                                queryDataPath={['couponCampaignsTable', 'items']}
                            />
                        ),
                    },
                    {
                        tabButton: intl.formatMessage({ id: "campaigns.toggle.couponSet" }),
                        tabContent: (
                            <CouponTable
                                loadData={loadCoupons}
                                data={dataCoupons}
                                defaultFilter={{ status: { eq: "Unassigned" } }}
                                refetch={refetchCoupons}
                                type={campaignType}
                                history={history}
                                toggle={true}
                                queryDataPath={['couponSetsTable', 'items']}
                            />

                        ),
                    },

                ]}
            />
        }
    }

    return (
        <>
            <PageHeader
                title={campaignType === CampaignTypeEnum.FREEPHOTO
                    ? intl.formatMessage({ id: "campaigns.freefoto.title" })
                    : (activeState === 0)
                        ? intl.formatMessage({ id: "campaigns.qr.title" })
                        : intl.formatMessage({ id: "campaigns.toggle.couponSet" })
                }
                actions={getActions()}
                withBackButton={false}
            />

            <IconCard
                icon={campaignType === CampaignTypeEnum.FREEPHOTO
                    ? <FFCampaignsIcon raw={true} />
                    : (activeState === 0) ? <QRCampaignsIcon raw={true} /> : <ListIcon />}
                title={
                    campaignType === CampaignTypeEnum.FREEPHOTO
                        ? intl.formatMessage({ id: "campaigns.freefoto.header" })
                        : (activeState === 0)
                            ? intl.formatMessage({ id: "campaigns.qr.header" })
                            : intl.formatMessage({ id: "campaigns.coupons.header" })
                }
            >
                {(campaignType !== CampaignTypeEnum.FREEPHOTO) &&
                    <CouponsFinderBox history={history} intl={intl} />
                }
                {renderTable()}
            </IconCard>
            <Formik
                initialValues={{
                    title: '',
                    text: ''
                }}
                onSubmit={(values, { resetForm }) => { createSetOfCoupons(values, resetForm) }}
                validationSchema={(type === CampaignTypeEnum.QR) ? getValidationSchemaNewQRCouponSet(intl) : getValidationSchemaNewPQRCoupon(intl)}
            >
                {(formikProps) => (
                    <Modal
                        title={type === CampaignTypeEnum.QR ? intl.formatMessage({ id: "campaigns.newCoupons.title" }) : intl.formatMessage({ id: "campaigns.newPQRCoupons.title" })}
                        open={assignModalOpen}
                        onClose={() => { setAssignModalOpen(false); formikProps.resetForm() }}
                        actions={renderAssignActions(formikProps)}
                    >
                        <FormikField
                            name="nominalValue"
                            formikProps={formikProps}
                            labelText={intl.formatMessage({ id: "campaigns.newCoupons.nominalValue" })}
                        />
                        {(type === CampaignTypeEnum.QR) &&
                            <FormikField
                                name="count"
                                formikProps={formikProps}
                                labelText={intl.formatMessage({ id: "campaigns.newCoupons.couponCount" })}
                            />
                        }
                    </Modal>
                )}
            </Formik>
        </>
    )
}

export default injectIntl(withRouter(CampaignListPage))