import { useQuery, useLazyQuery } from '@apollo/react-hooks';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import IconCard from 'components-lib/IconCard/IconCard';
import PageHeader from 'components-lib/PageHeader/PageHeader';
import Button from "components/CustomButtons/Button.js";
import paths from 'paths';
import { pathOr } from 'rambda';
import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { generatePath, withRouter } from "react-router-dom";
import styles from "common/styles/widgets.js";
import StoreTable from '../Stores/StoreTable';
import PartnerTable from './CompanyCards/PartnerTable';
import AddressCard from './CompanyCards/AddressCard';
import BillingInfoCard from './CompanyCards/BillingInfoCard';
import CommissionCard from './CompanyCards/CommissionCard';
import ContactPersonCard from './CompanyCards/ContactPersonCard';
import AccountStatusCard from './CompanyCards/AccountStatusCard';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import StoreIcon from '@material-ui/icons/Store';
import Loading from "components-lib/Loading/Loading";
import RoleEnum from "common/enums/RoleEnum";
import { isInRoles } from "helpers/helpers";
import COMPANY_DETAIL from 'queries/CompaniesQueries/companyDetail';
import STORES_BY_COMPANY from "queries/CompaniesQueries/storesByCompany";
import PARTNERS_BY_COMPANY from 'queries/CompaniesQueries/partnersByCompany';

const useStyles = makeStyles((theme) => styles(theme))

const CompanyDetailPage = (props) => {
    const { intl, history } = props;
    const classes = useStyles();

    const companyId = props.match.params.companyId;
    const queryParams = { id: companyId }
    const [filterStores, setFilterStores] = useState([])
    const { loading, data } = useQuery(COMPANY_DETAIL, { variables: queryParams, });
    const [partnersLoadData, { data: partnersTable, refetch: refetchPartnersTable }] = useLazyQuery(PARTNERS_BY_COMPANY(queryParams.id));
    const [storesLoadData, { data: storesTable }] = useLazyQuery(STORES_BY_COMPANY(queryParams.id));

    useEffect(() => {
        let storesSelectItems = pathOr([], 'storesTableByCompany.items', storesTable).map((store) => {
            return Number(store.id)
        })
        setFilterStores(storesSelectItems);
    }, [storesTable]);

    const renderStoreButtons = () => {
        if (!isInRoles([RoleEnum.ADMIN, RoleEnum.MAINTAINER])) {
            return (
                <>
                    <hr />
                    <Button
                        color="info"
                        size="sm"
                        round
                        disabled={filterStores.length <= 0}
                        className={classes.floatRight}
                        onClick={() => history.push({
                            pathname: `/admin${generatePath(paths.stores.list)}`,
                            state: { id: { in: filterStores } }
                        })}
                    >
                        {intl.formatMessage({ id: 'storeList.table.allStore' })}
                    </Button>
                </>
            )
        }
    }

    const renderPartnerButtons = () => {
        if (!isInRoles([RoleEnum.ADMIN, RoleEnum.MAINTAINER])) {
            return;
        }
    }

    const renderStoreTable = () => {
        return <>
            <StoreTable
                history={history}
                data={storesTable}
                loadData={storesLoadData}
                queryDataPath={['storesTableByCompany', 'items']}
                detail={true}
            />
            {renderStoreButtons()}
        </>
    }


    if (loading) return <Loading />
    return (
        <>
            <PageHeader
                title={pathOr('', ['company', 'name'], data)}
                subTitle={pathOr('', ['company', 'bid'], data)}
                handleBackAction={(e) => history.goBack()}
            />
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={6} lg={8}>
                    <Grid>
                        <IconCard
                            icon={<StoreIcon />}
                            title={intl.formatMessage({ id: 'storeList.table.title' })}
                        >
                            {renderStoreTable()}
                        </IconCard>
                    </Grid>
                    <Grid>
                        <IconCard
                            icon={<AssignmentIndIcon />}
                            title={intl.formatMessage({ id: 'companyDetail.partnersTable.title' })}
                        >
                            <PartnerTable
                                companyId={queryParams.id}
                                history={history}
                                data={partnersTable}
                                loadData={partnersLoadData}
                                refetch={refetchPartnersTable}
                                detail={true}
                                queryDataPath={['usersTableByCompany', 'items']}
                            />

                            {renderPartnerButtons()}
                        </IconCard>
                    </Grid>
                    <Grid>
                        <CommissionCard
                            companyId={companyId}
                            history={history}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4}>
                    <AccountStatusCard
                        history={history}
                        allocated={pathOr('', ['company', 'account', 'allocated'], data)}
                        balance={pathOr('', ['company', 'account', 'balance'], data)}
                        id={pathOr('', ['company', 'account', 'id'], data)}
                        companyId={queryParams.id}
                    />
                    <AddressCard
                        readData={data.company}
                    />
                    <ContactPersonCard
                        readData={data.company}
                    />
                    <BillingInfoCard
                        readData={data.company}
                    />
                </Grid>
            </Grid>
        </>
    )
}

export default injectIntl(withRouter(CompanyDetailPage));