import { useMutation } from "@apollo/react-hooks";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CameraEnhanceIcon from "@material-ui/icons/CameraEnhance";
import AutocompleteSelect from "components-lib/AutocompleteSelect/AutocompleteSelect";
import IconCard from "components-lib/IconCard/IconCard";
import Modal from "components-lib/Modal/Modal";
import Button from "components/CustomButtons/Button.js";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { injectIntl } from "react-intl";
import { withRouter } from "react-router";
import Box from "@material-ui/core/Box";
import styles from "common/styles/widgets.js";
import DeviceErrorCodesEnum from "common/enums/DeviceErrorCodesEnum";
import moment from "moment";
import { generatePath } from "react-router-dom";
import paths from "paths";
import WarningIcon from "@material-ui/icons/Warning";
import RoleEnum from "common/enums/RoleEnum";
import { isInRoles } from "helpers/helpers";
import DEVICE_ASSIGN_ZONE from "queries/DevicesQueries/deviceAssignZone";
import FormikField from "components-lib/FormikField/FormikField";
import Tooltip from "@material-ui/core/Tooltip";

const useStyles = makeStyles((theme) => styles(theme));

const colors = {
  use: "#4caf50",
  connected: "#1e88e5",
  disconnected: "#E30404",
  unavailable: "#b0bec5",
}


const StatusAndVolumeCard = (props) => {
  const {
    history,
    intl,
    deviceId,
    deviceBid,
    status,
    issue,
    lastHeartbeat,
    isAssigned,
    formikProps,
    refetchData,
    zoneId,
    remainingPhotos,
    remainingPhotosWarning,
    printedPhotos,
    photosPerMonth,
    photosPerMonthWarning,
    availableDevices,
    refetchDevice,
  } = props;

  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  const [changeModalOpen, setChangeModalOpen] = useState(false);
  const [chosenPlayerToChange, setChosenPlayerToChange] = useState(null);
  const [deviceAssignZone] = useMutation(DEVICE_ASSIGN_ZONE);

  const getConnection = (connectionStatus) => {
    switch (connectionStatus) {
      case "InUse": return { text: intl.formatMessage({ id: "storeDetail.deviceStatusCard.connectionStatus.InUse" }), color: colors.use };
      case "Connected": return { text: intl.formatMessage({ id: "storeDetail.deviceStatusCard.connectionStatus.Connected" }), color: colors.connected };
      case "Disconnected": return { text: intl.formatMessage({ id: "storeDetail.deviceStatusCard.connectionStatus.Disconnected" }), color: colors.disconnected };
      case "Unavailable": return { text: intl.formatMessage({ id: "storeDetail.deviceStatusCard.connectionStatus.Unavailable" }), color: colors.unavailable };
      default: return { text: intl.formatMessage({ id: "storeDetail.deviceStatusCard.connectionStatus.Unknown" }), color: colors.unavailable };
    }
  }
  const mobileIcon = <path d="M0 11.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-2zm4-3a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5v5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-5zm4-3a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5v8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-8zm4-3a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5v11a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-11z" />
  const wifiIcon = <>
    <path d="M15.384 6.115a.485.485 0 0 0-.047-.736A12.444 12.444 0 0 0 8 3C5.259 3 2.723 3.882.663 5.379a.485.485 0 0 0-.048.736.518.518 0 0 0 .668.05A11.448 11.448 0 0 1 8 4c2.507 0 4.827.802 6.716 2.164.205.148.49.13.668-.049z" />
    <path d="M13.229 8.271a.482.482 0 0 0-.063-.745A9.455 9.455 0 0 0 8 6c-1.905 0-3.68.56-5.166 1.526a.48.48 0 0 0-.063.745.525.525 0 0 0 .652.065A8.46 8.46 0 0 1 8 7a8.46 8.46 0 0 1 4.576 1.336c.206.132.48.108.653-.065zm-2.183 2.183c.226-.226.185-.605-.1-.75A6.473 6.473 0 0 0 8 9c-1.06 0-2.062.254-2.946.704-.285.145-.326.524-.1.75l.015.015c.16.16.407.19.611.09A5.478 5.478 0 0 1 8 10c.868 0 1.69.201 2.42.56.203.1.45.07.61-.091l.016-.015zM9.06 12.44c.196-.196.198-.52-.04-.66A1.99 1.99 0 0 0 8 11.5a1.99 1.99 0 0 0-1.02.28c-.238.14-.236.464-.04.66l.706.706a.5.5 0 0 0 .707 0l.707-.707z" />
  </>
  const ethernetIcon = <>
    <path d="M14 13.5v-7a.5.5 0 0 0-.5-.5H12V4.5a.5.5 0 0 0-.5-.5h-1v-.5A.5.5 0 0 0 10 3H6a.5.5 0 0 0-.5.5V4h-1a.5.5 0 0 0-.5.5V6H2.5a.5.5 0 0 0-.5.5v7a.5.5 0 0 0 .5.5h11a.5.5 0 0 0 .5-.5ZM3.75 11h.5a.25.25 0 0 1 .25.25v1.5a.25.25 0 0 1-.25.25h-.5a.25.25 0 0 1-.25-.25v-1.5a.25.25 0 0 1 .25-.25Zm2 0h.5a.25.25 0 0 1 .25.25v1.5a.25.25 0 0 1-.25.25h-.5a.25.25 0 0 1-.25-.25v-1.5a.25.25 0 0 1 .25-.25Zm1.75.25a.25.25 0 0 1 .25-.25h.5a.25.25 0 0 1 .25.25v1.5a.25.25 0 0 1-.25.25h-.5a.25.25 0 0 1-.25-.25v-1.5ZM9.75 11h.5a.25.25 0 0 1 .25.25v1.5a.25.25 0 0 1-.25.25h-.5a.25.25 0 0 1-.25-.25v-1.5a.25.25 0 0 1 .25-.25Zm1.75.25a.25.25 0 0 1 .25-.25h.5a.25.25 0 0 1 .25.25v1.5a.25.25 0 0 1-.25.25h-.5a.25.25 0 0 1-.25-.25v-1.5Z" />
    <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2ZM1 2a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2Z" />
  </>
  const changeDevice = () => {
    deviceAssignZone({
      variables: {
        id: chosenPlayerToChange.value,
        zoneId: zoneId,
      },
    })
      .then(() => {
        enqueueSnackbar(
          intl.formatMessage({
            id: "deviceDetail.player.assignPlayerSuccessful",
          }),
          { variant: "success" }
        );
        setChangeModalOpen(false);
        setChosenPlayerToChange(null);
        refetchData();
        refetchDevice();
      })
      .catch((err) => {
        console.log("[error]", err);
      });
  };


  const getStatus = (status) => {
    switch (status) {
      case "Online":
        return (
          <Box className={classes.floatRight} style={{ color: "green" }}>
            <span>Online</span>
          </Box>
        );
      case "Offline":
        return (
          <Box className={classes.floatRight} style={{ color: "red" }}>
            <span>Offline</span>
          </Box>
        );
      case "Error":
        return (
          <Box className={classes.floatRight} style={{ color: "#f57c00" }}>
            <span>
              Chyba ({issue.code}): {issue.description}
            </span>
          </Box>
        );
      default:
        return "-";
    }
  };

  const renderPhotosPerMonth = () => {
    if (photosPerMonthWarning)
      return (
        <div style={{ color: "#EE9600" }}>
          {intl.formatMessage({
            id: "storeDetail.deviceStatusCard.averagePhotos",
          })}
          <Box className={classes.floatRight}>
            <WarningIcon className={classes.warningIcon} />
            <span>{photosPerMonth}</span>
          </Box>
        </div>
      );

    return (
      <>
        {intl.formatMessage({
          id: "storeDetail.deviceStatusCard.averagePhotos",
        })}
        <Box className={classes.floatRight}>{photosPerMonth}</Box>
      </>
    );
  };

  const renderRemainingPhotos = () => {
    if (remainingPhotosWarning) {
      return (
        <div style={{ color: "#E30404" }}>
          {intl.formatMessage({
            id: "storeDetail.deviceStatusCard.numberOfPhotos",
          })}
          <Box className={classes.floatRight}>
            <WarningIcon className={classes.warningIcon} />
            <span>{remainingPhotos}</span>
          </Box>
        </div>
      );
    }
    return (
      <>
        {intl.formatMessage({
          id: "storeDetail.deviceStatusCard.numberOfPhotos",
        })}
        <Box className={classes.floatRight}>{remainingPhotos}</Box>
      </>
    );
  };

  const renderChangeActions = (change) => {
    return (
      <>
        <Button
          disabled={
            chosenPlayerToChange === deviceId || chosenPlayerToChange === null
          }
          onClick={() => changeDevice()}
          color="success"
          round
          size="sm"
        >
          {change
            ? intl.formatMessage({
              id: "deviceDetail.player.assignPlayer.change",
            })
            : intl.formatMessage({
              id: "deviceDetail.player.assignPlayer.assign",
            })}
        </Button>
        <Button
          onClick={() => setChangeModalOpen(false)}
          color="primary"
          round
          size="sm"
        >
          {intl.formatMessage({ id: "deviceDetail.status.back" })}
        </Button>
      </>
    );
  };

  const renderNoDevice = () => {
    return (
      <>
        <br />
        {intl.formatMessage({ id: "deviceDetail.player.withoutPlayer.title" })}
        <Grid container>
          {!isInRoles([RoleEnum.ADMIN, RoleEnum.MAINTAINER]) ? (
            ""
          ) : (
            <Grid item sm={12}>
              <hr />
              <Button
                className={classes.floatRight}
                color="info"
                round
                size="sm"
                onClick={() => setChangeModalOpen(true)}
              >
                {intl.formatMessage({
                  id: "deviceDetail.player.assignPlayer.title",
                })}
              </Button>
            </Grid>
          )}
        </Grid>

        <Modal
          title={intl.formatMessage({
            id: "deviceDetail.player.assignPlayer.title",
          })}
          open={changeModalOpen}
          onClose={() => setChangeModalOpen(false)}
          actions={renderChangeActions()}
        >
          <AutocompleteSelect
            onChange={(rowData) => setChosenPlayerToChange(rowData)}
            className={classes.selectStyle}
            placeholder={intl.formatMessage({
              id: "deviceList.assignModal.device",
            })}
            label={intl.formatMessage({
              id: "deviceDetail.player.newPlayer.text",
            })}
            options={availableDevices}
          />
        </Modal>
      </>
    );
  };

  const renderDeviceStatus = () => {
    return (
      <>
        <Grid container spacing={1}>
          {isInRoles([RoleEnum.ADMIN, RoleEnum.MAINTAINER]) && (<>
            <Grid item xs={12} sm={6}>
              <FormikField
                name="zones.0.preferences.wifiSsid"
                labelText={intl.formatMessage({ id: "storeDetail.deviceStatusCard.wifiSsid" })}
                formikProps={formikProps}
                highlightChange
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormikField
                name="zones.0.preferences.wifiPassword"
                labelText={intl.formatMessage({ id: "storeDetail.deviceStatusCard.wifiPassword" })}
                formikProps={formikProps}
                highlightChange
              />
            </Grid>
          </>)}
          <Grid item sm={12}>
            <br />


            {intl.formatMessage({ id: "storeDetail.deviceStatusCard.connection" })}
            <Box className={classes.floatRight}>
              <Tooltip
                style={{ cursor: 'pointer' }}

                id="tooltip-top"
                title={`${intl.formatMessage({ id: "storeDetail.deviceStatusCard.connection.ethernet" })}: ${getConnection(formikProps?.values?.zones?.[0]?.device?.connection?.wan).text}`}
                placement="bottom"
              >

                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill={getConnection(formikProps?.values?.zones?.[0]?.device?.connection?.wan).color} class="bi bi-ethernet" viewBox="0 0 16 16">
                  {ethernetIcon}
                </svg>
              </Tooltip>
              &nbsp; &nbsp;
              <Tooltip
                style={{ cursor: 'pointer' }}

                id="tooltip-top"
                title={`${intl.formatMessage({ id: "storeDetail.deviceStatusCard.connection.wifi" })}: ${getConnection(formikProps?.values?.zones?.[0]?.device?.connection?.wifi).text}`}
                placement="bottom"
              >

                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill={getConnection(formikProps?.values?.zones?.[0]?.device?.connection?.wifi).color} class="bi bi-wifi" viewBox="0 0 16 16">
                  {wifiIcon}
                </svg>
              </Tooltip>
              &nbsp; &nbsp;

              <Tooltip
                style={{ cursor: 'pointer' }}
                id="tooltip-top"
                title={`${intl.formatMessage({ id: "storeDetail.deviceStatusCard.connection.mobile" })}: ${getConnection(formikProps?.values?.zones?.[0]?.device?.connection?.mobile).text}`}
                placement="bottom"
              >

                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill={getConnection(formikProps?.values?.zones?.[0]?.device?.connection?.mobile).color} class="bi bi-reception-4" viewBox="0 0 16 16">
                  {mobileIcon}
                </svg>
              </Tooltip>

            </Box>
          </Grid>
          <Grid item sm={12}>
            {intl.formatMessage({ id: "storeDetail.deviceStatusCard.ID" })}
            <Box className={classes.floatRight}>{deviceBid}</Box>
          </Grid>
          <Grid item sm={12}>
            {intl.formatMessage({ id: "storeDetail.deviceStatusCard.status" })}
            {getStatus(status)}
          </Grid>
          <Grid item sm={12}>
            {intl.formatMessage({
              id: "storeDetail.deviceStatusCard.lastHeartbeat",
            })}
            <Box className={classes.floatRight}>
              {lastHeartbeat ? (
                <span className={classes.floatRight}>
                  {moment(lastHeartbeat).format("DD.MM.YYYY HH:mm:ss")}
                </span>
              ) : (
                <span> - </span>
              )}
            </Box>
          </Grid>
          <Grid item sm={12}>
            {renderRemainingPhotos()}
          </Grid>
          {isInRoles([RoleEnum.ADMIN, RoleEnum.MAINTAINER]) ? (
            <Grid item sm={12}>
              {intl.formatMessage({
                id: "storeDetail.deviceStatusCard.printerCounter",
              })}
              <Box className={classes.floatRight}>{printedPhotos}</Box>
            </Grid>
          ) : (
            ""
          )}
          <Grid item sm={12}>
            {renderPhotosPerMonth()}
          </Grid>
        </Grid>
        <hr style={{ marginBottom: "8px", marginTop: "8px" }} />
        {
          !isInRoles([RoleEnum.ADMIN, RoleEnum.MAINTAINER]) ? (
            ""
          ) : (
            <Button
              style={{ marginBottom: 0 }}
              className={classes.floatRight}
              color="info"
              round
              size="sm"
              onClick={() => setChangeModalOpen(true)}
            >
              {intl.formatMessage({
                id: "deviceDetail.player.changePlayer.title",
              })}
            </Button>
          )
        }
        <Button
          style={{ marginBottom: 0 }}
          className={classes.floatRight}
          color="info"
          round
          size="sm"
          onClick={() =>
            history.push(
              `/admin${generatePath(paths.devices.detail, {
                deviceId: deviceId,
              })}`
            )
          }
        >
          {intl.formatMessage({
            id: "storeDetail.deviceStatusCard.fotomatDetail",
          })}
        </Button>
        <Modal
          title={intl.formatMessage({
            id: "deviceDetail.player.changePlayer.title",
          })}
          open={changeModalOpen}
          onClose={() => setChangeModalOpen(false)}
          actions={renderChangeActions(true)}
        >
          <AutocompleteSelect
            onChange={(rowData) => setChosenPlayerToChange(rowData)}
            styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
            menuPortalTarget={document.body}
            className={classes.selectStyle}
            value={chosenPlayerToChange}
            placeholder={intl.formatMessage({
              id: "deviceList.assignModal.device",
            })}
            label={
              <span style={{ color: "gray" }}>
                {intl.formatMessage({ id: "storeDetail.changePlayer.text-1" })}
                {intl.formatMessage({
                  id: "storeDetail.changePlayer.text-2",
                })}{" "}
                {deviceBid}.<br />
                {intl.formatMessage({ id: "storeDetail.changePlayer.text-3" })}
              </span>
            }
            options={availableDevices}
          />
        </Modal>
      </>
    );
  };

  return (
    <>
      <IconCard
        title={intl.formatMessage({ id: "storeDetail.deviceStatusCard.title" })}
        className={classes.cardStyle}
        icon={<CameraEnhanceIcon />}
      >
        {isAssigned ? renderDeviceStatus() : renderNoDevice()}
      </IconCard>
    </>
  );
};
export default withRouter(injectIntl(StatusAndVolumeCard));
