import { makeStyles } from '@material-ui/core/styles';
import QueryTable from 'components-lib/QueryTable/QueryTable';
import Button from "components/CustomButtons/Button.js";
import paths from 'paths';
import React from 'react';
import { injectIntl } from 'react-intl';
import { generatePath } from 'react-router-dom';
import styles from "common/styles/widgets.js";
import ButtonDetail from 'components/ButtonDetail/ButtonDetail';

const useStyles = makeStyles((theme) => styles(theme))

export const OrderTable = (props) => {
    const { history, query, queryVariables, intl, defaultFilter, queryDataPath } = props
    const classes = useStyles();

    const onCommissionDetail = (id) => {
        history.push({
            pathname: `/admin${generatePath(paths.commissions.detail, { commissionId: id })}`
        });
    }

    const getColumns = () => {
        return (
            [
                {
                    Header: intl.formatMessage({ id: 'commissionsList.table.commissionID' }),
                    accessor: 'bid',
                    sortKey: 'bid',
                    filterKey: 'bid',
                    filterOperator: 'contains'
                },
                {
                    Header: intl.formatMessage({ id: 'commissionsList.table.companyID' }),
                    accessor: 'companyBid',
                    sortKey: 'companyBid',
                    filterKey: 'companyBid',
                    filterOperator: 'contains'
                },
                {
                    Header: intl.formatMessage({ id: 'commissionsList.table.companyName' }),
                    accessor: "companyName",
                    sortKey: "companyName",
                    filterKey: 'companyName',
                    filterOperator: 'contains'
                },
                {
                    Header: intl.formatMessage({ id: 'commissionsList.table.stores' }),
                    accessor: "storesCount",
                    sortKey: "storesCount",
                    filterKey: 'storesCount',
                    filterOperator: 'eq',
                    filterDataType: 'number'
                },
                {
                    Header: intl.formatMessage({ id: 'commissionsList.table.date' }),
                    accessor: (rowData) => {
                        const str = String(rowData.period)
                        const year = str.substring(0, 4);
                        const month = str.substring(5, 7);
                        switch (month) {
                            case '01': return <span>{intl.formatMessage({ id: 'commissionsList.month.january' })} / {year}</span>;
                            case '02': return <span>{intl.formatMessage({ id: 'commissionsList.month.february' })} / {year}</span>;
                            case '03': return <span>{intl.formatMessage({ id: 'commissionsList.month.march' })} / {year}</span>;
                            case '04': return <span>{intl.formatMessage({ id: 'commissionsList.month.april' })} / {year}</span>;
                            case '05': return <span>{intl.formatMessage({ id: 'commissionsList.month.may' })} / {year}</span>;
                            case '06': return <span>{intl.formatMessage({ id: 'commissionsList.month.june' })} / {year}</span>;
                            case '07': return <span>{intl.formatMessage({ id: 'commissionsList.month.july' })} / {year}</span>;
                            case '08': return <span>{intl.formatMessage({ id: 'commissionsList.month.august' })} / {year}</span>;
                            case '09': return <span>{intl.formatMessage({ id: 'commissionsList.month.september' })} / {year}</span>;
                            case '10': return <span>{intl.formatMessage({ id: 'commissionsList.month.october' })} / {year}</span>;
                            case '11': return <span>{intl.formatMessage({ id: 'commissionsList.month.november' })} / {year}</span>;
                            case '12': return <span>{intl.formatMessage({ id: 'commissionsList.month.december' })} / {year}</span>;
                            default: return <span>{month} / {year}</span>;
                        }
                    },
                    sortKey: "period",
                    filterKey: "period",
                    filterOperator: 'between',
                    filterDataType: "dateBetween",
                    filterComponent: 'date',
                },

                {
                    Header: intl.formatMessage({ id: 'commissionsList.table.credit' }),
                    accessor: (rowData) => rowData.photosCount + intl.formatMessage({ id: 'tableTexts.piece' }),
                    sortKey: "photosCount",
                    filterKey: "photosCount",
                    filterOperator: 'eq',
                    filterDataType: 'number'
                },
                {
                    Header: intl.formatMessage({ id: 'commissionsList.table.price' }),
                    accessor: (rowData) => rowData.value + " €",
                    sortKey: "value",
                    filterKey: "value",
                    filterOperator: 'eq',
                    filterDataType: 'number'
                },
                {
                    Header: intl.formatMessage({ id: 'orderList.table.actions' }),
                    accessor: (rowData) => 
                        <div style={{ display: "flex", flexDirection: "row",  flexWrap: "wrap",
                            justifyContent: "flex-start", alignItems: "center", gap: "0.2em" }}>

                            <ButtonDetail
                            onClick={ () => onCommissionDetail(rowData.id) }
                            />
                        </div>
                }
            ]
        )
    }

    return (
        <QueryTable
            query={query}
            queryVariables={queryVariables}
            permanentFilter={defaultFilter}
            queryDataPath={queryDataPath}
            columns={getColumns()}
        />
    )
}

export default injectIntl(OrderTable);