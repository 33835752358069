import StoreIcon from '@material-ui/icons/Store';
import RoleEnum from "common/enums/RoleEnum";
import IconCard from "components-lib/IconCard/IconCard";
import PageHeader from "components-lib/PageHeader/PageHeader";
import { isInRoles } from "helpers/helpers";
import paths from "paths";
import React from "react";
import { withRouter, useLocation } from 'react-router-dom';
import { injectIntl } from "react-intl";
import { generatePath } from "react-router-dom";
import StoreTable from "./StoreTable";
import ALL_STORES from 'queries/StoresQueries/allStores';

const StoreListPage = (props) => {
  const { intl, history } = props;
  let location = useLocation();
  let defaultFilter = location.state ? location.state : null
  const getActions = () => {
    if (!isInRoles([RoleEnum.ADMIN, RoleEnum.MAINTAINER])) {
      return;
    }
    return [
      {
        title: intl.formatMessage({ id: "storeList.addModal.title" }),
        onClick: () =>
          history.push(`/admin${generatePath(paths.stores.new)}`),
      },
    ];
  };
  console.log(ALL_STORES)
  const renderTables = () => {
    return (
        <div className="table" >
          <StoreTable
          query={ALL_STORES}
          defaultFilter={defaultFilter}
          history={history}
          queryDataPath={['storesTable', 'items']}
          
        />
        </div>
        
    );
  };

  return (
    <>
      <PageHeader
        title={intl.formatMessage({ id: "storeList.title" })}
        actions={getActions()}
        withBackButton={location.state ? true : false}  
      />
      <IconCard
        icon={<StoreIcon />}
        title={intl.formatMessage({ id: "storeList.table.title" })}
      >
        {renderTables()}
      </IconCard>
    </>
  );
};
export default withRouter(injectIntl(StoreListPage));