import React, { useState, useEffect } from 'react';
import paths from "paths";
import { generatePath } from "react-router-dom";
import { getApolloClient } from "configFiles/apollo";
import { useSnackbar } from 'notistack';
import { makeStyles } from "@material-ui/core/styles";
import TextField from '@material-ui/core/TextField';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { styled } from '@material-ui/core/styles';
import { colors } from 'common/styles/configLayout';
import COUPONS_SEARCH from 'queries/CampaignsQueries/couponsSearch';

const useStyles = makeStyles({
    box: {
        width: 400,
        position: 'absolute',
        right: 10,
        top: -30,
        zIndex: 1000,
        "@media (max-width: 1100px) and (min-width: 961px)": {
            width: 300,
        },
        "@media (max-width: 1000px) and (min-width: 616px)": {
            width: 270,
        },
        "@media (max-width: 615px)": {
            display: 'none',
        },
    },
    item: {
        padding: 0,
        margin: 0,
    },

    inputRoot: {
        "&.Mui-focused .MuiInput-notched": {
            borderColor: colors.main
        },
        "&.Mui-focused .MuiLabel-notchedLabel": {
            borderColor: colors.main
        },
        '& label.Mui-focused': {
            color: 'green',
        },
        '& label.Mui-focused': {
            color: colors.main,
        },
    },
})

const StyledTextField = styled(TextField)({
    "& label": {
        //color: "white"
    },
    "& label.Mui-focused": {
        color: colors.main,
    },
    "& .MuiInput-underline:after": {
        borderBottomColor: colors.main,
    },
    "& .MuiOutlinedInput-root": {
        "&:hover fieldset": {
            borderWidth: 1
        },
        "&.Mui-focused fieldset": {
        }
    }
});

const CouponsFinderBox = (props) => {
    const { intl, history } = props;
    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();
    const client = getApolloClient(enqueueSnackbar, history, intl);

    const [filterValue, setFilterValue] = useState(false);
    const [filteredCoupons, setFilteredCopupons] = useState([]);

    const getFilteredCoupons = async () => {
        if (filterValue?.length > 2) {
            try {
                const result = await client.query({
                    query: COUPONS_SEARCH,
                    variables: {
                        query: filterValue
                    }
                });
                if (result.error) {
                    throw result.error;
                }
                if (result.errors) {
                    throw result.errors[0];
                }
                setFilteredCopupons(result?.data?.couponsSearch);

            } catch (error) {
                throw error;
            }
        }
        else {
            setFilteredCopupons([])
        }
    };

    useEffect(() => {
        getFilteredCoupons();
    }, [filterValue]);

    return (
        <div className={classes.box}>
            <Autocomplete
                className={classes.inputRoot}
                classes={{ inputRoot: classes.inputRoot }}
                options={filteredCoupons}
                getOptionLabel={(option) => option?.coupon?.bid}
                size="small"
                renderInput={(params) => <>
                    <StyledTextField {...params} label={intl.formatMessage({ id: "campaigns.finderBox.label" })} />
                    {setFilterValue(params.inputProps.value)}
                </>}
                inputProps={{
                    classes: {
                        root: classes.label,
                    },
                }}
                renderOption={(option) => (
                    <List >
                        <ListItem classes={{ root: classes.item }} >
                            <ListItemText style={{ margin: "0px" }}
                                primary={
                                    <span style={{ fontSize: '0.9em' }}>
                                        {option?.coupon?.type} {intl.formatMessage({ id: "campaigns.finderBox.coupon" })}:
                                        <span
                                            style={{ textDecoration: "underline", cursor: "pointer" }}
                                            onClick={() => history.push(
                                                (option?.coupon?.type === "Qr")
                                                    ? `/admin${generatePath(paths.campaigns.QRCouponSetDetail, { couponsetId: option?.set?.id })}`
                                                    : `/admin${generatePath(paths.campaigns.PQRCouponSetDetail, { couponsetId: option?.set?.id })}`
                                            )}>
                                            {option?.coupon?.bid}
                                        </span>
                                        <span>
                                            &nbsp;&nbsp;{intl.formatMessage({ id: "campaigns.finderBox.value" })}: ({option?.coupon?.value} C)
                                        </span>
                                    </span>
                                }
                                secondary={
                                    <span style={{ fontSize: '0.8em' }}>{intl.formatMessage({ id: "campaigns.finderBox.set" })}:
                                        <span
                                            style={{ textDecoration: "underline", cursor: "pointer" }}
                                            onClick={() => history.push(
                                                (option?.coupon?.type === "Qr")
                                                    ? `/admin${generatePath(paths.campaigns.QRCouponSetDetail, { couponsetId: option?.set?.id })}`
                                                    : `/admin${generatePath(paths.campaigns.PQRCouponSetDetail, { couponsetId: option?.set?.id })}`
                                            )}>
                                            {option?.set?.bid}
                                        </span>
                                        &nbsp;&nbsp;{intl.formatMessage({ id: "campaigns.finderBox.campaign" })}:
                                        {(option?.campaign) ?
                                            <span
                                                style={{ textDecoration: "underline", cursor: "pointer" }}
                                                onClick={() => history.push(
                                                    (option?.coupon?.type === "Qr")
                                                        ? `/admin${generatePath(paths.campaigns.QRDetail, { campaignId: option?.campaign?.id })}`
                                                        : `/admin${generatePath(paths.campaigns.PQRDetail, { campaignId: option?.campaign?.id })}`
                                                )}>
                                                {option?.campaign?.bid}
                                            </span>
                                            : <> {intl.formatMessage({ id: "campaigns.finderBox.notAssigned" })}</>
                                        }
                                        &nbsp;&nbsp;{intl.formatMessage({ id: "campaigns.finderBox.applied" })}:
                                        {(option?.redeemer) ?
                                            <span
                                                style={{ textDecoration: "underline", cursor: "pointer" }}
                                                onClick={() => history.push(`/admin${generatePath(paths.users.detail, { userId: option?.redeemer?.id })}`)}
                                            >
                                                {option?.redeemer?.bid}
                                            </span>
                                            : <> {intl.formatMessage({ id: "campaigns.finderBox.unclaimed" })}</>
                                        }
                                    </span>}
                            />
                        </ListItem>
                    </List>
                )}
                formControlProps={{
                    fullWidth: true,
                }}
            />
        </div >
    );
}

export default CouponsFinderBox;
