import { gql } from "apollo-boost";

const ALL_SETTINGS = gql`
  query settings {
    settings {
      credit {
        value
        expiration
        directPaymentFee
        registrationAward
      }
      commission {
        creditValue
        lowerRate
        higherRate
        threshold
        photosPerMonthThreshold
      }
      order {
        expiration
        freeFotoExpiration
        freeFotoDayLimit
        unlockDuration
      }
      signage {
        idleTimeout
        bannerDuration
      }
      notifications {
        printsRemainingNThreshold
        printsRemainingHSThreshold
        globalNotifcationRecipients
      }
      ambassador {
        customerReward
        referralCreditReward
        referralMoneyReward
        claimMinutesLimit
      }
      supportMail
      pricing {
        photos {
            S10x15
            S15x15
            S20x15
        }
        levels {
            number
            surcharge     
        }
    }
    cashback {
        id
        quantity
        amount
    }

    }
  }
`;

export default ALL_SETTINGS;
