import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { injectIntl } from 'react-intl';
import styles from "common/styles/widgets.js";
import { makeStyles } from '@material-ui/core/styles';
import INVOICE_DETAIL from 'queries/InvoicesAndPayoutsQueries/invoiceDetail';
import PAYOUT_DETAIL from 'queries/InvoicesAndPayoutsQueries/payoutDetail';
import PageHeader from 'components-lib/PageHeader/PageHeader';
import { pathOr } from 'rambda';
import Box from "@material-ui/core/Box";
import { Grid } from "@material-ui/core";
import IconCard from "components-lib/IconCard/IconCard";
import CreditCardIcon from '@material-ui/icons/CreditCard';
import Business from "@material-ui/icons/Business";
import { useLocation } from "react-router-dom";
import Button from "components/CustomButtons/Button";
import { generatePath } from "react-router-dom";
import paths from "paths";
import Loading from "components-lib/Loading/Loading";

const useStyles = makeStyles((theme) => styles(theme))

const InvoiceDetailPage = (props) => {
    const invoiceId = props.match.params.invoiceId;
    const queryParams = { id: invoiceId }
    const { intl, history } = props;
    const classes = useStyles();
    let url = window.location;
    let anotherAppType = url.search.substring(6)
    let appType = useLocation().state
    let type = (anotherAppType) ? anotherAppType : appType
    let backButton = (history.length > 1) ? true : false

    let query = (type === "invoice") ? INVOICE_DETAIL : PAYOUT_DETAIL
    const { data, loading } = useQuery(query, { variables: queryParams, })

    let date = String(pathOr('', [type, 'createdAt'], data)).substr(0, 10)
    let createdTime = String(pathOr('', [type, 'createdAt'], data)).substr(11, 8)

    const renderCompanyDetailsButton = () => {
        let companyId = pathOr('', [type, 'company', 'id'], data)
        return (
            <Button
                className={classes.floatRight}
                color="info"
                size="sm"
                round
                onClick={() => { history.push(`/admin${generatePath(paths.companies.detail, { companyId: companyId, })}`) }}
            >
                {intl.formatMessage({ id: 'campaigns.companyCard.details.button' })}
            </Button>
        )
    }

    if (loading) return <Loading />;
    return (
        <>
            <PageHeader
                title={(type === "invoice")
                    ? intl.formatMessage({ id: 'invoiceDetail.title' })
                    : intl.formatMessage({ id: 'payoutDetail.title' })}
                subTitle={pathOr('', [type, 'bid'], data)}
                handleBackAction={(e) => history.goBack()}
                withBackButton={backButton}
            />
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={6} lg={4}>
                    <IconCard
                        title={intl.formatMessage({ id: 'invoiceDetail.widget.title' })}
                        icon={<CreditCardIcon />}
                    >
                        <Grid container spacing={2}>
                            <Grid item sm={12}>
                                {intl.formatMessage({ id: 'invoiceDetail.price' })}
                                <Box className={classes.floatRight}>
                                    {pathOr('', [type, 'price'], data)} €
                            </Box>
                            </Grid>
                            <Grid item sm={12}>
                                {intl.formatMessage({ id: 'invoiceDetail.credit' })}
                                <Box className={classes.floatRight}>
                                    {pathOr('', [type, 'credit'], data)} C
                            </Box>
                            </Grid>

                            <Grid item sm={12}>
                                {intl.formatMessage({ id: 'invoiceDetail.ID' })}
                                <Box className={classes.floatRight}>
                                    {pathOr('', [type, 'bid'], data)}
                                </Box>
                            </Grid>
                            <Grid item sm={12}>
                                {intl.formatMessage({ id: 'invoiceDetail.identifier' })}
                                <Box className={classes.floatRight}>
                                    {pathOr('', [type, 'identifier'], data)}
                                </Box>
                            </Grid>
                            <Grid item sm={12}>
                                {intl.formatMessage({ id: 'invoiceDetail.type' })}
                                <Box className={classes.floatRight}>
                                    {(type === "invoice")
                                        ? intl.formatMessage({ id: 'invoiceDetail.title' })
                                        : intl.formatMessage({ id: 'payoutDetail.title' })
                                    }
                                </Box>
                            </Grid>
                            <Grid item sm={12}>
                                {intl.formatMessage({ id: 'invoiceDetail.description' })}
                                <Box className={classes.floatRight}>
                                    <p>{pathOr('', [type, 'description'], data).substring(0, 40)}</p>
                                    <p>{pathOr('', [type, 'description'], data).substring(41, 80)}</p>
                                    <p>{pathOr('', [type, 'description'], data).substring(81, 120)}</p>
                                </Box>
                            </Grid>
                            <Grid item sm={12}>
                                <hr />
                                {intl.formatMessage({ id: 'invoiceDetail.createdBy' })}:
                                <Box className={classes.floatRight}>
                                    {pathOr('', [type, 'createdBy', 'fullName'], data)}
                                </Box>
                            </Grid>
                            <Grid item sm={12}>
                                {intl.formatMessage({ id: 'invoiceDetail.createdAt' })}:
                                <Box className={classes.floatRight}>
                                    {date} ({createdTime})
                        </Box>
                            </Grid>
                        </Grid>
                    </IconCard>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4}>
                    <IconCard
                        title={intl.formatMessage({ id: 'campaigns.companyCard.title' })}
                        icon={<Business />}
                    >
                        <>
                            <Grid container spacing={2}>
                                <Grid item sm={12}>
                                    {intl.formatMessage({ id: 'campaigns.companyCard.name' })}
                                    <Box className={classes.floatRight}>
                                        {pathOr('', [type, 'company', 'name'], data)}
                                    </Box>
                                </Grid>
                                <Grid item sm={12}>
                                    {intl.formatMessage({ id: 'campaigns.companyCard.address' })}
                                    <Box className={classes.floatRight}>
                                        {(!pathOr('', [type, 'company', 'address'], data) && !pathOr('', [type, 'company', 'postal'], data) && !pathOr('', [type, 'company', 'city'], data)) && "-"}
                                        {(pathOr('', [type, 'company', 'address'], data)) ? pathOr('', [type, 'company', 'address'], data) + ", " : ""}
                                        {(pathOr('', [type, 'company', 'postal'], data)) ? pathOr('', [type, 'company', 'postal'], data) + ", " : ""}
                                        {(pathOr('', [type, 'company', 'city'], data)) ? pathOr('', [type, 'company', 'city'], data) : ""}
                                    </Box>
                                </Grid>
                                <Grid item sm={12}>
                                    {intl.formatMessage({ id: 'campaigns.companyCard.contact' })}
                                    <Box className={classes.floatRight}>
                                        {pathOr('', [type, 'company', 'contactFirstName'], data)} {pathOr('', [type, 'company', 'contactLastName'], data)}
                                    </Box>
                                </Grid>
                                <Grid item sm={12}>
                                    {intl.formatMessage({ id: 'campaigns.companyCard.email' })}
                                    <Box className={classes.floatRight}>
                                        {(pathOr('', [type, 'company', 'contactEmail'], data)) ? pathOr('', [type, 'company', 'contactEmail'], data) : "-"}
                                    </Box>
                                </Grid>
                                <Grid item sm={12}>
                                    {intl.formatMessage({ id: 'invoiceDetail.phone' })}
                                    <Box className={classes.floatRight}>
                                        {(pathOr('', [type, 'company', 'contactPhone'], data)) ? pathOr('', [type, 'company', 'contactPhone'], data) : "-"}
                                    </Box>
                                </Grid>
                            </Grid>
                            <Grid item sm={12}>
                                <hr />
                                {renderCompanyDetailsButton()}
                            </Grid>
                        </>
                    </IconCard>
                </Grid>
            </Grid>
        </>
    );
};

export default injectIntl(InvoiceDetailPage);