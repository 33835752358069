import { useSnackbar } from "notistack";
import React, { useState, useEffect } from "react";
import { injectIntl } from "react-intl";
import { withRouter } from "react-router-dom";
import { pathOr } from "rambda";
import { useMutation, useQuery, useLazyQuery } from "@apollo/react-hooks";
import * as axios from "axios";
import IconCard from "components-lib/IconCard/IconCard";
import Modal from "components-lib/Modal/Modal";
import PageHeader from "components-lib/PageHeader/PageHeader";
import Button from "components/CustomButtons/Button.js";
import NavPills from "components/NavPills/NavPills.js";
import MediaAcceptanceStatusEnum from "common/enums/MediaAcceptanceStatus";
import MediaCategoryEnum from "common/enums/MediaCategoryEnum";
import MediaCategorySelectEnum from "common/enums/MediaCategorySelectEnum";
import MediaTable from "./MediaTable";
import FormikField from "components-lib/FormikField/FormikField";
import { Formik } from "formik";
import { getValidationSchema } from "./validationSchema";
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { defaultColors } from "common/styles/configLayout.js";
import PhotoAlbumIcon from '@material-ui/icons/PhotoAlbum';
import { Grid } from "@material-ui/core";
import CardMedia from '@material-ui/core/CardMedia';

import ALL_MEDIA from "queries/MediaQueries/allMedia";
import ALL_MEDIA_APPROVED from "queries/MediaQueries/allMediaApproved";
import ALL_MEDIA_PENDING from "queries/MediaQueries/allMediaPending";
import ALL_MEDIA_REJECTED from "queries/MediaQueries/allMediaRejected";
import SELECT_COMPANIES from "queries/CompaniesQueries/selectCompanies"
import MEDIA_UPLOAD from "queries/MediaQueries/mediaUpload";

function LinearProgressWithLabel(props) {
    return (
        <Box
            bgcolor="white"
            style={{ border: `2px solid ${defaultColors.one}`, borderRadius: "15px", padding: "10px", width: '30%', position: "fixed", bottom: "20px", right: "20px" }}
            display="flex"
            alignItems="center"
            zIndex="tooltip"
        >
            <Box width="100%" mr={1}>
                <LinearProgress variant="determinate" {...props} />
            </Box>
            <Box minWidth={35}>
                <Typography variant="body2" color="textSecondary">{`${Math.round(
                    props.value,
                )}%`}</Typography>
            </Box>
        </Box>
    );
}

const MediaListPage = (props) => {
    const { intl, history } = props;
    const { enqueueSnackbar } = useSnackbar();

    const [uploadModalOpen, setUploadModalOpen] = useState(false);
    const [uploadedFile, setUploadedFile] = useState(null);
    const [progress, setProgress] = useState(0);
    const [progressOpen, setProgressOpen] = useState(false);
    const { data: companiesData } = useQuery(SELECT_COMPANIES)
    const [companies, setCompanies] = useState([]);
    const [mediaUrl, setMediaUrl] = useState(null);
    const [badResolution, setBadResolution] = useState(false);

    const [uploadFile] = useMutation(MEDIA_UPLOAD);
    const [loadMediaApproved, { data: dataMediaApproved, refetch: refetchMediaApproved }] = useLazyQuery(ALL_MEDIA_APPROVED);
    const [loadMediaPending, { data: dataMediaPending, refetch: refetchMediaPending }] = useLazyQuery(ALL_MEDIA_PENDING);
    const [loadMediaRejected, { data: dataMediaRejected, refetch: refetchMediaRejected }] = useLazyQuery(ALL_MEDIA_REJECTED);
    const [userData] = useState({});

    useEffect(() => {
        let companiesSelectItems = pathOr([], "companies.items", companiesData).map(
            (company) => {
                return {
                    value: pathOr("", "id", company),
                    label: `${pathOr("", "name", company)}`,
                };
            }
        );
        setCompanies(companiesSelectItems);
    }, [companiesData]);

    const mediaInputData = () => {
        let arr = [];
        Object.keys(MediaCategorySelectEnum).map(i => (
            arr.push({
                value: i,
                label: intl.formatMessage({ id: `media.table.category.${i}` })
            })
        )
        )
        return arr
    }

    const getActions = () => {
        return [
            {
                title: intl.formatMessage({ id: 'media.page.uploadButton' }),
                onClick: () => setUploadModalOpen(true),
            },
        ];
    };

    const handleSetFileUpload = (values, width, height) => {
        let _URL = window.URL || window.webkitURL;
        let file = values;
        let img = new Image();
        let imgwidth = 0;
        let imgheight = 0;
        let url = "";
        let maxwidth = width;
        let maxheight = height;

        if (_URL.createObjectURL(file)) {
            img.src = _URL.createObjectURL(file);

            img.onload = function () {
                imgwidth = this.width;
                imgheight = this.height;
                url = this.url
                if (imgwidth !== maxwidth || imgheight !== maxheight) {
                    enqueueSnackbar(intl.formatMessage({ id: 'campaigns.graficCard.errorResolution' }), { variant: "error" })
                    setMediaUrl(null)
                    setBadResolution(true)
                } else {
                    setUploadedFile(values)
                    enqueueSnackbar(intl.formatMessage({ id: "campaigns.graficCard.successUpload" }), { variant: "success" })
                    setMediaUrl(img.src)

                }
            }
        }

    }

    const handleFileUpload = async (values, resetForm) => {
        setUploadedFile(null);
        setUploadModalOpen(false);
        try {
            const response = await uploadFile({
                variables: {
                    input: {
                        category: values.category,
                        name: values.name,
                        companyId: values.company
                    }
                }
            });
            window.onbeforeunload = function () {
                return "Are you sure to leave this page?";
            }
            const size = uploadedFile.size
            await axios.put(response.data.mediaUpload[0].url.url, uploadedFile, {
                onUploadProgress: progressEvent => {
                    setProgressOpen(true)
                    const percentage = (100 * progressEvent.loaded) / size
                    setProgress(percentage.toFixed(0))

                }
            });
            window.onbeforeunload = null;
            setProgressOpen(false)
            setUploadedFile(null);
            resetForm();
            refetchMediaApproved()
            refetchMediaPending()
            refetchMediaRejected()
            enqueueSnackbar(intl.formatMessage({ id: "media.uploadModal.success" }), { variant: "success" })
        } catch (error) {
            console.log('[error]')
        }
    }

    const renderModalButtons = (formikProps) => {
        return (
            <>
                <Button
                    disabled={!uploadedFile || !formikProps.values.name || !formikProps.values.category || !formikProps.values.company}
                    size="sm"
                    color="success"
                    onClick={(e) => formikProps.handleSubmit()}
                    round
                >
                    {intl.formatMessage({ id: "media.uploadModal.confirmButton" })}
                </Button>

                <Button
                    size="sm"
                    color="primary"
                    onClick={() => setUploadModalOpen(false)}
                    round
                >
                    {intl.formatMessage({ id: "media.uploadModal.cancelButton" })}
                </Button>
            </>
        );
    };

    const renderTables = () => {
        return (
            <NavPills
                color="info"
                tabs={[
                    {
                        tabButton: intl.formatMessage({ id: 'media.table.approved' }),
                        tabContent: (
                            <MediaTable
                                data={dataMediaApproved}
                                loadData={loadMediaApproved}
                                refetchMediaApproved={refetchMediaApproved}
                                refetchMediaPending={refetchMediaPending}
                                refetchMediaRejected={refetchMediaRejected}
                                queryDataPath={['mediaTable', 'items']}
                                acceptanceStatus={MediaAcceptanceStatusEnum.APPROVED}
                                defaultFilter={{ acceptanceStatus: { eq: MediaAcceptanceStatusEnum.APPROVED } }}
                                history={history}
                            />
                        ),
                    },
                    {
                        tabButton: intl.formatMessage({ id: 'media.table.pending' }),
                        tabContent: (
                            <MediaTable
                                data={dataMediaPending}
                                loadData={loadMediaPending}
                                refetchMediaPending={refetchMediaPending}
                                refetchMediaApproved={refetchMediaApproved}
                                refetchMediaRejected={refetchMediaRejected}
                                queryDataPath={['mediaTable', 'items']}
                                acceptanceStatus={MediaAcceptanceStatusEnum.AWAITING}
                                defaultFilter={{ acceptanceStatus: { eq: MediaAcceptanceStatusEnum.AWAITING } }}
                                history={history}
                            />
                        ),
                    },
                    {
                        tabButton: intl.formatMessage({ id: 'media.table.rejected' }),
                        tabContent: (
                            <MediaTable
                                data={dataMediaRejected}
                                loadData={loadMediaRejected}
                                refetchMediaApproved={refetchMediaApproved}
                                refetchMediaRejected={refetchMediaRejected}
                                refetchMediaPending={refetchMediaPending}
                                queryDataPath={['mediaTable', 'items']}
                                acceptanceStatus={MediaAcceptanceStatusEnum.REJECTED}
                                defaultFilter={{ acceptanceStatus: { eq: MediaAcceptanceStatusEnum.REJECTED } }}
                                history={history}
                                query={ALL_MEDIA}
                                rejected={true}
                            />
                        ),
                    },
                ]}
            />
        );
    };

    return (
        <>
            {progressOpen && <LinearProgressWithLabel value={progress} />}
            <PageHeader
                title={intl.formatMessage({ id: 'media.page.header' })}
                actions={getActions()}
                withBackButton={false}
            />
            <IconCard
                icon={<PhotoAlbumIcon />}
                title={intl.formatMessage({ id: "media.page.title" })}
            >
                {renderTables()}
            </IconCard>

            <Formik
                onSubmit={(values, { resetForm }) => handleFileUpload(values, resetForm)}
                initialValues={userData}
                validationSchema={getValidationSchema(intl)}
            >
                {(formikProps) => (
                    <Modal
                        title={intl.formatMessage({ id: "media.uploadModal.title" })}
                        open={uploadModalOpen}
                        onClose={() => setUploadModalOpen(false)}
                        actions={renderModalButtons(formikProps)}
                        fullWidth
                    >
                        <FormikField
                            name="name"
                            labelText={intl.formatMessage({ id: "media.uploadModal.name" })}
                            formikProps={formikProps}
                            highlightChange
                        />
                        <FormikField
                            type="select"
                            name="company"
                            labelText={intl.formatMessage({ id: "media.uploadModal.company" })}
                            options={companies}
                            formikProps={formikProps}
                            highlightChange
                        />
                        <FormikField
                            type="select"
                            name="category"
                            labelText={intl.formatMessage({ id: "media.uploadModal.category" })}
                            options={mediaInputData()}
                            formikProps={formikProps}
                            highlightChange
                        />
                        {(formikProps.values.category === MediaCategoryEnum.FREEPHOTOADVERTISEMENT || formikProps.values.category === MediaCategoryEnum.FREEPHOTOWATERMARK) &&
                            <>
                                <br /><br />
                                <hr style={{ margin: "2px" }} />
                                <p>{intl.formatMessage({ id: 'media.page.info' })}: </p>
                                <Grid>
                                    <p><b>{intl.formatMessage({ id: "campaigns.graficCard.rules" })}</b></p>
                                    {(formikProps.values.category === MediaCategoryEnum.FREEPHOTOWATERMARK) ?
                                        <>
                                            <p>{intl.formatMessage({ id: "campaigns.watermark.firstRule" })}</p>
                                            <p>{intl.formatMessage({ id: "campaigns.watermark.secondRule" })}</p>
                                        </>
                                        :
                                        <>
                                            <p>{intl.formatMessage({ id: "campaigns.graficCard.firstRule" })}</p>
                                            <p>{intl.formatMessage({ id: "campaigns.graficCard.secondRule" })}</p>
                                        </>
                                    }
                                </Grid>
                                <hr />
                                {(uploadedFile)
                                    ? <p>{intl.formatMessage({ id: "campaigns.graficCard.changePicture" })}</p>
                                    : <p>{intl.formatMessage({ id: "campaigns.graficCard.choosePicture" })}</p>
                                }

                                <input
                                    id="baner"
                                    name="baner"
                                    type='file'
                                    style={{ display: "true" }}
                                    width={300}
                                    accept="image/png, image/jpeg"
                                    onChange={({ target: { files } }) => {
                                        handleSetFileUpload(
                                            files[0], 600,
                                            (formikProps.values.category === MediaCategoryEnum.FREEPHOTOWATERMARK) ? 600 : 1200)
                                    }}
                                />
                                {mediaUrl ?
                                    <Grid item>
                                        <br />
                                        <CardMedia
                                            component="img"
                                            style={{ width: "100px" }}
                                            alt={mediaUrl}
                                            image={mediaUrl}
                                            title={mediaUrl}
                                        />
                                    </Grid>
                                    : <Grid item>
                                        {(badResolution) &&
                                            <span>
                                                <br />
                                                <p style={{ color: 'red' }}>{intl.formatMessage({ id: 'media.page.badResolutinPicture' })}</p>
                                            </span>
                                        }
                                    </Grid>
                                }
                                <hr style={{ margin: "4px" }} />
                            </>
                        }

                        {formikProps.values.category === MediaCategoryEnum.SIGNAGEADVERTISEMENT &&
                            <>
                                <br /><br />
                                <hr style={{ margin: "4px" }} />
                                {(uploadedFile)
                                    ? <p>{intl.formatMessage({ id: "campaigns.graficCard.changePicture" })}</p>
                                    : <p>{intl.formatMessage({ id: "campaigns.graficCard.choosePicture" })}</p>
                                }
                                <input
                                    id="baner"
                                    name="baner"
                                    type='file'
                                    style={{ display: "true" }}
                                    width={300}
                                    accept="image/png, image/jpeg"
                                    onChange={({ target: { files } }) => setUploadedFile(files[0])}
                                />
                                {uploadedFile &&
                                    <Grid item>
                                        <br />
                                        <CardMedia
                                            component="img"
                                            style={{ width: "200px" }}
                                            alt={(window.URL).createObjectURL(uploadedFile)}
                                            image={(window.URL).createObjectURL(uploadedFile)}
                                            title={(window.URL).createObjectURL(uploadedFile)}
                                        />
                                    </Grid>
                                }
                                <hr style={{ margin: "4px" }} />
                            </>
                        }
                    </Modal>
                )}

            </Formik>


        </>
    );
};

export default withRouter(injectIntl(MediaListPage));
