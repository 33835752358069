const defaultValidationErrors = {
  "validation.required": "Toto pole je povinné",
  "validation.number": "Toto pole musí obsahovať len čísla",
  "validation.number.min": "Minimálna hodnota pre toto pole je {0}",
  "validation.number.max": "Maximálna hodnota pre toto pole je {0}",
  "validation.number.maxBudget":
    "Nemôžete nastaviť vyššií rozpočet kampane ako je počet kreditov spoločnosti (max. {0})",
  "validation.number.integer": "Toto pole musí obsahovať celé číslo",
  "validation.number.positive": "Hodnota tohto poľa musí byť vyššia ako 0",
  "validation.email": "Emailová adresa nie je v správnom tvare",
  "validation.phoneNumber":
    "Telefónne číslo nie je v správnom tvare (+421903123123)",
  "validation.spaces":
    "Telefónne číslo nesmie obsahovať medzery (+421903123123)",
  "validation.iban": "IBAN je v nesprávnom tvare",
  "validation.password":
    "Heslo musí mať aspoň 8 znakov, musí obsahovať aspoň jedno číslo, aspoň jeden malý a aspoň jeden veľký znak",
  "validation.date": "Dátum je v nesprávnom tvare",

  "validation.graterDate":
    "Konečný dátum musí nasledovať za počiatočným dátumom",
  "validation.pastDate": "Dátum a čas musí byť v budúcnosti",
  "validation.maxDate": "Dátum  musí byť v minulosti",

  "validation.string.max": "Maximálna dĺžka tohto poľa je {0} znakov",
  "validation.string.exact": "Toto pole musí obsahovať presne {0} znakov",
};

const queryTable = {
  "queryTable.select.all": "Všetko",
  "queryTable.filter.placeholder": "Filter..",
  "queryTable.filter.dateBetween": "Od - Do",
  "queryTable.filter.setDateRange": "Nastaviť časové rozmedzie: ",
  "queryTable.filter.pickerStart": "Dátum od",
  "queryTable.filter.pickerEnd": "Dátum do",
};

const table = {
  "table.select.types": "výbery",
  "table.select.moreTypes": "výberov",
  "table.select.NoPlayer": "Bez fotomatu",
  "table.select.Offline": "Offline",
  "table.select.Online": "Online",
  "table.select.Error": "Chyba",
  "table.select.Income": "Income",
  "table.select.Outcome": "Outcome",
  "table.select.Transfer": "Transfer",
  "table.select.Succeeded": "Úspešná",
  "table.select.Pending": "Čaká sa",

  "table.select.Activated": "Aktivované",
  "table.select.Unactivated": "Neaktivované",
  "table.select.Blocked": "Blokované",
  "table.select.Money": "Peňažná",
  "table.select.Credit": "Kreditná",
  "table.select.Inactive": "-",
  "table.select.None": "bez predplatného",
  "table.select.Family": "Family",
  "table.select.Single": "Single",
  "table.select.Partner": "Partner",
  "table.select.Advertiser": "Inzerent",
  "table.select.User": "Používateľ",
  "table.select.Maintainer": "Správca",
  "table.select.Admin": "Admin",
  "table.select.Running": "Spustená",
  "table.select.Awaiting": "Čaká na schválenie",
  "table.select.Scheduled": "Naplánovaná",
  "table.select.Finished": "Ukončená",
  "table.select.Rejected": "Zamietnutá",
  "table.select.Qr": "QR",
  "table.select.PublicQr": "MQR",
  "table.select.Processing": "Spracováva sa",
  "table.select.Processed": "Spracovaná",
  "table.select.Invalid": "Neplatná",
  "table.select.Failed": "Chyba",

  "table.select.Created": "Vytvorená",
  "table.select.Ready": "Čaká na tlač",
  "table.select.InProgress": "Tlačí sa",
  "table.select.Completed": "Dokončená",
  "table.select.Incomplete": "Nedokončená",
  "table.select.Partial": "Čiastočne dokončená",
  "table.select.Expired": "Exspirovaná",
  "table.select.Canceled": "Stornovaná",
  "table.select.Incomplete": "Nedokončená",
  "table.select.Partial": "Čiastočne dokončená",
  "table.select.Expired": "Exspirovaná",

  "table.select.Mixed": "Kredit + doplatok",
  "table.select.Payment": "Doplatok",
  "table.select.Expired": "Exspirovaná",
  "table.select.FreePhoto": "FreeFoto",
};

const apiErrors = {
  "apiErrors.INTERNAL_SERVER_ERROR": "Nastala neočakávana chyba",
  "apiErrors.UnauthorizedUserError": "Boli ste odhlásený z dôvodu nečinnosti",
  "apiErrors.InvalidRequestError": "Odoslané dáta obsahujú neplatné hodnoty",
  "apiErrors.AccountDoesNotExistsError":
    "Účet so zadanou e-mailovou adresou neexistuje",
  "apiErrors.AccountExistsError":
    "Účet so zadanou e-mailovou adresou už existuje",
  "apiErrors.PasswordResetPendingError":
    "Na e-mail už bol odoslaný odkaz pre zmenu hesla",
  "apiErrors.PasswordResetNotAllowedError":
    "Tento účet nepodporuje resetovanie hesla",
  "apiErrors.InvalidLoginError": "Zadaný email alebo heslo je nesprávne.",
  "apiErrors.InvalidLoginMethodError":
    "Účet so zadaným emailom nepodporuje použitú autentifikačnú metódu.",
  "apiErrors.UnactivatedAccountError": "Účet vyžaduje aktiváciu.",
  "apiErrors.BlockedAccountError": "Účet bol zablokovaný administrátorom.",
  "apiErrors.DeviceOfflineError":
    "Nie je možné vykonať akciu lebo zariadenie je Offline",
  "apiErrors.EntityDoesNotExistsError": "Požadovaný záznam neexistuje",
  "apiErrors.EntityUniqueConstraintError":
    "Zariadne s rovnakým názvom už existuje",
  "apiErrors.CannotPerformOnOwnAccountError":
    "Táto operácia nemôže byť vykonaná na vlastnom účte",
  "apiErrors.PasswordChangeError": "Pôvodné heslo je nesprávne",
  "apiErrors.WeakPasswordError":
    "Heslo musí byť aspoň 8 znakov dlhé a musí obsahovať aspoň jedno číslo, aspoň jeden malý a aspoň jeden veľký znak",
  "apiErrors.SubscriptionNotActivatedError":
    "Používateľ nemá aktivované žiadne predplatné",
  "apiErrors.InvalidInputDataError":
    "Niektorý zo vstupných údajov nie je správny",
  "apiErrors.UserIsNotAmbassadorError":
    "Používateľovi ste nezapli funkciu ambasádor",
};

const dashboard = {
  "dashboard.title": "Prehľad",
  "dashboard.dropdown.companies": "Spoločnosti",
  "dashboard.calculate.success": "Základné štatistiky boli prepočítané",
  "dashboard.allCredits": "Celkový predaný kredit",
  "dashboard.allDevices": "Fotomatov celkom",
  "dashboard.allPhotos": "Fotografií celkom",
  "dashboard.allUsers": "Používateľov celkom",
  "dashboard.creditsCard.title": "Kredity",
  "dashboard.creditsCard.companyCredits": "Kredity spoločností celkom",
  "dashboard.creditsCard.userCredits": "Kredity požívateľov celkom",
  "dashboard.creditsCard.allCredits": "Celkový počet kreditov",
  "dashboard.devicesCard.title": "Fotomaty",
  "dashboard.devicesCard.storeDevices": "Fotomaty na prevádzkach",
  "dashboard.devicesCard.stockDevices": "Fotomaty skladom",
  "dashboard.devicesCard.allDevices": "Celkový počet fotomatov",
  "dashboard.devicesCard.offlineDevices": "Fotomaty offline",
  "dashboard.devicesCard.emptyDevicesFull": "Fotomaty s nedostatkom papiera",
  "dashboard.devicesCard.emptyDevices": "Nedostatok papiera",
  "dashboard.printedPhotosCard.title":
    "Vytlačené fotky (posledných 30 dní)",
  "dashboard.printedPhotosCard.photosOfDay": "Počet",
  "dashboard.printedPhotosCard.allPhotos": "Spolu",
  "dashboard.printedPhotosCard.freeFoto": "FreeFoto",
  "dashboard.printedPhotosCard.first": "10x15",
  "dashboard.printedPhotosCard.second": "15x15",
  "dashboard.printedPhotosCard.third": "20x15",
  "dashboard.usersCard.title": "Registrovaní používatelia (posledných 30 dní)",
  "dashboard.usersCard.first": "Počet registrovaných",
  "dashboard.ambassadorsScansChart.title":
    "Skenovania AQR ambasádorov (posledných 30 dní)",
  "dashboard.ambassadorsScansChart.first": "Počet scanov",
};

const routes = {
  "routes.dashboard": "Prehľad",
  "routes.profile": "Profil",
  "routes.devices": "Fotomaty",
  "routes.devices.detail": "Detail fotomatu",
  "routes.campaigns.freeFoto": "FreeFoto kampane",
  "routes.campaigns.qr": "Kupónové kampane",
  "routes.campaigns.new": "Nová kampaň",
  "routes.campaigns.detail": "Detail kampane",
  "routes.stores": "Prevádzky",
  "routes.stores.new": "Nová prevádzka",
  "routes.stores.detail": "Detail prevádzky",
  "routes.notifications": "Notifikácie",
  "routes.users": "Používatelia",
  "routes.users.new": "Nový používateľský účet",
  "routes.users.detail": "Detail pužívateľa",
  "routes.orders": "Objednávky",
  "routes.orders.detail": "Detail objednávky",
  "routes.companies": "Spoločnosti",
  "routes.companies.detail": "Detaily spoločnosti",
  "routes.companies.new": "Nová spoločnosť",
  "routes.media": "Médiá",
  "routes.management": "Témy a kategórie",

  "routes.payments": "Platby",
  "routes.invoices": "Fakturácia",
  "routes.invoices.detail": "Detail platby",
  "routes.commissions": "Provízie",
  "routes.commissions.detail": "Detail Provízie",
  "routes.operations": "Operácie",
  "routes.login": "Prihlásenie",
  "routes.settings": "Nastavenia",
  "routes.logout": "Odhlásiť",
  "routes.forgottenPassword": "Zabudnuté heslo",
  "routes.loading": "Načítavam...",
};

const usersPage = {
  "usersPage.title": "Používatelia",
  "usersPage.actions.newPartner": "NOVÝ PARTNER",
  "usersPage.actions.newAdvertiser": "NOVÝ INZERENT",
  "usersPage.actions.newMaintainer": "NOVÝ SPRÁVCA",
  "usersPage.table.title": "Zoznam používateľov",
  "usersPage.table.id": "ID",
  "usersPage.table.name": "Meno",
  "usersPage.table.company": "Spoločnosť",
  "usersPage.table.email": "E-mail",
  "usersPage.table.role": "Rola",
  "usersPage.table.status": "Stav",
  "usersPage.table.credits": "Kredity",
  "usersPage.table.status.activated": "AKTÍVNY",
  "usersPage.table.status.blocked": "BLOKOVANÝ",
  "usersPage.table.status.notActive": "NEAKTÍVNY",
  "usersPage.table.subscription": "Predplatné",
  "usersPage.table.actions": "Akcie",
  "usersPage.table.detail": "DETAIL",
  "usersPage.table.ambassador": "Ambasádor",
  "usersPage.table.ambassador.Money": "Peňažný",
  "usersPage.table.ambassador.Credit": "Kreditný",
  "usersPage.table.ambassador.Inactive": "-",
};

const createUserPage = {
  "createUser.title": "Nový účet",
  "createUser.submit": "VYTVORIŤ",
  "createUser.cancel": "ZRUŠIŤ",
  "createUser.company.title": "Údaje o spoločnosti",
  "createUser.company.name": "Názov spoločnosti",
  "createUser.company.address": "Adresa",
  "createUser.company.city": "Mesto",
  "createUser.company.postal": "PSČ",
  "createUser.company.country": "Krajina",
  "createUser.company.country.slovakia": "Slovensko",
  "createUser.contact.title": "Používateľ",
  "createUser.contact.firstName": "Meno",
  "createUser.contact.lastName": "Priezvisko",
  "createUser.contact.birth": "Dátum narodenia",
  "createUser.contact.gender": "Pohlavie",
  "createUser.contact.gender.male": "Muž",
  "createUser.contact.gender.female": "Žena",
  "createUser.contact.gender.unspecified": "Neuvedené",
  "createUser.contact.email": "E-mail",
  "createUser.contact.phone": "Telefón (Formát: +421903123123)",
  "createUser.identificators.title": "Identifikátory spoločnosti",
  "createUser.identificators.ico": "IČO",
  "createUser.identificators.dic": "DIČ",
  "createUser.identificators.icDph": "IČ DPH",
  "createUser.identificators.reg": "Registrový súd",
  "createUser.bank.title": "Bankové údaje",
  "createUser.bank.iban": "IBAN",
  "createUser.bank.bic": "BIC/SWIFT",
  "createUser.bank.name": "Banka",
  "createUser.success": "Účet {0} {1} bol vytvorený",
};

const settingsPage = {
  "settings.title": "Nastavenia",
  "settings.update.success": "Nastavenia systému boli aktualizované",
  "settings.creditSection.title": "Hodnota kreditu",
  "settings.creditSection.creditValue": "Hodnota 1 kreditu (EUR)",
  "settings.creditSection.registrationAward":
    "Výška kreditu za novú registráciu (C)",
  "settings.creditSection.paymentFee": "Poplatok za priamu platbu (%)",
  "settings.timeSection.title": "Nastavenia času",
  "settings.timeSection.creditExpiration": "Expirácia QR kreditu (dni)",
  "settings.timeSection.freePhoto": "Expirácia FreeFoto objednávky (dni)",
  "settings.timeSection.orderExpiration":
    "Expirácia kreditnej objednávky (dni)",
  "settings.timeSection.freePhotoMonthLimit":
    "Limit FreeFoto výtlačkov na deň (počet výtlačkov)",
  "settings.timeSection.unlockDuration":
    "Automatické uzamknutie objednávky (min)",
  "settings.commissionsSection.title": "Provízie pre partnerov",
  "settings.commissionsSection.creditValue":
    "Hodnota fotografie pre výpočet provízie (€)",
  "settings.commissionsSection.lower": "Úroveň nižšej provízie (%)",
  "settings.commissionsSection.higher": "Úroveň vyššej provízie (%)",
  "settings.commissionsSection.treshold": "Hranica provízií (počet fotografií)",
  "settings.commissionsSection.photosPerMonthThreshold":
    "Minimálny počet fotografií za mesiac",
  "settings.recepientsSection.title": "Podpora",
  "settings.recepientsSection.globalNotifcationRecipients": "Zoznam emailov",
  "settings.signageSection.title": "Digital signage",
  "settings.signageSection.idleTimeout": "Spustenie digital signage po (s)",
  "settings.signageSection.bannerDuration":
    "Trvanie digital signage bannera (s)",
  "settings.supportSection.title": "Mobilná aplikácia",
  "settings.supportSection.email": "Email podpory",
  "settings.supportSection.subject": "Predmet emailu",
  "settings.supportSection.body": "Telo emailu",
  "settings.notificationsSection.title": "Notifikácie",
  "settings.commissionsSection.printsRemainingNTreshold":
    "Pripomienka výmeny spotrebného materiálu (zostávajúce výtlačky 10x15)",
  "settings.ordersSection.title": "Cenník",
  "settings.ordersSection.price": "Cena (C)",
  "settings.ordersSection.size": "Rozmer",
  "settings.save": "ULOŽIŤ ZMENY",
  "settings.cancel": "ZRUŠIŤ",
  "settings.version.title": "Verzia systému",
  "settings.version.backend": "Verzia Backend",
  "settings.version.frontend": "Verzia Frontend",

  "settings.ambassadorSection.title": "Nastavenia ambasádora",
  "settings.ambassadorSection.customerReward": "Odmena pre používateľa",
  "settings.ambassadorSection.referralCreditReward": "Odmena pre ambasádora",
  "settings.ambassadorSection.referralMoneyReward": "Odmena pre abasádora",
  "settings.ambassadorSection.claimMinutesLimit": "Časový limit (minúty)",

  "settings.rewardSection.title": "Odmena",
};

const userDetail = {
  "userDetail.title": "{userName}",
  "userDetail.accountStatus": "Stav konta",
  "userDetail.ordersCount": "Počet objednávok",
  "userDetail.printedPhotosCount": "Počet vytlačených fotografií",
  "userDetail.contactInfo": "Používateľ",
  "userDetail.contactInfo.create": "Kontaktné údaje",
  "userDetail.orders": "Objednávky",
  "userDetail.payments": "Platby",
  "userDetail.operations": "Operácie",
  "userDetail.activityHistory": "História aktivity",
  "userDetail.activateUser.modalTitle": "Aktivácia účtu",
  "userDetail.activateUser.modalText":
    "Chystáte sa aktivovať účet. Naozaj chcete pokračovať?",
  "userDetail.activateUser.passwordLabel":
    "Vytvorte účtu prístupové heslo do dashboardu",
  "userDetail.activateUser.modalConfirm": "Aktivovať",
  "userDetail.unblockUser.modalTitle": "Odblokovanie účtu",
  "userDetail.unblockUser.modalText":
    "Chystáte sa odblokovať účet. Naozaj chcete pokračovať?",
  "userDetail.unblockUser.modalConfirm": "Odblokovať",
  "userDetail.blockUser.modalTitle": "Naozaj chcete zablokovať tento účet?",
  "userDetail.blockUser.modalSubtitle": "Uveďte dôvod blokovania",
  "userDetail.blockUser.modalConfirm": "ZABLOKOVAŤ",
  "userDetail.deleteUser.modalTitle": "Naozaj chcete odstrániť tento účet?",
  "userDetail.deleteUser.modalConfirm": "ODSTRÁNIŤ",
  "userDetail.headerActions.blockUser": "BLOKOVAŤ ÚČET",
  "userDetail.headerActions.activateUser": "AKTIVOVAŤ ÚČET",
  "userDetail.headerActions.sendActivateEmail": "ODOSLAŤ AKTIVÁCIU",
  "userDetail.headerActions.allowUser": "ODBLOKOVAŤ ÚČET",
  "userDetail.headerActions.resetPassword": "RESETOVAŤ HESLO",
  "userDetail.headerActions.deleteUser": "ODSTRÁNIŤ ÚČET",
  "userDetail.accountStatus.paymentList": "ZOZNAM PLATIEB",
  "userDetail.contactInfo.firstName": "Meno",
  "userDetail.contactInfo.lastName": "Priezvisko",
  "userDetail.contactInfo.gender": "Pohlavie",
  "userDetail.contactInfo.email": "E-mail",
  "userDetail.orders.id": "ID",
  "userDetail.orders.date": "Dátum",
  "userDetail.orders.size": "Objem",
  "userDetail.orders.actions": "Akcie",
  "userDetail.orders.detail": "Detail",
  "userDetail.orders.list": "ZOZNAM OBJEDNÁVOK",
  "userDetail.transactions.list": "ZOZNAM PLATIEB",
  "userDetail.invoice.pair": "PÁROVAŤ FAKTÚRU",
  "userDetail.accountStatus.title": "Stav konta",
  "userDetail.accountStatus.registrationService": "Registračná služba",
  "userDetail.accountStatus.blocation": "Blokácia",
  "userDetail.provision": "Stav konta",
  "userDetail.processedPhotosCount": "Počet spracovaných fotografií",
  "userDetail.photomatsCount": "Počet fotomatov",
  "userDetail.photomatsList": "Fotomaty",
  "userDetail.photomats.id": "ID",
  "userDetail.photomats.status": "Stav",
  "userDetail.photomats.hearthbeat": "Heartbeat",
  "userDetail.photomats.actions": "Akcie",
  "userDetail.photomats.showList": "ZOZNAM FOTOMATOV",
  "userDetail.totalSales": "Celkový obrat",
  "userDetail.transactions": "Platby",
  "userDetail.campaigns": "Kampane",
  "userDetail.activeFreePhotoCampaigns": "Aktívne FreeFoto kampane",
  "userDetail.totalFreePhotoCampaigns": "Všetky FreeFoto kampane",
  "userDetail.activeQrCouponsCampains": "Aktívne sady kupónov",
  "userDetail.totalQrCouponsCampains": "Všetky sady kupónov",
  "userDetail.buttons.cancel": "ZRUŠIŤ",
  "userDetail.buttons.confirm": "ULOŽIŤ ZMENY",
  "userDetail.resetPassword.success":
    "Na email: {0} bol odoslaný odkaz na resetovanie hesla",
  "userDetail.userUpdate.success": "Informácie o účte boli aktualizované",
  "userDetail.statusCard.title": "Základné informácie",
  "userDetail.statusCard.status": "Stav konta",
  "userDetail.statusCard.numberOfCredits": "Kredit na kupónovom účte",
  "userDetail.statusCard.numberOfSubscriptionCredits":
    "Kredit na predplatnom účte",
  "userDetail.statusCard.subscriptionStatus": "Typ predplatného",
  "userDetail.statusCard.subscriptionStatus.activated": "Aktívny",
  "userDetail.statusCard.subscriptionStatus.unactivated": "bez predplatného",
  "userDetail.statusCard.subscriptionStatus.canceled": "zrušené",
  "userDetail.statusCard.numberOfOrders": "Počet objednávok",
  "userDetail.statusCard.numberOfPhotos": "Počet vytlačených fotografií",
  "userDetail.statusCard.registrationDate": "Dátum registrácie",
  "userDetail.statusCard.registrationType": "Typ registrácie",
  "userDetail.statusCard.lastActivity": "Posledná aktivita",
  "userDetail.statusCard.creditTransfer": "Transferovať QR kredit",
  "userDetail.statusCard.subscriptionDelete": "Zrušiť predplatné",
  "userDetail.statusCard.operationList": "Zoznam operácií",
  "userDetail.statusCard.deleteModal.text":
    "Naozaj chcete používateľovi zrušiť predplatné?",
  "userDetail.statusCard.sourceUser": "Zdrojový používateľ",
  "userDetail.statusCard.destinationUser.placeholder":
    "Zadajte mail alebo meno (min. 3 znaky)",
  "userDetail.statusCard.destinationUser.label": "Cieľový používateľ",
  "userDetail.statusCard.sendCredits": "Počet transferovaných kreditov:",
  "userDetail.statusCard.remains": "Ostáva",
  "userDetail.statusCard.credits": "Kreditov",
  "userDetail.statusCard.confirmButton": "Potvrdiť",
  "userDetail.statusCard.lessThanZero": "Používateľ nemá dostatočný počet",
  "userDetail.statusCard.chooseUser": "Vyberte používateľa...",
  "userDetail.statusCard.maxCredits": "Počet kreditov: max.",
  "userDetail.statusCard.validCredits": "Number of credits must be less than",
  "userDetail.statusCard.modalTitle":
    "Chystáte sa transferovať kredit medzi používateľmi",
  "userDetail.statusCard.successTransform":
    "Kredity boli úspešne prevedené medzi použivateľmi",
  "userDetail.statusCard.successDeactivateSubscription":
    "Predplatné používateľa bolo zrušené",
  "userDetail.companyTable.title": "Spoločnosti",
  "userDetail.storeTable.title": "Prevádzky",
  "userDetail.toast.block.success": "Účet bol zablokovaný",
  "userDetail.toast.unblock.success": "Účet bol odblokovaný",
  "userDetail.toast.sendActivationUser.success":
    "Na účet bol zaslaný aktivačný email",
  "userDetail.toast.activate.success": "Účet bol aktivovaný",
  "userDetail.toast.delete.success": "Účet bol odstránený",
  "userDetail.toast.delete.fail": "Táto akcia nie je povolená",

  "userDetail.ordersTable.empty": "Zoznam objednávok používateľa je prázdny.",
  "userDetail.paymentsTable.empty": "Zoznam platieb používateľa je prázdny.",
  "userDetail.operationsTable.empty": "Zoznam operácii používateľa je prázdny.",

  "userDetail.ambassadorReferralsChart.title":
    "Graf aktivity skenovania za posledných 30 dní",
  "userDetail.ambassadorReferralsChart.empty":
    "Za poslených 30 dní nebola zaznamenaná žiadna aktivita skenovaia AQR kupónov.",
  "userDetail.ambassadorReferralsChart.barTitle": "Využitie AQR",

  "userDetail.ambassadorCart.title": "Ambasádor",
  "userDetail.ambassadorCart.function": "Funkcia ambasádor",
  "userDetail.ambassadorCart.function.enabled": "Zapnutá",
  "userDetail.ambassadorCart.function.disabled": "Vypnutá",
  "userDetail.ambassadorCart.enabled.success":
    "Nastavenia ambasádora boli upravené",
  "userDetail.ambassadorCart.disabled.success":
    "Funkcia ambasádor bola vypnutá",
  "userDetail.ambassadorCart.type": "Typ ambasádora",
  "userDetail.ambassadorCart.type.credit": "Kreditný",
  "userDetail.ambassadorCart.type.money": "Peňažný",
  "userDetail.ambassadorCart.inheritSettings":
    "Dediť hodnoty ambasádora zo systémových nastavení",
  "userDetail.ambassadorCart.qrCodeModal.title": "Zobrazenie QR kódu",

  "userDetail.ambassadorReferralsTable.title":
    "Zoznam odporúčaní aplikácie ambasádora",
  "userDetail.ambassadorReferralsTable.empty":
    "Zoznam odporúčaní ambasádora je prázdny.",
  "userDetail.ambassadorReferralsTable.claimedAt": "Uplatnený",
  "userDetail.ambassadorReferralsTable.user": "Používateľ",
  "userDetail.ambassadorReferralsTable.customerReward": "Odmena používateľ",
  "userDetail.ambassadorReferralsTable.referralReward": "Odmena ambasádor",
  "userDetail.ambassadorReferralsTable.type": "Typ",

  "userDetail.ambassadorCommissionsTable.title":
    "Zoznam mesačných kalendárnych výpisov ambasádora",
  "userDetail.ambassadorCommissionsTable.empty":
    "Zoznam mesačných kalendárnych výpisov ambasádora je prázdny.",
  "userDetail.ambassadorCommissionsTable.period": "Mesiac",
  "userDetail.ambassadorCommissionsTable.amount": "Suma",
  "userDetail.ambassadorCommissionsTable.referrals": "Počet uplatnení",
  "userDetail.ambassadorCommissionsTable.export": "Stiahnuť výpis",
  "userDetail.headerActions.inviteAmbassador": "Pozvať ambasádora",
  "userDetail.ambassadorInvite.success":
    "Pozvánka pre ambasádora bola odoslaná",

  "userDetail.ambassadorExport.modal.title":
    "Export odporúčaní aplikácie ambasádorov",
  "userDetail.ambassadorExport.modal.button": "Exportovať",
  "userDetail.ambassadorExport.modal.emails": "E-maily ambasádorov",
  "userDetail.ambassadorExport.success":
    "Export odporúčaní aplikácie ambasádorov bol stiahnutý ",
};

const devices = {
  "deviceList.title": "Fotomaty",
  "deviceList.header": "Zoznam fotomatov",
  "deviceList.table.title": "Zoznam fotomatov",
  "deviceList.assignDevice": "PRIRADIŤ FOTOMAT",
  "deviceList.discoverDevice": "PREHĽADAŤ SKLAD",
  "deviceList.table.active": "Aktívny",
  "deviceList.table.changePartnerSuccess": "Zmena partnera úspešná",
  "deviceList.table.changePartnerFailed": "Zmena partnera neúspešná",
  "deviceList.table.ID": "ID",
  "deviceList.table.state": "Stav",
  "deviceList.table.heartbeat": "Heartbeat",
  "deviceList.table.partner": "Partner",
  "deviceList.table.company": "Spoločnosť",
  "deviceList.table.store": "Prevádzka",
  "deviceList.table.address": "Adresa",
  "deviceList.table.printedPhotos": "Počet výtlačkov",
  "deviceList.table.remainingPhotos": "Zostáva",
  "deviceList.table.placement": "Umiestnenie",
  "deviceList.table.actions": "Akcie",
  "deviceList.table.actions.detail": "Detail",
  "deviceList.enabledToggle": "Prevádzka",
  "deviceList.disabledToggle": "Sklad",
  "deviceList.assignModal.title": "Priradiť fotomat",
  "deviceList.assignModal.assign": "PRIRADIŤ",
  "deviceList.assignModal.cancel": "ZRUŠIŤ",
  "deviceList.assignModal.partner": "Vyberte partnera...",
  "deviceList.assignModal.device": "Vyberte fotomat...",
  "deviceList.discoverModal.title": "Stav skladu",
  "deviceList.discoverModal.textNo":
    "Na sklade nepribudli žiadne nové fotomaty",
  "deviceList.discoverModal.textYes": "Počet nových fotomatov na sklade: {0}",
  "devicesList.filter.active": "Aktívny",
  "devicesList.filter.nonActive": "Neaktívny",
  "deviceDetail.title": "Fotomat",
  "deviceDetail.status.deviceState": "Stav fotomatu",
  "deviceDetail.status.lastHeartbeat": "Posledný heartbeat",
  "deviceDetail.status.remainingPhotoCount": "Zostávajúci počet fotografií",
  "deviceDetail.status.printerCounter": "Počítadlo tlačiarne",
  "deviceDetail.status.photosPerMonth": "Priemerne fotografií za mesiac",
  "deviceDetail.status.restart": "REŠTARTOVAŤ",
  "deviceDetail.status.deactivate": "DEAKTIVOVAŤ",
  "deviceDetail.status.reportError": "Nahlásiť poruchu",
  "deviceDetail.status.activate": "Aktivovať",
  "deviceDetail.status.back": "Späť",
  "deviceDetail.status.restartModal.title": "Reštartovať fotomat",
  "deviceDetail.status.restartModal.text":
    "Naozaj si prajete reštartovať tento fotomat?",
  "deviceDetail.status.deactivateModal.title": "Deaktivovať fotomat",
  "deviceDetail.status.deactivateModal.text":
    "Naozaj si prajete deaktivovať tento fotomat?",
  "deviceDetail.status.activateModal.title": "Aktivovať fotomat",
  "deviceDetail.status.activateModal.text":
    "Naozaj si prajete aktivovať tento fotomat?",
  "deviceDetail.partner.title": "Partner",
  "deviceDetail.partner.printerPhotos": "Fotografie z tlačiarne",
  "deviceDetail.partner.orderPhotos": "Fotografie z objednávok",
  "deviceDetail.partner.changePartner": "ZMENIŤ PARTNERA",
  "deviceDetail.partner.removePartner": "ODSTRÁNIŤ PARTNERA",
  "deviceDetail.partner.detailPartner": "DETAIL PARTNERA",
  "deviceDetail.partner.changePartnerModal.title": "Zmeniť partnera",
  "deviceDetail.partner.changePartnerModal.action": "ZMENIŤ",
  "deviceDetail.partner.removePartnerModal.title": "Odstrániť partnera",
  "deviceDetail.partner.removePartnerModal.text":
    "Naozaj si prajete odstrániť partnera",
  "deviceDetail.partner.removePartnerModal.action": "ODSTRÁNIŤ",
  "deviceDetail.partner.back": "Späť",
  "deviceDetail.orders.title": "Objednávky",
  "deviceDetail.orders.ordersList": "Zoznam objednávok",
  "deviceDetail.orders.table.id": "ID",
  "deviceDetail.orders.table.date": "Dátum",
  "deviceDetail.orders.table.volume": "Objem",
  "deviceDetail.orders.table.actions": "Akcie",
  "deviceDetail.orders.table.actions.detail": "Detail",
  "deviceDetail.provisions.title": "Provízie",
  "deviceDetail.provisions.totalProvision": "Celková provízia",
  "deviceDetail.provisions.provisionsList": "Zoznam provízií",
  "deviceDetail.address.title": "Adresa umiestnenia",
  "deviceDetail.address.placeholder": "Zadajte adresu",
  "deviceDetail.address.undefinedLocation": "Poloha neurčená",
  "deviceDetail.partner.removePartnerSuccessful":
    "Odstránenie partnera úspešné",
  "deviceDetail.partner.removePartnerFailed": "Odstránenie partnera neúspešné",
  "deviceDetail.partner.changePartnerSuccessful": "Zmena partnera úspešná",
  "deviceDetail.partner.changePartnerFailedl": "Zmena partnera neúspešná",
  "deviceDetail.status.deviceDeactivationFailed": "Deaktivácia neúspešná",
  "deviceDetail.status.deviceDeactivationSuccessful": "Deaktivácia úspešná",
  "deviceDetail.status.deviceActivationSuccessful": "Aktivácia úspešná",
  "deviceDetail.status.deviceActivationFailed": "Deaktivácia neúspešná",
  "deviceDetail.status.deviceRestartSuccessful": "Reštartovanie úspešné",
  "deviceDetail.status.deviceRestartFailed": "Reštartovanie neúspešné",
  "deviceDetail.partner.changeAddressFailed": "Zmena adresy neuspešná",
  "deviceDetail.partner.changeAddressSuccessful": "Zmena adresy úspešná",
  "deviceDetail.player.changePlayer.title": "Zmeniť fotomat",
  "deviceDetail.player.changePlayer.text":
    "K prevádzke {názov prevádzky} ({ID prevádzky}) je priradený fotomat {ID fotomatu}. Vyberte nový fotomat: ",
  "deviceDetail.player.newPlayer.text":
    "Vyberte fotomat, ktorý chcete priradiť tejto prevádzke",
  "deviceDetail.player.changePlayer.change": "Zmeniť",
  "deviceDetail.player.assignPlayer.title": "Priradiť fotomat",
  "deviceDetail.player.withoutPlayer.title":
    "Tejto prevadzke nie je priradený žiadny fotomat",
  "deviceDetail.player.assignPlayer.text":
    "K prevádzke {name of the operation} ({ID of operation}) priradiť nový fotomat: ",
  "deviceDetail.player.assignPlayer.assign": "Priradiť",
  "deviceDetail.player.assignPlayer.change": "Zmeniť",
  "deviceDetail.player.assignPlayerSuccessful":
    "Priradenie fotomatu bolo úspešné",
  "deviceDetail.store.detail": "Detail prevádzky",
  "deviceList.removeDevice": "Odobrať fotomat",
  "deviceList.assignModal.store": "Vyberte prevádzku...",
  "deviceDetail.status.removeModal.text-1": "Naozaj chcete odobrať fotomat",
  "deviceDetail.status.removeModal.text-2": "z prevádzky",
  "deviceDetail.status.remove": "Odobrať",
  "deviceDetail.status.deviceRemoveSuccessful": "Fotomat úspešne odobratý",
  "deviceDetail.deviceStatusCard.fotomatActivated": "Aktivovaný",
  "deviceDetail.deviceStatusCard.fotomatDeactivated": "Deaktivovaný",
  "deviceDetail.deviceStatusCard.fotomatApp": "Fotomat aplikácia",
  "deviceDetail.deviceStatusCard.fotomatAppRunning": "Spustená",
  "deviceDetail.deviceStatusCard.fotomatAppStopped": "Zastavená",
  "deviceDetail.status.removeModal.title": "Odobrať fotomat",
  "deviceDetail.success.browseStock": "Sklad s fotomatmi bol prehľadaný",

  "device.issue.title": "Zoznam všetkých chybových hlásení",
  "device.issue.code": "Kód chyby",
  "device.issue.description": "Popis chyby",
  "device.issue.date": "Dátum výskytu",
};

const campaigns = {
  "campaigns.removeModal.title": "Ukončiť kampaň",
  "campaigns.removeModal.yes": "Odstrániť",
  "campaigns.removeModal.no": "Zrušiť",
  "campaigns.new": "Nová kampaň",
  "campaigns.duplicate.button": "Duplikovať kampaň",
  "campaigns.end.button": "Ukončiť kampaň",
  "campaigns.detail": "Detail",
  "campaigns.freefoto.title": "FreeFoto kampane",
  "campaigns.freefoto.header": "Zoznam FF kampaní",
  "campaigns.qr.title": "Kupónové kampane",
  "campaigns.qr.header": "Zoznam kupónových kampaní",
  "campaigns.coupons.header": "Zoznam nepriradených sád kupónov a MQR kupónov",
  "campaigns.detail.basicInfo.title": "Základné informácie",
  "campaigns.detail.budget.title": "Rozpočet",
  "campaigns.detail.spots.title": "Spoty kampane",
  "campaigns.detail.schedule.title": "Časový rozvrh",
  "campaigns.detail.stores.title": "Lokalita a prevádzky",
  "campaigns.detail.company.title": "Spoločnosť",
  "campaigns.detail.map.title": "Mapa prevádzok",
  "campaigns.budgetCard.title": "Financovanie kampane",
  "campaigns.budgetCard.budgetTotal": "Rozpočet kampane",
  "campaigns.budgetCard.budgetSpent": "Spotrebovaný rozpočet",
  "campaigns.budgetCard.budgetRemaining": "Zostávajúci rozpočet",
  "campaigns.budgetCard.AvgBid": "Priemerná cena za jeden výtlačok (C)",
  "campaigns.budgetCard.MaxBid": "Maximálna cena za jeden výtlačok (C)",
  "campaigns.budgetCard.balanceOfCredits": "Zostatok kreditov: ",
  "campaigns.budgetCard.lackOfCredits":
    "Zostatok: Spoločnosť nemá dostatočný počet kreditov",
  "campaigns.spotsCard.title": "Médiá kampane",
  "campaigns.spotsCard.banners": "Reklamné bannery",
  "campaigns.spotsCard.count": "vytlačení",
  "campaigns.companyCard.title": "Spoločnosť",
  "campaigns.companyCard.name": "Názov",
  "campaigns.companyCard.address": "Adresa",
  "campaigns.companyCard.contact": "Kontakt",
  "campaigns.companyCard.phone": "Telefón",
  "campaigns.companyCard.email": "E-mail",
  "campaigns.companyCard.details.button": "Detail spoločnosti",
  "campaigns.infoCard.title": "Základné informácie",
  "campaigns.infoCard.owner": "Objednávateľ kampane",
  "campaigns.infoCard.description": "Popis kampane",
  "campaigns.infoCard.startDate": "Začiatok kampane",
  "campaigns.infoCard.endDate": "Koniec kampane",
  "campaigns.infoCard.topics": "Témy kampane",
  "campaigns.infoCard.maxBidInfo":
    "Cena za jeden výtlačok nemôže byť vyššia ako rozpočet kampane",
  "campaigns.scheduleCard.repeat":
    "Min. interval opakovania pre používateľa (dni)",
  "campaigns.mapCard.title": "Mapa prevádzok",
  "campaigns.targetingCard.title": "Lokalita a prevádzky",
  "campaigns.targetingCard.placeholder.location": "Lokalita",
  "campaigns.targetingCard.placeholder.category": "Typ prevádzky",
  "campaigns.targetingCard.placeholder.company": "Spoločnosť",
  "campaigns.targetingCard.placeholder.store": "Prevádzka",
  "campaigns.delete.response.success": "Kampaň bola úspešne odstránená",
  "campaigns.cancel.response.success": "Kampaň bola úspešne ukončená",
  "campaigns.delete.response.error": "Kampaň nebola odstránená",
  "campaigns.new.createButton": "Vytvoriť kampaň",
  "campaigns.new.cancelButton": "Zrušiť",
  "campaigns.new.freeFoto.title": "Nová FreeFoto kampaň",
  "campaigns.new.qr.title": "Nová QR kampaň",
  "campaigns.new.pqr.title": "Nová MQR kampaň",
  "campaigns.submit": "Submit",
  "campaigns.status.running": "Spustená",
  "campaigns.status.awaiting": "Čaká na schválenie",
  "campaigns.status.rejected": "Zamietnutá",
  "campaigns.status.finished": "Ukončená",
  "campaigns.status.scheduled": "Naplánovaná",
  "campaigns.status.inactive": "Neaktívna",
  "campaigns.table.actions": "Akcie",
  "campaigns.table.ID": "ID",
  "campaigns.table.name": "Názov",
  "campaigns.table.owner": "Zadávateľ",
  "campaigns.table.type": "Typ",
  "campaigns.table.description": "Popis",
  "campaigns.table.stores": "Počet prevádzok",
  "campaigns.table.maxBid": "Hodnota výtlačku",
  "campaigns.type.qr": "QR",
  "campaigns.type.pqr": "MQR",
  "campaigns.table.status": "Stav",
  "campaigns.table.start": "Začiatok",
  "campaigns.table.end": "Koniec",
  "campaigns.table.maxPrice": "Max. cena",
  "campaigns.table.budget": "Rozpočet",
  "campaigns.table.cupons": "Kupóny",
  "campaigns.table.couponCount": "Počet kupónov",
  "campaigns.table.couponNominalValue": "Nom. hodnota",
  "campaigns.table.author": "Vytvoril",
  "campaigns.table.updatedAt": "Dátum a čas",
  "campaigns.table.totalSetValue": "Objem",
  "campaigns.newCoupons.nominalValue": "Nominálna hodnota (C)",
  "campaigns.newCoupons.couponCount": "Počet QR kupónov v sade",
  "campaigns.newCoupons.title": "Vytvoriť novú sadu kupónov",
  "campaigns.newPQRCoupons.title": "Vytvoriť nový MQR kupón",
  "campaigns.newCoupons.create": "Vytvoriť",
  "campaigns.newCoupons.success": "Sada kupónov bola vytvorená",
  "campaigns.newPQRCoupons.success": "MQR kupón bol vytvorený",
  "campaigns.button.newCampaign": "Nová kampaň",
  "campaigns.button.newQRCampaign": "Nová QR kampaň",
  "campaigns.button.newPQRCampaign": "Nová MQR kampaň",
  "campaigns.button.newPQRCoupon": "Nový MQR kupón",
  "campaigns.button.newCouponSet": "Nová sada kupónov",
  "campaigns.toggle.couponSet": "Sady kupónov",
  "campaigns.toggle.qrCampaign": "Kupónové kampane",
  "campaigns.approved.response.success": "Kampaň bola schválená",
  "campaigns.rejected.response.success": "Kampaň bola zamietnutá",
  "campaigns.approved.button": "Schváliť kampaň",
  "campaigns.rejected.button": "Zamietnuť kampaň",
  "campaigns.removeModal.text": "Naozaj chcete ukončiť kampaň",
  "campaigns.rejectedModal.text": "Naozaj chcete zamietnuť kampaň",
  "campaigns.approvedModal.text": "Naozaj chcete schváliť kampaň",
  "campaigns.pictureCard.title": "Náhľad",
  "campaigns.creditChart.title": "Náklady",
  "campaigns.creditChart.remainingCredits": "Zostávajúce kredity",
  "campaigns.creditChart.usedCredits": "Použité kredity",
  "campaigns.photosChart.title": "Výtlačky",
  "campaigns.photosChart.remainingPhotos": "Zostávajúce",
  "campaigns.photosChart.usedPhotos": "Vytlačené",
  "campaigns.photosChart.allocatedPhotos": "Alokované",
  "campaigns.infoCard.name": "Názov kampane",
  "campaigns.infoCard.company": "Objednávateľ kampane",
  "campaigns.infoCard.createdBy": "Vytvoril:",
  "campaigns.infoCard.createdAt": "Dátum a čas:",
  "campaigns.budgetCard.account": "Účet:",
  "campaigns.budgetCard.qrBudget": "Počet kreditov:",
  "campaigns.budgetCard.maxPhotos": "Max. výtlačkov:",
  "campaigns.budgetCard.freeFotoBudget": "Počet výtlačkov:",
  "campaigns.coupons.removeWarning": "Naozaj chcete odstrániť sadu kupónov?",
  "campaigns.coupons.removeButton": "Odstrániť",
  "campaigns.coupons.removeSuccess": "Sada kupónov bola odstránená",
  "campaigns.coupons.couponSetExport":
    "Sada kupónov bola exportovaná do CSV súboru",
  "campaigns.graficCard.errorResolution":
    "Zvolili ste obrázok s nesprávnym rozlíšením",
  "campaigns.graficCard.successUpload": "Reklamný banner bol úspešne načítaný",
  "campaigns.graficCard.title": "Grafika kampane: ",
  "campaigns.graficCard.rules": "Pravidlá: ",
  "campaigns.graficCard.changeRules": "Vybrať iný obrázok: ",
  "campaigns.graficCard.firstRule":
    "1.) Rozlíšenie reklamného baneru: 600 x 1200 px",
  "campaigns.graficCard.secondRule": "2.) Podporované formáty: .jpg, .png",
  "campaigns.watermark.firstRule":
    "1.) Rozlíšenie FreeFoto vodoznaku: 600 x 600 px",
  "campaigns.watermark.secondRule": "2.) Podporovaný formát: .png",
  "campaigns.graficCard.choosePicture": "Pre výber obrázka stlačte tlačidlo: ",
  "campaigns.graficCard.changePicture": "Pre zmenu obrázka stlačte tlačidlo: ",
  "campaign.new.successCreated": "Kampaň bola vytvorená",
  "campaigns.setCouponSet.button": "Priradiť kupóny",
  "campaigns.addBudget.button": "Navýšiť kredit",
  "campaigns.setPQRCoupon.button": "Priradiť MQR kupón",
  "campaigns.setCouponSet.assigned": "Priradiť",
  "campaigns.setCouponSet.label": "Sada nepriradených kupónov",
  "campaigns.setCouponSet.placeholder": "Vyberte sadu kupónov...",
  "campaigns.setCouponSet.success": "Sada kupónov bola priradená",
  "campaigns.setCouponSet.confirm":
    "Naozaj chcete priradiť do kampane túto sadu kupónov?",
  "campaigns.setCouponSet.choosenCouponSet": "Zvolená sada kupónov:",
  "campaigns.setCouponSet.credits": "Zostatok kreditov:",
  "campaigns.couponsChart.title": "Kupóny",
  "campaigns.couponsChart.remainingCoupons": "Nepoužité kupóny",
  "campaigns.couponsChart.usedCoupons": "Použité kupóny",
  "campaigns.couponsChart.allCoupons": "Všetky kupóny",
  "campaigns.couponsChart.remainingBudget": "Voľný kredit",
  "campaigns.couponsChart.allocatedBudget": "Alokovaný kredit",
  "campaigns.couponsChart.allBudget": "Celkový rozpočet",
  "campaigns.couponsChart.usedBudget": "Použitý kredit",
  "campaigns.couponsChart.unusedBudget": "Nepoužitý kredit",
  "campaigns.couponsChart.noCoupons": "Nie je priradená žiadna sada kupónov",
  "campaigns.couponsChart.noAllocated":
    "Nie sú použíté ani alokované žiadne kredity",
  "campaigns.couponsTable.title": "Zoznam kupónov",
  "campaigns.couponWidget.title": "Sady kupónov",
  "campaigns.table.couponIdDetail": "ID",
  "campaigns.table.couponStatusDetail": "Stav",
  "campaigns.table.couponNominalValueDetail": "Hodnota",
  "campaigns.table.couponCountDetail": "Počet",
  "campaigns.table.couponUsedDetail": "Minuté",
  "campaigns.table.totalSetValueDetail": "Kredit",
  "campaigns.table.couponUsedCreditsDetail": "Minuté k.",
  "campaigns.table.detailButton": "Detail",
  "campaigns.table.exportButton": "Export",
  "campaigns.table.couponDate": "Využité",
  "campaigns.table.couponUser": "Používateľ",
  "campaigns.table.couponToken": "Token",
  "campaigns.table.coupon.active": "Akívna",
  "campaigns.table.coupon.depleted": "Vyčerpaná",
  "campaigns.table.coupon.used": "Použitý",
  "campaigns.table.coupon.unused": "Nepoužitý",
  "campaigns.couponSetDetail.title": "Kupónová sada",
  "campaigns.couponSetDetail.id": "ID Sady:",
  "campaigns.couponSetInfo.couponsNumber": "Počet kupónov",
  "campaigns.couponSetInfo.couponsNominalValue": "Nominálna hodnota",
  "campaigns.couponSetInfo.couponsTotalValue": "Celková hodnota",
  "campaigns.couponSetUse.title": "Využitie",
  "campaigns.couponSetUse.couponsAllNumber": "Počet kupónov",
  "campaigns.couponSetUse.couponsUsedNumber": "Počet použitých kupónov",
  "campaigns.couponSetUse.couponsRemainigNumber": "Počet nepoužitých kupónov",
  "campaigns.couponSetUse.couponsAllCredits": "Celková hodnota sady",
  "campaigns.couponSetUse.couponsUsedCredits": "Použitý kredit",
  "campaigns.couponSetUse.couponsRemainingCredits": "Nepoužitý kredit",
  "campaigns.couponSetInfo.table": "Kupóny",
  "campaigns.couponSetDetail.exportButton": "Export",
  "campaigns.unassignedCoupons.couponSet": "Sada",
  "campaigns.unassignedCoupons.PQRcouponSet": "MQR Kupón",
  "campaigns.unassignedCoupons.value": "Hodnota",

  // NOVE
  "campaigns.spotsCard.chooseSpots": "Vyberte spoty...",
  "campaigns.infoCard.type": "Typ kampane",
  "campaigns.infoCard.chooseType": "Vyberte typ kampane...",
  "campaigns.infoCard.Announcement": "Oznam",
  "campaigns.infoCard.Advertisement": "Reklama",
  "campaigns.campaignWidget.location1": "Lokalít",
  "campaigns.campaignWidget.location2": "Lokalita",
  "campaigns.campaignWidget.location3": "Lokality",

  "campaigns.campaignWidget.segment1": "Kategórií",
  "campaigns.campaignWidget.segment2": "Kategória",
  "campaigns.campaignWidget.segment3": "Kategórie",
  "campaigns.campaignWidget.company1": "Spoločností",
  "campaigns.campaignWidget.company2": "Spoločnosť",
  "campaigns.campaignWidget.company3": "Spoločnosti",
  "campaigns.campaignWidget.store1": "Prevádzok",
  "campaigns.campaignWidget.store2": "Prevádzka",
  "campaigns.campaignWidget.store3": "Prevádzky",
  "campaigns.campaignWidget.all": "Všetko",
  "campaigns.campaignWidget.find": "Vyhľadať",
  "campaigns.campaignWidget.chooseStores": "Prevádzky",

  "campaigns.addCredit.button": "Navýšiť",
  "campaigns.upCredit.success": "Kredit MQR kampane bol úspešne navýšený",
  "campaigns.setPQRCouponSet.success": "MQR kupón bol priradený kampani",
  "campaigns.button.continue": "Pokračovať",
  "campaigns.creditUp.title":
    "Chystáte sa navýšiť kredit MQR kampane o {0} C. Chcete pokračovať?",
  "campaigns.assignPQRCoupon.title": "Priradiť MQR kupón",
  "campaigns.PQRCoupon.description": "Popis MQR kupónu",
  "campaigns.PQRCoupon.couponSet": "Zoznam nepriradených MQR kupónov",
  "campaigns.setPQRCouponSet.confirm":
    "Naozaj chcete priradiť do kampane tento MQR kupón?",
  "campaigns.creditUp.modalTitle": "Navýšiť kredit MQR kampane",
  "campaigns.setPQRCouponSet.description": "Popis MQR kupónu",
  "campaigns.setQRCouponSet.id": "ID sady kupónov",
  "campaigns.setPQRCouponSet.id": "ID MQR kupónu",
  "campaigns.setQRCouponSet.value": "Hodnota sady kupónov",
  "campaigns.setPQRCouponSet.value": "Hodnota MQR kupónu",
  "campaigns.table.scans": "Počet použití",
  "campaigns.couponSetInfo.pqrName": "Popis MQR kupónu",
  "campaigns.couponSetInfo.pqrNominalValue": "Hodnota MQR kupónu",
  "campaigns.couponSetUse.pqrUse": "Počet použití MQR kupónu",
  "campaigns.infoCard.total": "Celkový rozpočet kampane",

  "campaigns.create.success": "Kampaň bola vytvorená",
  "campaigns.update.success": "Kampaň bola aktualizovaná",
  "campaigns.cancel.success": "Kampaň bola ukončená",
  "campaigns.approve.success": "Kampaň bola schválená",
  "campaigns.reject.success": "Kampaň bola zamietnutá",
  "campaigns.reject.button": "Zamietnuť",
  "campaigns.approve.button": "Schváliť",
  "campaigns.reject.bigButton": "Zamietnuť kampaň",
  "campaigns.approve.bigButton": "Schváliť kampaň",
  "campaigns.reject.modalBody": "Chcete zamietnuť kampaň",
  "campaigns.approve.modalBody": "Chcete schváliť kampaň",
  "campaigns.cancel.button": "Ukončiť kampaň",
  "campaigns.title.canceled": "Ukončená",
  "campaigns.title.rejected": "Zamietnutá",
  "campaigns.locationsCard.locations": "Lokácie",
  "campaigns.locationsCard.segments": "Kategórie",
  "campaigns.locationsCard.companies": "Spoločnosti",
  "campaigns.locationsCard.stores": "Prevádzky",
  "campaigns.locationsCard.all": "Všetko",
  "campaigns.locationsCard.table.name": "Názov",
  "campaigns.locationsCard.table.count": "Počet",
  "campaigns.scheduleCard.title": "Časový rozvrh",

  "campaigns.wizard.basicInfo": "Základné informácie",
  "campaigns.wizard.media": "Médiá",
  "campaigns.wizard.budgetAndTimePlan": "Ďalšie parametre",
  "campaigns.wizard.stores": "Prevádzky",
  "campaigns.wizard.confirm": "Potvrdenie",
  "campaigns.wizard.filters": "Vekové kategórie",
  "campaigns.wizard.basicInfo.description": "Zadajte základné údaje o kampani",
  "campaigns.wizard.media.description":
    "Vyberte obrázky kampane z knižnice médií",
  "campaigns.wizard.budgetAndTimePlan.description":
    "Zadajte rozpočet, časový plán a prevádzky kde bude kampaň cielená",
  "campaigns.wizard.age.filters":
    "Nastavte pohlavie a vek, pre lepšie zacielenie kampane",
  "campaigns.wizard.stores.description":
    "Zvoľte lokality v ktorých bude kampaň prebiehať",
  "campaigns.wizard.confirm.description":
    "Skontrolujte základné údaje o kampani a potvrďte jej vytvorenie",
  "campaigns.infoCard.withoutCredits":
    "Vybraná spoločnosť nemá dostatok kreditov",
  "campaigns.infoCard.creditStatus": "Zostatok kreditov spoločnosti: ",
  "campaigns.wizard.step3.budget": "Rozpočet",
  "campaigns.wizard.step3.maxBudget": "Maximálny rozpočet kampane",
  "campaigns.wizard.step3.maxBid":
    "Maximálna cena za jeden výtlačok (min. 11 C)",
  "campaigns.wizard.step3.timePlan": "Časový plán",
  "campaigns.wizard.finalStep.name": "Názov",
  "campaigns.wizard.finalStep.topics": "Témy",
  "campaigns.wizard.finalStep.start": "Začiatok",
  "campaigns.wizard.finalStep.end": "Koniec",
  "campaigns.wizard.finalStep.description": "Popis",
  "campaigns.wizard.finalStep.mediaAndBudget": "Médiá a rozpočet",
  "campaigns.wizard.finalStep.media": "Médiá",
  "campaigns.wizard.finalStep.maxBudget": "Max. rozpočet",
  "campaigns.wizard.finalStep.maxBid": "Max. cena výtlačok",
  "campaigns.wizard.finalStep.timePlan": "Časový plán",
  "campaigns.wizard.finalStep.minRepeatInterval": "Min. interval opakovania",
  "campaigns.wizard.finalStep.stores": "Oslovené prevádzky",
  "campaigns.wizard.finalStep.storesList": "Zoznam prevádzok",
  "campaigns.wizard.finalStep.days": "dní",
  "campaigns.wizard.step2.pictures": "Obrázky kampane",
  "campaigns.spotsCard.banner": "Reklamný banner",
  "campaigns.scheduleCard.timePlan": "Časový plán",
  "campaigns.wizard.finalStep.all": "Všetky",
  
  "campaigns.wizard.step4.title": "Pohlavie a vekové kategórie",
  "campaigns.wizard.step4.age": "Vek (od - do)",
  "campaigns.wizard.step4.gender.men": "Muži",
  "campaigns.wizard.step4.gender.women": "Ženy",
  "campaigns.wizard.step4.gender.Unknown": "Nezadaný",
  "campaigns.wizard.step4.gender.R13_18": "13 - 18",
  "campaigns.wizard.step4.gender.R19_34": "19 - 34",
  "campaigns.wizard.step4.gender.R35_49": "35 - 49",
  "campaigns.wizard.step4.gender.R50_": "50 +",
  "campaigns.wizard.step4.gender.age": " rokov",

  "campaigns.freeFoto.watermark": "Vodoznak",
  "campaigns.freeFoto.watermark.title": "Vodoznak kampane",
  "campaigns.freeFoto.watermark.default": "Predvolený vodoznak: Fotomat logo",
  "campaigns.finderBox.coupon": "Kupón",
  "campaigns.finderBox.label": "Vyhľadajte kupón podľa ID...",
  "campaigns.finderBox.value": "Hodnota",
  "campaigns.finderBox.set": "Sada",
  "campaigns.finderBox.campaign": "Kampaň",
  "campaigns.finderBox.notAssigned": "nepriradený",
  "campaigns.finderBox.applied": "Uplatnil",
  "campaigns.finderBox.unclaimed": "neuplatnený",
};

const media = {
  "media.page.title": "Zoznam médií",
  "media.page.header": "Knižnica médií",
  "media.page.info": "Tu nahrajte obrázok z lokálneho zariadenia",
  "media.uploadModal.title": "Nahrať obrázok",
  "media.uploadModal.cancelButton": "Zrušiť",
  "media.uploadModal.confirmButton": "Nahrať ",
  "media.uploadModal.name": "Názov súboru",
  "media.uploadModal.company": "Spoločnosť",
  "media.uploadModal.chooseCompany": "Vyberte spoločnosť...",
  "media.uploadModal.category": "Kategoria",
  "media.uploadModal.chooseCategory": "Vyberte kategoriu...",
  "media.uploadModal.success": "Nahrávanie súboru prebehlo úspešne",
  "media.table.approved": "Schválené",
  "media.table.pending": "Na schválenie",
  "media.table.rejected": "Zamietnuté",
  "media.table.approvedButton": "Schváliť",
  "media.table.rejectedButton": "Zamietnuť",
  "media.table.deletedButton": "Odstrániť",
  "media.table.approvedModalTitle": "Chcete schváliť tento súbor?",
  "media.table.rejectedModalTitle": "Chcete zamietnuť tento súbor?",
  "media.table.deletedModalTitle": "Chcete odstrániť tento súbor?",
  "media.table.rejectedReason": "Uveďte dôvod zamietnutia",
  "media.table.successApprove": "Súbor bol úspešne schválený",
  "media.table.successReject": "Súbor bol úspešne zamietnutý",
  "media.table.successDelete": "Súbor bol úspešne odstránený",
  "media.table.id": "ID",
  "media.table.date": " Dátum",
  "media.table.status": "Stav",
  "media.table.name": "Názov",
  "media.table.owner": "Vlastník",
  "media.table.category": "Kategória",
  "media.table.duration": "Trvanie",
  "media.table.reason": "Dôvod",
  "media.table.size": "Veľkosť",
  "media.table.actions": "Akcie",
  "media.page.uploadButton": "NAHRAŤ SÚBOR",
  "media.table.category.FreePhotoAdvertisement": "FreeFoto banner",
  "media.table.category.SignageAdvertisement": "Signage banner",
  "media.table.category.FreePhotoWatermark": "FreeFoto vodoznak",
  "media.table.status.pending": "Čaká sa",
  "media.table.status.uploaded": "Načítaný",
  "media.table.status.processing": "Spracováva sa",
  "media.table.status.processed": "Spracovaný",
  "media.table.status.invalid": "Neplatný",
  "media.table.status.failed": "Chyba",
  "media.page.badResolutinPicture":
    "Zvolili ste obrázok s nesprávnym rozlíšením. Vyberte iný obrázok",
};

const companies = {
  "companyList.title": "Spoločnosti",
  "companyList.table.title": "Zoznam spoločností",
  "companyList.table.allCompanies": "Zoznam spoločností",
  "companyList.table.addCompany": "Vytvoriť spoločnosť",
  "companyList.assignDevice": "VYTVORIŤ SPOLOČNOSŤ",
  "companyList.table.active": "Aktívny",
  "companyList.table.changePartnerSuccess": "Zmena partnera úspešná",
  "companyList.table.changePartnerFailed": "Zmena partnera neúspešná",
  "companyList.table.ID": "ID",
  "companyList.table.name": "Názov",
  "companyList.table.category": "Kategória",
  "companyList.table.stores": "Prevádzky",
  "companyList.table.address": "Adresa",
  "companyList.table.state": "Stav",
  "companyList.table.partner": "Partner",
  "companyList.table.company": "Spoločnosť",
  "companyList.table.placement": "Umiestnenie",
  "companyList.table.actions": "Akcie",
  "companyList.table.actions.detail": "Detail",
  "companyList.enabledToggle": "Prevádzka",
  "companyList.disabledToggle": "Sklad",
  "companyList.addModal.title": "VYTVORIŤ SPOLOČNOSŤ",
  "companyList.addModal.add": "PRIDAŤ",
  "companyList.addModal.cancel": "ZRUŠIŤ",
  "companyList.assignModal.partner": "Partner",
  "companyList.assignModal.company": "Vyberte spoločnosť...",
  "companiesList.filter.active": "Aktívny",
  "companiesList.filter.nonActive": "Neaktívny",
  "company.partner.assign.success": "Partner bol úspešne priradený",
  "company.partner.remove.success": "Partner bol odobratý",
  "company.store.assign.success": "Prevádzka bola úspešne priradená",
  "companyList.addCompany.title": "Nová spoločnosť",
  "companyDetail.title": "Spoločnosť",
  "companyDetail.status.storeState": "Stav spoločnosti",
  "companyDetail.status.restart": "RESTART",
  "companyDetail.status.deactivate": "DEACTIVATE",
  "companyDetail.status.reportError": "Report error",
  "companyDetail.status.activate": "Activate",
  "companyDetail.status.back": "Back",
  "companyDetail.status.restartModal.title": "Restart company",
  "companyDetail.status.restartModal.text":
    "Do you want to restart the company?",
  "companyDetail.status.deactivateModal.title": "Deactivate company",
  "companyDetail.status.deactivateModal.text":
    "Do you want to deactivate the company?",
  "companyDetail.status.activateModal.title": "Activate company",
  "companyDetail.status.activateModal.text":
    "Do you want to activate the company?",
  "companyDetail.partner.title": "Partner",
  "companyDetail.partner.printerPhotos": "Printer photo count",
  "companyDetail.partner.orderPhotos": "Order photo count",
  "companyDetail.partner.changePartner": "CHANGE PARTNER",
  "companyDetail.partner.removePartner": "REMOVE PARTNER",
  "companyDetail.partner.detailPartner": "PARTNER DETAIL",
  "companyDetail.partner.changePartnerModal.title": "Change partner",
  "companyDetail.partner.changePartnerModal.action": "CHANGE",
  "companyDetail.partner.removePartnerModal.title": "Remove partner",
  "companyDetail.partner.removePartnerModal.text":
    "Do you want to remove the partner?",
  "companyDetail.partner.removePartnerModal.action": "REMOVE",
  "companyDetail.partner.back": "Back",
  "companyDetail.orders.title": "Orders",
  "companyDetail.orders.ordersList": "Order list",
  "companyDetail.orders.table.id": "ID",
  "companyDetail.orders.table.date": "Date",
  "companyDetail.orders.table.volume": "Amount",
  "companyDetail.orders.table.actions": "Actions",
  "companyDetail.orders.table.actions.detail": "Detail",
  "companyDetail.provisions.title": "Commissions",
  "companyDetail.provisions.totalProvision": "Total commission",
  "companyDetail.provisions.provisionsList": "Commission list",
  "companyDetail.address.placeholder": "Enter address",
  "companyDetail.address.undefinedLocation": "Location not specified",
  "companyDetail.partner.removePartnerSuccessful":
    "Partner removed successfuly",
  "companyDetail.partner.removePartnerFailed": "Failed to remove partner",
  "companyDetail.partner.changePartnerSuccessful":
    "Partner changed  successfuly",
  "companyDetail.partner.changePartnerFailedl": "Failed to change partner",
  "companyDetail.status.companyDeactivationSuccessful":
    "Deactivated successfuly",
  "companyDetail.status.companyActivationSuccessful": "Activated successfuly",
  "companyDetail.status.companyDeactivationFailed": "Failed to deactivate",
  "companyDetail.status.companyActivationFailed": "Failed to activate",
  "companyDetail.status.companyRestartSuccessful": "Restarted successfuly",
  "companyDetail.status.companyRestartFailed": "Failed to restart",
  "companyDetail.partner.changeAddressFailed": "Failed to change address",
  "companyDetail.partner.changeAddressSuccessful":
    "Address changed successfuly",
  "companyDetail.address.title": "Adresa",
  "companyDetail.partnersTable.title": "Zoznam partnerov",
  "companyDetail.partnersTable.id": "ID",
  "companyDetail.partnersTable.name": "Name",
  "companyDetail.partnersTable.action": "Action",
  "companyDetail.addressCard.name": "Názov spoločnosti",
  "companyDetail.addressCard.address": "Ulica a číslo",
  "companyDetail.addressCard.postal": "PSČ",
  "companyDetail.addressCard.city": "Mesto",
  "companyDetail.addressCard.country": "Štát",
  "companyDetail.contactPersonCard.title": "Kontaktná osoba",
  "companyDetail.contactPersonCard.firstName": "Meno",
  "companyDetail.contactPersonCard.lastName": "Priezvisko",
  "companyDetail.contactPersonCard.phone": "Telefón (Formát: +421903123123)",
  "companyDetail.contactPersonCard.email": "E-mail",
  "companyDetail.billingInfoCard.ico": "IČO",
  "companyDetail.billingInfoCard.dic": "DIČ",
  "companyDetail.billingInfoCard.icDPH": "IČ DPH",
  "companyDetail.billingInfoCard.registrationCourt": "Registrový súd, vložka",
  "companyDetail.billingInfoCard.iban": "IBAN",
  "companyDetail.billingInfoCard.bankBIC": "BIC/SWIFT",
  "companyDetail.billingInfoCard.bankName": "Názov banky",
  "companyDetail.accountStatus.title": "Stav účtu",
  "companyDetail.accountStatus.status": "Zostatok na učte: ",
  "companyDetail.accountStatus.stores": "Prevádzky",
  "companyDetail.accountStatus.commissions": "Provízie",
  "companyDetail.btn.assignPartner": "Priradiť partnera",
  "companyDetail.btn.assign": "PRIRADIŤ",
  "companyDetail.btn.assignStore": "Priradiť prevádzku",
  "companyDetail.btn.removeCompany": "Odstrániť spoločnosť",
  "companyDetail.btn.remove": "ODSTRÁNIŤ",
  "companyDetail.store.title": "Prevádzka",
  "companyDetail.company.removeCompanySuccessful":
    "Spoločnosť bola úspešne odstránená",
  "companyDetail.company.updateCompanySuccessful":
    "Detaily spoločnosti boli úspešne aktualizované",
};

const stores = {
  "storeList.title": "Prevádzky",
  "storeList.table.title": "Zoznam prevádzok",
  "storeList.table.active": "Aktívny",
  "storeList.table.changePartnerSuccess": "Zmena partnera úspešná",
  "storeList.table.changePartnerFailed": "Zmena partnera neúspešná",
  "storeList.table.ID": "ID",
  "storeList.table.name": "Názov",
  "storeList.table.partnerName": "Meno",
  "storeList.table.category": "Kategória",
  "storeList.table.address": "Adresa",
  "storeList.table.state": "Stav fotomatu",
  "storeList.table.heartbeat": "Heartbeat",
  "storeList.table.partner": "Partner",
  "storeList.table.company": "Spoločnosť",
  "storeList.table.printedPhotos": "Počet výtlačkov",
  "storeList.table.placement": "Umiestnenie",
  "storeList.table.pricingLevel": "Cenová hladina",
  "storeList.table.actions": "Akcie",
  "storeList.table.actions.detail": "Detail",
  "storeList.table.actions.remove": "Odobrať",
  "storeList.table.actions.removeWarning":
    "Chystáte sa odobrať partnera spoločnosti",
  "storeList.enabledToggle": "Prevádzka",
  "storeList.disabledToggle": "Sklad",
  "storeList.assignModal.title": "Pridať prevádzku",
  "storeList.assignModal.cancel": "ZRUŠIŤ",
  "storeList.assignModal.partner": "Partner",
  "storeList.assignModal.store": "Prevádzka",
  "storeList.addModal.add": "PRIDAŤ",
  "storeList.addModal.title": "Vytvoriť prevádzku",
  "devicesList.filter.active": "Aktívny",
  "devicesList.filter.nonActive": "Neaktívny",
  "storeList.addStore.title": "Nová prevádzka",
  "storeList.addStore.success": "Prevádzka {0} bola vytvorená",
  "storeList.operator.name": "Meno prevádzkara",
  "storeList.partner.name": "Meno partnera",
  "storeList.company.name": "Názov spoločnosti",
  "storeList.table.addStore": "Vytvoriť prevádzku",
  "storeList.table.allStore": "Zoznam prevádzok",
  "storeList.online": "Online",
  "storeList.offline": "Offline",
  "storeList.withoutDevice": "Bez fotomatu",
  "storeList.error": "Chyba",
  "storeDetail.title": "Prevádzka",
  "storeDetail.titleName": "Názov prevádzky",
  "storeDetail.category": "Kategória",
  "storeDetail.area": "Plocha prevádzky(m2)",
  "storeDetail.status.storeState": "Stav prevádzky",
  "storeDetail.partner.title": "Partner",
  "storeDetail.partner.changePartner": "CHANGE PARTNER",
  "storeDetail.partner.removePartner": "REMOVE PARTNER",
  "storeDetail.partner.detailPartner": "PARTNER DETAIL",
  "storeDetail.partner.changePartnerModal.title": "Change partner",
  "storeDetail.partner.changePartnerModal.action": "CHANGE",
  "storeDetail.partner.removePartnerModal.title": "Remove partner",
  "storeDetail.partner.removePartnerModal.text":
    "Do you want to remove the partner?",
  "storeDetail.partner.removePartnerModal.action": "REMOVE",
  "storeDetail.partner.back": "Back",
  "storeDetail.orders.table.id": "ID",
  "storeDetail.orders.table.date": "Date",
  "storeDetail.orders.table.volume": "Amount",
  "storeDetail.orders.table.actions": "Actions",
  "storeDetail.orders.table.actions.detail": "Detail",
  "storeDetail.address.title": "Location address",
  "storeDetail.address.placeholder": "Enter address",
  "storeDetail.address.undefinedLocation": "Location not specified",
  "storeDetail.partner.removePartnerSuccessful": "Partner removed successfuly",
  "storeDetail.partner.removePartnerFailed": "Failed to remove partner",
  "storeDetail.store.addstoreSuccessful": "Prevádzka úspešne pridaná",
  "storeDetail.store.updatedSuccessful": "Prevádzka úspešne aktualizovaná",
  "storeDetail.partner.changePartnerFailedl": "Failed to change partner",
  "storeDetail.status.storeDeactivationSuccessful": "Deactivated successfuly",
  "storeDetail.status.storeActivationSuccessful": "Activated successfuly",
  "storeDetail.status.storeDeactivationFailed": "Failed to deactivate",
  "storeDetail.status.storeActivationFailed": "Failed to activate",
  "storeDetail.status.storeRestartSuccessful": "Restarted successfuly",
  "storeDetail.status.storeRestartFailed": "Failed to restart",
  "storeDetail.partner.changeAddressFailed": "Failed to change address",
  "storeDetail.partner.changeAddressSuccessful": "Address changed successfuly",
  "storeDetail.openingHours.title": "Otváracie hodiny",
  "storeDetail.openingHours.holiday": "Otvorené cez sviatky",
  "storeDetail.playtime.open": "Začiatok prehrávania po otvorení",
  "storeDetail.playtime.close": "Koniec prehrávania pred zatvorením",
  "storeDetail.countAdSpots": "Počet reklamných spotov",
  "storeDetail.storeCard.name": "Názov prevádzky",
  "storeDetail.storeCard.address": "Adresa prevádzky",
  "storeDetail.storeCard.category": "Kategória",
  "storeDetail.storeCard.area": "Plocha prevádzky (m2)",
  "storeDetail.storeCard.visitRate": "Návštevnosť (osôb/hod.)",
  "storeDetail.storeCard.playlist": "DS Playlist",
  "storeDetail.storeCard.playlist.without": "Bez playlistu",
  "storeDetail.storeCard.isOnlyOwnCampaignAllowed":
    "Tlačenie len vlastných FF kampaní",
  "storeDetail.storeCard.isOnlyOwnCampaignAllowed.on": "Áno",
  "storeDetail.storeCard.isOnlyOwnCampaignAllowed.off": "Nie",
  "storeDetail.mediaCard.forbiddenTopics": "Zakázané témy kampaní",
  "storeDetail.mediaCard.chooseForbiddenTopics": "Vyberte zakázané témy...",
  "storeDetail.companyCard.title": "Spoločnosť",
  "storeDetail.companyCard.name": "Názov spoločnosti",
  "storeDetail.companyCard.address": "Adresa spoločnosti",
  "storeDetail.companyCard.contactPerson": "Meno kontaktnej osoby",
  "storeDetail.companyCard.phone": "Telefón (Formát: +421903123123)",
  "storeDetail.companyCard.email": "E-mail",
  "storeDetail.storeAddressCard.title": "Adresa",
  "storeDetail.storeAddressCard.address": "Ulica a popisné číslo",
  "storeDetail.storeAddressCard.postal": "PSČ",
  "storeDetail.storeAddressCard.city": "Mesto",
  "storeDetail.storeAddressCard.cityPlaceholder": "Vyberte mesto...",
  "storeDetail.storeAddressCard.country": "Štát",
  "storeDetail.storeAddressCard.latitude": "Zemepisná šírka",
  "storeDetail.storeAddressCard.longtitude": "Zemepisná dĺžka",
  "storeDetail.partnerCard.firstName": "Meno",
  "storeDetail.partnerCard.lastName": "Priezvisko",
  "storeDetail.partnerCard.partnerAddress": "Adresa partnera",
  "storeDetail.partnerCard.phone": "Telefón (Formát: +421903123123)",
  "storeDetail.partnerCard.email": "E-mail",
  "storeDetail.partnerCard.addPartner": "Vytvoriť partnera",
  "storeDetail.partnerCard.allPartners": "Všetci partneri",
  "storeDetail.partnerCard.allOrders": "Zoznam objednávok",
  "storeDetail.partnerCard.allPayments": "Zoznam platieb",
  "storeDetail.partnerCard.allOperations": "Zoznam operácií",
  "storeDetail.campaignCard.title": "Kampane",
  "storeDetail.maintainer.title": "Prevádzkar",
  "storeDetail.removeStore.text": "Naozaj chcete odstrániť prevádzku",
  "storeDetail.contactPerson.title": "Kontaktná osoba",
  "storeDetail.billing.title": "Fakturačné údaje",
  "storeDetail.removeStore.title": "Odstrániť prevádzku",
  "storeDetail.removePlayer.title": "Odobrať fotomat",
  "storeDetail.removePlayer.successful": "Fotomat bol úspešne odobratý",
  "storeDetail.removeStore.successful": "Prevádzka bola úspešne odstránená",
  "storeDetail.changePlayer.text-1": "K prevádzke ",
  "storeDetail.changePlayer.text-2": "je priradený fotomat",
  "storeDetail.changePlayer.text-3": "Vyberte nový fotomat",
  "storeDetail.mediaCard.fileName": "Názov súboru",
  "storeDetail.maintainerCard.title": "Prevádzkar",
  "storeDetail.maintainerCard.firstName": "Meno",
  "storeDetail.maintainerCard.lastName": "Priezvisko",
  "storeDetail.maintainerCard.phone": "Telefón (+421903123123)",
  "storeDetail.maintainerCard.email": "E-mail",
  "storeDetail.device.remove": "Odobrať",
  "storeDetail.commissionCard.allCommissions": "Celková výška provízií",
  "storeDetail.commissionCard.button": "Zoznam provízií ",
  "storeDetail.commissionCard.title": "Provízie",
  "storeDetail.commissionCard.com": "Výška provízií",
  "storeDetail.printedPhotosCard.title":
    "Vytlačené fotky (posledných 30 dní)",
  "storeDetail.printedPhotosCard.allPhotos": "Spolu",
  "storeDetail.printedPhotosCard.threshold": "Hranica provízií",
  "storeDetail.printedPhotosCard.photos": "Vytlačené fotografie",
  "storeDetail.printedPhotosCard.day": "Deň",
  "storeDetail.deviceStatusCard.title": "Fotomat",
  "storeDetail.deviceStatusCard.ID": "ID fotomatu",
  "storeDetail.deviceStatusCard.status": "Stav fotomatu",
  "storeDetail.deviceStatusCard.lastHeartbeat": "Posledný heartbeat",
  "storeDetail.deviceStatusCard.numberOfPhotos": "Zostávajúci počet fotografií",
  "storeDetail.deviceStatusCard.printerCounter": "Počítadlo tlačiarne",
  "storeDetail.deviceStatusCard.averagePhotos":
    "Priemerný počet fotografií za mesiac",
  "storeDetail.deviceStatusCard.isEnabled": "Stav pripojenia fotomatu",
  "storeDetail.deviceStatusCard.fotomatDetail": "Detail fotomatu",
  "storeDetail.deviceStatusCard.wifiSsid": "SSID",
  "storeDetail.deviceStatusCard.wifiPassword": "Wifi heslo",
  "storeDetail.deviceStatusCard.connection": "Pripojenie fotomatu",

  "storeDetail.deviceStatusCard.connection.ethernet": "Ethernetové pripojenie",
  "storeDetail.deviceStatusCard.connection.wifi": "Wifi pripojenie",
  "storeDetail.deviceStatusCard.connection.mobile": "Mobilné pripojenie",
  "storeDetail.deviceStatusCard.connectionStatus.InUse": "Práve sa používa",
  "storeDetail.deviceStatusCard.connectionStatus.Connected": "Pripravený",
  "storeDetail.deviceStatusCard.connectionStatus.Disconnected": "Odpojený",
  "storeDetail.deviceStatusCard.connectionStatus.Unavailable": "Nedostupný",
  "storeDetail.deviceStatusCard.connectionStatus.Unknown": "Neznámy",

  "storeDetail.openingHours.spotsPlaybackStartOffset":
    "Začiatok prehrávania po otvorení",
  "storeDetail.openingHours.spotsPlaybackEndOffset":
    "Koniec prehrávania pred zatvorením",
  "storeDetail.openingHours.specialClosed": "Výnimočne zatvorené",
  "storeDetail.openingHours.open": "Otvorené",
  "storeDetail.openingHours.closed": "Zatvorené",
  "storeDetail.openingHours.powerSavingMode": "Akcia mimo otváracích hodín",
  "storeDetail.openingHours.powerSavingMode.ScreenPowerOff":
    "Vypnutie obrazovky",
  "storeDetail.openingHours.powerSavingMode.SystemPowerOff":
    "Vypnutie fotomatu",
  "storeDetail.openingHours.powerSavingMode.None": "Žiadna",
};

const orders = {
  "orderList.title": "Objednávky",
  "orderList.table.title": "Zoznam objednávok",
  "orderList.table.ID": "ID",
  "orderList.table.status": "Stav",
  "orderList.table.paid": "Zaplatená",
  "orderList.isPaid.yes": "Zaplatená",
  "orderList.isPaid.no": "Nezaplatená",
  "orderList.table.created": "Vytvorená",
  "orderList.table.notCreated": "Nevytvorená",
  "orderList.table.email": "Email",
  "orderList.table.createdAt": "Vytvorenie",
  "orderList.table.expiration": "Expirácia",
  "orderList.table.credit": "Objem",
  "orderList.table.type": "Typ",
  "orderList.table.creditType": "Kreditná",
  "orderList.table.freeFotoType": "FreeFoto",
  "orderList.table.creditAndPaymentType": "Kredit + Doplatok",
  "orderList.table.paymentType": "Doplatok",
  "orderList.table.actions": "Akcie",
  "orderList.table.actions.detail": "Detail",
  "orderList.table.purchaseAmount": "Doplatok",
  "orderDetail.title": "Objednávka",
  "orderDetail.cancelModal.title": "Naozaj chcete stornovať objednávku?",
  "orderDetail.cancelModal.button": "Stornovať",
  "orderDetail.cancel.button": "Stornovať objednávku",
  "orderDetail.statusCard.title": "Cenník objednávky",
  "orderDetail.paymentCard.title": "Stav objednávky",
  "orderDetail.paymentCard.type": "Typ objednávky",
  "orderDetail.paymentCard.credit": "Uhradené kreditmi z aplikácie",
  "orderDetail.paymentCard.payment": "Uhradené doplatkom v eurách",
  "orderDetail.paymentCard.summary": "Uhradené celkovo",
  "orderDetail.statusCard.firstPhotoCount": "Počet fotografií 10x15",
  "orderDetail.statusCard.secondPhotoCount": "Počet fotografií 15x15",
  "orderDetail.statusCard.thirdPhotoCount": "Počet fotografií 20x15",
  "orderDetail.statusCard.totalCount": "Celkový počet fotografií",
  "orderDetail.statusCard.totalCredits": "Hodnota objednávky",
  "orderDetail.statusCard.paidStatus": "Stav objednávky",
  "orderDetail.customerCard.title": "Objednávateľ",
  "orderDetail.customerCard.name": "Meno a Priezvisko",
  "orderDetail.customerCard.email": "E-mail",
  "orderDetail.customerCard.phone": "Telefón",
  "orderDetail.customerCard.userDetail": "Detail používateľa",
  "orderDetail.historyCard.title": "Historia objednávky",
  "orderDetail.historyCard.Created": "Vytvorená",
  "orderDetail.historyCard.Processing": "Spracováva sa",
  "orderDetail.historyCard.Ready": "Čaká na tlač",
  "orderDetail.historyCard.InProgress": "Tlačí sa",
  "orderDetail.historyCard.Completed": "Dokončená",
  "orderDetail.historyCard.Incomplete": "Nedokončená",
  "orderDetail.historyCard.Partial": "Čiatočne dokončená",
  "orderDetail.historyCard.Expired": "Expirovaná",
  "orderDetail.historyCard.Canceled": "Stornovaná",
  "orderDetail.removeOrder.successful": "Objednávka bola stornovaná",
};

const invoices = {
  "invoicesList.title": "Faktúry",
  "invoicesList.enabledToggle": "Faktúry",
  "invoicesList.disabledToggle": "Dobropisy",
  "invoicesList.table.title": "Faktúry a dobropisy",
  "invoicesList.table.ID": "ID",
  "invoicesList.table.identifier": "Číslo faktúry",
  "invoicesList.table.company": "Spoločnosť",
  "invoicesList.table.description": "Popis",
  "invoicesList.table.price": "Suma",
  "invoicesList.table.credit": "Kredity",
  "invoicesList.table.date": "Dátum",
  "invoicesList.table.createdBy": "Zaúčtoval",
  "invoicesList.addModal.title": "PÁROVAŤ FAKTÚRU",
  "invoicesList.assignModal.cancel": "ZRUŠIŤ",
  "invoicesList.assignModal.title": "Párovať faktúru",
  "invoicesList.assignModal.company": "Spoločnosť",
  "invoicesList.assignModal.number": "Číslo faktúry",
  "invoicesList.assignModal.price": "Suma (€)",
  "invoicesList.assignModal.credit": "Počet kreditov",
  "invoicesList.assignModal.description": "Popis",
  "invoicesList.assignModal.identifier": "Identifikačné číslo faktúry",
  "invoicesList.assignModal.create": "Vytvoriť",
  "invoicesList.createInvoiceSuccessfull": "Faktúra bola úspešne vytvorená",
  "invoiceDetail.widget.title": "Platba",
  "invoiceDetail.title": "Faktúra",
  "invoiceDetail.price": "Suma platby",
  "invoiceDetail.credit": "Objem kreditov",
  "invoiceDetail.ID": "ID platby",
  "invoiceDetail.identifier": "Číslo platby",
  "invoiceDetail.type": "Typ platby",
  "invoiceDetail.description": "Popis",
  "invoiceDetail.createdBy": "Vytvoril",
  "invoiceDetail.createdAt": "Dátum a čas",
  "invoiceDetail.phone": "Telefón",
};

const payouts = {
  "payoutsList.title": "Dobropisy",
  "payoutsList.table.ID": "ID",
  "payoutsList.table.company": "Spoločnosť",
  "payoutsList.table.description": "Popis",
  "payoutsList.table.price": "Suma",
  "payoutsList.table.credit": "Kredity",
  "payoutsList.table.date": "Dátum",
  "payoutsList.table.createdBy": "Zaúčtoval",
  "payoutsList.addModal.title": "VYTVORIŤ DOBROPIS",
  "payoutsList.assignModal.title": "Vytvoriť dobropis",
  "payoutsList.assignModal.company": "Spoločnosť",
  "payoutsList.assignModal.price": "Suma (€)",
  "payoutsList.assignModal.credit": "Počet kreditov (C)",
  "payoutsList.assignModal.description": "Popis",
  "payoutsList.assignModal.identifier": "Identifikačné číslo dobropisu",
  "payoutsList.assignModal.create": "Vytvoriť",
  "payoutsList.createPayoutSuccessfull": "Dobropis bol úspešne vytvorený",
  "payoutDetail.title": "Dobropis",
};

const operations = {
  "operationsList.title": "Operácie",
  "operationsList.table.title": "Zoznam operácií",
  "operationsList.table.ID": "ID",
  "operationsList.table.date": "Dátum",
  "operationsList.table.sourceID": "ID zdroja",
  "operationsList.table.operation": "Typ operácie",
  "operationsList.table.destinationID": "ID cieľa",
  "operationsList.table.price": "Hodnota",
};

const payments = {
  "paymentsList.title": "Platby",
  "paymentsList.table.title": "Zoznam platieb",
  "paymentsList.table.ID": "ID",
  "paymentsList.table.type": "Typ",
  "paymentsList.table.value": "Objem",
  "paymentsList.table.price": "Suma",
  "paymentsList.table.date": "Dátum",
  "paymentsList.table.purpose": "Účel",
  "paymentsList.table.user": "Používateľ",
  "paymentsList.table.operationID": "Operácia",
  "paymentsList.table.payoutID": "ID platby",
  "paymentsList.table.type.GooglePay": "Google Pay",
  "paymentsList.table.type.ApplePay": "Apple Pay",
  "paymentsList.table.type.CreditCard": "platba kartou",
  "paymentsList.table.purpose.OrderPayment": "platba objednávky",
  "paymentsList.table.purpose.Subscription": "predplatné",
  "paymentsList.table.status": "Stav",
  "paymentsList.status.Pending": "Čaká sa",
  "paymentsList.status.Processing": "Spracováva sa",
  "paymentsList.status.Succeeded": "Úspešná",
  "paymentsList.status.Expired": "Expirovaná",
};

const commissions = {
  "commissionsList.title": "Provízie",
  "commissionsList.table.title": "Zoznam provízií",
  "commissionsList.addModal.title": "EXPORT",
  "commissionsList.table.commissionID": "ID Provízie",
  "commissionsList.table.companyID": "ID Spoločn.",
  "commissionsList.table.companyName": "Spoločnosť",
  "commissionsList.table.stores": "Prevádzky",
  "commissionsList.table.email": "Email",
  "commissionsList.table.date": "Obdobie",
  "commissionsList.table.credit": "Objem",
  "commissionsList.table.margin": "Sadzba",
  "commissionsList.table.price": "Suma",
  "commissionsList.table.actions": "Akcie",
  "commissionsList.table.storeID": "ID Prevádzky",
  "commissionsList.table.storeName": "Prevádzka",
  "commissionsList.table.address": "Umiestnenie",
  "commissionList.table.subtitle": "Celkom",
  "commissionsList.assignModal.title": "Exportovať dáta",
  "commissionsList.assignModal.pickerStart": "Mesiac od: ",
  "commissionsList.assignModal.pickerEnd": "Mesiac do: ",
  "commissionsList.assignModal.monthFrom": "Zvoľte počiatočný mesiac",
  "commissionsList.assignModal.monthTo": "Zvoľte koncový mesiac",
  "commissionsList.table.pickerStart": "Od: ",
  "commissionsList.table.pickerEnd": "Do: ",
  "commissionsList.month.january": "Január",
  "commissionsList.month.february": "Február",
  "commissionsList.month.march": "Marec",
  "commissionsList.month.april": "Apríl",
  "commissionsList.month.may": "Máj",
  "commissionsList.month.june": "Jún",
  "commissionsList.month.july": "Júl",
  "commissionsList.month.august": "August",
  "commissionsList.month.september": "September",
  "commissionsList.month.october": "Október",
  "commissionsList.month.november": "November",
  "commissionsList.month.december": "December",
  "commissionsList.export.success":
    "Provízie boli úspešne exportované do csv súboru",
  "commissionDetail.title": "Provízie spoločnosti",
  "commissionDetail.photo.title": "Fotografie",
  "commissionDetail.photo.body": "Celkový počet fotografií",
  "commissionDetail.commission.title": "Provízia",
  "commissionDetail.commission.body": "Celková výška provízie",
  "commissionDetail.margin.title": "Sadzba",
  "commissionDetail.margin.body": "Priemenerná percentuálna sadzba",
};

const profilePage = {
  "profile.contactInfo.title": "Kontaktné Informácie",
  "profile.contactInfo.firstName": "Meno",
  "profile.contactInfo.lastName": "Priezvisko",
  "profile.contactInfo.gender": "Pohlavie",
  "profile.contactInfo.email": "E-mail",
  "profile.contactData.title": "Kontaktné údaje",
  "profile.contactData.phone": "Telefón",
  "profile.contactData.email": "Email",
  "profile.bankData.title": "Bankové informácie",
  "profile.bankData.bic": "BIC/SWIFT",
  "profile.bankData.iban": "IBAN",
  "profile.bankData.bankName": "Banka",
  "profile.changePassword.title": "Zmena hesla",
  "profile.changePassword.oldPassword": "Zadajte pôvodné heslo",
  "profile.changePassword.newPassword": "Zadajte nové heslo",
  "profile.changePassword.verifyPassword": "Zopakujte nové heslo",
  "profile.changePassword.change": "ZMENIŤ",
  "profile.identificators.title": "Identifikátory",
  "profile.identificators.ico": "IČO",
  "profile.identificators.dic": "DIČ",
  "profile.identificators.icDph": "IČ DPH",
  "profile.identificators.registerCode": "Registrový súd",
  "profile.companyData.title": "Údaje o spoločnosti",
  "profile.companyData.name": "Názov  spoločnosti",
  "profile.companyData.city": "Mesto",
  "profile.companyData.street": "Ulica",
  "profile.companyData.postal": "PSČ",
  "profile.companyData.country": "Krajina",
  "profile.language": "Jazyk",
  "profile.language.success": "Language preference was updated",
  "profile.password.empty": "Nezadali ste žiadne heslo",
  "profile.password.success": "Heslo bolo úspešene zmenené",
  "profile.password.failed": "Heslo nebolo zmenené",
  "profile.password.notEquals": "Heslá sa nezhodujú",
};

const userRoles = {
  "userRoles.partner": "Partner",
  "userRoles.maintainer": "Správca",
  "userRoles.advertiser": "Inzerent",
  "userRoles.user": "Používateľ",
  "userRoles.admin": "Administrátor",
};

const deviceStatus = {
  "deviceStatus.offline": "Offline",
  "deviceStatus.online": "Online",
  "deviceStatus.error": "Chyba",
};

const loginPage = {
  "loginPage.cardTitle": "Prihlásenie",
  "loginPage.email": "E-mail",
  "loginPage.password": "Heslo",
  "loginPage.submit": "Prihlásiť",
  "loginPage.stayLogged": "Zostať prihlásený",
  "loginPage.forgottenPassword": "Zabudnuté heslo",
  "loginPage.forgottenPassword.email": "Váš e-mail",
  "loginPage.forgottenPassword.infoText":
    "Uveďte e-mail spojený s Vaším účtom a pošleme Vám odkaz na zmenu hesla.",
  "loginPage.forgottenPassword.cancel": "Zrušiť",
  "loginPage.forgottenPassword.submit": "Odoslať",
  "loginPage.forgottenPassword.success":
    "Na e-mail bol odoslaný odkaz na zmenu hesla.",
  "loginPage.login.success": "Prihlásenie prebehlo úsešne",
};

const notificationsPage = {
  "notificationsPage.title": "Notifikácie",
  "notificationsPage.create": "VYTVORIŤ NOTIFIKÁCIU",
  "notificationsPage.table.title": "Zoznam notifikácií",
  "notificationsPage.table.id": "ID",
  "notificationsPage.table.date": "Dátum",
  "notificationsPage.table.author": "Autor",
  "notificationsPage.table.header": "Hlavička",
  "notificationsPage.table.text": "Text",
  "notificationsPage.modal.title": "Nová notifikácia",
  "notificationsPage.modal.header": "Hlavička",
  "notificationsPage.modal.text": "Text",
  "notificationsPage.modal.screen": "Obrazovka",
  "notificationsPage.modal.submit": "Odoslať",
  "notificationsPage.modal.cancel": "Zrušiť",
  "notificationsPage.create.success": "Notifikácia bola odoslaná",
  "notificationsPage.modal.confirm.title": "Potvrdenie",
  "notificationsPage.modal.confirm.text": "Chystáte sa odoslať notifikáciu",
  "notificationsPage.modal.confirm.submit": "ODOSLAŤ",
  "notificationsPage.modal.confirm.cancel": "NASPÄŤ",

  "notificationsPage.new.title": "Nová notifikácia",
  "notificationsPage.new.basicInfoCard.title":
    "Filtrovanie základných informácií",
  "notificationsPage.new.activityInfoCard.title":
    "Filtrovanie informácií o aktivite používateľov",
  "notificationsPage.new.notificationCard.title": "Nová notifikácia",
  "notificationsPage.new.basicInfoCard.gender": "Pohlavie",
  "notificationsPage.new.basicInfoCard.age": "Vek (od - do)",
  "notificationsPage.new.basicInfoCard.dayOfBirdth": "Deň a mesiac narodenia",
  "notificationsPage.new.basicInfoCard.emails": "Zoznam emailov",
  "notificationsPage.new.basicInfoCard.firstNames": "Zoznam krstných mien",
  "notificationsPage.new.activityInfoCard.registrationType": "Typ registrácie",
  "notificationsPage.new.activityInfoCard.lastOrder": "Posledná objednávka",
  "notificationsPage.new.activityInfoCard.turnover":
    "Obrat celkovo (Minimálna hranica v €)",
  "notificationsPage.new.activityInfoCard.subscriptionType": "Typ predplatného",
  "notificationsPage.new.activityInfoCard.creditStatus":
    "Stav kreditného účtu (Horná hranica v €)",
  "notificationsPage.new.activityInfoCard.isPrintedFreeFoto":
    "Tlačil už používateľ FreeFoto?",
  "notificationsPage.new.notificationCard.delay":
    "Oneskorenie odoslania notifikácie",
  "notificationsPage.new.notificationCard.delay.on": "Zapnuté",
  "notificationsPage.new.notificationCard.delay.off": "Vypnuté",
  "notificationsPage.new.notificationCard.sendDate":
    "Dátum odoslania notifikácie",
  "notificationsPage.new.button.calculate": "Kalkulovať",
  "notificationsPage.new.button.create": "Vytvoriť notifikáciu",
  "notificationsPage.new.modal.totalUsers": "Počet oslovených používateľov",
  "notificationsPage.new.modal.sendDate": "Dátum odoslania notifikácie",
  "notificationsPage.new.options.yes": "Áno",
  "notificationsPage.new.options.no": "Nie",
  "notificationsPage.new.options.noImportant": "Nezáleží",
  "notificationsPage.new.options.male": "Muž",
  "notificationsPage.new.options.female": "Žena",
  "notificationsPage.new.options.withoutGender": "Neuvedené",
  "notificationsPage.new.options.withoutSubscription": "Bez predplatného",
  "notificationsPage.new.options.all": "Nezáleží",

  "notificationsPage.automatic.title": "Automatické notifikácie",
  "notificationsPage.allCards.sendNotifications":
    "Automatické odosielanie notifikácie",
  "notificationsPage.allCards.sendNotifications.on": "Zapnuté",
  "notificationsPage.allCards.sendNotifications.off": "Vypnuté",
  "notificationsPage.allCards.mutations": "SK/EN jazykové verzie notifikácie",
  "notificationsPage.allCards.headerSK": "Hlavička (SK)",
  "notificationsPage.allCards.bodySK": "Text (SK)",
  "notificationsPage.allCards.headerEN": "Hlavička (EN)",
  "notificationsPage.allCards.bodyEN": "Text (EN)",

  "notificationsPage.expirationSubscriptionCard.title":
    "Exspiracia predplatneho",
  "notificationsPage.cancelSubscriptionCard.title": "Ukončenie predplatného",
  "notificationsPage.expirationPaymentCard.title": "Exspirácia platby",
  "notificationsPage.expirationOrderCard.title": "Exspirácia objednávky",
  "notificationsPage.successfulSubsRenewalCard.title":
    "Úspešná obnova predplatného",
  "notificationsPage.unsuccessfulSubsRenewalCard.title":
    "Neúspešná obnova predplatného",
  "notificationsPage.printedPhotoCard.title":
    "Úspešne vytlačená prvá objednávka",
  "notificationsPage.orderPaymentCard.title": "Doplatok za objednávku",
  "notificationsPage.orderLockCard.title": "Uzamknutie objednávky",

  "notificationsPage.expirationSubscriptionCard.label":
    "Pri exspirácii predplatného sa N dní pred exspiráciou zašle notifikácia používateľovi (počet dní je možné nastaviť)",
  "notificationsPage.cancelSubscriptionCard.label":
    "Pri ukončení predplatného sa N dní pred ukončením zašle notifikácia používateľovi (počet dní je možné nastaviť)",
  "notificationsPage.expirationPaymentCard.label":
    "Pri exspirácii predplatného sa N minút pred exspiráciou zašle notifikácia používateľovi (počet minút je možné nastaviť)",
  "notificationsPage.expirationOrderCard.label":
    "Pri exspirácii objednávky sa N dní pred ukončením zašle notifikácia používateľovi (počet dní je možné nastaviť)",
  "notificationsPage.successfulSubsRenewalCard.label":
    "Pri obnove predplatného, po úspešnom obnovení",
  "notificationsPage.unsuccessfulSubsRenewalCard.label":
    "Pri obnove predplatného po neúspešnom obnovení",
  "notificationsPage.printedPhotoCard.label":
    "Pri úspešne vytlačenej prvej objednávke za kredity (nie FreeFoto), oneskorenie notifikácie je možné nastaviť",
  "notificationsPage.orderPaymentCard.label":
    "Pri uskutočnení doplatku za objednávku alebo celej platby za objednávku",
  "notificationsPage.orderLockCard.label":
    "N minút pred uzamknutím objednávky sa zašle notifikácia používateľovi (počet minút je možné nastaviť)",

  "notificationsPage.expirationSubscriptionCard.days":
    "Počet dní pred exspiráciou predplatného",
  "notificationsPage.cancelSubscriptionCard.days":
    "Počet dní pred ukončením predplatného",
  "notificationsPage.expirationPaymentCard.minutes":
    "Počet minút pred exspiráciou platby",
  "notificationsPage.orderLockCard.minutes":
    "Počet minút pred uzamknutím objednávky",
  "notificationsPage.expirationOrderCard.days":
    "Počet dní pred exspiráciou objednávky",
  "notificationsPage.printedPhotoCard.delay":
    "Oneskorenie notifikácie (Počet minút)",

  "notificationsPage.delete.success": "Naplánovaná notifikácia bola zrušená",
  "notificationsPage.auto.update.success":
    "Zmeny v automatickej notifikácii boli uložené",
  "notificationsPage.modal.scheduled": "Hneď po stlačení tlačidla `ODOSLAŤ`",
  "notificationsPage.calculate.nobody": "Nikto",
  "notificationsPage.calculate.all": "Včetci",
  "notificationsPage.new.button.calculateDisabled": "Oslovení používatelia",
  "notificationsPage.new.basicInfoCard.firstNamesHelper":
    "Zadajte krstné meno a potvrďte stlačenim tlačidla `enter`",
  "notificationsPage.new.basicInfoCard.emailLabel":
    "Zadajte alebo skopírujte emailové adresy a stlačte `enter`",

  "notificationsPage.stepperLabel.filters": "Nastavenie filtrov",
  "notificationsPage.stepperLabel.notifications": "Vytvorenie notifikácie",
  "notificationsPage.stepperLabel.confirm": "Potvrdenie notifikácie",
  "notificationsPage.stepperTitle.filters":
    "Nastavte filtre, pre lepšie zacielenie notifikácie",
  "notificationsPage.stepperTitle.notifications": "Vytvorte obsah notifikácie",
  "notificationsPage.stepperTitle.confirm":
    "Skontrolujte a odošlite notifikáciu",
  "notificationsPage.stepperTitle.title": "Nová notifikácia",
  "notificationsPage.stepperTitle.continueButton": "Pokračovať",

  "notificationsPage.table.history.title": "História notifikácií",
  "notificationsPage.table.sending.title": "Zoznam notifikácií na odoslanie",
  "notificationsPage.table.sendDate": "Odoslaná",
  "notificationsPage.table.sendDateWill": "Bude odoslaná",
};

const management = {
  "management.title": "Témy a kategórie",
  "management.topics.table.title":
    "Zoznam tém, použitých pri vytváraní obsahu kampane",
  "management.segments.table.title":
    "Zoznam kategórií, definujúcich typ prevádzky",
  "management.table.name": "Názov",
  "management.table.actions": "Akcie",
  "management.table.actions.update": "Upraviť",
  "management.table.actions.remove": "Odstrániť",
  "management.topics.buttons.add": "Pridať tému",
  "management.segments.buttons.add": "Pridať kategóriu",
  "management.topics.createModal.title": "Vytvorenie novej témy",
  "management.segments.createModal.title": "Vytvorenie novej kategórie",
  "management.topics.updateModal.title": "Zmena názvu témy",
  "management.segments.updateModal.title": "Zmena názvu kategórie",
  "management.topics.removeModal.title": "Naozaj chcete odstrániť tému?",
  "management.segments.removeModal.title": "Naozaj chcete odstrániť kategóriu?",
  "management.topics.input.name": "Názov témy",
  "management.segments.input.name": "Názov kategórie",
  "management.modalButton.create": "Vytvoriť",
  "management.modalButton.update": "Zmeniť",
  "management.modalButton.remove": "Odstrániť",
  "management.modalButton.cancel": "Späť",
  "management.topics.snackbar.createSuccessfull": "Téma bola vytvorená",
  "management.topics.snackbar.updateSuccessfull": "Názov témy bol upravený",
  "management.topics.snackbar.deleteSuccessfull": "Téma bola odstranená",
  "management.segments.snackbar.createSuccessfull": "Kategória bola vytvorená",
  "management.segments.snackbar.updateSuccessfull":
    "Názov kategórie bol upravený",
  "management.segments.snackbar.deleteSuccessfull": "Kategória bola odstranená",
};

const screenTypeEnums = {
  "screenType.Home": "Domov",
  "screenType.Menu": "Menu",
  "screenType.Orders": "Objednávky",
  "screenType.Profile": "Profil",
  "screenType.Credits": "Kredity",
  "screenType.Fotomats": "Fotomaty",
};

const userStatuses = {
  "userStatus.activated": "Aktivované",
  "userStatus.unactivated": "Neaktivované",
  "userStatus.blocked": "Blokované",
  "userSubscription.Nothing": "bez predplatného",
};

const pageHeader = {
  "pageHeader.back": "Späť",
};

const tableTexts = {
  "tableTexts.rowsPerPage": "Počet záznamov",
  "tableTexts.of": "z",
  "tableTexts.moreThan": "viac ako",
  "tableTexts.piece": " ks",
};

const userDevicesPage = {
  "userDevicesPage.title": "Fotomaty používateľa {0}",
};

const weekDay = {
  "weekDay.Monday": "Pondelok",
  "weekDay.Tuesday": "Utorok",
  "weekDay.Wednesday": "Streda",
  "weekDay.Thursday": "Štvrtok",
  "weekDay.Friday": "Piatok",
  "weekDay.Saturday": "Sobota",
  "weekDay.Sunday": "Nedeľa",
};

export default {
  ...apiErrors,
  ...userRoles,
  ...dashboard,
  ...defaultValidationErrors,
  ...settingsPage,
  ...userDetail,
  ...routes,
  ...usersPage,
  ...devices,
  ...invoices,
  ...media,
  ...orders,
  ...payouts,
  ...operations,
  ...payments,
  ...companies,
  ...stores,
  ...campaigns,
  ...commissions,
  ...profilePage,
  ...createUserPage,
  ...deviceStatus,
  ...queryTable,
  ...table,
  ...loginPage,
  ...notificationsPage,
  ...management,
  ...screenTypeEnums,
  ...userStatuses,
  ...pageHeader,
  ...tableTexts,
  ...userDevicesPage,
  ...weekDay,
};
