import React, { useState } from "react";
import { MentionsInput, Mention } from "react-mentions";
import Linkify from "./Linkify";
import { injectIntl } from 'react-intl';
//import "./styles.css";

const users = [
  {
    id: "1",
    display: "@firstName"
  },
  {
    id: "2",
    display: "@lastName"
  },
  {
    id: "3",
    display: "@balance"
  }
];

const TextPlaceholder = (props) => {
  const { callback } = props;

  const [state, setState] = useState({
    comments: [],
    comment: ""
  })


  const handleCommentChange = (e) => {
    setState({
      comment: e.target.value
    });
  };

  const { comments, comment } = state;
  callback(comment)

  return (
    <div >
      {comments?.map((comment) => (
        <Linkify>{comment}</Linkify>
      ))}

      <hr />
      <MentionsInput
       className={"input " + (state.error && " has-error")}
        markup="@[__display__](__id__)"
        value={comment}
        onChange={handleCommentChange}
      >
        <Mention
         trigger="@" data={users} />
      </MentionsInput>
    </div>
  );
}


export default injectIntl(TextPlaceholder);