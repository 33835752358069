import gql from 'graphql-tag';

const COUPONS_SEARCH = gql`
query couponsSearch($query: String!) {
    couponsSearch (query: $query) {
        coupon {
          id
          bid
          type
          value
        }
    set {
      id
      bid
    }
    campaign {
      id
      bid
    }
    redeemer {
      id
      bid
      appliedAt
    }

    }
}
`;

export default COUPONS_SEARCH;

