import Business from "@material-ui/icons/Business";
import RoleEnum from "common/enums/RoleEnum";
import IconCard from "components-lib/IconCard/IconCard";
import PageHeader from "components-lib/PageHeader/PageHeader";
import { isInRoles } from "helpers/helpers";
import paths from "paths";
import React from "react";
import { injectIntl } from "react-intl";
import { generatePath } from "react-router-dom";
import CompanyTable from "./CompanyTable";
import ALL_COMPANIES from 'queries/CompaniesQueries/allCompanies';
import { useLocation } from 'react-router-dom';

const ComapnyListPage = (props) => {
  const { intl, history } = props;
  let location = useLocation();
  let defaultFilter = location.state ? location.state : null

  const getActions = () => {
    if (!isInRoles([RoleEnum.ADMIN, RoleEnum.MAINTAINER])) {
      return;
    }
    return [
      {
        title: intl.formatMessage({ id: "companyList.addModal.title" }),
        onClick: () =>
          history.push(`/admin${generatePath(paths.companies.new)}`),
      },
    ];
  };

  const renderTables = () => {
    return (
      <div>
        <CompanyTable
          query={ALL_COMPANIES}
          defaultFilter={defaultFilter}
          history={history}
        />
      </div>
    );
  };
  return (
    <>
      <PageHeader
        title={intl.formatMessage({ id: "companyList.title" })}
        actions={getActions()}
        withBackButton={false}
      />
      <IconCard
        icon={<Business />}
        title={intl.formatMessage({ id: "companyList.table.title" })}
      >
        {renderTables()}
      </IconCard>
    </>
  );
};
export default injectIntl(ComapnyListPage);