import { Grid } from "@material-ui/core";
import RoomIcon from '@material-ui/icons/Room';
import IconCard from "components-lib/IconCard/IconCard";
import React from "react";
import { injectIntl } from "react-intl";
import { withRouter } from "react-router-dom";
import FormikField from "components-lib/FormikField/FormikField";
import AutocompleteSelect from "components-lib/AutocompleteSelect/AutocompleteSelect";
import { useQuery } from "@apollo/react-hooks";
import ALL_VILLAGES from "queries/StoresQueries/allVillages";
import { pathOr } from "rambda";
import Input from "@material-ui/core/Input";

const NewAddressCard = (props) => {
  const { intl, formikProps, callbackCity } = props;

  const { data: villagesData } = useQuery(ALL_VILLAGES)

  const getVillagesOptions = () => {
    const villageOptions = [];
    pathOr([], ['villages', 'items'], villagesData).map(village => {
      villageOptions.push({
        label: village.name,
        value: village.id
      })
      return "";
    })
    return villageOptions
  }
  
  return (
    <>
      <IconCard
        title={intl.formatMessage({ id: 'storeDetail.storeAddressCard.title' })}
        icon={<RoomIcon />}
      >
        <Grid container>
          <Grid item sm={12}>
            <FormikField
              name="address"
              labelText={intl.formatMessage({ id: 'storeDetail.storeAddressCard.address' })}
              formikProps={formikProps}
              highlightChange
            />
          </Grid>
          <Grid item sm={12}>
            <FormikField
              name="postal"
              labelText={intl.formatMessage({ id: 'storeDetail.storeAddressCard.postal' })}
              formikProps={formikProps}
              highlightChange
            />
          </Grid>

          <Grid item sm={12}>
            <br />
            <AutocompleteSelect
              placeholder={intl.formatMessage({ id: 'storeDetail.storeAddressCard.cityPlaceholder' })}
              label={intl.formatMessage({ id: "storeDetail.storeAddressCard.city" })}
              options={getVillagesOptions()}
              onChange={(e) => callbackCity(e)}
              input={<Input />}
            />
          </Grid>
          <Grid item sm={12}>
            <FormikField
              name="country"
              labelText={intl.formatMessage({ id: 'storeDetail.storeAddressCard.country' })}
              formikProps={formikProps}
              highlightChange
            />
          </Grid>
          <Grid item sm={12}>
            <FormikField
              name="latitude"
              labelText={intl.formatMessage({ id: "storeDetail.storeAddressCard.latitude" })}
              formikProps={formikProps}
              highlightChange
            />
          </Grid>
          <Grid item sm={12}>
            <FormikField
              name="longtitude"
              labelText={intl.formatMessage({ id: "storeDetail.storeAddressCard.longtitude" })}
              formikProps={formikProps}
              highlightChange
            />
          </Grid>
        </Grid>
      </IconCard>
    </>
  );
};

export default withRouter(injectIntl(NewAddressCard));
