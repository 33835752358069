import gql from "graphql-tag";

const ALL_USERS = gql`
  query usersTable(
    $offset: Int
    $limit: Int
    $sort: [UsersTableSortInput!]
    $filter: [UsersTableFilterInput]
  ) {
    usersTable(offset: $offset, limit: $limit, sort: $sort, filter: $filter) {
      totalCount
      items {
        bid
        id
        fullName
        email
        status
        role
        subscription
        subscriptionStatus
        balance
        companies
        ambassador
      }
    }
  }
`;

export default ALL_USERS;
