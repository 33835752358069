import React from 'react';
import { Grid } from '@material-ui/core';
import EuroSymbolIcon from '@material-ui/icons/EuroSymbol';
import { injectIntl } from 'react-intl';
import ALL_COMMISSION_STORES from 'queries/CommissionsQueries/allCommissionsStores';
import { useQuery } from '@apollo/react-hooks';
import { pathOr } from 'rambda';
import IconCard from "components-lib/IconCard/IconCard";
import {
  BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip,
  Legend, ResponsiveContainer
} from 'recharts';
import { chartColors } from "common/styles/configLayout.js";

const DetailCommissionCard = (props) => {
  const { storeBid, intl } = props;

  const { data: commissionsData } = useQuery(ALL_COMMISSION_STORES, {
    variables: {
      filter: {
        storeBid: {
          eq: storeBid
        },
        interval: {
          eq: "Monthly"
        }
      }
    }
  });

  const commissions = pathOr([], ['zoneCommissionsTable', 'items'], commissionsData);
  const totalCommissions = commissions.reduce((sum, commission) => sum + commission.value, 0);

  const getMonth = (month) => {
    const helpMonth = (month < 1) ? month + 12 : month;
    switch (helpMonth) {
      case 1: return intl.formatMessage({ id: 'commissionsList.month.january' });
      case 2: return intl.formatMessage({ id: 'commissionsList.month.february' });
      case 3: return intl.formatMessage({ id: 'commissionsList.month.march' });
      case 4: return intl.formatMessage({ id: 'commissionsList.month.april' });
      case 5: return intl.formatMessage({ id: 'commissionsList.month.may' });
      case 6: return intl.formatMessage({ id: 'commissionsList.month.june' });
      case 7: return intl.formatMessage({ id: 'commissionsList.month.july' });
      case 8: return intl.formatMessage({ id: 'commissionsList.month.august' });
      case 9: return intl.formatMessage({ id: 'commissionsList.month.september' });
      case 10: return intl.formatMessage({ id: 'commissionsList.month.october' });
      case 11: return intl.formatMessage({ id: 'commissionsList.month.november' });
      case 12: return intl.formatMessage({ id: 'commissionsList.month.december' });
      default: return month.toString();
    }
  };

  const renderChartData = () => {
    const chartData = [];
    const actualMonth = new Date().getMonth() + 1;
    const actualYear = new Date().getFullYear();
    for (let i = 0; i < 4; i++) {
      const month = actualMonth - i;
      const commission = commissions.find((item) => {
        const commissionMonth = new Date(item.period).getMonth() + 1;
        const commissionYear = new Date(item.period).getFullYear();
        return commissionYear === actualYear && commissionMonth === month;
      });
      const value = commission ? parseFloat(commission.value).toFixed(2) : "0";
      const monthName = getMonth(month);
      chartData.unshift({ name: monthName, com: value });
    }
    return chartData;
  };

  return (
    <IconCard
      title={intl.formatMessage({ id: "storeDetail.commissionCard.title" })}
      icon={<EuroSymbolIcon />}
    >
      <ResponsiveContainer width="100%" height={310}>
        <BarChart
          data={renderChartData()}
          margin={{ top: 5, left: -15, right: 10, bottom: 15 }}
        >
          <CartesianGrid stroke="#f5f5f5" />
          <XAxis dataKey="name" />
          <YAxis type="number" scale="auto" />
          <Tooltip formatter={(label) => label + " C"} />
          <Bar name={intl.formatMessage({ id: "storeDetail.commissionCard.com" })} dataKey="com" barSize={30} fill={chartColors.one} />
          <Legend wrapperStyle={{ bottom: -20 }} />
        </BarChart>
      </ResponsiveContainer>
      <Grid container spacing={2}>
        <Grid item sm={12}>
          {/* Render your provisions table */}
        </Grid>
      </Grid>
      <hr style={{ marginTop: 61 }} />
      <Grid container>
        <Grid item sm={12}>
          <b>{intl.formatMessage({ id: "storeDetail.commissionCard.allCommissions" })}: {totalCommissions.toFixed(2)} &euro;</b>
        </Grid>
      </Grid>
    </IconCard>
  );
};

export default injectIntl(DetailCommissionCard);
