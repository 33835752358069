import { useMutation } from "@apollo/react-hooks";
import { Grid } from "@material-ui/core";
import PageHeader from "components-lib/PageHeader/PageHeader";
import Button from "components/CustomButtons/Button.js";
import { Formik } from "formik";
import { useSnackbar } from "notistack";
import { pathOr } from "rambda";
import React from "react";
import { injectIntl } from "react-intl";
import { generatePath, withRouter } from "react-router-dom";
import paths from "paths";
import AddressCard from "./CompanyCards/AddressCard";
import BillingInfoCard from "./CompanyCards/BillingInfoCard";
import ContactPersonCard from "./CompanyCards/ContactPersonCard";
import COMPANY_CREATE from "queries/CompaniesQueries/companyCreate";
import { getValidationSchema } from "./validationSchema";

const CompanyNewPage = (props) => {
    const { history, intl } = props;

    let userData = ({ contactEmail: "@" });
    const { enqueueSnackbar } = useSnackbar();
    const [createCompany] = useMutation(COMPANY_CREATE);

    const handleCreateCompany = (values) => {
        createCompany({
            variables: {
                input: values,
            },
        }).then((response) => {
            const companyName = pathOr("", ["data", "companyCreate", "name"], response);
            const id = pathOr("", ["data", "companyCreate", "id"], response);
            enqueueSnackbar(`Spoločnosť ${companyName} bola vytvorená`, { variant: 'success' });
            history.push(`/admin${generatePath(paths.companies.detail, { companyId: id })}`);
        })
            .catch(err => {
                console.log('[error]', err);
            });
    };

    return (
        <>
            <PageHeader
                title={intl.formatMessage({ id: "companyList.addCompany.title" })}
                handleBackAction={(e) => history.goBack()}
            />
            <Formik
                initialValues={userData}
                onSubmit={(values) => { handleCreateCompany(values); }}
                validationSchema={getValidationSchema(intl)}
            >
                {(formikProps) => (
                    <>
                        <Grid container>
                            <Grid item lg={4} >
                                <AddressCard
                                    data={formikProps.values}
                                    formikProps={formikProps}
                                />
                                <ContactPersonCard
                                    data={formikProps.values}
                                    formikProps={formikProps}
                                />
                                <BillingInfoCard
                                    data={formikProps.values}
                                    formikProps={formikProps}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Button
                                    color="primary"
                                    onClick={(e) => formikProps.handleSubmit()}
                                >
                                    {intl.formatMessage({ id: "createUser.submit" })}
                                </Button>
                                <Button
                                    onClick={() => history.push(`/admin${paths.operations.list}`)}
                                >
                                    {intl.formatMessage({ id: "createUser.cancel" })}
                                </Button>
                            </Grid>
                        </Grid>
                    </>
                )}
            </Formik>
        </>
    );
};

export default withRouter(injectIntl(CompanyNewPage));
