import { Grid } from "@material-ui/core";
import FormikField from "components-lib/FormikField/FormikField";
import IconCard from "components-lib/IconCard/IconCard";
import { injectIntl } from "react-intl";
import { withRouter } from "react-router-dom";
import RoleEnum from "common/enums/RoleEnum";
import { isInRoles } from "helpers/helpers";
import styles from "common/styles/widgets.js";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { pathOr } from 'rambda';
import FFCampaignsIcon from 'components-lib/Icons/FFCampaignsIcon/FFCampaignsIcon';
import { ALL_TOPICS } from "queries/CampaignsQueries/allTopics";
import FormControl from "@material-ui/core/FormControl";
import AutocompleteSelect from "components-lib/AutocompleteSelect/AutocompleteSelect";
import Input from "@material-ui/core/Input";

const useStyles = makeStyles((theme) => styles(theme));

const StoreDataCard = (props) => {
    const { intl,
        formikProps,
        forbidden,
        callbackForbidden,
    } = props;
    const classes = useStyles();

    const { data: allTopics } = useQuery(ALL_TOPICS)

    const [availableTopics, setAvailableTopics] = useState([])


    useEffect(() => {
        let dd = pathOr([], ['topics', 'items'], allTopics).map((topic) => {
            return {
                value: topic.id,
                label: topic.name
            }
        })
        setAvailableTopics(dd);
    }, [allTopics])

    const renderContent = () => {
        if (!isInRoles([RoleEnum.ADMIN, RoleEnum.MAINTAINER])) {
            return (
                <Grid container spacing={2}>
                    <Grid item sm={12}>
                        {intl.formatMessage({ id: 'storeDetail.mediaCard.forbiddenTopics' })}
                        <Box className={classes.floatRight}>
                            {forbidden?.map((item, idx) => (
                                <>{item.label}, </>
                            ))}
                        </Box>

                    </Grid>
                    <Grid container>
                        <Grid item sm={12}>
                            {intl.formatMessage({ id: 'storeDetail.storeCard.isOnlyOwnCampaignAllowed' })}
                            <Box className={classes.floatRight}>
                                {formikProps?.values?.zones?.[0]?.preferences?.isOnlyOwnCampaignAllowed
                                    ? <><label>{intl.formatMessage({ id: 'storeDetail.storeCard.isOnlyOwnCampaignAllowed.on' })}</label></>
                                    : <><label>{intl.formatMessage({ id: 'storeDetail.storeCard.isOnlyOwnCampaignAllowed.off' })}</label></>
                                }

                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            )
        }
        return (<Grid container spacing={1}>
            <Grid item xs={12}>
                <FormikField
                    name="zones.0.preferences.freePhotoLimit"
                    labelText="Max. počet FreeFoto na používateľa na jeden deň"
                    formikProps={formikProps}
                    highlightChange
                />
            </Grid>
            <Grid item xs={12}>
                <FormikField
                    name="zones.0.preferences.isOnlyOwnCampaignAllowed"
                    labelText={intl.formatMessage({ id: 'storeDetail.storeCard.isOnlyOwnCampaignAllowed' })}
                    checkedLabelText={intl.formatMessage({ id: 'storeDetail.storeCard.isOnlyOwnCampaignAllowed.on' })}
                    uncheckedLabelText={intl.formatMessage({ id: 'storeDetail.storeCard.isOnlyOwnCampaignAllowed.off' })}
                    formikProps={formikProps}
                    type="switch"
                    highlightChange
                />
            </Grid>
            <Grid item xs={12}>
                <FormControl fullWidth style={{ marginBottom: "15px" }}>
                    {intl.formatMessage({ id: 'storeDetail.mediaCard.forbiddenTopics' })}
                    <AutocompleteSelect
                        style={{ marginBottom: "15px" }}
                        placeholder={intl.formatMessage({ id: 'storeDetail.mediaCard.chooseForbiddenTopics' })}
                        isMulti
                        options={availableTopics}
                        value={forbidden}
                        onChange={(e) => callbackForbidden(e)}
                        input={<Input />}
                    />
                </FormControl>
            </Grid>
        </Grid>
        )
    }

    return (
        <>
            <IconCard
                title={intl.formatMessage({ id: "storeDetail.campaignCard.title" })}
                icon={<FFCampaignsIcon raw={true} />}
            >
                {renderContent()}
                <div style={{ paddingBottom: '28px' }} />
            </IconCard>
        </>
    );
};

export default withRouter(injectIntl(StoreDataCard));
