import { gql } from "apollo-boost";

const ABOUT = gql`
query about {
    about {
       version
    }
}
`;

export default ABOUT;