import gql from 'graphql-tag';

const ALL_NOTIFICATIONS = gql`
query notificationsTable(
    $offset: Int, 
    $limit: Int, 
    $sort: [NotificationsTableSortInput!], 
    $filter: [NotificationsTableFilterInput]) {
      notificationsTable(offset: $offset, limit: $limit, sort: $sort, filter: $filter) {
          totalCount
          items {
              id
              bid
              title
              text
              issuedAt
              sentAt
              scheduledTo
              author
          }
      }
  }
`;

export default ALL_NOTIFICATIONS;