import { makeStyles } from '@material-ui/core/styles';
import Button from "components/CustomButtons/Button.js";
import React, { useState } from 'react';
import { injectIntl } from 'react-intl';
import styles from "common/styles/widgets.js";
import Modal from 'components-lib/Modal/Modal';
import TOPIC_UPDATE from 'queries/ManagementQueries/topicUpdate';
import TOPIC_DELETE from 'queries/ManagementQueries/topicDelete';
import QueryTable from 'components-lib/QueryTable/QueryTableRefresh';
import { useMutation } from 'react-apollo';
import { useSnackbar } from "notistack";
import FormikField from "components-lib/FormikField/FormikField";
import { Formik } from "formik";
import getValidationSchema from "./validationSchema";

const useStyles = makeStyles((theme) => styles(theme))

export const OrderTable = (props) => {
    const { history, detail, data, loadData, refetch, queryVariables, numberOfRows, intl, defaultFilter, queryDataPath } = props

    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();

    const [updateTopic] = useMutation(TOPIC_UPDATE);
    const [deleteTopic] = useMutation(TOPIC_DELETE);
    const [updateModalOpen, setUpdateModalOpen] = useState(false)
    const [removeModalOpen, setRemoveModalOpen] = useState(false)
    const [topicId, setTopicId] = useState(-1)

    const handleUpdateTopic = (formikProps) => {
        updateTopic({
            variables: {
                id: topicId,
                input: {
                    ...formikProps?.values
                }
            }
        }).then((response) => {
            enqueueSnackbar(intl.formatMessage({ id: 'management.topics.snackbar.updateSuccessfull' }), { variant: 'success' });
            refetch();
            setUpdateModalOpen(false);
        }).catch((err) => {
            console.log('[error]', err);
        })
        formikProps.resetForm();
        setUpdateModalOpen(false);
    }

    const handleRemoveTopic = () => {
        deleteTopic({
            variables: {
                id: topicId,
            }
        }).then((response) => {
            enqueueSnackbar(intl.formatMessage({ id: 'management.topics.snackbar.deleteSuccessfull' }), { variant: 'success' });
            refetch();
            setRemoveModalOpen(false);
        }).catch((err) => {
            console.log('[error]', err);
        })
        setRemoveModalOpen(false);
    }


    const renderUpdateModalButtons = (formikProps) => {
        return (
            <>
                <Button
                    size="sm"
                    color="success"
                    onClick={() => handleUpdateTopic(formikProps)}
                    round
                >
                    {intl.formatMessage({ id: 'management.modalButton.update' })}
                </Button>

                <Button
                    size="sm"
                    color="primary"
                    onClick={() => { setUpdateModalOpen(false); formikProps.resetForm() }}
                    round
                >
                    {intl.formatMessage({ id: 'management.modalButton.cancel' })}
                </Button>
            </>
        );
    };

    const renderRemoveModalButtons = () => {
        return (
            <>
                <Button
                    size="sm"
                    color="danger"
                    onClick={handleRemoveTopic}
                    round
                >
                    {intl.formatMessage({ id: 'management.modalButton.remove' })}
                </Button>

                <Button
                    size="sm"
                    color="primary"
                    onClick={() => setRemoveModalOpen(false)}
                    round
                >
                    {intl.formatMessage({ id: 'management.modalButton.cancel' })}
                </Button>
            </>
        );
    };

    return (
        <>
            <QueryTable
                data={data}
                loadData={loadData}
                queryVariables={queryVariables}
                permanentFilter={defaultFilter}
                numberOfRows={numberOfRows}
                queryDataPath={queryDataPath}
                columns={[
                    {
                        Header: intl.formatMessage({ id: 'management.table.name' }),
                        accessor: 'name',
                        sortKey: 'name',
                        filterKey: 'name',
                        filterOperator: 'contains'
                    },
                    {
                        Header: intl.formatMessage({ id: 'management.table.actions' }),
                        accessor: (rowData) =>
                            <div>
                                <Button
                                    round
                                    size="sm"
                                    color="info"
                                    onClick={() => { setUpdateModalOpen(true); setTopicId(rowData.id) }}
                                >
                                    {intl.formatMessage({ id: 'management.table.actions.update' })}
                                </Button>
                                <Button
                                    round
                                    size="sm"
                                    color="danger"
                                    onClick={() => { setRemoveModalOpen(true); setTopicId(rowData.id) }}
                                >
                                    {intl.formatMessage({ id: 'management.table.actions.remove' })}
                                </Button>
                            </div>
                    }

                ]}
            />
            <Formik
                onSubmit={(values, { resetForm }) => handleUpdateTopic(values, resetForm)}
                initialValues={{}}
                validationSchema={getValidationSchema(intl)}
            >
                {(formikProps) => (
                    <Modal
                        title={intl.formatMessage({ id: 'management.topics.updateModal.title' })}
                        open={updateModalOpen}
                        onClose={() => setUpdateModalOpen(false)}
                        actions={renderUpdateModalButtons(formikProps)}
                        fullWidth
                    >
                        <FormikField
                            name="name"
                            labelText={intl.formatMessage({ id: "management.topics.input.name" })}
                            formikProps={formikProps}
                            highlightChange
                        />

                    </Modal>
                )}
            </Formik>
            <Modal
                title={intl.formatMessage({ id: 'management.topics.removeModal.title' })}
                open={removeModalOpen}
                onClose={() => setRemoveModalOpen(false)}
                actions={renderRemoveModalButtons()}
                fullWidth
            />
        </>
    )
}

export default injectIntl(OrderTable);