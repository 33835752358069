import React, { useState } from 'react';
import { generatePath, withRouter } from "react-router-dom";
import { pathOr } from 'rambda';
import { Grid } from '@material-ui/core';
import Button from "components/CustomButtons/Button.js";
import CompanyData from './UserCards/CompanyData';
import ContactData from './UserCards/ContactData';
import { useMutation } from '@apollo/react-hooks';
import { getRoleEnumBySlug } from 'helpers/helpers';
import { useSnackbar } from 'notistack';
import PageHeader from 'components-lib/PageHeader/PageHeader';
import paths from 'paths';
import { injectIntl } from 'react-intl';
import { Formik } from "formik";
import { getMaintainerValidationSchema } from "./validationSchema";
import USER_CREATE from 'queries/UsersQueries/userCreate';

const CreateUserPage = (props) => {
    const { location, history, intl } = props;
    const { enqueueSnackbar } = useSnackbar();
    const pathName = pathOr('', ['pathname'], location);
    const userType = pathName.split('/')[4];
    let userData = ({ role: getRoleEnumBySlug(userType), email: '@' });

    const [createUser] = useMutation(USER_CREATE);
    const [companiesIds, setCompaniesIds] = useState([])

    const handleCreateUser = (values) => {
        let setCompaniesIds = companiesIds.map(company => company.value);
        createUser({
            variables: {
                input: {
                    ...values,
                    companyIds: setCompaniesIds
                }
            }
        }).then((response) => {
            const firstName = pathOr('', ['data', 'userCreate', 'firstName'], response);
            const lastName = pathOr('', ['data', 'userCreate', 'lastName'], response);
            const id = pathOr('', ['data', 'userCreate', 'id'], response);
            enqueueSnackbar(intl.formatMessage({ id: 'createUser.success' }, [firstName, lastName]), { variant: 'success' });
            history.push(`/admin${generatePath(paths.users.detail, { userId: id })}`)
        }).catch((err) => {
            console.log('[error]', err);
        });
    };

    const getValidationSchemaByRole = () => {
        return getMaintainerValidationSchema(intl);
    };

    return (
        <>
            <PageHeader
                title={`${intl.formatMessage({ id: 'createUser.title' })} - ${intl.formatMessage({ id: `userRoles.${userType}` })}`}
                handleBackAction={(e) => history.goBack()}
            />
            <Formik
                initialValues={userData}
                onSubmit={(values) => { handleCreateUser(values); }}
                validationSchema={getValidationSchemaByRole()}
            >
                {(formikProps) =>
                    <>
                        <Grid container spacing={3}>
                            <Grid item xs={6} md={4}>
                                <ContactData
                                    data={formikProps.values}
                                    formikProps={formikProps}
                                    created={true}
                                />
                                {(userType === 'partner' || userType === "advertiser") &&
                                    <CompanyData
                                        data={formikProps.values}
                                        formikProps={formikProps}
                                        callbackCompanies={(newValue) => setCompaniesIds(newValue)}
                                    />
                                }
                            </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Button
                                    color="primary"
                                    onClick={(e) => formikProps.handleSubmit()}
                                >
                                    {intl.formatMessage({ id: 'createUser.submit' })}
                                </Button>
                                <Button
                                    onClick={(e) => history.goBack()}
                                >
                                    {intl.formatMessage({ id: 'createUser.cancel' })}
                                </Button>
                            </Grid>
                        </Grid>
                    </>
                }
            </Formik>
        </>
    );
};

export default withRouter(injectIntl(CreateUserPage));