import gql from 'graphql-tag';

const ALL_CAMPAIGNS = gql`
query campaignsTable(
  $offset: Int, 
  $limit:Int, 
  $sort:[CampaignsTableSortInput!],
  $filter:[CampaignsTableFilterInput]
) {
campaignsTable(
  offset: $offset, limit:$limit, sort: $sort, filter: $filter
  ) {
      totalCount,
      items {
          id
          bid
          name
          acceptanceStatus
          owner
          type
          status
          validFrom
          validTo
          maxBid
          budget
          stores
      }
  }
}
`;


export default ALL_CAMPAIGNS;