import gql from "graphql-tag";

const ALL_USERS_FOR_TRANSFER = gql`
  query users(
    $offset: Int
    $limit: Int
    $sort: [UserSortInput!]
    $filter: [UserFilterInput]
  ) {
    users(offset: $offset, limit: $limit, sort: $sort, filter: $filter) {
      totalCount
      items {
        id
        fullName
        email
      }
    }
  }
`;

export default ALL_USERS_FOR_TRANSFER;
