import React from "react";
import { Grid } from "@material-ui/core";
import { injectIntl } from "react-intl";
import PersonIcon from "@material-ui/icons/Person";
import IconCard from "components-lib/IconCard/IconCard";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
} from "recharts";
import { chartColors } from "common/styles/configLayout.js";
import moment from "moment";
import CropFreeIcon from "@material-ui/icons/CropFree";

const AmbassadorsScansChart = (props) => {
  const { intl, allScans } = props;

  let data = [];
  let sumUsers = 0;
  for (let i = 0; i < allScans?.length; i++) {
    let helpDate = moment(allScans[i]?.date).format("DD.MM.YYYY");
    let date = helpDate;
    let scans = allScans[i]?.count;

    data[i] = {
      name: date,
      scans: scans,
    };
    sumUsers += data[i].scans;
  }

  const legendData = [
    {
      id: 1,
      name: intl.formatMessage({ id: "dashboard.ambassadorsScansChart.first" }),
      color: chartColors.five,
    },
  ];

  return (
    <>
      <IconCard
        title={intl.formatMessage({
          id: "dashboard.ambassadorsScansChart.title",
        })}
        icon={<CropFreeIcon />}
      >
        <ResponsiveContainer width="100%" height={200}>
          <BarChart
            data={data}
            margin={{ top: 5, left: -25, right: 10, bottom: 15 }}
          >
            <CartesianGrid stroke="#f5f5f5" />
            <XAxis dataKey="name" />
            <YAxis allowDecimals={false} />
            <Tooltip />
            <Bar
              name={intl.formatMessage({
                id: "dashboard.ambassadorsScansChart.first",
              })}
              dataKey="scans"
              stackId="a"
              barSize={25}
              fill={chartColors.five}
            />
            <Legend
              wrapperStyle={{ bottom: -20 }}
              payload={legendData.map((item) => ({
                id: item.id,
                type: "square",
                color: item.color,
                value: item.name,
              }))}
            />
          </BarChart>
        </ResponsiveContainer>
        <br />
        <hr />
        <Grid container>
          <Grid item sm={12}>
            <b>
              {intl.formatMessage({
                id: "dashboard.printedPhotosCard.allPhotos",
              })}
              : {sumUsers}
            </b>
          </Grid>
        </Grid>
      </IconCard>
    </>
  );
};

export default injectIntl(AmbassadorsScansChart);
