import { Grid } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import CameraEnhanceIcon from '@material-ui/icons/CameraEnhance';
import IconCard from "components-lib/IconCard/IconCard";
import React from "react";
import { injectIntl } from "react-intl";
import styles from "common/styles/widgets.js"
import WarningIcon from '@material-ui/icons/Warning';

const useStyles = makeStyles((theme) => styles(theme));

const CampaignsCard = (props) => {
    const {
        intl,
        storeDevices,
        stockDevices,
        allDevices,
        offlineDevices,
        emptyDevices
    } = props;
    const classes = useStyles();

    return <>
        <IconCard
            title={intl.formatMessage({ id: 'dashboard.devicesCard.title' })}
            icon={<CameraEnhanceIcon />}
        >
            <Grid container spacing={1}>
                <Grid item sm={12}>
                    {intl.formatMessage({ id: 'dashboard.devicesCard.storeDevices' })}
                    <Box className={classes.floatRight}>
                        {storeDevices}
                    </Box>
                </Grid>
                <Grid item sm={12}>
                    {intl.formatMessage({ id: 'dashboard.devicesCard.stockDevices' })}
                    <Box className={classes.floatRight}>
                        {stockDevices}
                    </Box>
                </Grid>
                <Grid item sm={12}>
                    <hr style={{ marginTop: "5px", marginBottom: "5px" }} />
                    {intl.formatMessage({ id: 'dashboard.devicesCard.allDevices' })}
                    <Box className={classes.floatRight}>
                        {allDevices}
                    </Box>
                    <hr style={{ marginTop: "5px", marginBottom: "5px" }} />
                </Grid>
                {(offlineDevices > 0) ?
                    <Grid item sm={12} style={{ color: '#E30404' }}>
                        {intl.formatMessage({ id: 'dashboard.devicesCard.offlineDevices' })}
                        <Box className={classes.floatRight} >
                            <WarningIcon className={classes.warningIcon} /><span>{offlineDevices}</span>
                        </Box>
                    </Grid>
                    :
                    <Grid item sm={12}>
                        {intl.formatMessage({ id: 'dashboard.devicesCard.offlineDevices' })}
                        <Box className={classes.floatRight}>
                            {offlineDevices}
                        </Box>
                    </Grid>
                }
                {(emptyDevices > 0) ?
                    <Grid item sm={12} style={{ color: '#E30404' }}>
                        {intl.formatMessage({ id: 'dashboard.devicesCard.emptyDevicesFull' })}
                        <Box className={classes.floatRight} >
                            <WarningIcon className={classes.warningIcon} /><span>{emptyDevices}</span>
                        </Box>
                    </Grid>
                    :
                    <Grid item sm={12}>
                        {intl.formatMessage({ id: 'dashboard.devicesCard.emptyDevicesFull' })}
                        <Box className={classes.floatRight}>
                            {emptyDevices}
                        </Box>
                    </Grid>
                }
            </Grid>
        </IconCard>
    </>
};

export default injectIntl(CampaignsCard);
