import { gql } from "apollo-boost";

const UPDATE_SETTINGS = gql`
    mutation settingsUpdate ($input: SettingsUpdateInput!) {
        settingsUpdate (input:$input) {
            credit {
                value
            }
        }
  }
`;

export default UPDATE_SETTINGS;