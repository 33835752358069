import gql from "graphql-tag";

const DEVICE_ISSUES_TABLE_BY_DEVICE = gql`
  query deviceIssuesTableByDevice(
    $deviceId: ID!
    $offset: Int
    $limit: Int
    $sort: [DeviceIssuesTableSortInput!]
    $filter: [DeviceIssuesTableFilterInput!]
  ) {
    deviceIssuesTableByDevice(
      deviceId: $deviceId
      offset: $offset
      limit: $limit
      sort: $sort
      filter: $filter
    ) {
      totalCount
      items {
        id
        date
        code
        description
        deviceId
      }
    }
  }
`;

export default DEVICE_ISSUES_TABLE_BY_DEVICE;
