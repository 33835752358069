import { Grid } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import EuroIcon from '@material-ui/icons/Euro';
import IconCard from "components-lib/IconCard/IconCard";
import React from "react";
import { injectIntl } from "react-intl";
import styles from "common/styles/widgets.js";
import FormikField from "components-lib/FormikField/FormikField";
import COMPANY_DETAIL from 'queries/CompaniesQueries/companyDetail';
import { useQuery } from "@apollo/react-hooks";
import { pathOr } from 'rambda';
import CampaignTypeEnum from "common/enums/CampaignType";

const useStyles = makeStyles((theme) => styles(theme));

const BudgetCard = (props) => {
    const {
        intl,
        companyId,
        formikProps,
        campaignType,
        callbackBalance,
    } = props;
    const classes = useStyles();
    let helpCompId = (companyId) ? companyId : "1"
    const { data: companyData } = useQuery(COMPANY_DETAIL, { variables: { id: helpCompId } });

    let balance = pathOr('', ['company', 'account', 'balance'], companyData)
    let priceForPrint = 1
    let printedPhotos = balance * priceForPrint

    callbackBalance(balance)
    const getRemains = (cred) => {
        let final = (cred)
            ? Number(balance) - Number(cred)
            : balance
        return <>
            {(final >= 0)
                ? <span style={{ color: "black" }}>{intl.formatMessage({ id: 'campaigns.budgetCard.balanceOfCredits' })} {final} C</span>
                : <span style={{ color: "red" }}>
                    {intl.formatMessage({ id: 'campaigns.budgetCard.lackOfCredits' })}
                </span>}
        </>
    }


    return <>
        { (companyId) &&
            <IconCard
                title={intl.formatMessage({ id: 'campaigns.budgetCard.title' })}
                icon={<EuroIcon />}
            >
                {campaignType === CampaignTypeEnum.FREEPHOTO ?
                    <>
                        <Grid container>
                            <Grid item sm={12}>
                                <FormikField
                                    name="campaign.budget"
                                    labelText={
                                        <p>
                                            {intl.formatMessage({ id: 'campaigns.budgetCard.freeFotoBudget' })}
                                            {" (Max. " + printedPhotos + intl.formatMessage({ id: 'tableTexts.piece' }) + ")"}
                                        </p>
                                    }
                                    formikProps={formikProps}
                                    data={formikProps.values}
                                    highlightChange
                                />
                            </Grid>

                            <Grid item sm={12}>
                                <br />
                                {getRemains(formikProps.values.campaign.budget)}
                                <Box className={classes.floatRight}>
                                    {(formikProps.values.campaign.budget <= printedPhotos) ?
                                        <p>{(formikProps.values.campaign.budget) ? formikProps.values.campaign.budget : 0} C</p>
                                        :
                                        <p style={{ color: "red" }}>
                                            {(formikProps.values.campaign.budget) ? formikProps.values.campaign.budget : 0} C
                                        </p>
                                    }
                                </Box>
                            </Grid>
                        </Grid>

                        <Grid item sm={12}>
                            <hr />
                            {intl.formatMessage({ id: 'campaigns.budgetCard.account' })} {balance} C
                            <Box className={classes.floatRight}>
                                {intl.formatMessage({ id: 'campaigns.budgetCard.maxPhotos' })} {printedPhotos}{intl.formatMessage({ id: 'tableTexts.piece' })}
                            </Box>
                        </Grid>
                    </>
                    :
                    <>
                        <Grid container>
                            <Grid item sm={12}>
                                <FormikField
                                    name="campaign.budget"
                                    labelText={intl.formatMessage({ id: 'campaigns.budgetCard.qrBudget' })}
                                    formikProps={formikProps}
                                    data={formikProps.values}
                                    highlightChange
                                />
                            </Grid>
                            <Grid item sm={12}>
                                {getRemains(formikProps.values.campaign.budget)}
                            </Grid>
                        </Grid>

                        <Grid item sm={12}>
                            <hr />
                            {intl.formatMessage({ id: 'campaigns.budgetCard.account' })} {balance} C
                        </Grid>
                    </>
                }
            </IconCard>
        }
    </>
};

export default injectIntl(BudgetCard);
