import gql from 'graphql-tag';

const AUTO_NOTIFICATION_DETAIL = gql`
query automatedNotification($id: ID!) {
    automatedNotification(id: $id) {
              id
      action{
        screen
        params
      }
      isEnabled
      createdAt
      updatedAt
      mutations{
        language
        title
        text
      }
      triggers{
        event
        when
        time
        units
      }
      
    }
  }
`;

export default AUTO_NOTIFICATION_DETAIL;