const defaultValidationErrors = {
  "validation.required": "This field is required",
  "validation.number": "The value must be a number",
  "validation.number.min": "Minimal value for this field is {0}",
  "validation.number.max": "Maximal value for this field is {0}",
  "validation.number.integer": "The value must be an integer",
  "validation.number.positive":
    "The value for this field must be grater than 0",
  "validation.email": "Email address is not valid",
  "validation.phoneNumber": "Required format for phone number is +421999123123",
  "validation.spaces": "Phone number must not contain spaces (+421903123123)",
  "validation.iban": "Invalid IBAN format",
  "validation.password":
    "Password must be at least 8 characters, contain at least one number, at least one lowercase and at least one uppercase",
  "validation.date": "Invalid Date format",
  "validation.graterDate": "End date must be greater than start date",
  "validation.pastDate":
    "The date and time must be higher than the current one",
  "validation.string.max": "Input can be max {0} characters length",
  "validation.string.exact": "This field must contain exactly {0} characters",
};

const queryTable = {
  "queryTable.select.all": "All",
  "queryTable.filter.placeholder": "Filter..",
  "queryTable.filter.dateBetween": "Start - End",
  "queryTable.filter.setDateRange": "Set Date Range: ",
  "queryTable.filter.pickerStart": "Start Date",
  "queryTable.filter.pickerEnd": "End Date",
};

const table = {
  "table.select.types": "selections",
  "table.select.moreTypes": "selections",
  "table.select.NoPlayer": "Without fotomat",
  "table.select.Offline": "Offline",
  "table.select.Online": "Online",
  "table.select.Error": "Error",
  "table.select.Income": "Income",
  "table.select.Outcome": "Outcome",
  "table.select.Transfer": "Transfer",
  "table.select.Succeeded": "Suceeded",
  "table.select.Pending": "Pending",
  "table.select.Activated": "Activated",
  "table.select.Unactivated": "Unactivated",
  "table.select.Blocked": "Blocked",
  "table.select.Money": "Money",
  "table.select.Credit": "Credit",
  "table.select.Inactive": "-",
  "table.select.None": "without",
  "table.select.Family": "Family",
  "table.select.Single": "Single",
  "table.select.Partner": "Partner",
  "table.select.Advertiser": "Advertiser",
  "table.select.User": "User",
  "table.select.Maintainer": "Maintainer",
  "table.select.Admin": "Admin",
  "table.select.Running": "Running",

  "table.select.Awaiting": "Awaiting",
  "table.select.Scheduled": "Scheduled",
  "table.select.Finished": "Finished",
  "table.select.Rejected": "Rejected",
  "table.select.Qr": "QR",
  "table.select.PublicQr": "MQR",
  "table.select.Processing": "Processing",
  "table.select.Processed": "Processed",
  "table.select.Invalid": "Invalid",
  "table.select.Failed": "Failed",

  "table.select.Created": "Created",
  "table.select.Ready": "Waiting for print",
  "table.select.InProgress": "Printing",
  "table.select.Completed": "Completed",
  "table.select.Incomplete": "Incomplete",
  "table.select.Partial": "Partially completed",
  "table.select.Expired": "Expired",
  "table.select.Canceled": "Canceled",

  "table.select.Mixed": "Credit + Payment",
  "table.select.Payment": "Payment",
  "table.select.FreePhoto": "FreeFoto",
};

const apiErrors = {
  "apiErrors.INTERNAL_SERVER_ERROR": "Nastala neočakávana chyba",
  "apiErrors.UnauthorizedUserError":
    "You have been logged out due to inactivity",
  "apiErrors.InvalidRequestError": "Sent data contain invalid values",
  "apiErrors.AccountDoesNotExistsError":
    "Account with provided email does not exists",
  "apiErrors.AccountExistsError": "Account with provided email already exists",
  "apiErrors.PasswordResetPendingError":
    "Account has pending password reset request",
  "apiErrors.PasswordResetNotAllowedError":
    "Requested account does not support password reset",
  "apiErrors.InvalidLoginError": "Provided user email or password is invalid",
  "apiErrors.InvalidLoginMethodError":
    "Account with provided email address does not support used authentication method",
  "apiErrors.UnactivatedAccountError": "Account requires activation",
  "apiErrors.BlockedAccountError":
    "Account has been block by system administrator",
  "apiErrors.EntityDoesNotExistsError": "Requested entity does not exist",
  "apiErrors.DeviceOfflineError": "Requested device is offline",
  "apiErrors.EntityUniqueConstraintError":
    "Device with same name already exists",
  "apiErrors.CannotPerformOnOwnAccountError":
    "This operation cannot be performed on own account",
  "apiErrors.PasswordChangeError": "The current password is incorrect",
  "apiErrors.WeakPasswordError": "Provided current user password is incorrect",
  "apiErrors.SubscriptionNotActivatedError":
    "User dont have activated any subscription",
  "apiErrors.InvalidInputDataError": "Some of the input data is incorrect",
  "apiErrors.UserIsNotAmbassadorError":
    "You have not enabled the ambassador function for the user",
};

const dashboard = {
  "dashboard.title": "Dashboard",
  "dashboard.dropdown.companies": "Companies",
  "dashboard.calculate.success": "Basic statistics have been recalculated",
  "dashboard.allCredits": "Total credit sold",
  "dashboard.allDevices": "Total fotomats",
  "dashboard.allPhotos": "Total photos",
  "dashboard.allUsers": "Total users",
  "dashboard.creditsCard.title": "Credits",
  "dashboard.creditsCard.companyCredits": "Total companies credits",
  "dashboard.creditsCard.userCredits": "Total users credits",
  "dashboard.creditsCard.allCredits": "Total credits",
  "dashboard.devicesCard.title": "Fotomats",
  "dashboard.devicesCard.storeDevices": "Fotomats on stores",
  "dashboard.devicesCard.stockDevices": "Fotomats on stock",
  "dashboard.devicesCard.allDevices": "Total fotomats",
  "dashboard.devicesCard.offlineDevices": "Offline fotomats",
  "dashboard.devicesCard.emptyDevices": "Fotomats low paper",
  "dashboard.devicesCard.emptyDevicesFull": "Fotomats low paper",
  "dashboard.printedPhotosCard.title": "Printed photos (last 30 days)",
  "dashboard.printedPhotosCard.photosOfDay": "Count",
  "dashboard.printedPhotosCard.allPhotos": "Total",
  "dashboard.printedPhotosCard.freeFoto": "FreeFoto",
  "dashboard.printedPhotosCard.first": "10x15",
  "dashboard.printedPhotosCard.second": "15x15",
  "dashboard.printedPhotosCard.third": "20x15",
  "dashboard.usersCard.title": "Registred users (last 30 days)",
  "dashboard.usersCard.first": "Registred",
  "dashboard.ambassadorsScansChart.title":
    "Ambassadors AQR scans (last 30 days)",
  "dashboard.ambassadorsScansChart.first": "Scans",
};

const routes = {
  "routes.dashboard": "Dashboard",
  "routes.profile": "Profile",
  "routes.devices": "Fotomats",
  "routes.devices.detail": "Fotomat detail",
  "routes.campaigns.freeFoto": "FreeFoto Campaign",
  "routes.campaigns.qr": "Coupon Campaign",
  "routes.campaigns.new": "New Campaign",
  "routes.campaigns.detail": "Campaign detail",
  "routes.stores": "Stores",
  "routes.stores.new": "New Store",
  "routes.stores.detail": "Store detail ",
  "routes.notifications": "Notifications",
  "routes.users": "Accounts",
  "routes.users.new": "New user account",
  "routes.users.detail": "User detail",
  "routes.orders": "Orders",
  "routes.orders.detail": "Order detail",
  "routes.companies": "Companies",
  "routes.companies.detail": "Company detail",
  "routes.companies.new": "New company",
  "routes.media": "Media",
  "routes.payments": "Payments",
  "routes.invoices": "Invoices",
  "routes.invoices.detail": "Invoice detail",
  "routes.commissions": "Commissions",
  "routes.commissions.detail": "Commission detail",
  "routes.operations": "Operations",
  "routes.login": "Login",
  "routes.settings": "Options",
  "routes.logout": "Logout",
  "routes.forgottenPassword": "Forgotten Password",
  "routes.loading": "Loading...",
  "routes.management": "Topics and categories",
};

const usersPage = {
  "usersPage.title": "Accounts",
  "usersPage.actions.newPartner": "NEW PARTNER",
  "usersPage.actions.newAdvertiser": "NEW ADVERTISER",
  "usersPage.actions.newMaintainer": "NEW MAINTAINER",
  "usersPage.table.title": "Account list",
  "usersPage.table.id": "ID",
  "usersPage.table.name": "Name",
  "usersPage.table.company": "Company",
  "usersPage.table.email": "Email",
  "usersPage.table.role": "Role",
  "usersPage.table.status": "Status",
  "usersPage.table.credits": "Credits",
  "usersPage.table.status.activated": "ACTIVE",
  "usersPage.table.status.blocked": "BLOCKED",
  "usersPage.table.status.notActive": "INACTIVE",
  "usersPage.table.subscription": "Subscription",
  "usersPage.table.actions": "Actions",
  "usersPage.table.detail": "DETAIL",
};

const createUserPage = {
  "createUser.title": "New account",
  "createUser.submit": "CREATE",
  "createUser.cancel": "CANCEL",
  "createUser.company.title": "Company information",
  "createUser.company.name": "Company name",
  "createUser.company.address": "Address",
  "createUser.company.city": "City",
  "createUser.company.postal": "ZIP code",
  "createUser.company.country": "Country",
  "createUser.company.country.slovakia": "Slovakia",
  "createUser.contact.title": "User",
  "createUser.contact.firstName": "Name",
  "createUser.contact.lastName": "Surname",
  "createUser.contact.birth": "Date of birth",
  "createUser.contact.gender": "Gender",
  "createUser.contact.gender.male": "Male",
  "createUser.contact.gender.female": "Female",
  "createUser.contact.gender.unspecified": "Unspecified ",
  "createUser.contact.email": "Email",
  "createUser.contact.phone": "Phone (Format: +421903123123)",
  "createUser.identificators.title": "Company identification",
  "createUser.identificators.ico": "Business ID",
  "createUser.identificators.dic": "Tax ID",
  "createUser.identificators.icDph": "VAT number",
  "createUser.identificators.reg": "Court of registry",
  "createUser.bank.title": "Bank information",
  "createUser.bank.iban": "IBAN",
  "createUser.bank.bic": "BIC/SWIFT",
  "createUser.bank.name": "Bank",
  "createUser.success": "Account {0} {1} was created",
  "usersPage.table.ambassador": "Ambassador",
  "usersPage.table.ambassador.Money": "Money",
  "usersPage.table.ambassador.Credit": "Credit",
  "usersPage.table.ambassador.Inactive": "-",
};

const settingsPage = {
  "settings.title": "Settings",
  "settings.update.success": "System settings were updated",
  "settings.creditSection.title": "Credit value",
  "settings.creditSection.creditValue": "1 credit value (EUR)",
  "settings.creditSection.registrationAward": "New registration reward (C)",
  "settings.creditSection.paymentFee": "Direct payment fee (%)",
  "settings.timeSection.title": "Timing settings",
  "settings.timeSection.creditExpiration": "QR credit expiration (days)",
  "settings.timeSection.freePhoto": "FreeFoto order expiration (days)",
  "settings.timeSection.orderExpiration": "Credit order expiration (days)",
  "settings.timeSection.freePhotoMonthLimit":
    "Dailz FreeFoto limit (number of photos)",
  "settings.timeSection.unlockDuration": "Automatic order locking (min)",
  "settings.commissionsSection.title": "Partner commission",
  "settings.commissionsSection.creditValue": "Photo value for commission (€)",
  "settings.commissionsSection.lower": "Base commission level (%)",
  "settings.commissionsSection.higher": "High commission level (%)",
  "settings.commissionsSection.treshold":
    "Commission level threshold (Number of photos)",
  "settings.commissionsSection.photosPerMonthThreshold":
    "Minimal photos count per month",
  "settings.signageSection.title": "Digital signage",
  "settings.signageSection.idleTimeout": "Digital signage start timeout (s)",
  "settings.signageSection.bannerDuration":
    "Digital signage banner duration (s)",
  "settings.supportSection.title": "Mobile application",
  "settings.supportSection.email": "Support email",
  "settings.supportSection.subject": "Emailu subject",
  "settings.supportSection.body": "Email body",
  "settings.recepientsSection.title": "Support",
  "settings.recepientsSection.globalNotifcationRecipients": "Emails list",
  "settings.notificationsSection.title": "Notifications",
  "settings.commissionsSection.printsRemainingNTreshold":
    "Consumables replacement notification (remaining photos 10x15)",
  "settings.ordersSection.title": "Price list",
  "settings.ordersSection.size": "Size",
  "settings.save": "SAVE",
  "settings.cancel": "CANCEL",
  "settings.version.title": "System version",
  "settings.version.title": "Version Backend",
  "settings.version.title": "Version Frontend",

  "settings.ambassadorSection.title": "Ambassador settings",
  "settings.ambassadorSection.customerReward": "Customer Reward",
  "settings.ambassadorSection.referralCreditReward": "Referral Credit Reward",
  "settings.ambassadorSection.referralMoneyReward": "Referral Money Reward",
  "settings.ambassadorSection.claimMinutesLimit": "Claim Minutes Limit",

  "settings.rewardSection.title": "Reward",

  "settings.version.backend": "Backend version",
  "settings.version.frontend": "Frontend version",
};

const userDetail = {
  "userDetail.title": "{userName}",
  "userDetail.accountStatus": "Account status",
  "userDetail.ordersCount": "Orders",
  "userDetail.printedPhotosCount": "Printed photos",
  "userDetail.contactInfo": "User",
  "userDetail.contactInfo.create": "Contact information",
  "userDetail.orders": "Orders",
  "userDetail.payments": "Payments",
  "userDetail.operations": "Operations",
  "userDetail.activityHistory": "Activity history",
  "userDetail.activateUser.modalTitle": "Activate account",
  "userDetail.activateUser.modalText":
    "You are going to activate the account. Do you want to continue?",
  "userDetail.activateUser.passwordLabel": "Create account dashboard password",
  "userDetail.activateUser.modalConfirm": "Activate",
  "userDetail.unblockUser.modalTitle": "Unblock account",
  "userDetail.unblockUser.modalText":
    "You are going to unblock the account. Do you want to continue?",
  "userDetail.unblockUser.modalConfirm": "Unblock",
  "userDetail.blockUser.modalTitle": "You realy want to block account?",
  "userDetail.blockUser.modalSubtitle": "Block reason",
  "userDetail.blockUser.modalConfirm": "BLOCK",
  "userDetail.deleteUser.modalTitle": "You realy want to remove account?",
  "userDetail.deleteUser.modalConfirm": "REMOVE",
  "userDetail.headerActions.blockUser": "BLOCK ACCOUNT",
  "userDetail.headerActions.activateUser": "ACTIVATE ACCOUNT",
  "userDetail.headerActions.sendActivateEmail": "RESEND ACTIVATION",
  "userDetail.headerActions.allowUser": "UNBLOCK ACCOUNT",
  "userDetail.headerActions.resetPassword": "RESET PASSWORD",
  "userDetail.headerActions.deleteUser": "REMOVE ACCOUNT",
  "userDetail.accountStatus.paymentList": "PAYMENT LIST",
  "userDetail.contactInfo.firstName": "Name",
  "userDetail.contactInfo.lastName": "Surname",
  "userDetail.contactInfo.gender": "Gender",
  "userDetail.contactInfo.email": "E-mail",
  "userDetail.orders.id": "ID",
  "userDetail.orders.date": "Date",
  "userDetail.orders.size": "Amount",
  "userDetail.orders.actions": "Actions",
  "userDetail.orders.detail": "Detail",
  "userDetail.orders.list": "ORDER LIST",
  "userDetail.transactions.list": "TRANSACTIONS LIST",
  "userDetail.invoice.pair": "PAIR INVOICE",
  "userDetail.accountStatus.title": "Account status",
  "userDetail.accountStatus.registrationService": "Registration service",
  "userDetail.accountStatus.blocation": "Blocked",
  "userDetail.provision": "Account balance",
  "userDetail.processedPhotosCount": "Processed photos",
  "userDetail.photomatsCount": "Fotomats",
  "userDetail.photomatsList": "Fotomat list",
  "userDetail.photomats.id": "ID",
  "userDetail.photomats.status": "Status",
  "userDetail.photomats.hearthbeat": "Heartbeat",
  "userDetail.photomats.actions": "Actions",
  "userDetail.photomats.showList": "FOTOMAT LIST",
  "userDetail.totalSales": "Total revenue",
  "userDetail.transactions": "Transactions",
  "userDetail.campaigns": "Campaigns",
  "userDetail.activeFreePhotoCampaigns": "Active FreeFoto campaigns",
  "userDetail.totalFreePhotoCampaigns": "Total FreeFoto campaigns",
  "userDetail.activeQrCouponsCampains": "Active coupon sets",
  "userDetail.totalQrCouponsCampains": "Total coupon sets",
  "userDetail.buttons.cancel": "CANCEL",
  "userDetail.buttons.confirm": "SAVE",
  "userDetail.resetPassword.success":
    "A password reset link has been sent to the email: {0}",
  "userDetail.userUpdate.success": "Account information has been updated",
  "userDetail.statusCard.title": "Basic information",
  "userDetail.statusCard.status": "Account status",
  "userDetail.statusCard.numberOfCredits": "Coupon credit",
  "userDetail.statusCard.numberOfSubscriptionCredits": "Subscription credit",
  "userDetail.statusCard.deleteModal.text":
    "are you sure you want to cancel the subscription for the user?",
  "userDetail.statusCard.subscriptionStatus": "Subscription account status",
  "userDetail.statusCard.subscriptionStatus.activated": "Activated",
  "userDetail.statusCard.subscriptionStatus.unactivated":
    "without subscription",
  "userDetail.statusCard.subscriptionStatus.canceled": "canceled",
  "userDetail.statusCard.numberOfOrders": "Number of orders",
  "userDetail.statusCard.numberOfPhotos": "Number of printed photos",
  "userDetail.statusCard.registrationDate": "Registration date",
  "userDetail.statusCard.registrationType": "Registration type",
  "userDetail.statusCard.lastActivity": "Last activity",
  "userDetail.statusCard.creditTransfer": "Transfer credit",
  "userDetail.statusCard.subscriptionDelete": "Delete subscription",
  "userDetail.statusCard.operationList": "Operations list",
  "userDetail.statusCard.sourceUser": "Source user",
  "userDetail.statusCard.destinationUser.label": "Destination user",
  "userDetail.statusCard.destinationUser.placeholder": `Enter mail or name (min. 3 characters)`,
  "userDetail.statusCard.sendCredits": "Number of transfer credits:",
  "userDetail.statusCard.remains": "remains",
  "userDetail.statusCard.credits": "credits",
  "userDetail.statusCard.lessThanZero": "User not have enough",
  "userDetail.statusCard.chooseUser": "Choose user...",
  "userDetail.statusCard.maxCredits": "Number of credits: max.",
  "userDetail.statusCard.confirmButton": "Confirm",
  "userDetail.statusCard.modalTitle":
    "You are trying to transfer credit between users",
  "userDetail.statusCard.successTransform":
    "Credits have been successfully transferred between users",
  "userDetail.statusCard.successDeactivateSubscription":
    "User subscription was deactivated",
  "userDetail.companyTable.title": "Companies",
  "userDetail.storeTable.title": "Stores",
  "userDetail.toast.block.success": "Account was blocked",
  "userDetail.toast.unblock.success": "Account was unblocked",
  "userDetail.toast.sendActivationUser.success":
    "An activation email has been sent to your account",
  "userDetail.toast.activate.success": "Account was activated",
  "userDetail.toast.delete.success": "Account was removed",
  "userDetail.toast.delete.fail": "This action is not allowed",

  "userDetail.ordersTable.empty": "User order list is empty.",
  "userDetail.paymentsTable.empty": "User payments list is empty.",
  "userDetail.operationsTable.empty": "User operations list is empty.",
  "userDetail.ambassadorReferralsChart.title": "Graph of scan activity.",
  "userDetail.ambassadorReferralsChart.empty":
    "No AQR coupon scanning activity was recorded after the last 30 days.",
  "userDetail.ambassadorReferralsChart.barTitle": "AQR use",
  "userDetail.ambassadorCart.title": "Ambassador",
  "userDetail.ambassadorCart.function": "Ambassador function",
  "userDetail.ambassadorCart.function.enabled": "Enabled",
  "userDetail.ambassadorCart.function.disabled": "Disabled",
  "userDetail.ambassadorCart.type": "Ambassador type",
  "userDetail.ambassadorCart.type.credit": "Credit",
  "userDetail.ambassadorCart.type.money": "Money",
  "userDetail.ambassadorCart.inheritSettings":
    "Inherit ambassador values ​​from system settings",
  "userDetail.ambassadorCart.enabled.success":
    "The ambassador function has been activated",
  "userDetail.ambassadorCart.disabled.success":
    "The ambassador function has been deactivated",
  "userDetail.ambassadorCart.qrCodeModal.title": "QR code display",

  "userDetail.ambassadorReferralsTable.title": "List of ambassador referrals",
  "userDetail.ambassadorReferralsTable.empty":
    "List of ambassador referrals is empty.",
  "userDetail.ambassadorReferralsTable.claimedAt": "Claimed at",
  "userDetail.ambassadorReferralsTable.user": "User",
  "userDetail.ambassadorReferralsTable.customerReward": "Customer reward",
  "userDetail.ambassadorReferralsTable.referralReward": "Referral reward",
  "userDetail.ambassadorReferralsTable.type": "Typ",

  "userDetail.ambassadorCommissionsTable.title":
    "List of ambassador commissions",
  "userDetail.ambassadorCommissionsTable.empty":
    "List of ambassador commissions is empty.",
  "userDetail.ambassadorCommissionsTable.period": "Month",
  "userDetail.ambassadorCommissionsTable.amount": "Amount",
  "userDetail.ambassadorCommissionsTable.referrals": "Referrals",
  "userDetail.ambassadorCommissionsTable.export": "Export",
  "userDetail.headerActions.inviteAmbassador": "Invite ambassador",
  "userDetail.ambassadorInvite.success":
    "Invitation for ambassador was sended on email",
  "userDetail.ambassadorExport.modal.title": "Export Ambassador referrals",
  "userDetail.ambassadorExport.modal.button": "Export",
  "userDetail.ambassadorExport.modal.emails": "Ambassadors emails",
  "userDetail.ambassadorExport.success":
    "Ambassador referrals export was downloaded.",
};

const devices = {
  "deviceList.title": "Fotomats",
  "deviceList.header": "Fotomat list",
  "deviceList.table.title": "Fotomat list",
  "deviceList.assignDevice": "ASSIGN FOTOMAT",
  "deviceList.discoverDevice": "SEARCH STOCK",
  "deviceList.table.active": "Active",
  "deviceList.table.changePartnerSuccess": "Partner changed successfuly",
  "deviceList.table.changePartnerFailed": "Failed to change partner",
  "deviceList.table.ID": "ID",
  "deviceList.table.state": "Status",
  "deviceList.table.heartbeat": "Heartbeat",
  "deviceList.table.partner": "Partner",
  "deviceList.table.company": "Company",
  "deviceList.table.store": "Store",
  "deviceList.table.address": "Address",
  "deviceList.table.printedPhotos": "Printed Photos",
  "deviceList.table.remainingPhotos": "Zostáva",
  "deviceList.table.placement": "Location",
  "deviceList.table.actions": "Actions",
  "devicesList.filter.active": "Active",
  "devicesList.filter.nonActive": "Deactive",
  "deviceList.table.actions.detail": "Detail",
  "deviceList.enabledToggle": "Store",
  "deviceList.disabledToggle": "Stock",
  "deviceList.assignModal.title": "Assign fotomat",
  "deviceList.assignModal.assign": "ASSIGN",
  "deviceList.assignModal.cancel": "CANCEL",
  "deviceList.assignModal.partner": "Choose partner...",
  "deviceList.assignModal.device": "Choose fotomat...",
  "deviceList.discoverModal.title": "Stock status",
  "deviceList.discoverModal.textNo": "No new Fotomats have been added",
  "deviceList.discoverModal.textYes":
    "Number of new Fotomats to the stock: {0}",
  "deviceDetail.title": "Fotomat",
  "deviceDetail.status.deviceState": "Fotomat status",
  "deviceDetail.status.lastHeartbeat": "Latest heartbeat",
  "deviceDetail.status.remainingPhotoCount": "Remaining photos",
  "deviceDetail.status.printerCounter": "Printer counter",
  "deviceDetail.status.photosPerMonth": "Photos per month",
  "deviceDetail.status.restart": "RESTART",
  "deviceDetail.status.deactivate": "DEACTIVATE",
  "deviceDetail.status.reportError": "Report error",
  "deviceDetail.status.activate": "Activate",
  "deviceDetail.status.back": "Back",
  "deviceDetail.status.restartModal.title": "Restart fotomat",
  "deviceDetail.status.restartModal.text":
    "Do you want to restart the fotomat?",
  "deviceDetail.status.deactivateModal.title": "Deactivate fotomat",
  "deviceDetail.status.deactivateModal.text":
    "Do you want to deactivate the fotomat",
  "deviceDetail.status.activateModal.title": "Activate fotomat",
  "deviceDetail.status.activateModal.text":
    "Do you want to activate the fotomat?",
  "deviceDetail.partner.title": "Partner",
  "deviceDetail.partner.printerPhotos": "Printer photo count",
  "deviceDetail.partner.orderPhotos": "Order photo count",
  "deviceDetail.partner.changePartner": "CHANGE PARTNER",
  "deviceDetail.partner.removePartner": "REMOVE PARTNER",
  "deviceDetail.partner.detailPartner": "PARTNER DETAIL",
  "deviceDetail.partner.changePartnerModal.title": "Change partner",
  "deviceDetail.partner.changePartnerModal.action": "CHANGE",
  "deviceDetail.partner.removePartnerModal.title": "Remove partner",
  "deviceDetail.partner.removePartnerModal.text":
    "Do you want to remove the partner?",
  "deviceDetail.partner.removePartnerModal.action": "REMOVE",
  "deviceDetail.partner.back": "Back",
  "deviceDetail.orders.title": "Orders",
  "deviceDetail.orders.ordersList": "Order list",
  "deviceDetail.orders.table.id": "ID",
  "deviceDetail.orders.table.date": "Date",
  "deviceDetail.orders.table.volume": "Amount",
  "deviceDetail.orders.table.actions": "Actions",
  "deviceDetail.orders.table.actions.detail": "Detail",
  "deviceDetail.provisions.title": "Commissions",
  "deviceDetail.provisions.totalProvision": "Total commission",
  "deviceDetail.provisions.provisionsList": "Commission list",
  "deviceDetail.address.title": "Location address",
  "deviceDetail.address.placeholder": "Enter address",
  "deviceDetail.address.undefinedLocation": "Location not specified",
  "deviceDetail.partner.removePartnerSuccessful": "Partner removed successfuly",
  "deviceDetail.partner.removePartnerFailed": "Failed to remove partner",
  "deviceDetail.partner.changePartnerSuccessful":
    "Partner changed  successfuly",
  "deviceDetail.partner.changePartnerFailedl": "Failed to change partner",
  "deviceDetail.status.deviceDeactivationSuccessful": "Deactivated successfuly",
  "deviceDetail.status.deviceActivationSuccessful": "Activated successfuly",
  "deviceDetail.status.deviceDeactivationFailed": "Failed to deactivate",
  "deviceDetail.status.deviceActivationFailed": "Failed to activate",
  "deviceDetail.status.deviceRestartSuccessful": "Restarted successfuly",
  "deviceDetail.status.deviceRestartFailed": "Failed to restart",
  "deviceDetail.partner.changeAddressFailed": "Failed to change address",
  "deviceDetail.partner.changeAddressSuccessful": "Address changed successfuly",
  "deviceDetail.player.changePlayer.title": "Change fotomat",
  "deviceDetail.player.changePlayer.text":
    "To the operation {name of the operation} ({ID of operation}) have been assigned fotomat {ID of player}. Choose new fotomat: ",
  "deviceDetail.player.newPlayer.text":
    "Please choose new fotomat for this Store: ",
  "deviceDetail.player.changePlayer.change": "Change",
  "deviceDetail.player.assignPlayer.title": "Assign fotomat",
  "deviceDetail.player.assignPlayer.text":
    "To the operation {name of the operation} ({ID of operation}) assign new fotomat: ",
  "deviceDetail.player.assignPlayer.assign": "Assign",
  "deviceDetail.player.assignPlayer.change": "Change",
  "deviceDetail.player.withoutPlayer.title": "Without Fotomat",
  "deviceDetail.player.assignPlayerSuccessful": "Fotomat assigned successfully",
  "deviceDetail.store.detail": "Store detail",
  "deviceList.removeDevice": "Remove fotomat",
  "deviceList.assignModal.store": "Choose store...",
  "deviceDetail.status.removeModal.text-1": "You really want to remove fotomat",
  "deviceDetail.status.removeModal.text-2": "from store",
  "deviceDetail.status.remove": "Remove",
  "deviceDetail.status.deviceRemoveSuccessful": "Fotomat remove successful",
  "deviceDetail.deviceStatusCard.fotomatActivated": "Activated",
  "deviceDetail.deviceStatusCard.fotomatDeactivated": "Deactivated",
  "deviceDetail.deviceStatusCard.fotomatAppRunning": "Running",
  "deviceDetail.deviceStatusCard.fotomatAppStopped": "Stopped",
  "deviceDetail.deviceStatusCard.fotomatApp": "Fotomat App",
  "deviceDetail.status.removeModal.title": "Remove fotomat",
  "deviceDetail.success.browseStock": "The stock with Fotomats was searched",

  "device.issue.title": "List of all issues",
  "device.issue.code": "Code",
  "device.issue.description": "Description",
  "device.issue.date": "Date",
};

const campaigns = {
  "campaigns.removeModal.title": "Close campaign",
  "campaigns.removeModal.yes": "Remove",
  "campaigns.removeModal.no": "Cancel",
  "campaigns.new": "New campaign",
  "campaigns.duplicate.button": "Duplicate campaign",
  "campaigns.detail": "Detail",
  "campaigns.internal.title": "Internal campaigns",
  "campaigns.internal.header": "Campaign list",
  "campaigns.commercial.title": "Commercial campaigns",
  "campaigns.commercial.header": "Campaign list",
  "campaigns.detail.basicInfo.title": "Basic information",
  "campaigns.detail.budget.title": "Budget",
  "campaigns.detail.spots.title": "Campaign banners",
  "campaigns.detail.schedule.title": "Time schedule",
  "campaigns.detail.stores.title": "Location and stores",
  "campaigns.detail.company.title": "Company",
  "campaigns.detail.map.title": "Stores map",
  "campaigns.budgetCard.title": "Budget",
  "campaigns.budgetCard.budgetTotal": "Campaign budget",
  "campaigns.budgetCard.budgetSpent": "Spent budget",
  "campaigns.budgetCard.budgetRemaining": "Remaining budget",
  "campaigns.budgetCard.AvgBid": "Average photo print price (C)",
  "campaigns.budgetCard.MaxBid": "Max photo print price (C)",
  "campaigns.budgetCard.balanceOfCredits": "Balance of credits: ",
  "campaigns.budgetCard.lackOfCredits":
    "Balance of credits: The company does not have enough credits",
  "campaigns.spotsCard.title": "Campaign banners",
  "campaigns.spotsCard.banners": "Advertising  banners",
  "campaigns.spotsCard.count": "prints",
  "campaigns.companyCard.title": "Company",
  "campaigns.companyCard.name": "Company",
  "campaigns.companyCard.address": "Address",
  "campaigns.companyCard.contact": "Contact",
  "campaigns.companyCard.phone": "Phone",
  "campaigns.companyCard.email": "E-mail",
  "campaigns.companyCard.details.button": "Company detail",
  "campaigns.infoCard.maxBidInfo":
    "The Price for one print cannot be higher than the campaign budget",
  "campaigns.infoCard.title": "Basic information",
  "campaigns.infoCard.owner": "Ordered by",
  "campaigns.infoCard.type": "Campaign type",
  "campaigns.scheduleCard.repeat": "Minimum retry interval for one user (days)",
  "campaigns.mapCard.title": "Store map",
  "campaigns.targetingCard.title": "Location and stores",
  "campaigns.targetingCard.placeholder.location": "Location",
  "campaigns.targetingCard.placeholder.category": "Store category",
  "campaigns.targetingCard.placeholder.company": "Company",
  "campaigns.targetingCard.placeholder.store": "Store",
  "campaigns.new.createButton": "Create campaign",
  "campaigns.new.cancelButton": "Cancel",
  "campaigns.delete.response.success": "Campaign was successfully removed",
  "campaigns.cancel.response.success": "Campaign was successfully finished",
  "campaigns.delete.response.error": "Campaign was not removed",
  "campaigns.submit": "Submit",
  "campaigns.freefoto.title": "FreeFoto campaigns",
  "campaigns.freefoto.header": "FreeFoto campaigns list",
  "campaigns.qr.title": "Coupon campaigns",
  "campaigns.qr.header": "Coupon campaigns list",
  "campaigns.coupons.header": "Unassigned coupon sets and MQR coupons list",
  "campaigns.new.freeFoto.title": "New FreeFoto campaign",
  "campaigns.new.qr.title": "New QR campaign",
  "campaigns.new.pqr.title": "New MQR campaign",
  "campaigns.status.running": "Running",
  "campaigns.status.awaiting": "Pending approval",
  "campaigns.status.rejected": "Rejected",
  "campaigns.status.finished": "Finished",
  "campaigns.status.scheduled": "Scheduled",
  "campaigns.status.inactive": "Inactive",
  "campaigns.table.ID": "ID",
  "campaigns.table.name": "Name",
  "campaigns.table.owner": "Owner",
  "campaigns.table.type": "Type",
  "campaigns.type.qr": "QR",
  "campaigns.type.pqr": "MQR",
  "campaigns.table.status": "Status",
  "campaigns.table.start": "Start date",
  "campaigns.table.end": "End date",
  "campaigns.table.maxPrice": "Max. price",
  "campaigns.table.budget": "Budget",
  "campaigns.table.cupons": "Coupons",
  "campaigns.table.couponCount": "Coupons count",
  "campaigns.table.couponNominalValue": "Nom. value",
  "campaigns.table.author": "Author",
  "campaigns.table.updatedAt": "Date",
  "campaigns.table.totalSetValue": "Total (C)",
  "campaigns.newCoupons.nominalValue": "Nominal value (C)",
  "campaigns.newCoupons.couponCount": "Number of QR coupons in the set",
  "campaigns.newCoupons.title": "Create coupons set",
  "campaigns.newPQRCoupons.title": "Vytvoriť nový MQR kupón",
  "campaigns.newCoupons.create": "Create",
  "campaigns.newCoupons.success": "Coupons set was created",
  "campaigns.newPQRCoupons.success": "MQR coupon was created",
  "campaigns.button.newCampaign": "New campaign",
  "campaigns.button.newQRCampaign": "New QR campaign",
  "campaigns.button.newPQRCampaign": "New MQR campaign",
  "campaigns.button.newPQRCoupon": "Nový MQR kupón",
  "campaigns.button.newCouponSet": "New coupons set",
  "campaigns.toggle.couponSet": "Coupons sets",
  "campaigns.toggle.qrCampaign": "Coupon campaigns",
  "campaigns.approved.response.success": "Campaign was approved",
  "campaigns.rejected.response.success": "Campaign was rejected",
  "campaigns.end.button": "Close campaign",
  "campaigns.approved.button": "Approve campaign",
  "campaigns.rejected.button": "Reject campaign",
  "campaigns.removeModal.text": "Do you really want to close campaign",
  "campaigns.rejectedModal.text": "Do you really want to reject campaign",
  "campaigns.approvedModal.text": "Do you really want to approve campaign",
  "campaigns.pictureCard.title": "Preview",
  "campaigns.creditChart.title": "Credits",
  "campaigns.creditChart.remainingCredits": "Remaining credits",
  "campaigns.creditChart.usedCredits": "Used credits",
  "campaigns.photosChart.title": "Copies",
  "campaigns.photosChart.remainingPhotos": "Remaining",
  "campaigns.photosChart.usedPhotos": "Used",
  "campaigns.photosChart.allocatedPhotos": "Allocated",
  "campaigns.infoCard.name": "Campaign name",
  "campaigns.infoCard.company": "Campaign customer",
  "campaigns.infoCard.startDate": "Start date",
  "campaigns.infoCard.endDate": "End date",
  "campaigns.infoCard.description": "Description",
  "campaigns.infoCard.createdBy": "Created by:",
  "campaigns.infoCard.createdAt": "Created at:",
  "campaigns.infoCard.topics": "Topics",
  "campaigns.budgetCard.account": "Account:",
  "campaigns.budgetCard.qrBudget": "Number of credits:",
  "campaigns.budgetCard.maxPhotos": "Max. copies:",
  "campaigns.budgetCard.freeFotoBudget": "Number of copies:",
  "campaigns.coupons.removeWarning":
    "Are you sure you want to delete the coupon set?",
  "campaigns.coupons.removeButton": "Delete",
  "campaigns.coupons.removeSuccess": "Coupon Set was deleted",
  "campaigns.graficCard.errorResolution":
    "You have selected an incorrect resolution image",
  "campaigns.graficCard.successUpload": "Ad banner loaded successfully",
  "campaigns.graficCard.title": "Campaign graphics: ",
  "campaigns.graficCard.rules": "Rules: ",
  "campaigns.graficCard.changeRules": "Choose other picture: ",
  "campaigns.graficCard.firstRule":
    "1.) Advertising banner resolution: 600 x 1200 px",
  "campaigns.graficCard.secondRule": "2.) Supported formats: .jpg, .png",
  "campaigns.watermark.firstRule": "1.) Watermark resolution: 600 x 600 px",
  "campaigns.watermark.secondRule": "2.) Supported format: .png",
  "campaigns.graficCard.choosePicture":
    "Press the button to choose a picture: ",
  "campaigns.graficCard.changePicture":
    "Press the button to change a picture: ",
  "campaign.new.successCreated": "Campaign was created",
  "campaigns.setCouponSet.button": "Set coupons",
  "campaigns.setPQRCoupon.button": "Set MQR coupon",
  "campaigns.addBudget.button": "Doplniť kredity",
  "campaigns.setCouponSet.assigned": "Set",
  "campaigns.setCouponSet.label": "Unassigned coupons",
  "campaigns.setCouponSet.placeholder": "Choose coupon set...",
  "campaigns.setCouponSet.success": "Coupon set was assigned",
  "campaigns.setCouponSet.confirm":
    "Are you sure you want to assign this coupon set to your campaign?",
  "campaigns.setCouponSet.choosenCouponSet": "Selected set of coupons:",
  "campaigns.setCouponSet.credits": "Credit balance:",
  "campaigns.couponsChart.title": "Coupons",
  "campaigns.couponsChart.remainingCoupons": "Remaining coupons",
  "campaigns.couponsChart.usedCoupons": "Used coupons",
  "campaigns.couponsChart.allCoupons": "All coupons",
  "campaigns.couponsChart.remainingBudget": "Remaining credits",
  "campaigns.couponsChart.allocatedBudget": "Allocated credits",
  "campaigns.couponsChart.allBudget": "All credits",
  "campaigns.couponsChart.usedBudget": "Used credits",
  "campaigns.couponsChart.unusedBudget": "Unused credits",
  "campaigns.coupons.couponSetExport": "Coupon set was exported to CSV file",
  "campaigns.couponsChart.noCoupons": "No coupon set assigned",
  "campaigns.couponsChart.noAllocated": "No credits allocated or used",
  "campaigns.couponsTable.title": "Coupons List",
  "campaigns.couponWidget.title": "Coupon sets",
  "campaigns.table.couponIdDetail": "ID",
  "campaigns.table.couponStatusDetail": "Status",
  "campaigns.table.couponNominalValueDetail": "Value",
  "campaigns.table.description": "Description",
  "campaigns.table.stores": "Number of stores",
  "campaigns.table.maxBid": "Photo value",
  "campaigns.table.maxBid": `Value of one print`,
  "campaigns.table.couponCountDetail": "Count",
  "campaigns.table.couponUsedDetail": "Used",
  "campaigns.table.totalSetValueDetail": "Credit",
  "campaigns.table.couponUsedCreditsDetail": "Used cr.",
  "campaigns.table.actions": "Actions",
  "campaigns.table.detailButton": "Detail",
  "campaigns.table.exportButton": "Export",
  "campaigns.table.couponDate": "Use",
  "campaigns.table.couponUser": "User",
  "campaigns.table.couponToken": "Token",
  "campaigns.table.coupon.active": "Active",
  "campaigns.table.coupon.depleted": "Depleted",
  "campaigns.table.coupon.used": "Used",
  "campaigns.table.coupon.unused": "Unused",
  "campaigns.couponSetDetail.title": "Coupon set",
  "campaigns.couponSetDetail.id": "Coupon set ID:",
  "campaigns.couponSetInfo.couponsNumber": "Total coupons",
  "campaigns.couponSetInfo.couponsNominalValue": "Niminal value",
  "campaigns.couponSetInfo.couponsTotalValue": "Total value",
  "campaigns.couponSetUse.title": "Use",
  "campaigns.couponSetUse.couponsAllNumber": "Total coupons",
  "campaigns.couponSetUse.couponsUsedNumber": "Used coupons",
  "campaigns.couponSetUse.couponsRemainigNumber": "Remaining coupons",
  "campaigns.couponSetUse.couponsAllCredits": "Total credits",
  "campaigns.couponSetUse.couponsUsedCredits": "Used credits",
  "campaigns.couponSetUse.couponsRemainingCredits": "Remaining credits",
  "campaigns.couponSetInfo.table": "Coupons",
  "campaigns.couponSetDetail.exportButton": "Export",
  "campaigns.unassignedCoupons.couponSet": "Coupon set",
  "campaigns.unassignedCoupons.PQRcouponSet": "MQR Coupon",
  "campaigns.unassignedCoupons.value": "Value",

  "campaigns.addCredit.button": "Increase budget",
  "campaigns.upCredit.success":
    "The MQR campaign credit has been successfully increased",
  "campaigns.setPQRCouponSet.success":
    "The MQR coupon has been assigned to the campaign",
  "campaigns.button.continue": "Continue",
  "campaigns.creditUp.title":
    "You are about to increase the MQR campaign credit by {0} C. Do you want to continue?",
  "campaigns.assignPQRCoupon.title": "Assign a MQR coupon",
  "campaigns.PQRCoupon.description": "MQR coupon description",
  "campaigns.PQRCoupon.couponSet": "List of unassigned MQR coupons",
  "campaigns.setPQRCouponSet.confirm":
    "Are you sure you want to assign this MQR coupon to your campaign?",
  "campaigns.creditUp.modalTitle": "Increase MQR campaign budget",
  "campaigns.setPQRCouponSet.description": "MQR coupon description",
  "campaigns.setQRCouponSet.id": "Coupon set ID",
  "campaigns.setPQRCouponSet.id": "MQR coupon ID",
  "campaigns.setQRCouponSet.value": "Coupon set value",
  "campaigns.setPQRCouponSet.value": "MQR coupon value",
  "campaigns.table.scans": "Scans",
  "campaigns.couponSetInfo.pqrName": "MQR coupon description",
  "campaigns.couponSetInfo.pqrNominalValue": "MQR coupon value",
  "campaigns.couponSetUse.pqrUse": "Number of scans of the MQR coupon",
  "campaigns.infoCard.total": "Total budget",

  "campaigns.create.success": "Campaign was created",
  "campaigns.update.success": "Campaign was updated",
  "campaigns.cancel.success": "Campaign was canceled",
  "campaigns.approve.success": "Campaign was approved",
  "campaigns.reject.success": "Campaign was rejected",
  "campaigns.reject.button": "Reject",
  "campaigns.approve.button": "Approve",
  "campaigns.reject.bigButton": "Reject campaign",
  "campaigns.approve.bigButton": "Approve campaign",
  "campaigns.reject.modalBody": "You want reject campaign",
  "campaigns.approve.modalBody": "You want approve campaign",
  "campaigns.cancel.button": "Cancel campaign",
  "campaigns.title.canceled": "Canceled",
  "campaigns.title.rejected": "Rejected",
  "campaigns.locationsCard.locations": "Locations",
  "campaigns.locationsCard.segments": "Categories",
  "campaigns.locationsCard.companies": "Companies",
  "campaigns.locationsCard.stores": "Stores",
  "campaigns.locationsCard.all": "All",
  "campaigns.locationsCard.table.name": "Name",
  "campaigns.locationsCard.table.count": "Count",
  "campaigns.scheduleCard.title": "Time plan",

  // NEW
  "campaigns.campaignWidget.location1": "Locations",
  "campaigns.campaignWidget.location2": "Location",
  "campaigns.campaignWidget.location3": "Locations",

  "campaigns.campaignWidget.segment1": "Categories",
  "campaigns.campaignWidget.segment2": "Category",
  "campaigns.campaignWidget.segment3": "Categories",
  "campaigns.campaignWidget.company1": "Companies",
  "campaigns.campaignWidget.company2": "Company",
  "campaigns.campaignWidget.company3": "Companies",
  "campaigns.campaignWidget.store1": "Stores",
  "campaigns.campaignWidget.store2": "Store",
  "campaigns.campaignWidget.store3": "Stores",
  "campaigns.campaignWidget.all": "All",
  "campaigns.campaignWidget.find": "Find",
  "campaigns.campaignWidget.chooseStores": "Stores",

  "campaigns.wizard.basicInfo": "Basic information",
  "campaigns.wizard.media": "Media",
  "campaigns.wizard.budgetAndTimePlan": "Other parameters",
  "campaigns.wizard.stores": "Stores",
  "campaigns.wizard.confirm": "Confirmation",
  "campaigns.wizard.filters": "Age categories",
  "campaigns.wizard.basicInfo.description": "Enter basic campaign information",
  "campaigns.wizard.media.description":
    "Select campaign images from your media list",
  "campaigns.wizard.budgetAndTimePlan.description":
    "Enter the budget, schedule, and stores where the campaign will be targeted",
  "campaigns.wizard.age.filters":
    "Set gender and age for better campaign targeting",
  "campaigns.wizard.budgetAndTimePlan.filters":
    "Set filters to better target notifications",
  "campaigns.wizard.stores.description":
    "Choose the locations where the campaign will run",
  "campaigns.wizard.confirm.description":
    "Review the basic information about the campaign and confirm its creation",
  "campaigns.infoCard.withoutCredits":
    "The selected company does not have enough credits",
  "campaigns.infoCard.creditStatus": "Balance of company credits: ",
  "campaigns.wizard.step3.budget": "Budget",
  "campaigns.wizard.step3.maxBudget": "Maximum campaign budget",
  "campaigns.wizard.step3.maxBid": "Maximum price for one print (min. 11 C)",
  "campaigns.wizard.step3.timePlan": "Time plan",
  "campaigns.wizard.finalStep.name": "Name",
  "campaigns.wizard.finalStep.topics": "Topics",
  "campaigns.wizard.finalStep.start": "Start",
  "campaigns.wizard.finalStep.end": "End",
  "campaigns.wizard.finalStep.description": "Description",
  "campaigns.wizard.finalStep.mediaAndBudget": "Media and budget",
  "campaigns.wizard.finalStep.media": "Media",
  "campaigns.wizard.finalStep.maxBudget": "Max. budget",
  "campaigns.wizard.finalStep.maxBid": "Max. price for one print",
  "campaigns.wizard.finalStep.timePlan": "Time plan",
  "campaigns.wizard.finalStep.minRepeatInterval": "Min. repetition interval",
  "campaigns.wizard.finalStep.stores": "Addressed stores",
  "campaigns.wizard.finalStep.storesList": "Stores list",
  "campaigns.wizard.finalStep.days": "days",
  "campaigns.wizard.step2.pictures": "Campaign pictures",
  "campaigns.spotsCard.banner": "Advertising banner",
  "campaigns.scheduleCard.timePlan": "Time plan",
  "campaigns.wizard.finalStep.all": "All",

  "campaigns.wizard.step4.title": "Gender and Age Categories",
  "campaigns.wizard.step4.age": "Age (from - to)",
  "campaigns.wizard.step4.gender.men": "Men",
  "campaigns.wizard.step4.gender.women": "Women",
  "campaigns.wizard.step4.gender.Unknown": "Unknown",
  "campaigns.wizard.step4.gender.R13_18": "13 - 18",
  "campaigns.wizard.step4.gender.R19_34": "19 - 34",
  "campaigns.wizard.step4.gender.R35_49": "35 - 49",
  "campaigns.wizard.step4.gender.R50_": "50 +",
  "campaigns.wizard.step4.gender.age": " years",

  "campaigns.freeFoto.watermark": "Watermark",
  "campaigns.freeFoto.watermark.title": "Campaign Watermark",
  "campaigns.freeFoto.watermark.default": "Default watermark: Fotomat logo",
  "campaigns.finderBox.label": "Find the coupon by entering the ID...",
  "campaigns.finderBox.coupon": "Coupon",
  "campaigns.finderBox.value": "Value",
  "campaigns.finderBox.set": "Set",
  "campaigns.finderBox.campaign": "Campaign",
  "campaigns.finderBox.notAssigned": "not assigned",
  "campaigns.finderBox.applied": "Applied",
  "campaigns.finderBox.unclaimed": "unclaimed",
};

const companies = {
  "companyList.title": "Company",
  "companyList.table.title": "Company list",
  "companyList.table.allCompanies": "Companies list",
  "companyList.table.addCompany": "Create company",
  "companyList.table.active": "Active",
  "companyList.table.changePartnerSuccess": "Partner changed successfuly",
  "companyList.table.changePartnerFailed": "Failed to change partner",
  "companyList.table.ID": "ID",
  "companyList.table.name": "Title",
  "companyList.table.category": "Category",
  "companyList.table.stores": "Stores",
  "companyList.table.address": "Address",
  "companyList.table.state": "Status",
  "companyList.table.partner": "Partner",
  "companyList.table.company": "Company",
  "companyList.table.placement": "Location",
  "companyList.table.actions": "Actions",
  "companyList.table.actions.detail": "Detail",
  "companyList.enabledToggle": "Operation",
  "companyList.disabledToggle": "Stock",
  "companyList.addModal.title": "CREATE COMPANY",
  "companyList.addModal.add": "ADD",
  "companyList.addModal.cancel": "CANCEL",
  "companyList.assignModal.partner": "Partner",
  "companyList.assignModal.operation": "Operation",
  "companyList.assignModal.company": "Choose company...",
  "companiesList.filter.active": "Active",
  "companiesList.filter.nonActive": "Inactive",
  "company.partner.assign.success": "Partner successfully assigned",
  "company.partner.remove.success": "Partner removed",
  "company.store.assign.success": "Store successfully assigned",
  "companyList.addCompany.title": "New Company",
  "companyDetail.title": "Company",
  "companyDetail.status.operationState": "Company status",
  "companyDetail.status.restart": "RESTART",
  "companyDetail.status.deactivate": "DEACTIVATE",
  "companyDetail.status.reportError": "Report error",
  "companyDetail.status.activate": "Activate",
  "companyDetail.status.back": "Back",
  "companyDetail.status.restartModal.title": "Restart company",
  "companyDetail.status.restartModal.text":
    "Do you want to restart the company?",
  "companyDetail.status.deactivateModal.title": "Deactivate company",
  "companyDetail.status.deactivateModal.text":
    "Do you want to deactivate the company?",
  "companyDetail.status.activateModal.title": "Activate company",
  "companyDetail.status.activateModal.text":
    "Do you want to activate the company?",
  "companyDetail.partner.title": "Partner",
  "companyDetail.partner.printerPhotos": "Printer photo count",
  "companyDetail.partner.orderPhotos": "Order photo count",
  "companyDetail.partner.changePartner": "CHANGE PARTNER",
  "companyDetail.partner.removePartner": "REMOVE PARTNER",
  "companyDetail.partner.detailPartner": "PARTNER DETAIL",
  "companyDetail.partner.changePartnerModal.title": "Change partner",
  "companyDetail.partner.changePartnerModal.action": "CHANGE",
  "companyDetail.partner.removePartnerModal.title": "Remove partner",
  "companyDetail.partner.removePartnerModal.text":
    "Do you want to remove the partner?",
  "companyDetail.partner.removePartnerModal.action": "REMOVE",
  "companyDetail.partner.back": "Back",
  "companyDetail.orders.title": "Orders",
  "companyDetail.orders.ordersList": "Order list",
  "companyDetail.orders.table.id": "ID",
  "companyDetail.orders.table.date": "Date",
  "companyDetail.orders.table.volume": "Amount",
  "companyDetail.orders.table.actions": "Actions",
  "companyDetail.orders.table.actions.detail": "Detail",
  "companyDetail.provisions.title": "Commissions",
  "companyDetail.provisions.totalProvision": "Total commission",
  "companyDetail.provisions.provisionsList": "Commission list",
  "companyDetail.address.title": "Location address",
  "companyDetail.address.placeholder": "Enter address",
  "companyDetail.address.undefinedLocation": "Location not specified",
  "companyDetail.partner.removePartnerSuccessful":
    "Partner removed successfuly",
  "companyDetail.partner.removePartnerFailed": "Failed to remove partner",
  "companyDetail.partner.changePartnerSuccessful":
    "Partner changed  successfuly",
  "companyDetail.partner.changePartnerFailedl": "Failed to change partner",
  "companyDetail.status.companyDeactivationSuccessful":
    "Deactivated successfuly",
  "companyDetail.status.companyActivationSuccessful": "Activated successfuly",
  "companyDetail.status.companyDeactivationFailed": "Failed to deactivate",
  "companyDetail.status.companyActivationFailed": "Failed to activate",
  "companyDetail.status.companyRestartSuccessful": "Restarted successfuly",
  "companyDetail.status.companyRestartFailed": "Failed to restart",
  "companyDetail.partner.changeAddressFailed": "Failed to change address",
  "companyDetail.partner.changeAddressSuccessful":
    "Address changed successfuly",
  "companyDetail.partnersTable.title": "Partners List",
  "companyDetail.partnersTable.id": "ID",
  "companyDetail.partnersTable.name": "Name",
  "companyDetail.partnersTable.action": "Action",
  "companyDetail.addressCard.name": "Company name",
  "companyDetail.addressCard.address": "Street and street number",
  "companyDetail.addressCard.postal": "Postal Code",
  "companyDetail.addressCard.city": "City",
  "companyDetail.addressCard.country": "Country",
  "companyDetail.contactPersonCard.title": "Contact person",
  "companyDetail.contactPersonCard.firstName": "First name",
  "companyDetail.contactPersonCard.lastName": "Last name",
  "companyDetail.contactPersonCard.phone": "Phone  (Format: +421903123123)",
  "companyDetail.contactPersonCard.email": "E-mail",
  "companyDetail.billingInfoCard.ico": "IČO",
  "companyDetail.billingInfoCard.dic": "DIČ",
  "companyDetail.billingInfoCard.icDPH": "IČ DPH",
  "companyDetail.billingInfoCard.registrationCourt": "Registration court",
  "companyDetail.billingInfoCard.iban": "IBAN",
  "companyDetail.billingInfoCard.bankBIC": "BIC/SWIFT",
  "companyDetail.billingInfoCard.bankName": "Bank name",
  "companyDetail.accountStatus.title": "Account status",
  "companyDetail.accountStatus.status": "Bank account status: ",
  "companyDetail.accountStatus.stores": "Stores",
  "companyDetail.accountStatus.commissions": "Commissions",
  "companyDetail.btn.assignPartner": "Assign partner",
  "companyDetail.btn.assign": "ASSIGN",
  "companyDetail.btn.assignStore": "Assign store",
  "companyDetail.btn.removeCompany": "Remove company",
  "companyDetail.btn.remove": "REMOVE",
  "companyDetail.store.title": "Prevádzka",
  "companyDetail.company.removeCompanySuccessful":
    "Company removed succesfully",
  "companyDetail.company.updateCompanySuccessful":
    "Company updated succesfully",
};

const stores = {
  "storeList.title": "Stores",
  "storeList.table.title": "Store list",
  "storeList.table.active": "Active",
  "storeList.table.changePartnerSuccess": "Partner changed successfuly",
  "storeList.table.changePartnerFailed": "Failed to change partner",
  "storeList.table.ID": "ID",
  "storeList.table.name": "Title",
  "storeList.table.partnerName": "Name",
  "storeList.table.category": "Category",
  "storeList.table.heartbeat": "Heartbeat",
  "storeList.table.address": "Address",
  "storeList.table.state": "Fotomat status",
  "storeList.table.partner": "Partner",
  "storeList.table.company": "Company",
  "storeList.table.printedPhotos": "Printed Photos",
  "storeList.table.placement": "Location",
  "storeList.table.pricingLevel": "Pricing level",
  "storeList.table.actions": "Actions",
  "storeList.table.actions.detail": "Detail",
  "storeList.table.actions.remove": "Remove",
  "storeList.table.actions.removeWarning":
    "You are about to remove a company partner",
  "storeList.enabledToggle": "Store",
  "storeList.disabledToggle": "Stock",
  "storeList.assignModal.title": "Add store",
  "storeList.assignModal.cancel": "CANCEL",
  "storeList.assignModal.partner": "Partner",
  "storeList.assignModal.operation": "Store",
  "storeList.addModal.add": "ADD",
  "storeList.addModal.title": "Add store",
  "storeList.addOperation.title": "New store",
  "storeList.operator.name": "Operator name",
  "storeList.partner.name": "Partner name",
  "storeList.company.name": "Company name",
  "storeList.table.addStore": "Create store",
  "storeList.table.allStore": "Stores list",
  "storeList.online": "Online",
  "storeList.offline": "Offline",
  "storeList.withoutDevice": "Without fotomat",
  "storeList.error": "Error",
  "storeList.addStore.title": "New store",
  "storeList.addStore.success": "Store {0} was created",
  "storeDetail.campaignCard.title": "Campaigns",
  "storeDetail.title": "Store",
  "storeDetail.titleName": "Store name",
  "storeDetail.category": "Category",
  "storeDetail.area": "Area of store(m2)",
  "storeDetail.status.operationState": "Store status",
  "storeDetail.status.lastHeartbeat": "Latest heartbeat",
  "storeDetail.status.remainingPhotoCount": "Remaining photos",
  "storeDetail.status.printerCounter": "Printer counter",
  "storeDetail.status.photosPerMonth": "Photos per month",
  "storeDetail.status.restart": "RESTART",
  "storeDetail.status.deactivate": "DEACTIVATE",
  "storeDetail.status.reportError": "Report error",
  "storeDetail.status.activate": "Activate",
  "storeDetail.status.back": "Back",
  "storeDetail.status.restartModal.title": "Restart operation",
  "storeDetail.status.restartModal.text":
    "Do you want to restart the operation?",
  "storeDetail.status.deactivateModal.title": "Deactivate operation",
  "storeDetail.status.deactivateModal.text":
    "Do you want to deactivate the operation?",
  "storeDetail.status.activateModal.title": "Activate operation",
  "storeDetail.status.activateModal.text":
    "Do you want to activate the operation?",
  "storeDetail.partner.title": "Partner",
  "storeDetail.partner.printerPhotos": "Printer photo count",
  "storeDetail.partner.orderPhotos": "Order photo count",
  "storeDetail.partner.changePartner": "CHANGE PARTNER",
  "storeDetail.partner.removePartner": "REMOVE PARTNER",
  "storeDetail.partner.detailPartner": "PARTNER DETAIL",
  "storeDetail.partner.changePartnerModal.title": "Change partner",
  "storeDetail.partner.changePartnerModal.action": "CHANGE",
  "storeDetail.partner.removePartnerModal.title": "Remove partner",
  "storeDetail.partner.removePartnerModal.text":
    "Do you want to remove the partner?",
  "storeDetail.partner.removePartnerModal.action": "REMOVE",
  "storeDetail.partner.back": "Back",
  "storeDetail.orders.title": "Orders",
  "storeDetail.orders.ordersList": "Order list",
  "storeDetail.orders.table.id": "ID",
  "storeDetail.orders.table.date": "Date",
  "storeDetail.orders.table.volume": "Amount",
  "storeDetail.orders.table.actions": "Actions",
  "storeDetail.orders.table.actions.detail": "Detail",
  "storeDetail.provisions.title": "Commissions",
  "storeDetail.provisions.totalProvision": "Total commission",
  "storeDetail.provisions.provisionsList": "Commission list",
  "storeDetail.address.title": "Location address",
  "storeDetail.address.placeholder": "Enter address",
  "storeDetail.address.undefinedLocation": "Location not specified",
  "storeDetail.storeAddressCard.title": "Address",
  "storeDetail.storeAddressCard.address": "Street and number",
  "storeDetail.storeAddressCard.postal": "Postal",
  "storeDetail.storeAddressCard.city": "City",
  "storeDetail.storeAddressCard.cityPlaceholder": "Choose city...",
  "storeDetail.storeAddressCard.country": "Country",
  "storeDetail.storeAddressCard.latitude": "Latitude",
  "storeDetail.storeAddressCard.longtitude": "Longtitude",
  "storeDetail.partner.removePartnerSuccessful": "Partner removed successfuly",
  "storeDetail.partner.removePartnerFailed": "Failed to remove partner",
  "storeDetail.store.addStoreSuccessful":
    "Operation has been added successfuly",
  "storeDetail.partner.changePartnerFailed": "Failed to change partner",
  "storeDetail.status.operationDeactivationSuccessful":
    "Deactivated successfuly",
  "storeDetail.status.operationActivationSuccessful": "Activated successfuly",
  "storeDetail.status.operationDeactivationFailed": "Failed to deactivate",
  "storeDetail.status.operationActivationFailed": "Failed to activate",
  "storeDetail.status.operationRestartSuccessful": "Restarted successfuly",
  "storeDetail.status.operationRestartFailed": "Failed to restart",
  "storeDetail.partner.changeAddressFailed": "Failed to change address",
  "storeDetail.partner.changeAddressSuccessful": "Address changed successfuly",
  "storeDetail.store.updatedSuccessful": "Store has been updated",
  "storeDetail.openingHours.title": "Opening hours",
  "storeDetail.playtime.open": "Start of playing since open",
  "storeDetail.playtime.close": "End of playing before closure",
  "storeDetail.countAdSpots": "Number of campaign prints",
  "storeDetail.storeCard.name": "Store name",
  "storeDetail.storeCard.address": "Store address",
  "storeDetail.storeCard.category": "Category",
  "storeDetail.storeCard.area": "Store area (m2)",
  "storeDetail.storeCard.visitRate": "Visit rate (person/hour)",
  "storeDetail.storeCard.playlist": "DS Playlist",
  "storeDetail.storeCard.playlist.without": "Without playlist",
  "storeDetail.storeCard.isOnlyOwnCampaignAllowed":
    "Print only your own FreeFoto Campaigns",
  "storeDetail.storeCard.isOnlyOwnCampaignAllowed.on": "Own",
  "storeDetail.storeCard.isOnlyOwnCampaignAllowed.off": "All",
  "storeDetail.mediaCard.forbiddenTopics": "Forbidden Topics",
  "storeDetail.mediaCard.chooseForbiddenTopics": "Choose forbidden Topics...",
  "storeDetail.companyCard.title": "Company",
  "storeDetail.companyCard.name": "Company name",
  "storeDetail.companyCard.address": "Company address",
  "storeDetail.companyCard.contactPerson": "Name of contact person",
  "storeDetail.companyCard.phone": "Phone  (Format: +421903123123)",
  "storeDetail.companyCard.email": "E-mail",
  "storeDetail.partnerCard.firstName": "First name",
  "storeDetail.partnerCard.lastName": "Last name",
  "storeDetail.partnerCard.partnerAddress": `Partner's Adress`,
  "storeDetail.partnerCard.phone": "Phone  (Format: +421903123123)",
  "storeDetail.partnerCard.email": "E-mail",
  "storeDetail.partnerCard.addPartner": "Create partner",
  "storeDetail.partnerCard.allPartners": "Partners list",
  "storeDetail.partnerCard.allOrders": "Orders list",
  "storeDetail.partnerCard.allPayments": "All payments",
  "storeDetail.partnerCard.allOperations": "All operations",
  "storeDetail.maintainer.title": "Operator",
  "storeDetail.removeStore.text": "Do you really want to remove the store",
  "storeDetail.contactPerson.title": "Contact person",
  "storeDetail.billing.title": "Billing information",
  "storeDetail.removeStore.title": "Remove store",
  "storeDetail.removePlayer.title": "Remove fotomat",
  "storeDetail.removePlayer.successful": "Fotomat was removed successfully",
  "storeDetail.removeStore.successful": "Store was removed successfully",
  "storeDetail.changePlayer.text-1": "To the store ",
  "storeDetail.changePlayer.text-2": "is assigned fotomat",
  "storeDetail.changePlayer.text-3": "Choose new fotomat",
  "storeDetail.mediaCard.fileName": "File name",
  "storeDetail.maintainerCard.title": "Contact person",
  "storeDetail.maintainerCard.firstName": "Name",
  "storeDetail.maintainerCard.lastName": "Surname",
  "storeDetail.maintainerCard.phone": "Phone number",
  "storeDetail.maintainerCard.email": "E-mail",
  "storeDetail.device.remove": "Remove",
  "storeDetail.commissionCard.allCommissions": "All commissions: ",
  "storeDetail.commissionCard.button": "Commission list ",
  "storeDetail.commissionCard.title": "Commissions",
  "storeDetail.commissionCard.com": "Commissions",
  "storeDetail.printedPhotosCard.title":
    "The number of photos printed in the last 30 days",
  "storeDetail.printedPhotosCard.allPhotos": "Total",
  "storeDetail.printedPhotosCard.threshold": "Commission level threshold",
  "storeDetail.printedPhotosCard.photos": "Printed photos",
  "storeDetail.printedPhotosCard.day": "Day",
  "storeDetail.deviceStatusCard.title": "Fotomat",
  "storeDetail.deviceStatusCard.ID": "ID",
  "storeDetail.deviceStatusCard.status": "Fotomat status",
  "storeDetail.deviceStatusCard.lastHeartbeat": "Last heartbeat",
  "storeDetail.deviceStatusCard.numberOfPhotos":
    "The remaining number of photos",
  "storeDetail.deviceStatusCard.printerCounter": "Printer counter",
  "storeDetail.deviceStatusCard.averagePhotos":
    "average number of photos per month",
  "storeDetail.deviceStatusCard.isEnabled": "Connection status",
  "storeDetail.deviceStatusCard.fotomatDetail": "Fotomat detail",
  "storeDetail.deviceStatusCard.wifiSsid": "SSID",
  "storeDetail.deviceStatusCard.wifiPassword": "Wifi password",
  "storeDetail.deviceStatusCard.connection": "Fotomat connection",
  "storeDetail.deviceStatusCard.connection.ethernet": "Ethernet connection",
  "storeDetail.deviceStatusCard.connection.wifi": "Wifi connection",
  "storeDetail.deviceStatusCard.connection.mobile": "Mobile connection",
  "storeDetail.deviceStatusCard.connectionStatus.InUse": "In Use",
  "storeDetail.deviceStatusCard.connectionStatus.Connected": "Ready",
  "storeDetail.deviceStatusCard.connectionStatus.Disconnected": "Disconnected",
  "storeDetail.deviceStatusCard.connectionStatus.Unavailable": "Unavailable",
  "storeDetail.deviceStatusCard.connectionStatus.Unknown": "Unknown",

  "storeDetail.openingHours.open": "Open",
  "storeDetail.openingHours.closed": "Closed",
  "storeDetail.openingHours.holiday": "Open during holidays",
  "storeDetail.openingHours.specialClosed": "Special closed",
  "storeDetail.openingHours.spotsPlaybackStartOffset":
    "Start playback after opening",
  "storeDetail.openingHours.spotsPlaybackEndOffset":
    "End playback before closing",

  "storeDetail.openingHours.powerSavingMode": "Action when the Store is closed",
  "storeDetail.openingHours.powerSavingMode.ScreenPowerOff": "Screen power off",
  "storeDetail.openingHours.powerSavingMode.SystemPowerOff": "System power off",
  "storeDetail.openingHours.powerSavingMode.None": "No action",
};

const orders = {
  "orderList.title": "Orders",
  "orderList.table.title": "Orders list",
  "orderList.table.ID": "ID",
  "orderList.table.status": "Status",
  "orderList.table.paid": "Paid",
  "orderList.isPaid.yes": "Paid",
  "orderList.isPaid.no": "Unpaid",
  "orderList.table.created": "Created",
  "orderList.table.notCreated": "Not created",
  "orderList.table.email": "Email",
  "orderList.table.createdAt": "Created At",
  "orderList.table.expiration": "Expiration",
  "orderList.table.credit": "Credits",
  "orderList.table.type": "Type",
  "orderList.table.creditType": "Credit",
  "orderList.table.freeFotoType": "FreeFoto",
  "orderList.table.creditAndPaymentType": "Credit + Payment",
  "orderList.table.paymentType": "Payment",
  "orderList.table.actions": "Actions",
  "orderList.table.actions.detail": "Detail",
  "orderList.table.purchaseAmount": "Amount",
  "orderDetail.title": "Order",
  "orderDetail.cancelModal.title": "You really want to cancel your order?",
  "orderDetail.cancelModal.button": "Cancel order",
  "orderDetail.cancel.button": "Cancel order",
  "orderDetail.statusCard.title": "Order price list",
  "orderDetail.paymentCard.title": "Order status",
  "orderDetail.paymentCard.credit": "Paid with credits from application",
  "orderDetail.paymentCard.payment": "Paid by payment in euros",
  "orderDetail.paymentCard.summary": "Total paid",
  "orderDetail.paymentCard.type": "Order Type",
  "orderDetail.statusCard.firstPhotoCount": "Number of photos 10x15",
  "orderDetail.statusCard.secondPhotoCount": "Number of photos 15x15",
  "orderDetail.statusCard.thirdPhotoCount": "Number of photos 20x15",
  "orderDetail.statusCard.totalCount": "Total number of photos",
  "orderDetail.statusCard.totalCredits": "Order value",
  "orderDetail.statusCard.paidStatus": "Paid status",
  "orderDetail.customerCard.title": "Customer",
  "orderDetail.customerCard.name": "Name and Surname",
  "orderDetail.customerCard.email": "E-mail",
  "orderDetail.customerCard.phone": "Phone",
  "orderDetail.customerCard.userDetail": "User detail",
  "orderDetail.historyCard.title": "Order History",
  "orderDetail.historyCard.Created": "Created",
  "orderDetail.historyCard.Processing": "Processing",
  "orderDetail.historyCard.Ready": "Waiting for print",
  "orderDetail.historyCard.InProgress": "Printing",
  "orderDetail.historyCard.Completed": "Completed",
  "orderDetail.historyCard.Incomplete": "Incomplete",
  "orderDetail.historyCard.Partial": "Partially completed",
  "orderDetail.historyCard.Expired": "Expired",
  "orderDetail.historyCard.Canceled": "Canceled",
  "orderDetail.removeOrder.successful": "Order was canceled",
};

const invoices = {
  "invoicesList.title": "Invoices",
  "invoicesList.enabledToggle": "Invoices",
  "invoicesList.disabledToggle": "Payouts",
  "invoicesList.table.title": "Invoices and Payouts",
  "invoicesList.table.ID": "ID",
  "invoicesList.table.identifier": "Identifier",
  "invoicesList.table.company": "Company",
  "invoicesList.table.description": "Description",
  "invoicesList.table.price": "Price",
  "invoicesList.table.credit": "Credits",
  "invoicesList.table.date": "Date",
  "invoicesList.table.createdBy": "Created By",
  "invoicesList.addModal.title": "PAIR THE INVOICE",
  "invoicesList.assignModal.cancel": "CANCEL",
  "invoicesList.assignModal.title": "Pair the Invoice",
  "invoicesList.assignModal.company": "Company",
  "invoicesList.assignModal.number": "Invoice number",
  "invoicesList.assignModal.price": "Price",
  "invoicesList.assignModal.credit": "Credits",
  "invoicesList.assignModal.description": "Description",
  "invoicesList.assignModal.identifier": "Identifier",
  "invoicesList.createInvoiceSuccessfull": "Invoice was successfully created",
  "invoicesList.assignModal.create": "Create",
  "invoiceDetail.widget.title": "Payment",
  "invoiceDetail.title": "Invoice",
  "invoiceDetail.price": "Price payment",
  "invoiceDetail.credit": "Volume of credits",
  "invoiceDetail.ID": "Payment ID",
  "invoiceDetail.identifier": "Payment number",
  "invoiceDetail.type": "Payment type",
  "invoiceDetail.description": "Description",
  "invoiceDetail.createdBy": "Created By",
  "invoiceDetail.createdAt": "Created At",
  "invoiceDetail.phone": "Phone",
};

const payouts = {
  "payoutsList.title": "Payouts",
  "payoutsList.table.ID": "ID",
  "payoutsList.table.company": "Company",
  "payoutsList.table.description": "Description",
  "payoutsList.table.price": "Price",
  "payoutsList.table.credit": "Credits",
  "payoutsList.table.date": "Date",
  "payoutsList.table.createdBy": "Created By",
  "payoutsList.addModal.title": "CREATE PAYOUT",
  "payoutsList.assignModal.title": "Create Payout",
  "payoutsList.assignModal.company": "Company",
  "payoutsList.assignModal.price": "Price",
  "payoutsList.assignModal.credit": "Credits",
  "payoutsList.assignModal.description": "Description",
  "payoutsList.assignModal.identifier": "Identifier",
  "payoutsList.createPayoutSuccessfull": "Payout was successfully created",
  "payoutsList.assignModal.create": "Create",
  "payoutDetail.title": "Payout",
};

const media = {
  "media.page.title": "Media list",
  "media.page.header": "Media",
  "media.page.info": "Upload picture from your local storage here",
  "media.uploadModal.title": "Upload file",
  "media.uploadModal.cancelButton": "Cancel",
  "media.uploadModal.confirmButton": "Upload ",
  "media.uploadModal.name": "File name",
  "media.uploadModal.company": "Company",
  "media.uploadModal.chooseCompany": "Choose company...",
  "media.uploadModal.category": "Category",
  "media.uploadModal.chooseCategory": "Choose category...",
  "media.uploadModal.success": "File was successfully uploaded",
  "media.table.approved": "Approved",
  "media.table.pending": "Pending",
  "media.table.rejected": "Rejected",
  "media.table.approvedButton": "Approve",
  "media.table.rejectedButton": "Reject",
  "media.table.deletedButton": "Delete",
  "media.table.approvedModalTitle": "Do you want to approve this file?",
  "media.table.rejectedModalTitle": "Do you want to reject this file?",
  "media.table.deletedModalTitle": "Do you want to delete this file?",
  "media.table.successApprove": "File approved successfully",
  "media.table.successReject": "File rejected successfully",
  "media.table.successDelete": "File deleted successfully",
  "media.table.id": "ID",
  "media.table.date": " Date",
  "media.table.status": "Status",
  "media.table.name": "Name",
  "media.table.owner": "Owner",
  "media.table.category": "Category",
  "media.table.duration": "Duration",
  "media.table.reason": "Reason",
  "media.table.size": "Size",
  "media.table.actions": "Actions",
  "media.page.uploadButton": "Upload file",
  "media.table.category.FreePhotoAdvertisement": "FreeFoto banner",
  "media.table.category.SignageAdvertisement": "Signage banner",
  "media.table.category.FreePhotoWatermark": "FreeFoto Watermark",
  "media.table.status.pending": "Pending",
  "media.table.status.uploaded": "Uploaded",
  "media.table.status.processing": "Processing",
  "media.table.status.processed": "Processed",
  "media.table.status.invalid": "Invalid",
  "media.table.status.failed": "Failed",
  "media.table.rejectedReason": "Write a reason for rejection",
  "media.page.badResolutinPicture":
    "You have selected an incorrect resolution image. Please select another image",
};

const operations = {
  "operationsList.title": "Activities",
  "operationsList.table.title": "List of Activities",
  "operationsList.table.ID": "ID",
  "operationsList.table.date": "Date",
  "operationsList.table.sourceID": "Source ID",
  "operationsList.table.operation": "Operation type",
  "operationsList.table.destinationID": "Destination ID",
  "operationsList.table.price": "Price",
};

const payments = {
  "paymentsList.title": "Payments",
  "paymentsList.table.title": "Payments list",
  "paymentsList.table.ID": "ID",
  "paymentsList.table.type": "Type",
  "paymentsList.table.value": "Value",
  "paymentsList.table.price": "Price",
  "paymentsList.table.date": "Date",
  "paymentsList.table.purpose": "Purpose",
  "paymentsList.table.user": "User",
  "paymentsList.table.operationID": "Operation",
  "paymentsList.table.payoutID": "Payout ID",
  "paymentsList.table.type.GooglePay": "Google Pay",
  "paymentsList.table.type.ApplePay": "Apple Pay",
  "paymentsList.table.type.CreditCard": "Credit Card",
  "paymentsList.table.purpose.OrderPayment": "Order payment",
  "paymentsList.table.purpose.Subscription": "Subscription",
  "paymentsList.table.status": "Status",
  "paymentsList.status.Pending": "Pending",
  "paymentsList.status.Processing": "Processing",
  "paymentsList.status.Succeeded": "Succeeded",
  "paymentsList.status.Expired": "Expired",
};

const commissions = {
  "commissionsList.title": "Commissions",
  "commissionsList.table.title": "Commissions List",
  "commissionsList.addModal.title": "EXPORT",
  "commissionsList.table.commissionID": "Commission ID",
  "commissionsList.table.companyID": "Company ID",
  "commissionsList.table.companyName": "Company",
  "commissionsList.table.stores": "Stores",
  "commissionsList.table.email": "Email",
  "commissionsList.table.date": "Period",
  "commissionsList.table.credit": "Credit",
  "commissionsList.table.margin": "Margin",
  "commissionsList.table.price": "Price",
  "commissionsList.table.actions": "Actions",
  "commissionsList.table.storeID": "Store ID ",
  "commissionsList.table.storeName": "Store name",
  "commissionsList.table.address": "Address",
  "commissionsList.table.ID": "ID",
  "commissionList.table.subtitle": "Completely",
  "commissionsList.assignModal.title": "Data Export",
  "commissionsList.assignModal.pickerStart": "Start Month: ",
  "commissionsList.assignModal.pickerEnd": "End Month: ",
  "commissionsList.assignModal.monthFrom": "Choose start month",
  "commissionsList.assignModal.monthTo": "Choose end month",
  "commissionsList.table.pickerStart": "Start: ",
  "commissionsList.table.pickerEnd": "End: ",
  "commissionsList.month.january": "January",
  "commissionsList.month.february": "February",
  "commissionsList.month.march": "March",
  "commissionsList.month.april": "April",
  "commissionsList.month.may": "May",
  "commissionsList.month.june": "June",
  "commissionsList.month.july": "July",
  "commissionsList.month.august": "August",
  "commissionsList.month.september": "September",
  "commissionsList.month.october": "October",
  "commissionsList.month.november": "November",
  "commissionsList.month.december": "December",
  "commissionsList.export.success":
    "Commissions was succesfully exproted to csv file",
  "commissionDetail.title": "Company commissions",
  "commissionDetail.photo.title": "Photos",
  "commissionDetail.photo.body": "Total number of photos",
  "commissionDetail.commission.title": "Commission",
  "commissionDetail.commission.body": "Total Commissions",
  "commissionDetail.margin.title": "Margin",
  "commissionDetail.margin.body": "Average margin",
};

const profilePage = {
  "profile.contactInfo.title": "Contact information",
  "profile.contactInfo.firstName": "Name",
  "profile.contactInfo.lastName": "Surname",
  "profile.contactInfo.gender": "Gender",
  "profile.contactInfo.email": "Email",
  "profile.contactData.title": "Contact information",
  "profile.contactData.phone": "Phone",
  "profile.contactData.email": "Email",
  "profile.bankData.title": "Bank information",
  "profile.bankData.bic": "BIC",
  "profile.bankData.iban": "IBAN",
  "profile.bankData.swift": "BIC/SWIFT",
  "profile.bankData.bankName": "Bank",
  "profile.changePassword.title": "Password change",
  "profile.changePassword.oldPassword": "Current password",
  "profile.changePassword.newPassword": "New password",
  "profile.changePassword.verifyPassword": "Repeat new password",
  "profile.changePassword.change": "Change",
  "profile.identificators.title": "Company identification",
  "profile.identificators.ico": "Business ID",
  "profile.identificators.dic": "Tax ID",
  "profile.identificators.icDph": "VAT number",
  "profile.identificators.registerCode": "Court of Registry",
  "profile.companyData.title": "Company information",
  "profile.companyData.name": "Company name",
  "profile.companyData.street": "Street",
  "profile.companyData.city": "City",
  "profile.companyData.postal": "ZIP code",
  "profile.companyData.country": "Country",
  "profile.language": "Language",
  "profile.language.success": "Jazyková preferencia bola uložená",
  "profile.password.empty": "You have not entered a password",
  "profile.password.success": "Password changed successfully",
  "profile.password.failed": "The password has not been changed",
  "profile.password.notEquals": "Passwords do not match",
};

const userRoles = {
  "userRoles.partner": "Partner",
  "userRoles.maintainer": "Maintainer",
  "userRoles.advertiser": "Advertiser",
  "userRoles.user": "User",
  "userRoles.admin": "Administrator",
};

const loginPage = {
  "loginPage.cardTitle": "Login",
  "loginPage.email": "Email",
  "loginPage.password": "Password",
  "loginPage.submit": "Login",
  "loginPage.stayLogged": "Stay logged",
  "loginPage.forgottenPassword": "Forgotten password",
  "loginPage.forgottenPassword.email": "Your email",
  "loginPage.forgottenPassword.infoText":
    "Enter e-mail of your account and we will send you a link to reset your password.",
  "loginPage.forgottenPassword.cancel": "Cancel",
  "loginPage.forgottenPassword.submit": "Send",
  "loginPage.forgottenPassword.success":
    "An email with link to reset password was sent.",
  "loginPage.login.success": "Login has been successful",
};

const notificationsPage = {
  "notificationsPage.title": "Notifications",
  "notificationsPage.create": "Create notification",
  "notificationsPage.table.title": "Notifications list",
  "notificationsPage.table.id": "ID",
  "notificationsPage.table.date": "Date",
  "notificationsPage.table.author": "Author",
  "notificationsPage.table.header": "Header",
  "notificationsPage.table.text": "Body",
  "notificationsPage.modal.title": "New notification",
  "notificationsPage.modal.header": "Header",
  "notificationsPage.modal.text": "Body",
  "notificationsPage.modal.screen": "Screen",
  "notificationsPage.modal.submit": "Send",
  "notificationsPage.modal.cancel": "Back",
  "notificationsPage.create.success": "Notification sended",
  "notificationsPage.modal.confirm.title": "Confirmation",
  "notificationsPage.modal.confirm.text":
    "You are about to send a notification",
  "notificationsPage.modal.confirm.submit": "Send",
  "notificationsPage.modal.confirm.cancel": "Back",

  "notificationsPage.new.title": "New notification",
  "notificationsPage.new.basicInfoCard.title": "Basic information filtering",
  "notificationsPage.new.activityInfoCard.title":
    "Filter user activity information",
  "notificationsPage.new.notificationCard.title": "New notification",
  "notificationsPage.new.basicInfoCard.gender": "Gender",
  "notificationsPage.new.basicInfoCard.age": "Age (from - to)",
  "notificationsPage.new.basicInfoCard.dayOfBirdth": "Day and month of birth",
  "notificationsPage.new.basicInfoCard.emails": "Emails list",
  "notificationsPage.new.basicInfoCard.firstNames": "Firstnames List",
  "notificationsPage.new.activityInfoCard.registrationType":
    "Registration type",
  "notificationsPage.new.activityInfoCard.lastOrder": "Last order",
  "notificationsPage.new.activityInfoCard.turnover":
    "Total turnover (Minimum limit in €)",
  "notificationsPage.new.activityInfoCard.subscriptionType":
    "Subscription type",
  "notificationsPage.new.activityInfoCard.creditStatus":
    "Credit account balance (Upper limit in €)",
  "notificationsPage.new.activityInfoCard.isPrintedFreeFoto":
    "Already printed by FreeFoto?",
  "notificationsPage.new.notificationCard.delay":
    "Delay in sending a notification",
  "notificationsPage.new.notificationCard.delay.on": "On",
  "notificationsPage.new.notificationCard.delay.off": "Off",
  "notificationsPage.new.notificationCard.sendDate":
    "Date of send notification",
  "notificationsPage.new.button.calculate": "Calculate",
  "notificationsPage.new.button.create": "Create notification",
  "notificationsPage.new.modal.totalUsers": "Number of addressed users",
  "notificationsPage.new.modal.sendDate": "Date of send notification",
  "notificationsPage.new.options.yes": "Yes",
  "notificationsPage.new.options.no": "No",
  "notificationsPage.new.options.noImportant": "Does not matter",
  "notificationsPage.new.options.male": "Man",
  "notificationsPage.new.options.female": "Woman",
  "notificationsPage.new.options.withoutGender": "Not specified",
  "notificationsPage.new.options.withoutSubscription": "Without subscription",
  "notificationsPage.new.options.all": "All",

  "notificationsPage.automatic.title": "Automatic notifications",
  "notificationsPage.allCards.sendNotifications":
    "Automatic sending of notifications",
  "notificationsPage.allCards.sendNotifications.on": "On",
  "notificationsPage.allCards.sendNotifications.off": "Off",
  "notificationsPage.allCards.mutations":
    "SK/EN language versions of notifications",
  "notificationsPage.allCards.headerSK": "Header (SK)",
  "notificationsPage.allCards.bodySK": "Body (SK)",
  "notificationsPage.allCards.headerEN": "Header (EN)",
  "notificationsPage.allCards.bodyEN": "Body (EN)",

  "notificationsPage.expirationSubscriptionCard.title":
    "Subscription expiration",
  "notificationsPage.cancelSubscriptionCard.title":
    "Termination of subscription",
  "notificationsPage.expirationPaymentCard.title": "Payment expiration",
  "notificationsPage.expirationOrderCard.title": "Order expiration",
  "notificationsPage.successfulSubsRenewalCard.title":
    "Successful subscription renewal",
  "notificationsPage.unsuccessfulSubsRenewalCard.title":
    "Subscription renewal failed",
  "notificationsPage.printedPhotoCard.title":
    "Successfully printed first order",
  "notificationsPage.orderPaymentCard.title": "Surcharge for order",
  "notificationsPage.orderLockCard.title": "Order lock",

  "notificationsPage.expirationSubscriptionCard.label":
    "When the subscription expires, a notification is sent to the user N days before expiration (the number of days can be set)",
  "notificationsPage.cancelSubscriptionCard.label":
    "Upon termination of the subscription, a notification will be sent to the user N days before termination (the number of days can be set)",
  "notificationsPage.expirationPaymentCard.label":
    "When the subscription expires, a notification is sent to the user N minutes before expiration (the number of minutes can be set)",
  "notificationsPage.expirationOrderCard.label":
    "When the order expires, a notification will be sent to the user N days before the end (the number of days can be set)",
  "notificationsPage.successfulSubsRenewalCard.label":
    "When renewing a subscription, after a successful renewal",
  "notificationsPage.unsuccessfulSubsRenewalCard.label":
    "When renewing a subscription after a failed renewal",
  "notificationsPage.printedPhotoCard.label":
    "If the first credit order is successfully printed (not FreeFoto), the notification delay can be set",
  "notificationsPage.orderPaymentCard.label":
    "When making a surcharge for the order or the entire payment for the order",
  "notificationsPage.orderLockCard.label":
    "N minutes before order is locked, a notification will be sent to the user (number of minutes can be set)",

  "notificationsPage.expirationSubscriptionCard.days":
    "Number of days before the subscription expires",
  "notificationsPage.cancelSubscriptionCard.days":
    "Number of days before the subscription ends",
  "notificationsPage.expirationPaymentCard.minutes":
    "Number of minutes before payment expires",
  "notificationsPage.orderLockCard.minutes":
    "Number of minutes before order lock",
  "notificationsPage.expirationOrderCard.days":
    "Number of days before the expiration of the order",
  "notificationsPage.printedPhotoCard.delay": "Notification delay (Minutes)",

  "notificationsPage.delete.success": "Scheduled notification canceled",
  "notificationsPage.auto.update.success":
    "Automatic notification changes saved",
  "notificationsPage.modal.scheduled":
    "Immediately after pressing the `SEND` button",
  "notificationsPage.calculate.nobody": "Nobody",
  "notificationsPage.calculate.all": "All",
  "notificationsPage.new.button.calculateDisabled": "Addressed users",
  "notificationsPage.new.basicInfoCard.firstNamesHelper":
    "Enter your first name and confirm with `enter`",
  "notificationsPage.new.basicInfoCard.emailLabel":
    "Enter or copy email addresses and press `enter`",

  "notificationsPage.stepperLabel.filters": "Filter settings",
  "notificationsPage.stepperLabel.notifications": "Creating a notification",
  "notificationsPage.stepperLabel.confirm": "Confirmation of notification",
  "notificationsPage.stepperTitle.filters":
    "Set filters to better target notifications",
  "notificationsPage.stepperTitle.notifications":
    "Create the content of the notification",
  "notificationsPage.stepperTitle.confirm":
    "Please review and submit a notification",
  "notificationsPage.stepperTitle.title": "New notification",
  "notificationsPage.stepperTitle.continueButton": "Continue",
  "notificationsPage.table.history.title": "Notifications history",
  "notificationsPage.table.sending.title": "List of notifications to be sent",
  "notificationsPage.table.sendDate": "Sent",
  "notificationsPage.table.sendDateWill": "Sent",
};

const management = {
  "management.title": "Topics and categories",
  "management.topics.table.title":
    "List of topics used to create campaign content",
  "management.segments.table.title":
    "List of categories defining the store type",
  "management.table.name": "Name",
  "management.table.actions": "Actions",
  "management.table.actions.update": "Update",
  "management.table.actions.remove": "Delete",
  "management.topics.buttons.add": "Add topic",
  "management.segments.buttons.add": "Add category",
  "management.topics.createModal.title": "Create new topic",
  "management.segments.createModal.title": "Create new category",
  "management.topics.updateModal.title": "Change topic name",
  "management.segments.updateModal.title": "Change category name",
  "management.topics.removeModal.title":
    "Are you sure you want to delete topic?",
  "management.segments.removeModal.title":
    "Are you sure you want to delete category?",
  "management.topics.input.name": "Topic name",
  "management.segments.input.name": "Category name",
  "management.modalButton.create": "Create",
  "management.modalButton.update": "Change",
  "management.modalButton.remove": "Delete",
  "management.modalButton.cancel": "Back",
  "management.topics.snackbar.createSuccessfull": "Topic was created",
  "management.topics.snackbar.updateSuccessfull": "Topic was updated",
  "management.topics.snackbar.deleteSuccessfull": "Topic was deleted",
  "management.segments.snackbar.createSuccessfull": "Category was created",
  "management.segments.snackbar.updateSuccessfull": "Category was updated",
  "management.segments.snackbar.deleteSuccessfull": "Category was deleted",
};

const screenTypeEnums = {
  "screenType.Home": "Home",
  "screenType.Menu": "Menu",
  "screenType.Orders": "Orders",
  "screenType.Profile": "Profile",
  "screenType.Credits": "Credits",
  "screenType.Fotomats": "Fotomats",
};

const userStatuses = {
  "userStatus.activated": "Activated",
  "userStatus.unactivated": "Unactivated",
  "userStatus.blocked": "Blocked",
  "userSubscription.Nothing": "without subscription",
};

const deviceStatus = {
  "deviceStatus.offline": "Offline",
  "deviceStatus.online": "Online",
  "deviceStatus.error": "Error",
};

const pageHeader = {
  "pageHeader.back": "Back",
};

const tableTexts = {
  "tableTexts.rowsPerPage": "Rows per page",
  "tableTexts.of": "of",
  "tableTexts.moreThan": "more than",
  "tableTexts.piece": " pcs",
};

const userDevicesPage = {
  "userDevicesPage.title": "User's devices {0}",
};

const weekDay = {
  "weekDay.Monday": "Monday",
  "weekDay.Tuesday": "Tuesday",
  "weekDay.Wednesday": "Wednesday",
  "weekDay.Thursday": "Thursday",
  "weekDay.Friday": "Friday",
  "weekDay.Saturday": "Saturday",
  "weekDay.Sunday": "Sunday",
};

export default {
  ...apiErrors,
  ...userRoles,
  ...dashboard,
  ...defaultValidationErrors,
  ...settingsPage,
  ...userDetail,
  ...routes,
  ...usersPage,
  ...devices,
  ...media,
  ...invoices,
  ...orders,
  ...payouts,
  ...operations,
  ...payments,
  ...commissions,
  ...companies,
  ...campaigns,
  ...stores,
  ...profilePage,
  ...createUserPage,
  ...queryTable,
  ...table,
  ...loginPage,
  ...notificationsPage,
  ...screenTypeEnums,
  ...userStatuses,
  ...deviceStatus,
  ...pageHeader,
  ...tableTexts,
  ...userDevicesPage,
  ...management,
  ...weekDay,
};
