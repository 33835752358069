import gql from "graphql-tag";

const USER_DETAIL = gql`
  query user($id: ID!) {
    user(id: $id) {
      id
      bid
      firstName
      lastName
      fullName
      email
      birth
      gender
      age
      phone
      signInMethod
      createdAt
      role
      isActivated
      isBlocked
      ambassador {
        id
        type
        customerReward
        referralReward
        inheritSettings
        isEnabled
        qrcodeUrl
      }
      subscription {
        status
        validFrom
        validTo
        plan {
          id
          name
          price
          credit
        }
      }
      updatedAt
      primaryAccount {
        allocated
        balance
      }
      subscriptionAccount {
        allocated
        balance
      }
      stats {
        orders {
          totalCount
        }
        photos {
          printedCount
        }
      }
    }
  }
`;

export default USER_DETAIL;
