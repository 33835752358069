import React from 'react';
import PageHeader from 'components-lib/PageHeader/PageHeader';
import IconCard from 'components-lib/IconCard/IconCard';
import { Grid } from '@material-ui/core';
import { useState } from 'react';
import { useQuery, useMutation } from 'react-apollo';
import { Formik } from 'formik';
import { useEffect } from 'react';
import { isEmpty, pathOr } from 'rambda';
import FormikField from 'components-lib/FormikField/FormikField';
import Button from "components/CustomButtons/Button.js";
import { useSnackbar } from "notistack";
import { injectIntl } from 'react-intl';
import CreditIcon from 'components-lib/Icons/CreditIcon/CreditIcon';
import Loading from "components-lib/Loading/Loading";
import AUTO_NOTIFICATION_DETAIL from 'queries/NotificationsQueries/autoNotificationDetail';
import AUTO_NOTIFICATION_UPDATE from 'queries/NotificationsQueries/autoNotificationUpdate';
import NotificationScreenEnum from "common/enums/NotificationScreenEnum";
import { validationSchemaAuto } from "./validationSchema";

const SettingsPage = ({ intl, history }) => {

    const { data: allData1, refetch: refetch1 } = useQuery(AUTO_NOTIFICATION_DETAIL, { variables: { id: 1 } });
    const { data: allData2, refetch: refetch2 } = useQuery(AUTO_NOTIFICATION_DETAIL, { variables: { id: 2 } });
    const { data: allData3, refetch: refetch3 } = useQuery(AUTO_NOTIFICATION_DETAIL, { variables: { id: 3 } });
    const { data: allData4, refetch: refetch4 } = useQuery(AUTO_NOTIFICATION_DETAIL, { variables: { id: 4 } });
    const { data: allData5, refetch: refetch5 } = useQuery(AUTO_NOTIFICATION_DETAIL, { variables: { id: 5 } });
    const { data: allData6, refetch: refetch6 } = useQuery(AUTO_NOTIFICATION_DETAIL, { variables: { id: 6 } });
    const { data: allData7, refetch: refetch7 } = useQuery(AUTO_NOTIFICATION_DETAIL, { variables: { id: 7 } });
    const { data: allData8, refetch: refetch8 } = useQuery(AUTO_NOTIFICATION_DETAIL, { variables: { id: 8 } });
    const { data: allData9, loading: loading9, refetch: refetch9 } = useQuery(AUTO_NOTIFICATION_DETAIL, { variables: { id: 9 } });
    const [data1, setData1] = useState(null);
    const [data2, setData2] = useState(null);
    const [data3, setData3] = useState(null);
    const [data4, setData4] = useState(null);
    const [data5, setData5] = useState(null);
    const [data6, setData6] = useState(null);
    const [data7, setData7] = useState(null);
    const [data8, setData8] = useState(null);
    const [data9, setData9] = useState(null);

    const [updateAutoNotification] = useMutation(AUTO_NOTIFICATION_UPDATE);

    const { enqueueSnackbar } = useSnackbar();
    useEffect(() => {
        setData1(pathOr(null, ['automatedNotification'], allData1));
        setData2(pathOr(null, ['automatedNotification'], allData2));
        setData3(pathOr(null, ['automatedNotification'], allData3));
        setData4(pathOr(null, ['automatedNotification'], allData4));
        setData5(pathOr(null, ['automatedNotification'], allData5));
        setData6(pathOr(null, ['automatedNotification'], allData6));
        setData7(pathOr(null, ['automatedNotification'], allData7));
        setData8(pathOr(null, ['automatedNotification'], allData8));
        setData9(pathOr(null, ['automatedNotification'], allData9));
    }, [allData1, allData2, allData3, allData4, allData5, allData6, allData7, allData8, allData9]);

    const setNotificationsText = (text) => {
        let helpText = text
        var res1 = helpText.replace('@[@firstName](1)', '{firstName}');
        var res2 = res1.replace('@[@lastName](2)', '{lastName}');
        var resFinal = res2.replace('@[@balance](3)', '{balance}');
        return resFinal
    }

    const handleAutoNotificationUpdate = (values) => {
        let notificationId = values?.id
        const result = { ...values };
        delete result.id;
        delete result.createdAt;
        delete result.updatedAt;
        delete result.__typename;
        delete result?.triggers?.[0]?.__typename;

        let textSk = setNotificationsText(values?.mutations?.[0]?.text)
        let textEn = setNotificationsText(values?.mutations?.[1]?.text)
        updateAutoNotification({
            variables: {
                id: notificationId,
                input: {
                    action: {
                        screen: values?.action?.screen,
                    },
                    isEnabled: values?.isEnabled,
                    triggers: result?.triggers,
                    mutations: [
                        {
                            language: "sk",
                            title: values?.mutations?.[0]?.title,
                            text: textSk
                        },
                        {
                            language: "en",
                            title: values?.mutations?.[1]?.title,
                            text: textEn,
                        },
                    ]
                }
            }
        }).then((response) => {
            enqueueSnackbar(intl.formatMessage({ id: 'notificationsPage.auto.update.success' }), { variant: 'success' });
            refetch1();
            refetch2();
            refetch3();
            refetch4();
            refetch5();
            refetch6();
            refetch7();
            refetch8();
            refetch9();
        }).catch((err) => {
            console.log('[error]', err);
        })
    };

    const getMutationsFields = (props) => {
        return (
            <>
                <FormikField
                    type="select"
                    name="action.screen"
                    formikProps={props}
                    labelText={intl.formatMessage({ id: 'notificationsPage.modal.screen' })}
                    options={[
                        {
                            label: intl.formatMessage({ id: 'screenType.Home' }),
                            value: NotificationScreenEnum.HOME
                        },
                        {
                            label: intl.formatMessage({ id: 'screenType.Menu' }),
                            value: NotificationScreenEnum.MENU
                        },
                        {
                            label: intl.formatMessage({ id: 'screenType.Profile' }),
                            value: NotificationScreenEnum.PROFILE
                        },
                        {
                            label: intl.formatMessage({ id: 'screenType.Credits' }),
                            value: NotificationScreenEnum.CREDIT
                        },
                        {
                            label: intl.formatMessage({ id: 'screenType.Orders' }),
                            value: NotificationScreenEnum.ORDERS
                        },
                        {
                            label: intl.formatMessage({ id: 'screenType.Fotomats' }),
                            value: NotificationScreenEnum.FOTOMATS
                        },
                    ]}
                />
                <hr style={{ margintop: "10px", marginBottom: "10px" }} />
                <p>{intl.formatMessage({ id: 'notificationsPage.allCards.mutations' })}</p>
                <Grid container spacing={3}>
                    <Grid item sm={6}>
                        <FormikField
                            name="mutations.0.title"
                            labelText={intl.formatMessage({ id: 'notificationsPage.allCards.headerSK' })}
                            formikProps={props}
                            helperText={38 - pathOr('', ['values', 'mutations', [0], "title"], props).length}
                            highlightChange
                        />
                    </Grid>
                    <Grid item sm={6}>
                        <FormikField
                            name="mutations.1.title"
                            labelText={intl.formatMessage({ id: 'notificationsPage.allCards.headerEN' })}
                            formikProps={props}
                            helperText={38 - pathOr('', ['values', 'mutations', [1], "title"], props).length}
                            highlightChange
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item sm={6}>
                        <FormikField
                            type="placeholderText"
                            name="mutations.0.text"
                            formikProps={props}
                            labelText={intl.formatMessage({ id: 'notificationsPage.allCards.bodySK' })}
                            helperText={170 - pathOr('', ['values', 'mutations', [0], "text"], props).length}
                            inputProps={{ rows: 2 }}
                        />
                    </Grid>
                    <Grid item sm={6}>
                        <FormikField
                            type="placeholderText"
                            name="mutations.1.text"
                            formikProps={props}
                            labelText={intl.formatMessage({ id: 'notificationsPage.allCards.bodyEN' })}
                            helperText={170 - pathOr('', ['values', 'mutations', [1], "text"], props).length}
                            inputProps={{ rows: 2 }}
                        />
                    </Grid>
                </Grid>
                <Grid item sm={12}>
                    <br />
                    <Button
                        color="primary"
                        size="sm"
                        round
                        onClick={(e) => isEmpty(props.errors) && handleAutoNotificationUpdate(props.values)}
                    >
                        {intl.formatMessage({ id: "userDetail.buttons.confirm" })}
                    </Button>
                    <Button
                        color="info"
                        size="sm"
                        round
                        onClick={() => props.resetForm()}
                    >
                        {intl.formatMessage({ id: 'settings.cancel' })}
                    </Button>
                </Grid>
            </>
        )
    }
    const getSendNotificationSwitch = (props, name) => {
        return (
            <FormikField
                name="isEnabled"
                labelText={intl.formatMessage({ id: 'notificationsPage.allCards.sendNotifications' })}
                checkedLabelText={intl.formatMessage({ id: 'notificationsPage.allCards.sendNotifications.on' })}
                uncheckedLabelText={intl.formatMessage({ id: 'notificationsPage.allCards.sendNotifications.off' })}
                formikProps={props}
                type="switch"
                highlightChange
            />
        )
    }


    if (loading9) return <Loading />;
    return (
        <>
            <PageHeader
                title={intl.formatMessage({ id: 'notificationsPage.automatic.title' })}
                handleBackAction={(e) => history.goBack()}
            />


            <>
                <Grid container spacing={3}>
                    <Grid item md={6} lg={6} sm={12}>
                        <Formik
                            enableReinitialize
                            initialValues={data1}
                            validationSchema={validationSchemaAuto(intl)}
                        >
                            {props => (
                                <IconCard
                                    icon={<CreditIcon raw />}
                                    title={intl.formatMessage({ id: 'notificationsPage.expirationSubscriptionCard.title' })}
                                >
                                    <label>{intl.formatMessage({ id: 'notificationsPage.expirationSubscriptionCard.label' })}</label>
                                    <hr />
                                    {getSendNotificationSwitch(props, "expirationSubscription.send")}
                                    <FormikField
                                        name="triggers.0.time"
                                        labelText={intl.formatMessage({ id: 'notificationsPage.expirationSubscriptionCard.days' })}
                                        formikProps={props}
                                        highlightChange
                                    />
                                    {getMutationsFields(props, "expirationSubscription.days")}
                                </IconCard>
                            )}
                        </Formik>
                        <Formik
                            enableReinitialize
                            initialValues={data3}
                            validationSchema={validationSchemaAuto(intl)}
                        >
                            {props => (
                                <IconCard
                                    icon={<CreditIcon raw />}
                                    title={intl.formatMessage({ id: 'notificationsPage.unsuccessfulSubsRenewalCard.title' })}
                                >
                                    <label>{intl.formatMessage({ id: 'notificationsPage.unsuccessfulSubsRenewalCard.label' })}</label>
                                    <hr />
                                    {getSendNotificationSwitch(props, "unsuccessfulSubsRenewalCard.send")}
                                    {getMutationsFields(props)}
                                </IconCard>
                            )}
                        </Formik>

                        <Formik
                            enableReinitialize
                            initialValues={data4}
                            validationSchema={validationSchemaAuto(intl)}
                        >
                            {props => (
                                <IconCard
                                    icon={<CreditIcon raw />}
                                    title={intl.formatMessage({ id: 'notificationsPage.expirationOrderCard.title' })}
                                >
                                    <label>{intl.formatMessage({ id: 'notificationsPage.expirationOrderCard.label' })}</label>
                                    <hr />
                                    {getSendNotificationSwitch(props, "expirationOrder.send")}
                                    <FormikField
                                        name="triggers.0.time"
                                        labelText={intl.formatMessage({ id: 'notificationsPage.expirationOrderCard.days' })}
                                        formikProps={props}
                                        highlightChange
                                    />
                                    {getMutationsFields(props, "expirationOrder.days")}
                                </IconCard>
                            )}
                        </Formik>
                        <Formik
                            enableReinitialize
                            initialValues={data6}
                            validationSchema={validationSchemaAuto(intl)}
                        >
                            {props => (
                                <IconCard
                                    icon={<CreditIcon raw />}
                                    title={intl.formatMessage({ id: 'notificationsPage.orderPaymentCard.title' })}
                                >
                                    <label>{intl.formatMessage({ id: 'notificationsPage.orderPaymentCard.label' })}</label>
                                    <hr />
                                    {getSendNotificationSwitch(props, "orderPaymentCard.send")}
                                    {getMutationsFields(props)}
                                </IconCard>
                            )}
                        </Formik>

                        <Formik
                            enableReinitialize
                            initialValues={data9}
                            validationSchema={validationSchemaAuto(intl)}
                        >
                            {props => (
                                <IconCard
                                    icon={<CreditIcon raw />}
                                    title={intl.formatMessage({ id: 'notificationsPage.orderLockCard.title' })}
                                >
                                    <label>{intl.formatMessage({ id: 'notificationsPage.orderLockCard.label' })}</label>
                                    <hr />
                                    {getSendNotificationSwitch(props, "successfulSubsRenewalCard.send")}
                                    <FormikField
                                        name="triggers.0.time"
                                        labelText={intl.formatMessage({ id: 'notificationsPage.orderLockCard.minutes' })}
                                        formikProps={props}
                                        highlightChange
                                    />
                                    {getMutationsFields(props)}
                                </IconCard>
                            )}
                        </Formik>

                    </Grid>

                    {/* CENTER */}


                    <Grid item md={6} lg={6} sm={12}>
                        <Formik
                            enableReinitialize
                            initialValues={data2}
                            validationSchema={validationSchemaAuto(intl)}
                        >
                            {props => (
                                <IconCard
                                    icon={<CreditIcon raw />}
                                    title={intl.formatMessage({ id: 'notificationsPage.successfulSubsRenewalCard.title' })}
                                >
                                    <label>{intl.formatMessage({ id: 'notificationsPage.successfulSubsRenewalCard.label' })}</label>
                                    <hr />
                                    {getSendNotificationSwitch(props, "successfulSubsRenewalCard.send")}

                                    {getMutationsFields(props)}
                                </IconCard>
                            )}
                        </Formik>
                        <Formik
                            enableReinitialize
                            initialValues={data7}
                            validationSchema={validationSchemaAuto(intl)}
                        >
                            {props => (
                                <IconCard
                                    icon={<CreditIcon raw />}
                                    title={intl.formatMessage({ id: 'notificationsPage.cancelSubscriptionCard.title' })}
                                >
                                    <label>{intl.formatMessage({ id: 'notificationsPage.cancelSubscriptionCard.label' })}</label>
                                    <hr />
                                    {getSendNotificationSwitch(props, "cancelSubscription.send")}
                                    <FormikField
                                        name="triggers.0.time"
                                        labelText={intl.formatMessage({ id: 'notificationsPage.cancelSubscriptionCard.days' })}
                                        formikProps={props}
                                        highlightChange
                                    />
                                    {getMutationsFields(props)}
                                </IconCard>
                            )}
                        </Formik>
                        <Formik
                            enableReinitialize
                            initialValues={data5}
                            validationSchema={validationSchemaAuto(intl)}
                        >
                            {props => (
                                <IconCard
                                    icon={<CreditIcon raw />}
                                    title={intl.formatMessage({ id: 'notificationsPage.expirationPaymentCard.title' })}
                                >
                                    <label>{intl.formatMessage({ id: 'notificationsPage.expirationPaymentCard.label' })}</label>
                                    <hr />
                                    {getSendNotificationSwitch(props, "expirationPayment.send")}
                                    <FormikField
                                        name="triggers.0.time"
                                        labelText={intl.formatMessage({ id: 'notificationsPage.expirationPaymentCard.minutes' })}
                                        formikProps={props}
                                        highlightChange
                                    />
                                    {getMutationsFields(props, "expirationPayment.days")}
                                </IconCard>
                            )}
                        </Formik>

                        <Formik
                            enableReinitialize
                            initialValues={data8}
                            validationSchema={validationSchemaAuto(intl)}
                        >
                            {props => (
                                <IconCard
                                    icon={<CreditIcon raw />}
                                    title={intl.formatMessage({ id: 'notificationsPage.printedPhotoCard.title' })}
                                >
                                    <label>{intl.formatMessage({ id: 'notificationsPage.printedPhotoCard.label' })}</label>
                                    <hr />
                                    {getSendNotificationSwitch(props, "printedPhoto.send")}
                                    <FormikField
                                        name="triggers.0.time"
                                        labelText={intl.formatMessage({ id: 'notificationsPage.printedPhotoCard.delay' })}
                                        formikProps={props}
                                        highlightChange
                                    />
                                    {getMutationsFields(props, "expirationSubscription.days")}
                                </IconCard>
                            )}
                        </Formik>


                    </Grid>
                </Grid>
            </>

        </>
    );
}

export default injectIntl(SettingsPage);