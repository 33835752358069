import React from "react";
import MUICheckbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { colors } from "common/styles/configLayout";

const Item = ({ id, name, level, isChecked, isPartiallyChecked, nodeNamePreparer, onChange, }) => {
    const handleOnClick = (event) => {
        // onChange(id, !isChecked);
    };
    return (React.createElement(FormControlLabel, { control: React.createElement(MUICheckbox, { style: { color: colors.main }, checked: isChecked, indeterminate: !isChecked && isPartiallyChecked, onClick: handleOnClick }), label: nodeNamePreparer ? nodeNamePreparer(name, level) : name }));
};
export default Item;
