import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import EuroSymbolIcon from '@material-ui/icons/EuroSymbol';
import IconCard from "components-lib/IconCard/IconCard";
import React from "react";
import { injectIntl } from "react-intl";
import { withRouter } from 'react-router';
import Box from "@material-ui/core/Box";
import styles from "common/styles/widgets.js";
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';

const useStyles = makeStyles((theme) => styles(theme));

const OrderStatusCard = (props) => {
    const classes = useStyles();
    const {
        intl,
        isPaid,
        firstPhotoCount,
        secondPhotoCount,
        thirdPhotoCount,
        firstPhotoCredit,
        secondPhotoCredit,
        thirdPhotoCredit,
        totalCount,
        totalCredit,
    } = props;

    return (
        <>
            <IconCard
                title={intl.formatMessage({ id: "orderDetail.statusCard.title" })}
                className={classes.cardStyle}
                icon={<FormatListNumberedIcon />}
            >
                <Grid container spacing={2}>
                    <Grid item sm={12}>
                        {intl.formatMessage({ id: "orderDetail.statusCard.firstPhotoCount" })}
                        <Box className={classes.floatRight}>
                            {firstPhotoCredit} C
                        </Box>
                        <Box className={classes.floatRight} style={{ marginRight: "1.3em" }}>
                            {firstPhotoCount}{intl.formatMessage({ id: 'tableTexts.piece' })}
                        </Box>
                    </Grid>
                    <Grid item sm={12}>
                        {intl.formatMessage({ id: "orderDetail.statusCard.secondPhotoCount" })}
                        <Box className={classes.floatRight}>
                            {secondPhotoCredit} C
                        </Box>
                        <Box className={classes.floatRight} style={{ marginRight: "1.3em" }}>
                            {secondPhotoCount}{intl.formatMessage({ id: 'tableTexts.piece' })}
                        </Box>
                    </Grid>
                    <Grid item sm={12}>
                        {intl.formatMessage({ id: "orderDetail.statusCard.thirdPhotoCount" })}
                        <Box className={classes.floatRight}>
                            {thirdPhotoCredit} C
                        </Box>
                        <Box className={classes.floatRight} style={{ marginRight: "1.3em" }}>
                            {thirdPhotoCount}{intl.formatMessage({ id: 'tableTexts.piece' })}
                        </Box>
                    </Grid>
                    <Grid item sm={12}>
                        <hr syle={{ marginTop: "-1px", marginBottom: "1px" }} />
                        <b>{intl.formatMessage({ id: "orderDetail.statusCard.totalCount" })}
                            <Box className={classes.floatRight}>
                                {totalCount}{intl.formatMessage({ id: 'tableTexts.piece' })}
                            </Box></b>
                    </Grid>
                    <Grid item sm={12}>
                        <b>{intl.formatMessage({ id: "orderDetail.statusCard.totalCredits" })}
                            <Box className={classes.floatRight}>
                                {totalCredit} C
                            </Box></b>
                    </Grid>
                    {/*<Grid item sm={12}>
                        <b>{intl.formatMessage({ id: "orderDetail.statusCard.paidStatus" })}</b>
                        <Box className={classes.floatRight}>
                            {(isPaid)
                                ? <p style={{ color: "green" }}>{intl.formatMessage({ id: 'orderList.isPaid.yes' })}</p>
                                : <p style={{ color: "#9e9e9e" }}>{intl.formatMessage({ id: 'orderList.isPaid.no' })}</p>
                            }
                        </Box>
                    </Grid> */}
                </Grid>
            </IconCard>
        </>
    );
};
export default withRouter(injectIntl(OrderStatusCard));
