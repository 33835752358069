import { Grid } from "@material-ui/core";
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import FormikField from "components-lib/FormikField/FormikField";
import IconCard from "components-lib/IconCard/IconCard";
import React from "react";
import { injectIntl } from "react-intl";
import { withRouter } from "react-router-dom";

const NewMaintainerCard = (props) => {
  const { intl, loading, formikProps } = props;

  if (loading) return "Loading..."

  return (
    <>
      <>
        <IconCard
          title={intl.formatMessage({ id: "storeDetail.maintainer.title" })}
          icon={<AccountBoxIcon />}
        >
          <Grid container>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <FormikField
                  name="contactFirstName"
                  labelText={intl.formatMessage({ id: "storeDetail.partnerCard.firstName" })}
                  formikProps={formikProps}
                  highlightChange
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormikField
                  name="contactLastName"
                  labelText={intl.formatMessage({ id: "storeDetail.partnerCard.lastName" })}
                  formikProps={formikProps}
                  highlightChange
                />
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormikField
                name="contactPhone"
                labelText={intl.formatMessage({ id: "storeDetail.partnerCard.phone" })}
                formikProps={formikProps}
                highlightChange
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormikField
                name="contactEmail"
                labelText={intl.formatMessage({ id: "storeDetail.partnerCard.email", })}
                formikProps={formikProps}
                highlightChange
              />
            </Grid>
          </Grid>
        </IconCard>
      </>
    </>
  );
};

export default withRouter(injectIntl(NewMaintainerCard));
