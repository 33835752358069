import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import EuroSymbolIcon from '@material-ui/icons/EuroSymbol';
import IconCard from "components-lib/IconCard/IconCard";
import React from "react";
import { injectIntl } from "react-intl";
import { withRouter } from 'react-router';
import Box from "@material-ui/core/Box";
import styles from "common/styles/widgets.js";
import OrderTypeEnum from 'common/enums/OrderTypeEnum';

const useStyles = makeStyles((theme) => styles(theme));

const OrderStatusCard = (props) => {
    const classes = useStyles();
    const {
        intl,
        isPaid,
        totalCredit,
        type,
        apiType,
        credit,
        payment,
        paymentEur,
        paymentCredit,
        amount,
        totalPaid
    } = props;




    return (
        <>
            <IconCard
                title={intl.formatMessage({ id: "orderDetail.paymentCard.title" })}
                className={classes.cardStyle}
                icon={<EuroSymbolIcon />}
            >
                <Grid container spacing={2}>
                    <Grid item sm={12}>
                        {intl.formatMessage({ id: "orderDetail.paymentCard.type" })}
                        <Box className={classes.floatRight}>
                            {type}
                        </Box>
                    </Grid>
                    {(apiType !== OrderTypeEnum.FREEPHOTO) &&
                        <>
                            {(type !== intl.formatMessage({ id: 'orderList.table.paymentType' })) &&
                                <Grid item sm={12}>
                                    {intl.formatMessage({ id: "orderDetail.paymentCard.credit" })}
                                    <Box className={classes.floatRight}>
                                        {(type === intl.formatMessage({ id: 'orderList.table.creditAndPaymentType' }))
                                            ? (!isPaid)
                                                ? totalPaid + " C"
                                                : totalCredit - payment + " C"
                                            : totalPaid + " C"
                                        }
                                    </Box>
                                </Grid>
                            }
                            {(type !== intl.formatMessage({ id: 'orderList.table.creditType' })) &&
                                <Grid item sm={12}>
                                    {intl.formatMessage({ id: "orderDetail.paymentCard.payment" })}
                                    <Box className={classes.floatRight}>
                                        {(isPaid) ? amount + " € (" + payment + " C)" : "0 €"}
                                    </Box>
                                </Grid>
                            }
                            <Grid item sm={12}>
                                <hr syle={{ marginTop: "-1px", marginBottom: "1px" }} />
                                <b>{intl.formatMessage({ id: "orderDetail.paymentCard.summary" })}
                                    <Box className={classes.floatRight}>
                                        {(totalPaid) ? totalPaid : "0"} C / {totalCredit} C
                                    </Box></b>
                            </Grid>
                        </>
                    }
                    <Grid item sm={12}>
                        <b>{intl.formatMessage({ id: "orderDetail.statusCard.paidStatus" })}</b>
                        <Box className={classes.floatRight}>
                            {(isPaid)
                                ? <p style={{ color: "green" }}>{intl.formatMessage({ id: 'orderList.isPaid.yes' })}</p>
                                : <p style={{ color: "#9e9e9e" }}>{intl.formatMessage({ id: 'orderList.isPaid.no' })}</p>
                            }
                        </Box>
                    </Grid>
                </Grid>
            </IconCard>
        </>
    );
};
export default withRouter(injectIntl(OrderStatusCard));
