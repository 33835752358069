import gql from "graphql-tag";

const AMBASSADOR_COMMISSIONS_TABLE = gql`
  query ambassadorCommissionsTable(
    $offset: Int
    $limit: Int
    $sort: [AmbassadorCommissionsTableSortInput!]
    $filter: [AmbassadorCommissionsTableFilterInput!]
  ) {
    ambassadorCommissionsTable(
      offset: $offset
      limit: $limit
      sort: $sort
      filter: $filter
    ) {
      totalCount
      items {
        id
        period
        amount
        referrals
        exportFileUrl
        ambassadorId
      }
    }
  }
`;

export default AMBASSADOR_COMMISSIONS_TABLE;
