import React from 'react';
import { Grid } from '@material-ui/core';
import { injectIntl } from 'react-intl';
import ImageIcon from '@material-ui/icons/Image';
import IconCard from "components-lib/IconCard/IconCard";
import {
  BarChart, Bar, XAxis, YAxis, CartesianGrid,
  Tooltip, ResponsiveContainer, Legend
} from 'recharts';
import { chartColors } from "common/styles/configLayout.js";
import moment from "moment";
import RecursiveTreeView from "./Checkbox";

const PhotosCard = (props) => {
  const {
    intl,
    allPhotos,
    companies
  } = props;

  let data = []
  let sumPhotos = 0
  for (let i = 0; i < allPhotos?.length; i++) {
    let helpDate = moment(allPhotos[i]?.date).format("DD.MM.YYYY")
    let date = helpDate
    let ff = allPhotos[i]?.FreePhoto
    let f = allPhotos[i]?.S10x15
    let s = allPhotos[i]?.S15x15
    let t = allPhotos[i]?.S20x15

    data[i] = {
      name: date,
      freeFotos: ff,
      first: f,
      second: s,
      third: t,
      all: ff + f + s + t
    }
    sumPhotos += data[i].all
  }

  const legendData = [
    {
      id: 1,
      name: intl.formatMessage({ id: "dashboard.printedPhotosCard.freeFoto" }),
      color: chartColors.four
    },
    {
      id: 2,
      name: intl.formatMessage({ id: "dashboard.printedPhotosCard.first" }),
      color: chartColors.one
    },
    {
      id: 3,
      name: intl.formatMessage({ id: "dashboard.printedPhotosCard.second" }),
      color: chartColors.two
    },
    {
      id: 4,
      name: intl.formatMessage({ id: "dashboard.printedPhotosCard.third" }),
      color: chartColors.three
    },
  ]

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div style={{
          padding: "5px",
          borderStyle: "solid",
          borderWidth: "2px",
          borderColor: "gray",
          backgroundColor: "white"
        }}
        >
          <p className="label">{`${label}`}</p>
          <hr style={{ margin: "1px" }} />
          <p style={{ color: chartColors.four, margin: "1px" }}>{intl.formatMessage({ id: "dashboard.printedPhotosCard.freeFoto" })}: {payload[0].payload.freeFotos}</p>
          <p style={{ color: chartColors.one, margin: "1px" }}>{intl.formatMessage({ id: "dashboard.printedPhotosCard.first" })}: {payload[0].payload.first}</p>
          <p style={{ color: chartColors.two, margin: "1px" }}>{intl.formatMessage({ id: "dashboard.printedPhotosCard.second" })}: {payload[0].payload.second}</p>
          <p style={{ color: chartColors.three, margin: "1px" }}>{intl.formatMessage({ id: "dashboard.printedPhotosCard.third" })}: {payload[0].payload.third}</p>
          <hr style={{ margin: "1px" }} />
          <p>{intl.formatMessage({ id: "dashboard.printedPhotosCard.allPhotos" })}: {payload[0].payload.freeFotos + payload[0].payload.first + payload[0].payload.second + payload[0].payload.third}</p>
        </div>
      );
    }
    return null;
  };
  return (
    <>
      <IconCard
        title={intl.formatMessage({ id: "dashboard.printedPhotosCard.title" })}
        icon={<ImageIcon />}
      >
        <ResponsiveContainer width="100%" height={200}>
          <BarChart
            data={data}
            margin={{ top: 5, left: -25, right: 10, bottom: 15 }}
          >
            <CartesianGrid stroke="#f5f5f5" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip content={<CustomTooltip />} />
            <Bar name={intl.formatMessage({ id: "dashboard.printedPhotosCard.third" })} dataKey="third" stackId="a" barSize={25} fill={chartColors.three} />
            <Bar name={intl.formatMessage({ id: "dashboard.printedPhotosCard.second" })} dataKey="second" stackId="a" barSize={25} fill={chartColors.two} />
            <Bar name={intl.formatMessage({ id: "dashboard.printedPhotosCard.first" })} dataKey="first" stackId="a" barSize={25} fill={chartColors.one} />
            <Bar name={intl.formatMessage({ id: "dashboard.printedPhotosCard.freeFoto" })} dataKey="freeFotos" stackId="a" barSize={25} fill={chartColors.four} />

            <Legend
              wrapperStyle={{ bottom: -20 }}
              payload={
                legendData.map(
                  item => ({
                    id: item.id,
                    type: "square",
                    color: item.color,
                    value: item.name,
                  })
                )
              }
            />
          </BarChart>
        </ResponsiveContainer>
        <br />
        <hr />
        <Grid container >
          <Grid item sm={12}>
            <b>{intl.formatMessage({ id: "dashboard.printedPhotosCard.allPhotos" })}: {sumPhotos}{intl.formatMessage({ id: 'tableTexts.piece' })}</b>
          </Grid>
        </Grid>
      </IconCard>
    </>
  )
}

export default injectIntl(PhotosCard);
