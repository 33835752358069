import React from "react";
import { injectIntl } from "react-intl";
import { withRouter } from "react-router-dom";
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import IconCard from "components-lib/IconCard/IconCard";
import PageHeader from "components-lib/PageHeader/PageHeader";
import ALL_PAYMENTS from "queries/PaymentsQueries/allPayments";
import PaymentTable from "./PaymentTable";
import { useLocation } from 'react-router-dom';

const PaymentListPage = (props) => {
    const { intl } = props;
    const { history } = props;
    let location = useLocation();
    let defaultFilter = location.state ? location.state : null
    const renderOperationsTable = () => {
        return (
            <PaymentTable
                query={ALL_PAYMENTS}
                history={history}
                queryDataPath={['paymentsTable', 'items']}
                defaultFilter={defaultFilter}
            />
        );
    };

    return (
        <>
            <PageHeader
                title={intl.formatMessage({ id: "paymentsList.title" })}
                withBackButton={false}
            />
            <IconCard
                icon={<AccountBalanceWalletIcon />}
                title={intl.formatMessage({ id: "paymentsList.table.title" })}
            >
                {renderOperationsTable()}
            </IconCard>
        </>
    );
};

export default withRouter(injectIntl(PaymentListPage));