import IconCard from "components-lib/IconCard/IconCard";
import React from "react";
import { injectIntl } from "react-intl";
import CardMedia from '@material-ui/core/CardMedia';
import BrandingWatermarkIcon from '@material-ui/icons/BrandingWatermark';
import { defaultFFWatermarkUrl } from 'configFiles/config';

const DetailWatermarkCard = (props) => {
    const { intl, media } = props;

    return <>
        <IconCard
            title={"Vodotlač kampane"}
            icon={<BrandingWatermarkIcon />}
        >
            <CardMedia
                style={{ backgroundColor: '#F36E21' }}
                component="img"
                alt={(media?.watermarkUrl) ? media?.watermarkUrl : defaultFFWatermarkUrl}
                image={(media?.watermarkUrl) ? media?.watermarkUrl : defaultFFWatermarkUrl}
                title={(media?.watermarkUrl) ? media?.watermarkUrl : defaultFFWatermarkUrl}
            />
        </IconCard>
    </>
};

export default injectIntl(DetailWatermarkCard);
