import { Grid } from "@material-ui/core";
import StoreIcon from '@material-ui/icons/Store';
import FormikField from "components-lib/FormikField/FormikField";
import IconCard from "components-lib/IconCard/IconCard";
import React, { useEffect, useState } from "react";
import { injectIntl } from "react-intl";
import { withRouter } from "react-router-dom";
import ALL_SEGMENTS from "queries/StoresQueries/allSegments";
import { useQuery } from '@apollo/react-hooks';
import { pathOr } from 'rambda';

const NewStoreCard = (props) => {
    const { intl, formikProps } = props;

    const { data: segmentData } = useQuery(ALL_SEGMENTS);
    const [availableSegments, setAvailableSegments] = useState([]);

    useEffect(() => {
        const segments = pathOr([], ['segments', 'items'], segmentData).map(segment => {
            return {
                label: pathOr('', 'name', segment),
                value: pathOr('', 'id', segment)
            }
        })
        setAvailableSegments(segments);
    }, [segmentData])

    return (
        <>
            <IconCard
                title={intl.formatMessage({ id: "storeDetail.title" })}
                icon={<StoreIcon />}
            >
                <Grid container>
                    <Grid item sm={12}>
                        <FormikField
                            name="name"
                            labelText={intl.formatMessage({ id: "storeDetail.storeCard.name" })}
                            formikProps={formikProps}
                            highlightChange
                        />
                        <FormikField
                            name={"segmentId"}
                            labelText={intl.formatMessage({ id: "storeDetail.storeCard.category" })}
                            formikProps={formikProps}
                            type="select"
                            options={availableSegments}
                        />
                    </Grid>
                </Grid>
            </IconCard>
        </>
    );
};

export default withRouter(injectIntl(NewStoreCard));
