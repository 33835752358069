import gql from "graphql-tag";

const AMBASSADOR_REFERRALS_TABLE = gql`
  query ambassadorReferralsTable(
    $offset: Int
    $limit: Int
    $sort: [AmbassadorReferralsTableSortInput!]
    $filter: [AmbassadorReferralsTableFilterInput!]
  ) {
    ambassadorReferralsTable(
      offset: $offset
      limit: $limit
      sort: $sort
      filter: $filter
    ) {
      totalCount
      items {
        id
        claimedAt
        email
        type
        customerReward
        referralReward
        customerId
        ambassadorId
      }
    }
  }
`;

export default AMBASSADOR_REFERRALS_TABLE;
