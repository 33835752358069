import { gql } from "apollo-boost";

const AMBASSADOR_DISABLE = gql`
  mutation ambassadorDisable($userId: ID!) {
    ambassadorDisable(userId: $userId) {
      id
    }
  }
`;

export default AMBASSADOR_DISABLE;
