const OrderStatusEnum = {
    CREATED: "Created",
    PROCESSING: "Processing",
    READY: "Ready",
    INPROGRESS: "InProgress",
    COMPLETED: "Completed",
    INCOMPLETE: "Incomplete",
    PARTIAL: "Partial",
    EXPIRED: "Expired",
    CANCELED: "Canceled"
}

export default OrderStatusEnum;