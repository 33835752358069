import React from 'react';
import { Grid } from '@material-ui/core';
import { useState } from 'react';
import { useQuery } from 'react-apollo';
import { Formik } from 'formik';
import { useEffect } from 'react';
import { pathOr } from 'rambda';
import FormikField from 'components-lib/FormikField/FormikField';
import { validationSchemaFilter } from "../validationSchema";
import { injectIntl } from 'react-intl';
import STORES_SELECT from "queries/StoresQueries/storesSelect";

const Step1 = ({ intl, callback, callbackValid, initialData }) => {
 

  const genderOptions = [
    {
      label: intl.formatMessage({ id: 'notificationsPage.new.options.all' }),
      value: "AllNotifications"
    },
    {
      label: intl.formatMessage({ id: 'notificationsPage.new.options.female' }),
      value: 'Female'
    },
    {
      label: intl.formatMessage({ id: 'notificationsPage.new.options.male' }),
      value: 'Male'
    },
    {
      label: intl.formatMessage({ id: 'notificationsPage.new.options.withoutGender' }),
      value: 'Without'
    },
  ]

  const regTypeOptions = [
    {
      label: intl.formatMessage({ id: 'notificationsPage.new.options.all' }),
      value: "AllNotifications"
    },
    {
      label: "Google",
      value: 'Google'
    },
    {
      label: "Facebook",
      value: 'Facebook'
    },
    {
      label: "Apple ID",
      value: 'Apple'
    },
    {
      label: "Email",
      value: "Credentials"
    },
  ]

  const subTypeOptions = [
    {
      label: intl.formatMessage({ id: 'notificationsPage.new.options.all' }),
      value: "AllNotifications"
    },
    {
      label: "Single",
      value: 1
    },
    {
      label: "Family",
      value: 2
    },
    {
      label: intl.formatMessage({ id: 'notificationsPage.new.options.withoutSubscription' }),
      value: 0
    },
  ]

  const printPhotoOptions = [
    {
      label: intl.formatMessage({ id: 'notificationsPage.new.options.all' }),
      value: "AllNotifications"
    },
    {
      label: intl.formatMessage({ id: 'notificationsPage.new.options.yes' }),
      value: true
    },
    {
      label: intl.formatMessage({ id: 'notificationsPage.new.options.no' }),
      value: "No"
    },
  ]

  const [stores, setStores] = useState([]);


  const { data: storesData } = useQuery(STORES_SELECT);

  useEffect(() => {
    let storesSelectItems = pathOr([], "stores.items", storesData).map(
      (store) => {
        return {
          value: store?.zones?.[0]?.device?.id,
          label: `${pathOr("", "name", store)} (${pathOr("", "bid", store)})`,
        };
      }
    );
    storesSelectItems.unshift({
      label: intl.formatMessage({ id: 'notificationsPage.new.options.all' }),
      value: "AllNotifications"
    })
    setStores(storesSelectItems);
  }, [storesData, intl]);

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={initialData}
        validationSchema={validationSchemaFilter(intl)}
      >
        {props => (
          <>
            {callbackValid(props.isValid)}
            {callback(props.values)}
            <Grid container spacing={4}>
              <Grid item sm={6}>
                <FormikField
                  placeholder="Všetko"
                  name="criteria.genders"
                  labelText={intl.formatMessage({ id: 'notificationsPage.new.basicInfoCard.gender' })}
                  formikProps={props}
                  type="multiSelect"
                  highlightChange
                  options={genderOptions}
                />
                <Grid item> <br /> </Grid>
                <FormikField
                  name="criteria.ageRange"
                  labelText={intl.formatMessage({ id: 'notificationsPage.new.basicInfoCard.age' })}
                  formikProps={props}
                  type="doubleSlider"
                  minValueSlider={0}
                  maxValueSlider={100}
                  defaultValueSlider={50}
                  highlightChange
                />
                <Grid item> <br /> </Grid>
                <FormikField
                  name="criteria.birthDay"
                  labelText={intl.formatMessage({ id: 'notificationsPage.new.basicInfoCard.dayOfBirdth' })}
                  formikProps={props}
                  data={props.values}
                  format="MM/dd"
                  highlightChange
                  type="datePicker2"
                />
                <Grid item> <br /> </Grid>
                <FormikField
                  name="criteria.emails"
                  labelText={intl.formatMessage({ id: 'notificationsPage.new.basicInfoCard.emails' })}
                  formikProps={props}
                  type="emailParser"
                  highlightChange
                  helperText={intl.formatMessage({ id: 'notificationsPage.new.basicInfoCard.emailLabel' })}
                />

                <FormikField
                  name="criteria.names"
                  labelText={intl.formatMessage({ id: 'notificationsPage.new.basicInfoCard.firstNames' })}
                  formikProps={props}
                  type="chips"
                  highlightChange
                  helperText={intl.formatMessage({ id: 'notificationsPage.new.basicInfoCard.firstNamesHelper' })}
                />
                <Grid item> <br /> </Grid>
              </Grid>
              <Grid item sm={6}>

                <FormikField
                  name="criteria.registrations"
                  labelText={intl.formatMessage({ id: 'notificationsPage.new.activityInfoCard.registrationType' })}
                  formikProps={props}
                  type="multiSelect"
                  highlightChange
                  options={regTypeOptions}
                />
                <Grid item> <br /> </Grid>
                <FormikField
                  name="criteria.lastOrder"
                  labelText={intl.formatMessage({ id: 'notificationsPage.new.activityInfoCard.lastOrder' })}
                  formikProps={props}
                  data={props.values}
                  highlightChange
                  type="datePicker2"
                />
                <FormikField
                  name="criteria.revenue"
                  labelText={intl.formatMessage({ id: 'notificationsPage.new.activityInfoCard.turnover' })}
                  formikProps={props}
                  highlightChange
                />
                <Grid item> <br /> </Grid>
                <FormikField
                  name="criteria.subscriptionPlans"
                  labelText={intl.formatMessage({ id: 'notificationsPage.new.activityInfoCard.subscriptionType' })}
                  formikProps={props}
                  type="multiSelect"
                  options={subTypeOptions}
                  highlightChange
                />
                <Grid item> <br /> </Grid>
                <FormikField
                  name="criteria.printedAtFotomatIds"
                  labelText="Tlačené na vybraných kioskoch"
                  formikProps={props}
                  type="multiSelect"
                  options={stores}
                  highlightChange
                />
                <Grid item> <br /> </Grid>
                <FormikField
                  name="criteria.creditAccountBalance"
                  labelText={intl.formatMessage({ id: 'notificationsPage.new.activityInfoCard.creditStatus' })}
                  formikProps={props}
                  highlightChange
                />
                <Grid item> <br /> </Grid>
                <FormikField
                  name="criteria.usedFreePhoto"
                  labelText={intl.formatMessage({ id: 'notificationsPage.new.activityInfoCard.isPrintedFreeFoto' })}
                  formikProps={props}
                  type="select"
                  options={printPhotoOptions}
                  highlightChange
                />
              </Grid>
            </Grid>
          </>
        )}
      </Formik>
    </>
  );
}

export default injectIntl(Step1);