import { Grid } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import InfoIcon from '@material-ui/icons/Info';
import IconCard from "components-lib/IconCard/IconCard";
import React from "react";
import { injectIntl } from "react-intl";
import styles from "common/styles/widgets.js"

const useStyles = makeStyles((theme) => styles(theme));

const InfoCard = (props) => {
    const {
        intl,
        allCoupons,
        usedCoupons,
        allCredits,
        usedCredits,
        pqrType
    } = props;
    const classes = useStyles();

    return <IconCard
        title={intl.formatMessage({ id: 'campaigns.couponSetUse.title' })}
        icon={<InfoIcon />}
    >
        {(!pqrType) ?
            <Grid container spacing={2}>
                <Grid item sm={12}>
                    {intl.formatMessage({ id: 'campaigns.couponSetUse.couponsAllNumber' })}
                    <Box className={classes.floatRight}>
                        {allCoupons}{intl.formatMessage({ id: 'tableTexts.piece' })}
                    </Box>
                </Grid>
                <Grid item sm={12}>
                    {intl.formatMessage({ id: 'campaigns.couponSetUse.couponsUsedNumber' })}
                    <Box className={classes.floatRight}>
                        {usedCoupons}{intl.formatMessage({ id: 'tableTexts.piece' })}
                    </Box>
                </Grid>
                <Grid item sm={12}>
                    {intl.formatMessage({ id: 'campaigns.couponSetUse.couponsRemainigNumber' })}
                    <Box className={classes.floatRight}>
                        {allCoupons - usedCoupons}{intl.formatMessage({ id: 'tableTexts.piece' })}
                    </Box>
                    <hr />
                </Grid>
                <Grid item sm={12}>
                    {intl.formatMessage({ id: 'campaigns.couponSetUse.couponsAllCredits' })}
                    <Box className={classes.floatRight}>
                        {allCredits} C
                    </Box>
                </Grid>
                <Grid item sm={12}>
                    {intl.formatMessage({ id: 'campaigns.couponSetUse.couponsUsedCredits' })}
                    <Box className={classes.floatRight}>
                        {usedCredits} C
                    </Box>
                </Grid>
                <Grid item sm={12}>
                    {intl.formatMessage({ id: 'campaigns.couponSetUse.couponsRemainingCredits' })}
                    <Box className={classes.floatRight}>
                        {allCredits - usedCredits} C
                    </Box>
                </Grid>
            </Grid>
            :
            <Grid container spacing={2}>
                <Grid item sm={12}>
                    {intl.formatMessage({ id: 'campaigns.couponSetUse.pqrUse' })}
                    <Box className={classes.floatRight}>
                        {usedCoupons}
                    </Box>
                </Grid>
                <Grid item sm={12}>
                    {intl.formatMessage({ id: 'campaigns.couponSetUse.couponsUsedCredits' })}
                    <Box className={classes.floatRight}>
                        {usedCredits} C
                    </Box>
                </Grid>
            </Grid>
        }
    </IconCard>
};

export default injectIntl(InfoCard);
