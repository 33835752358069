import React, { useState, useEffect } from "react";
import { useQuery } from "@apollo/react-hooks";
import { injectIntl } from "react-intl";
import { withRouter } from "react-router-dom";
import PageHeader from "components-lib/PageHeader/PageHeader";
import EuroSymbolIcon from "@material-ui/icons/EuroSymbol";
import BurstModeIcon from "@material-ui/icons/BurstMode";
import LinkedCameraIcon from "@material-ui/icons/LinkedCamera";
import PeopleIcon from "@material-ui/icons/People";
import { Grid } from "@material-ui/core";
import { pathOr } from "rambda";
import IconCardOneItem from "components-lib/IconCard/IconCardOneItem";
import PhotosChart from "./DashboardCards/PhotosChart";
import UsersChart from "./DashboardCards/UsersChart";
import CreditsCard from "./DashboardCards/CreditsCard";
import DevicesCard from "./DashboardCards/DevicesCard";
import OVERVIEW from "queries/DashboardQueries/overview";
import { getApolloClient } from "configFiles/apollo";
import { useSnackbar } from "notistack";
import RoleEnum from "common/enums/RoleEnum";
import { isInRoles } from "helpers/helpers";
import SELECT_COMPANIES from "queries/CompaniesQueries/selectCompanies";
import FormikField from "components-lib/FormikField/FormikField";
import { Formik } from "formik";
import OfflineBoltIcon from "@material-ui/icons/OfflineBolt";
import PrintIcon from "@material-ui/icons/Print";
import Button from "components/CustomButtons/Button.js";
import CachedIcon from "@material-ui/icons/Cached";
import AmbassadorsScansChart from "./DashboardCards/AmbassadorsScansChart";

const DashboardPage = (props) => {
  const { intl, history } = props;
  const [overview, setOverview] = useState();
  const { enqueueSnackbar } = useSnackbar();
  const client = getApolloClient(enqueueSnackbar, history, intl);
  const { data: companiesData } = useQuery(SELECT_COMPANIES);
  const [companies, setCompanies] = useState([]);

  var storageCompanies = JSON.parse(localStorage.getItem("storageCompanies"))
    ? JSON.parse(localStorage.getItem("storageCompanies"))
    : [];

  const [companiesIds, setCompaniesIds] = useState(storageCompanies);

  const renderOverviewData = async (ids, snackbar) => {
    localStorage.setItem("storageCompanies", JSON.stringify(ids));
    setCompaniesIds(ids);

    try {
      const result = await client.query({
        query: OVERVIEW,
        variables: {
          input: {
            companyIds: ids[0] === "AllNotifications" ? [] : ids,
          },
        },
      });
      if (result.error) {
        throw result.error;
      }
      if (result.errors) {
        throw result.errors[0];
      }
      setOverview(result.data);
      snackbar &&
        enqueueSnackbar(
          intl.formatMessage({ id: "dashboard.calculate.success" }),
          { variant: "success" }
        );
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    if (isInRoles([RoleEnum.ADMIN, RoleEnum.MAINTAINER]))
      renderOverviewData([]);
    else renderOverviewData(companiesIds);
  }, []);

  useEffect(() => {
    let companiesSelectItems = pathOr([], "companies.items", companiesData).map(
      (company) => {
        return {
          value: pathOr("", "id", company),
          label: `${pathOr("", "name", company)}`,
        };
      }
    );

    companiesSelectItems.unshift({
      label: intl.formatMessage({ id: "notificationsPage.new.options.all" }),
      value: "AllNotifications",
    });
    setCompanies(companiesSelectItems);
  }, [companiesData, intl]);

  return (
    <>
      <PageHeader
        title={intl.formatMessage({ id: "dashboard.title" })}
        withBackButton={false}
      />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={6} lg={3}>
          {isInRoles([RoleEnum.ADMIN, RoleEnum.MAINTAINER]) ? (
            <IconCardOneItem
              icon={<EuroSymbolIcon />}
              headerText={intl.formatMessage({ id: "dashboard.allCredits" })}
              bodyText={
                pathOr("", ["overview", "credit", "soldAmount"], overview) +
                " C"
              }
            />
          ) : (
            <IconCardOneItem
              icon={<LinkedCameraIcon />}
              headerText={intl.formatMessage({ id: "dashboard.allDevices" })}
              bodyText={
                pathOr("", ["overview", "devices", "totalCount"], overview) +
                intl.formatMessage({ id: "tableTexts.piece" })
              }
            />
          )}
        </Grid>

        <Grid item xs={12} sm={12} md={6} lg={3}>
          {isInRoles([RoleEnum.ADMIN, RoleEnum.MAINTAINER]) ? (
            <IconCardOneItem
              icon={<LinkedCameraIcon />}
              headerText={intl.formatMessage({ id: "dashboard.allDevices" })}
              bodyText={
                pathOr("", ["overview", "devices", "totalCount"], overview) +
                intl.formatMessage({ id: "tableTexts.piece" })
              }
            />
          ) : (
            <IconCardOneItem
              icon={<OfflineBoltIcon />}
              headerText={intl.formatMessage({
                id: "dashboard.devicesCard.offlineDevices",
              })}
              bodyText={
                pathOr("", ["overview", "devices", "offlineCount"], overview) +
                intl.formatMessage({ id: "tableTexts.piece" })
              }
            />
          )}
        </Grid>

        <Grid item xs={12} sm={12} md={6} lg={3}>
          {isInRoles([RoleEnum.ADMIN, RoleEnum.MAINTAINER]) ? (
            <IconCardOneItem
              icon={<BurstModeIcon />}
              headerText={intl.formatMessage({ id: "dashboard.allPhotos" })}
              bodyText={
                pathOr("", ["overview", "photos", "totalCount"], overview) +
                intl.formatMessage({ id: "tableTexts.piece" })
              }
            />
          ) : (
            <IconCardOneItem
              icon={<PrintIcon />}
              headerText={intl.formatMessage({
                id: "dashboard.devicesCard.emptyDevices",
              })}
              bodyText={
                pathOr("", ["overview", "devices", "lowPaperCount"], overview) +
                intl.formatMessage({ id: "tableTexts.piece" })
              }
            />
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={3}>
          {isInRoles([RoleEnum.ADMIN, RoleEnum.MAINTAINER]) ? (
            <IconCardOneItem
              icon={<PeopleIcon />}
              headerText={intl.formatMessage({ id: "dashboard.allUsers" })}
              bodyText={pathOr(
                "",
                ["overview", "users", "totalCount"],
                overview
              )}
            />
          ) : (
            <IconCardOneItem
              icon={<BurstModeIcon />}
              headerText={intl.formatMessage({ id: "dashboard.allPhotos" })}
              bodyText={
                pathOr("", ["overview", "photos", "totalCount"], overview) +
                intl.formatMessage({ id: "tableTexts.piece" })
              }
            />
          )}
        </Grid>

        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          style={{ marginTop: "-50px" }}
        >
          <PhotosChart
            allPhotos={pathOr(
              "",
              ["overview", "photos", "printedCounts"],
              overview
            )}
            companies={companies}
          />
        </Grid>
        {isInRoles([RoleEnum.ADMIN, RoleEnum.MAINTAINER]) && (
          <Grid item xs={12} lg={6} style={{ marginTop: "-50px" }}>
            <UsersChart
              allUsers={pathOr(
                "",
                ["overview", "users", "registeredCounts"],
                overview
              )}
            />
          </Grid>
        )}
        <Grid item xs={12} lg={6} style={{ marginTop: "-50px" }}>
          <AmbassadorsScansChart
            allScans={pathOr(
              "",
              ["overview", "ambassadors", "referralCounts"],
              overview
            )}
          />
        </Grid>
        {console.log(
          pathOr("", ["overview", "ambassadors", "referralCounts"], overview)
        )}
        {isInRoles([RoleEnum.ADMIN, RoleEnum.MAINTAINER]) && (
          <>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={4}
              style={{ marginTop: "-50px" }}
            >
              <CreditsCard
                companyCredits={pathOr(
                  "",
                  ["overview", "credit", "companiesAmount"],
                  overview
                )}
                userCredits={pathOr(
                  "",
                  ["overview", "credit", "usersAmount"],
                  overview
                )}
                allCredits={pathOr(
                  "",
                  ["overview", "credit", "totalAmount"],
                  overview
                )}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={4}
              style={{ marginTop: "-50px" }}
            >
              <DevicesCard
                storeDevices={pathOr(
                  "",
                  ["overview", "devices", "assignedCount"],
                  overview
                )}
                stockDevices={pathOr(
                  "",
                  ["overview", "devices", "unassignedCount"],
                  overview
                )}
                allDevices={pathOr(
                  "",
                  ["overview", "devices", "totalCount"],
                  overview
                )}
                offlineDevices={pathOr(
                  "",
                  ["overview", "devices", "offlineCount"],
                  overview
                )}
                emptyDevices={pathOr(
                  "",
                  ["overview", "devices", "lowPaperCount"],
                  overview
                )}
              />
            </Grid>
          </>
        )}
      </Grid>
      {!isInRoles([RoleEnum.ADMIN, RoleEnum.MAINTAINER]) && (
        <div
          style={{
            position: "fixed",
            left: "480px",
            top: "20px",
            width: "300px",
          }}
        >
          <Formik
            initialValues={{ companies: companiesIds }}
            onSubmit={(values) => {
              renderOverviewData(values?.companies, true);
            }}
          >
            {(props) => (
              <Grid container spacing={1}>
                <Grid item sm={10}>
                  <FormikField
                    name="companies"
                    labelText={intl.formatMessage({
                      id: "dashboard.dropdown.companies",
                    })}
                    formikProps={props}
                    type="multiSelect"
                    highlightChange
                    options={companies}
                  />
                </Grid>
                <Grid item sm={2}>
                  <Button
                    style={{ marginTop: "20px" }}
                    color="primary"
                    size="sm"
                    onClick={(e) => {
                      props.handleSubmit();
                    }}
                  >
                    <CachedIcon />
                  </Button>
                </Grid>
              </Grid>
            )}
          </Formik>
        </div>
      )}
    </>
  );
};

export default withRouter(injectIntl(DashboardPage));
