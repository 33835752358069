import { Grid } from "@material-ui/core";
import CreditCardIcon from '@material-ui/icons/CreditCard';
import FormikField from "components-lib/FormikField/FormikField";
import IconCard from "components-lib/IconCard/IconCard";
import React from "react";
import { injectIntl } from "react-intl";
import { withRouter } from "react-router-dom";
import RoleEnum from "common/enums/RoleEnum";
import { isInRoles } from "helpers/helpers";
import styles from "common/styles/widgets.js";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => styles(theme));

const BillingInfoCard = (props) => {
  const { intl, formikProps, readData } = props;
  const classes = useStyles();

  const renderContent = () => {
    if (!isInRoles([RoleEnum.ADMIN, RoleEnum.MAINTAINER])) {
      return (
        <Grid container spacing={2}>
          <Grid item sm={12}>
            {intl.formatMessage({ id: "companyDetail.billingInfoCard.ico" })}
            <Box className={classes.floatRight}>
              {(readData.ico) ? readData.ico : "-"}
            </Box>
          </Grid>
          <Grid item sm={12}>
            {intl.formatMessage({ id: "companyDetail.billingInfoCard.dic" })}
            <Box className={classes.floatRight}>
              {(readData.dic) ? readData.dic : "-"}
            </Box>
          </Grid>
          <Grid item sm={12}>
            {intl.formatMessage({ id: "companyDetail.billingInfoCard.icDPH" })}
            <Box className={classes.floatRight}>
              {(readData.icDPH) ? readData.icDPH : "-"}
            </Box>
          </Grid>
          <Grid item sm={12}>
            {intl.formatMessage({ id: "companyDetail.billingInfoCard.registrationCourt" })}
            <Box className={classes.floatRight}>
              {(readData.registrationCourt) ? readData.registrationCourt : "-"}
            </Box>
          </Grid>
          <Grid item sm={12}>
            {intl.formatMessage({ id: "companyDetail.billingInfoCard.iban" })}
            <Box className={classes.floatRight}>
              {(readData.bankIBAN) ? readData.bankIBAN : "-"}
            </Box>
          </Grid>
          <Grid item sm={12}>
            {intl.formatMessage({ id: "companyDetail.billingInfoCard.bankBIC" })}
            <Box className={classes.floatRight}>
              {(readData.bankBIC) ? readData.bankBIC : "-"}
            </Box>
          </Grid>
          <Grid item sm={12}>
            {intl.formatMessage({ id: "companyDetail.billingInfoCard.bankName" })}
            <Box className={classes.floatRight}>
              {(readData.bankName) ? readData.bankName : "-"}
            </Box>
          </Grid>
        </Grid>
      )
    }

    return (
      <Grid container>
        <Grid container spacing={3}>
          <Grid item sm={6}>
            <FormikField
              name="ico"
              labelText={intl.formatMessage({ id: "companyDetail.billingInfoCard.ico" })}
              formikProps={formikProps}
              highlightChange
            />
          </Grid>
          <Grid item sm={6}>
            <FormikField
              name="dic"
              labelText={intl.formatMessage({ id: "companyDetail.billingInfoCard.dic" })}
              formikProps={formikProps}
              highlightChange
            />
          </Grid>
        </Grid>
        <Grid item sm={12}>
          <FormikField
            name="icDPH"
            labelText={intl.formatMessage({ id: "companyDetail.billingInfoCard.icDPH" })}
            formikProps={formikProps}
            highlightChange
          />
        </Grid>
        <Grid item sm={12}>
          <FormikField
            name="registrationCourt"
            labelText={intl.formatMessage({ id: "companyDetail.billingInfoCard.registrationCourt" })}
            formikProps={formikProps}
            highlightChange
          />
        </Grid>
        <Grid item sm={12}>
          <FormikField
            name="bankIBAN"
            labelText={intl.formatMessage({ id: "companyDetail.billingInfoCard.iban" })}
            formikProps={formikProps}
            highlightChange
          />
        </Grid>
        <Grid item sm={12}>
          <FormikField
            name="bankBIC"
            labelText={intl.formatMessage({ id: "companyDetail.billingInfoCard.bankBIC" })}
            formikProps={formikProps}
            highlightChange
          />
        </Grid>
        <Grid item sm={12}>
          <FormikField
            name="bankName"
            labelText={intl.formatMessage({ id: "companyDetail.billingInfoCard.bankName" })}
            formikProps={formikProps}
            highlightChange
          />
        </Grid>
      </Grid>
    )
  }

  return (
    <>
      <IconCard
        title={intl.formatMessage({ id: "storeDetail.billing.title" })}
        icon={<CreditCardIcon />}
      >
        {renderContent()}
      </IconCard>
    </>
  );
};

export default withRouter(injectIntl(BillingInfoCard));
