import { makeStyles } from "@material-ui/core/styles";
import QueryTable from "components-lib/QueryTable/QueryTable";
import QueryTableRefresh from "components-lib/QueryTable/QueryTableRefresh";
import Button from "components/CustomButtons/Button.js";
import paths from "paths";
import React from "react";
import { injectIntl } from "react-intl";
import { generatePath } from "react-router-dom";
import { pathOr } from "rambda";
import Box from "@material-ui/core/Box";
import styles from "common/styles/widgets.js";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import WarningIcon from "@material-ui/icons/Warning";
import Tooltip from "@material-ui/core/Tooltip";
import PowerOffIcon from "@material-ui/icons/PowerOff";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import ButtonDetail from 'components/ButtonDetail/ButtonDetail';

const useStyles = makeStyles((theme) => styles(theme));

export const CampaignStoreTable = (props) => {
  const classes = useStyles();

  const {
    history,
    intl,
    defaultFilter,
    queryDataPath,
    detail,
    data,
    loadData,
    query,
    queryVariables,
    numberOfRows,
  } = props;

  
  const getStoreStatus = (status) => {
    switch (status) {
      case "Online":
        return (
          <p style={{ color: "green" }}>
            <CheckCircleIcon className={classes.circleTable} />
            Online
          </p>
        );
      case "Error":
        return (
          <p style={{ color: "#f57c00" }}>
            <WarningIcon className={classes.circleTable} />
            {intl.formatMessage({ id: "storeList.error" })}
          </p>
        );
      case "Offline":
        return (
          <p style={{ color: "red" }}>
            <PowerOffIcon className={classes.circleTable} />
            Offline
          </p>
        );
      case "NoPlayer":
        return (
          <p style={{ color: "gray" }}>
            <RemoveCircleIcon className={classes.circleTable} />
            {intl.formatMessage({ id: "storeList.withoutDevice" })}
          </p>
        );
      default:
        return "-";
    }
  };

  const onStoreDetail = (id) => {
    history.push(`/admin${generatePath(paths.stores.detail, { storeId: id })}`);
  };

  const getColumnsDetail = () => {
    return [
      {
        Header: intl.formatMessage({ id: "storeList.table.ID" }),
        accessor: "bid",
        sortKey: "bid",
        filterKey: "bid",
        filterOperator: "contains",
      },
      {
        Header: intl.formatMessage({ id: "storeList.table.name" }),
        accessor: "name",
        sortKey: "name",
        filterKey: "name",
        filterOperator: "contains",
      },
      {
        Header: intl.formatMessage({ id: "storeList.table.company" }),
        accessor: "company",
        sortKey: "company",
        filterKey: "company",
        filterOperator: "contains",
      },
      {
        Header: intl.formatMessage({ id: "storeList.table.category" }),
        accessor: "segment",
        sortKey: "segment",
        filterKey: "segment",
        filterOperator: "contains",
      },
      // {
      //   Header: intl.formatMessage({ id: "storeList.table.pricingLevel" }),
      //   accessor: (rowData) => `L${rowData.pricingLevel}`,
      //   sortKey: "pricingLevel",
      //   filterKey: "pricingLevel",
      //   filterComponent: "select",
      //   filterOperator: "eq",
      //   filterOptions: [
      //     {
      //       value: 1,
      //       label: "L1",
      //     },
      //     {
      //       value: 2,
      //       label: "L2",
      //     },
      //     {
      //       value: 3,
      //       label: "L3",
      //     },
      //   ],
      // },
      {
        Header: intl.formatMessage({ id: "storeList.table.address" }),
        accessor: "address",
        sortKey: "address",
        filterKey: "address",
        filterOperator: "contains",
      },
      // {
      //   Header: intl.formatMessage({ id: "storeList.table.state" }),
      //   accessor: (rowData) => getStoreStatus(rowData.status),
      //   show: false,
      //   sortKey: "status",
      //   filterKey: "status",
      //   filterComponent: "select",
      //   filterOperator: "in",
      //   filterOptions: [
      //     {
      //       value: "Online",
      //       label: intl.formatMessage({ id: "storeList.online" }),
      //     },
      //     {
      //       value: "Offline",
      //       label: intl.formatMessage({ id: "storeList.offline" }),
      //     },
      //     {
      //       value: "NoPlayer",
      //       label: intl.formatMessage({ id: "storeList.withoutDevice" }),
      //     },
      //     {
      //       value: "Error",
      //       label: intl.formatMessage({ id: "storeList.error" }),
      //     },
      //   ],
      // },
      {
        Header: intl.formatMessage({ id: "storeList.table.actions" }),
        accessor: (rowData) => (
          // <Button
          //   round
          //   className={classes.tenantButtons}
          //   size="sm"
          //   color="info"
          //   onClick={() => onStoreDetail(rowData.id)}
          // >
          //   {intl.formatMessage({ id: "storeList.table.actions.detail" })}
          // </Button>
          <div style={{ display: "flex", flexDirection: "row",  flexWrap: "wrap",
            justifyContent: "flex-start", alignItems: "center", gap: "0.2em" }}>

              <ButtonDetail
              // classes={classes.tenantButtons}
              onClick={ () => onStoreDetail(rowData.id) }
              />
          </div>
          
        ),
      },
    ];
  };

  /*const getColumns = () => {
        return (
            [
                {
                    Header: intl.formatMessage({ id: 'storeList.table.ID' }),
                    accessor: 'bid',
                    sortKey: 'bid',
                    filterKey: 'bid',
                    filterOperator: 'contains'
                },
                {
                    Header: intl.formatMessage({ id: 'storeList.table.name' }),
                    accessor: "name",
                    sortKey: "name",
                    filterKey: 'name',
                    filterOperator: 'contains'
                },
                {
                    Header: intl.formatMessage({ id: 'storeList.table.company' }),
                    accessor: "company",
                    sortKey: "company",
                    filterKey: 'company',
                    filterOperator: 'contains'
                },
                {
                    Header: intl.formatMessage({ id: 'storeList.table.category' }),
                    accessor: "segment",
                    sortKey: "segment",
                    filterKey: 'segment',
                    filterOperator: 'contains'
                },
                {
                    Header: intl.formatMessage({ id: 'storeList.table.address' }),
                    accessor: 'address',
                    sortKey: 'address',
                    filterKey: 'address',
                    filterOperator: 'contains'
                },
                {
                    Header: intl.formatMessage({ id: 'storeList.table.state' }),
                    accessor: (rowData) => getStoreStatus(rowData),
                    show: false,
                    sortKey: 'status',
                    filterKey: 'status',
                    filterComponent: 'select',
                    filterOperator: 'eq',
                    filterOptions: [
                        {
                            value: 'Online',
                            label: intl.formatMessage({ id: 'storeList.online' })
                        },
                        {
                            value: 'Offline',
                            label: intl.formatMessage({ id: 'storeList.offline' })
                        },
                        {
                            value: 'NoPlayer',
                            label: intl.formatMessage({ id: 'storeList.withoutDevice' })
                        },
                        {
                            value: 'Error',
                            label: intl.formatMessage({ id: 'storeList.error' })
                        },
                    ]
                },
                {
                    Header: intl.formatMessage({ id: 'storeList.table.actions' }),
                    accessor: (rowData) =>
                        <Button
                            round
                            className={classes.tenantButtons}
                            size="sm"
                            color="info"
                            onClick={() => onStoreDetail(rowData.id)}
                        >
                            {intl.formatMessage({ id: 'storeList.table.actions.detail' })}
                        </Button>
                }
            ]
        )
    }*/

  return detail ? (
    // <></>
    <QueryTableRefresh
      data={data}
      loadData={loadData}
      queryVariables={queryVariables}
      permanentFilter={defaultFilter}
      queryDataPath={queryDataPath}
      numberOfRows={numberOfRows}
      columns={getColumnsDetail()}
    />
  ) : (
    // <></>
    <QueryTable
      query={query}
      queryVariables={queryVariables}
      permanentFilter={defaultFilter}
      queryDataPath={queryDataPath}
      numberOfRows={numberOfRows}
      columns={getColumnsDetail()}
    />
  );
};

export default injectIntl(CampaignStoreTable);
