import { makeStyles } from '@material-ui/core/styles';
import QueryTableRefresh from 'components-lib/QueryTable/QueryTableRefresh';
import Button from "components/CustomButtons/Button.js";
import paths from 'paths';
import React, { useState } from 'react';
import { injectIntl } from 'react-intl';
import { generatePath } from 'react-router-dom';
import styles from "common/styles/widgets.js";
import Modal from 'components-lib/Modal/Modal';
import REMOVE_PARTNER from 'queries/CompaniesQueries/removePartner'
import { useSnackbar } from 'notistack'
import { useMutation } from "@apollo/react-hooks";
import RoleEnum from "common/enums/RoleEnum";
import { isInRoles } from "helpers/helpers";

const useStyles = makeStyles((theme) => styles(theme))

const PartnersTable = (props) => {
    const classes = useStyles();
    const {
        history,
        query,
        data,
        loadData,
        refetch,
        companyId,
        numberOfRows,
        intl,
        defaultFilter = null,
        queryDataPath = ['usersTableByCompany', 'items'],
        filterFields = []
    } = props
    const [removePartnerOpen, setRemovePartnerOpen] = useState(false);
    const [userId, setUserId] = useState(-1)
    const [removePartner] = useMutation(REMOVE_PARTNER);
    const { enqueueSnackbar } = useSnackbar();

    const onPartnerDetail = (partnerIdVar) => {
        history.push(`/admin${generatePath(paths.users.detail, { userId: partnerIdVar })}`);
    }

    const onRemovePartner = () => {
        removePartner({
            variables: {
                id: companyId,
                userId: userId
            },
        }).then((response) => {
            enqueueSnackbar(intl.formatMessage({ id: "company.partner.remove.success" }), { variant: "success" });
            refetch()
            setRemovePartnerOpen(false);
        }).catch((err) => {
            console.log('[error]', err)
        });
    }

    const renderModalButtons = () => {
        return (
            <>
                <Button
                    size="sm"
                    color="danger"
                    onClick={onRemovePartner}
                    round
                >
                    {intl.formatMessage({ id: 'storeList.table.actions.remove' })}
                </Button>

                <Button
                    size="sm"
                    color="primary"
                    onClick={() => setRemovePartnerOpen(false)}
                    round
                >
                    {intl.formatMessage({ id: "invoicesList.assignModal.cancel" })}
                </Button>
            </>
        );
    };

    const getColumns = () => {
        if (!isInRoles([RoleEnum.ADMIN, RoleEnum.MAINTAINER])) {
            return [
                {
                    Header: intl.formatMessage({ id: 'storeList.table.ID' }),
                    accessor: 'bid',
                    sortKey: 'bid',
                    filterKey: 'bid',
                    filterOperator: 'contains'
                },
                {
                    Header: intl.formatMessage({ id: 'usersPage.table.name' }),
                    accessor: 'fullName',
                    filterKey: 'fullName',
                    sortKey: 'fullName',
                    filterOperator: 'contains'
                }
            ].filter(item => !filterFields.includes(item.filterKey))

        }
        return [
            {
                Header: intl.formatMessage({ id: 'storeList.table.ID' }),
                accessor: 'bid',
                sortKey: 'bid',
                filterKey: 'bid',
                filterOperator: 'contains'
            },
            {
                Header: intl.formatMessage({ id: 'storeList.table.partnerName' }),
                accessor: "fullName",
                sortKey: "fullName",
                filterKey: 'fullName',
                filterOperator: 'contains'
            },
            {
                Header: intl.formatMessage({ id: 'storeList.table.actions' }),
                accessor: (rowData) =>
                    <div>
                        <Button
                            round
                            className={classes.tenantButtons}
                            size="sm"
                            color="info"
                            onClick={() => onPartnerDetail(rowData.id)}
                        >
                            {intl.formatMessage({ id: 'storeList.table.actions.detail' })}
                        </Button>
                        <Button
                            round
                            className={classes.tenantButtons}
                            size="sm"
                            color="danger"
                            onClick={() => { setRemovePartnerOpen(true); setUserId(rowData.id) }}
                        >
                            {intl.formatMessage({ id: 'storeList.table.actions.remove' })}
                        </Button>
                    </div>
            }
        ].filter(item => !filterFields.includes(item.filterKey))
    }

    return (
        <>
            <QueryTableRefresh
                query={query}
                permanentFilter={defaultFilter}
                queryDataPath={queryDataPath}
                columns={getColumns()}
                data={data}
                loadData={loadData}
                numberOfRows={numberOfRows}
            />
            <Modal
                title={intl.formatMessage({ id: 'storeList.table.actions.removeWarning' })}
                open={removePartnerOpen}
                onClose={() => setRemovePartnerOpen(false)}
                actions={renderModalButtons()}
            />
        </>
    )
}

export default injectIntl(PartnersTable);