import { useMutation } from '@apollo/react-hooks';
import { Grid } from '@material-ui/core';
import PageHeader from 'components-lib/PageHeader/PageHeader';
import Button from "components/CustomButtons/Button.js";
import { Formik } from "formik";
import { useSnackbar } from 'notistack';
import { pathOr } from 'rambda';
import React, { useState } from "react";
import { injectIntl } from 'react-intl';
import { generatePath, withRouter } from "react-router-dom";
import paths from 'paths';
import NewCompanyCard from './StoreCards/NewCompanyCard';
import NewMaintainerCard from './StoreCards/NewMaintainerCard';
import NewStoreCard from './StoreCards/NewStoreCard';
import NewAddressCard from './StoreCards/NewAddressCard';
import STORE_CREATE from 'queries/StoresQueries/storeCreate';
import { getNewValidationSchema } from "./validationSchema";

const StoreNewPage = (props) => {
    const { history, intl } = props;
    const { enqueueSnackbar } = useSnackbar();

    let userData = ({ contactEmail: '@' });
    const [city, setCity] = useState([])

    const [createStore] = useMutation(STORE_CREATE);

    const handleCreateStore = (values) => {
        const formData = { ...values };
        delete formData.area;
        delete formData.visitors;
        delete formData.zones;
        delete formData.segmentId;
        delete formData.latitude;
        delete formData.longtitude;

        const parseFloatArea = parseFloat(values.area);
        const parseFloatVisitors = parseFloat(values.visitors)
        const parseSegment = values.segmentId
        let setCityLabel = city.label;
        const result = {
            ...formData,
            area: parseFloatArea,
            visitors: parseFloatVisitors,
            city: setCityLabel,
            latitude: Number(values.latitude),
            longtitude: Number(values.longtitude),
            preferences: {
                segmentId: parseSegment
            }
        }
        createStore({
            variables: {
                input: { ...result }
            }
        }).then((response) => {
            const companyName = pathOr('', ['data', 'storeCreate', 'company.name'], response);
            const id = pathOr('', ['data', 'storeCreate', 'id'], response);
            enqueueSnackbar(intl.formatMessage({ id: 'storeList.addStore.success' }, [companyName]), { variant: 'success' });
            history.push(`/admin${generatePath(paths.stores.detail, { storeId: id })}`)
        }).catch((err) => {
            console.log('[error]', err);
        });
    };

    return (
        <>
            <PageHeader
                title={intl.formatMessage({ id: 'storeList.addStore.title' })}
                handleBackAction={(e) => history.goBack()}
            />
            <Formik
                initialValues={userData}
                onSubmit={(values) => { handleCreateStore(values); }}
                validationSchema={getNewValidationSchema(intl)}
            >
                {(formikProps) =>
                    <>
                        <Grid container spacing={3}>
                            <Grid item lg={4}>
                                <NewStoreCard
                                    data={formikProps.values}
                                    formikProps={formikProps}
                                />
                                <NewMaintainerCard
                                    data={formikProps.values}
                                    formikProps={formikProps}
                                />
                                <NewAddressCard
                                    data={formikProps.values}
                                    formikProps={formikProps}
                                    callbackCity={(newValue) => setCity(newValue)}
                                />
                            </Grid>
                            <Grid item lg={4}>
                                <NewCompanyCard
                                    data={formikProps.values}
                                    formikProps={formikProps}
                                />
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item lg={4}>
                                <Button
                                    color="primary"
                                    type="submit"
                                    onClick={() => formikProps.handleSubmit()}
                                >
                                    {intl.formatMessage({ id: 'createUser.submit' })}
                                </Button>
                                <Button
                                    onClick={(e) => history.push(`/admin${paths.stores.list}`)}
                                >
                                    {intl.formatMessage({ id: 'createUser.cancel' })}
                                </Button>
                            </Grid>
                        </Grid>
                    </>
                }
            </Formik>
        </>
    );
};

export default withRouter(injectIntl(StoreNewPage));