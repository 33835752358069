import React, { useCallback } from 'react';
import { Grid } from '@material-ui/core';
import { useState } from 'react';
import { useQuery } from 'react-apollo';
import { Formik } from 'formik';
import { useEffect } from 'react';
import { pathOr } from 'rambda';
import FormikField from 'components-lib/FormikField/FormikField';
import { validationSchemaStep3 } from "./validationSchema";
import { injectIntl } from 'react-intl';
import { makeStyles } from "@material-ui/core/styles";
import styles from "common/styles/widgets.js";
import COMPANY_DETAIL from 'queries/CompaniesQueries/companyDetail';
import Form from "components-lib/CampaignWidget/component/form/Form";
import HoursCard from "components-lib/HoursCard/HoursCard";
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import FormHelperText from "@material-ui/core/FormHelperText";
import colors from "common/styles/colors";

import CAMPAIGN_DETAILS from 'queries/CampaignsQueries/campaignDetails';

const useStyles = makeStyles((theme) => styles(theme));

const Step3 = ({ intl, initialValues, callback, callbackCriteria, callbackValid, initialData, companyId, times, callbackTimes, criteria, axios, isBack }) => {

  const { data: companyData } = useQuery(COMPANY_DETAIL, { variables: { id: companyId } });
  const classes = useStyles();
  const [budgetCondition, setBudgetCondition] = useState(false)

  let balance = pathOr('', ['company', 'account', 'balance'], companyData)
  const getRemains = (cred) => {
    let final = (cred)
      ? Number(balance) - Number(cred)
      : balance
    return <>
      {(final >= 0 && cred) && <FormHelperText style={{ color: colors.success }} className={classes.labelRootSuccess}>
        {intl.formatMessage({ id: 'campaigns.infoCard.creditStatus' })} {final} C
      </FormHelperText>
      }

    </>
  }

  const getBudget = (budget, maxBid) => {
    if (Number(budget) >= Number(maxBid)) setBudgetCondition(true)
    else setBudgetCondition(false)
    return <>
      {(Number(budget) < Number(maxBid) && maxBid) && <FormHelperText style={{ color: colors.danger }} className={classes.labelRootSuccess}>
        {intl.formatMessage({ id: 'campaigns.infoCard.maxBidInfo' })}
      </FormHelperText>}
    </>
  }

  const [value, setValue] = useState(criteria);
  const [campaign, setCampaign] = useState({ companyId: companyId })

  const onChange = useCallback((value) => {
    setValue(value);
    callbackCriteria(value)
  }, [])

  useEffect(() => {
    callbackValid(false)
  }, []);

  const initialVal = initialValues
    ? {    
      ...initialData,        
      maxBid: initialValues.maxBid,
      budget: initialValues.budget.total,
      minRepeatInterval: initialValues.minRepeatInterval,
  }
  : initialData;

  const searchForm = initialValues ?
    {
      ...criteria,
      locationIds: initialValues.criteria[0].locations.length > 0 ? initialValues.criteria[0].locations.map( company => company.id) : ["*"],
      segmentIds: initialValues.criteria[0].segments.length > 0 ? initialValues.criteria[0].segments.map( company => company.id) : ["*"],
      companyIds: initialValues.criteria[0].companies.length > 0 ? initialValues.criteria[0].companies.map( company => company.id) : ["*"],
      zoneIds: initialValues.criteria[0].zones.length > 0 ? initialValues.criteria[0].zones.map( company => company.id) : ["*"],
    } : criteria;

    useEffect(() => {
      console.log( JSON.stringify(searchForm))
      console.log( JSON.stringify(value) )
      if ( !isBack) setValue(searchForm); callbackCriteria( searchForm )
      // if ( isBack && JSON.stringify(searchForm) === JSON.stringify(value) ) {
      //   setValue(searchForm)
      // }
    }, []);

    // console.log( JSON.stringify(searchForm))
    // console.log( JSON.stringify(value) )

    return (
    <>
      <Formik
        enableReinitialize
        initialValues={ isBack ? initialData : initialVal }
        validationSchema={validationSchemaStep3(intl, balance, null)}
      >

        {formikProps => (
          <Grid container spacing={1}>
            <Grid item sm={6}>
              <Grid container spacing={1}>
                <Grid item sm={12}>
                  <Paper style={{ padding: "10px" }}>
                    <Typography variant="h5" component="h2">
                      {intl.formatMessage({ id: 'campaigns.wizard.step3.budget' })}
                    </Typography>
                    {callbackValid(formikProps.isValid && formikProps.values.budget && formikProps.values.maxBid && (budgetCondition === true)
                      && (formikProps.values.budget <= balance))}
                    {callback(formikProps.values)}
                    <FormikField
                      name="budget"
                      labelText={
                        <p>
                          {intl.formatMessage({ id: 'campaigns.wizard.step3.maxBudget' })}
                          {" (Max. " + balance + " C" + ")"}
                        </p>
                      }
                      formikProps={formikProps}
                      data={formikProps.values}
                      highlightChange
                    />
                    {getRemains(formikProps.values?.budget)}

                    <FormikField
                      name="maxBid"
                      labelText={intl.formatMessage({ id: 'campaigns.wizard.step3.maxBid' })}
                      formikProps={formikProps}
                      data={formikProps.values}
                      highlightChange
                    />
                    {getBudget(formikProps.values?.budget, formikProps?.values?.maxBid)}
                  </Paper>
                </Grid>
                {axios &&
                  <Grid item sm={12}>
                    <Paper style={{ padding: "10px" }}>
                      <Form
                        axios={axios}
                        campaign={ campaign }
                        value={ value }
                        onChange={onChange}
                      />
                    </Paper>
                  </Grid>
                }

              </Grid>


            </Grid>
            <Grid item sm={6}>
              <Grid container spacing={1}>
                <Grid item sm={12}>
                  <Paper style={{ padding: "10px" }}>
                    <Typography variant="h5" component="h2">
                      {intl.formatMessage({ id: 'campaigns.wizard.step3.timePlan' })}
                    </Typography>
                    <FormikField
                      name="minRepeatInterval"
                      labelText={intl.formatMessage({ id: "campaigns.scheduleCard.repeat" })}
                      formikProps={formikProps}
                      data={formikProps.values}
                      highlightChange
                    />
                    <br />
                    <HoursCard
                      tms={times}
                      callbackTimes={(newValue) => callbackTimes(newValue)}
                      formikProps={formikProps}
                      withoutOpenInfo={true}
                    />
                  </Paper>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

        )}
      </Formik>

    </>
  );
}

export default injectIntl(Step3);