import React, { useState } from "react";
import styles from "common/styles/widgets.js";
import { makeStyles } from "@material-ui/core/styles";
import { injectIntl } from "react-intl";
import { Grid } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import IconCard from "components-lib/IconCard/IconCard";
import AccessAlarmIcon from '@material-ui/icons/AccessAlarm';
import HoursCard from "components-lib/HoursCard/HoursCard";
import InactiveHoursCard from "components-lib/HoursCard/InactiveHoursCard";
import FormikField from "components-lib/FormikField/FormikField";
import RoleEnum from "common/enums/RoleEnum";
import { isInRoles } from "helpers/helpers";

const useStyles = makeStyles((theme) => styles(theme));

const TimePlanCard = (props) => {
    const {
        intl,
        formikProps,
        times,
        callbackTimes,
        callbackInactiveTimes,
        storeId,
        storeUpdate,
        multipleUpdate
    } = props;

    const classes = useStyles();
    const [isOpenDuringHolidaysChange, setIsOpenDuringHolidaysChange] = useState(true);
    const [openHoursChange, setOpenHoursChange] = useState(true);
    const [inactiveHoursChange, setInactiveHoursChange] = useState(true);
    //const [spotsPlaybackStartOffsetChange, setSpotsPlaybackStartOffsetChange] = useState(multipleUpdate ? false : true);
    //const [spotsPlaybackEndOffsetChange, setSpotsPlaybackEndOffsetChange] = useState(multipleUpdate ? false : true);

    /*const spotsInterval = [
        { value: "0", label: `0 ${intl.formatMessage({ id: 'tableTexts.minutes' })}` },
        { value: "5", label: `5 ${intl.formatMessage({ id: 'tableTexts.minutes' })}` },
        { value: "10", label: `10 ${intl.formatMessage({ id: 'tableTexts.minutes' })}` },
        { value: "15", label: `15 ${intl.formatMessage({ id: 'tableTexts.minutes' })}` },
        { value: "20", label: `20 ${intl.formatMessage({ id: 'tableTexts.minutes' })}` },
        { value: "25", label: `25 ${intl.formatMessage({ id: 'tableTexts.minutes' })}` },
        { value: "30", label: `30 ${intl.formatMessage({ id: 'tableTexts.minutes' })}` },
    ];*/

    const powerSavingModeOptions = [
        { value: "ScreenPowerOff", label: intl.formatMessage({ id: 'storeDetail.openingHours.powerSavingMode.ScreenPowerOff' }) },
        { value: "SystemPowerOff", label: intl.formatMessage({ id: 'storeDetail.openingHours.powerSavingMode.SystemPowerOff' }) },
        { value: "None", label: intl.formatMessage({ id: 'storeDetail.openingHours.powerSavingMode.None' }) },
    ];

    const renderOpenHours = () => {
        return (<>
            {(!openHoursChange) ?
                <>
                    {/*<MultipleValuesField
                    callback={(newValue) => setOpenHoursChange(newValue)}
                    labelText={intl.formatMessage({ id: 'storeDetail.openingHours.title' })}
                    type={'time'}
                />*/}
                </>
                :
                <>
                    {/*<h4>{intl.formatMessage({ id: 'storeDetail.openingHours.title' })}</h4>
                    <hr style={{ marginTop: "10px", marginBottom: "10px" }} />*/}
                    <br />
                    <HoursCard
                        times={times}
                        storeId={storeId}
                        callbackTimes={callbackTimes}
                        formikProps={formikProps}
                        storeUpdate={storeUpdate}
                        helperText={(multipleUpdate) &&
                            <span
                                className={classes.multiUpdate}
                                style={{ color: "blue" }}
                                onClick={() => { callbackTimes([]); setOpenHoursChange(false) }}
                            >
                                {intl.formatMessage({ id: 'storeList.multiUpdate.state.undoChange' })}
                            </span>
                        }
                    />
                </>
            }</>
        )
    }

    const renderSpecialCloseHours = () => {
        return (<>
            {(!inactiveHoursChange) ?
                <></>
                :
                <>
                    <h5>{intl.formatMessage({ id: 'storeDetail.openingHours.specialClosed' })}</h5>
                    <InactiveHoursCard
                        callback={callbackInactiveTimes}
                        storeId={storeId}
                        storeUpdate={storeUpdate}
                        formikProps={formikProps}
                        multipleUpdate={multipleUpdate}
                        helperText={(multipleUpdate) &&
                            <span
                                className={classes.multiUpdate}
                                style={{ color: "blue" }}
                                onClick={() => { callbackInactiveTimes([]); setInactiveHoursChange(false) }}
                            >
                                {intl.formatMessage({ id: 'storeList.multiUpdate.state.undoChange' })}
                            </span>
                        }
                    />
                </>
            }
        </>
        )
    }

    const renderIsOpenDuringHolidays = () => {
        if (isInRoles([RoleEnum.ADMIN, RoleEnum.MAINTAINER]))
            return (<>
                {(!isOpenDuringHolidaysChange) ?
                    <> {/*
                    <MultipleValuesField
                        callback={(newValue) => setIsOpenDuringHolidaysChange(newValue)}
                        labelText={intl.formatMessage({ id: 'storeDetail.openingHours.holiday' })}
                        type={'toggle'}
                    />
                */} </>
                    :
                    <FormikField
                        name="zones.0.preferences.isOpenedDuringHolidays"
                        labelText={intl.formatMessage({ id: 'storeDetail.openingHours.holiday' })}
                        checkedLabelText={intl.formatMessage({ id: 'storeDetail.openingHours.open' })}
                        uncheckedLabelText={intl.formatMessage({ id: 'storeDetail.openingHours.closed' })}
                        formikProps={formikProps}
                        type="switch"
                        highlightChange
                        helperText={(multipleUpdate) &&
                            <span
                                className={classes.multiUpdate}
                                style={{ color: "blue" }}
                                onClick={() => { formikProps.setFieldValue("zones.0.preferences.isOpenedDuringHolidays", undefined); setIsOpenDuringHolidaysChange(false) }}
                            >
                                {intl.formatMessage({ id: 'storeList.multiUpdate.state.undoChange' })}
                            </span>
                        }
                    />
                }
            </>)
        return (
            <Grid container>
                <Grid item sm={12}>
                    {intl.formatMessage({ id: 'storeDetail.openingHours.holiday' })}
                    <Box className={classes.floatRight}>
                        {formikProps?.values?.zones?.[0]?.preferences?.isOpenedDuringHolidays
                            ? <><label>{intl.formatMessage({ id: 'storeDetail.openingHours.open' })}</label></>
                            : <><label>{intl.formatMessage({ id: 'storeDetail.openingHours.closed' })}</label></>
                        }

                    </Box>
                </Grid>
            </Grid>
        )
    };

    return (
        <IconCard
            title={intl.formatMessage({ id: 'campaigns.scheduleCard.title' })}
            icon={<AccessAlarmIcon />}
        >
            <Grid container spacing={0}>
                {(isInRoles([RoleEnum.ADMIN, RoleEnum.MAINTAINER])) ?
                    <Grid item sm={12}>
                        <FormikField
                            name="zones.0.preferences.powerSavingMode"
                            labelText={intl.formatMessage({ id: 'storeDetail.openingHours.powerSavingMode' })}
                            formikProps={formikProps}
                            type="select"
                            options={powerSavingModeOptions}
                            highlightChange
                        />
                    </Grid>
                    :
                    <Grid item sm={12}>
                        {intl.formatMessage({ id: 'storeDetail.openingHours.powerSavingMode' })}
                        <Box className={classes.floatRight}>
                            {(formikProps?.values?.zones?.[0]?.preferences?.powerSavingMode === "None")
                                ? <><label>{intl.formatMessage({ id: 'storeDetail.openingHours.powerSavingMode.None' })}</label></>
                                : (formikProps?.values?.zones?.[0]?.preferences?.powerSavingMode === "ScreenPowerOff")
                                    ? <><label>{intl.formatMessage({ id: 'storeDetail.openingHours.powerSavingMode.ScreenPowerOff' })}</label></>
                                    : <><label>{intl.formatMessage({ id: 'storeDetail.openingHours.powerSavingMode.SystemPowerOff' })}</label></>
                            }

                        </Box>
                    </Grid>
                }
                <Grid item sm={12}>
                    {renderOpenHours()}
                </Grid>
                <Grid item sm={12} style={{ paddingTop: "10px" }} >
                    {renderIsOpenDuringHolidays()}
                </Grid>
                <Grid item sm={12}>
                    <hr style={{ marginTop: "2px", marginBottom: "0px" }} />
                </Grid>
                <Grid item sm={12}>
                    {renderSpecialCloseHours()}
                </Grid>

            </Grid>
        </IconCard >
    )
};

export default injectIntl(TimePlanCard);
