import gql from 'graphql-tag';

const ALL_COMMISSION_STORES = gql`
query zoneCommissionsTable (
  $offset: Int, 
  $limit:Int, 
  $sort:[ZoneCommissionsTableSortInput!],
  $filter:[ZoneCommissionsTableFilterInput]
) {
  zoneCommissionsTable (
  offset: $offset, limit:$limit, sort: $sort, filter: $filter
  ) {
      totalCount,
      items {
        id
        bid
        storeBid
        storeName
        address
        period
        photosCount
        value
      }
  }
}
`;

export default ALL_COMMISSION_STORES;