
import gql from 'graphql-tag';

const STORES_SELECT = gql`
query stores($offset: Int, $limit: Int, $sort: [StoreSortInput!], $filter: [StoreFilterInput]){
  stores(offset:$offset, limit: $limit, sort: $sort, filter: $filter){
    totalCount,
    items{
      id,
      bid,
      name,
      segment,
      address,
      postal
      city,
      country,
      latitude,
      longtitude,
      zones{
        device{
          id
        }
      }
      fullAddress,
      contactFirstName,
      contactLastName,
      contactPhone,
      contactEmail,
      createdAt,
      updatedAt,
      company{
        id,
        bid,
        name,
        ico,
        dic,
        icDPH,
        registrationCourt,
        address,
        postal,
        city,
        country,
        latitude,
        longtitude,
        bankName,
        bankIBAN,
        bankBIC,
        contactFirstName,
        contactLastName,
        contactPhone,
        contactEmail,
        commissionRate,
        createdAt,
        updatedAt,
      }
    }
  }
}
`;


export default STORES_SELECT;

