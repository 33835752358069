import gql from 'graphql-tag';

const AUTO_NOTIFICATION_UPDATE = gql`
mutation automatedNotificationUpdate ($id: ID!, $input: AutomatedNotificationUpdateInput!) {
  automatedNotificationUpdate (id: $id, input: $input) {
      id
  }
}
`;

export default AUTO_NOTIFICATION_UPDATE;