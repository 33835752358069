import gql from 'graphql-tag';

const CAMPAIGN_CANCEL = gql`
    mutation campaignCancel($id: ID!){
        campaignCancel(id:$id){
            id
        	name
        }
    }
`;

export default CAMPAIGN_CANCEL;
