import React from "react";
import { injectIntl } from "react-intl";
import { Grid } from "@material-ui/core";
import IconCard from "components-lib/IconCard/IconCard";
import AccessAlarmIcon from '@material-ui/icons/AccessAlarm';
import FormikField from "components-lib/FormikField/FormikField";
import HoursCard from "components-lib/HoursCard/HoursCard";

const ScheduleCard = (props) => {
    const {
        intl,
        formikProps,
        callbackTimes,
        campaignId,
        isCanceled
    } = props;


    const renderOpenHours = () => {
        return (
            <HoursCard
                callbackTimes={callbackTimes}
                formikProps={formikProps}
                withoutOpenInfo={true}
                campaignId={campaignId}
                readOnly={isCanceled}
            />
        )
    }

    return (
        <IconCard
            title={intl.formatMessage({ id: 'campaigns.scheduleCard.title' })}
            icon={<AccessAlarmIcon />}
            className= "detailHours"
        >
            <FormikField
                name="campaign.minRepeatInterval"
                disabled={isCanceled}
                labelText={intl.formatMessage({ id: "campaigns.scheduleCard.repeat" })}
                formikProps={formikProps}
                data={formikProps.values}
                highlightChange
            />
           <Grid item sm={12}><br /></Grid>
            <Grid item sm={12}>
                {renderOpenHours()}
            </Grid>
            <br /><br />
        </IconCard>
    )

};

export default injectIntl(ScheduleCard);
