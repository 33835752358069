import gql from 'graphql-tag';

const NOTIFICATION_DETAIL = gql`
query notification($id: ID!){
  notification(id:$id){
    id
    bid
    topic
    title
    text
    action{
      screen
      params
    }
    criteria{
      id
      genders
      registrations
      lastOrder
      revenue
      printedAtFotomatIds
      birthDay
      ageRange
      subscriptionPlans
      emails
      names
      creditAccountBalance
      usedFreePhoto
    }
    issuedAt
    issuedBy{
      fullName
    }
    reach
    scheduledTo
    sentAt
    canceledAt
  }
}
`;

export default NOTIFICATION_DETAIL;