import gql from 'graphql-tag';

const ALL_PAYMENTS = gql`
query paymentsTable(
  $offset: Int
  $limit: Int
  $sort: [PaymentsTableSortInput!]
  $filter: [PaymentsTableFilterInput]
) {
  paymentsTable(offset: $offset, limit: $limit, sort: $sort, filter: $filter) {
    totalCount,
    items {
      id
      bid
      purpose
      status
      method
      amount
      credit
      userEmail
      userId
      operationId
      operationBid
      checkoutId
      createdAt
    }
  }
}
`;

export default ALL_PAYMENTS;