import { gql } from "apollo-boost";

const AMBASSADOR_REFERRALS_EXPORT = gql`
mutation ambassadorReferralsExport($input: AmbassadorReferralsExportInput!) {
  ambassadorReferralsExport(input: $input) {
     type
      url
     expiresAt
  }
}
`;

export default AMBASSADOR_REFERRALS_EXPORT;
