import * as React from 'react';

const FFCampaignsIcon = ({ raw = false }) => {
    return (
        <svg 
            id="Layer_1" 
            data-name="Layer 1" 
            xmlns="http://www.w3.org/2000/svg" 
            viewBox="0 0 100 100"
            className={`MuiSvgIcon-root`}
            style={ !raw ? {
                top: '3px',
                color: 'inherit',
                float: 'left',
                width: '30px',
                height: '24px',
                opacity: '0.8',
                position: 'inherit',
                textAlign: 'center',
                marginRight: '15px',
                verticalAlign: 'middle',
            } : {}}
        >
            <path className="cls-1" d="M87.93,0H12.07A12.06,12.06,0,0,0,0,12.07V87.93A12.06,12.06,0,0,0,12.07,100H87.93A12.06,12.06,0,0,0,100,87.93V12.07A12.06,12.06,0,0,0,87.93,0ZM71.46,81l-22-15.29L27.53,81l7.75-25.61L14,39.24l26.75-.54L49.5,13.42,58.28,38.7,85,39.24,63.71,55.41Z"/>
        </svg>
    );
}

export default FFCampaignsIcon;