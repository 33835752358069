import gql from 'graphql-tag';

const STORE_STATS = gql`
query storeStats($id: ID!) {
storeStats(id: $id) {
   printedPhotos{
    date
    count
  }
}
}
`;

export default STORE_STATS;