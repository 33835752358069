import gql from 'graphql-tag';

const CAMPAIGN_CREATE = gql`
mutation campaignCreate($input: CampaignCreateInput!){
  campaignCreate(input: $input){
      name
      id
      bid
  }
}
`;

export default CAMPAIGN_CREATE;